import {
    INVOICE,
    INVOICE_BY_ID,
    INVOICE_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

import {
    GET_INVOICE_URL,
    INVOICE_BY_ID_URL,
    UPDATE_INVOICE_URL,
    CREATE_INVOICE_URL
} from '../../services/urls';



import axiosauth from '../../services/axios-auth';


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}


export function invoiceList(data) {
    return {
        type: INVOICE,
        payload: data
    }
}

export function invoice_By_Id(data) {
    return {
        type: INVOICE_BY_ID,
        payload: data
    }
}


export function responseInvoice(data) {
    return {
        type: INVOICE_RESPONSE,
        payload: data
    }
}


// ###########  Fecth All bookingList ########

export const invoiceByPage = (user, page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(invoiceList([]))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_INVOICE_URL + `?userType=${user}&page=${page}&limit=${10}&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(invoiceList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("invoice Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}

    }
}


// ###########  Fecth  Invoice Detail By Id ########

export const invoiceById = (id) => {
    return async (dispatch) => {

        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(INVOICE_BY_ID_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(invoice_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("invoice_By_Id Fail")
                }
            }).catch((error) => {
                console.log("Error==>", error.message)
                dispatch(isError(error.message))
                dispatch(isLoading(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  UPDATE UPDATE_INVOICE_URL  ############

export const createInvoice = (data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.post(CREATE_INVOICE_URL, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(responseInvoice(res))
                    dispatch(isPending(false))
                } else {
                    console.log("Invoice Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}



// ###########  UPDATE UPDATE_INVOICE_URL  ############

export const updateInvoice = (fields, id) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.put(UPDATE_INVOICE_URL + `/${id}`, fields).then(response => {
                let res = JSON.parse(response.data)
                console.log("resss-->", res)
                if (res.statusCode === 200) {
                    dispatch(responseInvoice(res))
                    dispatch(isPending(false))
                } else {
                    console.log("Invoice Details Fail")
                }
            }).catch((error) => {
                console.log("catch-->", error)
                dispatch(isError(error.message))
                dispatch(isPending(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}