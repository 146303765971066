import React from "react";
import { Link } from "react-router-dom";

const TypeList = ({ item, index, read }) => {
  return (
    <tr>
      <td className="text-center"> {index}. </td> <td> {item.type} </td>
      <td>
        {" "}
        <img
          src={item.image}
          style={{
            width: 100,
            height: 60,
          }}
          alt="type"
        />{" "}
      </td>{" "}
      <td className="text-center">
        <Link
          to={`/update-type/${item.id}`}
          className={`btn font-weight-bold px-1 py-0 text-danger ${
            !read ? "" : "d-none"
          }`}
        >
          {" "}
          <i className="fas fa-edit"> </i>
        </Link>
        <Link
          to={`/type-detail/${item.id}`}
          className="btn font-weight-bold px-1 py-0"
        >
          {" "}
          <i className="fas fa-eye"> </i>
        </Link>
      </td>{" "}
    </tr>
  );
};

export default TypeList;
