import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { fetchZones, createZones } from "../../store/action/zoneAction";

const Zone = () => {
  const history = useHistory();
  const [zoneForm, setZoneForm] = useState([]);
  const [zoneName, setZoneName] = useState(null);
  const [zoneNameErr, setZoneNameErr] = useState(null);
  const [zoneStateErr, setZoneStateErr] = useState(null);
  const [zones, setZones] = useState([]);
  const [createdMessage, setCreatedMessage] = useState(null);
  const [uniqueErr, setUniqueErr] = useState(null);

  const stateOptions = [
    {
      value: "ANDHRA PRADESH",
      label: "ANDHRA PRADESH",
    },
    {
      value: "PONDICHERRY",
      label: "PONDICHERRY",
    },
    {
      value: "ASSAM",
      label: "ASSAM",
    },
    {
      value: "BIHAR",
      label: "BIHAR",
    },
    {
      value: "CHATTISGARH",
      label: "CHATTISGARH",
    },
    {
      value: "DELHI",
      label: "DELHI",
    },
    {
      value: "GUJARAT",
      label: "GUJARAT",
    },
    {
      value: "DAMAN & DIU",
      label: "DAMAN & DIU",
    },
    {
      value: "DADRA & NAGAR HAVELI",
      label: "DADRA & NAGAR HAVELI",
    },
    {
      value: "HARYANA",
      label: "HARYANA",
    },
    {
      value: "HIMACHAL PRADESH",
      label: "HIMACHAL PRADESH",
    },
    {
      value: "JAMMU & KASHMIR",
      label: "JAMMU & KASHMIR",
    },
    {
      value: "JHARKHAND",
      label: "JHARKHAND",
    },
    {
      value: "KARNATAKA",
      label: "KARNATAKA",
    },
    {
      value: "KERALA",
      label: "KERALA",
    },
    {
      value: "LAKSHADWEEP",
      label: "LAKSHADWEEP",
    },
    {
      value: "MADHYA PRADESH",
      label: "MADHYA PRADESH",
    },
    {
      value: "MAHARASHTRA",
      label: "MAHARASHTRA",
    },
    {
      value: "GOA",
      label: "GOA",
    },
    {
      value: "MANIPUR",
      label: "MANIPUR",
    },
    {
      value: "MIZORAM",
      label: "MIZORAM",
    },
    {
      value: "NAGALAND",
      label: "NAGALAND",
    },
    {
      value: "TRIPURA",
      label: "TRIPURA",
    },
    {
      value: "ARUNACHAL PRADESH",
      label: "ARUNACHAL PRADESH",
    },
    {
      value: "MEGHALAYA",
      label: "MEGHALAYA",
    },
    {
      value: "ODISHA",
      label: "ODISHA",
    },
    {
      value: "PUNJAB",
      label: "PUNJAB",
    },
    {
      value: "CHANDIGARH",
      label: "CHANDIGARH",
    },
    {
      value: "RAJASTHAN",
      label: "RAJASTHAN",
    },
    {
      value: "TAMIL NADU",
      label: "TAMIL NADU",
    },
    {
      value: "UTTAR PRADESH",
      label: "UTTAR PRADESH",
    },
    {
      value: "UTTARAKHAND",
      label: "UTTARAKHAND",
    },
    {
      value: "WEST BENGAL",
      label: "WEST BENGAL",
    },
    {
      value: "ANDAMAN & NICOBAR ISLANDS",
      label: "ANDAMAN & NICOBAR ISLANDS",
    },
    {
      value: "SIKKIM",
      label: "SIKKIM",
    },
  ];

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    setZoneForm(selectedOption);
    setZoneStateErr(null);
  };

  const zoneSubmit = () => {
    const zn = zoneForm.map((item) => item.value);
    if (!zoneName) {
      setZoneNameErr("Zone Name Required");
      return 0;
    }
    if (zn.length === 0) {
      setZoneStateErr("States Are Required");
      return 0;
    }

    let obj = {
      name: zoneName,
      states: zn,
    };

    createZones(setZones, setCreatedMessage, setUniqueErr, obj);
  };

  useEffect(() => {
    fetchZones(setZones);
  }, []);

  function parseStates(states) {
    return JSON.parse(states);
  }

  return (
    <div>
      <LayoutOne>
        <div className="row align-items-center px-3 mx-0">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0 d-flex justify-content-between">
            <h5 className="text-dark pt-2">
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-2 cursorPointer"
              ></i>
              Zone Management{" "}
            </h5>{" "}
          </div>
          <div className="col-12 bg-white p-3">
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="zone"> Zone Name </label>{" "}
                  <input
                    onChange={(e) => {
                      setZoneName(e.target.value);
                      setZoneNameErr(null);
                    }}
                    type="text"
                    className="form-control"
                    id="zone"
                    placeholder="Enter Zone Name"
                    required
                  />
                  <span className="text-danger"> {zoneNameErr} </span>{" "}
                </div>{" "}
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="zone"> Select Multipal State </label>{" "}
                  <Select
                    //    defaultValue={[colourOptions[2], colourOptions[3]]}
                    onChange={handleChange}
                    isMulti
                    name="colors"
                    options={stateOptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  <span className="text-danger"> {zoneStateErr} </span>{" "}
                </div>{" "}
              </div>{" "}
              <span className="text-success ml-3"> {createdMessage} </span>{" "}
              <span className="text-danger ml-3"> {uniqueErr} </span>{" "}
            </div>
            <button className="btn btn-deep-blue" onClick={zoneSubmit}>
              Submit{" "}
            </button>
            <div className="row align-items-center mt-2">
              {" "}
              {zones &&
                zones.length > 0 &&
                zones
                  .filter((zn) => zn.name !== null)
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="col-12 table-responsive table-gray-admin"
                      >
                        <div className="card">
                          <h5 className="card-header"> {item.name} </h5>{" "}
                          <div className="card-body">
                            {" "}
                            {parseStates(item.states) &&
                              parseStates(item.states).length > 0 &&
                              parseStates(item.states).map((itm, i) => (
                                <span
                                  key={i}
                                  className="card-text mr-2 my-2 p-1 border"
                                  style={{
                                    lineHeight: "240%",
                                  }}
                                >
                                  {" "}
                                  {itm}{" "}
                                </span>
                              ))}{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    );
                  })}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </LayoutOne>{" "}
    </div>
  );
};

export default Zone;
