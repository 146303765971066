import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import SearchBox from "../../components/common/SearchBox";
import DocList from "../../wrapper/documents/DocList";
import { documentByPage } from "../../store/action/documentAction";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";

const Documents = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.DOCUMENT_INFO);
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(documentByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(documentByPage(parseInt(pageCount), searchHandler));
  };

  const callSearch = () => {
    dispatch(documentByPage(parseInt(pageCount), searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Documents </title>{" "}
      </Helmet>

      <div className="row align-items-center px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 px-0 d-flex justify-content-between">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 pt-2 cursorPointer"
            >
              {" "}
            </i>
            Manage Documents{" "}
          </h5>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
          />{" "}
        </div>
        <div className="col-12 px-0">
          <div className="row align-items-center py-3 px-3 mx-0">
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-12 text-nowrap table-responsive table-gray-admin bg-white p-3">
                  <table className="table table-dark-custom">
                    <thead>
                      <tr>
                        <th className="text-nowrap text-center"> WH ID </th>{" "}
                        <th className="text-nowrap"> Company Name </th>{" "}
                        <th className="text-nowrap"> Doc.Date </th>{" "}
                        <th className="text-nowrap"> Vendor Name </th>{" "}
                        <th className="text-nowrap"> Doc.Date </th>{" "}
                        {/* <th className="text-nowrap">Status</th>  */}{" "}
                        <th className="text-center"> Action </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      {data.documentList &&
                      data.documentList.data &&
                      data.documentList.data.length > 0
                        ? data.documentList.data.map((item, index) => {
                            return (
                              <DocList item={item} key={index} read={read} />
                            );
                          })
                        : null}
                    </tbody>{" "}
                  </table>{" "}
                </div>{" "}
              </div>{" "}
              <div className="row">
                <div className="col-12">
                  {" "}
                  {data.documentList?.totalCount > 10 && (
                    <div className="pagination-custom">
                      <Pagination
                        activePage={parseInt(pageCount)}
                        itemsCountPerPage={10}
                        totalItemsCount={data.documentList.totalCount}
                        pageRangeDisplayed={1}
                        onChange={handlePageChange}
                        prevPageText={<i className="fas fa-chevron-left" />}
                        nextPageText={<i className="fas fa-chevron-right" />}
                        hideFirstLastPages={true}
                      />{" "}
                    </div>
                  )}{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default Documents;
