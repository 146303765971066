import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import {readableDate} from '../../components/helper/reuse';
import { confirmAlert } from "react-confirm-alert";
import { updateWhsUser } from "../../store/action/whsUserAction";
import { useDispatch } from "react-redux";

const WHSUserList = ({ index, item, read }) => {
  const [isActive, setIsActive] = useState(null);
  const dispatch = useDispatch();

  // Status Change confirmation
  const statusChange = (isActive) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to change status.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              updateWhsUser(item.id, {
                active: isActive,
              })
            ),
        },
        {
          label: "No",
          onClick: () => setIsActive(!isActive),
        },
      ],
    });
  };

  const haldleChange = (e) => {
    setIsActive(e.target.value === "true");
    statusChange(e.target.value === "true");
  };

  useEffect(() => {
    setIsActive(item.active);
  }, [item.active]);

  return (
    <tr>
      <td className="text-center"> {index} </td>{" "}
      <td> {item?.firstName + " " + item?.lastName} </td>
      <td> {item?.accountRole?.name} </td> <td> {item?.phone} </td>{" "}
      <td> {item?.email} </td> <td> {item?.department?.name} </td>{" "}
      <td>
        <select
          disabled={read}
          onChange={haldleChange}
          name="status"
          value={isActive}
          className="form-control form-control-md custom-select bg-white common-select-deep-blue border-0 w-90px"
        >
          <option value={true}> Active </option>{" "}
          <option value={false}> In Active </option>{" "}
        </select>{" "}
      </td>{" "}
      <td className="text-center text-nowrape">
        <Link to={`/wsh-user-details/${item?.id}`} className="btn p-1">
          {" "}
          <i className="fas fa-eye"> </i>
        </Link>
        <Link
          to={`/edit-whs-user/${item?.id}`}
          className={`btn p-1 btn-link btn-link-deep-blue font-weight-bold ${
            !read ? "" : "d-none"
          }`}
        >
          {" "}
          <i class="far fa-edit"> </i>
        </Link>
      </td>{" "}
    </tr>
  );
};

export default WHSUserList;
