import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reviewDeleteById } from "../../store/action/reviewAction";

const ReviewList = ({ item, index }) => {
  const dispatch = useDispatch();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);

  const deleteReview = () => {
    dispatch(reviewDeleteById(item.id));
  };

  return (
    <tr>
      <td className="text-center py-2"> {index} </td>{" "}
      <td className="text-nowrap">
        <span className="text-capitalize">
          {" "}
          {item?.user?.firstName + " " + item?.user?.lastName}{" "}
        </span>{" "}
      </td>{" "}
      <td className="text-nowrap"> {item.warehouse?.warehouseName} </td>{" "}
      <td className="text-nowrap">
        <Link to={`/review/${item.id}`}>
          <i className="fa fa-eye"> </i>{" "}
        </Link>{" "}
      </td>{" "}
      <td className="text-nowrap">
        <i
          onClick={deleteReview}
          class={`fas fa-trash-alt text-dark ${!read ? "" : "d-none"}`}
        ></i>{" "}
      </td>{" "}
    </tr>
  );
};

export default ReviewList;
