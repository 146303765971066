import React from "react";

const ExpandButton = () => {
  return (
    <div className="col-auto my-2 d-lg-none">
      <button
        className="btn btn-deep-blue px-0 size-40px toggle-class btn-sidebar-admin sidebar-admin-toggle align-items-center justify-content-center"
        type="button"
        data-target=".sidebar-admin-toggle"
        data-toggle-class="open"
      >
        <span> </span>{" "}
      </button>{" "}
    </div>
  );
};

export default ExpandButton;
