import {
    ENQUIRY,
    ENQUIRY_BY_ID,
    IS_ERROR,
    IS_LOADING
} from "./../types";

import axiosauth from "../../services/axios-auth";

export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}

export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function enquiryList(data) {
    return {
        type: ENQUIRY,
        payload: data,
    };
}

export function enquiry_By_Id(data) {
    return {
        type: ENQUIRY_BY_ID,
        payload: data,
    };
}

// ###########  Fecth All Warehouse List ########

export const enguiryByPage = (page, search, type = "pending") => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        let limit = 10;
        try {
            // eslint-disable-next-line
            axiosauth
                .get(
                    `/api/v1/enquiry/admin/enquiries/usertype/customer/status/${type}` +
                    `?page=${page}&limit=${limit}&search=${search}`
                )
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        dispatch(enquiryList(res));
                        dispatch(isLoading(false));
                    } else {
                        console.log("notification Fail");
                    }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};
export const enguiryByPageapproved = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        let limit = 10;
        try {
            // eslint-disable-next-line
            axiosauth
                .get(
                    "/api/v1/enquiry/admin/enquiries/usertype/customer/status/approved" +
                    `?page=${page}&limit=${limit}&search=${search}`
                )
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        dispatch(enquiryList(res));
                        dispatch(isLoading(false));
                    } else {
                        console.log("notification Fail");
                    }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};
export const enguiryByPage1 = (page) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        let limit = 10;
        try {
            // eslint-disable-next-line
            axiosauth
                .get(
                    "/api/v1/enquiry/admin/enquiries/usertype/vendor/status/pending" +
                    `?page=${page}&limit=${limit}`
                )
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        dispatch(enquiryList(res));
                        dispatch(isLoading(false));
                    } else {
                        console.log("notification Fail");
                    }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};
export const enguiryByPage1approve = (page) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        let limit = 10;
        try {
            // eslint-disable-next-line
            axiosauth
                .get(
                    "/api/v1/enquiry/admin/enquiries/usertype/vendor/status/approved" +
                    `?page=${page}&limit=${limit}`
                )
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        dispatch(enquiryList(res));
                        dispatch(isLoading(false));
                    } else {
                        console.log("notification Fail");
                    }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

// ###########  Fetch Warehouse By Id ############

export const enquiryById = (id, userType) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            // eslint-disable-next-line
            axiosauth
                .get(`/api/v1/enquiry/${id}/userType/${userType}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        dispatch(enquiry_By_Id(res.data));
                        dispatch(isLoading(false));
                    } else {
                        console.log("booking Details Fail");
                    }
                })
                .catch((error) => {
                    console.log("Fail--->", error.message);
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};