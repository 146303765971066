import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { whyById } from "../../store/action/whyAction";
import { useSelector, useDispatch } from "react-redux";
import { CardLoader } from "../../components/helper/CustomLoader";

const WhyDetail = () => {
  const history = useHistory();
  const { whyId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WHY_INFO);

  useEffect(() => {
    dispatch(whyById(parseInt(whyId)));
  }, [dispatch, whyId]);

  return (
    <LayoutOne>
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row align-items-center pb-3 px-3 mx-0">
          <div className="col-12 pb-2 pt-3">
            <h5 className="text-dark font-weight-bold">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-2 cursorPointer"
              >
                {" "}
              </i>{" "}
              Type Details
            </h5>
          </div>{" "}
          <div className="col-12">
            {data.whyDetail ? (
              <div className="row mx-0">
                <div className="col-12 bg-white rounded">
                  <div className="card card-body border-0">
                    <div className="d-flex">
                      <div className="mw-200px px-3 font-weight-bold py-2">
                        {" "}
                        Title{" "}
                      </div>{" "}
                      <div className="mw-300px px-3 py-2 text-dark">
                        {" "}
                        {data.whyDetail?.title}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="d-flex">
                      <div className="mw-200px px-3 font-weight-bold py-2">
                        {" "}
                        Description{" "}
                      </div>{" "}
                      <div className="mw-300px px-3 py-2 text-dark">
                        {" "}
                        {data.whyDetail?.description}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="d-flex">
                      <div className="mw-200px px-3 font-weight-bold py-2">
                        {" "}
                        Image{" "}
                      </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        <img
                          src={data.whyDetail?.image}
                          style={{
                            width: 200,
                            height: 150,
                          }}
                          alt="why"
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            ) : null}
            <div className="row pt-3">
              <div className="col-12 my-1">
                <button
                  onClick={() => history.goBack()}
                  className="btn py-2 btn-outline-deep-blue"
                >
                  {" "}
                  Back{" "}
                </button>
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default WhyDetail;
