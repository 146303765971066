import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updatePermit,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";

const PermitPendingForm = ({ warehouseId, viewMood }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return datestring;
  };

  const [yesForm, setYesForm] = useState({
    landlordYes: true,
    ownershipYes: true,
    cluYes: true,
    completionYes: true,
    occupancyYes: true,
    buildingInsuranceYes: true,
    buildingStabilityYes: true,
    FireNOCYes: true,
    floorLoadYes: true,
    approvedBuildingYes: true,
    gstRegistrationYes: true,
    panchayatNOCYes: true,
    panCardYes: true,
    electricityBillYes: true,
    shopCertificateYes: true,
    tradeLicenseYes: true,
    laborLicenseYes: true,
    factoryLicenseYes: true,
    fssaiLicenseYes: true,
    pollutionPollutionYes: true,
  });

  const {
    landlordYes,
    ownershipYes,
    cluYes,
    completionYes,
    occupancyYes,
    buildingInsuranceYes,
    buildingStabilityYes,
    FireNOCYes,
    floorLoadYes,
    approvedBuildingYes,
    gstRegistrationYes,
    panchayatNOCYes,
    panCardYes,
    electricityBillYes,
    shopCertificateYes,
    tradeLicenseYes,
    laborLicenseYes,
    factoryLicenseYes,
    fssaiLicenseYes,
    pollutionPollutionYes,
  } = yesForm;

  const [permitForm, setPermitForm] = useState({
    landlordFrom: "",
    landlordTill: "",
    landlordFile: "",
    landlordFileLink: "",
    ownershipFile: "",
    ownershipFileLink: "",
    cluFile: "",
    cluFileLink: "",
    completionFile: "",
    completionFileLink: "",
    occupancyFile: "",
    occupancyFileLink: "",

    buildingInsuranceFrom: "",
    buildingInsuranceTill: "",
    buildingInsuranceFile: "",
    buildingInsuranceFileLink: "",

    buildingStabilityFrom: "",
    buildingStabilityTill: "",
    buildingStabilityFile: "",
    buildingStabilityFileLink: "",

    FireNOCFrom: "",
    FireNOCTill: "",
    FireNOCFile: "",
    FireNOCFileLink: "",

    floorLoadFrom: "",
    floorLoadTill: "",
    floorLoadFile: "",
    floorLoadFileLink: "",

    approvedBuildingFile: "",
    approvedBuildingFileLink: "",

    photoFile: "",
    photoFileLink: "",

    profileFile: "",
    profileFileLink: "",

    gstRegistrationFile: "",
    gstRegistrationFileLink: "",

    panchayatNOCFrom: "",
    panchayatNOCTill: "",
    panchayatNOCFile: "",
    panchayatNOCFileLink: "",

    panCardFile: "",
    panCardFileLink: "",

    electricityBillFile: "",
    electricityBillFileLink: "",

    shopCertificateFile: "",
    shopCertificateFileLink: "",

    tradeLicenseFrom: "",
    tradeLicenseTill: "",
    tradeLicenseFile: "",
    tradeLicenseFileLink: "",

    laborLicenseFrom: "",
    laborLicenseTill: "",
    laborLicenseFile: "",
    laborLicenseFileLink: "",

    factoryLicenseFrom: "",
    factoryLicenseTill: "",
    factoryLicenseFile: "",
    factoryLicenseFileLink: "",

    fssaiLicenseFrom: "",
    fssaiLicenseTill: "",
    fssaiLicenseFile: "",
    fssaiLicenseFileLink: "",

    pollutionPollutionFrom: "",
    pollutionPollutionTill: "",
    pollutionPollutionFile: "",
    pollutionPollutionFileLink: "",
  });
  const {
    landlordFrom,
    landlordTill,
    landlordFileLink,
    ownershipFileLink,
    cluFileLink,
    completionFileLink,
    occupancyFileLink,

    buildingInsuranceFrom,
    buildingInsuranceTill,
    buildingInsuranceFileLink,

    buildingStabilityFrom,
    buildingStabilityTill,
    buildingStabilityFileLink,

    FireNOCFrom,
    FireNOCTill,
    FireNOCFileLink,

    floorLoadFrom,
    floorLoadTill,
    floorLoadFileLink,

    approvedBuildingFileLink,

    photoFileLink,

    profileFileLink,

    gstRegistrationFileLink,

    panchayatNOCFrom,
    panchayatNOCTill,
    panchayatNOCFileLink,

    panCardFileLink,

    electricityBillFileLink,

    shopCertificateFileLink,

    tradeLicenseFrom,
    tradeLicenseTill,
    tradeLicenseFileLink,

    laborLicenseFrom,
    laborLicenseTill,
    laborLicenseFileLink,

    factoryLicenseFrom,
    factoryLicenseTill,
    factoryLicenseFileLink,

    fssaiLicenseFrom,
    fssaiLicenseTill,
    fssaiLicenseFileLink,

    pollutionPollutionFrom,
    pollutionPollutionTill,
    pollutionPollutionFileLink,
  } = permitForm;
  const validationSchema = function (values) {
    return Yup.object().shape({
      tradePermit: Yup.object().shape({
        gstRegistrationCertificate: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        panCardOfCompany: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        electricityBill: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        shopAndEstablishmentCertificate: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        tradeRelatedPermit: Yup.array().of(
          Yup.object().shape({
            trade: Yup.object().shape({
              whsstatus: Yup.bool().oneOf(
                [true, false],
                "Please Select a option"
              ),
              // etc
            }),
          })
        ),
      }),

      buildingPermit: Yup.object().shape({
        ownershipDocument: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        cluCommercialWarehousing: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        completionCertificate: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        occupancyCertificate: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),

        approvedBuildingLayout: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),

        warehousePhotoGallery: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),

        warehouseProfilePic: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        buildingRelatedPermit: Yup.array().of(
          Yup.object().shape({
            building: Yup.object().shape({
              whsstatus: Yup.bool().oneOf(
                [true, false],
                "Please Select a option"
              ),
              // etc
            }),
          })
        ),
      }),
    });
  };
  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        console.log("permitchodu", getErrorsFromValidationError(error));
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };
  const onSubmit = (fields) => {
    const payload = {
      data: {
        permitsRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },

        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updatePermit(payload));
  };
  const [remarkForm, setRemarkForm] = useState({
    tradePermit: {
      gstRegistrationCertificate: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      panCardOfCompany: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      electricityBill: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      shopAndEstablishmentCertificate: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      tradeRelatedPermit: [
        {
          type: "Panchayat / Municipal NOC",
          trade: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "Trade License",
          trade: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "Labor License",
          trade: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "Factory License (for VAS activities)",
          trade: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "FSSAI License",
          trade: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "Pollution Under Control(PUC)",
          trade: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
      ],
    },
    buildingPermit: {
      ownershipDocument: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      cluCommercialWarehousing: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      completionCertificate: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      occupancyCertificate: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },

      approvedBuildingLayout: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      warehousePhotoGallery: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      warehouseProfilePic: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      buildingRelatedPermit: [
        {
          type: "Copy Of Lease Agreement With Landlord",
          building: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "Building Insurance",
          building: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "Building Stability Certificate",
          building: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "Fire NOC",
          building: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
        {
          type: "Floor Load Bearing Capacity Certificate",
          building: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        },
      ],
    },
  });

  useEffect(() => {
    if (data.singleFormData && data.type === "buildingTradeRelatedPermit") {
      if (
        data.singleFormData.buildingTradeRelatedPermitInfo &&
        data.singleFormData.buildingTradeRelatedPermitInfo
      ) {
        let building = data.singleFormData.buildingTradeRelatedPermitInfo
          ? data.singleFormData.buildingTradeRelatedPermitInfo
              .buildingRelatedPermit
          : "";
        let trade = data.singleFormData.buildingTradeRelatedPermitInfo
          ? data.singleFormData.buildingTradeRelatedPermitInfo
              .tradeRelatedPermit
          : "";

        let landlord = building.buildingRelatedPermitInfo.find(
          (o) => o.type === "copyOfLeaseAgreement"
        );
        let bi = building.buildingRelatedPermitInfo.find(
          (o) => o.type === "buildingInsurance"
        );
        let bs = building.buildingRelatedPermitInfo.find(
          (o) => o.type === "buildingStabilityCertificate"
        );
        let firenoc = building.buildingRelatedPermitInfo.find(
          (o) => o.type === "fireNoc"
        );
        let floor = building.buildingRelatedPermitInfo.find(
          (o) => o.type === "floorLoadBearingCapacityCertificate"
        );

        let panch = trade.tradeRelatedPermitInfo.find(
          (o) => o.type === "panchayatMunicipalNOC"
        );
        let labor = trade.tradeRelatedPermitInfo.find(
          (o) => o.type === "laborLicense"
        );
        let trad = trade.tradeRelatedPermitInfo.find(
          (o) => o.type === "tradeLicense"
        );
        let fact = trade.tradeRelatedPermitInfo.find(
          (o) => o.type === "factoryLicense"
        );
        let fsli = trade.tradeRelatedPermitInfo.find(
          (o) => o.type === "fssaiLicense"
        );
        let puc = trade.tradeRelatedPermitInfo.find((o) => o.type === "puc");

        setPermitForm({
          landlordFrom: landlord ? landlord.validForm : "",
          landlordTill: landlord ? landlord.validTill : "",
          landlordFile: landlord ? landlord.filePath : "",
          landlordFileLink: landlord ? landlord.filePath : "",

          ownershipFile: building ? building.ownershipDocument : "",
          ownershipFileLink: building ? building.ownershipDocument : "",

          cluFile: building ? building.cluCommercialWarehousing : "",
          cluFileLink: building ? building.cluCommercialWarehousing : "",

          completionFile: building ? building.completionCertificate : "",
          completionFileLink: building ? building.completionCertificate : "",

          occupancyFile: building ? building.occupancyCertificate : "",
          occupancyFileLink: building ? building.occupancyCertificate : "",

          buildingInsuranceFrom: bi ? bi.validForm : "",
          buildingInsuranceTill: bi ? bi.validTill : "",
          buildingInsuranceFile: bi ? bi.filePath : "",
          buildingInsuranceFileLink: bi ? bi.filePath : "",

          buildingStabilityFrom: bs ? bs.validForm : "",
          buildingStabilityTill: bs ? bs.validTill : "",
          buildingStabilityFile: bs ? bs.filePath : "",
          buildingStabilityFileLink: bs ? bs.filePath : "",

          FireNOCFrom: firenoc ? firenoc.validForm : "",
          FireNOCTill: firenoc ? firenoc.validTill : "",
          FireNOCFile: firenoc ? firenoc.filePath : "",
          FireNOCFileLink: firenoc ? firenoc.filePath : "",

          floorLoadFrom: floor ? floor.validForm : "",
          floorLoadTill: floor ? floor.validTill : "",
          floorLoadFile: floor ? floor.filePath : "",
          floorLoadFileLink: floor ? floor.filePath : "",

          approvedBuildingFile: building ? building.approvedBuildingLayout : "",
          approvedBuildingFileLink: building
            ? building.approvedBuildingLayout
            : "",

          photoFile: building ? building.warehousePhotoGallery : "",
          photoFileLink: building ? building.warehousePhotoGallery : "",

          profileFile: building ? building.warehouseProfilePic : "",
          profileFileLink: building ? building.warehouseProfilePic : "",

          gstRegistrationFile: trade ? trade.gstRegistrationCertificate : "",
          gstRegistrationFileLink: trade
            ? trade.gstRegistrationCertificate
            : "",

          panchayatNOCFrom: panch ? panch.validForm : "",
          panchayatNOCTill: panch ? panch.validTill : "",
          panchayatNOCFile: panch ? panch.filePath : "",
          panchayatNOCFileLink: panch ? panch.filePath : "",

          panCardFile: trade ? trade.panCardOfCompany : "",
          panCardFileLink: trade ? trade.panCardOfCompany : "",

          electricityBillFile: trade ? trade.electricityBill : "",
          electricityBillFileLink: trade ? trade.electricityBill : "",

          shopCertificateFile: trade
            ? trade.shopAndEstablishmentCertificate
            : "",
          shopCertificateFileLink: trade
            ? trade.shopAndEstablishmentCertificate
            : "",

          tradeLicenseFrom: trad ? trad.validForm : "",
          tradeLicenseTill: trad ? trad.validTill : "",
          tradeLicenseFile: trad ? trad.filePath : "",
          tradeLicenseFileLink: trad ? trad.filePath : "",

          laborLicenseFrom: labor ? labor.validForm : "",
          laborLicenseTill: labor ? labor.validTill : "",
          laborLicenseFile: labor ? labor.filePath : "",
          laborLicenseFileLink: labor ? labor.filePath : "",

          factoryLicenseFrom: fact ? fact.validForm : "",
          factoryLicenseTill: fact ? fact.validTill : "",
          factoryLicenseFile: fact ? fact.filePath : "",
          factoryLicenseFileLink: fact ? fact.filePath : "",

          fssaiLicenseFrom: fsli ? fsli.validForm : "",
          fssaiLicenseTill: fsli ? fsli.validTill : "",
          fssaiLicenseFile: fsli ? fsli.filePath : "",
          fssaiLicenseFileLink: fsli ? fsli.filePath : "",

          pollutionPollutionFrom: puc ? puc.validForm : "",
          pollutionPollutionTill: puc ? puc.validTill : "",
          pollutionPollutionFile: puc ? puc.filePath : "",
          pollutionPollutionFileLink: puc ? puc.filePath : "",
        });
        console.log("beti", landlord);
        setYesForm({
          landlordYes: landlord.validForm ? true : false,
          ownershipYes: building.ownershipDocument ? true : false,
          cluYes: building.cluCommercialWarehousing ? true : false,
          completionYes: building.completionCertificate ? true : false,
          occupancyYes: building.occupancyCertificate ? true : false,
          buildingInsuranceYes: bs.validForm ? true : false,
          buildingStabilityYes: bi.validForm ? true : false,
          FireNOCYes: firenoc.validForm ? true : false,
          floorLoadYes: floor.validForm ? true : false,
          approvedBuildingYes: building.approvedBuildingLayout ? true : false,
          gstRegistrationYes: trade.gstRegistrationCertificate ? true : false,
          panchayatNOCYes: panch.validForm ? true : false,
          panCardYes: trade.panCardOfCompany ? true : false,
          electricityBillYes: trade.electricityBill ? true : false,
          shopCertificateYes: trade.shopAndEstablishmentCertificate
            ? true
            : false,
          tradeLicenseYes: trad.validForm ? true : false,
          laborLicenseYes: labor.validForm ? true : false,
          factoryLicenseYes: fact.validForm ? true : false,
          fssaiLicenseYes: fsli.validForm ? true : false,
          pollutionPollutionYes: puc.validForm ? true : false,
        });

        if (data.singleFormData.permitsRemark) {
          setRemarkForm({
            tradePermit: {
              gstRegistrationCertificate: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .gstRegistrationCertificate.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .gstRegistrationCertificate.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .gstRegistrationCertificate.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .gstRegistrationCertificate.faremark
                  : "",
              },
              panCardOfCompany: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .panCardOfCompany.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .panCardOfCompany.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .panCardOfCompany.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .panCardOfCompany.faremark
                  : "",
              },
              electricityBill: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .electricityBill.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .electricityBill.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .electricityBill.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .electricityBill.faremark
                  : "",
              },
              shopAndEstablishmentCertificate: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .shopAndEstablishmentCertificate.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .shopAndEstablishmentCertificate.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .shopAndEstablishmentCertificate.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.tradePermit
                      .shopAndEstablishmentCertificate.faremark
                  : "",
              },
              tradeRelatedPermit: [
                {
                  type: "Panchayat / Municipal NOC",
                  trade: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[0]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[0].trade.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[0]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[0].trade.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[0]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[0].trade.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[0]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[0].trade.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "Trade License",
                  trade: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[1]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[1].trade.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[1]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[1].trade.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[1]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[1].trade.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[1]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[1].trade.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "Labor License",
                  trade: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[2]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[2].trade.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[2]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[2].trade.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[2]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[2].trade.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[2]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[2].trade.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "Factory License (for VAS activities)",
                  trade: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[3]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[3].trade.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[3]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[3].trade.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[3]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[3].trade.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[3]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[3].trade.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "FSSAI License",
                  trade: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[4]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[4].trade.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[4]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[4].trade.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[4]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[4].trade.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[4]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[4].trade.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "Pollution Under Control(PUC)",
                  trade: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[5]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[5].trade.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[5]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[5].trade.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[5]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[5].trade.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.tradePermit
                          .tradeRelatedPermit[5]
                        ? data.singleFormData.permitsRemark.tradePermit
                            .tradeRelatedPermit[5].trade.faremark
                        : ""
                      : "",
                  },
                },
              ],
            },
            buildingPermit: {
              ownershipDocument: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .ownershipDocument.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .ownershipDocument.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .ownershipDocument.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .ownershipDocument.faremark
                  : "",
              },
              cluCommercialWarehousing: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .cluCommercialWarehousing.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .cluCommercialWarehousing.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .cluCommercialWarehousing.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .cluCommercialWarehousing.faremark
                  : "",
              },
              completionCertificate: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .completionCertificate.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .completionCertificate.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .completionCertificate.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .completionCertificate.faremark
                  : "",
              },
              occupancyCertificate: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .occupancyCertificate.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .occupancyCertificate.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .occupancyCertificate.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .occupancyCertificate.faremark
                  : "",
              },

              approvedBuildingLayout: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .approvedBuildingLayout.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .approvedBuildingLayout.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .approvedBuildingLayout.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .approvedBuildingLayout.faremark
                  : "",
              },
              warehousePhotoGallery: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .warehousePhotoGallery.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .warehousePhotoGallery.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .warehousePhotoGallery.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .warehousePhotoGallery.faremark
                  : "",
              },
              warehouseProfilePic: {
                value: "",

                whsstatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .warehouseProfilePic.whsstatus
                  : "",
                whsremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .warehouseProfilePic.whsremark
                  : "",
                fastatus: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .warehouseProfilePic.fastatus
                  : "",
                faremark: data.singleFormData.permitsRemark
                  ? data.singleFormData.permitsRemark.buildingPermit
                      .warehouseProfilePic.faremark
                  : "",
              },
              buildingRelatedPermit: [
                {
                  type: "Copy Of Lease Agreement With Landlord",
                  building: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[0]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[0].building.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[0]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[0].building.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[0]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[0].building.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[0]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[0].building.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "Building Insurance",
                  building: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[1]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[1].building.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[1]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[1].building.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[1]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[1].building.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[1]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[1].building.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "Building Stability Certificate",
                  building: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[2]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[2].building.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[2]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[2].building.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[2]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[2].building.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[2]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[2].building.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "Fire NOC",
                  building: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[3]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[3].building.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[3]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[3].building.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[3]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[3].building.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[3]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[3].building.faremark
                        : ""
                      : "",
                  },
                },
                {
                  type: "Floor Load Bearing Capacity Certificate",
                  building: {
                    value: "",

                    whsstatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[4]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[4].building.whsstatus
                        : ""
                      : "",
                    whsremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[4]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[4].building.whsremark
                        : ""
                      : "",
                    fastatus: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[4]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[4].building.fastatus
                        : ""
                      : "",
                    faremark: data.singleFormData.permitsRemark
                      ? data.singleFormData.permitsRemark.buildingPermit
                          .buildingRelatedPermit[4]
                        ? data.singleFormData.permitsRemark.buildingPermit
                            .buildingRelatedPermit[4].building.faremark
                        : ""
                      : "",
                  },
                },
              ],
            },
          });
        } else {
          setRemarkForm({
            tradePermit: {
              gstRegistrationCertificate: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              panCardOfCompany: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              electricityBill: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              shopAndEstablishmentCertificate: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              tradeRelatedPermit: [
                {
                  type: "Panchayat / Municipal NOC",
                  trade: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "Trade License",
                  trade: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "Labor License",
                  trade: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "Factory License (for VAS activities)",
                  trade: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "FSSAI License",
                  trade: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "Pollution Under Control(PUC)",
                  trade: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
              ],
            },
            buildingPermit: {
              ownershipDocument: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              cluCommercialWarehousing: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              completionCertificate: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              occupancyCertificate: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },

              approvedBuildingLayout: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              warehousePhotoGallery: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              warehouseProfilePic: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              buildingRelatedPermit: [
                {
                  type: "Copy Of Lease Agreement With Landlord",
                  building: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "Building Insurance",
                  building: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "Building Stability Certificate",
                  building: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "Fire NOC",
                  building: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
                {
                  type: "Floor Load Bearing Capacity Certificate",
                  building: {
                    value: "",

                    whsstatus: "null",
                    whsremark: "",
                    fastatus: "null",
                    faremark: "",
                  },
                },
              ],
            },
          });
        }
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-12 rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12">
                    {" "}
                    <h6 className="py-3 mb-3 border-bottom">
                      {" "}
                      Building Related{" "}
                    </h6>
                  </div>
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Copy Of Lease Agreement With Landlord:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {landlordYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(landlordFrom)}
                              className="form-control px-4 mb-2"
                              placeholder="valid From"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(landlordTill)}
                              className="form-control px-4 mb-2"
                              placeholder="valid To"
                              disabled
                            />
                          </td>{" "}
                          {landlordFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={landlordFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(landlordFileLink).then((response) => {
                                      response.blob().then((blob) => {
                                        let url =
                                          window.URL.createObjectURL(blob);
                                        let a = document.createElement("a");
                                        a.href = url;
                                        a.download = "landlordFile";
                                        a.click();
                                      });
                                      //window.location.href = response.url;
                                    });
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[0].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                id={"aaaaaaaaa"}
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[0].building
                                        .whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[0].building.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="aaaaaaaaa"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[0].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="fjfjfj"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[0].building
                                        .whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[0].building.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="fjfjfj"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.buildingRelatedPermit[0].building.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit.buildingRelatedPermit
                                ? values.buildingPermit.buildingRelatedPermit[0]
                                    .building.fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit.buildingRelatedPermit
                                  ? values.buildingPermit
                                      .buildingRelatedPermit[0].building
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Ownership Document Copy:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {ownershipYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {ownershipFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={ownershipFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(ownershipFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "ownershipFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.ownershipDocument.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccc"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.ownershipDocument
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.ownershipDocument.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccc"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.ownershipDocument.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccc"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.ownershipDocument
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.ownershipDocument.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccc"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.ownershipDocument.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit
                                ? values.buildingPermit.ownershipDocument
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit
                                  ? values.buildingPermit.ownershipDocument
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              CLU-Commercial / Warehousing:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {cluYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {cluFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={cluFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(cluFileLink).then((response) => {
                                      response.blob().then((blob) => {
                                        let url =
                                          window.URL.createObjectURL(blob);
                                        let a = document.createElement("a");
                                        a.href = url;
                                        a.download = "cluFile";
                                        a.click();
                                      });
                                      //window.location.href = response.url;
                                    });
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.cluCommercialWarehousing.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="ccccc"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.cluCommercialWarehousing
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.cluCommercialWarehousing.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ccccc"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.cluCommercialWarehousing.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nccccc"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.cluCommercialWarehousing
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.cluCommercialWarehousing.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nccccc"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.cluCommercialWarehousing.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit
                                ? values.buildingPermit.cluCommercialWarehousing
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit
                                  ? values.buildingPermit
                                      .cluCommercialWarehousing.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Completion Certificate:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {completionYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {completionFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={completionFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(completionFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "completionFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.completionCertificate.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="ccccccc"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.completionCertificate
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.completionCertificate.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ccccccc"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.completionCertificate.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nccccccc"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.completionCertificate
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.completionCertificate.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nccccccc"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.completionCertificate.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit
                                ? values.buildingPermit.completionCertificate
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit
                                  ? values.buildingPermit.completionCertificate
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Occupancy Certificate:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {occupancyYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {occupancyFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={occupancyFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(occupancyFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "occupancyFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.occupancyCertificate.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="ccccccccc"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.occupancyCertificate
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.occupancyCertificate.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ccccccccc"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.occupancyCertificate.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nccccccccc"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.occupancyCertificate
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.occupancyCertificate.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nccccccccc"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.occupancyCertificate.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit
                                ? values.buildingPermit.occupancyCertificate
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit
                                  ? values.buildingPermit.occupancyCertificate
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Building Insurance: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {buildingInsuranceYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(buildingInsuranceFrom)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(buildingInsuranceTill)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {buildingInsuranceFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={buildingInsuranceFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(buildingInsuranceFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "buildingInsuranceFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[1].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="ccccccccccc"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[1].building
                                        .whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[1].building.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ccccccccccc"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[1].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nccccccccccc"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[1].building
                                        .whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[1].building.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nccccccccccc"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.buildingRelatedPermit[1].building.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit.buildingRelatedPermit
                                ? values.buildingPermit.buildingRelatedPermit[1]
                                    .building.fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit.buildingRelatedPermit
                                  ? values.buildingPermit
                                      .buildingRelatedPermit[1].building
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Building Stability Certificate:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {buildingStabilityYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(buildingStabilityFrom)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(buildingStabilityTill)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {buildingStabilityFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={buildingStabilityFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(buildingStabilityFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "buildingStabilityFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[2].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="ccccccccccccc"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[2].building
                                        .whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[2].building.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ccccccccccccc"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[2].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nccccccccccccc"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[2].building
                                        .whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[2].building.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nccccccccccccc"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.buildingRelatedPermit[2].building.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit.buildingRelatedPermit
                                ? values.buildingPermit.buildingRelatedPermit[2]
                                    .building.fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit.buildingRelatedPermit
                                  ? values.buildingPermit
                                      .buildingRelatedPermit[2].building
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Fire NOC: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {FireNOCYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(FireNOCFrom)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(FireNOCTill)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {FireNOCFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={FireNOCFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(FireNOCFileLink).then((response) => {
                                      response.blob().then((blob) => {
                                        let url =
                                          window.URL.createObjectURL(blob);
                                        let a = document.createElement("a");
                                        a.href = url;
                                        a.download = "FireNOCFile";
                                        a.click();
                                      });
                                      //window.location.href = response.url;
                                    });
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[3].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="ccccccccccccccc"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[3].building
                                        .whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[3].building.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ccccccccccccccc"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[3].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nccccccccccccccc"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[3].building
                                        .whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[3].building.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nccccccccccccccc"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.buildingRelatedPermit[3].building.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit.buildingRelatedPermit
                                ? values.buildingPermit.buildingRelatedPermit[3]
                                    .building.fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit.buildingRelatedPermit
                                  ? values.buildingPermit
                                      .buildingRelatedPermit[3].building
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Floor Load Bearing Capacity Certificate:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {floorLoadYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(floorLoadFrom)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(floorLoadTill)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {floorLoadFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={floorLoadFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(floorLoadFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "floorLoadFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[4].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="ccccccccccccccccc"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[4].building
                                        .whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[4].building.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ccccccccccccccccc"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.buildingRelatedPermit[4].building.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nccccccccccccccccccc"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.buildingRelatedPermit
                                    ? values.buildingPermit
                                        .buildingRelatedPermit[4].building
                                        .whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.buildingRelatedPermit[4].building.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nccccccccccccccccccc"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.buildingRelatedPermit[4].building.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit.buildingRelatedPermit
                                ? values.buildingPermit.buildingRelatedPermit[4]
                                    .building.fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit.buildingRelatedPermit
                                  ? values.buildingPermit
                                      .buildingRelatedPermit[4].building
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Approved Building Layout Copy:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {approvedBuildingYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {approvedBuildingFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={approvedBuildingFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(approvedBuildingFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "approvedBuildingFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.approvedBuildingLayout.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccdd"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.approvedBuildingLayout
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.approvedBuildingLayout.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccdd"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.approvedBuildingLayout.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccdd"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.approvedBuildingLayout
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.approvedBuildingLayout.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccdd"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.approvedBuildingLayout.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit
                                ? values.buildingPermit.approvedBuildingLayout
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit
                                  ? values.buildingPermit.approvedBuildingLayout
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Warehouse Photo Gallery:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-2 mb-0">
                            <div className="form-check common-radio-deep-blue mx-3"></div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3"></div>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {photoFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={photoFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(photoFileLink).then((response) => {
                                      response.blob().then((blob) => {
                                        let url =
                                          window.URL.createObjectURL(blob);
                                        let a = document.createElement("a");
                                        a.href = url;
                                        a.download = "photo";
                                        a.click();
                                      });
                                      //window.location.href = response.url;
                                    });
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.warehousePhotoGallery.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddee"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.warehousePhotoGallery
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.warehousePhotoGallery.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddee"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.warehousePhotoGallery.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddee"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.warehousePhotoGallery
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.warehousePhotoGallery.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddee"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.warehousePhotoGallery.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit
                                ? values.buildingPermit.warehousePhotoGallery
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit
                                  ? values.buildingPermit.warehousePhotoGallery
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Warehouse Profile Pic:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-2 mb-0">
                            <div className="form-check common-radio-deep-blue mx-3"></div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3"></div>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {profileFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={profileFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(profileFileLink).then((response) => {
                                      response.blob().then((blob) => {
                                        let url =
                                          window.URL.createObjectURL(blob);
                                        let a = document.createElement("a");
                                        a.href = url;
                                        a.download = "profileFile";
                                        a.click();
                                      });
                                      //window.location.href = response.url;
                                    });
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.warehouseProfilePic.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeff"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.warehouseProfilePic
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.warehouseProfilePic.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeff"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="buildingPermit.warehouseProfilePic.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeff"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.buildingPermit.warehouseProfilePic
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "buildingPermit.warehouseProfilePic.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeff"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`buildingPermit.warehouseProfilePic.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.buildingPermit
                                ? values.buildingPermit.warehouseProfilePic
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.buildingPermit
                                  ? values.buildingPermit.warehouseProfilePic
                                      .faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>
                    </table>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="row">
                  <div className="col-12">
                    {" "}
                    <h6 className="py-3 mb-3 border-bottom"> Trade Related </h6>
                  </div>
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              GST Registration Certificate:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {gstRegistrationYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {gstRegistrationFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={gstRegistrationFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(gstRegistrationFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "gstRegistrationFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.gstRegistrationCertificate.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgg"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.gstRegistrationCertificate
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.gstRegistrationCertificate.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgg"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.gstRegistrationCertificate.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgg"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.gstRegistrationCertificate
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.gstRegistrationCertificate.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgg"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              name={`tradePermit.gstRegistrationCertificate.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit
                                ? values.tradePermit.gstRegistrationCertificate
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit
                                  ? values.tradePermit
                                      .gstRegistrationCertificate.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Panchayat / Municipal NOC:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {panchayatNOCYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              value={readableDate(panchayatNOCFrom)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              value={readableDate(panchayatNOCTill)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {panchayatNOCFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={panchayatNOCFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(panchayatNOCFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "panchayatNOCFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[0].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghh"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[0]
                                        .trade.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[0].trade.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghh"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[0].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghh"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[0]
                                        .trade.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[0].trade.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghh"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`tradePermit.tradeRelatedPermit[0].trade.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit.tradeRelatedPermit
                                ? values.tradePermit.tradeRelatedPermit[0].trade
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit.tradeRelatedPermit
                                  ? values.tradePermit.tradeRelatedPermit[0]
                                      .trade.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              PAN Card copy of Company:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {panCardYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {panCardFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={panCardFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(panCardFileLink).then((response) => {
                                      response.blob().then((blob) => {
                                        let url =
                                          window.URL.createObjectURL(blob);
                                        let a = document.createElement("a");
                                        a.href = url;
                                        a.download = "panCardFile";
                                        a.click();
                                      });
                                      //window.location.href = response.url;
                                    });
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.panCardOfCompany.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghhbb"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.panCardOfCompany
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.panCardOfCompany.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghhbb"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.panCardOfCompany.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghhbb"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.panCardOfCompany
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.panCardOfCompany.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghhbb"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              name={`tradePermit.panCardOfCompany.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit
                                ? values.tradePermit.panCardOfCompany
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit
                                  ? values.tradePermit.panCardOfCompany.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Electricity Bill Copy:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {electricityBillYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {electricityBillFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={electricityBillFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(electricityBillFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "electricityBillFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.electricityBill.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghhii"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.electricityBill
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.electricityBill.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghhii"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.electricityBill.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghhii"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.electricityBill
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.electricityBill.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghhii"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`tradePermit.electricityBill.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit
                                ? values.tradePermit.electricityBill
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit
                                  ? values.tradePermit.electricityBill.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Shop & amp; Establishment Certificate:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {shopCertificateYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4"></td> <td className="pb-4"></td>{" "}
                          {shopCertificateFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={shopCertificateFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(shopCertificateFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "shopCertificateFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.shopAndEstablishmentCertificate.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghhiijj"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit
                                    .shopAndEstablishmentCertificate
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.shopAndEstablishmentCertificate.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghhiijj"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.shopAndEstablishmentCertificate.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghhiijj"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit
                                    .shopAndEstablishmentCertificate
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.shopAndEstablishmentCertificate.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghhiijj"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`tradePermit.shopAndEstablishmentCertificate.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit
                                ? values.tradePermit
                                    .shopAndEstablishmentCertificate
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit
                                  ? values.tradePermit
                                      .shopAndEstablishmentCertificate.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Trade License: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {tradeLicenseYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              value={readableDate(tradeLicenseFrom)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              value={readableDate(tradeLicenseTill)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {tradeLicenseFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={tradeLicenseFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(tradeLicenseFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "tradeLicenseFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[1].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghhzz"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[1]
                                        .trade.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[1].trade.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghhzz"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[1].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghhzz"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[1]
                                        .trade.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[1].trade.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghhzz"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`tradePermit.tradeRelatedPermit[1].trade.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit.tradeRelatedPermit
                                ? values.tradePermit.tradeRelatedPermit[1].trade
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit.tradeRelatedPermit
                                  ? values.tradePermit.tradeRelatedPermit[1]
                                      .trade.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Labor License: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {laborLicenseYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              value={readableDate(laborLicenseFrom)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              value={readableDate(laborLicenseTill)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {laborLicenseFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={laborLicenseFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(laborLicenseFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "laborLicenseFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[2].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghhzzaa"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[2]
                                        .trade.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[2].trade.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghhzzaa"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[2].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghhzzaa"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[2]
                                        .trade.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[2].trade.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghhzzaa"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`tradePermit.tradeRelatedPermit[2].trade.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit.tradeRelatedPermit
                                ? values.tradePermit.tradeRelatedPermit[2].trade
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit.tradeRelatedPermit
                                  ? values.tradePermit.tradeRelatedPermit[2]
                                      .trade.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Factory License( for VAS activities):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {factoryLicenseYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              value={readableDate(factoryLicenseFrom)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              value={readableDate(factoryLicenseTill)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {factoryLicenseFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={factoryLicenseFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(factoryLicenseFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "factoryLicenseFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[3].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghhzzaaa"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[3]
                                        .trade.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[3].trade.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghhzzaaa"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[3].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghhzzaaa"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[3]
                                        .trade.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[3].trade.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghhzzaaa"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`tradePermit.tradeRelatedPermit[3].trade.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit.tradeRelatedPermit
                                ? values.tradePermit.tradeRelatedPermit[3].trade
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit.tradeRelatedPermit
                                  ? values.tradePermit.tradeRelatedPermit[3]
                                      .trade.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> FSSAI License: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {fssaiLicenseYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(fssaiLicenseFrom)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(fssaiLicenseTill)}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {fssaiLicenseFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={fssaiLicenseFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(fssaiLicenseFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "fssaiLicenseFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[4].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghhzzaaaa"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[4]
                                        .trade.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[4].trade.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghhzzaaaa"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[4].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghhzzaaaa"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[4]
                                        .trade.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[4].trade.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghhzzaaaa"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`tradePermit.tradeRelatedPermit[4].trade.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit.tradeRelatedPermit
                                ? values.tradePermit.tradeRelatedPermit[4].trade
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit.tradeRelatedPermit
                                  ? values.tradePermit.tradeRelatedPermit[4]
                                      .trade.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Pollution Under Control(PUC):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {pollutionPollutionYes ? "Yes" : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              value={readableDate(pollutionPollutionFrom)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              value={readableDate(pollutionPollutionTill)}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          {pollutionPollutionFileLink !== "" ? (
                            <td className="text-center text-nowrape d-flex">
                              <div className="btn btn-group px-0 mt-1">
                                <a
                                  href={pollutionPollutionFileLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="btn px-3 py-2 btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-eye"> </i>
                                </a>{" "}
                                {/*  eslint-disable-next-line */}{" "}
                                <a
                                  onClick={() => {
                                    fetch(pollutionPollutionFileLink).then(
                                      (response) => {
                                        response.blob().then((blob) => {
                                          let url =
                                            window.URL.createObjectURL(blob);
                                          let a = document.createElement("a");
                                          a.href = url;
                                          a.download = "pollutionPollutionFile";
                                          a.click();
                                        });
                                        //window.location.href = response.url;
                                      }
                                    );
                                  }}
                                  className="btn px-3 py-2 btn-link btn-deep-blue"
                                  download=""
                                >
                                  {" "}
                                  <i className="fas fa-download"> </i>
                                </a>
                              </div>{" "}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[5].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="cccccccccccddeeffgghhzzaaaaa"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[5]
                                        .trade.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[5].trade.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="cccccccccccddeeffgghhzzaaaaa"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="tradePermit.tradeRelatedPermit[5].trade.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="ncccccccccccddeeffgghhzzaaaaa"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.tradePermit.tradeRelatedPermit
                                    ? values.tradePermit.tradeRelatedPermit[5]
                                        .trade.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "tradePermit.tradeRelatedPermit[5].trade.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ncccccccccccddeeffgghhzzaaaaa"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`tradePermit.tradeRelatedPermit[5].trade.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.tradePermit.tradeRelatedPermit
                                ? values.tradePermit.tradeRelatedPermit[5].trade
                                    .fastatus === true
                                  ? "okay"
                                  : "not okay"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.tradePermit.tradeRelatedPermit
                                  ? values.tradePermit.tradeRelatedPermit[5]
                                      .trade.faremark
                                  : ""
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>
                    </table>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              <div className={`col-12 mt-2`}>
                <div className="row justify-content-end mb-4">
                  <div className="col-auto ">
                    <button
                      type="submit"
                      className="btn btn-deep-blue add-class remove-class "
                      disabled={data.isPending}
                    >
                      Save{" "}
                      {data.isPending ? <Spinner animation="border" /> : null}{" "}
                    </button>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default PermitPendingForm;
