import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import OnBoardFormList from "../../wrapper/customerOnboarding/OnBoardFormList";
import { useSelector, useDispatch } from "react-redux";
import { getSubUserAssignOnboard } from "../../store/action/onBoardAction";
import { ItemNotFound } from "../../components/helper/CustomLoader";

const ManageContactOnboard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ON_BOARD_INFO);

  useEffect(() => {
    dispatch(getSubUserAssignOnboard("contactDetail"));
  }, [dispatch]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Customer Contact </title>{" "}
      </Helmet>

      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-auto d-lg-none">
          <button
            className="btn btn-deep-blue px-0 size-40px toggle-className btn-sidebar-admin sidebar-admin-toggle align-items-center justify-content-center"
            type="button"
            data-target=".sidebar-admin-toggle"
            data-toggle-class="open"
          >
            <span> </span>{" "}
          </button>{" "}
        </div>{" "}
        <div className="py-3 col">
          <div className="input-group admin-search prepend w-100">
            <div className="input-group-prepend">
              <span className="input-group-text bg-white">
                <button className="btn btn-lighter-blue p-0 size-30px">
                  {" "}
                  <i className="fas fa-search"> </i>
                </button>
              </span>{" "}
            </div>{" "}
            <input
              type="text"
              className="form-control h-100% toggle-className"
              placeholder="Search"
              data-target=".custom-search"
              data-toggle-class="open"
              data-event
            />
          </div>{" "}
        </div>
        <div className="col-12 px-0">
          <h5 className="text-dark"> Contact Onboarding </h5>{" "}
        </div>{" "}
        <div className="col-12 px-0">
          <div className="row align-items-center py-3 px-3 mx-0">
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-12 px-0 table-responsive table-gray-admin">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center"> Onboard ID </th>{" "}
                        <th className="text-center"> Warehouse ID </th>{" "}
                        <th> Company Name </th> <th> On Board Date </th>{" "}
                        <th> Email ID </th> <th> City </th> <th> Area </th>{" "}
                        <th className="text-center"> Action </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {data.userOnboardList && data.userOnboardList.length > 0
                        ? data.userOnboardList.map((item, index) => {
                            return (
                              <OnBoardFormList
                                formType="contactForm"
                                item={item}
                                key={index}
                              />
                            );
                          })
                        : null}
                    </tbody>{" "}
                  </table>
                  {data.userOnboardList &&
                  data.userOnboardList.length > 0 ? null : (
                    <ItemNotFound message="Data Not Found" />
                  )}{" "}
                </div>{" "}
              </div>{" "}
              <div className="row">
                <div className="col-12">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-center my-5">
                      <li className="page-item mx-2">
                        <a
                          className="page-link pagination-deep-blue rounded"
                          href="#dd"
                        >
                          {" "}
                          <i className="fas fa-chevron-left"> </i>
                        </a>
                      </li>{" "}
                      <li className="page-item mx-2">
                        <a
                          className="page-link pagination-deep-blue rounded"
                          href="#dd"
                        >
                          {" "}
                          <i className="fas fa-chevron-right"> </i>
                        </a>
                      </li>{" "}
                    </ul>{" "}
                  </nav>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default ManageContactOnboard;
