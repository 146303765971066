import {
  WHS_USER,
  WHS_USER_BY_ID,
  ROLE,
  ROLE_BY_ID,
  DEPARTMENT,
  DEPARTMENT_BY_ID,
  PERMISSION,
  PERMISSION_BY_ID,
  ROLE_PERMISSION,
  WHS_RESPONSE,
  IS_ERROR,
  IS_LOADING,
  IS_PENDING,
} from "./../types";

import // GET_WHS_URL,
// GET_WHS_BY_ID_URL,
// CREATE_WHS_URL,
// UPDATE_WHS_URL,

// GET_ROLE_URL,
// GET_ROLE_BY_ID_URL,
// CREATE_ROLE_URL,
// UPDATE_ROLE_URL,

// GET_DEPARTMENT_URL,
// GET_DEPARTMENT_BY_ID_URL,
// CREATE_DEPARTMENT_URL,
// UPDATE_DEPARTMENT_URL
"../../services/urls";

import axiosauth from "../../services/axios-auth";

export function isError(error) {
  return {
    type: IS_ERROR,
    isError: error,
  };
}

export function isLoading(bool) {
  return {
    type: IS_LOADING,
    isLoading: bool,
  };
}

export function isPending(bool) {
  return {
    type: IS_PENDING,
    isLoading: bool,
  };
}

export function whsUserList(data) {
  return {
    type: WHS_USER,
    payload: data,
  };
}

export function whsUser_By_Id(data) {
  return {
    type: WHS_USER_BY_ID,
    payload: data,
  };
}

export function roleList(data) {
  return {
    type: ROLE,
    payload: data,
  };
}

export function role_By_Id(data) {
  return {
    type: ROLE_BY_ID,
    payload: data,
  };
}

export function departmentList(data) {
  return {
    type: DEPARTMENT,
    payload: data,
  };
}

export function department_By_Id(data) {
  return {
    type: DEPARTMENT_BY_ID,
    payload: data,
  };
}

export function permissionList(data) {
  return {
    type: PERMISSION,
    payload: data,
  };
}

export function rolePermissionList(data) {
  return {
    type: ROLE_PERMISSION,
    payload: data,
  };
}

export function permission_By_Id(data) {
  return {
    type: PERMISSION_BY_ID,
    payload: data,
  };
}

export function responseWhs(data) {
  return {
    type: WHS_RESPONSE,
    payload: data,
  };
}

// ###########  Fecth All WHS List ########

export const whsByPage = (page, search) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .get(
          `/api/v1/accounts/${accountId}/users?page=${page}&limit=${10}&search=${search}`
        )
        .then((response) => {
          let res = JSON.parse(response.data);

          console.log("res------>", res);
          dispatch(whsUserList(res));
          dispatch(isLoading(false));
          // }
          // else {
          // console.log("whsUserList Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Fecth  WHS Detail By Id ########

export const whsById = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .get(`/api/v1/accounts/${accountId}/users/${id}`)
        .then((response) => {
          let res = JSON.parse(response.data);

          console.log("res====>", res);
          dispatch(whsUser_By_Id(res));
          dispatch(isLoading(false));
          // }
          // else {
          // console.log("booking_By_Id Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// Get Subuser By Department Id

// ###########  Fecth All WHS List ########

export const subUserByDepartmentId = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    // let account = JSON.parse(localStorage.getItem('userData'));
    //   let accountId = account?.account?.id

    try {
      axiosauth
        .get(`api/v1/accounts/1/users/department/${id}?page=1&limit=10`)
        .then((response) => {
          let res = JSON.parse(response.data);

          console.log("res------>", res);
          dispatch(whsUserList(res));
          dispatch(isLoading(false));
          // }
          // else {
          // console.log("whsUserList Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Create WHS USer  ########

export const createWhsUser = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(""));

    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .post(`/api/v1/accounts/${accountId}/users/`, data)
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(responseWhs(res));
          dispatch(isPending(false));
          // }
          // else {
          // console.log("Role Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Update WHS USer  ########

export const updateWhsUser = (id, data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(""));

    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .patch(`/api/v1/accounts/${accountId}/users/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(responseWhs(res));
          dispatch(isPending(false));
          // }
          // else {
          // console.log("Update whs warehouse Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Fecth All Role List ########

export const roleByPage = (isActive, page, search) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));
    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .get(
          `/api/v1/accounts/${accountId}/roles?isActive=${isActive}&page=${
            page ? page : 1
          }&limit=${10}&search=${search ? search : ""}`
        )
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(roleList(res.data));
          dispatch(isLoading(false));
          // }
          // else {
          // console.log("Role Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Fecth  Role Detail By Id ########

export const roleById = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));
    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .get(`/api/v1/accounts/${accountId}/roles/${id}`)
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(role_By_Id(res));
          dispatch(isLoading(false));
          // }
          // else {
          // console.log("role_By_Id Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Create Role  ########

export const createRole = (data) => {
  return async (dispatch) => {
    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;
    dispatch(isPending(true));
    dispatch(isError(""));
    try {
      axiosauth
        .post(`/api/v1/accounts/${accountId}/roles/`, data)
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(responseWhs(res));
          dispatch(isPending(false));
          // }
          // else {
          // console.log("Role Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Update Role  ########

export const updateRole = (id, data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));
    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;
    try {
      axiosauth
        .patch(`/api/v1/accounts/${accountId}/roles/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(responseWhs(res));
            dispatch(isLoading(false));
          } else {
            console.log("Role Fail");
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Fecth All Department List ########

export const departmentByPage = (isActive, page, search) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .get(
          `/api/v1/accounts/${accountId}/departments?isActive=${isActive}&page=${
            page ? page : 1
          }&limit=${10}&search=${search ? search : ""}`
        )
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(departmentList(res.data));
          dispatch(isLoading(false));
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Fecth  Department Detail By Id ########

export const departmentById = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .get(`/api/v1/accounts/${accountId}/departments/${id}`)
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(department_By_Id(res));
          dispatch(isLoading(false));
          // }
          // else {
          // console.log("department_By_Id Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Create Department  ########

export const createDepartment = (data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .post(`/api/v1/accounts/${accountId}/departments/`, data)
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(responseWhs(res));
          dispatch(isLoading(false));
          // }
          // else {
          // console.log("CREATE_DEPARTMENT_URL Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// ###########  Update Department  ########

export const updateDepartment = (id, data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));
    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .patch(`/api/v1/accounts/${accountId}/departments/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(responseWhs(res));
            dispatch(isLoading(false));
          } else {
            console.log("DEPARTMENT Fail");
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// Fetch Perminssion Module

export const rolePermissionByRoleId = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .get(`/api/v1/accounts/${accountId}/roles/${id}/maps?type=0`)
        .then((response) => {
          let res = JSON.parse(response.data);

          // console.log("resssssssssss--->", res)
          dispatch(rolePermissionList(res));
          dispatch(isLoading(false));
          // }
          // else {
          // console.log("Role Fail")
          // }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// When Status data created already

export const updatePermission = (id, data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(""));
    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .patch(`/api/v1/accounts/${accountId}/roles/${id}/maps/`, data)
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(responseWhs(res));
          dispatch(isPending(false));
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};

// When Status data created already

export const createPermission = (id, data) => {
  return async (dispatch) => {
    // dispatch(isPending(true))
    dispatch(isError(""));
    let account = JSON.parse(localStorage.getItem("userData"));
    let accountId = account?.account?.id;

    try {
      axiosauth
        .post(`/api/v1/accounts/${accountId}/roles/${id}/maps/`, data)
        .then((response) => {
          let res = JSON.parse(response.data);

          dispatch(responseWhs(res));
          // dispatch(isPending(false))
        })
        .catch((error) => {
          dispatch(isError(error.message));
          // dispatch(isPending(false))
        })
        .then(() => {
          console.log("-----always executes");
        });
    } catch (e) {}
  };
};
