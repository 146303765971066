import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  onlyNumberAllow,
  onlyAlphaNumericAllow,
} from "../../components/helper/reuse";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllWarehouseInvoice } from "../../store/action/warehouseAction";
import { bookingByPage } from "../../store/action/bookingAction";
import {
  createInvoice,
  responseInvoice,
} from "../../store/action/invoiceAction";
import { documentUpload } from "../../components/utils";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import { AiOutlineDownload } from "react-icons/ai";

const CreateUserInvoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.INVOICE_INFO);
  // const items = useSelector((state) => state.WAREHOUSEINFO);
  const booking = useSelector((state) => state.BOOKING_INFO);
  const [wh, setWh] = useState(null);

  // let filter = {
  //   filter: {
  //     status: "approved",
  //     type: "adminStatus",
  //   },
  // };

  let jsonData = {
    state: "",
    city: "",
    area: "",
    warehouse: "",
    booking: "",
    name: "",
    email: "",
    contactNumber: "",
    dueDate: "",
    invoiceDate: "",
    price: "",
    gst: "",
    userType: "user",
    invoiceCreatedBy: "Admin",
    billDescription: "",
    invoiceNumber: "",
    isGst: "False",
    gstCalculation: "",
    creditDays: "",
    paymentTerms: "",
    remarks: "",
    warehouseName: "",
  };

  useEffect(() => {
    dispatch(
      bookingByPage(
        parseInt(1),
        {
          filter: {
            type: "admin",
            status: "CONFIRMED",
          },
        },
        ""
      )
    );
  }, [dispatch]);

  useEffect(() => {
    let filter = {
      filter: {
        status: "approved",
        type: "adminStatus",
      },
    };
    dispatch(fetchAllWarehouseInvoice(1, filter));
  }, [dispatch]);

  // File Upload
  const [res, setRes] = useState(null);
  const [fileError, setFileError] = useState(null);
  const uploadDocs = async (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let urlData = await documentUpload(formData);
    console.log(urlData);
    setRes(urlData);
  };

  // Validation Schema
  const invoiceSchema = Yup.object().shape({
    // area: Yup.string().required("Required"),
    // warehouse: Yup.string().required("Required"),
    booking: Yup.string().required("Required"),
    // name: Yup.string().required("Required"),
    // email: Yup.string().required("Required"),
    // contactNumber: Yup.string()
    //   .required("Required")
    //   .max(10, "Contact Number Must be 10 Digit"),
    invoiceDate: Yup.string().required("Required"),
    dueDate: Yup.string().required("Required"),
    price: Yup.string().required("Required"),
    gst: Yup.string().required("Required"),
    // documentFile: Yup.string().required('Required'),
    billDescription: Yup.string().required("Required"),
    invoiceNumber: Yup.string().required("Required"),
    // gstCalculation: Yup.string().required("Required"),
    creditDays: Yup.string().required("Required"),
    paymentTerms: Yup.string().required("Required"),
  });

  const redirect = () => {
    dispatch(responseInvoice(null));
    history.replace("/user-invoice?page=1");
  };

  const getWarehouse = (id) => {
    const book = booking?.bookingList?.data.find(
      (item) => item?.id === parseInt(id)
    );
    setWh(book);
  };

  return (
    <LayoutOne>
      {" "}
      {data.invoiceResponse && data.invoiceResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={redirect}
          message={data.invoiceResponse.message}
        />
      ) : null}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            ></i>{" "}
            Create Invoice{" "}
          </h5>{" "}
        </div>
        <div className="col-12 bg-white p-3">
          <Formik
            enableReinitialize={true}
            validationSchema={invoiceSchema}
            initialValues={jsonData}
            onSubmit={(fields) => {
              console.log("fields-->", fields);
              fields["warehouse"] = wh?.warehouse?.id;
              if (res && res.url) {
                fields["documentFile"] = res.url;
                console.log("fields-->", fields);
                dispatch(createInvoice(fields));
              } else {
                setFileError("Choose file");
              }
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              if (values.booking !== "") {
                getWarehouse(values.booking);
              }
              return (
                <Form>
                  <div className="row">
                    {" "}
                    {/* ============================================================================ */}
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Booking Id{" "}
                      </label>{" "}
                      <Field
                        name="booking"
                        as="select"
                        className={
                          "form-control  bg-white px-4 common-select-deep-blue"
                        }
                        id="category"
                      >
                        <option value=""> Select Booked Warehouse </option>{" "}
                        {booking?.bookingList?.data?.length > 0 &&
                          booking?.bookingList?.data.map((item, index) => (
                            <option key={index} value={parseInt(item.id)}>
                              {" "}
                              {item.id}{" "}
                            </option>
                          ))}{" "}
                      </Field>{" "}
                      <span className="errorMsg">
                        {" "}
                        {errors.booking ? `Required` : ""}{" "}
                      </span>{" "}
                    </div>
                    <div className="form-group mb-4 async-control  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Warehouse Id{" "}
                      </label>

                      <input
                        value={wh?.warehouse?.warehouseId}
                        type="text"
                        className={`form-control form-control-md px-4`}
                        id="staticEmail"
                        placeholder="Type here"
                        readOnly
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        WH Location{" "}
                      </label>{" "}
                      <Field
                        name={`area`}
                        type="text"
                        className={
                          `form-control form-control-md px-4` +
                          (errors.area && touched.area ? " is-invalid" : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`area`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Warehouse Name{" "}
                      </label>

                      <Field
                        value={wh?.warehouse?.warehouseName}
                        type="text"
                        className={`form-control form-control-md px-4`}
                        id="staticEmail"
                        placeholder="Type here"
                        readOnly
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Bill Description{" "}
                      </label>{" "}
                      <Field
                        name={`billDescription`}
                        type="text"
                        className={
                          `form-control form-control-md px-4` +
                          (errors.billDescription && touched.billDescription
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`billDescription`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Invoice Number{" "}
                      </label>{" "}
                      <Field
                        name={`invoiceNumber`}
                        type="text"
                        className={
                          `form-control form-control-md px-4` +
                          (errors.invoiceNumber && touched.invoiceNumber
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`invoiceNumber`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Invoice Date{" "}
                      </label>{" "}
                      <Field
                        name={`invoiceDate`}
                        type="date"
                        className={
                          `form-control form-control-md px-4` +
                          (errors.invoiceDate && touched.invoiceDate
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`invoiceDate`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Is GST ?
                      </label>{" "}
                      <Field
                        name="isGst"
                        as="select"
                        className={
                          "form-control bg-white px-4 common-select-deep-blue"
                        }
                        id="isGst"
                      >
                        <option value={"False"}> No </option>{" "}
                        <option value={"True"}> Yes </option>{" "}
                      </Field>{" "}
                    </div>
                    {values.isGst === "True" && (
                      <div className="form-group mb-4  col-md-4 col-sm-6">
                        <label htmlFor="staticEmail" className="mb-2 pl-3">
                          GST Calculation{" "}
                        </label>

                        <Field
                          name={`gstCalculation`}
                          as="select"
                          className={
                            "form-control bg-white px-4 common-select-deep-blue"
                          }
                          id="category"
                        >
                          <option value={5}> 5 % </option>{" "}
                          <option value={12}> 12 % </option>{" "}
                          <option value={18}> 18 % </option>{" "}
                        </Field>

                        <ErrorMessage
                          name={`gstCalculation`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    )}
                    {/* <div className="form-group mb-4  col-md-4 col-sm-6">
                                            <label htmlFor="staticEmail" className="mb-2 pl-3">
                                              Company Name
                                            </label>
                                            <Field
                                              name={`name`}
                                              type="text"
                                              onKeyPress={(e) => onlyAlphaNumericSpaceAllow(e)}
                                              className={
                                                `form-control form-control-md px-4` +
                                                (errors.name && touched.name ? " is-invalid" : "")
                                              }
                                              id="staticEmail"
                                              placeholder="Type here"
                                            />
                                            <ErrorMessage
                                              name={`name`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div> */}{" "}
                    {/* <div className="form-group mb-4  col-md-4 col-sm-6">
                                            <label htmlFor="staticEmail" className="mb-2 pl-3">
                                              Email ID
                                            </label>
                                            <Field
                                              name={`email`}
                                              type="email"
                                              className={
                                                `form-control form-control-md px-4` +
                                                (errors.email && touched.email ? " is-invalid" : "")
                                              }
                                              id="staticEmail"
                                              placeholder="Type here"
                                            />
                                            <ErrorMessage
                                              name={`email`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div> */}{" "}
                    {/* <div className="form-group mb-4  col-md-4 col-sm-6">
                                            <label htmlFor="staticEmail" className="mb-2 pl-3">
                                              Contact Number
                                            </label>
                                            <Field
                                              name={`contactNumber`}
                                              type="number"
                                              onKeyPress={(e) => onlyNumberAllow(e)}
                                              className={
                                                `form-control form-control-md px-4` +
                                                (errors.contactNumber && touched.contactNumber
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              id="staticEmail"
                                              placeholder="Type here"
                                            />
                                            <ErrorMessage
                                              name={`contactNumber`}
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div> */}
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Due Date{" "}
                      </label>{" "}
                      <Field
                        name={`dueDate`}
                        type="date"
                        className={
                          `form-control form-control-md px-4` +
                          (errors.dueDate && touched.dueDate
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`dueDate`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Price{" "}
                      </label>{" "}
                      <Field
                        name={`price`}
                        type="number"
                        onKeyPress={(e) => onlyNumberAllow(e)}
                        className={
                          `form-control form-control-md px-4` +
                          (errors.price && touched.price ? " is-invalid" : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`price`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        GST{" "}
                      </label>{" "}
                      <Field
                        name={`gst`}
                        type="number"
                        onKeyPress={(e) => onlyAlphaNumericAllow(e)}
                        className={
                          `form-control form-control-md px-4` +
                          (errors.gst && touched.gst ? " is-invalid" : "")
                        }
                        id="staticEmail"
                        placeholder="Total Price"
                      />
                      <ErrorMessage
                        name={`gst`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Total Price{" "}
                      </label>{" "}
                      <input
                        value={values.price + values.gst}
                        type="number"
                        className={`form-control form-control-md px-4`}
                        id="staticEmail"
                        placeholder="Type here"
                        readOnly
                      />
                    </div>{" "}
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Credit Days{" "}
                      </label>{" "}
                      <Field
                        name={`creditDays`}
                        type="number"
                        className={`form-control form-control-md px-4`}
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`creditDays`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Payment Terms{" "}
                      </label>{" "}
                      <Field
                        name={`paymentTerms`}
                        type="number"
                        className={`form-control form-control-md px-4`}
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`paymentTerms`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        Remarks{" "}
                      </label>{" "}
                      <Field
                        name={`remarks`}
                        type="number"
                        className={`form-control form-control-md px-4`}
                        id="staticEmail"
                        placeholder="Type here"
                      />
                    </div>
                    {/* ================================================================================ */}
                    {/* <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Address
                                              </label>
                                              <Field
                                                name={`area`}
                                                type="text"
                                                className={
                                                  `form-control form-control-md px-4 rounded-pill` +
                                                  (errors.area && touched.area ? " is-invalid" : "")
                                                }
                                                id="staticEmail"
                                                placeholder="Type here"
                                              />

                                              <ErrorMessage
                                                name={`area`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>

                                            <div className="form-group mb-4 async-control  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Warehouse Id
                                              </label>

                                              <AsyncPaginate
                                                value={value}
                                                loadOptions={loadOptions}
                                                onChange={(e) => {
                                                  setValue(e);
                                                  setFieldValue("warehouse", parseInt(e.value));
                                                }}
                                                additional={{
                                                  page: 1,
                                                }}
                                                styles={{
                                                  control: (provided, state) => ({
                                                    ...provided,
                                                    boxShadow: "none",
                                                    border: state.isFocused && "1px solid #ced4da",
                                                    borderRadius: "50px",
                                                  }),

                                                  // option: (provided, state) => ({
                                                  //    ...provided,
                                                  //    backgroundColor: state.isFocused && "lightgray",
                                                  //    color: state.isFocused && "red"
                                                  // })
                                                }}
                                                classNamePrefix={errors.warehouse ? `mySelect` : ""}
                                              />
                                              <span className="errorMsg">
                                                {errors.warehouse ? `Required` : ""}
                                              </span>
                                            </div>

                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Booking Id
                                              </label>

                                              <AsyncPaginate
                                                value={bookingValue}
                                                loadOptions={loadBookingOptions}
                                                onChange={(e) => {
                                                  setBookingValue(e);
                                                  setFieldValue("booking", parseInt(e.value));
                                                }}
                                                additional={{
                                                  page: 1,
                                                }}
                                                styles={{
                                                  control: (provided, state) => ({
                                                    ...provided,
                                                    boxShadow: "none",
                                                    border: state.isFocused && "1px solid #ced4da",
                                                    borderRadius: "50px",
                                                  }),
                                                }}
                                                classNamePrefix={errors.booking ? `mySelect` : ""}
                                              />
                                              <span className="errorMsg">
                                                {errors.booking ? `Required` : ""}
                                              </span>
                                            </div>

                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Company Name
                                              </label>
                                              <Field
                                                name={`name`}
                                                type="text"
                                                onKeyPress={(e) => onlyLetterAllow(e)}
                                                className={
                                                  `form-control form-control-md px-4 rounded-pill` +
                                                  (errors.name && touched.name ? " is-invalid" : "")
                                                }
                                                id="staticEmail"
                                                placeholder="Type here"
                                              />
                                              <ErrorMessage
                                                name={`name`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Email ID
                                              </label>
                                              <Field
                                                name={`email`}
                                                type="email"
                                                className={
                                                  `form-control form-control-md px-4 rounded-pill` +
                                                  (errors.email && touched.email ? " is-invalid" : "")
                                                }
                                                id="staticEmail"
                                                placeholder="Type here"
                                              />
                                              <ErrorMessage
                                                name={`email`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Contact Number
                                              </label>
                                              <Field
                                                name={`contactNumber`}
                                                type="number"
                                                onKeyPress={(e) => onlyNumberAllow(e)}
                                                className={
                                                  `form-control form-control-md px-4 rounded-pill` +
                                                  (errors.contactNumber && touched.contactNumber
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="staticEmail"
                                                placeholder="Type here"
                                              />
                                              <ErrorMessage
                                                name={`contactNumber`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Invoice Date
                                              </label>
                                              <Field
                                                name={`invoiceDate`}
                                                type="date"
                                                className={
                                                  `form-control form-control-md px-4 rounded-pill` +
                                                  (errors.invoiceDate && touched.invoiceDate
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="staticEmail"
                                                placeholder="Type here"
                                              />
                                              <ErrorMessage
                                                name={`invoiceDate`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Due Date
                                              </label>
                                              <Field
                                                name={`dueDate`}
                                                type="date"
                                                className={
                                                  `form-control form-control-md px-4 rounded-pill` +
                                                  (errors.dueDate && touched.dueDate
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="staticEmail"
                                                placeholder="Type here"
                                              />
                                              <ErrorMessage
                                                name={`dueDate`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Price
                                              </label>
                                              <Field
                                                name={`price`}
                                                type="number"
                                                onKeyPress={(e) => onlyNumberAllow(e)}
                                                className={
                                                  `form-control form-control-md px-4 rounded-pill` +
                                                  (errors.price && touched.price ? " is-invalid" : "")
                                                }
                                                id="staticEmail"
                                                placeholder="Type here"
                                              />
                                              <ErrorMessage
                                                name={`price`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                GST
                                              </label>
                                              <Field
                                                name={`gst`}
                                                type="number"
                                                onKeyPress={(e) => onlyAlphaNumericAllow(e)}
                                                className={
                                                  `form-control form-control-md px-4 rounded-pill` +
                                                  (errors.gst && touched.gst ? " is-invalid" : "")
                                                }
                                                id="staticEmail"
                                                placeholder="Type here"
                                              />
                                              <ErrorMessage
                                                name={`gst`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                            <div className="form-group mb-4  col-md-4 col-sm-6">
                                              <label htmlFor="staticEmail" className="mb-2 pl-3">
                                                Total Price
                                              </label>
                                              <input
                                                value={values.price + values.gst}
                                                type="number"
                                                className={`form-control form-control-md px-4 rounded-pill`}
                                                id="staticEmail"
                                                placeholder="Type here"
                                                readOnly
                                              />
                                            </div> */}
                    {/* =========================================================== */}
                    {res && res.url ? (
                      <div className="custom-file form-group mb-4  col-md-4 col-sm-6">
                        <div className="row">
                          <div className="col-3">
                            <a href={res.url} target="_blank" rel="noreferrer">
                              <AiOutlineDownload
                                style={{
                                  color: "green",
                                  border: "2px solid #fffff0",
                                  fontSize: 50,
                                  backgroundColor: "#FFFFFF",
                                  borderRadius: "100%",
                                  padding: 10,
                                  marginTop: 15,
                                  cursor: "pointer",
                                }}
                              />{" "}
                            </a>{" "}
                          </div>{" "}
                          <div className="cal-9">
                            <input
                              onChange={uploadDocs}
                              type="file"
                              id="custom-file-upload-input"
                              className="custom-file-input"
                              hidden
                            />
                            <span
                              id="custom-file-name"
                              className="d-block text-left custom-file-name px-3 mb-2"
                            >
                              Change Document{" "}
                            </span>{" "}
                            <label
                              className="custom-file-upload-label btn-deep-blue btn text-nowrap rounded-pill d-block"
                              htmlFor="custom-file-upload-input"
                            >
                              {res && res.url ? "Uploaded" : "Upload File"}{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    ) : (
                      <div className="custom-file form-group mb-4  col-md-4 col-sm-6">
                        <input
                          onChange={uploadDocs}
                          type="file"
                          id="custom-file-upload-input"
                          className="custom-file-input"
                          hidden
                        />
                        <span
                          id="custom-file-name"
                          className="d-block text-left custom-file-name px-3 mb-2"
                        >
                          Upload Document{" "}
                        </span>{" "}
                        <label
                          className="custom-file-upload-label btn-deep-blue btn text-nowrap rounded-pill d-block py-2"
                          htmlFor="custom-file-upload-input"
                        >
                          {res && res.url ? "Uploaded" : "Upload File"}{" "}
                        </label>{" "}
                        <span className="errorMsg"> {fileError} </span>{" "}
                      </div>
                    )}{" "}
                  </div>{" "}
                  <div className="col-12 mt-1 px-0 pt-3 border-top mt-2">
                    <div className="row justify-content-end">
                      <div className="col-auto">
                        <button
                          onClick={() => history.goBack()}
                          type="button"
                          className="btn btn-outline-deep-blue py-2"
                        >
                          Back{" "}
                        </button>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <button
                          type="submit"
                          disabled={data.isPending}
                          className="btn btn-deep-blue py-2"
                        >
                          Submit{" "}
                          {data.isPending ? (
                            <Spinner animation="border" />
                          ) : null}{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default CreateUserInvoice;
