import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import UserList from "../../wrapper/userManagement/UserList";
import { Link } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { userListByPage } from "../../store/action/userAction";

const ServiceUser = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.USER);

  console.log("service User->", user);

  useEffect(() => {
    dispatch(userListByPage());
  }, [dispatch]);

  return (
    <LayoutOne>
      <div class="row justify-content-end align-items-center sticky-top py-3 px-3 bg-lighter-blue">
        <div class="col-auto d-lg-none">
          <button
            class="btn btn-deep-blue px-0 size-40px toggle-class btn-sidebar-admin sidebar-admin-toggle align-items-center justify-content-center"
            type="button"
            data-target=".sidebar-admin-toggle"
            data-toggle-class="open"
          >
            <span> </span>{" "}
          </button>{" "}
        </div>{" "}
        <SearchBox />{" "}
        {/* <div class="py-3 col">
                        <div class="input-group admin-search prepend w-100">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-white">
                              <button class="btn btn-lighter-blue p-0 size-30px"><i class="fas fa-search"></i></button>
                            </span>
                          </div>
                          <input type="text" class="form-control h-100% toggle-class" placeholder="Search"
                            data-target=".custom-search" data-toggle-class="open" data-event />
                        </div>
                      </div> */}{" "}
      </div>{" "}
      <div class="row align-items-center py-3 px-3 mx-0">
        <div class="col-12 bg-white p-2 mb-4">
          <div class="row justify-content-between">
            <div class="col-md-auto px-4 order-md-2">
              {" "}
              {/* <div class="row">
                              <div class="col-auto d-flex h-100 px-2 my-2">
                                <div class="dropdown btn-export">
                                  <button class="btn btn-deep-blue dropdown-toggle" type="button" id="dropdownMenu5"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Export
                                  </button>
                                  <div class="dropdown-menu">
                                    <a class="dropdown-item text-center" href="#!">Excel</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item text-center" href="#!">PDF</a>
                                  </div>
                                </div>
                              </div>
                              <div class="col-auto d-flex h-100 px-2 my-2">
                                <a href="add-new-vendor.html" class="btn btn-deep-blue">
                                  Add New Vendor
                                </a>
                              </div>
                            </div> */}{" "}
            </div>{" "}
            <div class="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul class="nav nav-pills admin-tabs-blue">
                <li class="nav-item">
                  <Link to="/vendor?page=1" class="nav-link text-uppercase">
                    {" "}
                    Vendors{" "}
                  </Link>{" "}
                </li>{" "}
                <li class="nav-item">
                  <Link to="/customer?page=1" class="nav-link text-uppercase">
                    {" "}
                    Customers{" "}
                  </Link>{" "}
                </li>{" "}
                <li class="nav-item">
                  <Link
                    to="/service-user?page=1"
                    class="nav-link active_link text-uppercase"
                  >
                    {" "}
                    Services{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div class="col-12 px-0">
          <div class="row align-items-center py-3 px-3 mx-0">
            <div class="col-12 px-0">
              <div class="row">
                <div class="col-12 px-0 table-responsive table-gray-admin">
                  <table class="table text-center">
                    <thead>
                      <tr>
                        <th> Sr.No. </th> <th> Company Name </th>{" "}
                        {/* <th>City</th>
                                      <th>Area</th> */}{" "}
                        <th> Vendor ID </th> <th> Email ID </th>{" "}
                        <th> Contact No. </th> <th> Status </th>{" "}
                        <th class="text-center"> </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      {[1, 2, 3, 4].map((data, index) => {
                        return (
                          <UserList
                            data={data}
                            // index={(pageCount-1)*10 + (index+1)}
                            key={index}
                          />
                        );
                      })}{" "}
                    </tbody>{" "}
                  </table>{" "}
                </div>{" "}
              </div>
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default ServiceUser;
