import React, { useState, useEffect } from "react";

import LayoutOne from "../../layout/LayoutOne";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  cmsByType,
  cmsUpdateByTypeId,
  responseCMS,
} from "../../store/action/cmsAction";
import FormSuccess from "../../components/common/FormSuccess";
import { CardLoader } from "../../components/helper/CustomLoader";

const Faq = () => {
  const dispatch = useDispatch();
  const about = useSelector((state) => state.CMS_INFO);

  const [specs, setspecs] = useState([]);
  /**
   * TODO: function use for removing specification row
   *@version 1.0.0
   * @author [Rohan Jha]
   */
  const removespecs = (index) => {
    const temp = [...specs];

    const filter = temp.filter((item, i) => i !== index);

    setspecs(filter);
  };
  /**
   * TODO: function use for adding specification row
   *@version 1.0.0
   * @author [Rohan Jha]
   */
  const addspecs = () => {
    setspecs([
      ...specs,
      {
        name: "",
        value: [
          {
            name: "",
            value: "",
          },
        ],
      },
    ]);
  };
  const addspecsvalues = (index) => {
    const addValueSchema = {
      name: "",
      value: "",
    };
    const updatedSpecs = [...specs];
    updatedSpecs[index].value.push(addValueSchema);

    setspecs(updatedSpecs);
  };
  const removespecsvalues = (index, idx) => {
    // const addValueSchema = {
    // name:"",
    // value:""
    // }
    const updatedSpecs = [...specs];
    const filtered = updatedSpecs.map((item, i) => {
      if (i === index) {
        item.value.splice(idx, 1);
      }
      return item;
    });
    // console.log(filtered)
    setspecs(filtered);
  };
  /**
   * TODO: function use for handling name value change of specification
   *@version 1.0.0
   * @author [Rohan Jha]
   */
  const handleChange2 = (e, name, index) => {
    const { value } = e.target;
    const list = [...specs];
    list[index][name] = value;
    setspecs(list);
  };
  const handleChange22 = (e, name, index, idx) => {
    // const { value } = e.target;
    // const list = [...specs];
    // list[index][name] = value;
    // setspecs(list);
    const updatedSpecs = [...specs];
    const filtered = updatedSpecs.map((item, i) => {
      if (i === index) {
        item.value[idx][name] = e.target.value;
      }
      return item;
    });
    console.log("sdjhsdj", filtered);
    setspecs(filtered);
  };
  useEffect(() => {
    console.log("test", about);
    //  setspecs(JSON.parse(about?.cmsDetail?.data))
    if (
      about.cmsDetail !== null &&
      about.cmsDetail !== undefined &&
      about.cmsDetail.cmsType === "testcust" &&
      about.cmsDetail.data !== undefined
    ) {
      var d = JSON.parse(about.cmsDetail.data);
      //   var spec = []
      //   for (let i = 0; i <d.length; i++) {
      //     spec.push({
      //       name: d[i].name,
      //       value: d[i].value
      //     })
      //   }
      //   console.log("sdjjsd", spec)
      setspecs(d);
    }
  }, [about]);

  const aboutData = () => {
    let varn = "";
    for (let i = 0; i < specs.length; i++) {
      varn += `  <p class="quot;text-justifyquot;"><span style="color: 0#4b4b4bquot;; font-family: 0Poppins,;">1. ${specs[i].name}</span></p>
       <p class="quot;text-justifyquot;"><span style="color: 0#4b4b4bquot;; font-family: 0Poppins,;">&nbsp;</span></p>
       <p class="quot;text-justifyquot;"><span style="color: 0#4b4b4bquot;; font-family: 0Poppins,;">${specs[i].value}</span></p>
       <p class="quot;text-justifyquot;">&nbsp;</p>
       <p class="quot;text-justifyquot;"><span style="color: 0#4b4b4bquot;; font-family: 0Poppins,;">&nbsp;</span></p>
       <p class="quot;text-justifyquot;">&nbsp;</p>
       <p class="quot;text-justifyquot;"><span style="color: 0#4b4b4bquot;; font-family: 0Poppins,;">&nbsp;</span></p>`;
    }
    let html = `<div class="quot;welcome-content">
    <h1>FAQ's</h1>
    <p class="quot;text-justifyquot;"><span style="color: 0#4b4b4bquot;; font-family: 0Poppins,;">Products Related</span></p>
    <p class="quot;text-justifyquot;"><span style="color: 0#4b4b4bquot;; font-family: 0Poppins,;">&nbsp;</span></p>
   ${varn}
    <div class="quot;welcome-content">&nbsp;</div>
    </div>`;
    let obj = {
      cmsType: "FAQ1",
      cmsActive: true,
      data: html,
    };
    dispatch(cmsUpdateByTypeId(obj, "FAQ1", 8, specs));
  };
  useEffect(() => {
    dispatch(cmsByType("testcust"));
  }, [dispatch]);

  return (
    <LayoutOne>
      {about.cmsResponse && about.cmsResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(responseCMS(null))}
          message={about.cmsResponse.message}
        />
      ) : null}
      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 pb-3 px-0">
          <h5 className="text-dark"> Content Management System </h5>{" "}
        </div>{" "}
        <div className="col-12 bg-white px-3 mb-4">
          <div className="row justify-content-between">
            <div className="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul className="nav nav-pills admin-tabs-blue">
                <li className="nav-item">
                  <Link to="/about-us" className="nav-link text-uppercase">
                    {" "}
                    About Us{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/faq"
                    className="nav-link active_link text-uppercase"
                  >
                    {" "}
                    FAQ 's
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/term-and-condition"
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    t & c{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link to="/return-policy" className="nav-link text-uppercase">
                    return policy{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link to="/privacy" className="nav-link text-uppercase">
                    {" "}
                    privacy{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="col-12 bg-white px-3 mb-4">
          <div className="row justify-content-between">
            <div className="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul className="nav nav-pills admin-tabs-blue">
                <li className="nav-item">
                  <Link to="/faq" className="nav-link text-uppercase">
                    {" "}
                    Vendor{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/faqcustomer"
                    className="nav-link active_link text-uppercase"
                  >
                    {" "}
                    Customer{" "}
                  </Link>{" "}
                </li>
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        {about.isLoading ? (
          <CardLoader />
        ) : (
          <div className="col-12 px-0">
            <div className="row align-items-center py-3 px-3 mx-0">
              <div className="col-12 px-0 cms-wrap">
                {" "}
                {specs && specs.length > 0
                  ? specs.map((value, index) => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-md-10">
                              {" "}
                              {/* <label> {index + 1}. </label> */}
                              <input
                                type="text"
                                placeholder="Section Name"
                                value={value.name}
                                className={"form-control form-control-nonround"}
                                mandatory={true}
                                onChange={(e) =>
                                  handleChange2(e, "name", index)
                                }
                              />{" "}
                            </div>{" "}
                            <div>
                              <button
                                className="cross_button"
                                onClick={() => removespecs(index)}
                              >
                                {" "}
                                X{" "}
                              </button>{" "}
                            </div>{" "}
                          </div>{" "}
                          <div className="add_button">
                            <button onClick={() => addspecsvalues(index)}>
                              {" "}
                              +ADD Value{" "}
                            </button>{" "}
                          </div>{" "}
                          {value.value && value.value.length > 0
                            ? value.value.map((val, i) => {
                                return (
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="col-md-10">
                                          <div class="form-group form-input">
                                            <label> {i + 1}.Question </label>{" "}
                                            <textarea
                                              placeholder="Question"
                                              value={val.name}
                                              className={
                                                "form-control form-control-nonround"
                                              }
                                              mandatory={true}
                                              onChange={(e) =>
                                                handleChange22(
                                                  e,
                                                  "name",
                                                  index,
                                                  i
                                                )
                                              }
                                              rows={3}
                                            />{" "}
                                          </div>{" "}
                                        </div>
                                        <div className=" mt-3">
                                          <button
                                            className="cross_button"
                                            onClick={() =>
                                              removespecsvalues(index, i)
                                            }
                                          >
                                            {" "}
                                            X{" "}
                                          </button>{" "}
                                        </div>{" "}
                                      </div>{" "}
                                    </div>{" "}
                                    <div className="col-md-10 col-6 mr-0">
                                      <div class="form-group form-input">
                                        <label> Answer </label>
                                        <textarea
                                          placeholder="Answer"
                                          value={val.value}
                                          className={
                                            "form-control form-control-nonround"
                                          }
                                          mandatory={true}
                                          onChange={(e) =>
                                            handleChange22(e, "value", index, i)
                                          }
                                          rows={3}
                                        />{" "}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </>
                      );
                    })
                  : null}{" "}
                {/* {specs.length <5 ? (
                                                          <div className="add_button">
                                                            <button onClick={() => addspecs()}>+ADD Value</button>
                                                          </div>) : null} */}{" "}
                {specs.length < 5 ? (
                  <div className="add_button">
                    <button onClick={() => addspecs()}> +ADD </button>{" "}
                  </div>
                ) : null}
              </div>{" "}
            </div>{" "}
            <div className="row">
              <div className="col-12 text-right pb-5 px-5">
                <button onClick={aboutData} className="btn btn-deep-blue py-2">
                  {" "}
                  Save{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        )}{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default Faq;
