import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";
import { confirmAlert } from "react-confirm-alert";
import { updateRole } from "../../store/action/whsUserAction";
import { useDispatch } from "react-redux";

const WHSRoleList = ({ item, index, read }) => {
  const [isActive, setIsActive] = useState(null);
  const dispatch = useDispatch();

  // Status Change confirmation
  const statusChange = (isActive) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to change status.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              updateRole(item.id, {
                isActive: isActive,
              })
            ),
        },
        {
          label: "No",
          onClick: () => setIsActive(!isActive),
        },
      ],
    });
  };

  const haldleChange = (e) => {
    setIsActive(e.target.value === "true");
    statusChange(e.target.value === "true");
  };

  useEffect(() => {
    setIsActive(item.isActive);
  }, [item.isActive]);

  return (
    <tr>
      <td className="text-center"> {index} </td>{" "}
      <td> {readableDate(item?.createdAt)} </td> <td> {item?.name} </td>{" "}
      <td>
        <select
          disabled={read}
          onChange={haldleChange}
          name="status"
          value={isActive}
          className="form-control form-control-lg custom-select bg-white common-select-deep-blue border-0 w-90px"
        >
          <option value={true}> Active </option>{" "}
          <option value={false}> In Active </option>{" "}
        </select>{" "}
      </td>{" "}
      <td className="text-center">
        {" "}
        <Link
          to={`/wsh-role-details/${item?.id}`}
          className="btn p-1 text-dark"
        >
          {" "}
          <i className="fas fa-eye"> </i>
        </Link>{" "}
      </td>{" "}
      <td className="text-center">
        {" "}
        {!read ? (
          <Link
            to={`/edit-role-permission/${item?.id}`}
            className={`btn p-1 text-dark`}
          >
            {" "}
            <i class="far fa-edit"> </i>
          </Link>
        ) : (
          <span className={`btn px-3 text-dark`}> Not allow </span>
        )}{" "}
      </td>{" "}
    </tr>
  );
};

export default WHSRoleList;
