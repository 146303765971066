import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { onlyAlphaNumericSpaceAllow } from "../../components/helper/reuse";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerOnboardForm,
  responseOnboard,
  getSubUserAssignOnboardDetailById,
  updateUserForm,
  userOnboardDetail,
  formDetails,
} from "../../store/action/onBoardAction";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import CustomerCommonForm from "../../wrapper/customerOnboarding/CustomerCommonForm";

const CustomerSpecialInstruction = () => {
  const { onBoardId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const formType = new URLSearchParams(window.location.search).get("formType");
  const assignUserId = new URLSearchParams(window.location.search).get(
    "assignUserId"
  );
  const onData = useSelector((state) => state.ON_BOARD_INFO);
  const contactDetailOnboardId = new URLSearchParams(
    window.location.search
  ).get("contactDetailOnboardId");

  const [costJson, setCostJson] = useState({
    specialInstructions: {
      instruction: [
        {
          anstructions: "",
          by: "",
          medium: "",
          action: "",
        },
        {
          anstructions: "",
          by: "",
          medium: "",
          action: "",
        },
        {
          anstructions: "",
          by: "",
          medium: "",
          action: "",
        },
        {
          anstructions: "",
          by: "",
          medium: "",
          action: "",
        },
      ],
      preparedBy: "",
      preparedByDate: "",
      checkedBy: "",
      checkedByDate: "",
      approvedBy: "",
      approveByDate: "",
      ReceivedBy: "",
      ReceivedByDate: "",
    },
  });

  const schema = Yup.object().shape({
    specialInstructions: Yup.object().shape({
      preparedBy: Yup.string().required("Required"),
      preparedByDate: Yup.string().required("Required"),
      checkedBy: Yup.string().required("Required"),
      checkedByDate: Yup.string().required("Required"),
      approvedBy: Yup.string().required("Required"),
      approveByDate: Yup.string().required("Required"),
      ReceivedBy: Yup.string().required("Required"),
      ReceivedByDate: Yup.string().required("Required"),
    }),
  });

  useEffect(() => {
    if (onData.formDetail?.specialInstruction) {
      setCostJson({
        specialInstructions:
          onData.formDetail?.specialInstruction?.specialInstructions,
      });
    }

    // if (formType === "specialInstruction" && onData.userOnboardDetail?.specialInstruction && onData.userOnboardDetail?.specialInstruction?.specialInstructions) {
    //   setCostJson({
    //     specialInstructions: onData.userOnboardDetail.specialInstruction?.specialInstructions
    //   })
    // }
  }, [
    onData.formDetail?.specialInstruction,
    onData.userOnboardDetail,
    formType,
  ]);

  useEffect(() => {
    if (formType === "specialInstruction") {
      dispatch(
        getSubUserAssignOnboardDetailById("specialInstruction", onBoardId)
      );
    } else {
      dispatch(
        getCustomerOnboardForm(assignUserId, "specialInstruction", onBoardId)
      );
    }

    return () => {
      dispatch(userOnboardDetail(null));
      dispatch(formDetails(null));
    };
  }, [dispatch, onBoardId, formType, assignUserId]);

  const redirect = () => {
    dispatch(responseOnboard(null));
    history.goBack();
  };

  return (
    <LayoutOne>
      {onData.onResponse && onData.onResponse.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={onData.onResponse.message} />
      ) : null}

      <div className="row align-items-center py-2 px-3 mx-0  form-control-height">
        <div className="col-12 py-2">
          <span onClick={() => history.goBack()}>
            {" "}
            <h5 className="text-dark-blue cursorPointer">
              {" "}
              <i className="fas fa-chevron-left mr-3"> </i> Special Operations
            </h5>{" "}
          </span>{" "}
        </div>
        <CustomerCommonForm buttonHide={true} />
        <div className="col-12 mt-2">
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={costJson}
            onSubmit={(fields) => {
              if (contactDetailOnboardId) {
                dispatch(
                  updateUserForm(
                    contactDetailOnboardId,
                    "specialInstruction",
                    fields
                  )
                );
              }
              console.log("Fields==>", fields);
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <>
                    <div className="row mx-0">
                      <div className="col-12 px-0 table-responsive table-gray-admin">
                        <table className="table text-left">
                          <tbody>
                            <tr>
                              <td className="font-weight-bold py-2">
                                {" "}
                                Activities{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Frequency{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Source of Data{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Supporting Document{" "}
                              </td>{" "}
                            </tr>

                            {values.specialInstructions?.instruction &&
                              values.specialInstructions?.instruction?.length >
                                0 &&
                              values.specialInstructions?.instruction.map(
                                (ins, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        <Field
                                          onKeyPress={(e) =>
                                            onlyAlphaNumericSpaceAllow(e)
                                          }
                                          name={`specialInstructions.instruction.${index}.activities`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`instruction.${index}.activities`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`specialInstructions.instruction.${index}.frequency`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`instruction.${index}.frequency`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`specialInstructions.instruction.${index}.sourceofData`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`instruction.${index}.sourceofData`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`specialInstructions.instruction.${index}.supportingDocument`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`instruction.${index}.supportingDocument`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </div>

                    <div className="row py-2 mt-2">
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Prepared by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="specialInstructions.preparedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.specialInstructions?.preparedBy &&
                              touched.specialInstructions?.preparedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="specialInstructions.preparedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="specialInstructions.preparedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.specialInstructions?.preparedByDate &&
                              touched.specialInstructions?.preparedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="specialInstructions.preparedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Checked by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="specialInstructions.checkedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.specialInstructions?.checkedBy &&
                              touched.specialInstructions?.checkedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="specialInstructions.checkedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="specialInstructions.checkedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.specialInstructions?.checkedByDate &&
                              touched.specialInstructions?.checkedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="specialInstructions.checkedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Approved by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="specialInstructions.approvedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.specialInstructions?.approvedBy &&
                              touched.specialInstructions?.approvedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="specialInstructions.approvedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="specialInstructions.approveByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.specialInstructions?.approveByDate &&
                              touched.specialInstructions?.approveByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="specialInstructions.approveByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Received by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="specialInstructions.ReceivedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.specialInstructions?.ReceivedBy &&
                              touched.specialInstructions?.ReceivedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="specialInstructions.ReceivedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="specialInstructions.ReceivedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.specialInstructions?.ReceivedByDate &&
                              touched.specialInstructions?.ReceivedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="specialInstructions.ReceivedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 px-2">
                        <button
                          type="submit"
                          className="btn btn-deep-blue py-2"
                          disabled={onData.isPending}
                        >
                          Save{" "}
                          {onData.isPending ? (
                            <Spinner animation="border" />
                          ) : null}{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>
                  </>{" "}
                </Form>
              );
            }}
          />
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default CustomerSpecialInstruction;
