import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { CardLoader } from "../../../components/common/CustomLoader";

const StorageTypeForm = ({ isView }) => {
  const data = useSelector((state) => state.RFQ_INFO);

  const [jsonData, setJsonData] = useState([
    {
      processType: "Temperature Control (Airconditioned)",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Humidity Control",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Dust Free",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Pallet Storage",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Serial No",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Shelve Rack Storage",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Block Storage",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Block Storage",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Ground Storage",
      areaSq: "",
      noOfPallet: "",
    },
    {
      processType: "Ground Storage",
      areaSq: "",
      noOfPallet: "",
    },
  ]);

  const storeSchema = Yup.object().shape({
    storageTypes: Yup.array().of(
      Yup.object().shape({
        areaSq: Yup.string().required("Required"),
        noOfPallet: Yup.string().required("Required"),
      })
    ),
  });

  useEffect(() => {
    if (
      data?.rfqDetail?.data &&
      data?.rfqDetail?.data?.storageTypes &&
      data?.rfqDetail?.data?.storageTypes?.length !== 0
    ) {
      console.log(data.rfqDetail.data.storageTypes,"|||*****|||*****|||")
      setJsonData(data.rfqDetail.data.storageTypes);

    }
  }, [data?.rfqDetail?.data]);

  return (
    <>
      {" "}
      {data.isLoading ? (
        <CardLoader loaderCard="loaderCard" />
      ) : (
        <Formik
          enableReinitialize={true}
          validationSchema={storeSchema}
          initialValues={{
            storageTypes: jsonData,
          }}
          onSubmit={(fields) => {
            console.log("Fields===>", fields);
          }}
          render={({ values, errors, status, onChange, touched }) => {
            return (
              <div className="w-100 d-block">
                <Form>
                  <div className="row">
                    <div className="row col-12 ml-0">
                      <div className="col-12 px-0 table-responsive border bg-deep-gray rounded-md table-cell">
                        <table className="table text-center form-control-height">
                          <thead>
                            <tr>
                              <td className="border-top-0 font-weight-bold py-2">
                                {" "}
                                S.no{" "}
                              </td>{" "}
                              <td className="text-left border-top-0 font-weight-bold text-nowrap py-2 w-160px">
                                {" "}
                                Process Description{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold py-2 mw-200px">
                                {" "}
                                No of Pallets{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold text-nowrap py-2 mw-150px">
                                {" "}
                                Area in Sqft{" "}
                              </td>{" "} 
                              <td className="border-top-0 font-weight-bold text-nowrap py-2 mw-150px">
                                {" "}
                                Remarks{" "}
                              </td>{" "}                              
                            </tr>{" "}
                          </thead>{" "}
                          <tbody>
                            {values.storageTypes.length > 0 &&
                              values.storageTypes.map((man, index) => {
                                const boundErrors =
                                  (errors.storageTypes?.length &&
                                    errors.storageTypes[index]) ||
                                  {};
                                const boundTouched =
                                  (touched.storageTypes?.length &&
                                    touched.storageTypes[index]) ||
                                  {};
                                return (
                                  <tr>
                                    <td> {index + 1} </td>{" "}
                                    <td className="text-left">
                                      {" "}
                                      {man.processType}{" "}
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`storageTypes.${index}.noOfPallet`}
                                        type="text"
                                        className={
                                          `form-control text-center bg-white my-1` +
                                          (boundErrors.noOfPallet &&
                                          boundTouched.noOfPallet
                                            ? " is-invalid"
                                            : "")
                                        }
                                        readOnly={isView}
                                      />{" "}
                                      <ErrorMessage
                                        name={`storageTypes.${index}.noOfPallet`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`storageTypes.${index}.areaSq`}
                                        type="number"
                                        className={
                                          `form-control text-center bg-white my-1` +
                                          (boundErrors.areaSq &&
                                          boundTouched.areaSq
                                            ? " is-invalid"
                                            : "")
                                        }
                                        readOnly={isView}
                                      />{" "}
                                      <ErrorMessage
                                        name={`storageTypes.${index}.areaSq`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`storageTypes.${index}.remarks`}
                                        type="text"
                                        className={
                                          `form-control text-center bg-white my-1` +
                                          (boundErrors.remarks &&
                                          boundTouched.remarks
                                            ? " is-invalid"
                                            : "")
                                        }
                                        readOnly={isView}
                                      />{" "}
                                      <ErrorMessage
                                        name={`storageTypes.${index}.remarks`}
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </td>{" "}
                                  </tr>
                                );
                              })}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </div>
                  </div>{" "}
                </Form>{" "}
              </div>
            );
          }}
        />
      )}{" "}
    </>
  );
};

export default StorageTypeForm;
