import axiosauth from "../../services/axios-auth";

//   Get All Zone
export const fetchZones = (setZones) => {
    try {
        axiosauth
            .get(`/api/v1/zone-mapping`)
            .then((response) => {
                let res = JSON.parse(response.data);
                if (response.status === 200) {
                    setZones(res);
                }
            })
            .catch((error) => {
                console.log("error : ", error);
            });
    } catch (e) {}
};


//   Get All Zone
export const createZones = (setZones, setCreatedMessage, setUniqueErr, obj) => {
    try {
        axiosauth
            .post(`/api/v1/zone-mapping`, obj)
            .then((response) => {
                if (response.status === 201) {
                    setCreatedMessage("Zone Created")
                    setTimeout(() => {
                        setCreatedMessage(null)
                    }, 3000)
                    fetchZones(setZones);
                }
            })
            .catch((error) => {
                setUniqueErr("Name Should Be Unique")
                setTimeout(() => {
                    setUniqueErr(null)
                }, 3000)
                console.log("error : ", error);
            });
    } catch (e) {}
};