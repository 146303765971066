import React from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Link } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import ReportList from "../../wrapper/report/ReportList";

const SubAdminReport = () => {
  return (
    <LayoutOne>
      <div class="row justify-content-end align-items-center sticky-top py-3 px-3 bg-lighter-blue">
        <div class="col-auto d-lg-none">
          <button
            class="btn btn-deep-blue px-0 size-40px toggle-class btn-sidebar-admin sidebar-admin-toggle align-items-center justify-content-center"
            type="button"
            data-target=".sidebar-admin-toggle"
            data-toggle-class="open"
          >
            <span> </span>{" "}
          </button>{" "}
        </div>{" "}
        <SearchBox />
      </div>{" "}
      <div class="row align-items-center py-3 px-3 mx-0">
        <div class="col-12 bg-white p-3 mb-4">
          <div class="row justify-content-between">
            <div class="col-md-auto px-4 order-md-2">
              <div class="row">
                <div class="col-auto d-flex h-100 px-2 my-2">
                  <div class="dropdown btn-export">
                    <button
                      class="btn btn-deep-blue dropdown-toggle"
                      type="button"
                      id="dropdownMenu5"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Export{" "}
                    </button>{" "}
                    <div class="dropdown-menu">
                      <a class="dropdown-item text-center" href="#!">
                        {" "}
                        Excel{" "}
                      </a>{" "}
                      <div class="dropdown-divider"> </div>{" "}
                      <a class="dropdown-item text-center" href="#!">
                        {" "}
                        PDF{" "}
                      </a>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div class="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul class="nav nav-pills admin-tabs-blue">
                <li class="nav-item">
                  <Link to="/warehouse-report" class="nav-link text-uppercase">
                    {" "}
                    Warehouse INFORMATION{" "}
                  </Link>{" "}
                </li>{" "}
                <li class="nav-item">
                  <Link to="/user-report" class="nav-link  text-uppercase">
                    {" "}
                    USER INFORMATION{" "}
                  </Link>{" "}
                </li>{" "}
                <li class="nav-item">
                  <Link to="/vendor-report" class="nav-link  text-uppercase">
                    {" "}
                    VENDOR INFORMATION{" "}
                  </Link>{" "}
                </li>{" "}
                <li class="nav-item">
                  <Link
                    to="/sub-admin-report"
                    class="nav-link active_link text-uppercase"
                  >
                    {" "}
                    SUB ADMIN INFORMATION{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div class="col-12 px-0">
          <div class="row align-items-center py-3 mx-0">
            <div class="col-12 px-0 table-responsive table-gray-admin">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="w-100px"> S.No. </th> <th> Warehouse ID </th>{" "}
                    <th> Vendor Name </th> <th> Vendor Company </th>{" "}
                    <th> City </th> <th> Area </th>{" "}
                    <th> Activate / Inactivate </th>{" "}
                  </tr>{" "}
                </thead>{" "}
                <tbody>
                  <ReportList />
                  <ReportList />
                </tbody>{" "}
              </table>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default SubAdminReport;
