import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Link, useHistory, useParams } from "react-router-dom";
import { documentById } from "../../store/action/documentAction";
import { useSelector, useDispatch } from "react-redux";
import DocCertificateList from "../../wrapper/documents/DocCertificateList";

const VendorDocuments = () => {
  const history = useHistory();
  const { warehouseId, whId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.DOCUMENT_INFO.documentDetail);

  useEffect(() => {
    dispatch(documentById(warehouseId));
  }, [dispatch, warehouseId]);

  return (
    <LayoutOne>
      <div className="row align-items-center px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <h5 className="text-dark-blue py-2">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>{" "}
            Warehouse ID : DL-{whId}
          </h5>
        </div>{" "}
        <div className="col-12 bg-white rounded">
          <div className="row justify-content-between">
            <div className="tabs-scroll col-md-auto order-md-1 d-flex">
              <ul className="nav nav-pills admin-tabs-blue justify-content-between">
                <li className="nav-item">
                  <Link
                    to={`/customer-documents/${warehouseId}/${whId}`}
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    customers{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to={`/vendor-documents/${warehouseId}/${whId}`}
                    className="nav-link text-uppercase active"
                  >
                    {" "}
                    vendors{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>
            </div>{" "}
          </div>
        </div>{" "}
        <div className="col-12 text-nowrap mt-2 table-responsive table-gray-admin">
          <table className="table">
            <thead>
              <tr>
                <th> Warehouse ID </th> <th> Document Name </th>{" "}
                <th className="text-center"> View </th>{" "}
                <th className="text-center"> Upload Document </th>{" "}
              </tr>{" "}
            </thead>{" "}
            <tbody>
              <tr>
                <td> ID {whId} </td> <td> {`Space Certificate`} </td>{" "}
                <td className="text-center">
                  <Link
                    to={`/space-documents/${data.data?.id}?userType=vendor`}
                    className="btn font-weight-bold px-1 py-0"
                  >
                    {" "}
                    <i className="fas fa-eye"> </i>
                  </Link>
                </td>{" "}
                <td className="text-center"></td>{" "}
              </tr>

              {data.data &&
              data.data.vendorDocument &&
              data.data.vendorDocument.documents &&
              data.data.vendorDocument.documents.length > 0
                ? data.data.vendorDocument.documents.map((item, index) => {
                    return (
                      <DocCertificateList
                        userDocType="vendordoc"
                        userType="vendor"
                        item={item}
                        key={index}
                        whId={whId}
                        documentId={data.data.vendorDocument.id}
                        index={index}
                      />
                    );
                  })
                : null}

              {/* {
                                data.documentDetail.vendor.documents && data.documentDetail.vendor.documents.length>0 ?
                                data.documentDetail.vendor.documents.map((item, index)=>{
                                  return(
                                    <DocCertificateList item={item} key={index} warehouseId={data.documentDetail.warehouse.id} index={index} />
                                  )
                                })
                            :null
                            } */}

              {/* <tr> 
                                <td>ID 89000</td>
                                <td>Space Certificate</td>
                                <td className="text-center">
                                    <a href="edit-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-eye"></i></a>
                                </td>
                                <td className="text-center">
                                    <a href="view-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-upload"></i></a>
                                </td> 
                                </tr>   
                                <tr> 
                                <td>ID 89000</td>
                                <td>Agreement Package</td>
                                <td className="text-center">
                                    <a href="edit-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-eye"></i></a>
                                </td>
                                <td className="text-center">
                                    <a href="view-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-upload"></i></a>
                                </td> 
                                </tr>
                                <tr> 
                                <td>ID 89000</td>
                                <td>SOW</td>
                                <td className="text-center">
                                    <a href="edit-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-eye"></i></a>
                                </td>
                                <td className="text-center">
                                    <a href="view-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-upload"></i></a>
                                </td> 
                                </tr>
                                <tr> 
                                <td>ID 89000</td>
                                <td>NOC</td>
                                <td className="text-center">
                                    <a href="edit-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-eye"></i></a>
                                </td>
                                <td className="text-center">
                                    <a href="view-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-upload"></i></a>
                                </td> 
                                </tr>
                                <tr> 
                                <td>ID 89000</td>
                                <td>Others</td>
                                <td className="text-center">
                                    <a href="edit-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-eye"></i></a>
                                </td>
                                <td className="text-center">
                                    <a href="view-vendor-invoice.html" className="btn font-weight-bold px-1 py-0"><i className="fas fa-upload"></i></a>
                                </td> 
                                </tr> */}
            </tbody>{" "}
          </table>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default VendorDocuments;
