import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";
import { useDispatch, useSelector } from "react-redux";
import { serviceById } from "../../store/action/serviceAction";

const ViewService = () => {
  const history = useHistory();
  const { serviceId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.SERVICE_INFO);

  useEffect(() => {
    dispatch(serviceById(serviceId));
  }, [dispatch, serviceId]);

  return (
    <LayoutOne>
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            >
              {" "}
            </i>{" "}
            Service Details
          </h5>
        </div>{" "}
        <div className="col-12">
          <div className="row mx-0">
            <div className="col-12 bg-white rounded">
              {data.serviceDetail ? (
                <div className="card card-body border-0">
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Service ID </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceDetail?.id}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Service Name </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceDetail?.name}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Category Name </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceDetail?.category?.name}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2">
                      {" "}
                      Sub Category Name{" "}
                    </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceDetail?.subcategory?.name}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Active date </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {readableDate(data.serviceDetail?.activeDate)}{" "}
                    </div>{" "}
                  </div>
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Status </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceDetail?.active ? "Active" : "In Active"}{" "}
                    </div>{" "}
                  </div>
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Features </div>{" "}
                    <div>
                      {" "}
                      {JSON.parse(data.serviceDetail?.serviceFeature) &&
                      JSON.parse(data.serviceDetail?.serviceFeature).length > 0
                        ? JSON.parse(data.serviceDetail?.serviceFeature).map(
                            (item, index) => {
                              return (
                                <>
                                  <div key={index} className="px-3 text-gray">
                                    {" "}
                                    {index + 1}. {item?.name}{" "}
                                  </div>{" "}
                                  <br />
                                </>
                              );
                            }
                          )
                        : null}{" "}
                    </div>
                  </div>{" "}
                </div>
              ) : null}{" "}
            </div>
            <div className="row">
              <div className="col-auto my-2">
                <button
                  onClick={() =>
                    (window.location.href = data.serviceDetail?.sampleRfq)
                  }
                  className="btn py-2 btn-outline-deep-blue"
                >
                  {" "}
                  Download Rfq Excel{" "}
                </button>{" "}
              </div>
              <div className="col-auto my-2">
                <button
                  onClick={() => history.goBack()}
                  className="btn py-2 btn-outline-deep-blue"
                >
                  {" "}
                  Back{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default ViewService;
