import React, { useState } from "react";
import { uploadDocumentCertificate } from "../../components/utils";
import FormSuccess from "../../components/common/FormSuccess";
import { Link, useParams } from "react-router-dom";
import { documentById } from "../../store/action/documentAction";
import { useDispatch } from "react-redux";

const DocCertificateList = ({
  item,
  whId,
  userDocType,
  documentId,
  userType,
  index,
}) => {
  const { warehouseId } = useParams();
  const [doc, setDoc] = useState(null);
  const [res, setRes] = useState(null);
  const dispatch = useDispatch();

  const uploadDocs = async (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("userType", userType);
    let urlData = await uploadDocumentCertificate(
      documentId,
      userDocType,
      item.documentName,
      formData
    );
    console.log(urlData);
    if (urlData && urlData.statusCode === 200) {
      setRes(urlData);
    }
    setDoc(URL.createObjectURL(e.target.files[0]));
  };

  const redirect = () => {
    dispatch(documentById(warehouseId));
    setRes(null);
  };

  return (
    <>
      {" "}
      {res ? (
        <FormSuccess onClick={redirect} message="Document Uploaded" />
      ) : null}
      <tr>
        <td className="py-2"> ID {whId} </td> <td> {item.documentName} </td>{" "}
        <td className="text-center">
          {" "}
          {item?.documentName === "space_certificate" ? (
            <Link
              to={`/space-documents/${1}`}
              rel="noreferrer"
              className="btn font-weight-bold px-1 py-0"
            >
              {" "}
              <i className="fas fa-eye"> </i>
            </Link>
          ) : (
            <a
              href={item.url}
              target="_blank"
              rel="noreferrer"
              download
              className="btn font-weight-bold px-1 py-0"
            >
              {" "}
              <i className="fas fa-eye"> </i>
            </a>
          )}
        </td>{" "}
        <td className="text-center">
          <input onChange={uploadDocs} id={`docs${index}`} type="file" hidden />
          <label
            htmlFor={`docs${index}`}
            className="btn font-weight-bold px-1 py-0"
          >
            {" "}
            <i className="fas fa-upload"> </i>
          </label>

          {item.signedCertificateWarehousity !== "" ? (
            <a
              href={item.signedCertificateWarehousity}
              target="_blank"
              rel="noreferrer"
              download
              className="btn font-weight-bold px-1 py-0"
            >
              {" "}
              <i className="fas fa-eye"> </i>
            </a>
          ) : (
            <>
              {" "}
              {doc ? (
                <a
                  href={doc}
                  target="_blank"
                  rel="noreferrer"
                  download
                  className="btn font-weight-bold px-1 py-0"
                >
                  {" "}
                  <i className="fas fa-eye"> </i>
                </a>
              ) : null}{" "}
            </>
          )}
        </td>{" "}
      </tr>{" "}
    </>
  );
};

export default DocCertificateList;
