import {
    SERVICE,
    SERVICE_BY_ID,
    SERVICE_CATEGORY,
    SERVICE_CATEGORY_BY_ID,
    SERVICE_SUBCATEGORY,
    SERVICE_SUBCATEGORY_BY_ID,
    SERVICE_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
    SERVICE_RECIEVE,
    SERVICE_RECIEVE_BY_ID
} from './../types';


const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    serviceList: null,
    serviceDetail: null,
    categoryList: null,
    categoryDetail: null,
    subCategoryList: null,
    subCategoryDetail: null,
    serviceResponse: null,
    serviceRecieve: null,
    serviceRecieveDetail: null
}

export function SERVICE_INFO(state = initialState, action) {

    switch (action.type) {

        case SERVICE:
            return {
                ...state,
                serviceList: action.payload,
            };
        case SERVICE_RECIEVE:
            return {
                ...state,
                serviceRecieve: action.payload,
            };
        case SERVICE_RECIEVE_BY_ID:
            return {
                ...state,
                serviceRecieveDetail: action.payload,
            };

        case SERVICE_BY_ID:
            return {
                ...state,
                serviceDetail: action.payload,
            };


        case SERVICE_CATEGORY:
            return {
                ...state,
                categoryList: action.payload,
            };

        case SERVICE_CATEGORY_BY_ID:
            return {
                ...state,
                categoryDetail: action.payload,
            };

        case SERVICE_SUBCATEGORY:
            return {
                ...state,
                subCategoryList: action.payload,
            };

        case SERVICE_SUBCATEGORY_BY_ID:
            return {
                ...state,
                subCategoryDetail: action.payload,
            };


        case SERVICE_RESPONSE:
            return {
                ...state,
                serviceResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        default:
            return state;
    }
}