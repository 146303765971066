import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import SearchBox from "../../components/common/SearchBox";
import CategoryList from "../../wrapper/categoryManagement/CategoryList";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { categoryByPage } from "../../store/action/categoryAction";
import Pagination from "react-js-pagination";
import { ItemNotFound } from "../../components/helper/CustomLoader";

const Category = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.CATEGORY_INFO);
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(categoryByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(categoryByPage(pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(categoryByPage(pageCount, searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Category </title>{" "}
      </Helmet>
      <div className="row align-items-center px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0 d-flex justify-content-between">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>
            Category Management{" "}
          </h5>
          <Link
            to="/add-category"
            className={`btn btn-deep-blue py-2 px-md-3 px-sm-1 text-spacing ${
              !read ? "" : "d-none"
            }`}
          >
            {" "}
            Add Category{" "}
          </Link>
        </div>{" "}
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
          />{" "}
        </div>
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12 table-responsive table-gray-admin bg-white p-3">
              {data.categoryList &&
              data.categoryList.data &&
              data.categoryList.data.length > 0 ? (
                <table className="table table-dark-custom">
                  <thead>
                    <tr>
                      <th className="text-nowrap text-center"> Sr.No. </th>{" "}
                      <th className="text-nowrap"> Category Name </th>{" "}
                      <th className="text-nowrap"> Created Date </th>{" "}
                      <th className="text-nowrap"> Image </th>{" "}
                      <th className="text-nowrap"> Inactive Date </th>{" "}
                      <th className="text-nowrap"> Action </th>{" "}
                      <th className="text-nowrap"> Delete </th>{" "}
                      <th className="text-nowrap"> Update </th>{" "}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {data.categoryList.data.map((item, index) => {
                      return (
                        <CategoryList
                          item={item}
                          key={index + 1}
                          index={index + 1}
                          read={read}
                        />
                      );
                    })}{" "}
                  </tbody>{" "}
                </table>
              ) : (
                <ItemNotFound message="Category Not Found" />
              )}
            </div>{" "}
          </div>

          {data.categoryList?.totalCount > 10 && (
            <div className="pagination-custom">
              <Pagination
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={data.categoryList?.totalCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                prevPageText={<i className="fas fa-chevron-left" />}
                nextPageText={<i className="fas fa-chevron-right" />}
                hideFirstLastPages={true}
              />{" "}
            </div>
          )}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default Category;
