import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  createRFQ,
  whsToVendorRfqByIdAndType,
  rfq_By_Id,
} from "../../store/action/rfqAction";
import { CardLoader } from "../../components/common/CustomLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: "5px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    padding: "15px",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const WhsToVendorRFQ = () => {
  const history = useHistory();
  const { vRequestId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.RFQ_INFO);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const [jsonData, setJsonData] = useState({
    locationDetails: [
      {
        type: "Area",
        input: "",
        remark: "",
      },
      {
        type: "Deposit",
        input: "",
        remark: "",
      },
      {
        type: "Rent/Sq.Ft",
        input: "",
        remark: "",
      },
      {
        type: "Utilities",
        input: "",
        remark: "",
      },
      {
        type: "Interest",
        input: "",
        remark: "",
      },
      {
        type: "Over all Interest",
        input: "",
        remark: "",
      },
      {
        type: "Salary Increase/year",
        input: "",
        remark: "",
      },
      {
        type: "AMC (IT)",
        input: "",
        remark: "",
      },
      {
        type: "AMC ( Non IT)",
        input: "",
        remark: "",
      },
      {
        type: "Margin",
        input: "",
        remark: "",
      },
    ],
    manPowers: [
      {
        manpowerType: "WH Manager",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "Deposit",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "Rent/Sq.Ft",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "Utilities",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "Interest",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "Over all Interest",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "Salary Increase/year",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "AMC (IT)",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "AMC ( Non IT)",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "Margin",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
      {
        manpowerType: "Total",
        quantity: "",
        costUnit: "",
        totalMonth: "",
      },
    ],
    infrastructures: [
      {
        infrastructureType: "MFD",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "year deap",
        dep: "dep",
        int: "int",
        amc: "amc",
      },
      {
        infrastructureType: "Fire Extinguiser",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Office Setup",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "HPT Electric Voltas",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "HPT Electric Voltas",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Hand Pallet Truck",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Picking Trolley",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Desktop Computer",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Office Printer",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Printer With Xerox, Fax and Scan facility",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Barcode printer",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "UPS",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Inverter",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Electric Stacker",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "AC",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "ForkLift Battery Operated",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Projector",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Weighting Machine",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Wooden Pallets",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Generator 20KVA",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Message Boards (Notice)",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "HD Selves For File and Record",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Lock and Key Almira",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Water Cooler",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Labels & Signage",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Office and Security Fan",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Emergency Light with Battery",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "WMS Software",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Wire Mesh Cage",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "First Aid Kit",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Security Alarm",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Dock Levler",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Shelve Racks",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },

      {
        infrastructureType: "CCTV",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Internal Painting",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Security & Safety Audit",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Security & Safety Displays",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Shelved Rack",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Broadband Connection setup",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Vaccumm Cleaner",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Start Up",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
      {
        infrastructureType: "Scanners",
        quantity: "",
        costUnit: "",
        totalMonth: "",
        yearDeap: "",
        dep: "",
        int: "",
        amc: "",
      },
    ],
    runningCosts: [
      {
        runningCostType: "Rent",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Int.On Deposit",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Int.On Deposit",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "runningcost",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Communication",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Utilities",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Staff Welfare",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Power",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Licenses",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "House Keeping Consumables",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "WMS ID",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Packaging Consumable",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Genset",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Diesel",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Consumable(printing material)",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Broadband Connection",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Pallets",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
      {
        runningCostType: "Total",
        quantity: "",
        costUnit: "",
        amount: "",
        vendorType: "",
      },
    ],
    monthlyCosts: [
      {
        monthlyCostType: "Warehouse Rentals",
        year: "",
      },
      {
        monthlyCostType: "Manpower",
        year: "",
      },
      {
        monthlyCostType: "Infrastructure",
        year: "",
      },
      {
        monthlyCostType: "Utility",
        year: "",
      },
      {
        monthlyCostType: "Management Fees @12%",
        year: "",
      },
    ],
  });

  useEffect(() => {
    dispatch(whsToVendorRfqByIdAndType(vRequestId, "manpowers"));
    return () => {
      dispatch(rfq_By_Id([]));
    };
  }, [dispatch, vRequestId]);

  useEffect(() => {
    if (data.rfqDetail.data) {
      setJsonData(data.rfqDetail.data);
    }
  }, [data.rfqDetail.data]);

  const rfqSchema = Yup.object().shape({
    locationDetails: Yup.array().of(
      Yup.object().shape({
        input: Yup.string().required("Required"),
        remark: Yup.string().required("Required"),
      })
    ),
    manPowers: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.string().required("Required"),
        costUnit: Yup.string().required("Required"),
        totalMonth: Yup.string().required("Required"),
      })
    ),
    infrastructures: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.string().required("Required"),
        costUnit: Yup.string().required("Required"),
        totalMonth: Yup.string().required("Required"),
        yearDeap: Yup.string().required("Required"),
        dep: Yup.string().required("Required"),
        int: Yup.string().required("Required"),
        amc: Yup.string().required("Required"),
      })
    ),

    runningCosts: Yup.array().of(
      Yup.object().shape({
        amount: Yup.string().required("Required"),
        quantity: Yup.string().required("Required"),
        costUnit: Yup.string().required("Required"),
      })
    ),
    monthlyCosts: Yup.array().of(
      Yup.object().shape({
        year: Yup.string().required("Required"),
      })
    ),
  });

  const handleChange = (panel, type) => (event, isExpanded) => {
    dispatch(whsToVendorRfqByIdAndType(vRequestId, type));
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <LayoutOne>
      <Formik
        enableReinitialize={true}
        validationSchema={rfqSchema}
        initialValues={jsonData}
        onSubmit={(fields) => {
          dispatch(createRFQ(fields));
        }}
        render={({ values, errors, status, onChange, touched }) => {
          return (
            <div className="w-100 d-block">
              <Form>
                <div className="row align-items-center py-3 px-3 mx-0">
                  <div className="col-12">
                    <h5 className="text-dark">
                      {" "}
                      <i
                        onClick={() => history.goBack()}
                        className="fas fa-chevron-left mr-3 cursorPointer"
                      >
                        {" "}
                      </i>
                      WHS RFQ Details
                    </h5>
                  </div>

                  <div className="col-12 bg-deep-gray py-3">
                    <div className="row">
                      <div className="col-12 px-0">
                        <div className="form-group form-inline mb-3 px-0">
                          <label
                            htmlFor="inputPassword6"
                            className="w-250px justify-content-start px-3"
                          >
                            {" "}
                            RFQ ID{" "}
                          </label>{" "}
                          <div className="row mx-md-0 mx-sm-3 mx-0">
                            <div className="col-12 px-sm-3 px-0">
                              <input
                                value={
                                  data.rfqDetail.data
                                    ? data.rfqDetail.data.customerRfq?.id
                                    : ""
                                }
                                type="text"
                                id="inputPassword6"
                                className="form-control d-inline-block form-control-sm w-160px mx-3"
                                placeholder="DL-01379"
                                readOnly
                              />
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-12 px-0">
                        <div className="row">
                          <div className="col-12">
                            <div className="row mx-0">
                              <div className="col-md-auto pl-0">
                                <label
                                  htmlFor="staticEmail"
                                  className="mb-2 w-250px justify-content-start px-3"
                                >
                                  {" "}
                                  State{" "}
                                </label>{" "}
                                <div className="form-group col-auto px-3 mb-3">
                                  <input
                                    value={
                                      data.rfqDetail.data &&
                                      data.rfqDetail.data.customerRfq
                                        ?.location !== null
                                        ? data.rfqDetail.data.customerRfq
                                            ?.location.city.name
                                        : ""
                                    }
                                    className="form-control form-control-sm w-160px"
                                    disabled
                                  />
                                </div>{" "}
                              </div>{" "}
                              <div className="col-md-auto pl-0">
                                <label
                                  htmlFor="staticEmail"
                                  className="mb-2 w-250px justify-content-start px-3"
                                >
                                  {" "}
                                  District{" "}
                                </label>{" "}
                                <div className="form-group col-auto px-3 mb-3">
                                  <input
                                    value={
                                      data.rfqDetail.data &&
                                      data.rfqDetail.data.customerRfq
                                        ?.location !== null
                                        ? data.rfqDetail.data.customerRfq
                                            ?.location.area.name
                                        : ""
                                    }
                                    className="form-control form-control-sm w-160px"
                                    disabled
                                  />
                                </div>{" "}
                              </div>
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                      {/* <div className="col-12 px-0">
                                                <div className="form-group form-inline mb-2 px-0">
                                                  <label htmlFor="inputPassword6" className="w-250px justify-content-start px-3">WH Space Required</label>
                                                  <div className="row mx-md-0 mx-sm-3 mx-0">
                                                    <div className="col-12 px-sm-3 px-0">
                                                      <input value={data.rfqDetail.data ? data.rfqDetail.data.customerRfq?.warehouseSpaceRequired : ""} type="text" id="inputPassword6" className="form-control d-inline-block form-control-sm w-160px mx-3" placeholder="Sqft" readOnly />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div> */}
                      <div className="col-12 px-0">
                        <div className="form-group form-inline mb-4 px-0">
                          <label
                            htmlFor="inputPassword6"
                            className="w-250px justify-content-start px-3"
                          >
                            {" "}
                            Warehouse Name{" "}
                          </label>{" "}
                          {/* <div className="row mx-md-0 mx-sm-3 mx-0">
                                                    <div className="col-12 px-sm-3 px-0"> */}{" "}
                          <input
                            value={
                              data.rfqDetail.data
                                ? data.rfqDetail.data?.warehouse?.warehouseId +
                                  " : " +
                                  data.rfqDetail.data?.warehouse?.warehouseName
                                : ""
                            }
                            type="text"
                            id="inputPassword6"
                            className="form-control d-inline-block form-control-sm mx-3"
                            placeholder="Sqft"
                            readOnly
                          />{" "}
                          {/* </div>
                                                  </div> */}{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>

                  <div className="col-12 py-3 px-0">
                    <h4 className="text-dark-blue"> WHS Internal Working </h4>{" "}
                  </div>

                  <div className={classes.root}>
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleChange("panel1", "locationdetails")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {" "}
                          <span className="accord-heading"> Location </span>
                        </Typography>
                      </AccordionSummary>{" "}
                      <AccordionDetails>
                        {" "}
                        {data.isLoading ? (
                          <CardLoader loaderCard="loaderCard" />
                        ) : (
                          <div className="col-12 bg-deep-gray">
                            <div className="row">
                              <div className="col-12 table-gray-admin table-responsive form-control-height">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <td className="col font-weight-bold">
                                        <p className="mb-0 pb-1">
                                          {" "}
                                          Location-Delhi{" "}
                                        </p>{" "}
                                      </td>{" "}
                                      <td className="w-200px mw-200px font-weight-bold">
                                        Input{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        <div className="pl-3"> Remarks </div>{" "}
                                      </td>{" "}
                                    </tr>{" "}
                                  </thead>{" "}
                                  <tbody>
                                    {values.locationDetails &&
                                      values.locationDetails.length > 0 &&
                                      values.locationDetails.map((r, index) => {
                                        const locationErrors =
                                          (errors.locationDetails?.length &&
                                            errors.locationDetails[index]) ||
                                          {};
                                        const locationTouched =
                                          (touched.locationDetails?.length &&
                                            touched.locationDetails[index]) ||
                                          {};

                                        return (
                                          <tr key={index}>
                                            <td>
                                              <p className="m-0 pb-1">
                                                {" "}
                                                {r.type}{" "}
                                              </p>{" "}
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`locationDetails.${index}.input`}
                                                type="text"
                                                id="location-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (locationErrors.input &&
                                                  locationTouched.input
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`locationDetails.${index}.input`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`locationDetails.${index}.remark`}
                                                type="text"
                                                className={
                                                  `form-control text-center bg-white my-1 px-4` +
                                                  (locationErrors.remark &&
                                                  locationTouched.remark
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                id="location-remarks1"
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`locationDetails.${index}.remark`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>{" "}
                              </div>{" "}
                            </div>{" "}
                            {/* </form> */}{" "}
                          </div>
                        )}
                      </AccordionDetails>{" "}
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleChange("panel2", "manpowers")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {" "}
                          <span className="accord-heading"> Man Powers </span>
                        </Typography>
                      </AccordionSummary>{" "}
                      <AccordionDetails>
                        {data.isLoading ? (
                          <CardLoader loaderCard="loaderCard" />
                        ) : (
                          <div className="col-12 bg-deep-gray">
                            <div className="row">
                              <div className="col-12 px-0 table-gray-admin table-responsive form-control-height">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th colSpan="4"> Send to Vendor </th>{" "}
                                      <th colSpan="5">
                                        {" "}
                                        Send to Vendor Blank / Auto update from
                                        RFQ reply from Vendor to WHS{" "}
                                      </th>{" "}
                                    </tr>{" "}
                                  </thead>{" "}
                                  <tbody>
                                    <tr>
                                      <td className="col font-weight-bold">
                                        Manpower:
                                      </td>{" "}
                                      <td className="w-200px mw-200px font-weight-bold">
                                        Qty{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Cost / Unit{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Total / Month{" "}
                                      </td>{" "}
                                      <td className="col font-weight-bold">
                                        Type{" "}
                                      </td>{" "}
                                      <td className="w-200px mw-200px font-weight-bold">
                                        Qty{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Cost / Unit{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Total / Month{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Remarks{" "}
                                      </td>{" "}
                                    </tr>

                                    {values.manPowers &&
                                      values.manPowers.length > 0 &&
                                      values.manPowers.map((r, index) => {
                                        const manErrors =
                                          (errors.manPowers?.length &&
                                            errors.manPowers[index]) ||
                                          {};
                                        const manTouched =
                                          (touched.manPowers?.length &&
                                            touched.manPowers[index]) ||
                                          {};

                                        return (
                                          <tr key={index}>
                                            <td>
                                              <p className="m-0 pb-1">
                                                {" "}
                                                {r.manpowerType}{" "}
                                              </p>{" "}
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`manPowers.${index}.quantity`}
                                                type="text"
                                                id="manpower-qty-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (manErrors.quantity &&
                                                  manTouched.quantity
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`manPowers.${index}.quantity`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`manPowers.${index}.costUnit`}
                                                type="text"
                                                id="manpower-cost-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (manErrors.costUnit &&
                                                  manTouched.costUnit
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`manPowers.${index}.costUnit`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`manPowers.${index}.totalMonth`}
                                                type="text"
                                                id="manpower-months-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (manErrors.totalMonth &&
                                                  manTouched.totalMonth
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`manPowers.${index}.totalMonth`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <select
                                                defaultValue="select"
                                                className="form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-130px"
                                                id="manpower-type1"
                                                disabled
                                              >
                                                <option> Select </option>{" "}
                                                <option> Shared </option>{" "}
                                                <option> Dedicated </option>{" "}
                                              </select>{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="manpower-qty-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="manpower-cost-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="manpower-total-months-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control text-center bg-white my-1 px-4"
                                                id="manpower-remarks1"
                                                disabled
                                              />
                                            </td>{" "}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>
                        )}{" "}
                      </AccordionDetails>{" "}
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleChange("panel3", "infrastructures")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {" "}
                          <span className="accord-heading">
                            {" "}
                            Infrastructure{" "}
                          </span>
                        </Typography>
                      </AccordionSummary>{" "}
                      <AccordionDetails>
                        {" "}
                        {data.isLoading ? (
                          <CardLoader loaderCard="loaderCard" />
                        ) : (
                          <div className="col-12 bg-deep-gray">
                            {" "}
                            {/* <form className=""> */}{" "}
                            <div className="row">
                              <div className="col-12 px-0 table-gray-admin table-responsive form-control-height">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th colSpan="9"> Send to Vendor </th>{" "}
                                      <th colSpan="5">
                                        {" "}
                                        Send to Vendor Blank / Auto update from
                                        RFQ reply from Vendor to WHS{" "}
                                      </th>{" "}
                                    </tr>{" "}
                                  </thead>{" "}
                                  <tbody>
                                    <tr>
                                      <td className="col mw-150px font-weight-bold">
                                        Infrastructure:
                                      </td>{" "}
                                      <td className="w-200px mw-200px font-weight-bold">
                                        Qty{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Cost / Unit{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Total / Month{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Years of Dep{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Dep{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Int{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        AMC{" "}
                                      </td>{" "}
                                      {/* <td className="mw-250px font-weight-bold">
                                                            Total/Month
                                                          </td> */}{" "}
                                      <td className="col font-weight-bold">
                                        Type{" "}
                                      </td>{" "}
                                      <td className="w-200px mw-200px font-weight-bold">
                                        Qty{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Cost / Unit{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Total / Month{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Remarks{" "}
                                      </td>{" "}
                                    </tr>

                                    {values.infrastructures &&
                                      values.infrastructures.length > 0 &&
                                      values.infrastructures.map((r, index) => {
                                        const infraErrors =
                                          (errors.infrastructures?.length &&
                                            errors.infrastructures[index]) ||
                                          {};
                                        const infraTouched =
                                          (touched.infrastructures?.length &&
                                            touched.infrastructures[index]) ||
                                          {};

                                        return (
                                          <tr key={index}>
                                            <td>
                                              <p className="m-0 pb-1">
                                                {" "}
                                                {r.infrastructureType}{" "}
                                              </p>{" "}
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`infrastructures.${index}.quantity`}
                                                type="number"
                                                id="infrastructure-qty-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (infraErrors.quantity &&
                                                  infraTouched.quantity
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`infrastructures.${index}.quantity`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`infrastructures.${index}.costUnit`}
                                                type="number"
                                                id="infrastructure-cost-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (infraErrors.costUnit &&
                                                  infraTouched.costUnit
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`infrastructures.${index}.costUnit`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`infrastructures.${index}.totalMonth`}
                                                type="number"
                                                id="infrastructure-months-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (infraErrors.totalMonth &&
                                                  infraTouched.totalMonth
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`infrastructures.${index}.totalMonth`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`infrastructures.${index}.yearDeap`}
                                                type="text"
                                                id="infrastructure-years-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (infraErrors.yearDeap &&
                                                  infraTouched.yearDeap
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`infrastructures.${index}.yearDeap`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`infrastructures.${index}.dep`}
                                                type="text"
                                                id="infrastructure-dep-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (infraErrors.dep &&
                                                  infraTouched.dep
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`infrastructures.${index}.dep`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`infrastructures.${index}.int`}
                                                type="text"
                                                id="infrastructure-int-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (infraErrors.int &&
                                                  infraTouched.int
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`infrastructures.${index}.int`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`infrastructures.${index}.amc`}
                                                type="text"
                                                id="infrastructure-amc-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (infraErrors.amc &&
                                                  infraTouched.amc
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`infrastructures.${index}.amc`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            {/* <td><Field name={`manPowers.${index}.quantity`} type="text" id="infrastructure-total-months-input1" className="form-control bg-white px-4"/></td> */}{" "}
                                            <td>
                                              <select
                                                defaultValue="select"
                                                className="form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-130px"
                                                id="infrastructure-type1"
                                                disabled
                                              >
                                                <option> Select </option>{" "}
                                                <option> Shared </option>{" "}
                                                <option> Dedicated </option>{" "}
                                              </select>{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="infrastructure-qty-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="infrastructure-cost-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="infrastructure-total-months-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control text-center bg-white my-1 px-4"
                                                id="infrastructure-remarks1"
                                                disabled
                                              />
                                            </td>{" "}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>
                        )}
                      </AccordionDetails>{" "}
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleChange("panel4", "runningcosts")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {" "}
                          <span className="accord-heading"> Running Cost </span>
                        </Typography>
                      </AccordionSummary>{" "}
                      <AccordionDetails>
                        {data.isLoading ? (
                          <CardLoader loaderCard="loaderCard" />
                        ) : (
                          <div className="col-12 bg-deep-gray">
                            <div className="row">
                              <div className="col-12 px-0 table-gray-admin table-responsive form-control-height">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th colSpan="4"> Send to Vendor </th>{" "}
                                      <th colSpan="5">
                                        {" "}
                                        Send to Vendor Blank / Auto update from
                                        RFQ reply from Vendor to WHS{" "}
                                      </th>{" "}
                                    </tr>{" "}
                                  </thead>{" "}
                                  <tbody>
                                    <tr>
                                      <td className="col mw-150px font-weight-bold">
                                        Running Cost:
                                      </td>{" "}
                                      <td className="w-200px mw-200px font-weight-bold">
                                        Qty{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Cost / Unit{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Amount{" "}
                                      </td>{" "}
                                      <td className="col font-weight-bold">
                                        Type{" "}
                                      </td>{" "}
                                      <td className="w-200px mw-200px font-weight-bold">
                                        Qty{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Cost / Unit{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Total / Month{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Remarks{" "}
                                      </td>{" "}
                                    </tr>

                                    {values.runningCosts &&
                                      values.runningCosts.length > 0 &&
                                      values.runningCosts.map((r, index) => {
                                        const runningErrors =
                                          (errors.runningCosts?.length &&
                                            errors.runningCosts[index]) ||
                                          {};
                                        const runningTouched =
                                          (touched.runningCosts?.length &&
                                            touched.runningCosts[index]) ||
                                          {};

                                        return (
                                          <tr key={index}>
                                            <td>
                                              <p className="m-0 pb-1">
                                                {" "}
                                                {r.runningCostType}{" "}
                                              </p>{" "}
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`runningCosts.${index}.quantity`}
                                                type="number"
                                                id="runningcost-qty-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (runningErrors.quantity &&
                                                  runningTouched.quantity
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`runningCosts.${index}.quantity`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`runningCosts.${index}.costUnit`}
                                                type="number"
                                                id="runningcost-cost-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (runningErrors.costUnit &&
                                                  runningTouched.costUnit
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`runningCosts.${index}.costUnit`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`runningCosts.${index}.amount`}
                                                type="number"
                                                id="runningcost-amount-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (runningErrors.amount &&
                                                  runningTouched.amount
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`runningCosts.${index}.amount`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                as="select"
                                                name={`runningCosts.${index}.vendorType`}
                                                className="form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-130px"
                                                id="runningcost-type1"
                                                disabled
                                              >
                                                <option> Select </option>{" "}
                                                <option value="shared">
                                                  {" "}
                                                  Shared{" "}
                                                </option>{" "}
                                                <option value="dedicated">
                                                  {" "}
                                                  Dedicated{" "}
                                                </option>{" "}
                                              </Field>{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="runningcost-qty-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="runningcost-cost-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              {" "}
                                              <input
                                                type="text"
                                                id="runningcost-total-months-vendorinput1"
                                                className="form-control bg-white px-4"
                                                disabled
                                              />{" "}
                                            </td>{" "}
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control text-center bg-white my-1 px-4"
                                                id="runningcost-remarks1"
                                                disabled
                                              />
                                            </td>{" "}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>
                        )}
                      </AccordionDetails>{" "}
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleChange("panel5", "monthlycosts")}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography className={classes.heading}>
                          {" "}
                          <span className="accord-heading"> Monthly Cost </span>
                        </Typography>
                      </AccordionSummary>{" "}
                      <AccordionDetails>
                        {data.isLoading ? (
                          <CardLoader loaderCard="loaderCard" />
                        ) : (
                          <div className="col-12 bg-deep-gray py-3">
                            <div className="row">
                              <div className="col-12 table-gray-admin table-responsive form-control-height">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th colSpan="2"> </th>{" "}
                                      <th>
                                        <div className="pl-3">
                                          {" "}
                                          Send to Vendor{" "}
                                        </div>{" "}
                                      </th>{" "}
                                    </tr>{" "}
                                  </thead>{" "}
                                  <tbody>
                                    <tr>
                                      <td className="col mw-150px font-weight-bold">
                                        Monthly Cost{" "}
                                      </td>{" "}
                                      <td className="w-200px mw-200px font-weight-bold">
                                        Year I{" "}
                                      </td>{" "}
                                      <td className="mw-250px font-weight-bold">
                                        Remarks{" "}
                                      </td>{" "}
                                    </tr>

                                    {values.monthlyCosts &&
                                      values.monthlyCosts.length > 0 &&
                                      values.monthlyCosts.map((r, index) => {
                                        const monthErrors =
                                          (errors.monthlyCosts?.length &&
                                            errors.monthlyCosts[index]) ||
                                          {};
                                        const monthTouched =
                                          (touched.monthlyCosts?.length &&
                                            touched.monthlyCosts[index]) ||
                                          {};

                                        return (
                                          <tr key={index}>
                                            <td>
                                              <p className="m-0 pb-1">
                                                {" "}
                                                {r.monthlyCostType}{" "}
                                              </p>{" "}
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`monthlyCosts.${index}.year`}
                                                type="number"
                                                id="monthlycost-year-input1"
                                                className={
                                                  `form-control bg-white px-4` +
                                                  (monthErrors.year &&
                                                  monthTouched.year
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                                disabled
                                              />
                                              <ErrorMessage
                                                name={`monthlyCosts.${index}.year`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </td>{" "}
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control text-center bg-white my-1 px-4"
                                                id="monthlycost-year-remarks1"
                                                disabled
                                              />
                                            </td>{" "}
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>{" "}
                              </div>{" "}
                              <div className="col-12 mt-5 d-none">
                                <div className="row justify-content-end">
                                  <div className="col-auto">
                                    <button
                                      type="submit"
                                      className="btn btn-deep-primary mb-3 add-class remove-class"
                                      data-add-target=".steps11"
                                      data-add-target-class="d-none"
                                      data-remove-target=".steps12"
                                      data-remove-target-class="d-none"
                                    >
                                      {" "}
                                      Submit{" "}
                                    </button>{" "}
                                  </div>{" "}
                                </div>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>
                        )}{" "}
                      </AccordionDetails>{" "}
                    </Accordion>{" "}
                  </div>

                  <div className="col-12">
                    <div className="row justify-content-start">
                      <div className="col-auto">
                        <button
                          onClick={() => history.goBack()}
                          type="button"
                          className="btn btn-outline-secondary mr-3 my-2 py-1"
                        >
                          {" "}
                          Back{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                </div>{" "}
              </Form>{" "}
            </div>
          );
        }}
      />
    </LayoutOne>
  );
};

export default WhsToVendorRFQ;
