import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { updateWarehouseContact } from './../../../store/action/warehouseAction';
// import { changeWarehouseStatus } from '../../store/actions/vendor/warehouseList';
// import { maxLengthCheck, consiseContactDetailsSchema } from '../../validation';
// import { updateWarehouseContact } from '../../store/actions/vendor/warehouseList';
import CustomLoader from '../../../CustomLoader';
import { useDispatch, useSelector } from 'react-redux';
import pinCode from '../../../json/pincode.json';

// import getLatLng from './getLatLng';
// import { CardLoader } from '../../helper/CustomLoader';

const ContactPendingForm = ({ warehouseId, viewMood, slug, closeErrorMessage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const category = useSelector((state) => state.CATEGORY_INFO.categoryList);
  const typeWh = useSelector((state) => state.WHY_INFO);

  const [contactForm, setContactForm] = useState({
    warehouseName: '',
    warehouseCategory: '',
    warehouseType: '',
    address1: '',
    address2: '',
    country: 'India',
    state: '',
    district: '',
    pincode: '',
    gpsLatitude: '',
    gpsLongitude: '',
  });

  // async function getLatLongBlur(e) {
  //   const getlonglat = await getLatLng(e);
  //   if (getlonglat !== 'error') {
  //     return getlonglat;
  //   } else {
  //     return null;
  //   }
  // }
  function handleBlur() {
    console.log(contactForm);
  }

  function pinCodeChange(pin) {
    let pinCodeObject = pinCode.filter((v) => parseInt(v.pincode) === parseInt(pin));
    if (pinCodeObject.length > 0) {
      return pinCodeObject[0];
    } else {
      return null;
    }
  }

  // useEffect(() => {
  //   if (data?.addNewResponse?.statusCode === 200) {
  //     history.push(`/vendor/warehouse/update-warehouse/${warehouseId}/storage-space`);
  //     // dispatch(changeWarehouseStatus());
  //   }
  // }, [data, history, dispatch]);

  useEffect(() => {
    console.log(data, 'contact-detail', warehouseId, slug);
    if (data?.singleFormData?.remarks === 0) {
      if (data?.singleFormData?.warehouseRes?.warehouseContacts !== undefined) {
        console.log('hey i am running');
        setContactForm({ ...data?.singleFormData?.warehouseRes?.warehouseContacts[0] });
      }
    }
  }, [data?.singleFormData]);

  return (
    <>
      {/* <FormMessage
        onClick={() => {
          dispatch(clearMessageAction())
          // alert('dfs');
        }}
        message='DSDSD'
      /> */}

      {/* {data.addNewResponse.message} */}
      {data?.isLoading ? (
        <CustomLoader />
      ) : (
        <div className='row align-items-center justify-content-center mx-0'>
          <div className='col-11'>
            <Formik
              enableReinitialize={true}
              initialValues={contactForm}
              // validationSchema={consiseContactDetailsSchema}
              onSubmit={(fields) => {
                console.log(fields, 'Final action');
                fields.accountId = 1;
                // dispatch(updateWarehouseContact({ fields, slug, warehouseId }));
              }}
              render={({ errors, status, onChange, setFieldValue, touched, values }) => (
                <Form>
                  <div className='row'>
                    <div className='col-4'>
                      <div className='form-group mb-1'>
                        <label htmlFor='warehouseName' className='mb-1'>
                          Warehouse Display Name
                        </label>
                        <Field
                          name='warehouseName'
                          // onChange={(e) => {
                          //   setFieldValue('warehouseName', e.target.value);
                          //   console.log(e.target.value, 'current calye');
                          // }}
                          onBlur={handleBlur}
                          updatecase='updatecaseaction'
                          disabled={true}
                          className={
                            'form-control form-control-sm bg-white' +
                            (errors.warehouseName && touched.warehouseName ? ' is-invalid' : '')
                          }
                          placeholder='Enter Display Name'
                          id='warehouseName'
                        />
                        <ErrorMessage
                          name='warehouseName'
                          component='div'
                          className='invalid-feedback'
                        />
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className='form-group mb-1'>
                        <label htmlFor='warehouseCategory' className='mb-1'>
                          Select Warehouse Category
                        </label>
                        <Field
                          disabled={viewMood}
                          name='warehouseCategory'
                          as='select'
                          className={
                            'form-control bg-white px-4 common-select-deep-blue w-100' +
                            (errors.warehouseCategory && touched.warehouseCategory
                              ? ' is-invalid'
                              : '')
                          }
                          id='warehouseCategory'
                        >
                          <option value=''>Select Category</option>

                          {category && category.length > 0
                            ? category
                                .filter((item) => item.categoryStatus === true)
                                .map((item, index) => {
                                  return (
                                    <option value={item.id} key={index} className='text-capitalize'>
                                      {item.categoryName}
                                    </option>
                                  );
                                })
                            : null}
                        </Field>
                        <ErrorMessage
                          name='warehouseCategory'
                          component='div'
                          className='invalid-feedback'
                        />
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className='form-group mb-1'>
                        <label htmlFor='warehouseType' className='mb-1'>
                          Select Warehouse Type
                        </label>
                        <Field
                          disabled={viewMood}
                          name='warehouseType'
                          as='select'
                          className={
                            'form-control bg-white px-4 common-select-deep-blue w-100' +
                            (errors.warehouseType && touched.warehouseType ? ' is-invalid' : '')
                          }
                          id='warehouseType'
                        >
                          <option value=''>Select Warehouse Type</option>
                          {typeWh?.typeList?.data && typeWh?.typeList?.data.length > 0
                            ? typeWh?.typeList?.data.map((item, index) => {
                                return (
                                  <option value={item.id} key={index} className='text-capitalize'>
                                    {item.type}
                                  </option>
                                );
                              })
                            : null}
                        </Field>
                        <ErrorMessage
                          name='warehouseType'
                          component='div'
                          className='invalid-feedback'
                        />
                      </div>
                    </div>
                    <div className='col-md-4 col-12'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='form-group mb-1'>
                            <label htmlFor='staticEmail' className='mb-1'>
                              Pincode:
                            </label>
                            <Field
                              name='pincode'
                              // onBlur={getLatLongBlur}
                              type='number'
                              className={
                                'form-control form-control-sm bg-white ' +
                                (errors.pincode && touched.pincode ? ' is-invalid' : '')
                              }
                              placeholder='Enter Pincode'
                              onChange={async (e) => {
                                // let data = pinCodeChange(e.target.value);
                                // setFieldValue('pincode', parseInt(e.target.value));
                                // if (data) {
                                //   setFieldValue('state', data.stateName);
                                //   setFieldValue('district', data.districtName);
                                // }
                                // let getlatlong = await getLatLongBlur(e.target.value);
                                // if (getlatlong !== null) {
                                //   setFieldValue('gpsLatitude', getlatlong.lat);
                                //   setFieldValue('gpsLongitude', getlatlong.lng);
                                // }
                              }}
                              maxLength='6'
                              // onInput={maxLengthCheck}
                              onKeyDown={(e) => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                              disabled={viewMood}
                            />
                            <ErrorMessage
                              name='pincode'
                              component='div'
                              className='invalid-feedback'
                            />
                          </div>
                        </div>

                        <div className='col-12'>
                          <div className='form-group  mb-1 '>
                            <label htmlFor='country' className='mb-1'>
                              Country:
                            </label>
                            <input
                              type='text'
                              className='form-control'
                              value={'India'}
                              name='country'
                              disabled={viewMood}
                            />
                            <ErrorMessage
                              name='country'
                              component='div'
                              className='invalid-feedback'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-12' style={{ display: 'none' }}>
                        <div className='form-group col-12 mb-1  mt-2'>
                          <label htmlFor='staticEmail' className='mb-1 px-2'>
                            Warehouse GPS Location - latitude{' '}
                          </label>
                          <Field
                            name='gpsLatitude'
                            value={contactForm.gpsLatitude}
                            type='number'
                            className={
                              'form-control form-control-sm bg-white px-4' +
                              (errors.gpsLatitude && touched.gpsLatitude ? ' is-invalid' : '')
                            }
                            placeholder='Warehouse GPS Location -  latitude '
                            disabled={viewMood}
                          />
                          <ErrorMessage
                            name='gpsLatitude'
                            component='div'
                            className='invalid-feedback'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 col-12'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='form-group mb-1'>
                            <label htmlFor='state' className='mb-1'>
                              {' '}
                              State:
                            </label>

                            <Field
                              name='state'
                              //  as="select"
                              type='text'
                              className={
                                'form-control form-control-sm bg-white' +
                                (errors.state && touched.state ? ' is-invalid' : '')
                              }
                              id='state'
                              disabled={viewMood}
                            />
                            <ErrorMessage
                              name='state'
                              component='div'
                              className='invalid-feedback'
                            />
                          </div>
                        </div>

                        <div className='col-12'>
                          <div className='form-group mb-1'>
                            <label htmlFor='address1' className='mb-1'>
                              Address 1:
                            </label>
                            <Field
                              name='address1'
                              type='text'
                              className={
                                'form-control form-control-sm bg-white' +
                                (errors.address1 && touched.address1 ? ' is-invalid' : '')
                              }
                              placeholder='Enter Address'
                              disabled={viewMood}
                            />
                            <ErrorMessage
                              name='address1'
                              component='div'
                              className='invalid-feedback'
                            />
                          </div>
                        </div>

                        <div className='col-12' style={{ display: 'none' }}>
                          <div className='form-group col-12 mb-1  mt-2'>
                            <label htmlFor='staticEmail' className='mb-1 px-2'>
                              Warehouse GPS Location - longitude
                            </label>
                            <Field
                              name='gpsLongitude'
                              type='number'
                              value={contactForm.gpsLongitude}
                              className={
                                'form-control form-control-sm bg-white px-4' +
                                (errors.gpsLongitude && touched.gpsLongitude ? ' is-invalid' : '')
                              }
                              placeholder='Warehouse GPS Location -  longitude'
                              disabled={viewMood}
                            />
                            <ErrorMessage
                              name='gpsLongitude'
                              component='div'
                              className='invalid-feedback'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 col-12'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='form-group mb-1'>
                            <label htmlFor='staticEmail' className='mb-1'>
                              District:
                            </label>
                            <Field
                              name='district'
                              type='text'
                              className={
                                'form-control form-control-sm bg-white w-100 ' +
                                (errors.district && touched.district ? ' is-invalid' : '')
                              }
                              placeholder=''
                              disabled={viewMood}
                            />
                            <ErrorMessage
                              name='district'
                              component='div'
                              className='invalid-feedback'
                            />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='form-group mb-1 '>
                            <label htmlFor='staticEmail' className='mb-1'>
                              {' '}
                              Address 2:
                            </label>
                            <Field
                              name='address2'
                              type='text'
                              className={
                                'form-control form-control-sm bg-white' +
                                (errors.address2 && touched.address2 ? ' is-invalid' : '')
                              }
                              placeholder='Enter Address'
                              disabled={viewMood}
                            />
                            <ErrorMessage
                              name='address2'
                              component='div'
                              className='invalid-feedback'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`col-12 mt-2 ${viewMood ? 'd-none' : ''}`}>
                      <div
                        className={`${
                          data?.isError ? 'row justify-content-between' : 'row justify-content-end'
                        }`}
                      >
                        {data?.isError ? (
                          <div class='alert alert-warning alert-dismissible fade show' role='alert'>
                            {data.isError}.
                            <button
                              type='button'
                              class='btn-close'
                              data-bs-dismiss='alert'
                              aria-label='Close'
                              onClick={closeErrorMessage}
                            ></button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ContactPendingForm;
