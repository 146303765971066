import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { updateFilter } from "../../store/action/filterAction";
import { useDispatch } from "react-redux";

const AutoFilterList = ({ index, item }) => {
  const [isActive, setIsActive] = useState(null);
  const dispatch = useDispatch();

  // Status Change confirmation
  const statusChange = (isActive) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to change status.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              updateFilter(item.id, {
                status: isActive,
              })
            ),
        },
        {
          label: "No",
          onClick: () => setIsActive(!isActive),
        },
      ],
    });
  };

  const haldleChange = (e) => {
    setIsActive(e.target.value === "true");
    statusChange(e.target.value === "true");
  };

  useEffect(() => {
    setIsActive(item.status);
  }, [item.status]);

  return (
    <tr>
      <td className="">
        <div className="text-gray text-uppercase small text-nowrap">
          {" "}
          FILTER NAME{" "}
        </div>{" "}
        <div className="font-weight-bold text-capitalize text-nowrap">
          {" "}
          {item?.filterType}{" "}
        </div>{" "}
      </td>
      <td className="">
        <div className="text-gray text-uppercase small text-nowrap">
          {" "}
          FILTER Type{" "}
        </div>{" "}
        <div className="font-weight-bold text-capitalize text-nowrap">
          {" "}
          {item?.type}{" "}
        </div>{" "}
      </td>
      <td className="">
        <div className="text-gray text-uppercase small"> </div>{" "}
        <div className="font-weight-bold"> </div>{" "}
      </td>{" "}
      <td className="">
        <div className="font-weight-bold">
          <div className="d-inline-block">
            <select
              onChange={haldleChange}
              name="status"
              value={isActive}
              className="custom-select py-0 common-select-deep-blue"
            >
              <option value={true}> Active </option>{" "}
              <option value={false}> Inactive </option>{" "}
            </select>{" "}
          </div>{" "}
        </div>{" "}
      </td>
    </tr>
  );
};

export default AutoFilterList;
