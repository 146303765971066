import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React, { Suspense, lazy, useEffect } from 'react';
// Public Style
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/common.scss';
import './style/custom.css';
import './style/component.scss';
import './style/successModal.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import loadjs from 'loadjs';
import store from './store';

import requiredAuth from './hoc/protectedRoute/requiredAuth';
import noRequiredAuth from './hoc/protectedRoute/noRequiredAuth';
import { authenticated } from './store/action/account/login';

import EnquiryDetails from './pages/enquiryManagement/EnquiryPendingDetails';

import AddNewCustomer from './pages/userManagement/AddNewCustomer';
import AddNewVendor from './pages/userManagement/AddNewVendor';

import WarehouseReport from './pages/report/WarehouseReport';
import UserReport from './pages/report/UserReport';
import VendorReport from './pages/report/VendorReport';
import SubAdminReport from './pages/report/SubAdminReport';
import WHSUserBuilkUpload from './pages/whsUserManagement/WHSUserBuilkUpload';
import WHSUserDetails from './pages/whsUserManagement/WHSUserDetails';
import EditFilter from './pages/filterManagement/EditFilter';
import EditWHSUser from './pages/whsUserManagement/EditWHSUser';
import CreateWHSRole from './pages/whsUserManagement/CreateWHSRole';
import WHSUserRoleDetails from './pages/whsUserManagement/WHSUserRoleDetails';
import RolePermissionUser from './pages/whsUserManagement/RolePermissionUser';
import DailyProgressReport from './pages/report/DailyProgressReport';
import NoSearchReport from './pages/report/NoSearchReport';
import Faq from './pages/cms/Faq';
import FaqCusomer from './pages/cms/FaqCustomer';

import TermAndCondition from './pages/cms/TermAndCondition';
import ReturnPolicy from './pages/cms/ReturnPolicy';
import Privacy from './pages/cms/Privacy';
// import ManageService from './pages/serviceManagement/ManageService';
import ViewService from './pages/serviceManagement/ViewService';
import AddService from './pages/serviceManagement/AddService';
import RateMatrix from './pages/serviceManagement/Graph';

import EditService from './pages/serviceManagement/EditService';
// import ManageServiceCategory from './pages/serviceManagement/ManageServiceCategory';
import ViewServiceCategory from './pages/serviceManagement/ViewServiceCategory';
import AddServiceCategory from './pages/serviceManagement/AddServiceCategory';
import EditServiceCategory from './pages/serviceManagement/EditServiceCategory';
// import ManageServiceSubCategory from './pages/serviceManagement/ManageServiceSubCategory';
import AddServiceSubCategory from './pages/serviceManagement/AddServiceSubCategory';
import EditServiceSubCategory from './pages/serviceManagement/EditServiceSubCategory';
import ViewServiceSubCategory from './pages/serviceManagement/ViewServiceSubCategory';
// import UserInvoiceManage from './pages/invoiceManagement/UserInvoiceManage';
// import VendorInvoiceManage from './pages/invoiceManagement/VendorInvoiceManage';
import CreateUserInvoice from './pages/invoiceManagement/CreateUserInvoice';
import CreateVendorInvoice from './pages/invoiceManagement/CreateVendorInvoice';
import EditUserInvoice from './pages/invoiceManagement/EditUserInvoice';
import EditVendorInvoice from './pages/invoiceManagement/EditVendorInvoice';
import UserInvoiceDetails from './pages/invoiceManagement/UserInvoiceDetails';
import VendorInvoiceDetails from './pages/invoiceManagement/VendorInvoiceDetails';
import CustomLoader from './components/common/CustomLoader';
import ManageRFQ from './pages/rfq/ManageRFQ';
import ManageRFQView from './pages/rfq/ManageRFQView';
import OpenRFQResponse from './pages/rfq/OpenRFQResponse';
import OpenRFQResponseView from './pages/rfq/OpenRFQResponseView';
import Login from './pages/account/Login';
import PendingWarehouse from './pages/warehouseManagement/PendingWarehouse';
import ApprovedWarehouse from './pages/warehouseManagement/ApprovedWarehouse';
import RejectedWarehouse from './pages/warehouseManagement/RejectedWarehouse';
import ApprovedDetails from './pages/warehouseManagement/ApprovedDetails';
import PendingDetails from './pages/warehouseManagement/PendingDetails';
import RejectDetails from './pages/warehouseManagement/RejectDetails';
import ManageRFQList from './pages/rfq/ManageRFQList';
import RejectedFieldAgent from './pages/warehouseManagement/RejectedFieldAgent';
import ApprovedFieldAgent from './pages/warehouseManagement/ApprovedFieldAgent';

import AssignedFieldAgent from './pages/warehouseManagement/AssignedFieldAgent';
import AssignedFieldAgentDetails from './pages/warehouseManagement/AssignedFieldAgentDetails';
import RejectedFieldAgentDetails from './pages/warehouseManagement/RejectedFieldAgentDetails';

import FieldAgentPending from './pages/fieldAgentManagement/FieldAgentPending';
import FieldAgentPendingDetail from './pages/fieldAgentManagement/FieldAgentPendingDetail';
import FieldAgentApprove from './pages/fieldAgentManagement/FieldAgentApprove';
import FieldAgentApproveDetail from './pages/fieldAgentManagement/FieldAgentApproveDetail';
import FieldAgentReject from './pages/fieldAgentManagement/FieldAgentReject';
import FieldAgentRejectDetail from './pages/fieldAgentManagement/FieldAgentRejectDetail';
import AssignFormToFieldAgent from './pages/warehouseManagement/AssignFormToFieldAgent';
import CustomerRFQDetails from './pages/rfq/CustomerRFQDetails';
import WhsToCustomerRFQ from './pages/rfq/WshToCustomerRFQ';
import SendWhsToCustomer from './pages/rfq/SendWhsToCustomer';
import CreateRfqByAdmin from './pages/rfq/CreateRfqByAdmin';
import VendorToWhsRFQ from './pages/rfq/VendorToWhsRFQ';
import WhsToVendorRFQ from './pages/rfq/WhsToVendorRFQ';
import CustomerPending from './pages/enquiryManagement/CustomerPending';
import CustomerApprove from './pages/enquiryManagement/CustomerApprove';
import VendorPending from './pages/enquiryManagement/VendorPending';
import VendorApprove from './pages/enquiryManagement/VendorApprove';
import EnquiryPendingDetails from './pages/enquiryManagement/EnquiryPendingDetails';
import EnquiryApproveDetails from './pages/enquiryManagement/EnquiryApproveDetails';
import EnquiryPendingDetailsVendor from './pages/enquiryManagement/EnquiryPendingDetailsVendor';
import WhsDepartment from './pages/whsUserManagement/WhsDepartment';
import WHSUserDepartmentDetails from './pages/whsUserManagement/WHSUserDepartmentDetails';
import CreateWHSDepartment from './pages/whsUserManagement/CreateWHSDepartment';
import WhyWarehouse from './pages/whyWarehousity/WhyWarehouse';
import CreateWhy from './pages/whyWarehousity/CreateWhy';
import TypeWarehouse from './pages/whyWarehousity/TypeWarehouse';
import CreateType from './pages/whyWarehousity/CreateType';
import UpdateWhy from './pages/whyWarehousity/UpdateWhy';
import UpdateType from './pages/whyWarehousity/UpdateType';
import TypeDetail from './pages/whyWarehousity/TypeDetail';
import WhyDetail from './pages/whyWarehousity/WhyDetail';
import SpaceCertificate from './pages/documents/SpaceCertificate';
// import OnBoardFormList from './wrapper/customerOnboarding/OnBoardFormList';
import ManageContactOnboard from './pages/customerOnboarding/ManageContactOnboard';
import ManageCostOnBoard from './pages/customerOnboarding/ManageCostOnBoard';
import ManageBillingOnBoard from './wrapper/customerOnboarding/ManageBillingOnBoard';
import ManageCSOnBoard from './wrapper/customerOnboarding/ManageCSOnBoard';
import ManageOperationOnBoard from './wrapper/customerOnboarding/ManageOperationOnBoard';
import ManageSpecialOnBoard from './wrapper/customerOnboarding/ManageSpecialOnBoard';
import FilterOption from './pages/filterManagement/FilterOption';
import AddFilterOption from './pages/filterManagement/AddFilterOption';
import UpdateFilter from './pages/filterManagement/UpdateFilter';
import UpdateFilterOption from './pages/filterManagement/UpdateFilterOption';
import UpdateCategory from './pages/categoryManagement/UpdateCategory';
import loadjs from 'loadjs';
import Demography from './pages/dashboard/Demography';
import ManageSpace from './pages/manageSpace/ManageSpace';
import CustomerSummery from './pages/dashboard/CustomerSummery';
import AutoFilter from './pages/filterManagement/AutoFilter';
import FetureWarehouse from './pages/fetureManagement/FetureWarehouse';
import FeatureDetails from './pages/fetureManagement/FeatureDetails';
import ServiceReceive from './pages/serviceManagement/ServiceReceive';
import ServiceReceiveDetail from './pages/serviceManagement/ServiceReceiveDetail';
import { ToastProvider } from 'react-toast-notifications';
import UserContact from './pages/enquiryManagement/UserContact';
import CustomerCancelled from './pages/enquiryManagement/CustomerCancelled';
import Feedback from './pages/feedback/Feedback';
import FeedbackDetails from './pages/feedback/FeedbackDetails';
import Zone from './pages/zone/Zone';
import Dashboard from './pages/dashboard/Dashboard';
import Vendor from './pages/userManagement/Vendor';
import Customer from './pages/userManagement/Customer';
import ServiceUser from './pages/userManagement/ServiceUser';
import UserDetails from './pages/userManagement/UserDetails';
import Booking from './pages/bookingManagement/Booking';
import BookingApproved from './pages/bookingManagement/BookingApproved';
import BookingPending from './pages/bookingManagement/BookingPending';
import BookingCancelled from './pages/bookingManagement/BookingCancelled';
import BookingDetails from './pages/bookingManagement/BookingDetails';
import Documents from './pages/documents/Documents';
import CustomerDocuments from './pages/documents/CustomerDocuments';
import VendorDocuments from './pages/documents/VendorDocuments';
import Category from './pages/categoryManagement/Category';
import AddCategory from './pages/categoryManagement/AddCategory';
// import Filter from "./pages/filterManagement/Filter";
import AddFilter from './pages/filterManagement/AddFilter';
import Review from './pages/reviewManagement/Review';
import ReviewDetails from './pages/reviewManagement/ReviewDetails';
import Notification from './pages/notification/Notification';
import CreateNotification from './pages/notification/CreateNotification';
import AboutUs from './pages/cms/AboutUs';
import WHSListingReport from './pages/report/WHSListingReport';
import Setting from './pages/setting/Setting';
import WHSUser from './pages/whsUserManagement/WHSUser';
import WHSUserRole from './pages/whsUserManagement/WHSUserRole';
import AddWHSUser from './pages/whsUserManagement/AddWHSUser';
import CustomerManage from './pages/customerOnboarding/CustomerManage';
import CustomerContactDetails from './pages/customerOnboarding/CustomerContactDetails';
import CustomerCostSheet from './pages/customerOnboarding/CustomerCostSheet';
import CustomerBillingSupport from './pages/customerOnboarding/CustomerBillingSupport';
import CustomerTransitionOperation from './pages/customerOnboarding/CustomerTransitionOperation';
import CustomerTransitionCS from './pages/customerOnboarding/CustomerTransitionCS';
import CustomerSpecialInstruction from './pages/customerOnboarding/CustomerSpecialInstruction';
import CustomerManageUpdate from './pages/customerOnboarding/CustomerManageUpdate';
import ManageService from './pages/serviceManagement/ManageService';
import ManageServiceCategory from './pages/serviceManagement/ManageServiceCategory';
import ManageServiceSubCategory from './pages/serviceManagement/ManageServiceSubCategory';
// React Lazy

// const CustomerManageUpdate = lazy(() =>
//   import("./pages/customerOnboarding/CustomerManageUpdate")
// );
// const ManageService = lazy(() =>
//   import("./pages/serviceManagement/ManageService")
// );
// const ManageServiceCategory = lazy(() =>
//   import("./pages/serviceManagement/ManageServiceCategory")
// );
// const ManageServiceSubCategory = lazy(() =>
//   import("./pages/serviceManagement/ManageServiceSubCategory")
// // );
// const UserInvoiceManage = lazy(() =>
//   import("./pages/invoiceManagement/UserInvoiceManage")
// );
// const VendorInvoiceManage = lazy(() =>
//   import("./pages/invoiceManagement/VendorInvoiceManage")
// );

// const CreateRFQ = lazy(() => import("./pages/rfq/CreateRFQ"));
const user = localStorage.getItem('accesstoken');

if (user) {
  store.dispatch(authenticated(true));
}

const App = () => {
  useEffect(() => {
    loadjs('/assets/js/jquery.min.js', function () {
      loadjs('/assets/plugins/bootstrap/js/bootstrap.bundle.min.js', function () {});
    });
  });
  return (
    <ToastProvider placement='bottom-right' autoDismiss autoDismissTimeout={5000}>
      <Suspense fallback={<CustomLoader />}>
        <Router>
          <Switch>
            {' '}
            {/* User Account */}{' '}
            <Route exact path={process.env.PUBLIC_URL + '/'} component={noRequiredAuth(Login)} />
            {/* Dashboard */}{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/dashboard'}
              component={requiredAuth(Dashboard)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/dashboard-demography'}
              component={requiredAuth(Demography)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-dashboard'}
              component={requiredAuth(CustomerSummery)}
            />
            {/* User Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/vendor'}
              component={requiredAuth(Vendor)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/vendor/:userId'}
              component={requiredAuth(UserDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer'}
              component={requiredAuth(Customer)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer/:userId'}
              component={requiredAuth(UserDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-user'}
              component={requiredAuth(ServiceUser)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-user/:userId'}
              component={requiredAuth(UserDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/add-customer'}
              component={requiredAuth(AddNewCustomer)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/add-vendor'}
              component={requiredAuth(AddNewVendor)}
            />
            {/* Feture warehouse */}{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/feature-warehouse'}
              component={requiredAuth(FetureWarehouse)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/feature-warehouse/:featureId'}
              component={requiredAuth(FeatureDetails)}
            />
            {/* WHS User Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/wsh-user'}
              component={requiredAuth(WHSUser)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/rate-matrix'}
              component={requiredAuth(RateMatrix)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/wsh-bulk-upload'}
              component={requiredAuth(WHSUserBuilkUpload)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/edit-whs-user/:whsId'}
              component={requiredAuth(EditWHSUser)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/wsh-user-details/:whsUserId'}
              component={requiredAuth(WHSUserDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/wsh-user-role'}
              component={requiredAuth(WHSUserRole)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/wsh-department'}
              component={requiredAuth(WhsDepartment)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/create-wsh-role'}
              component={requiredAuth(CreateWHSRole)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/create-wsh-department'}
              component={requiredAuth(CreateWHSDepartment)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/wsh-role-details/:roleId'}
              component={requiredAuth(WHSUserRoleDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/wsh-department-details/:departmentId'}
              component={requiredAuth(WHSUserDepartmentDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/edit-role-permission/:roleId'}
              component={requiredAuth(RolePermissionUser)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/add-whs-user'}
              component={requiredAuth(AddWHSUser)}
            />
            {/* Warehouse Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/pending-warehouse'}
              component={requiredAuth(PendingWarehouse)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/pending-details/:warehouseId/:slug'}
              component={requiredAuth(PendingDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/approved-warehouse'}
              component={requiredAuth(ApprovedWarehouse)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/approved-details/:warehouseId/:slug'}
              component={requiredAuth(ApprovedDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/rejected-warehouse'}
              component={requiredAuth(RejectedWarehouse)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/rejected-details/:warehouseId/:slug'}
              component={requiredAuth(RejectDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/assign-to-field-agent'}
              component={requiredAuth(AssignedFieldAgent)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/assign-to-field-agent-rejected'}
              component={requiredAuth(RejectedFieldAgent)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/assign-to-field-agent-approved'}
              component={requiredAuth(ApprovedFieldAgent)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/assign-to-field-agent/:warehouseId'}
              component={requiredAuth(AssignedFieldAgentDetails)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/assign-to-field-agent-rejected/:warehouseId'}
              component={requiredAuth(RejectedFieldAgentDetails)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/assign-form-to-field-agent/:warehouseId'}
              component={requiredAuth(AssignFormToFieldAgent)}
            />
            {/* Field Agent Warehouse Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/field-agent-pending'}
              component={requiredAuth(FieldAgentPending)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/field-agent-pending/:warehouseId'}
              component={requiredAuth(FieldAgentPendingDetail)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/field-agent-approve'}
              component={requiredAuth(FieldAgentApprove)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/field-agent-approve/:warehouseId'}
              component={requiredAuth(FieldAgentApproveDetail)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/field-agent-reject'}
              component={requiredAuth(FieldAgentReject)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/field-agent-reject/:warehouseId'}
              component={requiredAuth(FieldAgentRejectDetail)}
            />
            {/* Bookin Management */}{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/booking'}
              component={requiredAuth(Booking)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/booking-approve'}
              component={requiredAuth(BookingApproved)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/booking-pending'}
              component={requiredAuth(BookingPending)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/booking-cancel'}
              component={requiredAuth(BookingCancelled)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/booking-details/:bookingId'}
              component={requiredAuth(BookingDetails)}
            />
            {/* Documents Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/documents'}
              component={requiredAuth(Documents)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-documents/:warehouseId/:whId'}
              component={requiredAuth(CustomerDocuments)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/space-documents/:certificateId'}
              component={requiredAuth(SpaceCertificate)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/vendor-documents/:warehouseId/:whId'}
              component={requiredAuth(VendorDocuments)}
            />
            {/* Category Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/category'}
              component={requiredAuth(Category)}
            />
            {/* Zone Mapping */}
            <Route exact path={process.env.PUBLIC_URL + '/zone'} component={requiredAuth(Zone)} />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/add-category'}
              component={requiredAuth(AddCategory)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/update-category/:categoryId'}
              component={requiredAuth(UpdateCategory)}
            />
            {/* Filter Management */}
            {/* <Route
                        exact
                        path={process.env.PUBLIC_URL + '/filter'}
                        component={requiredAuth(Filter)}
                      />  */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/filter'}
              component={requiredAuth(AutoFilter)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/add-filter'}
              component={requiredAuth(AddFilter)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/update-filter/:filterId'}
              component={requiredAuth(UpdateFilter)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/add-filter-option/:filterId'}
              component={requiredAuth(AddFilterOption)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/update-filter-option/:filterId/:filterOptionId'}
              component={requiredAuth(UpdateFilterOption)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/edit-filter/:filterId'}
              component={requiredAuth(EditFilter)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/filter-option/:filterId'}
              component={requiredAuth(FilterOption)}
            />
            {/* Customer Onboarding Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-manage'}
              component={requiredAuth(CustomerManage)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-manage-update/:onBoardId'}
              component={requiredAuth(CustomerManageUpdate)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-contact-details/:onBoardId'}
              component={requiredAuth(CustomerContactDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/onboard-customer-contact'}
              component={requiredAuth(ManageContactOnboard)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/onboard-cost-sheet'}
              component={requiredAuth(ManageCostOnBoard)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-cost-sheet/:onBoardId'}
              component={requiredAuth(CustomerCostSheet)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/onboard-billing-support'}
              component={requiredAuth(ManageBillingOnBoard)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-billing-support/:onBoardId'}
              component={requiredAuth(CustomerBillingSupport)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-transition-operation/:onBoardId'}
              component={requiredAuth(CustomerTransitionOperation)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/onboard-transition-operation'}
              component={requiredAuth(ManageOperationOnBoard)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-transition/:onBoardId'}
              component={requiredAuth(CustomerTransitionCS)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/onboard-transition'}
              component={requiredAuth(ManageCSOnBoard)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-special-instruction/:onBoardId'}
              component={requiredAuth(CustomerSpecialInstruction)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/onboard-special-instruction'}
              component={requiredAuth(ManageSpecialOnBoard)}
            />
            {/* Service Management */}{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service'}
              component={requiredAuth(ManageService)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-receive'}
              component={requiredAuth(ServiceReceive)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-receive/:serviceReceiveId'}
              component={requiredAuth(ServiceReceiveDetail)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service/:serviceId'}
              component={requiredAuth(ViewService)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-add'}
              component={requiredAuth(AddService)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-edit/:serviceId'}
              component={requiredAuth(EditService)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-category'}
              component={requiredAuth(ManageServiceCategory)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-category-add'}
              component={requiredAuth(AddServiceCategory)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-category-edit/:serviceCategoryId'}
              component={requiredAuth(EditServiceCategory)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-category/:serviceCategoryId'}
              component={requiredAuth(ViewServiceCategory)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-subcategory'}
              component={requiredAuth(ManageServiceSubCategory)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-subcategory-add'}
              component={requiredAuth(AddServiceSubCategory)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-subcategory-edit/:subCategoryId'}
              component={requiredAuth(EditServiceSubCategory)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/service-subcategory/:subCategoryId'}
              component={requiredAuth(ViewServiceSubCategory)}
            />
            {/* RFQ Management */}{' '}
            {/*<Route
              exact
              path={process.env.PUBLIC_URL + "/create-rfq"}
              component={requiredAuth(CreateRFQ)}
            />*/}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/create-rfq/:rfqId/:warehouseId'}
              component={requiredAuth(CreateRfqByAdmin)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/manage-rfq'}
              component={requiredAuth(ManageRFQ)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/manage-rfq/:rfqId'}
              component={requiredAuth(ManageRFQList)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/manage-rfq/:rfqId/:warehouseId'}
              component={requiredAuth(ManageRFQView)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/whs-to-vendor-rfq/:rfqId/:vRequestId'}
              component={requiredAuth(WhsToVendorRFQ)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/whs-to-customer-rfq/:rfqId'}
              component={requiredAuth(WhsToCustomerRFQ)}
            />
            <Route
              exact
              path={
                process.env.PUBLIC_URL +
                '/send-whs-to-customer-rfq/:rfqId/:warehouseId/:vendorResponseId'
              }
              component={requiredAuth(SendWhsToCustomer)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/vendor-to-whs-rfq/:vResponseId'}
              component={requiredAuth(VendorToWhsRFQ)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/manage-response/:rfqId'}
              component={requiredAuth(OpenRFQResponse)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/manage-response/:rfqId/:rfqViewId'}
              component={requiredAuth(OpenRFQResponseView)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-rfq/:customerRfqId'}
              component={requiredAuth(CustomerRFQDetails)}
            />
            {/* Invoice Management */}{' '}
            {/* <Route
              exact
              path={process.env.PUBLIC_URL + "/user-invoice"}
              component={requiredAuth(UserInvoiceManage)}
            />{" "} */}
            {/* <Route
              exact
              path={process.env.PUBLIC_URL + "/vendor-invoice"}
              component={requiredAuth(VendorInvoiceManage)}
            />{" "} */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/user-invoice/:invoiceId'}
              component={requiredAuth(UserInvoiceDetails)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/vendor-invoice/:invoiceId'}
              component={requiredAuth(VendorInvoiceDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/create-user-invoice'}
              component={requiredAuth(CreateUserInvoice)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/create-vendor-invoice'}
              component={requiredAuth(CreateVendorInvoice)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/edit-user-invoice/:userInvoiceId'}
              component={requiredAuth(EditUserInvoice)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/edit-vendor-invoice/:invoiceId'}
              component={requiredAuth(EditVendorInvoice)}
            />
            {/* Review Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/review'}
              component={requiredAuth(Review)}
            />
            {/* Feedback management */}{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/feedback'}
              component={requiredAuth(Feedback)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/feedback/:feedbackId'}
              component={requiredAuth(FeedbackDetails)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/manageenquiry/:enquiryId'}
              component={requiredAuth(EnquiryDetails)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/review/:reviewId'}
              component={requiredAuth(ReviewDetails)}
            />
            {/* Manage Space */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/manage-space'}
              component={requiredAuth(ManageSpace)}
            />
            {/* Notification Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/notification'}
              component={requiredAuth(Notification)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/create-notification'}
              component={requiredAuth(CreateNotification)}
            />{' '}
            {/* CMS management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/about-us'}
              component={requiredAuth(AboutUs)}
            />
            <Route exact path={process.env.PUBLIC_URL + '/faq'} component={requiredAuth(Faq)} />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/faqcustomer'}
              component={requiredAuth(FaqCusomer)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/term-and-condition'}
              component={requiredAuth(TermAndCondition)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/return-policy'}
              component={requiredAuth(ReturnPolicy)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/privacy'}
              component={requiredAuth(Privacy)}
            />
            {/* Whys Warehouse management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/why-warehouse'}
              component={requiredAuth(WhyWarehouse)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/add-why'}
              component={requiredAuth(CreateWhy)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/update-why/:whyId'}
              component={requiredAuth(UpdateWhy)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/why-detail/:whyId'}
              component={requiredAuth(WhyDetail)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/type-warehouse'}
              component={requiredAuth(TypeWarehouse)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/add-type'}
              component={requiredAuth(CreateType)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/update-type/:typeId'}
              component={requiredAuth(UpdateType)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/type-detail/:typeId'}
              component={requiredAuth(TypeDetail)}
            />
            {/* Enquiry Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-enquiry-pending'}
              component={requiredAuth(CustomerPending)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-enquiry-cancelled'}
              component={requiredAuth(CustomerCancelled)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/user-contact'}
              component={requiredAuth(UserContact)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/vendor-enquiry-pending'}
              component={requiredAuth(VendorPending)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/enquiry-pending-detail'}
              component={requiredAuth(EnquiryPendingDetails)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/enquiry-pending-detail-vendor/:enquiryId'}
              component={requiredAuth(EnquiryPendingDetailsVendor)}
            />{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/customer-enquiry-approve'}
              component={requiredAuth(CustomerApprove)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/vendor-enquiry-approve'}
              component={requiredAuth(VendorApprove)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/enquiry-approve-detail'}
              component={requiredAuth(EnquiryApproveDetails)}
            />
            {/* 
             <CustomerPending />
             <CustomerApprove />
             <VendorApprove />
             <VendorPending /> */}
            {/* Report Management */}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/warehouse-report'}
              component={requiredAuth(WarehouseReport)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/warehouse-report-list'}
              component={requiredAuth(WHSListingReport)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/daily-progress-report'}
              component={requiredAuth(DailyProgressReport)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/no-search-report'}
              component={requiredAuth(NoSearchReport)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/user-report'}
              component={requiredAuth(UserReport)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/vendor-report'}
              component={requiredAuth(VendorReport)}
            />
            <Route
              exact
              path={process.env.PUBLIC_URL + '/sub-admin-report'}
              component={requiredAuth(SubAdminReport)}
            />
            {/* setting management */}{' '}
            <Route
              exact
              path={process.env.PUBLIC_URL + '/setting'}
              component={requiredAuth(Setting)}
            />{' '}
          </Switch>{' '}
        </Router>{' '}
      </Suspense>{' '}
    </ToastProvider>
  );
};

export default App;
