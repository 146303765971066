import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";
import { departmentById } from "../../store/action/whsUserAction";
import { useDispatch, useSelector } from "react-redux";

const WHSUserDepartmentDetails = () => {
  const { departmentId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.WHS_USER_INFO);

  useEffect(() => {
    dispatch(departmentById(departmentId));
    // console.log(data,"****");
  }, [dispatch, departmentId]);

  return (
    <LayoutOne>
      {JSON.stringify(data.departmentDetail)}
      <div className="row align-items-center mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left cursorPointer"
            >
              {" "}
            </i>{" "}
            View Details
          </h5>
        </div>{" "}
      </div>{" "}
      <div className="row align-items-center px-3 mx-0">
        <div className="col-12 px-0">
          {data.departmentDetail ? (
            <div className="row">
              <div className="col-md-12 d-flex py-2">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Department Name </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data?.departmentDetail?.name}{" "}
                  </p>{" "}
                </div>{" "}
              </div>
              <div className="col-md-6 d-flex py-2">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Created On </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {readableDate(data?.departmentDetail?.createdAt)}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-6 d-flex py-2">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Status </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data?.departmentDetail?.isActive
                      ? "Active"
                      : "In Active"}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          ) : null}
        </div>{" "}
        <div className="col-auto">
          <button
            onClick={() => history.goBack()}
            className="btn btn-outline-deep-blue my-4"
          >
            {" "}
            back{" "}
          </button>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default WHSUserDepartmentDetails;
