import {
  WAREHOUSE_ERROR,
  WAREHOUSE_PENDING,
  WAREHOUSE_LOADING,
  ADD_NEW_WAREHOUSE,
  WAREHOUSE_LIST,
  WAREHOUSE_BY_ID,
  NEW_WAREHOUSE_INFO,
  ACCORDION,
  FINAL_SUBMIT,
  TYPE_TO_USE,
  WAREHOUSE_BY_ID_AND_TYPE,
  WAREHOUSE_ERROR_MSG,
  FINAL_SUBMIT_VENDOR,
} from '../types';

import {
  ADD_WAREHOUSE,
  WAREHOUSE_LIST_URL,
  WAREHOUSE_LIST_URL_NEW,
  WAREHOUSE_BY_ID_URL,
  UPDATE_WAREHOUSE_URL,
  UPDATE_WAREHOUSE_PENDING_APPROVAL_FORM,
} from '../../services/urls';

import axiosauth from '../../services/axios-auth';

export function isPending(data) {
  return {
    type: WAREHOUSE_PENDING,
    payload: data,
  };
}
export function isType(data) {
  return {
    type: TYPE_TO_USE,
    payload: data,
  };
}

export function isLoading(data) {
  return {
    type: WAREHOUSE_LOADING,
    payload: data,
  };
}

export function isError(data) {
  return {
    type: WAREHOUSE_ERROR,
    payload: data,
  };
}

export function isErrorMsg(data) {
  return {
    type: WAREHOUSE_ERROR_MSG,
    payload: data,
  };
}

export function accordian(data) {
  return {
    type: ACCORDION,
    payload: data,
  };
}

export function finalSubmitForm(data) {
  return {
    type: FINAL_SUBMIT,
    payload: data,
  };
}
export function finalSubmitFormVendor(data) {
  return {
    type: FINAL_SUBMIT_VENDOR,
    payload: data,
  };
}
export function addNewWarehouse(data) {
  return {
    type: ADD_NEW_WAREHOUSE,
    payload: data,
  };
}

export function newWarehouseInfo(data) {
  return {
    type: NEW_WAREHOUSE_INFO,
    payload: data,
  };
}

export function warehouseList(data) {
  return {
    type: WAREHOUSE_LIST,
    payload: data,
  };
}

export function WarehouseById(data) {
  return {
    type: WAREHOUSE_BY_ID,
    payload: data,
  };
}

export function WarehouseByIdType(data) {
  return {
    type: WAREHOUSE_BY_ID_AND_TYPE,
    payload: data,
  };
}

export const changeWarehouseStatus = () => {
  return async (dispatch) => {
    dispatch(isPending(false));
    dispatch(addNewWarehouse([]));
    dispatch(isError(''));
    dispatch(newWarehouseInfo([]));
    dispatch(finalSubmitForm(false));
  };
};
export const changeWarehouseStatus1 = () => {
  return async (dispatch) => {
    dispatch(isPending(false));
    dispatch(addNewWarehouse([]));
    dispatch(isError(''));
    dispatch(newWarehouseInfo([]));
    dispatch(finalSubmitFormVendor(false));
  };
};
// ###########  Fecth All Warehouse List ########

export const fetchWarehouse = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .get(WAREHOUSE_LIST_URL)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res.data));
            dispatch(isLoading(false));
          } else {
            console.log('Add Warehouse Fail');
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  Fecth All Warehouse List ########

export const fetchAllWarehouse = (filter) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${1}&limit=${10}`, filter)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res.data));
            dispatch(isLoading(false));
          } else {
            console.log('Add Warehouse Fail');
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchAllWarehouseInvoice = (page, filter) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    dispatch(warehouseList([]));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${10}`, filter)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Add Warehouse Fail');
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchWarehouseSpace = (page, filter) => {
  return async (dispatch) => {
    dispatch(warehouseList([]));
    try {
      axiosauth
        .post(`/api/v1/warehouses/spacemanagement/warehouse?page=${page}&limit=${10}`, filter)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
          }
        })
        .catch((error) => { })
        .then(() => { });
    } catch (e) { }
  };
};

// ###########  Fecth All Warehouse List By for filter ########

export const fetchWarehouseFilter = (page) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}`, {
          filter: {
            type: 'adminStatus',
            status: 'Approved',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  Fecth All Warehouse List By Page Number ########

export const fetchWarehouseByPage = (page, search, filterAry) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    // console.log(
    //   WAREHOUSE_LIST_URL_NEW + `?page=${page}&limit=${limit}&search=${search}`,
    //   'aDMIN ACTION TRIGGER HJ',
    // );
    try {
      // if (body?.filter && body?.filter?.type) {
      //   filters[body?.filter?.type] = body?.filter?.status;
      //   filters[body?.filter?.type] = body?.filter?.status;
      // } else filters = {};
      axiosauth
        .post(WAREHOUSE_LIST_URL_NEW + `?page=${page}&limit=${limit}&search=${search}`, {
          filter: {
            type: 'adminStatus',
            status: filterAry,
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchSearchWarehouseByPage = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=${search}`, {
          filter: {
            type: 'adminStatus',
            status: 'approved',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchWarehouseByPageFieldAgent = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=${search}`, {
          filter: {
            type: 'fieldAgaint',
            status: 'New Request',
            fieldAgaint: JSON.parse(localStorage.getItem('userData')).id,
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchWarehouseByPageFieldAgent1 = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=${search}`, {
          filter: {
            type: 'fieldAgaint',
            status: 'Live',
            fieldAgaint: JSON.parse(localStorage.getItem('userData')).id,
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const fetchWarehouseByPageFieldAgent2 = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search`, {
          filter: {
            type: 'fieldAgaint',
            status: 'Rejected',
            fieldAgaint: JSON.parse(localStorage.getItem('userData')).id,
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const fetchWarehouseByPage3 = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=${search}`, {
          filter: {
            type: 'adminStatus',
            status: 'Submitted To FA',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const fetchWarehouseByPage344 = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=${search}`, {
          filter: {
            type: 'adminStatus',
            status: 'ApprovedByFa',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const fetchWarehouseByPage34 = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=`, {
          filter: {
            type: 'adminStatus',
            status: 'Rejected By FA',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const fetchWarehouseByPage2 = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=`, {
          filter: {
            type: 'adminStatus',
            status: 'Rejected By FA',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const fetchWarehouseByPage2New = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    // dispatch(isLoading(true));
    // dispatch(isError(''));
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL_NEW + `?page=${page}&limit=${limit}&search=`, {
          filter: {
            type: 'adminStatus',
            status: 'Rejected By FA',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            // dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            // dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          // dispatch(isLoading(false));
        })
        .then(() => {
          // dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchWarehouseByPage1 = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    dispatch(warehouseList([]));
    dispatch(isLoading(true));
    dispatch(isError(''));

    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=${search}`, {
          filter: {
            type: 'adminStatus',
            status: 'Approved',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Fail==>', res);
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// fech warehose without loading

export const fetchWarehouseByPageV1 = (page, search) => {
  return async (dispatch) => {
    const limit = 10;
    try {
      axiosauth
        .post(WAREHOUSE_LIST_URL + `?page=${page}&limit=${limit}&search=${search}`, {
          filter: {
            type: 'adminStatus',
            status: 'Approved',
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(warehouseList(res));
          }
        })
        .catch((error) => { })
        .then(() => {
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###### Update warehose sold out ###########

export const updateSoldOut = (id, data, page) => {
  return async (dispatch) => {
    try {
      axiosauth
        .put(`/api/v1/warehouses/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(fetchWarehouseByPageV1(page, ''));
          }
        })
        .catch((error) => { })
        .then(() => { });
    } catch (e) { }
  };
};

// ###########  Fetch Warehouse By Id ############

export const fetchWarehouseById = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .get(WAREHOUSE_BY_ID_URL + `/${id}/type/warehouse/usertype/vendor`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(WarehouseById(res.data));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Details Fail');
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          console.log('Fail--->', error.message);
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  Fetch Warehouse By Id ############

export const warehouseByIdSpace = (id) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(''));
    try {
      axiosauth
        .get(`/api/v1/warehouses/${id}`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(WarehouseById(res.data));
            dispatch(isLoading(false));
          } else {
            console.log('Warehouse Details Fail');
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const clearSingleDataState = (payload) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(WarehouseByIdType(payload));
  };
};

// ###########  Fetch Warehouse By Id And Type ############

export const fetchWarehouseByIdAndType = (id, type) => {
  return async (dispatch) => {
    dispatch(isType(type));
    dispatch(WarehouseByIdType(null));

    dispatch(isLoading(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .get(`/api/v1/warehouses/${id}/type/${type}/usertype/vendor`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(WarehouseByIdType(res.data));
            dispatch(isLoading(false));
          } else {
            dispatch(isErrorMsg(res.message));
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchWarehouseByIdAndTypePending = (id, type) => {
  return async (dispatch) => {
    console.log(id, type, 'id and type section first');
    // dispatch(isLoading(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    // dispatch(isPending(true));
    try {
      console.log(id, type, 'id and type section');
      axiosauth
        .get(`/api/v1/warehousesnew/${id}/type/pending/${type}/usertype/vendor`)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('single data || ==>', res);
          if (res.statusCode === 200) {
            dispatch(WarehouseByIdType(res?.data));
            dispatch(isLoading(false));
            // dispatch(isPending(false));
          } else {
            console.log('Warehouse Details Fail');
            dispatch(isLoading(false));
            // dispatch(isErrorMsg(res.message));
            // dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          // console.log("Fail---> Yyyyyyy", error?.message,"^^^^^^^^^^^^");
          // dispatch(isError(error.message));
          // dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const fetchWarehouseFieldAgentByIdAndType = (id, type) => {
  return async (dispatch) => {
    dispatch(isType(type));
    dispatch(WarehouseByIdType(null));

    dispatch(isLoading(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    if (type === 'warehouse') {
      dispatch(
        WarehouseByIdType({
          id: 7,
          warehouseName: 'whs-fa-test1',
          whsAdmincategorystatus: 'okay',
          whsAdmincategoryremark: 'categoryremark',
          whsAdmintypestatus: 'notokay',
          whsAdmintyperemark: 'typeremark',
          whsAdminwarehouseNamestatus: 'notokay',
          whsAdminwarehouseNameremark: 'warehousenameremark',
          whsFieldAgentcategorystatus: 'okay',
          whsFieldAgentcategoryremark: 'categoryremark',
          whsFieldAgenttypestatus: 'notokay',
          whsFieldAgenttyperemark: 'typeremark',
          whsFieldAgentwarehouseNamestatus: 'notokay',
          whsFieldAgentwarehouseNameremark: 'warehousenameremark',
          category: 'cold',
          type: 'shared',
          gstCertificate: '',
          gstNumber: '',
          location: 'delhi',
          totalArea: 100,
          structureType: 'shelter',
          workingHour: '9 to 9',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: true,
          whsFieldAgaintWarehouseContactDetail: true,
          storageSpace: false,
          whsStorageSpace: false,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: false,
          whsItAndOfficeInfra: false,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: false,
          whsMhInfra: false,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: false,
          whsSafetyAndSecurity: false,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: false,
          whsPermit: false,
          whsFieldAgaintPermit: false,
          materialType: false,
          whsMaterialType: false,
          whsFieldAgaintMaterialType: false,
          formEighty: false,
          whsFormEighty: false,
          whsFieldAgaintFormEighty: false,
          warehouseImages: false,
          whsWarehouseImages: false,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Draft',
          adminStatus: null,
          fieldAgaintStatus: null,
          created_at: '2021-07-01T06:39:19.679Z',
          updated_at: '2021-07-01T09:48:44.000Z',
          whsWarehouse: [
            {
              id: 1,
              created_at: '2021-07-01T08:10:01.319Z',
              updated_at: '2021-07-01T08:10:01.319Z',
              warehouseName: {
                id: 1,
                status: true,
                remark: 'whname info',
                created_at: '2021-07-01T08:10:00.831Z',
                updated_at: '2021-07-01T08:10:00.831Z',
              },
              category: {
                id: 2,
                status: true,
                remark: 'cate info',
                created_at: '2021-07-01T08:10:01.099Z',
                updated_at: '2021-07-01T08:10:01.099Z',
              },
              type: {
                id: 3,
                status: false,
                remark: 'typw info',
                created_at: '2021-07-01T08:10:01.105Z',
                updated_at: '2021-07-01T08:10:01.105Z',
              },
            },
          ],
        }),
      );
    } else if (type === 'warehouseContactDetails') {
      dispatch(
        WarehouseByIdType({
          id: 4,
          warehouseName: 'szzzzzzzzzzzzzz',
          category: 'Custom',
          type: 'dedicated',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: true,
          whsFieldAgaintWarehouseContactDetail: true,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: true,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: true,
          whsFieldAgaintItAndOfficeInfra: true,
          mhInfra: true,
          whsMhInfra: true,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: true,
          whsFieldAgaintSafetyAndSecurity: true,
          permit: true,
          whsPermit: true,
          whsFieldAgaintPermit: true,
          materialType: true,
          whsMaterialType: true,
          whsFieldAgaintMaterialType: true,
          formEighty: true,
          whsFormEighty: true,
          whsFieldAgaintFormEighty: true,
          warehouseImages: true,
          whsWarehouseImages: true,
          whsFieldAgaintWarehouseImages: true,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Rejected By FA',
          adminStatus: 'Rejected By FA',
          fieldAgaintStatus: 'Rejected',
          created_at: '2021-06-22T11:02:05.439Z',
          updated_at: '2021-06-23T11:48:03.000Z',
          warehouseContactDetailInfo: {
            id: 1,
            companyName: 'companyName',

            contactInfo: [
              {
                id: 1,
                name: 'dzsssssssssssss',
                whsAdminnamestatus: 'okay',
                whsAdminnameremark: 'remark',
                whsAdminphonestatus: 'okay',
                whsAdminphoneremark: 'remark',
                whsAdminemailstatus: 'okay',
                whsAdminemailremark: 'remark',

                whsFieldAgentnamestatus: 'okay',
                whsFieldAgentnameremark: 'remark',
                whsFieldAgentphonestatus: 'okay',
                whsFieldAgentphoneremark: 'remark',
                whsFieldAgentemailstatus: 'okay',
                whsFieldAgentemailremark: 'remark',
                phone: '9899887766',
                email: 'rohancool3845@gmail.com',
                type: 'contact',
              },
              {
                id: 2,
                whsAdminnamestatus: 'okay',
                whsAdminnameremark: 'remark',
                whsAdminphonestatus: 'okay',
                whsAdminphoneremark: 'remark',
                whsAdminemailstatus: 'okay',
                whsAdminemailremark: 'remark',

                whsFieldAgentnamestatus: 'okay',
                whsFieldAgentnameremark: 'remark',
                whsFieldAgentphonestatus: 'okay',
                whsFieldAgentphoneremark: 'remark',
                whsFieldAgentemailstatus: 'okay',
                whsFieldAgentemailremark: 'remark',
                name: 'ssssssssssss',
                phone: '9899887766',
                email: 'eends@bv.com',
                type: 'alternateContact',
              },
            ],
            address: {
              id: 1,
              line1: 'dssssssssssssss',
              whsAdminline1status: 'okay',
              whsAdminline1remark: 'remark',

              whsAdminline2status: 'okay',
              whsAdminline2remark: 'remark',

              whsAdmincitystatus: 'okay',
              whsAdmincityremark: 'remark',

              whsAdmindistrictstatus: 'okay',
              whsAdmindistrictremark: 'remark',

              whsAdminstatestatus: 'okay',
              whsAdminstateremark: 'remark',

              whsAdmincountrystatus: 'okay',
              whsAdmincountryremark: 'remark',

              whsAdminpinCodestatus: 'okay',
              whsAdminpinCoderemark: 'remark',

              whsAdminlandmarkstatus: 'okay',
              whsAdminlandmarkremark: 'remark',

              whsAdminlatitudestatus: 'okay',
              whsAdminlatituderemark: 'remark',

              whsAdminlongitudestatus: 'okay',
              whsAdminlongituderemark: 'remark',

              /////

              whsFieldAgentline1status: 'okay',
              whsFieldAgentline1remark: 'remark',

              whsFieldAgentline2status: 'okay',
              whsFieldAgentline2remark: 'remark',

              whsFieldAgentcitystatus: 'okay',
              whsFieldAgentcityremark: 'remark',

              whsFieldAgentdistrictstatus: 'okay',
              whsFieldAgentdistrictremark: 'remark',

              whsFieldAgentstatestatus: 'okay',
              whsFieldAgentstateremark: 'remark',

              whsFieldAgentcountrystatus: 'okay',
              whsFieldAgentcountryremark: 'remark',

              whsFieldAgentpinCodestatus: 'okay',
              whsFieldAgentpinCoderemark: 'remark',

              whsFieldAgentlandmarkstatus: 'okay',
              whsFieldAgentlandmarkremark: 'remark',

              whsFieldAgentlatitudestatus: 'okay',
              whsFieldAgentlatituderemark: 'remark',

              whsFieldAgentlongitudestatus: 'okay',
              whsFieldAgentlongituderemark: 'remark',

              line2: 'dsssssssssssss',
              city: 'Ghaziabad',
              district: 'dsssssssssss',
              state: 'Uttar Pradesh',
              country: 'India',
              pinCode: '201012',
              landmark: 'dsssssssssssss',
              latitude: 43,
              longnitude: 44,
            },
          },
          whsWarehouse: [
            {
              id: 1,
              created_at: '2021-06-23T10:10:41.987Z',
              updated_at: '2021-06-23T10:10:41.987Z',
              whsWarehouseContactPerson: {
                id: 1,
                companyName: {
                  id: 4,
                  status: true,
                  remark: 'compnayname',
                  created_at: '2021-06-23T10:58:07.850Z',
                  updated_at: '2021-06-23T10:58:18.000Z',
                },
                contactInfo: [
                  {
                    id: 2,
                    name: {
                      id: 9,
                      status: true,
                      remark: '',
                      created_at: '2021-06-23T10:58:08.157Z',
                      updated_at: '2021-06-23T10:58:18.000Z',
                    },
                    phone: {
                      id: 10,
                      status: true,
                      remark: '',
                      created_at: '2021-06-23T10:58:08.193Z',
                      updated_at: '2021-06-23T10:58:18.000Z',
                    },
                    email: {
                      id: 11,
                      status: true,
                      remark: '',
                      created_at: '2021-06-23T10:58:08.202Z',
                      updated_at: '2021-06-23T10:58:18.000Z',
                    },
                    contactType: {
                      id: 12,
                      status: false,
                      remark: '',
                      created_at: '2021-06-23T10:58:08.229Z',
                      updated_at: '2021-06-23T10:58:18.000Z',
                    },
                  },
                  {
                    id: 1,
                    name: {
                      id: 5,
                      status: true,
                      remark: '',
                      created_at: '2021-06-23T10:58:07.958Z',
                      updated_at: '2021-06-23T10:58:18.000Z',
                    },
                    phone: {
                      id: 6,
                      status: true,
                      remark: '',
                      created_at: '2021-06-23T10:58:07.981Z',
                      updated_at: '2021-06-23T10:58:18.000Z',
                    },
                    email: {
                      id: 7,
                      status: true,
                      remark: '',
                      created_at: '2021-06-23T10:58:08.010Z',
                      updated_at: '2021-06-23T10:58:18.000Z',
                    },
                    contactType: {
                      id: 8,
                      status: false,
                      remark: '',
                      created_at: '2021-06-23T10:58:08.036Z',
                      updated_at: '2021-06-23T10:58:18.000Z',
                    },
                  },
                ],
                address: {
                  id: 1,
                  line1: {
                    id: 13,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.318Z',
                    updated_at: '2021-06-23T10:58:18.000Z',
                  },
                  addressLine2: {
                    id: 14,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.329Z',
                    updated_at: '2021-06-23T10:58:18.000Z',
                  },
                  city: {
                    id: 15,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.348Z',
                    updated_at: '2021-06-23T10:58:18.000Z',
                  },
                  district: {
                    id: 17,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.382Z',
                    updated_at: '2021-06-23T10:58:19.000Z',
                  },
                  state: {
                    id: 16,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.363Z',
                    updated_at: '2021-06-23T10:58:19.000Z',
                  },
                  country: {
                    id: 18,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.396Z',
                    updated_at: '2021-06-23T10:58:19.000Z',
                  },
                  pinCode: {
                    id: 19,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.446Z',
                    updated_at: '2021-06-23T10:58:19.000Z',
                  },
                  landmark: {
                    id: 20,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.468Z',
                    updated_at: '2021-06-23T10:58:19.000Z',
                  },
                  latitude: {
                    id: 21,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.482Z',
                    updated_at: '2021-06-23T10:58:19.000Z',
                  },
                  longnitude: {
                    id: 22,
                    status: true,
                    remark: '',
                    created_at: '2021-06-23T10:58:08.493Z',
                    updated_at: '2021-06-23T10:58:19.000Z',
                  },
                },
              },
            },
          ],
        }),
      );
    } else if (type === 'storageSpace') {
      dispatch(
        WarehouseByIdType({
          id: 23,
          warehouseName: 'Abcdefgh',
          category: 'Temperature',
          type: 'emptySpace',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: false,
          whsFieldAgaintWarehouseContactDetail: false,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: false,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: true,
          whsMhInfra: false,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: false,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: true,
          whsPermit: false,
          whsFieldAgaintPermit: false,
          materialType: true,
          whsMaterialType: false,
          whsFieldAgaintMaterialType: false,
          formEighty: true,
          whsFormEighty: false,
          whsFieldAgaintFormEighty: false,
          warehouseImages: true,
          whsWarehouseImages: false,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Submitted To WHS',
          adminStatus: 'New Request',
          fieldAgaintStatus: null,
          created_at: '2021-07-05T06:42:06.109Z',
          updated_at: '2021-07-05T07:19:18.000Z',
          storageSpaceInfo: {
            id: 7,
            storageType: 'palletposition',
            whsAdminstorageTypestatus: 'jdjg',
            whsAdminstorageTyperemark: 'jfjfd',
            whsAdminpalletsOnFloorstatus: 'jdjg',
            whsAdminpalletsOnFloorremark: 'jfjfd',
            whsAdmintotalAreastatus: 'jdjg',
            whsAdmintotalArearemark: 'jfjfd',
            whsAdminavailableSpacestatus: 'jdjg',
            whsAdminavailableSpaceremark: 'jfjfd',
            whsAdminloadingAndUnloadingBaysstatus: 'jdjg',
            whsAdminloadingAndUnloadingBaysremark: 'jfjfd',
            whsAdmindockSizestatus: 'jdjg',
            whsAdmindockSizeremark: 'jfjfd',
            whsAdminparkingAreastatus: 'jdjg',
            whsAdminparkingArearemark: 'jfjfd',

            /////

            whsFieldAgentstorageTypestatus: 'jdjg',
            whsFieldAgentstorageTyperemark: 'jfjfd',
            whsFieldAgentpalletsOnFloorstatus: 'jdjg',
            whsFieldAgentpalletsOnFloorremark: 'jfjfd',
            whsFieldAgenttotalAreastatus: 'jdjg',
            whsFieldAgenttotalArearemark: 'jfjfd',
            whsFieldAgentavailableSpacestatus: 'jdjg',
            whsFieldAgentavailableSpaceremark: 'jfjfd',
            whsFieldAgentloadingAndUnloadingBaysstatus: 'jdjg',
            whsFieldAgentloadingAndUnloadingBaysremark: 'jfjfd',
            whsFieldAgentdockSizestatus: 'jdjg',
            whsFieldAgentdockSizeremark: 'jfjfd',
            whsFieldAgentparkingAreastatus: 'jdjg',
            whsFieldAgentparkingArearemark: 'jfjfd',

            palletsOnFloor: '9',
            availableSpace: '7800',
            loadingAndUnloadingBays: '5',
            parkingArea: '500',
            floors: [
              {
                id: 25,
                floorType: 'basement',
                unit: 'FEET',
                floorDimension: {
                  id: 25,
                  dimension: 'SQFT',
                  length: '33',
                  breath: '4',
                  height: '4',
                },
              },
              {
                id: 26,
                floorType: 'groundFloor',
                unit: 'FEET',
                floorDimension: {
                  id: 26,
                  dimension: 'SQFT',
                  length: '3',
                  breath: '5',
                  height: '5',
                },
              },
              {
                id: 27,
                floorType: 'firstFloor',
                unit: 'FEET',
                floorDimension: {
                  id: 27,
                  dimension: 'SQFT',
                  length: '3',
                  breath: '6',
                  height: '6',
                },
              },
              {
                id: 28,
                floorType: 'secondFloor',
                unit: 'FEET',
                floorDimension: {
                  id: 28,
                  dimension: 'SQFT',
                  length: '',
                  breath: '',
                  height: '',
                },
              },
            ],
            racks: [
              {
                id: 13,
                whsAdminrackNamestatus: 'jdjg',
                whsAdminrackNameremark: 'jfjfd',
                whsFieldAgentrackNamestatus: 'jdjg',
                whsFieldAgentrackNameremark: 'jfjfd',
                name: 'shelveracks',
                rackInfo: {
                  id: 13,
                  quantity: '8',
                  length: '8',
                  breath: '8',
                  height: '8',
                },
              },
              {
                id: 14,
                name: 'binsracks',
                whsAdminrackNamestatus: 'jdjg',
                whsAdminrackNameremark: 'jfjfd',
                whsFieldAgentrackNamestatus: 'jdjg',
                whsFieldAgentrackNameremark: 'jfjfd',
                rackInfo: {
                  id: 14,
                  quantity: '9',
                  length: '8',
                  breath: '8',
                  height: '8',
                },
              },
            ],
            dockSize: {
              id: 7,
              height: '800',
              width: '500',
              heightFromGound: '1500',
            },
          },
          whsWarehouse: [
            {
              id: 9,
              created_at: '2021-07-05T07:18:43.927Z',
              updated_at: '2021-07-05T07:18:43.927Z',
              whsStorageSpace: {
                id: 4,
                storageType: {
                  id: 785,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T07:19:18.433Z',
                  updated_at: '2021-07-05T07:19:18.433Z',
                },
                totalArea: {
                  id: 786,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T07:19:18.487Z',
                  updated_at: '2021-07-05T07:19:18.487Z',
                },
                palletsOnFloor: {
                  id: 784,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T07:19:18.413Z',
                  updated_at: '2021-07-05T07:19:18.413Z',
                },
                availableSpace: {
                  id: 780,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T07:19:18.292Z',
                  updated_at: '2021-07-05T07:19:18.292Z',
                },
                loadingAndUnloadingBays: {
                  id: 783,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T07:19:18.377Z',
                  updated_at: '2021-07-05T07:19:18.377Z',
                },
                dockSize: {
                  id: 782,
                  status: false,
                  remark: '',
                  created_at: '2021-07-05T07:19:18.352Z',
                  updated_at: '2021-07-05T07:19:18.352Z',
                },
                parkingArea: {
                  id: 781,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T07:19:18.318Z',
                  updated_at: '2021-07-05T07:19:18.318Z',
                },
                racks: [
                  {
                    id: 7,
                    rackName: 'bins-up',
                    rack: {
                      id: 788,
                      status: false,
                      remark: '',
                      created_at: '2021-07-05T07:19:18.663Z',
                      updated_at: '2021-07-05T07:19:18.663Z',
                    },
                  },
                  {
                    id: 8,
                    rackName: 'shelveup',
                    rack: {
                      id: 790,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T07:19:18.801Z',
                      updated_at: '2021-07-05T07:19:18.801Z',
                    },
                  },
                ],
              },
            },
          ],
        }),
      );
    }

    if (type === 'itInfrAndOfficeSpace') {
      dispatch(
        WarehouseByIdType({
          id: 23,
          warehouseName: 'Abcdefgh',
          category: 'Temperature',
          type: 'emptySpace',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: false,
          whsFieldAgaintWarehouseContactDetail: false,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: true,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: true,
          whsMhInfra: false,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: false,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: true,
          whsPermit: false,
          whsFieldAgaintPermit: false,
          materialType: true,
          whsMaterialType: false,
          whsFieldAgaintMaterialType: false,
          formEighty: true,
          whsFormEighty: false,
          whsFieldAgaintFormEighty: false,
          warehouseImages: true,
          whsWarehouseImages: false,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Submitted To WHS',
          adminStatus: 'New Request',
          fieldAgaintStatus: null,
          created_at: '2021-07-05T06:42:06.109Z',
          updated_at: '2021-07-05T08:22:21.000Z',
          itInfraAndOfficeSpaceInfo: {
            id: 7,
            itInfra: {
              id: 14,
              desktop: '44',
              whsAdmindesktopstatus: 'okay',
              whsAdmindesktopremark: 'fdjfd',
              whsAdminprinterstatus: 'okay',
              whsAdminprinterremark: 'fdjfd',
              whsAdminprinterMultifunctionstatus: 'okay',
              whsAdminprinterMultifunctionremark: 'fdjfd',
              whsAdminupsAndInverterstatus: 'okay',
              whsAdminupsAndInverterremark: 'fdjfd',
              whsAdminbroadBandstatus: 'okay',
              whsAdminbroadBandremark: 'fdjfd',
              whsAdminscannerstatus: 'okay',
              whsAdminscannerremark: 'fdjfd',
              whsAdmintelephonestatus: 'okay',
              whsAdmintelephoneremark: 'fdjfd',
              whsAdminwmsstatus: 'okay',
              whsAdminwmsremark: 'fdjfd',
              whsAdminothersstatus: 'okay',
              whsAdminothersremark: 'fdjfd',

              ///////

              whsFieldAgentdesktopstatus: 'okay',
              whsFieldAgentdesktopremark: 'fdjfd',
              whsFieldAgentprinterstatus: 'okay',
              whsFieldAgentprinterremark: 'fdjfd',
              whsFieldAgentprinterMultifunctionstatus: 'okay',
              whsFieldAgentprinterMultifunctionremark: 'fdjfd',
              whsFieldAgentupsAndInverterstatus: 'okay',
              whsFieldAgentupsAndInverterremark: 'fdjfd',
              whsFieldAgentbroadBandstatus: 'okay',
              whsFieldAgentbroadBandremark: 'fdjfd',
              whsFieldAgentscannerstatus: 'okay',
              whsFieldAgentscannerremark: 'fdjfd',
              whsFieldAgenttelephonestatus: 'okay',
              whsFieldAgenttelephoneremark: 'fdjfd',
              whsFieldAgentwmsstatus: 'okay',
              whsFieldAgentwmsremark: 'fdjfd',
              whsFieldAgentothersstatus: 'okay',
              whsFieldAgentothersremark: 'fdjfd',
              printer: '44',
              printerMultifunction: '44',
              upsAndInverter: '4444',
              broadBand: '44',
              scanner: '44',
              telephone: '44',
              wms: '44',
              others: 'xvgbbbbbbbbbbb',
            },
            officeSpace: {
              id: 14,
              whsAdminareastatus: 'okay',
              whsAdminarearemark: 'fdf',
              whsAdminairConditionerstatus: 'okay',
              whsAdminairConditionerremark: 'fdf',
              whsAdminworkStationsstatus: 'okay',
              whsAdminworkStationsremark: 'fdf',
              whsAdminchairsstatus: 'okay',
              whsAdminchairsremark: 'fdf',
              whsAdmintablesstatus: 'okay',
              whsAdmintablesremark: 'fdf',
              whsAdmincabinsstatus: 'okay',
              whsAdmincabinsremark: 'fdf',
              whsAdminmeetingRoomsstatus: 'okay',
              whsAdminmeetingRoomsremark: 'fdf',
              whsAdminrecordRoomsstatus: 'okay',

              whsAdminrecordRoomsremark: 'fdf',
              whsAdminstrongRoomsstatus: 'okay',
              whsAdminstrongRoomsremark: 'fdf',
              whsAdminsecurityGuardOfficeTablestatus: 'okay',
              whsAdminsecurityGuardOfficeTableremark: 'fdf',
              whsAdminelectricLoadstatus: 'okay',
              whsAdminelectricLoadremark: 'fdf',
              whsAdminothersstatus: 'okay',
              whsAdminothersremark: 'fdf',
              /////////////////////////

              whsFieldAgentareastatus: 'okay',
              whsFieldAgentarearemark: 'fdf',
              whsFieldAgentairConditionerstatus: 'okay',
              whsFieldAgentairConditionerremark: 'fdf',
              whsFieldAgentworkStationsstatus: 'okay',
              whsFieldAgentworkStationsremark: 'fdf',
              whsFieldAgentchairsstatus: 'okay',
              whsFieldAgentchairsremark: 'fdf',
              whsFieldAgenttablesstatus: 'okay',
              whsFieldAgenttablesremark: 'fdf',
              whsFieldAgentcabinsstatus: 'okay',
              whsFieldAgentcabinsremark: 'fdf',
              whsFieldAgentmeetingRoomsstatus: 'okay',
              whsFieldAgentmeetingRoomsremark: 'fdf',
              whsFieldAgentrecordRoomsstatus: 'okay',

              whsFieldAgentrecordRoomsremark: 'fdf',
              whsFieldAgentstrongRoomsstatus: 'okay',
              whsFieldAgentstrongRoomsremark: 'fdf',
              whsFieldAgentsecurityGuardOfficeTablestatus: 'okay',
              whsFieldAgentsecurityGuardOfficeTableremark: 'fdf',
              whsFieldAgentelectricLoadstatus: 'okay',
              whsFieldAgentelectricLoadremark: 'fdf',
              whsFieldAgentothersstatus: 'okay',
              whsFieldAgentothersremark: 'fdf',

              area: '44',
              airConditioner: '44',
              workStations: '4444',
              chairs: '500',
              tables: '44',
              cabins: '44',
              meetingRooms: '44',
              recordRooms: '44',
              strongRooms: '44',
              securityGuardOfficeTable: '44',
              electricLoad: 'fdffffffffffffffff',
              others: 'fdddddddddddd',
            },
          },
          whsWarehouse: [
            {
              id: 9,
              created_at: '2021-07-05T07:18:43.927Z',
              updated_at: '2021-07-05T07:18:43.927Z',
              whsItInfraAndOfficeSpace: {
                id: 4,
                itInfra: {
                  id: 4,
                  desktop: {
                    id: 791,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.218Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  printer: {
                    id: 792,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.233Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  printerMultifunction: {
                    id: 793,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.259Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  upsAndInverter: {
                    id: 794,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.265Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  broadBand: {
                    id: 795,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.288Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  scanner: {
                    id: 796,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.309Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  telephone: {
                    id: 797,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.326Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  wms: {
                    id: 798,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.345Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  itinfra_other: {
                    id: 799,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.361Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                },
                officeSpace: {
                  id: 4,
                  area: {
                    id: 800,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.502Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  airConditioner: {
                    id: 801,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.562Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  workStations: {
                    id: 802,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.600Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  chairs: {
                    id: 803,
                    status: false,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.614Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  tables: {
                    id: 804,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.629Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  cabins: {
                    id: 805,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.657Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  meetingRooms: {
                    id: 806,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.684Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  recordRooms: {
                    id: 807,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.698Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  strongRooms: {
                    id: 808,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.717Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  securityGuardOfficeTable: {
                    id: 809,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.743Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  electricLoad: {
                    id: 810,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.768Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                  officespace_other: {
                    id: 811,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T08:22:15.801Z',
                    updated_at: '2021-07-05T08:22:21.000Z',
                  },
                },
              },
            },
          ],
        }),
      );
    }
    if (type === 'mhInfra') {
      dispatch(
        WarehouseByIdType({
          id: 23,
          warehouseName: 'Abcdefgh',
          category: 'Temperature',
          type: 'emptySpace',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: false,
          whsFieldAgaintWarehouseContactDetail: false,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: true,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: true,
          whsMhInfra: false,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: false,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: true,
          whsPermit: false,
          whsFieldAgaintPermit: false,
          materialType: true,
          whsMaterialType: false,
          whsFieldAgaintMaterialType: false,
          formEighty: true,
          whsFormEighty: false,
          whsFieldAgaintFormEighty: false,
          warehouseImages: true,
          whsWarehouseImages: false,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Submitted To WHS',
          adminStatus: 'New Request',
          fieldAgaintStatus: null,
          created_at: '2021-07-05T06:42:06.109Z',
          updated_at: '2021-07-05T08:22:21.000Z',
          mhInfraInfo: {
            id: 7,
            handPalletTruck: '44',
            pickingTrolley: '44',
            hydraulicDockLevler: '44',
            batteryOperatedPalletTruck: '44',
            electricStacker: '44',
            shrinkAndStretchWrapMachine: '44',
            others: 'gfffffffffffffff',
            whsAdminpalletsstatus: 'okay',
            whsAdminpalletsremark: 'fdfd',
            whsAdminhandPalletTruckstatus: 'okay',
            whsAdminhandPalletTruckremark: 'fdfd',
            whsAdminweighingMachinestatus: 'okay',
            whsAdminweighingMachineremark: 'fdfd',
            whsAdminpickingTrolleystatus: 'okay',
            whsAdminpickingTrolleyremark: 'fdfd',
            whsAdminhydraulicDockLevlerstatus: 'okay',
            whsAdminhydraulicDockLevlerremark: 'fdfd',
            whsAdminbatteryOperatedPalletTruckstatus: 'okay',
            whsAdminbatteryOperatedPalletTruckremark: 'fdfd',
            whsAdminelectricStackerstatus: 'okay',
            whsAdminelectricStackerremark: 'fdfd',
            whsAdminhydraCranestatus: 'okay',
            whsAdminhydraCraneremark: 'fdfd',
            whsAdminshrinkAndStretchWrapMachinestatus: 'okay',
            whsAdminshrinkAndStretchWrapMachineremark: 'fdfd',
            whsAdminothersstatus: 'okay',
            whsAdminothersremark: 'fdfd',
            ///////////////////////////

            whsFieldAgentpalletsstatus: 'okay',
            whsFieldAgentpalletsremark: 'fdfd',
            whsFieldAgenthandPalletTruckstatus: 'okay',
            whsFieldAgenthandPalletTruckremark: 'fdfd',
            whsFieldAgentweighingMachinestatus: 'okay',
            whsFieldAgentweighingMachineremark: 'fdfd',
            whsFieldAgentpickingTrolleystatus: 'okay',
            whsFieldAgentpickingTrolleyremark: 'fdfd',
            whsFieldAgenthydraulicDockLevlerstatus: 'okay',
            whsFieldAgenthydraulicDockLevlerremark: 'fdfd',
            whsFieldAgentbatteryOperatedPalletTruckstatus: 'okay',
            whsFieldAgentbatteryOperatedPalletTruckremark: 'fdfd',
            whsFieldAgentelectricStackerstatus: 'okay',
            whsFieldAgentelectricStackerremark: 'fdfd',
            whsFieldAgenthydraCranestatus: 'okay',
            whsFieldAgenthydraCraneremark: 'fdfd',
            whsFieldAgentshrinkAndStretchWrapMachinestatus: 'okay',
            whsFieldAgentshrinkAndStretchWrapMachineremark: 'fdfd',
            whsFieldAgentothersstatus: 'okay',
            whsFieldAgentothersremark: 'fdfd',
            pallets: {
              id: 7,
              quantity: '44',
              remark: 'gdgg',
            },
            weighingMachine: {
              id: 7,
              validFrom: null,
              validTill: null,
            },
            forkLifts: [
              {
                id: 13,
                type: 'batteryoperated',
                capacityTon: '44',
                whsAdminforkLiftsstatus: 'okay',
                whsAdminforkLiftsremark: 'fdfd',
                whsFieldAgentforkLiftsstatus: 'okay',
                whsFieldAgentforkLiftsremark: 'fdfd',
              },
              {
                id: 14,
                type: 'dieseloperated',
                capacityTon: '44444',
                whsAdminforkLiftsstatus: 'okay',
                whsAdminforkLiftsremark: 'fdfd',
                whsFieldAgentforkLiftsstatus: 'okay',
                whsFieldAgentforkLiftsremark: 'fdfd',
              },
            ],
            hydraCrane: {
              id: 7,
              loadCapacity: '15T',
              remark: 'gdf',
            },
          },
          whsWarehouse: [
            {
              id: 9,
              created_at: '2021-07-05T07:18:43.927Z',
              updated_at: '2021-07-05T07:18:43.927Z',
              whsMhinfra: {
                id: 4,
                pallets: {
                  id: 818,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:10.047Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                handPalletTruck: {
                  id: 814,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:09.947Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                weighingMachine: {
                  id: 820,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:10.193Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                pickingTrolley: {
                  id: 819,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:10.073Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                hydraulicDockLevler: {
                  id: 816,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:09.986Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                batteryOperatedPalletTruck: {
                  id: 812,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:09.890Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                electricStacker: {
                  id: 813,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:09.929Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                hydraCrane: {
                  id: 815,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:09.981Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                shrinkAndStretchWrapMachine: {
                  id: 821,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:10.260Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                mhinfra_others: {
                  id: 817,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T09:21:10.013Z',
                  updated_at: '2021-07-05T09:22:25.000Z',
                },
                forkLifts: [
                  {
                    id: 7,
                    type: 'batteryoperatedup',
                    forkLift: {
                      id: 823,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T09:21:10.472Z',
                      updated_at: '2021-07-05T09:22:25.000Z',
                    },
                  },
                  {
                    id: 8,
                    type: 'dieseloperated',
                    forkLift: {
                      id: 825,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T09:21:10.668Z',
                      updated_at: '2021-07-05T09:22:25.000Z',
                    },
                  },
                ],
              },
            },
          ],
        }),
      );
    }

    if (type === 'safetyAndSecurity') {
      dispatch(
        WarehouseByIdType({
          id: 23,
          warehouseName: 'Abcdefgh',
          category: 'Temperature',
          type: 'emptySpace',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: false,
          whsFieldAgaintWarehouseContactDetail: false,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: true,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: true,
          whsMhInfra: true,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: true,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: true,
          whsPermit: false,
          whsFieldAgaintPermit: false,
          materialType: true,
          whsMaterialType: false,
          whsFieldAgaintMaterialType: false,
          formEighty: true,
          whsFormEighty: false,
          whsFieldAgaintFormEighty: false,
          warehouseImages: true,
          whsWarehouseImages: false,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Submitted To WHS',
          adminStatus: 'New Request',
          fieldAgaintStatus: null,
          created_at: '2021-07-05T06:42:06.109Z',
          updated_at: '2021-07-05T10:01:24.000Z',
          safetyAndSecurityInfo: {
            id: 8,
            whsAdminfireSprinklersstatus: 'okay',
            whsAdminfireSprinklersremark: 'dfdf',
            whsAdminfireHydrantstatus: 'okay',
            whsAdminfireHydrantremark: 'dfdf',
            whsAdmincctvstatus: 'okay',
            whsAdmincctvremark: 'dfdf',
            whsAdminsmokeDetectorsAndFireAlarmstatus: 'okay',
            whsAdminsmokeDetectorsAndFireAlarmremark: 'dfdf',
            whsAdminwaterTankstatus: 'okay',
            whsAdminwaterTankremark: 'dfdf',
            whsAdminsecurityGuardstatus: 'okay',
            whsAdminsecurityGuardremark: 'dfdf',
            whsAdminmetalDetectorstatus: 'okay',
            whsAdminmetalDetectorremark: 'dfdf',
            whsAdminpestControlstatus: 'okay',
            whsAdminpestControlremark: 'dfdf',
            whsAdminothersstatus: 'okay',
            whsAdminothersremark: 'dfdf',

            //////////////////////////////
            whsFieldAgentfireSprinklersstatus: 'okay',
            whsFieldAgentfireSprinklersremark: 'dfdf',
            whsFieldAgentfireHydrantstatus: 'okay',
            whsFieldAgentfireHydrantremark: 'dfdf',
            whsFieldAgentcctvstatus: 'okay',
            whsFieldAgentcctvremark: 'dfdf',
            whsFieldAgentsmokeDetectorsAndFireAlarmstatus: 'okay',
            whsFieldAgentsmokeDetectorsAndFireAlarmremark: 'dfdf',
            whsFieldAgentwaterTankstatus: 'okay',
            whsFieldAgentwaterTankremark: 'dfdf',
            whsFieldAgentsecurityGuardstatus: 'okay',
            whsFieldAgentsecurityGuardremark: 'dfdf',
            whsFieldAgentmetalDetectorstatus: 'okay',
            whsFieldAgentmetalDetectorremark: 'dfdf',
            whsFieldAgentpestControlstatus: 'okay',
            whsFieldAgentpestControlremark: 'dfdf',
            whsFieldAgentothersstatus: 'okay',
            whsFieldAgentothersremark: 'dfdf',

            fireSprinklers: '',
            fireHydrant: '',
            cctv: '',
            smokeDetectorsAndFireAlarm: '',
            securityGuard: '',
            metalDetector: '',
            pestControl: null,
            others: 'dffffffffffffffff',
            fireExtinguishers: [
              {
                id: 22,
                type: 'co2',
                quantity: '',
                whsAdminfireExtinguisherstatus: 'okay',
                whsAdminfireExtinguisherremark: 'Ffdfd',
                whsFieldAgentfireExtinguisherstatus: 'okay',
                whsFieldAgentfireExtinguisherremark: 'Ffdfd',
                fireExtinguisherValidity: {
                  id: 23,
                  validFrom: null,
                  validTill: null,
                },
              },
              {
                id: 23,
                type: 'code',
                quantity: '',
                whsAdminfireExtinguisherstatus: 'okay',
                whsAdminfireExtinguisherremark: 'Ffdfd',
                whsFieldAgentfireExtinguisherstatus: 'okay',
                whsFieldAgentfireExtinguisherremark: 'Ffdfd',
                fireExtinguisherValidity: {
                  id: 22,
                  validFrom: null,
                  validTill: null,
                },
              },
              {
                id: 24,
                type: 'h20',
                quantity: '',
                whsAdminfireExtinguisherstatus: 'okay',
                whsAdminfireExtinguisherremark: 'Ffdfd',
                whsFieldAgentfireExtinguisherstatus: 'okay',
                whsFieldAgentfireExtinguisherremark: 'Ffdfd',
                fireExtinguisherValidity: {
                  id: 24,
                  validFrom: null,
                  validTill: null,
                },
              },
            ],
            waterTank: {
              id: 8,
              quantity: '',
              storage: '',
            },
          },
          whsWarehouse: [
            {
              id: 9,
              created_at: '2021-07-05T07:18:43.927Z',
              updated_at: '2021-07-05T07:18:43.927Z',
              whsSafetyAndSecurity: {
                id: 4,
                fireSprinklers: {
                  id: 826,
                  status: true,
                  remark: 'firespinker info',
                  created_at: '2021-07-05T10:01:23.164Z',
                  updated_at: '2021-07-05T10:01:23.164Z',
                },
                fireHydrant: {
                  id: 827,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T10:01:23.225Z',
                  updated_at: '2021-07-05T10:01:23.225Z',
                },
                cctv: {
                  id: 828,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T10:01:23.265Z',
                  updated_at: '2021-07-05T10:01:23.265Z',
                },
                waterTank: {
                  id: 829,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T10:01:23.299Z',
                  updated_at: '2021-07-05T10:01:23.299Z',
                },
                smokeDetectorsAndFireAlarm: {
                  id: 830,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T10:01:23.313Z',
                  updated_at: '2021-07-05T10:01:23.313Z',
                },
                securityGuard: {
                  id: 831,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T10:01:23.341Z',
                  updated_at: '2021-07-05T10:01:23.341Z',
                },
                metalDetector: {
                  id: 832,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T10:01:23.359Z',
                  updated_at: '2021-07-05T10:01:23.359Z',
                },
                pestControl: {
                  id: 833,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T10:01:23.379Z',
                  updated_at: '2021-07-05T10:01:23.379Z',
                },
                safetySecurityOther: {
                  id: 834,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T10:01:23.401Z',
                  updated_at: '2021-07-05T10:01:23.401Z',
                },
                whsFireExtinguishers: [
                  {
                    id: 10,
                    type: 'abc',
                    fireExtinguisher: {
                      id: 836,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T10:01:23.694Z',
                      updated_at: '2021-07-05T10:01:23.694Z',
                    },
                  },
                  {
                    id: 11,
                    type: 'co2',
                    fireExtinguisher: {
                      id: 838,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T10:01:24.006Z',
                      updated_at: '2021-07-05T10:01:24.006Z',
                    },
                  },
                  {
                    id: 12,
                    type: 'h20',
                    fireExtinguisher: {
                      id: 840,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T10:01:24.157Z',
                      updated_at: '2021-07-05T10:01:24.157Z',
                    },
                  },
                ],
              },
            },
          ],
        }),
      );
    }
    if (type === 'buildingTradeRelatedPermit') {
      dispatch(
        WarehouseByIdType({
          id: 23,
          warehouseName: 'Abcdefgh',
          category: 'Temperature',
          type: 'emptySpace',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: false,
          whsFieldAgaintWarehouseContactDetail: false,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: true,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: true,
          whsMhInfra: true,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: true,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: true,
          whsPermit: true,
          whsFieldAgaintPermit: false,
          materialType: true,
          whsMaterialType: false,
          whsFieldAgaintMaterialType: false,
          formEighty: true,
          whsFormEighty: false,
          whsFieldAgaintFormEighty: false,
          warehouseImages: true,
          whsWarehouseImages: false,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Submitted To WHS',
          adminStatus: 'New Request',
          fieldAgaintStatus: null,
          created_at: '2021-07-05T06:42:06.109Z',
          updated_at: '2021-07-05T10:37:41.000Z',
          buildingTradeRelatedPermitInfo: {
            id: 7,
            tradeRelatedPermit: {
              id: 7,
              whsAdmingstRegistrationCertificatestatus: 'okay',
              whsAdmingstRegistrationCertificateremark: 'fddf',
              whsAdminpanCardOfCompanystatus: 'okay',
              whsAdminpanCardOfCompanyremark: 'fddf',
              whsAdminelectricityBillstatus: 'okay',
              whsAdminelectricityBillremark: 'fddf',
              whsAdminshopAndEstablishmentCertificatestatus: 'okay',
              whsAdminshopAndEstablishmentCertificateremark: 'fddf',
              //////

              whsFieldAgentgstRegistrationCertificatestatus: 'okay',
              whsFieldAgentgstRegistrationCertificateremark: 'fddf',
              whsFieldAgentpanCardOfCompanystatus: 'okay',
              whsFieldAgentpanCardOfCompanyremark: 'fddf',
              whsFieldAgentelectricityBillstatus: 'okay',
              whsFieldAgentelectricityBillremark: 'fddf',
              whsFieldAgentshopAndEstablishmentCertificatestatus: 'okay',
              whsFieldAgentshopAndEstablishmentCertificateremark: 'fddf',

              gstRegistrationCertificate: '',
              panCardOfCompany: '',
              electricityBill: '',
              shopAndEstablishmentCertificate: '',
              tradeRelatedPermitInfo: [
                {
                  id: 37,
                  validForm: null,
                  validTill: null,
                  whsAdminpucstatus: 'okay',
                  whsAdminpucremark: 'dff',
                  whsFieldAgentpucstatus: 'okay',
                  whsFieldAgentpucremark: 'dff',
                  filePath: '',
                  type: 'puc',
                },
                {
                  id: 38,
                  validForm: null,
                  validTill: null,
                  filePath: '',
                  whsAdminpanchayatMunicipalNOCstatus: 'okay',
                  whsAdminpanchayatMunicipalNOCremark: 'dff',
                  whsFieldAgentpanchayatMunicipalNOCstatus: 'okay',
                  whsFieldAgentpanchayatMunicipalNOCremark: 'dff',
                  type: 'panchayatMunicipalNOC',
                },
                {
                  id: 39,
                  validForm: null,
                  validTill: null,
                  whsAdminlaborLicensestatus: 'okay',
                  whsAdminlaborLicenseremark: 'dff',
                  whsFieldAgentlaborLicensestatus: 'okay',
                  whsFieldAgentlaborLicenseremark: 'dff',
                  filePath: '',
                  type: 'laborLicense',
                },
                {
                  id: 40,
                  validForm: null,
                  validTill: null,
                  filePath: '',
                  whsAdmintradeLicensestatus: 'okay',
                  whsAdmintradeLicenseremark: 'dff',
                  whsFieldAgenttradeLicensestatus: 'okay',
                  whsFieldAgenttradeLicenseremark: 'dff',
                  type: 'tradeLicense',
                },
                {
                  id: 41,
                  validForm: null,
                  validTill: null,
                  whsAdminfactoryLicensestatus: 'okay',
                  whsAdminfactoryLicenseremark: 'dff',
                  whsFieldAgentfactoryLicensestatus: 'okay',
                  whsFieldAgentfactoryLicenseremark: 'dff',
                  filePath: '',
                  type: 'factoryLicense',
                },
                {
                  id: 42,
                  validForm: null,
                  validTill: null,
                  whsAdminfssaiLicensestatus: 'okay',
                  whsAdminfssaiLicenseremark: 'dff',
                  whsFieldAgentfssaiLicensestatus: 'okay',
                  whsFieldAgentfssaiLicenseremark: 'dff',
                  filePath: '',
                  type: 'fssaiLicense',
                },
              ],
            },
            buildingRelatedPermit: {
              id: 7,
              ownershipDocument: '',
              whsAdminownershipDocumentstatus: 'okay',
              whsAdminownershipDocumentremark: 'dffd',

              whsAdmincluCommercialWarehousingstatus: 'okay',
              whsAdmincluCommercialWarehousingremark: 'dffd',

              whsAdmincompletionCertificatestatus: 'okay',
              whsAdmincompletionCertificateremark: 'dffd',

              whsAdminoccupancyCertificatestatus: 'okay',
              whsAdminoccupancyCertificateremark: 'dffd',

              whsAdminapprovedBuildingLayoutstatus: 'okay',
              whsAdminapprovedBuildingLayoutremark: 'dffd',

              whsAdminwarehousePhotoGallerystatus: 'okay',
              whsAdminwarehousePhotoGalleryremark: 'dffd',

              whsAdminwarehouseProfilePicstatus: 'okay',
              whsAdminwarehouseProfilePicremark: 'dffd',

              ///////////////////////////////////////////

              whsFieldAgentownershipDocumentstatus: 'okay',
              whsFieldAgentownershipDocumentremark: 'dffd',

              whsFieldAgentcluCommercialWarehousingstatus: 'okay',
              whsFieldAgentcluCommercialWarehousingremark: 'dffd',

              whsFieldAgentcompletionCertificatestatus: 'okay',
              whsFieldAgentcompletionCertificateremark: 'dffd',

              whsFieldAgentoccupancyCertificatestatus: 'okay',
              whsFieldAgentoccupancyCertificateremark: 'dffd',

              whsFieldAgentapprovedBuildingLayoutstatus: 'okay',
              whsFieldAgentapprovedBuildingLayoutremark: 'dffd',

              whsFieldAgentwarehousePhotoGallerystatus: 'okay',
              whsFieldAgentwarehousePhotoGalleryremark: 'dffd',

              whsFieldAgentwarehouseProfilePicstatus: 'okay',
              whsFieldAgentwarehouseProfilePicremark: 'dffd',

              cluCommercialWarehousing: '',
              completionCertificate: '',
              occupancyCertificate: '',
              approvedBuildingLayout: '',
              warehousePhotoGallery:
                'http://139.59.13.212:8080/api/v1/buildingtraderelated/52b9a4af-8a4c-4afc-a971-a5e78e73ceee.pdf',
              warehouseProfilePic:
                'http://139.59.13.212:8080/api/v1/buildingtraderelated/087c2224-d581-46c7-8071-175176df9715.pdf',
              buildingRelatedPermitInfo: [
                {
                  id: 37,
                  validForm: null,
                  whsAdminbuildingStabilityCertificatestatus: 'okay',
                  whsAdminbuildingStabilityCertificateremark: 'dff',
                  whsFieldAgentbuildingStabilityCertificatestatus: 'okay',
                  whsFieldAgentbuildingStabilityCertificateremark: 'dff',
                  validTill: null,
                  filePath: '',
                  type: 'buildingStabilityCertificate',
                },
                {
                  id: 38,
                  validForm: null,
                  validTill: null,
                  filePath: '',
                  whsAdminbuildingInsurancestatus: 'okay',
                  whsAdminbuildingInsuranceremark: 'dff',
                  whsFieldAgentbuildingInsurancestatus: 'okay',
                  whsFieldAgentbuildingInsuranceremark: 'dff',
                  type: 'buildingInsurance',
                },
                {
                  id: 39,
                  validForm: null,
                  validTill: null,
                  filePath: '',
                  whsAdmincopyOfLeaseAgreementstatus: 'okay',
                  whsAdmincopyOfLeaseAgreementremark: 'dff',
                  whsFieldAgentcopyOfLeaseAgreementstatus: 'okay',
                  whsFieldAgentcopyOfLeaseAgreementremark: 'dff',
                  type: 'copyOfLeaseAgreement',
                },
                {
                  id: 40,
                  validForm: null,
                  validTill: null,
                  filePath: '',
                  whsAdminbuildingInsurancestatus: 'okay',
                  whsAdminbuildingInsuranceremark: 'dff',
                  whsFieldAgentbuildingInsurancestatus: 'okay',
                  whsFieldAgentbuildingInsuranceremark: 'dff',
                  type: 'buildingInsurance',
                },
                {
                  id: 41,
                  validForm: null,
                  validTill: null,
                  filePath: '',
                  whsAdminfireNocstatus: 'okay',
                  whsAdminfireNocremark: 'dff',
                  whsFieldAgentfireNocstatus: 'okay',
                  whsFieldAgentfireNocremark: 'dff',
                  type: 'fireNoc',
                },
                {
                  id: 42,
                  validForm: null,
                  validTill: null,
                  filePath: '',
                  whsAdminfloorLoadBearingCapacityCertificatestatus: 'okay',
                  whsAdminfloorLoadBearingCapacityCertificateremark: 'dff',
                  whsFieldAgentfloorLoadBearingCapacityCertificatestatus: 'okay',
                  whsFieldAgentfloorLoadBearingCapacityCertificateremark: 'dff',
                  type: 'floorLoadBearingCapacityCertificate',
                },
              ],
            },
          },
          whsWarehouse: [
            {
              id: 9,
              created_at: '2021-07-05T07:18:43.927Z',
              updated_at: '2021-07-05T07:18:43.927Z',
              whsBuildingTradeRelatedPermit: {
                id: 3,
                buildingPermit: {
                  id: 3,
                  ownershipDocument: {
                    id: 841,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:04.870Z',
                    updated_at: '2021-07-05T10:37:40.000Z',
                  },
                  cluCommercialWarehousing: {
                    id: 842,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:04.941Z',
                    updated_at: '2021-07-05T10:37:40.000Z',
                  },
                  completionCertificate: {
                    id: 843,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:04.967Z',
                    updated_at: '2021-07-05T10:37:40.000Z',
                  },
                  occupancyCertificate: {
                    id: 844,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:05.016Z',
                    updated_at: '2021-07-05T10:37:40.000Z',
                  },
                  approvedBuildingLayout: {
                    id: 845,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:05.039Z',
                    updated_at: '2021-07-05T10:37:40.000Z',
                  },
                  buildingInsurance: null,
                  warehousePhotoGallery: {
                    id: 846,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:05.069Z',
                    updated_at: '2021-07-05T10:37:40.000Z',
                  },
                  warehouseProfilePic: {
                    id: 847,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:05.120Z',
                    updated_at: '2021-07-05T10:37:40.000Z',
                  },
                  buildingRelatedPermit: [
                    {
                      id: 11,
                      type: 'Copy Of Lease Agreement With Landlord',
                      building: {
                        id: 850,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:05.534Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 12,
                      type: 'Building Insurance',
                      building: {
                        id: 852,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:05.698Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 13,
                      type: 'Building Stability Certificate',
                      building: {
                        id: 854,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:05.902Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 14,
                      type: 'Fire NOC',
                      building: {
                        id: 856,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:06.073Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 15,
                      type: 'Floor Load Bearing Capacity Certificate',
                      building: {
                        id: 858,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:06.245Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                  ],
                },
                tradePermit: {
                  id: 3,
                  gstRegistrationCertificate: {
                    id: 859,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:06.416Z',
                    updated_at: '2021-07-05T10:37:41.000Z',
                  },
                  shopAndEstablishmentCertificate: {
                    id: 862,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:06.508Z',
                    updated_at: '2021-07-05T10:37:41.000Z',
                  },
                  panCardOfCompany: {
                    id: 860,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:06.437Z',
                    updated_at: '2021-07-05T10:37:41.000Z',
                  },
                  electricityBill: {
                    id: 861,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T10:32:06.488Z',
                    updated_at: '2021-07-05T10:37:41.000Z',
                  },
                  tradeRelatedPermit: [
                    {
                      id: 13,
                      type: 'Panchayat / Municipal NOC',
                      trade: {
                        id: 865,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:06.696Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 14,
                      type: 'Trade License',
                      trade: {
                        id: 867,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:06.877Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 15,
                      type: 'Labor License',
                      trade: {
                        id: 869,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:07.023Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 16,
                      type: 'Factory License (for VAS activities)',
                      trade: {
                        id: 871,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:07.223Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 17,
                      type: 'FSSAI License',
                      trade: {
                        id: 873,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:07.322Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                    {
                      id: 18,
                      type: 'Pollution Under Control(PUC)',
                      trade: {
                        id: 875,
                        status: true,
                        remark: '',
                        created_at: '2021-07-05T10:32:07.440Z',
                        updated_at: '2021-07-05T10:37:41.000Z',
                      },
                    },
                  ],
                },
              },
            },
          ],
        }),
      );
    }
    if (type === 'materialType') {
      dispatch(
        WarehouseByIdType({
          id: 23,
          warehouseName: 'Abcdefgh',
          category: 'Temperature',
          type: 'emptySpace',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: false,
          whsFieldAgaintWarehouseContactDetail: false,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: true,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: true,
          whsMhInfra: true,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: true,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: true,
          whsPermit: true,
          whsFieldAgaintPermit: false,
          materialType: true,
          whsMaterialType: true,
          whsFieldAgaintMaterialType: false,
          formEighty: true,
          whsFormEighty: false,
          whsFieldAgaintFormEighty: false,
          warehouseImages: true,
          whsWarehouseImages: false,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Submitted To WHS',
          adminStatus: 'New Request',
          fieldAgaintStatus: null,
          created_at: '2021-07-05T06:42:06.109Z',
          updated_at: '2021-07-05T12:11:54.000Z',
          materialTypeInfo: {
            id: 7,
            whsAdmindryMaterialstatus: 'okay',
            whsAdmindryMaterialremark: 'fdfd',
            whsAdminwetMaterialstatus: 'okay',
            whsAdminwetMaterialremark: 'fdfd',
            whsAdminagriAndAlliedIndustriesstatus: 'okay',
            whsAdminagriAndAlliedIndustriesremark: 'fdfd',
            whsAdminautoMobilesstatus: 'okay',
            whsAdminautoMobilesremark: 'fdfd',
            whsAdminautoComponentsstatus: 'okay',
            whsAdminautoComponentsremark: 'fdfd',
            whsAdminaviationstatus: 'okay',
            whsAdminaviationremark: 'fdfd',
            whsAdminconsumerDurablesstatus: 'okay',
            whsAdminconsumerDurablesremark: 'fdfd',
            whsAdminecommercestatus: 'okay',
            whsAdminecommerceremark: 'fdfd',
            whsAdmineducationRelatedstatus: 'okay',
            whsAdmineducationRelatedremark: 'fdfd',
            whsAdminengineeringGoodsstatus: 'okay',
            whsAdminengineeringGoodsremark: 'fdfd',
            whsAdminfmcgstatus: 'okay',
            whsAdminfmcgremark: 'fdfd',
            whsAdminhealthAndPharmastatus: 'okay',
            whsAdminhealthAndPharmaremark: 'fdfd',
            whsAdminitAndItesstatus: 'okay',
            whsAdminitAndItesremark: 'fdfd',
            whsAdminmanufacturingstatus: 'okay',
            whsAdminmanufacturingremark: 'fdfd',
            whsAdminmediaAndEntertainmentstatus: 'okay',
            whsAdminmediaAndEntertainmentremark: 'fdfd',
            whsAdminoilAndGasstatus: 'okay',
            whsAdminoilAndGasremark: 'fdfd',
            whsAdminpowerstatus: 'okay',
            whsAdminpowerremark: 'fdfd',
            whsAdminrenewableEnergystatus: 'okay',
            whsAdminrenewableEnergyremark: 'fdfd',
            whsAdminretailstatus: 'okay',
            whsAdminretailremark: 'fdfd',
            whsAdminscienceAndTechnologystatus: 'okay',
            whsAdminscienceAndTechnologyremark: 'fdfd',
            whsAdminsteelstatus: 'okay',
            whsAdminsteelremark: 'fdfd',
            whsAdmintelecommunicationstatus: 'okay',
            whsAdmintelecommunicationremark: 'fdfd',
            whsAdmintextTilesstatus: 'okay',
            whsAdmintextTilesremark: 'fdfd',
            whsAdmintourismAndHospitalitystatus: 'okay',
            whsAdmintourismAndHospitalityremark: 'fdfd',
            whsAdminprominentCustomersInTheWarehousestatus: 'okay',
            whsAdminprominentCustomersInTheWarehouseremark: 'fdfd',

            /////////////////

            whsFieldAgentdryMaterialstatus: 'okay',
            whsFieldAgentdryMaterialremark: 'fdfd',
            whsFieldAgentwetMaterialstatus: 'okay',
            whsFieldAgentwetMaterialremark: 'fdfd',
            whsFieldAgentagriAndAlliedIndustriesstatus: 'okay',
            whsFieldAgentagriAndAlliedIndustriesremark: 'fdfd',
            whsFieldAgentautoMobilesstatus: 'okay',
            whsFieldAgentautoMobilesremark: 'fdfd',
            whsFieldAgentautoComponentsstatus: 'okay',
            whsFieldAgentautoComponentsremark: 'fdfd',
            whsFieldAgentaviationstatus: 'okay',
            whsFieldAgentaviationremark: 'fdfd',
            whsFieldAgentconsumerDurablesstatus: 'okay',
            whsFieldAgentconsumerDurablesremark: 'fdfd',
            whsFieldAgentecommercestatus: 'okay',
            whsFieldAgentecommerceremark: 'fdfd',
            whsFieldAgenteducationRelatedstatus: 'okay',
            whsFieldAgenteducationRelatedremark: 'fdfd',
            whsFieldAgentengineeringGoodsstatus: 'okay',
            whsFieldAgentengineeringGoodsremark: 'fdfd',
            whsFieldAgentfmcgstatus: 'okay',
            whsFieldAgentfmcgremark: 'fdfd',
            whsFieldAgenthealthAndPharmastatus: 'okay',
            whsFieldAgenthealthAndPharmaremark: 'fdfd',
            whsFieldAgentitAndItesstatus: 'okay',
            whsFieldAgentitAndItesremark: 'fdfd',
            whsFieldAgentmanufacturingstatus: 'okay',
            whsFieldAgentmanufacturingremark: 'fdfd',
            whsFieldAgentmediaAndEntertainmentstatus: 'okay',
            whsFieldAgentmediaAndEntertainmentremark: 'fdfd',
            whsFieldAgentoilAndGasstatus: 'okay',
            whsFieldAgentoilAndGasremark: 'fdfd',
            whsFieldAgentpowerstatus: 'okay',
            whsFieldAgentpowerremark: 'fdfd',
            whsFieldAgentrenewableEnergystatus: 'okay',
            whsFieldAgentrenewableEnergyremark: 'fdfd',
            whsFieldAgentretailstatus: 'okay',
            whsFieldAgentretailremark: 'fdfd',
            whsFieldAgentscienceAndTechnologystatus: 'okay',
            whsFieldAgentscienceAndTechnologyremark: 'fdfd',
            whsFieldAgentsteelstatus: 'okay',
            whsFieldAgentsteelremark: 'fdfd',
            whsFieldAgenttelecommunicationstatus: 'okay',
            whsFieldAgenttelecommunicationremark: 'fdfd',
            whsFieldAgenttextTilesstatus: 'okay',
            whsFieldAgenttextTilesremark: 'fdfd',
            whsFieldAgenttourismAndHospitalitystatus: 'okay',
            whsFieldAgenttourismAndHospitalityremark: 'fdfd',
            whsFieldAgentprominentCustomersInTheWarehousestatus: 'okay',
            whsFieldAgentprominentCustomersInTheWarehouseremark: 'fdfd',

            dryMaterial: true,
            wetMaterial: true,
            agriAndAlliedIndustries: true,
            autoMobiles: true,
            autoComponents: true,
            aviation: true,
            consumerDurables: true,
            ecommerce: true,
            educationRelated: true,
            engineeringGoods: true,
            fmcg: true,
            healthAndPharma: true,
            itAndItes: true,
            manufacturing: true,
            mediaAndEntertainment: true,
            oilAndGas: true,
            power: true,
            renewableEnergy: true,
            retail: true,
            scienceAndTechnology: true,
            steel: true,
            telecommunication: true,
            textTiles: true,
            tourismAndHospitality: true,
            prominentCustomersInTheWarehouse: '444',
            chemicals: [
              {
                id: 13,
                type: 'updatedry',
                whsAdminChemicalStatus: 'okay',
                whsAdminChemicalRemark: 'fdffffff',
                whsFieldAgentChemicalStatus: 'okay',
                whsFieldAgentChemicalRemark: 'fdffffff',
                active: 'false',
              },
              {
                id: 14,
                type: 'wet',
                whsAdminChemicalStatus: 'okay',
                whsAdminChemicalRemark: 'fdffffff',
                whsFieldAgentChemicalStatus: 'okay',
                whsFieldAgentChemicalRemark: 'fdffffff',
                active: 'false',
              },
            ],
          },
          whsWarehouse: [
            {
              id: 9,
              created_at: '2021-07-05T07:18:43.927Z',
              updated_at: '2021-07-05T07:18:43.927Z',
              whsMaterialTypes: {
                id: 3,
                dryMaterial: {
                  id: 892,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.992Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                wetMaterial: {
                  id: 891,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.981Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                agriAndAlliedIndustries: {
                  id: 890,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.962Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                autoMobiles: {
                  id: 889,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.946Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                autoComponents: {
                  id: 888,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.932Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                aviation: {
                  id: 887,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.916Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                consumerDurables: {
                  id: 886,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.902Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                ecommerce: {
                  id: 885,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.888Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                educationRelated: {
                  id: 884,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.864Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                engineeringGoods: {
                  id: 883,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.850Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                fmcg: {
                  id: 882,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.820Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                healthAndPharma: {
                  id: 881,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.805Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                itAndItes: {
                  id: 880,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.785Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                manufacturing: {
                  id: 879,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.770Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                mediaAndEntertainment: {
                  id: 878,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.760Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                oilAndGas: {
                  id: 877,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.735Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                power: {
                  id: 876,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:48.698Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                renewableEnergy: {
                  id: 893,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:49.017Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                retail: {
                  id: 894,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:49.031Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                scienceAndTechnology: {
                  id: 895,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:49.046Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                steel: {
                  id: 896,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:49.066Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                telecommunication: {
                  id: 897,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:49.084Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                textTiles: {
                  id: 898,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:49.096Z',
                  updated_at: '2021-07-05T12:11:53.000Z',
                },
                tourismAndHospitality: {
                  id: 899,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:49.113Z',
                  updated_at: '2021-07-05T12:11:54.000Z',
                },
                prominentCustomersInTheWarehouse: {
                  id: 900,
                  status: true,
                  remark: '',
                  created_at: '2021-07-05T12:11:49.129Z',
                  updated_at: '2021-07-05T12:11:54.000Z',
                },
                chemicals: [
                  {
                    id: 5,
                    type: 'dryup',
                    chemical: {
                      id: 902,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T12:11:49.282Z',
                      updated_at: '2021-07-05T12:11:54.000Z',
                    },
                  },
                  {
                    id: 6,
                    type: 'wetup',
                    chemical: {
                      id: 904,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T12:11:49.426Z',
                      updated_at: '2021-07-05T12:11:54.000Z',
                    },
                  },
                ],
              },
            },
          ],
        }),
      );
    }
    if (type === 'warehouseImages') {
      dispatch(
        WarehouseByIdType({
          id: 23,
          warehouseName: 'Abcdefgh',
          category: 'Temperature',
          type: 'emptySpace',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: false,
          whsFieldAgaintWarehouseContactDetail: false,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: true,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: true,
          whsMhInfra: true,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: true,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: true,
          whsPermit: true,
          whsFieldAgaintPermit: false,
          materialType: true,
          whsMaterialType: true,
          whsFieldAgaintMaterialType: false,
          formEighty: true,
          whsFormEighty: false,
          whsFieldAgaintFormEighty: false,
          warehouseImages: true,
          whsWarehouseImages: true,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Submitted To WHS',
          adminStatus: 'New Request',
          fieldAgaintStatus: null,
          created_at: '2021-07-05T06:42:06.109Z',
          updated_at: '2021-07-05T12:50:12.000Z',
          warehouseImagesInfo: {
            id: 7,
            whsAdmincoverImagestatus: 'okay',
            whsAdmincoverImageremark: 'fdfd',
            whsFieldAgentcoverImagestatus: 'okay',
            whsFieldAgentcoverImageremark: 'fdfd',
            coverImage: {
              id: 7,
              url: 'http://139.59.13.212:8080/api/v1/warehouseimage/eef53382-1f43-4f24-abac-f7ac40f199cb.png',
              type: 'coverImage',
            },
            indoorImages: [
              {
                id: 6,
                whsAdminindoorImagesstatus: 'okay',
                whsAdminindoorImagesremark: 'fdfd',
                whsFieldAgentindoorImagesstatus: 'okay',
                whsFieldAgentindoorImagesremark: 'fdfd',
                url: 'http://139.59.13.212:8080/api/v1/warehouseimage/12aad36f-cfe5-425c-8721-58a26c36e5de.png',
                type: 'indoor',
              },
            ],
            outdoorImages: [
              {
                id: 6,
                whsAdminoutdoorImagesstatus: 'okay',
                whsAdminoutdoorImagesremark: 'fdfd',
                whsFieldAgentoutdoorImagesstatus: 'okay',
                whsFieldAgentoutdoorImagesremark: 'fdfd',
                url: 'http://139.59.13.212:8080/api/v1/warehouseimage/95eb4519-293c-4cd5-b592-9efa9844addf.png',
                type: 'outdoor',
              },
            ],
          },
          whsWarehouse: [
            {
              id: 9,
              created_at: '2021-07-05T07:18:43.927Z',
              updated_at: '2021-07-05T07:18:43.927Z',
              whsWarehouseImage: {
                id: 3,
                whsIndoors: [
                  {
                    id: 3,
                    type: 'indoor0',
                    indoor: {
                      id: 906,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T12:50:12.085Z',
                      updated_at: '2021-07-05T12:50:12.085Z',
                    },
                  },
                ],
                whsOutdoors: [
                  {
                    id: 3,
                    type: 'out0',
                    outdoor: {
                      id: 908,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T12:50:12.187Z',
                      updated_at: '2021-07-05T12:50:12.187Z',
                    },
                  },
                ],
                whsCoverImage: {
                  id: 3,
                  type: '',
                  coverImg: {
                    id: 910,
                    status: true,
                    remark: '',
                    created_at: '2021-07-05T12:50:12.300Z',
                    updated_at: '2021-07-05T12:50:12.300Z',
                  },
                },
              },
            },
          ],
        }),
      );
    }

    if (type === 'formEighty') {
      dispatch(
        WarehouseByIdType({
          id: 23,
          warehouseName: 'Abcdefgh',
          category: 'Temperature',
          type: 'emptySpace',
          gstCertificate: '',
          gstNumber: '',
          location: 'noida',
          totalArea: 5000,
          structureType: 'shelter',
          workingHour: '9am tom 6pm',
          pallet: 50,
          warehouseContactDetail: true,
          whsWarehouseContactDetail: false,
          whsFieldAgaintWarehouseContactDetail: false,
          storageSpace: true,
          whsStorageSpace: true,
          whsFieldAgaintStorageSpace: false,
          itAndOfficeInfra: true,
          whsItAndOfficeInfra: true,
          whsFieldAgaintItAndOfficeInfra: false,
          mhInfra: true,
          whsMhInfra: true,
          whsFieldAgaintMhInfra: false,
          safetyAndSecurity: true,
          whsSafetyAndSecurity: true,
          whsFieldAgaintSafetyAndSecurity: false,
          permit: true,
          whsPermit: true,
          whsFieldAgaintPermit: false,
          materialType: true,
          whsMaterialType: true,
          whsFieldAgaintMaterialType: false,
          formEighty: true,
          whsFormEighty: true,
          whsFieldAgaintFormEighty: false,
          warehouseImages: true,
          whsWarehouseImages: true,
          whsFieldAgaintWarehouseImages: false,
          bookingStatus: 'PENDING',
          warehouseFormFilled: false,
          vendorStatus: 'Submitted To WHS',
          adminStatus: 'New Request',
          fieldAgaintStatus: null,
          created_at: '2021-07-05T06:42:06.109Z',
          updated_at: '2021-07-05T13:07:50.000Z',
          formEightyInfo: [
            {
              id: 441,
              heading: 'Accessibility',
              parameter: 'Distance from Police Station (KMs)',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              input: 'f',
              remark: 'f',
            },
            {
              id: 442,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'How wide is the Road Access to the warehouse from the main road-Ft',
              input: 'a',
              remark: 'a',
            },
            {
              id: 443,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from Fire Station (KMs)',
              input: 'b',
              remark: 'b',
            },
            {
              id: 444,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'How wide is the Road / Pathway inside the premises-Ft.',
              input: 'cc',
              remark: 'c',
            },
            {
              id: 445,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is the WH in residential area?',
              input: 'd',
              remark: 'd',
            },
            {
              id: 446,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is the WH in industrial area or any WH zone??',
              input: 'e',
              remark: 'e',
            },
            {
              id: 447,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from Nearest School (KMs)',
              input: 'g',
              remark: 'g',
            },
            {
              id: 448,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from Highway Road (KMs)',
              input: 'h',
              remark: 'h',
            },
            {
              id: 449,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from Metro/ Bus Station (KMs)',
              input: 'k',
              remark: 'k',
            },
            {
              id: 450,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from City Centre (KMs)',
              input: 'l',
              remark: 'l',
            },
            {
              id: 451,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from ICD/CFS/Port (KMs)',
              input: 'm',
              remark: 'm',
            },
            {
              id: 452,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from the Labour hub',
              input: 'n',
              remark: 'no',
            },
            {
              id: 453,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Public transport availability',
              input: 'o',
              remark: 'o',
            },
            {
              id: 454,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from Hospital (KMs)',
              input: 'p',
              remark: 'p',
            },
            {
              id: 455,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from transport Hub (KMs)',
              input: 'i',
              remark: 'i',
            },
            {
              id: 456,
              heading: 'Accessibility',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Distance from warehousing Hub (KMs)',
              input: 'j',
              remark: 'j',
            },
            {
              id: 457,
              heading: 'Accommodation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Are additional rooms available in the compound to be used as residence for Supervisors / workers?',
              input: 'q',
              remark: 'q',
            },
            {
              id: 458,
              heading: 'Accommodation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'How nearest is the resdential colony for workers from the warehouse?',
              input: 'r',
              remark: 'r',
            },
            {
              id: 459,
              heading: 'Affiliation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there any Labour Union in the area?',
              input: 's',
              remark: 's',
            },
            {
              id: 460,
              heading: 'Affiliation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there any Transportation Union in the area?',
              input: 't',
              remark: 't',
            },
            {
              id: 461,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Construction Type (RCC / Mixed / Shed)',
              input: 'u',
              remark: 'u',
            },
            {
              id: 462,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Construction Age (No of Years)',
              input: 'v',
              remark: 'v',
            },
            {
              id: 463,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Transparent Sheets in the roof (for Day Light)-Qty',
              input: 'w',
              remark: 'w',
            },
            {
              id: 464,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Turbo Ventilators in the shed-Qty',
              input: 'x',
              remark: 'x',
            },
            {
              id: 465,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Flooring Type – Trimix / Normal / Epoxy',
              input: 'y',
              remark: 'y',
            },
            {
              id: 466,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Roof height of the storage area from the platform',
              input: 'aa',
              remark: 'aa',
            },
            {
              id: 467,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'How many Loading / Unloading Docks avaibale in the warehouse?',
              input: 'bb',
              remark: 'bb',
            },
            {
              id: 468,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Shutter height from the platform',
              input: 'z',
              remark: 'z',
            },
            {
              id: 469,
              heading: 'Accommodation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is the WH has Hydraulic Loading/Unloading Docs?',
              input: 'cc',
              remark: 'cc',
            },
            {
              id: 470,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Dock height from the ground',
              input: 'dd',
              remark: 'dd',
            },
            {
              id: 471,
              heading: 'Construction',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'General apearance of the floor (Good / Bad / Ugly)',
              input: 'ee',
              remark: 'ee',
            },
            {
              id: 472,
              heading: 'Accommodation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'What is the size of the parking area for personal vehicles inside the coumpound?',
              input: 'ff',
              remark: 'ff',
            },
            {
              id: 473,
              heading: 'Accommodation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'What is the size of the parking area for trucks inside the coumpound?',
              input: 'gg',
              remark: 'gg',
            },
            {
              id: 474,
              heading: 'Accommodation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there a meeting room available in the warehouse?',
              input: 'hh',
              remark: 'hh',
            },
            {
              id: 475,
              heading: 'Accommodation',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is the WH structure capable to take load of solar panels?',
              input: 'ii',
              remark: 'ii',
            },
            {
              id: 476,
              heading: 'Electricity & Electrical Fittings',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there Genset Area available in the coumpound.',
              input: 'jj',
              remark: 'jj',
            },
            {
              id: 477,
              heading: 'Electricity & Electrical Fittings',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Electricity Connection (Kw)',
              input: 'kk',
              remark: 'kk',
            },
            {
              id: 478,
              heading: 'Electricity & Electrical Fittings',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Dedicated Transformer (Kva)',
              input: 'll',
              remark: 'll',
            },
            {
              id: 479,
              heading: 'Electricity & Electrical Fittings',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Electrical fittings /wirings /gadgets are with ISI marks.',
              input: 'mm',
              remark: 'mm',
            },
            {
              id: 480,
              heading: 'Electricity & Electrical Fittings',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Electrical Earthing is available\t',
              input: 'nn',
              remark: 'nn',
            },
            {
              id: 481,
              heading: 'Electricity & Electrical Fittings',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there is Electricity panel / Power Panel Room in the compound ?\t',
              input: 'oo',
              remark: 'oo',
            },
            {
              id: 482,
              heading: 'Electricity & Electrical Fittings',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'No open / loose wiring in and outside of the warehouse\t',
              input: 'pp',
              remark: 'pp',
            },
            {
              id: 483,
              heading: 'Electricity & Electrical Fittings',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is the WH using any green energy? Eg-Solar power\t',
              input: 'qq',
              remark: 'qq',
            },
            {
              id: 484,
              heading: 'Emergency Exit & Fire Prevention',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'No of Emergency Door available inside the warehouse?\t',
              input: 'rr',
              remark: 'rr',
            },
            {
              id: 485,
              heading: 'Emergency Exit & Fire Prevention',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there an assembly area earmarked during fire breakout?\t',
              input: 'ss',
              remark: 'ss',
            },
            {
              id: 486,
              heading: 'Emergency Exit & Fire Prevention',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'What is the size of open area?-SQ Ft\t',
              input: 'tt',
              remark: 'tt',
            },
            {
              id: 487,
              heading: 'Emergency Exit & Fire Prevention',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are the fire fighting equipments in approachable limits inside the WH?\t',
              input: 'uu',
              remark: 'uuvv',
            },
            {
              id: 488,
              heading: 'Emergency Exit & Fire Prevention',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are the fire fighting equipments inside the WH are in adequate qty?\t',
              input: 'vv',
              remark: 'vvww',
            },
            {
              id: 489,
              heading: 'Emergency Exit & Fire Prevention',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Are the workers trained to fire fight and use fire fighting equipments?\t',
              input: 'ww',
              remark: 'ww',
            },
            {
              id: 490,
              heading: 'Emergency Exit & Fire Prevention',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Is the evacuation route clearly marked and visible inside the warehouse?\t',
              input: 'xx',
              remark: 'xx',
            },
            {
              id: 491,
              heading: 'Emergency Exit & Fire Prevention',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Emergency alarm is clearly audible, supported by battery and alarm buttons are available inside WH?\t',
              input: 'yy',
              remark: 'yy',
            },
            {
              id: 492,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Does the compound has Green belt / plantation?\t',
              input: 'zz',
              remark: 'zz',
            },
            {
              id: 493,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there a Rain Water Harvesting / Drainage system?\t',
              input: 'aaa',
              remark: 'aaa',
            },
            {
              id: 494,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is the open area well metteled?\t',
              input: 'bbb',
              remark: 'bbb',
            },
            {
              id: 495,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are the Sewage pipes connected to a sewage Pit ?\t',
              input: 'ccc',
              remark: 'ccc',
            },
            {
              id: 496,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are the Sewage pipes connected to the approved system of the area?\t',
              input: 'ddd',
              remark: 'ddd',
            },
            {
              id: 497,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Ground contamination due to release of / keeping of chemical is taken care of?\t',
              input: 'eee',
              remark: 'eee',
            },
            {
              id: 498,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Is the PUC getting done of the air pollutant releasing equipments as per Govt norms?\t',
              input: 'fff',
              remark: 'fff',
            },
            {
              id: 499,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Handling of all of type of waste inluding Hazardous waste within WH premises is as per defined norms?\t',
              input: 'ggg',
              remark: 'ggg',
            },
            {
              id: 500,
              heading: 'Environment Protection',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Disposal of all type of waste inluding Hazardous waste within WH premises is as per defined norms?\t',
              input: 'hhh',
              remark: 'hhh',
            },
            {
              id: 501,
              heading: 'Policy',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Policy for child labour is available?\t',
              input: 'iii',
              remark: 'iii',
            },
            {
              id: 502,
              heading: 'Policy',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Policy for ethical ways of conducting business is available?\t',
              input: 'jjj',
              remark: 'jjj',
            },
            {
              id: 503,
              heading: 'Policy',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Policy for environmental protection is available?\t',
              input: 'kkk',
              remark: 'kkk',
            },
            {
              id: 504,
              heading: 'Policy',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Policy for worker/labour insurance, compensation and benefits is available?\t',
              input: 'lll',
              remark: 'lll',
            },
            {
              id: 505,
              heading: 'Safety & Security\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is the Warehouse inside a gated compound?\t',
              input: 'mmm',
              remark: 'mmm',
            },
            {
              id: 506,
              heading: 'Safety & Security\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Aprox Height of the Compound Boundary Wall height-Ft\t',
              input: 'nnn',
              remark: 'nnn',
            },
            {
              id: 507,
              heading: 'Safety & Security\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'How far is HT (high tension) electric line from the WH premise-KMs\t',
              input: 'ooo',
              remark: 'ooo',
            },
            {
              id: 508,
              heading: 'Safety & Security\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are their Glass pieces on boundary wall, as a safety maeasure.\t',
              input: 'ppp',
              remark: 'ppp',
            },
            {
              id: 509,
              heading: 'Safety & Security\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are there Barbed Wire over boundary wall\t',
              input: 'qqq',
              remark: 'qqq',
            },
            {
              id: 510,
              heading: 'Safety & Security\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'No. of gates into the premises compound\t',
              input: 'rrr',
              remark: 'rrr',
            },
            {
              id: 511,
              heading: 'Safety & Security\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there a proper Security Room at the main gate ?\t',
              input: 'sss',
              remark: 'sss',
            },
            {
              id: 512,
              heading: 'Warehouse Operations',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are the windows on side walls are properly pilferage proof?\t',
              input: 'ttt',
              remark: 'ttt',
            },
            {
              id: 513,
              heading: 'Warehouse Operations',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is WH operational for 24 hrs?\t',
              input: 'uuu',
              remark: 'uuu',
            },
            {
              id: 514,
              heading: 'Warehouse Operations',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Does the WH operator provides Handling Equipments?\t',
              input: 'vvv',
              remark: 'vvv',
            },
            {
              id: 515,
              heading: 'Warehouse Operations',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Does the WH operator provides Labour?\t',
              input: 'www',
              remark: 'www',
            },
            {
              id: 516,
              heading: 'Warehouse Operations',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are the workers/labours working in WH are permanent?\t',
              input: 'xxx',
              remark: 'xxx',
            },
            {
              id: 517,
              heading: 'Safety & Security\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is the complete premises under CCTV survellience?\t',
              input: 'yyy',
              remark: 'yyy',
            },
            {
              id: 518,
              heading: 'Warehouse Operations',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there a Weigh Bridge inside the WH premises?\t',
              input: 'zzz',
              remark: 'zzz',
            },
            {
              id: 519,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there a Pantry area available within the warehouse premises ?\t',
              input: 'aaa',
              remark: 'aaa',
            },
            {
              id: 520,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are there washroosm for staff / workers?\t',
              input: 'bbb',
              remark: 'bbb',
            },
            {
              id: 521,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there a creche available inside WH premises?\t',
              input: 'ccc',
              remark: 'cccdd',
            },
            {
              id: 522,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are there enough lights in the open area in the night?\t',
              input: 'ddddd',
              remark: 'dddd',
            },
            {
              id: 523,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Source of Water in the warehouse (for regular use)?\t',
              input: 'eeee',
              remark: 'eeee',
            },
            {
              id: 524,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is drinking water available and accessible to all 24hrs?\t',
              input: 'ffff',
              remark: 'ffff',
            },
            {
              id: 525,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are the basic safety gears available for the labours/workers?\t',
              input: 'gggg',
              remark: 'gggghh',
            },
            {
              id: 526,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter:
                'Are the safety signs visible and adequately availble inside the WH as well as WH premises?\t',
              input: 'hhhh',
              remark: 'hhhh',
            },
            {
              id: 527,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Are the first aid boxes adequately available inside the WH?\t',
              input: 'iiii',
              remark: 'iiii',
            },
            {
              id: 528,
              heading: 'Worker Facility, Health & Safety\t',
              whsAdminFormEightystatus: 'okay',
              whsAdminFormEightyremark: 'fdfd',
              whsFieldAgentFormEightystatus: 'okay',
              whsFieldAgentFormEightyremark: 'fdfd',
              parameter: 'Is there an Ambulance parked 24/7 inside the WH premises?\t',
              input: 'jjjj',
              remark: 'jjjj',
            },
          ],
          whsWarehouse: [
            {
              id: 9,
              created_at: '2021-07-05T07:18:43.927Z',
              updated_at: '2021-07-05T07:18:43.927Z',
              whsFormEighty: {
                id: 3,
                formEighties: [
                  {
                    id: 177,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 912,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:43.124Z',
                      updated_at: '2021-07-05T13:07:43.124Z',
                    },
                  },
                  {
                    id: 178,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 914,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:43.238Z',
                      updated_at: '2021-07-05T13:07:43.238Z',
                    },
                  },
                  {
                    id: 179,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 916,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:43.391Z',
                      updated_at: '2021-07-05T13:07:43.391Z',
                    },
                  },
                  {
                    id: 180,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 918,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:43.574Z',
                      updated_at: '2021-07-05T13:07:43.574Z',
                    },
                  },
                  {
                    id: 181,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 920,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:43.718Z',
                      updated_at: '2021-07-05T13:07:43.718Z',
                    },
                  },
                  {
                    id: 182,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 922,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:43.793Z',
                      updated_at: '2021-07-05T13:07:43.793Z',
                    },
                  },
                  {
                    id: 183,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 924,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:43.900Z',
                      updated_at: '2021-07-05T13:07:43.900Z',
                    },
                  },
                  {
                    id: 184,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 926,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:43.968Z',
                      updated_at: '2021-07-05T13:07:43.968Z',
                    },
                  },
                  {
                    id: 185,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 928,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.049Z',
                      updated_at: '2021-07-05T13:07:44.049Z',
                    },
                  },
                  {
                    id: 186,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 930,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.105Z',
                      updated_at: '2021-07-05T13:07:44.105Z',
                    },
                  },
                  {
                    id: 187,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 932,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.167Z',
                      updated_at: '2021-07-05T13:07:44.167Z',
                    },
                  },
                  {
                    id: 188,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 934,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.224Z',
                      updated_at: '2021-07-05T13:07:44.224Z',
                    },
                  },
                  {
                    id: 189,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 936,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.286Z',
                      updated_at: '2021-07-05T13:07:44.286Z',
                    },
                  },
                  {
                    id: 190,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 938,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.348Z',
                      updated_at: '2021-07-05T13:07:44.348Z',
                    },
                  },
                  {
                    id: 191,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 940,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.432Z',
                      updated_at: '2021-07-05T13:07:44.432Z',
                    },
                  },
                  {
                    id: 192,
                    heading: 'Accessibility',
                    formEighty: {
                      id: 942,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.513Z',
                      updated_at: '2021-07-05T13:07:44.513Z',
                    },
                  },
                  {
                    id: 193,
                    heading: 'Accommodation',
                    formEighty: {
                      id: 944,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.608Z',
                      updated_at: '2021-07-05T13:07:44.608Z',
                    },
                  },
                  {
                    id: 194,
                    heading: 'Accommodation',
                    formEighty: {
                      id: 946,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.770Z',
                      updated_at: '2021-07-05T13:07:44.770Z',
                    },
                  },
                  {
                    id: 195,
                    heading: 'Affiliation',
                    formEighty: {
                      id: 948,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.861Z',
                      updated_at: '2021-07-05T13:07:44.861Z',
                    },
                  },
                  {
                    id: 196,
                    heading: 'Affiliation',
                    formEighty: {
                      id: 950,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:44.972Z',
                      updated_at: '2021-07-05T13:07:44.972Z',
                    },
                  },
                  {
                    id: 197,
                    heading: 'Construction',
                    formEighty: {
                      id: 952,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.043Z',
                      updated_at: '2021-07-05T13:07:45.043Z',
                    },
                  },
                  {
                    id: 198,
                    heading: 'Construction',
                    formEighty: {
                      id: 954,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.123Z',
                      updated_at: '2021-07-05T13:07:45.123Z',
                    },
                  },
                  {
                    id: 199,
                    heading: 'Construction',
                    formEighty: {
                      id: 956,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.196Z',
                      updated_at: '2021-07-05T13:07:45.196Z',
                    },
                  },
                  {
                    id: 200,
                    heading: 'Construction',
                    formEighty: {
                      id: 958,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.343Z',
                      updated_at: '2021-07-05T13:07:45.343Z',
                    },
                  },
                  {
                    id: 201,
                    heading: 'Construction',
                    formEighty: {
                      id: 960,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.398Z',
                      updated_at: '2021-07-05T13:07:45.398Z',
                    },
                  },
                  {
                    id: 202,
                    heading: 'Construction',
                    formEighty: {
                      id: 962,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.452Z',
                      updated_at: '2021-07-05T13:07:45.452Z',
                    },
                  },
                  {
                    id: 203,
                    heading: 'Construction',
                    formEighty: {
                      id: 964,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.512Z',
                      updated_at: '2021-07-05T13:07:45.512Z',
                    },
                  },
                  {
                    id: 204,
                    heading: 'Construction',
                    formEighty: {
                      id: 966,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.571Z',
                      updated_at: '2021-07-05T13:07:45.571Z',
                    },
                  },
                  {
                    id: 205,
                    heading: 'Accommodation',
                    formEighty: {
                      id: 968,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.655Z',
                      updated_at: '2021-07-05T13:07:45.655Z',
                    },
                  },
                  {
                    id: 206,
                    heading: 'Construction',
                    formEighty: {
                      id: 970,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.721Z',
                      updated_at: '2021-07-05T13:07:45.721Z',
                    },
                  },
                  {
                    id: 207,
                    heading: 'Construction',
                    formEighty: {
                      id: 972,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.772Z',
                      updated_at: '2021-07-05T13:07:45.772Z',
                    },
                  },
                  {
                    id: 208,
                    heading: 'Accommodation',
                    formEighty: {
                      id: 974,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.829Z',
                      updated_at: '2021-07-05T13:07:45.829Z',
                    },
                  },
                  {
                    id: 209,
                    heading: 'Accommodation',
                    formEighty: {
                      id: 976,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.884Z',
                      updated_at: '2021-07-05T13:07:45.884Z',
                    },
                  },
                  {
                    id: 210,
                    heading: 'Accommodation',
                    formEighty: {
                      id: 978,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:45.939Z',
                      updated_at: '2021-07-05T13:07:45.939Z',
                    },
                  },
                  {
                    id: 211,
                    heading: 'Accommodation',
                    formEighty: {
                      id: 980,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.030Z',
                      updated_at: '2021-07-05T13:07:46.030Z',
                    },
                  },
                  {
                    id: 212,
                    heading: 'Electricity & Electrical Fittings',
                    formEighty: {
                      id: 982,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.131Z',
                      updated_at: '2021-07-05T13:07:46.131Z',
                    },
                  },
                  {
                    id: 213,
                    heading: 'Electricity & Electrical Fittings',
                    formEighty: {
                      id: 984,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.223Z',
                      updated_at: '2021-07-05T13:07:46.223Z',
                    },
                  },
                  {
                    id: 214,
                    heading: 'Electricity & Electrical Fittings',
                    formEighty: {
                      id: 986,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.319Z',
                      updated_at: '2021-07-05T13:07:46.319Z',
                    },
                  },
                  {
                    id: 215,
                    heading: 'Electricity & Electrical Fittings',
                    formEighty: {
                      id: 988,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.385Z',
                      updated_at: '2021-07-05T13:07:46.385Z',
                    },
                  },
                  {
                    id: 216,
                    heading: 'Electricity & Electrical Fittings',
                    formEighty: {
                      id: 990,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.475Z',
                      updated_at: '2021-07-05T13:07:46.475Z',
                    },
                  },
                  {
                    id: 217,
                    heading: 'Electricity & Electrical Fittings',
                    formEighty: {
                      id: 992,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.554Z',
                      updated_at: '2021-07-05T13:07:46.554Z',
                    },
                  },
                  {
                    id: 218,
                    heading: 'Electricity & Electrical Fittings',
                    formEighty: {
                      id: 994,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.642Z',
                      updated_at: '2021-07-05T13:07:46.642Z',
                    },
                  },
                  {
                    id: 219,
                    heading: 'Electricity & Electrical Fittings',
                    formEighty: {
                      id: 996,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.716Z',
                      updated_at: '2021-07-05T13:07:46.716Z',
                    },
                  },
                  {
                    id: 220,
                    heading: 'Emergency Exit & Fire Prevention',
                    formEighty: {
                      id: 998,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.795Z',
                      updated_at: '2021-07-05T13:07:46.795Z',
                    },
                  },
                  {
                    id: 221,
                    heading: 'Emergency Exit & Fire Prevention',
                    formEighty: {
                      id: 1000,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.893Z',
                      updated_at: '2021-07-05T13:07:46.893Z',
                    },
                  },
                  {
                    id: 222,
                    heading: 'Emergency Exit & Fire Prevention',
                    formEighty: {
                      id: 1002,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:46.949Z',
                      updated_at: '2021-07-05T13:07:46.949Z',
                    },
                  },
                  {
                    id: 223,
                    heading: 'Emergency Exit & Fire Prevention',
                    formEighty: {
                      id: 1004,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.014Z',
                      updated_at: '2021-07-05T13:07:47.014Z',
                    },
                  },
                  {
                    id: 224,
                    heading: 'Emergency Exit & Fire Prevention',
                    formEighty: {
                      id: 1006,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.088Z',
                      updated_at: '2021-07-05T13:07:47.088Z',
                    },
                  },
                  {
                    id: 225,
                    heading: 'Emergency Exit & Fire Prevention',
                    formEighty: {
                      id: 1008,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.144Z',
                      updated_at: '2021-07-05T13:07:47.144Z',
                    },
                  },
                  {
                    id: 226,
                    heading: 'Emergency Exit & Fire Prevention',
                    formEighty: {
                      id: 1010,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.200Z',
                      updated_at: '2021-07-05T13:07:47.200Z',
                    },
                  },
                  {
                    id: 227,
                    heading: 'Emergency Exit & Fire Prevention',
                    formEighty: {
                      id: 1012,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.267Z',
                      updated_at: '2021-07-05T13:07:47.267Z',
                    },
                  },
                  {
                    id: 228,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1014,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.334Z',
                      updated_at: '2021-07-05T13:07:47.334Z',
                    },
                  },
                  {
                    id: 229,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1016,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.445Z',
                      updated_at: '2021-07-05T13:07:47.445Z',
                    },
                  },
                  {
                    id: 230,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1018,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.524Z',
                      updated_at: '2021-07-05T13:07:47.524Z',
                    },
                  },
                  {
                    id: 231,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1020,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.613Z',
                      updated_at: '2021-07-05T13:07:47.613Z',
                    },
                  },
                  {
                    id: 232,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1022,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.698Z',
                      updated_at: '2021-07-05T13:07:47.698Z',
                    },
                  },
                  {
                    id: 233,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1024,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.747Z',
                      updated_at: '2021-07-05T13:07:47.747Z',
                    },
                  },
                  {
                    id: 234,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1026,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.858Z',
                      updated_at: '2021-07-05T13:07:47.858Z',
                    },
                  },
                  {
                    id: 235,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1028,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:47.958Z',
                      updated_at: '2021-07-05T13:07:47.958Z',
                    },
                  },
                  {
                    id: 236,
                    heading: 'Environment Protection',
                    formEighty: {
                      id: 1030,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.022Z',
                      updated_at: '2021-07-05T13:07:48.022Z',
                    },
                  },
                  {
                    id: 237,
                    heading: 'Policy',
                    formEighty: {
                      id: 1032,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.090Z',
                      updated_at: '2021-07-05T13:07:48.090Z',
                    },
                  },
                  {
                    id: 238,
                    heading: 'Policy',
                    formEighty: {
                      id: 1034,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.140Z',
                      updated_at: '2021-07-05T13:07:48.140Z',
                    },
                  },
                  {
                    id: 239,
                    heading: 'Policy',
                    formEighty: {
                      id: 1036,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.195Z',
                      updated_at: '2021-07-05T13:07:48.195Z',
                    },
                  },
                  {
                    id: 240,
                    heading: 'Policy',
                    formEighty: {
                      id: 1038,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.252Z',
                      updated_at: '2021-07-05T13:07:48.252Z',
                    },
                  },
                  {
                    id: 241,
                    heading: 'Safety & Security\t',
                    formEighty: {
                      id: 1040,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.306Z',
                      updated_at: '2021-07-05T13:07:48.306Z',
                    },
                  },
                  {
                    id: 242,
                    heading: 'Safety & Security\t',
                    formEighty: {
                      id: 1042,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.362Z',
                      updated_at: '2021-07-05T13:07:48.362Z',
                    },
                  },
                  {
                    id: 243,
                    heading: 'Safety & Security\t',
                    formEighty: {
                      id: 1044,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.406Z',
                      updated_at: '2021-07-05T13:07:48.406Z',
                    },
                  },
                  {
                    id: 244,
                    heading: 'Safety & Security\t',
                    formEighty: {
                      id: 1046,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.461Z',
                      updated_at: '2021-07-05T13:07:48.461Z',
                    },
                  },
                  {
                    id: 245,
                    heading: 'Safety & Security\t',
                    formEighty: {
                      id: 1048,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.511Z',
                      updated_at: '2021-07-05T13:07:48.511Z',
                    },
                  },
                  {
                    id: 246,
                    heading: 'Safety & Security\t',
                    formEighty: {
                      id: 1050,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.616Z',
                      updated_at: '2021-07-05T13:07:48.616Z',
                    },
                  },
                  {
                    id: 247,
                    heading: 'Safety & Security\t',
                    formEighty: {
                      id: 1052,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.704Z',
                      updated_at: '2021-07-05T13:07:48.704Z',
                    },
                  },
                  {
                    id: 248,
                    heading: 'Warehouse Operations',
                    formEighty: {
                      id: 1054,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.787Z',
                      updated_at: '2021-07-05T13:07:48.787Z',
                    },
                  },
                  {
                    id: 249,
                    heading: 'Warehouse Operations',
                    formEighty: {
                      id: 1056,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.873Z',
                      updated_at: '2021-07-05T13:07:48.873Z',
                    },
                  },
                  {
                    id: 250,
                    heading: 'Warehouse Operations',
                    formEighty: {
                      id: 1058,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:48.962Z',
                      updated_at: '2021-07-05T13:07:48.962Z',
                    },
                  },
                  {
                    id: 251,
                    heading: 'Warehouse Operations',
                    formEighty: {
                      id: 1060,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.059Z',
                      updated_at: '2021-07-05T13:07:49.059Z',
                    },
                  },
                  {
                    id: 252,
                    heading: 'Warehouse Operations',
                    formEighty: {
                      id: 1062,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.131Z',
                      updated_at: '2021-07-05T13:07:49.131Z',
                    },
                  },
                  {
                    id: 253,
                    heading: 'Safety & Security\t',
                    formEighty: {
                      id: 1064,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.191Z',
                      updated_at: '2021-07-05T13:07:49.191Z',
                    },
                  },
                  {
                    id: 254,
                    heading: 'Warehouse Operations',
                    formEighty: {
                      id: 1066,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.243Z',
                      updated_at: '2021-07-05T13:07:49.243Z',
                    },
                  },
                  {
                    id: 255,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1068,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.344Z',
                      updated_at: '2021-07-05T13:07:49.344Z',
                    },
                  },
                  {
                    id: 256,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1070,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.426Z',
                      updated_at: '2021-07-05T13:07:49.426Z',
                    },
                  },
                  {
                    id: 257,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1072,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.516Z',
                      updated_at: '2021-07-05T13:07:49.516Z',
                    },
                  },
                  {
                    id: 258,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1074,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.624Z',
                      updated_at: '2021-07-05T13:07:49.624Z',
                    },
                  },
                  {
                    id: 259,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1076,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.683Z',
                      updated_at: '2021-07-05T13:07:49.683Z',
                    },
                  },
                  {
                    id: 260,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1078,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.736Z',
                      updated_at: '2021-07-05T13:07:49.736Z',
                    },
                  },
                  {
                    id: 261,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1080,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.804Z',
                      updated_at: '2021-07-05T13:07:49.804Z',
                    },
                  },
                  {
                    id: 262,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1082,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.877Z',
                      updated_at: '2021-07-05T13:07:49.877Z',
                    },
                  },
                  {
                    id: 263,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1084,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:49.947Z',
                      updated_at: '2021-07-05T13:07:49.947Z',
                    },
                  },
                  {
                    id: 264,
                    heading: 'Worker Facility, Health & Safety\t',
                    formEighty: {
                      id: 1086,
                      status: true,
                      remark: '',
                      created_at: '2021-07-05T13:07:50.023Z',
                      updated_at: '2021-07-05T13:07:50.023Z',
                    },
                  },
                ],
              },
            },
          ],
        }),
      );
    }
  };
};

// ###########  Create New Warehouse ############

export const fetchFieldAgentByIdAndType = (id, type) => {
  return async (dispatch) => {
    dispatch(isType(type));
    dispatch(WarehouseByIdType(null));

    dispatch(isLoading(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .get(`/api/v1/warehouses/${id}/type/${type}/usertype/vendor`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(WarehouseByIdType(res.data));
            dispatch(isLoading(false));
            console.log('resss type====>', res.data);
          } else {
            console.log('Warehouse Details Fail');
            dispatch(isErrorMsg(res.message));
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          console.log('Fail--->', error.message);
          dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          dispatch(isLoading(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const createNewWarehouse = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .post(ADD_WAREHOUSE, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(newWarehouseInfo(res));
            dispatch(fetchWarehouseById(res.data.id));
            dispatch(fetchWarehouseByIdAndType(res.data.id, 'warehouseContactDetails'));
            dispatch(isPending(false));
            dispatch(accordian('contactForm'));
          } else {
            console.log('Add Warehouse Fail');
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  Update Warehouse ############

export const updateWarehouse = (id, data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(UPDATE_WAREHOUSE_URL + `/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(id));
            dispatch(fetchWarehouseByIdAndType(id, 'warehouseContactDetails'));
            dispatch(isPending(false));
          } else {
            console.log('Add Warehouse Fail');
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE WAREHOUSE CONTACT ############

//pendingDetails all forms one api

export const updateWarehousependingDetails = (data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    // dispatch(isError(''));
    // dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put(`/api/v1/warehousesremark/${parseInt(data.warehouseId)}/${data.slug}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(WarehouseByIdType(res));
            dispatch(isLoading(false));
            // dispatch(fetchWarehouseById(data.warehouseId));
            // dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'warehouseContactDetails'));
            // dispatch(accordian('spaceForm'));
          } else {
            // console.log('Add Warehouse Fail==>', res);
            // dispatch(isErrorMsg(res.message));
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          // dispatch(isError(error.message));
          dispatch(isLoading(false));
        })
        .then(() => {
          // dispatch(isPending(false));
          // console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updateWarehouseContact = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + parseInt(data.warehouseId), data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isPending(false));
            dispatch(fetchWarehouseById(data.warehouseId));
            dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'warehouseContactDetails'));
            dispatch(accordian('spaceForm'));
          } else {
            console.log('Add Warehouse Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateWarehouseContactFieldAgent = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/whsfieldagaintwarehousecontactdetail', data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isPending(false));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchFieldAgentByIdAndType(data.warehouse, 'warehouseContactDetails'));
            dispatch(accordian('spaceForm'));
          } else {
            console.log('Add Warehouse Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// ###########  UPDATE WAREHOUSE STORAGE ############

export const updateStorageSpace = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + parseInt(data.warehouseId), data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouseId));
            dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'itInfrAndOfficeSpace'));
            dispatch(isPending(false));
            dispatch(accordian('itOfficeForm'));
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateStorageSpacefieldAgent = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/whsfieldagaintstoragespace', data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchFieldAgentByIdAndType(data.warehouse, 'itInfrAndOfficeSpace'));
            dispatch(isPending(false));
            dispatch(accordian('itOfficeForm'));
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE IT INFRA ############

export const updateItInfra = (itSpace) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    // it office infra Api class
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + itSpace.warehouseId, itSpace)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isPending(false));
            dispatch(accordian('mhForm'));
            dispatch(fetchWarehouseById(itSpace.warehouseId));
            dispatch(fetchWarehouseByIdAndType(itSpace.warehouseId, 'mhInfra'));
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateItInfraFieldAgent = (itSpace) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    // it office infra Api class
    try {
      axiosauth
        .put('/api/v1/whsfieldagaintitinfraofficespace', itSpace)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isPending(false));
            dispatch(accordian('mhForm'));
            dispatch(fetchWarehouseById(itSpace.warehouse));
            dispatch(fetchFieldAgentByIdAndType(itSpace.warehouse, 'mhInfra'));
          } else {
            console.log('Add Warehouse Storage Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// ###########  UPDATE WAREHOUSE MH INFRA ############
export const updateMhInfraFieldAgent = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/whsfieldagaintmhinfra', data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isPending(false));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchFieldAgentByIdAndType(data.warehouse, 'safetyAndSecurity'));
            dispatch(accordian('safetyForm'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updateMhInfra = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + data.warehouseId, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(isPending(false));
            dispatch(fetchWarehouseById(data.warehouseId));
            dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'safetyAndSecurity'));
            dispatch(accordian('safetyForm'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE SAFETY SECURITY FORM ############

export const updateSafetySecurity = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + parseInt(data.warehouseId), data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouseId));
            dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'buildingTradeRelatedPermit'));
            dispatch(isPending(false));
            dispatch(accordian('permitForm'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateSafetySecurityFieldAgent = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/whsfieldagaintsafetyandsecurity', data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchFieldAgentByIdAndType(data.warehouse, 'buildingTradeRelatedPermit'));
            dispatch(isPending(false));
            dispatch(accordian('permitForm'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// ###########  UPDATE PERMIN FORM ############

export const updatePermit = (permitData) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + permitData.warehouseId, permitData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(permitData.warehouseId));
            dispatch(fetchWarehouseByIdAndType(permitData.warehouseId, 'materialType'));
            dispatch(isPending(false));
            dispatch(accordian('materialForm'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) {
      console.log('--Try catch---', e);
    }
  };
};
export const updatePermitFieldAgent = (permitData) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/whsfieldagaintbuildingtradepermit', permitData)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(permitData.warehouse));
            dispatch(fetchFieldAgentByIdAndType(permitData.warehouse, 'materialType'));
            dispatch(isPending(false));
            dispatch(accordian('materialForm'));
          } else {
            console.log('Add Warehouse MHINfra Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) {
      console.log('--Try catch---', e);
    }
  };
};
// ###########  UPDATE MATERIAL TYPE FORM ############

export const updateMaterialType = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + parseInt(data.warehouseId), data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('ressss---->', res);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouseId));
            dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'formEighty'));
            dispatch(isPending(false));
            // dispatch(accordian("photo"))
          } else {
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
            dispatch(accordian('photo'));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updateMaterialTypeFieldAgent = (data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/whsfieldagaintmaterialtypes', data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('ressss---->', res);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(fetchFieldAgentByIdAndType(data.warehouse, 'formEighty'));
            dispatch(isPending(false));
            // dispatch(accordian("photo"))
          } else {
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
            dispatch(accordian('photo'));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE IMAGES ############

export const updatePhoto = (data, id) => {
  return async (dispatch) => {
    console.log('photo===>', data);
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + parseInt(data.warehouseId), data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('res===>', res);
          if (res.statusCode === 200) {
            dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'warehouseImages'));
            dispatch(fetchWarehouseById(data.warehouseId));
            dispatch(isPending(false));
            // dispatch(accordian(""));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add photo Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updatePhotoFieldAgent = (data, id) => {
  return async (dispatch) => {
    console.log('photo===>', data);
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/whsfieldagaintwarehouseimage', data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('res===>', res);
          if (res.statusCode === 200) {
            dispatch(fetchFieldAgentByIdAndType(id, 'warehouseImages'));
            dispatch(fetchWarehouseById(id));
            dispatch(isPending(false));
            // dispatch(accordian(""));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add photo Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE FORM EIGHTY ############

export const updateForm80 = (data) => {
  return async (dispatch) => {
    console.log('form80===>', data);
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + parseInt(data.warehouseId), data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(fetchWarehouseById(data.warehouseId));
            dispatch(isPending(false));
            dispatch(accordian(''));
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'formEighty'));
          } else {
            console.log('Add Warehouse 80 Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateForm80FieldAgent = (data) => {
  return async (dispatch) => {
    console.log('form80===>', data);
    dispatch(isPending(true));
    dispatch(isError(''));
    dispatch(isErrorMsg(''));
    try {
      axiosauth
        .put('/api/v1/whsfieldagintformeighty', data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(fetchWarehouseById(data.warehouse));
            dispatch(isPending(false));
            dispatch(accordian(''));
            dispatch(addNewWarehouse(res));
            dispatch(fetchFieldAgentByIdAndType(data.warehouse, 'formEighty'));
          } else {
            console.log('Add Warehouse 80 Fail==>', res);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

// ###########  UPDATE WAREHOUSE STATUS ############

export const updateWarehouseStatus = (id, data) => {
  return async (dispatch) => {
    console.log('Update Status==>', id, data);
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(UPDATE_WAREHOUSE_URL + `/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(id));
            dispatch(isPending(false));
            dispatch(finalSubmitForm(true));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateWarehouseStatusResubmitted = (id, data) => {
  return async (dispatch) => {
    console.log('Update Status==>', id, data);
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(`/api/v1/warehouses/warehouseadminresubmitedtovendorNew/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            // dispatch(clearSingleDataState([]));
            console.log('||warehouseadminresubmitedtovendorNew||');
            // dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseById(id));
            // dispatch(isPending(false));
            dispatch(finalSubmitFormVendor(true));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateWarehouseStatusAdminApproved = (id, data) => {
  return async (dispatch) => {
    console.log('Update Status==>', id, data);
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(`/api/v1/warehouses/warehouseapproved/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            // dispatch(fetchWarehouseById(id));
            // dispatch(isPending(false));
            dispatch(finalSubmitForm(true));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updateWarehouseStatusFieldAgent = (id, data) => {
  return async (dispatch) => {
    console.log('Update Status==>', id, data);
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(`/api/v1/warehouses/warehousefieldagaintreject/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(id));
            dispatch(isPending(false));
            dispatch(finalSubmitForm(true));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

export const updateWarehouseStatusFieldAgentApprove = (id, data) => {
  return async (dispatch) => {
    console.log('Update Status==>', id, data);
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(`/api/v1/warehouses/warehousefieldagaintresubmittedtoadmin/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(id));
            dispatch(isPending(false));
            dispatch(finalSubmitForm(true));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const assigntofieldagent = (id, fa) => {
  return async (dispatch) => {
    console.log('Update Status==>', id);
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(`/api/v1/warehouses/warehousesubmitofieldagaint/${id}`, {
          fieldAgaint: fa,
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseById(id));
            dispatch(isPending(false));
            dispatch(finalSubmitForm(true));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// CREATE APPROVAL FORM
// 1. IF (WHS WAREHOUSE === [])  // CREATE APPROVAL FORM
//  ELSE-UPDATE APPROVAL FORM , ID PARAMS ,
export const createWarehouseApprovalForm = (data, id) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put('/api/v1/warehousesremark/' + parseInt(data.warehouseId), data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isPending(false));
            dispatch(addNewWarehouse(res));
            dispatch(fetchWarehouseByIdAndType(data.warehouseId, 'warehouse'));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};

//
export const createFieldAgentApprovalForm = (data, id) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .post('/api/v1/whsfieldagaintwarehouses', data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isPending(false));
            dispatch(addNewWarehouse(res));
            dispatch(fetchFieldAgentByIdAndType(id, 'warehouse'));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
export const updateWarehosueApprovalForm = (id, data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(UPDATE_WAREHOUSE_PENDING_APPROVAL_FORM + `/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isPending(false));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
// addNewWarehouse --- to update response message
export const updateFieldAgentApprovalForm = (id, data) => {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(''));
    try {
      axiosauth
        .put(`/api/v1/whsfieldagaintwarehouses/${id}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(isPending(false));
            dispatch(addNewWarehouse(res));
          } else {
            console.log('Add Warehouse Fail');
          }
        })
        .catch((error) => {
          dispatch(isError(error.message));
          dispatch(isPending(false));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log('-----always executes');
        });
    } catch (e) { }
  };
};
