import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import './pendingForms.css';
import {
  changeWarehouseStatus1,
  updateWarehousependingDetails,
} from '../../../store/action/warehouseAction';
import FormSuccess from '../../../components/common/FormSuccess';
import CustomLoader from '../../../CustomLoader';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

function SpacePendingForm({ warehouseId, read, slug }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);

  const floorTypesOptions = [
    // { value: '', label: 'Select Floor' },
    { value: 'Basement', label: 'Basement' },
    { value: 'Ground Floor', label: 'Ground Floor' },
    { value: '1st Floor', label: '1st Floor' },
    { value: '2nd Floor', label: '2nd Floor' },
  ];

  const [storeForm, setStoreForm] = useState({
    storageType: 'floorarea',
    noOfShift: '',
    palletsOnFloor: '',
    totalAvailableSpace: 0,
    noOfShiftremarks: '',
    palletsOnFloorremarks: '',
    totalAvailableSpaceremarks: '',
    floors: [
      {
        floorType: floorTypesOptions[0].value,
        // floorType: '',
        floorDimension: {
          dimension: 'SQFT',
          length: '',
          breath: '',
          height: '',
          floorsRemarks: '',
        },
        availableSpace: {
          length: '',
          breath: '',
          height: '',
        },
        unit: 'FEET',
      },
    ],
    racks: [
      {
        name: 'binsracks',
        rackInfo: {
          quantity: '',
          length: '',
          breath: '',
          height: '',
          racksSlottedYesremarks: '',
        },
      },
      {
        name: 'shelveracks',
        rackInfo: {
          quantity: '',
          length: '',
          breath: '',
          height: '',
          racksShelveYesremarks: '',
        },
      },
    ],
    racksSlottedYes: true,
    racksShelveYes: true,
  });

  const totalLength = storeForm.floors.reduce(function (acc, current) {
    return acc + current?.floorDimension.length;
  }, 0);

  const totalBreath = storeForm.floors.reduce(function (acc, current) {
    return acc + current?.floorDimension.breath;
  }, 0);

  const handleChangheFloorKeys = (event, name) => {
    setStoreForm({ ...storeForm, [name]: event });
  };

  const handleChangeFloor = (value, name, index) => {
    const updatedFloors = [...storeForm.floors];
    updatedFloors[index].floorDimension[name] = value;
    setStoreForm({ ...storeForm, floors: updatedFloors });
  };

  const handleChangeRacks = (key, value, index) => {
    const updatedFloors = [...storeForm.racks];
    updatedFloors[index].rackInfo[key] = value;
    setStoreForm({ ...storeForm, racks: updatedFloors });
  };

  const handleDeleteFloor = (id) => {
    setStoreForm((prevState) => ({
      ...prevState,
      floors: prevState.floors.filter((_, i) => i !== id),
    }));
  };

  const handleChangeSpace = () => {
    // console.log('0');
  };

  const handleYes = (e) => {
    setStoreForm({ ...storeForm, [e.target.name]: e.target.value === 'true' });
  };

  useEffect(() => {
    if (
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      data?.singleFormData?.remarks?.warehouseStorageSpacesRemarks !== undefined &&
      Object.keys(data?.singleFormData?.remarks?.warehouseStorageSpacesRemarks).length > 0
    ) {
      setStoreForm(data?.singleFormData?.remarks?.warehouseStorageSpacesRemarks);
    } else {
      if (
        data?.singleFormData?.warehouseRes !== null &&
        data?.singleFormData?.warehouseRes !== undefined &&
        data?.singleFormData?.warehouseRes?.warehouseStorageSpaces !== undefined
      ) {
        setStoreForm({ ...data?.singleFormData?.warehouseRes?.warehouseStorageSpaces[0] });
      }
    }
  }, [data?.singleFormData]);

  // const handleNextPage = () => {
  //   if (data?.addNewResponse && data?.addNewResponse?.statusCode === 200) {
  //     history.push(`/pending-details/${warehouseId}/it-infra`);
  //     dispatch(changeWarehouseStatus1());
  //   }
  // };

  return (
    <>
      {/* {data?.addNewResponse && data?.addNewResponse?.statusCode === 200 ? (
        <FormSuccess onClick={handleNextPage} message={data.addNewResponse.message} />
      ) : null}{' '} */}
      {data?.isLoading === true ? (
        <CustomLoader />
      ) : (
        <div className='row align-items-center pb-3 mx-0'>
          <div className='col-12'>
            <Formik
              enableReinitialize={true}
              initialValues={storeForm}
              onSubmit={(fields) => {
                let payloadData = {
                  data: {
                    fields: fields,
                  },
                  warehouseId: warehouseId,
                  slug: slug,
                };
                dispatch(updateWarehousependingDetails(payloadData));
              }}
              render={(values, touched, errors, setFieldValue) => {
                const fieldClass = (fieldName, isYes = true) => {
                  return (
                    'form-control form-control-sm bg-white px-4 w-50' +
                    (isYes
                      ? values.errors[fieldName] && values.touched[fieldName]
                        ? ' is-invalid'
                        : ''
                      : '')
                  );
                };

                const floorMultiRowValidation = (id, fieldname) => {
                  if (values?.errors !== undefined && values?.errors[`floors`] !== undefined) {
                    if (values?.errors[`floors`][id]?.floorDimension !== undefined) {
                      if (values?.errors[`floors`][id]?.floorDimension[fieldname] !== undefined) {
                        return 'form-control form-control-sm bg-white px-4 w-5 is-invalid';
                      } else {
                        return 'form-control form-control-sm bg-white px-4 w-5';
                      }
                    } else {
                      return 'form-control form-control-sm bg-white px-4 w-5';
                    }
                  } else {
                    return 'form-control form-control-sm bg-white px-4 w-5';
                  }
                };

                const floorTypeValidation = (id, selected) => {
                  if (values?.errors?.floors?.[id]?.floorType !== undefined) {
                    return ' w-50 mr-5 float-left is-invalid  border-radius';
                  } else {
                    return ' w-50 mr-5 float-left  border-radius';
                  }
                };

                const rackMultiRowValidation = (id, fieldname, status) => {
                  if (values?.errors !== undefined && values?.errors[`racks`] !== undefined) {
                    if (
                      values?.errors[`racks`][id]?.rackInfo[fieldname] !== undefined &&
                      status === true
                    ) {
                      return 'form-control bg-white px-4 is-invalid';
                    } else {
                      return 'form-control bg-white px-4';
                    }
                  } else {
                    return 'form-control bg-white px-4';
                  }
                };

                return (
                  <>
                    <Form>
                      <div className='row bg-white rounded mx-0 col-xxxl-11 justify-content-center'>
                        <div className='form-group form-inline w-90 mb-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-1 mr-3'>
                            Storage Type:
                          </label>
                          <div className='form-check common-radio-deep-blue mx-3'>
                            <Field
                              name='storageType'
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='floor-area'
                              value='floorarea'
                              hidden=''
                              disabled={read}
                            />
                            <label className=' pl-4' htmlFor='floor-area'>
                              Floor Area
                            </label>
                          </div>
                          <div className='form-check common-radio-deep-blue mx-3'>
                            <Field
                              name='storageType'
                              className='common-radio-deep-blue-input'
                              type='radio'
                              id='pallet-position'
                              value='palletposition'
                              hidden=''
                              disabled={read}
                            />
                            <label className=' pl-4' htmlFor='pallet-position'>
                              Pallet Position
                            </label>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between h3 w-90'>Floor Plan </div>
                        {storeForm?.floors.map((field, index) => {
                          return (
                            <div className='main w-90 border my-3' key={index}>
                              <div className='mt-1'>
                                <label htmlFor='' className='px-1 fontSize'>
                                  Floors*
                                </label>
                                {index !== 0 ? (
                                  <span
                                    onClick={() => handleDeleteFloor(index)}
                                    className='ml-4 cursors float-right my-2'
                                  >
                                    <DeleteIcon />
                                  </span>
                                ) : (
                                  <></>
                                )}

                                <div class='w-100 d-flex justify-content-between'>
                                  <Select
                                    value={floorTypesOptions[index]}
                                    className={floorTypeValidation(`${index}`)}
                                    options={floorTypesOptions}
                                    name={`floors[${index}].floorType`}
                                    onChange={(event) => {
                                      handleChangeFloor(event, 'floorType', index);
                                      values.setFieldValue(
                                        `floors[${index}].floorType`,
                                        event.value,
                                      );
                                    }}
                                    disabled={read}
                                  />

                                  <Field
                                    disabled={read}
                                    name={`floors[${index}].floorDimension.floorsRemarks`}
                                    onChange={(event) =>
                                      handleChangeFloor(event.target.value, 'floorsRemarks', index)
                                    }
                                    type='text'
                                    className='form-control bg-white float-center w-25'
                                    placeholder='Remarks'
                                  />
                                </div>
                              </div>
                              <div className='d-inline-flex'>
                                <span className='py-3 pr-4'>
                                  <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                    Length *
                                  </label>
                                  <Field
                                    name={`floors[${index}].floorDimension.length`}
                                    type='number'
                                    placeholder='Length'
                                    className={floorMultiRowValidation(`${index}`, 'length')}
                                    onChange={(event) =>
                                      handleChangeFloor(event.target.value, 'length', index)
                                    }
                                    disabled={read}
                                  />
                                </span>
                                <span className='py-3 px-4'>
                                  <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                    Breadth *
                                  </label>
                                  <Field
                                    type='number'
                                    name={`floors[${index}].floorDimension.breath`}
                                    placeholder='Breadth'
                                    className={floorMultiRowValidation(`${index}`, 'breath')}
                                    onChange={(event) =>
                                      handleChangeFloor(event.target.value, 'breath', index)
                                    }
                                    disabled={read}
                                  />
                                </span>
                                <span className='py-3 px-4'>
                                  <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                    Height *
                                  </label>
                                  <Field
                                    name={`floors[${index}].floorDimension.height`}
                                    type='number'
                                    placeholder='Height'
                                    className={floorMultiRowValidation(`${index}`, 'height')}
                                    onChange={(event) =>
                                      handleChangeFloor(event.target.value, 'height', index)
                                    }
                                    disabled={read}
                                  />
                                </span>
                              </div>
                              <div>
                                <label htmlFor='' className='px-1 fontSize'>
                                  Available Space
                                </label>
                              </div>
                              <div className='d-inline-flex'>
                                <span className='pr-4'>
                                  <Field
                                    name={`floors[${index}].availableSpace.length`}
                                    type='number'
                                    placeholder='Length'
                                    className={
                                      'form-control form-control-sm bg-white px-4 w-5 mb-3'
                                    }
                                    onChange={(event) => handleChangeSpace(event, index)}
                                    disabled={read}
                                  />
                                </span>
                                <span className='px-4'>
                                  <Field
                                    name={`floors[${index}].availableSpace.breath`}
                                    type='number'
                                    placeholder='Breadth'
                                    className={'form-control form-control-sm bg-white px-4 w-5'}
                                    onChange={(event) => handleChangeSpace(event, index)}
                                    disabled={read}
                                  />
                                </span>
                                <span className='px-4'>
                                  <Field
                                    name={`floors[${index}].availableSpace.height`}
                                    type='number'
                                    placeholder='Height'
                                    className={'form-control form-control-sm bg-white px-4 w-5'}
                                    onChange={(event) => handleChangeSpace(event, index)}
                                    disabled={read}
                                  />
                                </span>
                                <span className='px-4'>
                                  <Field
                                    disabled={read}
                                    name={`totalAvailableSpaceremarks`}
                                    type='text'
                                    className='form-control bg-white px-4 mb-2 mr-4'
                                    onChange={(event) =>
                                      handleChangheFloorKeys(
                                        event.target.value,
                                        `totalAvailableSpaceremarks`,
                                      )
                                    }
                                    placeholder='Remarks'
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        })}
                        <div className='mb-3 w-90'>
                          <label className='mb-2'>Total Area(sqft):</label>
                          <div className='d-flex'>
                            <Field
                              className='form-control bg-white px-4 mr-3 col-2'
                              name={`totalAvailableSpace`}
                              value={totalLength * totalBreath}
                              disabled
                            />
                            {/* <Field
                              className='form-control bg-white px-4 mx-3 col-2'
                              value={0}
                              disabled
                            />
                            <Field
                              className='form-control bg-white px-4 mx-3 col-2'
                              value={0}
                              disabled
                            />
                            <Field
                              className='form-control bg-white px-4 col-2'
                              value={0}
                              disabled
                            /> */}
                          </div>
                          <div></div>
                        </div>
                        <div className='my-2 w-90'>
                          <label className='mb-2'>Pallets on Floor</label>
                          <div className='d-flex justify-content-between'>
                            <Field
                              className='form-control bg-white px-4 w-50'
                              name={`palletsOnFloor`}
                              onChange={(event) =>
                                handleChangheFloorKeys(event.target.value, `palletsOnFloor`)
                              }
                              disabled={read}
                            />
                            <Field
                              disabled={read}
                              name={`palletsOnFloorremarks`}
                              type='text'
                              className='form-control bg-white float-center w-25'
                              placeholder='Remarks'
                              onChange={(event) =>
                                handleChangheFloorKeys(event.target.value, `palletsOnFloorremarks`)
                              }
                            />
                          </div>
                        </div>
                        <div className='my-3 border w-90'>
                          <div className='d-flex justify-content-between mt-2'>
                            <label className='mb-2'>Racks(Slotted Angle) No of Bins:</label>
                            <Field
                              disabled={read}
                              name={`racks[0].rackInfo.racksSlottedYesremarks`}
                              onChange={(event) =>
                                handleChangeRacks('racksSlottedYesremarks', event.target.value, 0)
                              }
                              type='text'
                              className='form-control bg-white float-center w-25'
                              placeholder='Remarks'
                            />
                          </div>
                          <div className='d-flex'>
                            <div className='form-check common-radio-deep-blue'>
                              <Field
                                name={`racksSlottedYes`}
                                onChange={(e) => handleYes(e)}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksSlotted'
                                value={true}
                                hidden=''
                                disabled={read}
                              />
                              <label className='pl-1' htmlFor='racksSlotted'>
                                Yes
                              </label>
                            </div>
                            <div className='form-check mx-3'>
                              <Field
                                name={`racksSlottedYes`}
                                onChange={(e) => handleYes(e)}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksSlotted1'
                                value={false}
                                hidden=''
                                disabled={read}
                              />
                              <label className='pl-1' htmlFor='racksSlotted1'>
                                No
                              </label>
                            </div>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Quantity *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.quantity`}
                                className={rackMultiRowValidation(`${0}`, 'quantity')}
                                onChange={(event) =>
                                  handleChangeRacks('quantity', event.target.value, 0)
                                }
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Length *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.length`}
                                className={rackMultiRowValidation(`${0}`, 'length')}
                                onChange={(event) =>
                                  handleChangeRacks('length', event.target.value, 0)
                                }
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Breath *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.breath`}
                                className={rackMultiRowValidation(`${0}`, 'breath')}
                                onChange={(event) =>
                                  handleChangeRacks('breath', event.target.value, 0)
                                }
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Height *
                              </label>
                              <Field
                                type='number'
                                name={`racks[0].rackInfo.height`}
                                className={rackMultiRowValidation(`${0}`, 'height')}
                                onChange={(event) =>
                                  handleChangeRacks('height', event.target.value, 0)
                                }
                              />
                            </span>
                          </div>
                        </div>
                        <div className='my-3 border w-90'>
                          <div className='d-flex justify-content-between mt-2'>
                            <label className='mb-2'>Racks(Shelve Racks) No of Shelve:</label>
                            <Field
                              disabled={read}
                              name={`racks[1].rackInfo.racksShelveYesremarks`}
                              onChange={(event) =>
                                handleChangeRacks('racksShelveYesremarks', event.target.value, 1)
                              }
                              type='text'
                              className='form-control bg-white float-center w-25'
                              placeholder='Remarks'
                            />
                          </div>
                          <div className='d-flex'>
                            <div className='form-check common-radio-deep-blue'>
                              <Field
                                name={`racksShelveYes`}
                                onChange={(e) => handleYes(e)}
                                value={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksShelveYes'
                                disabled={read}
                              />
                              <span className='pl-1' htmlFor='racksShelveYes'>
                                Yes
                              </span>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                name={`racksShelveYes`}
                                onChange={(e) => handleYes(e)}
                                value={false}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='racksShelveYes1'
                                disabled={read}
                              />
                              <label className='pl-1' htmlFor='racksShelveYes1'>
                                No
                              </label>
                            </div>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Quantity *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.quantity`}
                                className={rackMultiRowValidation(`${1}`, 'quantity')}
                                onChange={(event) =>
                                  handleChangeRacks('quantity', event.target.value, 1)
                                }
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Length *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.length`}
                                className={rackMultiRowValidation(`${1}`, 'length')}
                                onChange={(event) =>
                                  handleChangeRacks('length', event.target.value, 1)
                                }
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Breath *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.breath`}
                                className={rackMultiRowValidation(`${1}`, 'breath')}
                                onChange={(event) =>
                                  handleChangeRacks('breath', event.target.value, 1)
                                }
                              />
                            </span>
                            <span className='py-3 pr-4'>
                              <label className=' pl-2 mr-3' htmlFor='defaultCheck1'>
                                Height *
                              </label>
                              <Field
                                type='number'
                                name={`racks[1].rackInfo.height`}
                                className={rackMultiRowValidation(`${1}`, 'height')}
                                onChange={(event) =>
                                  handleChangeRacks('height', event.target.value, 1)
                                }
                              />
                            </span>
                          </div>
                        </div>
                        <div className='my-2 w-90'>
                          <label className='mb-2'>No of Shifts</label>
                          <div className='d-flex justify-content-between'>
                            <Field
                              name={`noOfShift`}
                              placeholder='Enter No Of Shifts'
                              onChange={(event) =>
                                handleChangheFloorKeys(event.target.value, `noOfShift`)
                              }
                              className={fieldClass('noOfShift')}
                              disabled={read}
                            />
                            <Field
                              disabled={read}
                              name={`noOfShiftremarks`}
                              onChange={(event) =>
                                handleChangheFloorKeys(event.target.value, `noOfShiftremarks`)
                              }
                              type='text'
                              className='form-control bg-white float-center w-25'
                              placeholder='Remarks'
                            />
                          </div>
                        </div>

                        {!read && (
                          <div
                            className={`${
                              data?.isError
                                ? 'row justify-content-between'
                                : 'row justify-content-end'
                            }`}
                          >
                            {/* {data?.isError ? (
                              <div
                                class='alert alert-warning alert-dismissible fade show'
                                role='alert'
                              >
                                {data.isError}.
                                <button
                                  type='button'
                                  class='btn-close'
                                  data-bs-dismiss='alert'
                                  aria-label='Close'
                                ></button>
                              </div>
                            ) : null} */}
                            <div className='col-auto'>
                              {Object.keys(values.touched).length > 0 ? (
                                <button
                                  type='submit'
                                  className='btn btn-deep-blue add-className remove-className my-2'
                                >
                                  Save
                                </button>
                              ) : (
                                ''
                              )}
                            </div>
                            {console.log('touched', values.touched)}
                          </div>
                        )}
                      </div>
                    </Form>
                  </>
                );
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default SpacePendingForm;
