import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
// import {documentUpload} from '../../components/utils';
import {
  serviceCategoryById,
  updateServiceCategory,
  responseService,
} from "../../store/action/serviceAction";
import { CardLoader } from "../../components/helper/CustomLoader";
import {
  onlyAlphaNumericSpaceAllow,
  forDescriptionValidation,
} from "../../components/helper/reuse";

const EditServiceCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.SERVICE_INFO);
  const [initCategory, setInitCategory] = useState({
    name: "",
    description: "",
    active: true,
  });

  const { serviceCategoryId } = useParams();

  const roleSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  // File Upload
  // const [res, setRes] = useState([])
  // const [fileError, setFileError]=useState(null)

  // const uploadDocs = async (e) =>{
  //  setFileError(null)
  //    let formData = new FormData()
  //    formData.append("file", e.target.files[0])
  //    let urlData = await documentUpload(formData)
  //   //  console.log(urlData)
  //   if(urlData && urlData.url){
  //     setRes([...res, urlData.url])
  //   }
  //  }

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service-category`);
  };

  useEffect(() => {
    setInitCategory(data.categoryDetail);
  }, [data.categoryDetail]);

  useEffect(() => {
    dispatch(serviceCategoryById(serviceCategoryId));
  }, [dispatch, serviceCategoryId]);

  return (
    <LayoutOne>
      {" "}
      {data.serviceResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Category Updated`} />
      ) : null}
      <div className="row align-items-center py-3 mx-0">
        <div className="col-12">
          <h5 className="text-dark-blue font-weight-bold">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left cursorPointer"
            ></i>{" "}
            Update Category{" "}
          </h5>{" "}
        </div>{" "}
      </div>
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row align-items-center pb-3 px-3 mx-0">
          <div className="col-12">
            <Formik
              enableReinitialize={true}
              validationSchema={roleSchema}
              initialValues={initCategory}
              onSubmit={(fields) => {
                dispatch(updateServiceCategory(serviceCategoryId, fields));
              }}
              render={({ values, errors, status, onChange, touched }) => {
                return (
                  <Form className="bg-white p-3 ">
                    <div className="row">
                      <div className="form-group col-12 mb-2">
                        <label htmlFor="staticEmail" className="mb-2 px-3">
                          Display Name{" "}
                        </label>{" "}
                        <Field
                          onKeyPress={(e) => onlyAlphaNumericSpaceAllow(e)}
                          name="name"
                          type="text"
                          className={
                            `form-control form-control-md rounded-pill` +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                          id="staticEmail"
                        />
                        <ErrorMessage
                          name={`name`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                      <div className="form-group col-12">
                        <label htmlFor="staticEmail" className="mb-2 px-3">
                          Description{" "}
                        </label>{" "}
                        <Field
                          onKeyPress={(e) => forDescriptionValidation(e)}
                          name="description"
                          type="text"
                          className={
                            `form-control form-control-md rounded-pill` +
                            (errors.description && touched.description
                              ? " is-invalid"
                              : "")
                          }
                          id="staticEmail"
                        />
                        <ErrorMessage
                          name={`description`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {/* <div className="col-12 mt-3">
                                                    <div className="row">
                                                      <div className="col-auto">
                                                        <div className="card card-overlay upload-image-preview position-relative">

                                                        {res && res.length >0 ?
                                                        <div className="img-holder size-200px">
                                                            <img src={res[0]} className="w-100 h-100 img-fluid rounded overflow-hideen" id="imageResult" alt=""/>
                                                          </div>

                                                          :
                                                          <>
                                                          <div className="img-holder size-200px">
                                                            <img className="w-100 h-100 img-fluid rounded overflow-hideen" id="imageResult" src="/assets/images/upload-bg-white.png" alt=""/>
                                                          </div>
                                                          <div className="card-img-overlay size-200px d-flex justify-content-center align-items-center">
                                                            <div className="card-text">
                                                              <input onChange={uploadDocs} id="upload-image-preview1" type="file" className="form-control border-0" hidden/>
                                                              <label id="upload-label" htmlFor="upload-image-preview1" className="font-weight-light text-muted"></label>
                                                              <div className="input-group-append">
                                                                <label htmlFor="upload-image-preview1" className="btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4"> 
                                                                  <div>
                                                                    <img src="/assets/images/icons/upload-icon-deep-blue.png" alt=""/>
                                                                  </div>
                                                                  Upload image
                                                                </label>
                                                              </div> 
                                                            </div>
                                                          </div>
                                                          </>
                                                        }

                                                        </div>
                                                        <span className="errorMsg">{fileError}</span>
                                                      </div>

                                                      {res && res.length >=1 ?
                                                      <div className="col-auto">
                                                        <div className="card card-overlay upload-image-preview position-relative">
                                                        {res && res.length >1 ?
                                                          <div className="img-holder size-200px">
                                                            <img  src={res[1]} className="w-100 h-100 img-fluid rounded overflow-hideen" id="imageResult" alt=""/>
                                                          </div>
                                                          :
                                                        <>
                                                          <div className="img-holder size-200px">
                                                            <img className="w-100 h-100 img-fluid rounded overflow-hideen" id="imageResult" src={"/assets/images/upload-bg-white.png"} alt=""/>
                                                          </div>
                                                          <div className="card-img-overlay size-200px d-flex justify-content-center align-items-center">
                                                            <div className="card-text">
                                                              <input onChange={uploadDocs} id="upload-image-preview2" type="file" className="form-control border-0" hidden />
                                                              <label id="upload-label" htmlFor="upload-image-preview2" className="font-weight-light text-muted"></label>
                                                              <div className="input-group-append">
                                                                <label htmlFor="upload-image-preview2" className="btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4"> 
                                                                  <div>
                                                                    <img src="/assets/images/icons/upload-icon-deep-blue.png" alt=""/>
                                                                  </div>
                                                                  Upload image
                                                                </label>
                                                              </div> 
                                                            </div>
                                                          </div>
                                                          </>
                                                        }

                                                        </div>
                                                      </div>
                                                      :null}
                                                 
                                                    </div>
                                                  </div>  */}{" "}
                    </div>{" "}
                    <div className="row">
                      <div className="col-auto">
                        <button
                          type="submit"
                          disabled={data.isPending}
                          className="btn btn-deep-blue toggle-class py-2 my-4"
                        >
                          Update{" "}
                          {data.isPending ? (
                            <Spinner animation="border" />
                          ) : null}{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>{" "}
                  </Form>
                );
              }}
            />{" "}
          </div>{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default EditServiceCategory;
