import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateMhInfra,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";

const MhPendingForm = ({ warehouseId, viewMood }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);

  const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return datestring;
  };
  const [formField, setFormField] = useState(null);
  const validationSchema = function (values) {
    return Yup.object().shape({
      pickingTrolley: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      hydraulicDockLevler: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      batteryOperatedPalletTruck: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      handPalletTruck: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      electricStacker: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      shrinkAndStretchWrapMachine: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      mhinfra_others: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      pallets: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      weighingMachine: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      forkLifts: Yup.array().of(
        Yup.object().shape({
          forkLift: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      hydraCrane: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        mhInfraRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateMhInfra(payload));
  };
  const [remarkForm, setRemarkForm] = useState({
    pickingTrolley: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    hydraulicDockLevler: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    batteryOperatedPalletTruck: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    handPalletTruck: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    electricStacker: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    shrinkAndStretchWrapMachine: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    mhinfra_others: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    pallets: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    weighingMachine: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    forkLifts: [
      {
        type: "batteryoperatedup",
        forkLift: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "dieseloperated",
        forkLift: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    hydraCrane: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });
  useEffect(() => {
    if (
      data.singleFormData &&
      data.singleFormData !== null &&
      data.type === "mhInfra"
    ) {
      setFormField(data.singleFormData.mhInfraInfo);
      if (data.singleFormData.mhInfraRemark) {
        setRemarkForm({
          pickingTrolley: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pickingTrolley.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pickingTrolley.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pickingTrolley.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pickingTrolley.faremark
              : "",
          },
          hydraulicDockLevler: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraulicDockLevler.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraulicDockLevler.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraulicDockLevler.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraulicDockLevler.faremark
              : "",
          },
          batteryOperatedPalletTruck: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.batteryOperatedPalletTruck
                  .whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.batteryOperatedPalletTruck
                  .whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.batteryOperatedPalletTruck
                  .fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.batteryOperatedPalletTruck
                  .faremark
              : "",
          },
          handPalletTruck: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.handPalletTruck.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.handPalletTruck.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.handPalletTruck.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.handPalletTruck.faremark
              : "",
          },
          electricStacker: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.electricStacker.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.electricStacker.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.electricStacker.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.electricStacker.faremark
              : "",
          },
          shrinkAndStretchWrapMachine: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.shrinkAndStretchWrapMachine
                  .whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.shrinkAndStretchWrapMachine
                  .whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.shrinkAndStretchWrapMachine
                  .fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.shrinkAndStretchWrapMachine
                  .faremark
              : "",
          },
          mhinfra_others: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.mhinfra_others.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.mhinfra_others.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.mhinfra_others.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.mhinfra_others.faremark
              : "",
          },
          pallets: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pallets.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pallets.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pallets.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pallets.faremark
              : "",
          },
          weighingMachine: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.weighingMachine.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.weighingMachine.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.weighingMachine.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.weighingMachine.faremark
              : "",
          },
          forkLifts: [
            {
              type: "batteryoperatedup",
              forkLift: {
                value: "",

                whsstatus: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[0]
                    ? data.singleFormData.mhInfraRemark.forkLifts[0].forkLift
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[0]
                    ? data.singleFormData.mhInfraRemark.forkLifts[0].forkLift
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[0]
                    ? data.singleFormData.mhInfraRemark.forkLifts[0].forkLift
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[0]
                    ? data.singleFormData.mhInfraRemark.forkLifts[0].forkLift
                        .faremark
                    : ""
                  : "",
              },
            },
            {
              type: "dieseloperated",
              forkLift: {
                value: "",

                whsstatus: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[1]
                    ? data.singleFormData.mhInfraRemark.forkLifts[1].forkLift
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[1]
                    ? data.singleFormData.mhInfraRemark.forkLifts[1].forkLift
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[1]
                    ? data.singleFormData.mhInfraRemark.forkLifts[1].forkLift
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[1]
                    ? data.singleFormData.mhInfraRemark.forkLifts[1].forkLift
                        .faremark
                    : ""
                  : "",
              },
            },
          ],
          hydraCrane: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraCrane.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraCrane.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraCrane.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraCrane.faremark
              : "",
          },
        });
      } else {
        setRemarkForm({
          pickingTrolley: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          hydraulicDockLevler: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          batteryOperatedPalletTruck: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          handPalletTruck: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          electricStacker: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          shrinkAndStretchWrapMachine: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          mhinfra_others: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          pallets: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          weighingMachine: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          forkLifts: [
            {
              type: "batteryoperatedup",
              forkLift: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              type: "dieseloperated",
              forkLift: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
          ],
          hydraCrane: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  // console.log("MH formField==>", formField)

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-12 rounded-lg">
                <div className="row">
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2"> Pallets: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField && formField.pallets
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className="mb-2"> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.pallets
                                  ? formField.pallets.quantity
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.pallets
                                  ? formField.pallets.whsremark
                                  : ""
                              }
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="pallets.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nabcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.pallets.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("pallets.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nabcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="pallets.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nabcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.pallets.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("pallets.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nabcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`pallets.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.pallets.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.pallets.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2">
                              {" "}
                              Hand Pallet Truck(Hydraulic):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField && formField.handPalletTruck !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className="mb-2"> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={formField ? formField.handPalletTruck : ""}
                              disabled
                            />
                          </td>{" "}
                          <td></td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="handPalletTruck.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.handPalletTruck.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "handPalletTruck.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="handPalletTruck.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.handPalletTruck.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "handPalletTruck.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`handPalletTruck.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.handPalletTruck.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.handPalletTruck.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2"> Weighing Machine: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-4 mb-2 mt-2">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.weighingMachine &&
                              formField.weighingMachine.validFrom !== null
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <p className="mb-0"> Calliberated on: </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              type="date"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.weighingMachine
                                  ? readableDate(
                                      formField.weighingMachine.validFrom
                                    )
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              type="date"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.weighingMachine
                                  ? readableDate(
                                      formField.weighingMachine.validTill
                                    )
                                  : ""
                              }
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="weighingMachine.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbxzxcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.weighingMachine.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "weighingMachine.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbxzxcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="weighingMachine.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcatedgoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.weighingMachine.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "weighingMachine.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatedgoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`weighingMachine.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.weighingMachine.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.weighingMachine.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2"> Picking Trolley: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField && formField.pickingTrolley !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={formField ? formField.pickingTrolley : ""}
                              disabled
                            />
                          </td>{" "}
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="pickingTrolley.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryOkayb"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.pickingTrolley.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "pickingTrolley.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryOkayb"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="pickingTrolley.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryNotOkayb"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.pickingTrolley.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "pickingTrolley.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryNotOkayb"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`pickingTrolley.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.pickingTrolley.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.pickingTrolley.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2">
                              {" "}
                              Hydraulic Dock Levler:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField && formField.hydraulicDockLevler !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className="mb-2"> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField ? formField.hydraulicDockLevler : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="hydraulicDockLevler.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcatgoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.hydraulicDockLevler.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "hydraulicDockLevler.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatgoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="hydraulicDockLevler.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcatgoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.hydraulicDockLevler.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "hydraulicDockLevler.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatgoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`hydraulicDockLevler.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.hydraulicDockLevler.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.hydraulicDockLevler.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2">
                              {" "}
                              Battery Operated Pallet Truck(BOPT){" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.batteryOperatedPalletTruck !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className="mb-2"> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField
                                  ? formField.batteryOperatedPalletTruck
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="batteryOperatedPalletTruck.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcatnegoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.batteryOperatedPalletTruck
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "batteryOperatedPalletTruck.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="batteryOperatedPalletTruck.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcatnegoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.batteryOperatedPalletTruck
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "batteryOperatedPalletTruck.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`batteryOperatedPalletTruck.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.batteryOperatedPalletTruck.fastatus ===
                              true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.batteryOperatedPalletTruck.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2">
                              {" "}
                              Forklift(Battery Operated):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-4">
                            <p className="text-gray py-3 mb-0"> Yes </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <p className="mb-0"> Capacity in ton: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              value={
                                formField
                                  ? formField.forkLifts[0]
                                    ? formField.forkLifts[0].capacityTon
                                    : ""
                                  : ""
                              }
                              className="form-control px-4 mb-2"
                              disabled
                            />
                          </td>{" "}
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="forkLifts[0].forkLift.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcxxategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.forkLifts[0]
                                    ? values.forkLifts[0].forkLift.whsstatus ===
                                      true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "forkLifts[0].forkLift.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcxxategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="forkLifts[0].forkLift.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcxxategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.forkLifts[0]
                                    ? values.forkLifts[0].forkLift.whsstatus ===
                                      false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "forkLifts[0].forkLift.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcxxategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`forkLifts[0].forkLift.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.forkLifts[0] &&
                              values.forkLifts[0].forkLift.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.forkLifts[0] &&
                                values.forkLifts[0].forkLift.faremark
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2">
                              {" "}
                              Forklift(Diesel Operated):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-4">
                            <p className="text-gray py-3 mb-0"> Yes </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <p className="mb-0"> Capacity in ton: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              value={
                                formField
                                  ? formField.forkLifts[1]
                                    ? formField.forkLifts[1].capacityTon
                                    : ""
                                  : ""
                              }
                              type="text"
                              className="form-control px-4 mb-2"
                              disabled
                            />
                          </td>{" "}
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="forkLifts[1].forkLift.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategotryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.forkLifts[1]
                                    ? values.forkLifts[1].forkLift.whsstatus ===
                                      true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "forkLifts[1].forkLift.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategotryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="forkLifts[1].forkLift.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategotryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.forkLifts[1]
                                    ? values.forkLifts[1].forkLift.whsstatus ===
                                      false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "forkLifts[1].forkLift.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategotryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`forkLifts[1].forkLift.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.forkLifts[1] &&
                              values.forkLifts[1].forkLift.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.forkLifts[1] &&
                                values.forkLifts[1].forkLift.faremark
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2"> Electric Stacker: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField && formField.electricStacker !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className="mb-2"> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={formField ? formField.electricStacker : ""}
                              disabled
                            />
                          </td>{" "}
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="electricStacker.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcatnegorycpOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.electricStacker.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "electricStacker.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegorycpOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="electricStacker.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcatnegorycpNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.electricStacker.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "electricStacker.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegorycpNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`electricStacker.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.electricStacker.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.electricStacker.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Hydra Crane: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-4 mt-2">
                            <p className="text-gray py-3 mb-0"> Yes </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <p className="mb-0"> Load Capacity: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField && formField.hydraCrane
                                ? formField.hydraCrane.loadCapacity
                                : ""}{" "}
                            </p>
                            <select
                              style={{
                                display: "none",
                              }}
                              className="form-control custom-select px-4 common-select-deep-blue"
                              id="exampleFormControlSelect1"
                              disabled
                            >
                              <option> Select </option> <option> Delhi </option>{" "}
                              <option> Mumbai </option>{" "}
                              <option> Kolkata </option>{" "}
                            </select>{" "}
                          </td>{" "}
                          <td>
                            <input
                              value={
                                formField && formField.hydraCrane
                                  ? formField.hydraCrane.whsremark
                                  : ""
                              }
                              type="text"
                              className="form-control px-4 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="hydraCrane.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcatnegorycppOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.hydraCrane.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("hydraCrane.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegorycppOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="hydraCrane.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcatnegorycppNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.hydraCrane.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("hydraCrane.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegorycppNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`hydraCrane.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.hydraCrane.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.hydraCrane.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-2">
                              {" "}
                              Shrink / Stretch Wrap Machine:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-4 mt-2">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.shrinkAndStretchWrapMachine !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <p className="mb-2"> Qty: </p>{" "}
                          </td>{" "}
                          <td>
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField
                                  ? formField.shrinkAndStretchWrapMachine
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td> </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="shrinkAndStretchWrapMachine.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcatnegorycOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.shrinkAndStretchWrapMachine
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "shrinkAndStretchWrapMachine.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegorycOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="shrinkAndStretchWrapMachine.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcatnegorycNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.shrinkAndStretchWrapMachine
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "shrinkAndStretchWrapMachine.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegorycNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`shrinkAndStretchWrapMachine.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.shrinkAndStretchWrapMachine.fastatus ===
                              true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.shrinkAndStretchWrapMachine.faremark
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-5 pb-1"> Others: </p>{" "}
                          </td>{" "}
                          <td className="" colSpan="4">
                            <textarea
                              type="text"
                              className="form-control px-4 rounded-md"
                              value={formField ? formField.others : ""}
                              rows="4"
                              disabled
                            >
                              {" "}
                            </textarea>{" "}
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mt-4 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="mhinfra_others.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcatnegorycOkaddy"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.mhinfra_others.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "mhinfra_others.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegorycOkaddy"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="mhinfra_others.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcatnegorycNotOkaddy"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.mhinfra_others.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "mhinfra_others.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcatnegorycNotOkaddy"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`mhinfra_others.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.mhinfra_others.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.mhinfra_others.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>
                    </table>{" "}
                  </div>{" "}
                </div>{" "}
                <div className={`col-12 mt-4`}>
                  <div className="row justify-content-end">
                    <div className="col-auto ">
                      <button
                        type="submit"
                        className="btn btn-deep-blue add-class remove-class "
                        disabled={data.isPending}
                      >
                        Save{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default MhPendingForm;
