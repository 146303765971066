import {
    DASHBOARD,
    DASHBOARD_RESPONSE,
    DASHBOARD_OPERATOR_TYPE,
    DASHBOARD_LOCATION_WAREHOUSE,
    DASHBOARD_WAREHOUSE_REQUEST_RECEIVE,
    DASHBOARD_LOCATION_FILTER,
    DASHBOARD_WAREHOUSE_CATEGORY,
    DEMOGRAPHY,
    USER_SUMMERY,
    REGISTER_USER_STATS,
    USER_SPACE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';


const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    dashboardDetail: null,
    dashboardOperator: null,
    dashboardLocation: null,
    dashboardWarehouseReceive: null,
    dashboardLocationFilter: null,
    dashboardWarehouseCategory: null,
    dashboardDemography: null,
    dashboardUserSummery: null,
    dashboardUserSpace: null,
    dashboardResponse: null,
    registerUserStats: null
}

export function DASHBOARD_INFO(state = initialState, action) {

    switch (action.type) {

        case DEMOGRAPHY:
            return {
                ...state,
                dashboardDemography: action.payload,
            };

        case REGISTER_USER_STATS:
            return {
                ...state,
                registerUserStats: action.payload,
            };

        case USER_SUMMERY:
            return {
                ...state,
                dashboardUserSummery: action.payload,
            };

        case USER_SPACE:
            return {
                ...state,
                dashboardUserSpace: action.payload,
            };

        case DASHBOARD_OPERATOR_TYPE:
            return {
                ...state,
                dashboardOperator: action.payload,
            };

        case DASHBOARD_LOCATION_WAREHOUSE:
            return {
                ...state,
                dashboardLocation: action.payload,
            };

        case DASHBOARD_WAREHOUSE_REQUEST_RECEIVE:
            return {
                ...state,
                dashboardWarehouseReceive: action.payload,
            };

        case DASHBOARD_LOCATION_FILTER:
            return {
                ...state,
                dashboardLocationFilter: action.payload,
            };

        case DASHBOARD_WAREHOUSE_CATEGORY:
            return {
                ...state,
                dashboardWarehouseCategory: action.payload,
            };

        case DASHBOARD:
            return {
                ...state,
                dashboardDetail: action.payload,
            };





        case DASHBOARD_RESPONSE:
            return {
                ...state,
                dashboardResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };


        default:
            return state;
    }
}