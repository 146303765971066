import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { CardLoader } from "../../../components/common/CustomLoader";

const InBoundForm = ({ isView }) => {
  const data = useSelector((state) => state.RFQ_INFO);

  const [jsonData, setJsonData] = useState([
    {
      processType: "pro typeupdate",
      uom: "",
      weightPerUom: "",
      volume: "",
      quantity: "",
      vehicleType: "",
      remark: "",
    },
    {
      processType: "Vehicles Inbound",
      uom: "",
      weightPerUom: "",
      volume: "",
      quantity: "",
      vehicleType: "",
      remark: "",
    },
    {
      processType: "Invoice per Vehicle",
      uom: "",
      weightPerUom: "",
      volume: "",
      quantity: "",
      vehicleType: "",
      remark: "",
    },
    {
      processType: "Cartons/ Boxes /Pallets per vehicle",
      uom: "",
      weightPerUom: "",
      volume: "",
      quantity: "",
      vehicleType: "",
      remark: "",
    },
    {
      processType: "SKU Items per Invoice",
      uom: "",
      weightPerUom: "",
      volume: "",
      quantity: "",
      vehicleType: "",
      remark: "",
    },
    {
      processType: " Generate barcode/Lable/Sticker",
      uom: "",
      weightPerUom: "",
      volume: "",
      quantity: "",
      vehicleType: "",
      remark: "",
    },
    {
      processType: "Scanning Inbound",
      uom: "",
      weightPerUom: "",
      volume: "",
      quantity: "",
      vehicleType: "",
      remark: "",
    },
    {
      processType: "MRN/GRN in System",
      uom: "",
      weightPerUom: "",
      volume: "",
      quantity: "",
      vehicleType: "",
      remark: "",
    },
  ]);

  const inboundSchema = Yup.object().shape({
    inbounds: Yup.array().of(
      Yup.object().shape({
        uom: Yup.string().required("Required"),
        weightPerUom: Yup.string().required("Required"),
        volume: Yup.string().required("Required"),
        quantity: Yup.string().required("Required"),
        vehicleType: Yup.string().required("Required"),
        remark: Yup.string().required("Required"),
      })
    ),
  });

  useEffect(() => {
    if (
      data?.rfqDetail?.data &&
      data?.rfqDetail?.data?.inbounds &&
      data?.rfqDetail?.data?.inbounds.length !== 0
    ) {
      console.log(data?.rfqDetail?.data?.inbounds,"|||&&&&|*|&&&&|||")
      setJsonData(data.rfqDetail.data.inbounds);
    }
  }, [data?.rfqDetail?.data]);

  return (
    <>
      {" "}
      {data.isLoading ? (
        <CardLoader loaderCard="loaderCard" />
      ) : (
        <Formik
          enableReinitialize={true}
          validationSchema={inboundSchema}
          initialValues={{
            inbounds: jsonData,
          }}
          onSubmit={(fields) => {
            console.log("---->", fields);
          }}
          render={({ values, errors, status, onChange, touched }) => {
            return (
              <div className="w-100 d-block">
                <Form>
                  <div className="row">
                    <div className="col-12 ml-0">
                      <div className="table-responsive border bg-deep-gray rounded-md table-cell">
                        <table className="table text-center form-control-height">
                          <thead>
                            <tr>
                              <td className="border-top-0 font-weight-bold text-nowrap py-2">
                                {" "}
                                S.no{" "}
                              </td>{" "}
                              <td className="text-left border-top-0 font-weight-bold text-nowrap py-2">
                                {" "}
                                Receving Handling Unit{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold text-nowrap py-2">
                                {" "}
                                Monthly Qty{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold py-2 text-nowrap">
                                {" "}
                                Weight/Unit{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold text-nowrap py-2">
                                {" "}
                                Handling Unit{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold text-nowrap py-2">
                                {" "}
                                Put-Away Unit{" "}
                              </td>{" "}
                            </tr>{" "}
                          </thead>{" "}
                          <tbody>
                            {values.inbounds.length > 0 &&
                              values.inbounds.map((bound, index) => {
                                const boundErrors =
                                  (errors.inbounds?.length &&
                                    errors.inbounds[index]) ||
                                  {};
                                const boundTouched =
                                  (touched.inbounds?.length &&
                                    touched.inbounds[index]) ||
                                  {};
                                return (
                                  <tr key={index}>
                                    <td> {index + 1} </td>{" "}
                                    <td className="text-left">
                                      {" "}
                                      {bound.processType}{" "}
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`inbounds.${index}.monthlyQty`}
                                        type="text"
                                        className={
                                          `form-control text-center bg-white my-1 mw-70px` +
                                          (boundErrors.uom && boundTouched.uom
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder=""
                                        readOnly={isView}
                                      />{" "}
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`inbounds.${index}.weightUnit`}
                                        type="text"
                                        className={
                                          `form-control text-center bg-white my-1` +
                                          (boundErrors.weightPerUom &&
                                          boundTouched.weightPerUom
                                            ? " is-invalid"
                                            : "")
                                        }
                                        readOnly={isView}
                                      />{" "}
                                    </td>{" "}
                                    <td>
                                    <Field
                                        name={`inbounds.${index}.handlingUnit`}
                                        type="text"
                                        className={
                                          `form-control text-center bg-white my-1` +
                                          (boundErrors.weightPerUom &&
                                          boundTouched.weightPerUom
                                            ? " is-invalid"
                                            : "")
                                        }
                                        readOnly={isView}
                                      />
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`inbounds.${index}.putawayUnit`}
                                        type="number"
                                        className={
                                          `form-control text-center bg-white  mw-70px my-1` +
                                          (boundErrors.quantity &&
                                          boundTouched.quantity
                                            ? " is-invalid"
                                            : "")
                                        }
                                        placeholder=""
                                        readOnly={isView}
                                      />{" "}
                                    </td>{" "}
                                  </tr>
                                );
                              })}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </div>
                  </div>
                </Form>{" "}
              </div>
            );
          }}
        />
      )}{" "}
    </>
  );
};

export default InBoundForm;
