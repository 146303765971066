import React, { useState, useEffect } from "react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import LayoutOne from "../../layout/LayoutOne";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  cmsByType,
  cmsUpdateByTypeId,
  responseCMS,
  createCMS,
} from "../../store/action/cmsAction";
import FormSuccess from "../../components/common/FormSuccess";
import { CardLoader } from "../../components/helper/CustomLoader";

const ReturnPolicy = () => {
  const [updateData, setUpdateData] = useState(EditorState.createEmpty());
  const dispatch = useDispatch();
  const about = useSelector((state) => state.CMS_INFO);
  const onEditorStateChange = (editorState) => {
    setUpdateData(editorState);
  };

  useEffect(() => {
    if (typeof about.cmsDetail.data == "string") {
      setUpdateData(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(about.cmsDetail.data)
          )
        )
      );
    }
  }, [about]);

  const aboutData = () => {
    let enterData = draftToHtml(convertToRaw(updateData.getCurrentContent()));
    let obj = {
      cmsType: "returnPolicy",
      cmsActive: true,
      data: enterData,
    };

    console.log(
      "cms->",
      JSON.stringify(convertToRaw(updateData.getCurrentContent()))
    );

    if (about.cmsDetail.cmsType === "returnPolicy") {
      dispatch(cmsUpdateByTypeId(obj, "returnPolicy"));
    } else {
      dispatch(createCMS(obj));
    }
  };

  useEffect(() => {
    dispatch(cmsByType("returnPolicy"));
  }, [dispatch]);

  return (
    <LayoutOne>
      {" "}
      {about.cmsResponse && about.cmsResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(responseCMS(null))}
          message={about.cmsResponse.message}
        />
      ) : null}{" "}
      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 pb-3 px-0">
          <h5 className="text-dark"> Content Management System </h5>{" "}
        </div>{" "}
        <div className="col-12 bg-white px-3 mb-4">
          <div className="row justify-content-between">
            <div className="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul className="nav nav-pills admin-tabs-blue">
                <li className="nav-item">
                  <Link to="/about-us" className="nav-link text-uppercase">
                    {" "}
                    About Us{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link to="/faq" className="nav-link text-uppercase">
                    {" "}
                    FAQ 's
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/term-and-condition"
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    t & c{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/return-policy"
                    className="nav-link active_link text-uppercase"
                  >
                    return policy{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link to="/privacy" className="nav-link text-uppercase">
                    {" "}
                    privacy{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        {about.isLoading ? (
          <CardLoader />
        ) : (
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12 px-0 cms-wrap">
                <Editor
                  editorState={updateData}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={onEditorStateChange}
                />{" "}
              </div>{" "}
            </div>{" "}
            <div className="row">
              <div className="col-12 text-right pb-5  pt-2">
                <button onClick={aboutData} className="btn btn-deep-blue py-2">
                  {" "}
                  Save{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        )}{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default ReturnPolicy;
