import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateForm80 } from "./../../../store/action/warehouseAction";

const FormEighty = ({ warehouseId, viewMood }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);

  const [jsonData, setJsonData] = useState([
    {
      heading: "Accessibility",
      parameter: "Distance from Fire Station (KMs)",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter:
        "How wide is the Road Access to the warehouse from the main road-Ft",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "How wide is the Road / Pathway inside the premises-Ft.",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Is the WH in residential area?",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Is the WH in industrial area or any WH zone??",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Distance from Police Station (KMs)",
      input: "",
      remark: "",
    },

    {
      heading: "Accessibility",
      parameter: "Distance from Nearest School (KMs)",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Distance from Highway Road (KMs)",
      input: "",
      remark: "",
    },

    {
      heading: "Accessibility",
      parameter: "Distance from transport Hub (KMs)",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Distance from warehousing Hub (KMs)",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Distance from Metro/ Bus Station (KMs)",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Distance from City Centre (KMs)",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Distance from ICD/CFS/Port (KMs)",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Distance from the Labour hub",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Public transport availability",
      input: "",
      remark: "",
    },
    {
      heading: "Accessibility",
      parameter: "Distance from Hospital (KMs)",
      input: "",
      remark: "",
    },
    //
    {
      heading: "Accommodation",
      parameter:
        "Are additional rooms available in the compound to be used as residence for Supervisors / workers?",
      input: "",
      remark: "",
    },
    {
      heading: "Accommodation",
      parameter:
        "How nearest is the resdential colony for workers from the warehouse?",
      input: "",
      remark: "",
    },
    {
      heading: "Affiliation",
      parameter: "Is there any Labour Union in the area?",
      input: "",
      remark: "",
    },
    {
      heading: "Affiliation",
      parameter: "Is there any Transportation Union in the area?",
      input: "",
      remark: "",
    },
    {
      heading: "Construction",
      parameter: "Construction Type (RCC / Mixed / Shed)",
      input: "",
      remark: "",
    },
    {
      heading: "Construction",
      parameter: "Construction Age (No of Years)",
      input: "",
      remark: "",
    },
    {
      heading: "Construction",
      parameter: "Transparent Sheets in the roof (for Day Light)-Qty",
      input: "",
      remark: "",
    },
    {
      heading: "Construction",
      parameter: "Turbo Ventilators in the shed-Qty",
      input: "",
      remark: "",
    },
    {
      heading: "Construction",
      parameter: "Flooring Type – Trimix / Normal / Epoxy",
      input: "",
      remark: "",
    },
    //
    {
      heading: "Construction",
      parameter: "Shutter height from the platform",
      input: "",
      remark: "",
    },
    {
      heading: "Construction",
      parameter: "Roof height of the storage area from the platform",
      input: "",
      remark: "",
    },
    {
      heading: "Construction",
      parameter:
        "How many Loading / Unloading Docks avaibale in the warehouse?",
      input: "",
      remark: "",
    },

    {
      heading: "Accommodation",
      parameter: "Is the WH has Hydraulic Loading/Unloading Docs?",
      input: "",
      remark: "",
    },

    {
      heading: "Construction",
      parameter: "Dock height from the ground",
      input: "",
      remark: "",
    },
    {
      heading: "Construction",
      parameter: "General apearance of the floor (Good / Bad / Ugly)",
      input: "",
      remark: "",
    },
    {
      heading: "Accommodation",
      parameter:
        "What is the size of the parking area for personal vehicles inside the coumpound?",
      input: "",
      remark: "",
    },
    {
      heading: "Accommodation",
      parameter:
        "What is the size of the parking area for trucks inside the coumpound?",
      input: "",
      remark: "",
    },

    {
      heading: "Accommodation",
      parameter: "Is there a meeting room available in the warehouse?",
      input: "",
      remark: "",
    },

    {
      heading: "Accommodation",
      parameter: "Is the WH structure capable to take load of solar panels?",
      input: "",
      remark: "",
    },
    {
      heading: "Electricity & Electrical Fittings",
      parameter: "Is there Genset Area available in the coumpound.",
      input: "",
      remark: "",
    },
    {
      heading: "Electricity & Electrical Fittings",
      parameter: "Electricity Connection (Kw)",
      input: "",
      remark: "",
    },
    {
      heading: "Electricity & Electrical Fittings",
      parameter: "Dedicated Transformer (Kva)",
      input: "",
      remark: "",
    },
    {
      heading: "Electricity & Electrical Fittings",
      parameter: "Electrical fittings /wirings /gadgets are with ISI marks.",
      input: "",
      remark: "",
    },
    {
      heading: "Electricity & Electrical Fittings",
      parameter: "Electrical Earthing is available	",
      input: "",
      remark: "",
    },
    {
      heading: "Electricity & Electrical Fittings",
      parameter:
        "Is there is Electricity panel / Power Panel Room in the compound ?	",
      input: "",
      remark: "",
    },
    {
      heading: "Electricity & Electrical Fittings",
      parameter: "No open / loose wiring in and outside of the warehouse	",
      input: "",
      remark: "",
    },
    {
      heading: "Electricity & Electrical Fittings",
      parameter: "Is the WH using any green energy? Eg-Solar power	",
      input: "",
      remark: "",
    },

    {
      heading: "Emergency Exit & Fire Prevention",
      parameter: "No of Emergency Door available inside the warehouse?	",
      input: "",
      remark: "",
    },
    {
      heading: "Emergency Exit & Fire Prevention",
      parameter: "Is there an assembly area earmarked during fire breakout?	",
      input: "",
      remark: "",
    },
    {
      heading: "Emergency Exit & Fire Prevention",
      parameter: "What is the size of open area?-SQ Ft	",
      input: "",
      remark: "",
    },
    {
      heading: "Emergency Exit & Fire Prevention",
      parameter:
        "Are the fire fighting equipments in approachable limits inside the WH?	",
      input: "",
      remark: "",
    },
    {
      heading: "Emergency Exit & Fire Prevention",
      parameter:
        "Are the fire fighting equipments inside the WH are in adequate qty?	",
      input: "",
      remark: "",
    },
    {
      heading: "Emergency Exit & Fire Prevention",
      parameter:
        "Are the workers trained to fire fight and use fire fighting equipments?	",
      input: "",
      remark: "",
    },
    {
      heading: "Emergency Exit & Fire Prevention",
      parameter:
        "Is the evacuation route clearly marked and visible inside the warehouse?	",
      input: "",
      remark: "",
    },
    {
      heading: "Emergency Exit & Fire Prevention",
      parameter:
        "Emergency alarm is clearly audible, supported by battery and alarm buttons are available inside WH?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter: "Does the compound has Green belt / plantation?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter: "Is there a Rain Water Harvesting / Drainage system?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter: "Is the open area well metteled?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter: "Are the Sewage pipes connected to a sewage Pit ?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter:
        "Are the Sewage pipes connected to the approved system of the area?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter:
        "Ground contamination due to release of / keeping of chemical is taken care of?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter:
        "Is the PUC getting done of the air pollutant releasing equipments as per Govt norms?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter:
        "Handling of all of type of waste inluding Hazardous waste within WH premises is as per defined norms?	",
      input: "",
      remark: "",
    },
    {
      heading: "Environment Protection",
      parameter:
        "Disposal of all type of waste inluding Hazardous waste within WH premises is as per defined norms?	",
      input: "",
      remark: "",
    },
    {
      heading: "Policy",
      parameter: "Policy for child labour is available?	",
      input: "",
      remark: "",
    },
    {
      heading: "Policy",
      parameter: "Policy for ethical ways of conducting business is available?	",
      input: "",
      remark: "",
    },
    {
      heading: "Policy",
      parameter: "Policy for environmental protection is available?	",
      input: "",
      remark: "",
    },
    {
      heading: "Policy",
      parameter:
        "Policy for worker/labour insurance, compensation and benefits is available?	",
      input: "",
      remark: "",
    },
    {
      heading: "Safety & Security	",
      parameter: "Is the Warehouse inside a gated compound?	",
      input: "",
      remark: "",
    },
    {
      heading: "Safety & Security	",
      parameter: "Aprox Height of the Compound Boundary Wall height-Ft	",
      input: "",
      remark: "",
    },
    {
      heading: "Safety & Security	",
      parameter:
        "How far is HT (high tension) electric line from the WH premise-KMs	",
      input: "",
      remark: "",
    },
    {
      heading: "Safety & Security	",
      parameter:
        "Are their Glass pieces on boundary wall, as a safety maeasure.	",
      input: "",
      remark: "",
    },
    {
      heading: "Safety & Security	",
      parameter: "Are there Barbed Wire over boundary wall	",
      input: "",
      remark: "",
    },
    {
      heading: "Safety & Security	",
      parameter: "No. of gates into the premises compound	",
      input: "",
      remark: "",
    },
    {
      heading: "Safety & Security	",
      parameter: "Is there a proper Security Room at the main gate ?	",
      input: "",
      remark: "",
    },

    {
      heading: "Warehouse Operations",
      parameter: "Are the windows on side walls are properly pilferage proof?	",
      input: "",
      remark: "",
    },
    {
      heading: "Warehouse Operations",
      parameter: "Is WH operational for 24 hrs?	",
      input: "",
      remark: "",
    },
    {
      heading: "Warehouse Operations",
      parameter: "Does the WH operator provides Handling Equipments?	",
      input: "",
      remark: "",
    },
    {
      heading: "Warehouse Operations",
      parameter: "Does the WH operator provides Labour?	",
      input: "",
      remark: "",
    },
    {
      heading: "Warehouse Operations",
      parameter: "Are the workers/labours working in WH are permanent?	",
      input: "",
      remark: "",
    },
    {
      heading: "Safety & Security	",
      parameter: "Is the complete premises under CCTV survellience?	",
      input: "",
      remark: "",
    },
    {
      heading: "Warehouse Operations",
      parameter: "Is there a Weigh Bridge inside the WH premises?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter:
        "Is there a Pantry area available within the warehouse premises ?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter: "Are there washroosm for staff / workers?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter: "Is there a creche available inside WH premises?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter: "Are there enough lights in the open area in the night?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter: "Source of Water in the warehouse (for regular use)?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter: "Is drinking water available and accessible to all 24hrs?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter:
        "Are the basic safety gears available for the labours/workers?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter:
        "Are the safety signs visible and adequately availble inside the WH as well as WH premises?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter: "Are the first aid boxes adequately available inside the WH?	",
      input: "",
      remark: "",
    },
    {
      heading: "Worker Facility, Health & Safety	",
      parameter: "Is there an Ambulance parked 24/7 inside the WH premises?	",
      input: "",
      remark: "",
    },
  ]);

  const [remarkForm, setRemarkForm] = useState(null);
  useEffect(() => {
    if (data.singleFormData !== null && data.type === "formEighty") {
      if (data.singleFormData.formEightyInfo) {
        setJsonData(
          data.singleFormData.formEightyInfo.map((item, i) => {
            return {
              heading: item.heading,
              parameter: item.parameter,
              input: item.input,
              remark: item.remark,
            };
          })
        );
      }

      if (
        data.singleFormData &&
        data.singleFormData !== null &&
        data.singleFormData.formEightyRemark
      ) {
        if (data.singleFormData.formEightyRemark) {
          let here = data.singleFormData.formEightyRemark.formEighties.map(
            (item, i) => {
              return {
                // "heading": item.heading,
                formEighty: {
                  value: "",

                  whsstatus: item.formEighty.whsstatus,
                  whsremark: item.formEighty.whsremark,
                  fastatus: item.formEighty.fastatus,
                  faremark: item.formEighty.faremark,
                },
              };
            }
          );

          setRemarkForm({
            formEighties: here,
          });
        }
      }
    }
  }, [data.singleFormData, data.type]);

  const validationSchema = function (values) {
    return Yup.object().shape({
      formEighties: Yup.array().of(
        Yup.object().shape({
          formEighty: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),

      // etc
    });
  };
  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        console.log("yahaayaerror", error, getErrorsFromValidationError(error));
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  return (
    <>
      {" "}
      {/* Loader */}
      {data.isLoading ? null : data.isError !== "" ? null : (
        <>
          {" "}
          {/* {
                            data.addNewResponse.whsstatusCode===200 ?
                                <FormSuccess onClick={()=>dispatch(addNewWarehouse([]))} message={data.addNewResponse.message} />
                              :null
                            } */}
          <div className="row justify-content-end align-items-center py-3 px-3 mx-0 w-100">
            <div className="col-12">
              <Formik
                enableReinitialize={true}
                validate={validate(validationSchema)}
                initialValues={{
                  formEighty: jsonData,
                  remark: remarkForm,
                }}
                onSubmit={(fields) => {
                  let newfield = fields.remark;

                  newfield.warehouse = warehouseId;
                  dispatch(updateForm80(newfield));
                }}
                render={({
                  values,
                  errors,
                  status,
                  onChange,
                  setFieldValue,
                  touched,
                }) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-12 px-0 table-responsive table-gray-admin">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="w-100px"> S.NO </th>{" "}
                                <th> Headings </th>{" "}
                                <th className="mw-300px"> Parameters </th>{" "}
                                <th className=""> Input </th>{" "}
                                <th className="text-center"> Remark </th>{" "}
                                <th className="text-center col-1">
                                  {" "}
                                  FA Status{" "}
                                </th>{" "}
                                <th className="text-center"> FA Remark </th>
                                <th className="text-center"> okay </th>{" "}
                                <th className="text-center"> Not Okay </th>{" "}
                                <th className="text-center"> Remark Action </th>{" "}
                              </tr>{" "}
                            </thead>{" "}
                            <tbody>
                              {" "}
                              {values.formEighty.length > 0 &&
                                values.formEighty.map((eighty, index) => {
                                  const eightyErrors =
                                    (errors.formEighty?.length &&
                                      errors.formEighty[index]) ||
                                    {};
                                  const eightyTouched =
                                    (touched.formEighty?.length &&
                                      touched.formEighty[index]) ||
                                    {};
                                  return (
                                    <tr key={index}>
                                      <td> {index + 1} </td>{" "}
                                      <td> {eighty.heading} </td>{" "}
                                      <td> {eighty.parameter} </td>{" "}
                                      <td>
                                        <Field
                                          disabled
                                          type="text"
                                          name={`formEighty.${index}.input`}
                                          className={
                                            "form-control border-1 w-150px" +
                                            (eightyErrors.input &&
                                            eightyTouched.input
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />{" "}
                                        <ErrorMessage
                                          name={`formEighty.${index}.input`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </td>{" "}
                                      <td>
                                        <Field
                                          disabled
                                          type="text"
                                          name={`formEighty.${index}.remark`}
                                          className="form-control border-1 w-150px"
                                        />
                                      </td>{" "}
                                      <td className="text-center">
                                        {" "}
                                        {values.remark &&
                                        values.remark.formEighties &&
                                        values.remark.formEighties[index] &&
                                        values.remark.formEighties[index]
                                          .formEighty.fastatus === true
                                          ? "okay"
                                          : "not okay"}{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        {values.remark &&
                                          values.remark.formEighties &&
                                          values.remark.formEighties[index] &&
                                          values.remark.formEighties[index]
                                            .formEighty.faremark}{" "}
                                      </td>{" "}
                                      <td className=" pr-0">
                                        <div className="form-inline form-group pt-3">
                                          <div className="form-check common-radio-deep-blue mx-3">
                                            <Field
                                              name={`remark.formEighties[${index}].formEighty.whsstatus`}
                                              className="common-radio-deep-blue-input"
                                              id={"okiiay" + index}
                                              type="radio"
                                              value="okay"
                                              hidden="true"
                                              required
                                              checked={
                                                values.remark
                                                  ? values.remark.formEighties
                                                    ? values.remark
                                                        .formEighties[index]
                                                      ? values.remark
                                                          .formEighties[index]
                                                          .formEighty
                                                          .whsstatus === true
                                                      : ""
                                                    : ""
                                                  : ""
                                              }
                                              disabled={viewMood}
                                              onChange={() => {
                                                let a = `remark.formEighties[${index}].formEighty.whsstatus`;
                                                setFieldValue(a, true);
                                              }}
                                            />{" "}
                                            <label
                                              className="common-radio-deep-blue-label pl-sm-4"
                                              htmlFor={"okiiay" + index}
                                            ></label>{" "}
                                          </div>{" "}
                                        </div>{" "}
                                      </td>{" "}
                                      <td className="pr-0">
                                        <div className="form-inline form-group pt-3">
                                          <div className="form-check common-radio-deep-blue mx-3">
                                            <Field
                                              name={`remark.formEighties[${index}].formEighty.whsstatus`}
                                              className="common-radio-deep-blue-input"
                                              id={"not-okiiay" + index}
                                              type="radio"
                                              value="notOkay"
                                              hidden="true"
                                              required
                                              checked={
                                                values.remark
                                                  ? values.remark.formEighties
                                                    ? values.remark
                                                        .formEighties[index]
                                                      ? values.remark
                                                          .formEighties[index]
                                                          .formEighty
                                                          .whsstatus === false
                                                      : ""
                                                    : ""
                                                  : ""
                                              }
                                              disabled={viewMood}
                                              onChange={() => {
                                                let a = `remark.formEighties[${index}].formEighty.whsstatus`;
                                                setFieldValue(a, false);
                                              }}
                                            />{" "}
                                            <label
                                              className="common-radio-deep-blue-label pl-sm-4"
                                              htmlFor={"not-okiiay" + index}
                                            ></label>{" "}
                                          </div>{" "}
                                        </div>{" "}
                                      </td>{" "}
                                      <td className="mw-150px">
                                        <Field
                                          type="text"
                                          name={`remark.formEighties[${index}].formEighty.whsremark`}
                                          className="form-control bg-white px-4 mt-2 mr-4"
                                          placeholder="Remarks"
                                        />
                                      </td>{" "}
                                    </tr>
                                  );
                                })}{" "}
                            </tbody>{" "}
                          </table>{" "}
                        </div>{" "}
                        <div className="col-12 mt-4">
                          <div className="row justify-content-end"> </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </Form>
                  );
                }}
              />{" "}
            </div>{" "}
          </div>{" "}
        </>
      )}{" "}
    </>
  );
};

export default FormEighty;
