import {
    REPORT,
    REPORT_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING
} from './../types';


const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    reportList: [],
    reportResponse: null
}

export function REPORT_INFO(state = initialState, action) {

    switch (action.type) {

        case REPORT:
            return {
                ...state,
                reportList: action.payload,
            };

        case REPORT_RESPONSE:
            return {
                ...state,
                reportResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        default:
            return state;
    }
}