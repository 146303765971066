import React from "react";

import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axiosauth from "../../services/axios-auth";
import {
  assigntofieldagent,
  changeWarehouseStatus,
} from "../../store/action/warehouseAction";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";

const AssignFormToFieldAgent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSEINFO);

  const { warehouseId } = useParams();
  const [citylist, setcitylist] = useState([]);
  const [arealist, setarealist] = useState([]);
  const [departmentlist, setdepartmentlist] = useState([]);
  const [userslist, setuserslist] = useState([]);
  const [id, setid] = useState("");
  console.log("citylist===>", citylist);

  useEffect(() => {
    // /api/v1/user/assignfieldagaint?page=1&limit=10
    try {
      axiosauth
        .post(`/api/v1/user/assignfieldagaint?page=1&limit=10`, {
          filter: {
            city: "",
            area: "",
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log("res-->", res);
          let cityarr = [];
          for (let i = 0; i < res.data.length; i++) {
            cityarr.push({
              value: res.data[i].city,
              label: res.data[i].city,
            });
          }
          setcitylist(cityarr);
        })
        .catch((error) => {})
        .then(() => {});
    } catch (e) {}
  }, []);

  const changecity = (city) => {
    try {
      axiosauth
        .post(`/api/v1/user/assignfieldagaint?page=1&limit=10`, {
          filter: {
            city: city,
            area: "",
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          let cityarr = [];
          for (let i = 0; i < res.data.length; i++) {
            cityarr.push({
              value: res.data[i].area,
              label: res.data[i].area,
            });
          }
          setarealist(cityarr);
        })
        .catch((error) => {})
        .then(() => {});
    } catch (e) {}
  };
  const changearea = (area) => {
    try {
      axiosauth
        .post(`/api/v1/user/assignfieldagaint?page=1&limit=10`, {
          filter: {
            city: "",
            area: area,
          },
        })
        .then((response) => {
          let res = JSON.parse(response.data);
          let cityarr = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].department !== null) {
              cityarr.push({
                value: res.data[i].department?.id,
                label: res.data[i].department?.name,
              });
            }
            //  cityarr.push({
            //    value:res.data[i].department?.name,
            //   label:res.data[i].department?.name
            //  })
          }
          setdepartmentlist(cityarr);
        })
        .catch((error) => {})
        .then(() => {});
    } catch (e) {}
  };
  const changedepartment = (id) => {
    try {
      axiosauth
        .get(
          `/api/v1/accounts/1/users/department/` +
            id +
            "/role/1?page=1&limit=10"
        )
        .then((response) => {
          let res = JSON.parse(response.data);
          let cityarr = [];
          for (let i = 0; i < res.data.length; i++) {
            cityarr.push({
              value: res.data[i].id,
              label: res.data[i].firstName + " " + res.data[i].lastName,
            });
          }

          setuserslist(cityarr);
        })
        .catch((error) => {})
        .then(() => {});
    } catch (e) {}
  };
  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Assign To Field Agent </title>{" "}
      </Helmet>{" "}
      {data.finalSubmit ? (
        <FormSuccess
          onClick={() => {
            dispatch(changeWarehouseStatus());
            history.replace(`/pending-warehouse?page=1`);
          }}
          message="your submission has been send for approval. We will respond to you very soon"
        />
      ) : null}
      <div className="row align-items-center py-3">
        <div className="col-12 py-3 mt-4">
          <h5 className="text-dark">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            ></i>{" "}
            Assign to Field Agent{" "}
          </h5>{" "}
        </div>{" "}
      </div>{" "}
      <div className="row align-items-center pb-3 px-3 mx-0">
        {" "}
        {/* <div className="col-12 h6 mb-4">WH ID: {warehouseId}</div> */}{" "}
        <div className="col-12">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="row">
              <div className="form-group col-lg-3 col-md-6 mb-4">
                <label for="staticEmail" className="mb-2 pl-3">
                  City{" "}
                </label>{" "}
                <select
                  onChange={(e) => changecity(e.target.value)}
                  className="form-control form-control-md bg-white px-4 common-select-deep-blue rounded-pill"
                  id="exampleFormControlSelect1"
                >
                  <option> Select </option>{" "}
                  {citylist && citylist.length > 0
                    ? citylist.map((value, index) => {
                        return <option> {value.label} </option>;
                      })
                    : null}{" "}
                </select>{" "}
              </div>{" "}
              <div className="form-group col-lg-3 col-md-6 mb-4">
                <label for="staticEmail" className="mb-2 pl-3">
                  Area{" "}
                </label>{" "}
                <select
                  onChange={(e) => {
                    changearea(e.target.value);
                  }}
                  className="form-control form-control-md bg-white px-4 common-select-deep-blue rounded-pill"
                  id="exampleFormControlSelect1"
                >
                  <option> Select </option>{" "}
                  {arealist && arealist.length > 0
                    ? arealist.map((value, index) => {
                        return <option> {value.label} </option>;
                      })
                    : null}{" "}
                </select>{" "}
              </div>{" "}
              <div className="form-group col-lg-3 col-md-6 mb-4">
                <label for="staticEmail" className="mb-2 pl-3">
                  Department{" "}
                </label>{" "}
                <select
                  onChange={(e) => {
                    changedepartment(e.target.value);
                  }}
                  className="form-control form-control-md bg-white px-4 common-select-deep-blue rounded-pill"
                  id="exampleFormControlSelect1"
                >
                  <option> Select </option>{" "}
                  {departmentlist && departmentlist.length > 0
                    ? departmentlist.map((value, index) => {
                        return (
                          <option value={value.value}> {value.label} </option>
                        );
                      })
                    : null}{" "}
                </select>{" "}
              </div>{" "}
              <div className="form-group col-lg-3 col-md-6 mb-4">
                <label for="staticEmail" className="mb-2 pl-3">
                  Assign to{" "}
                </label>{" "}
                <select
                  onChange={(e) => setid(e.target.value)}
                  className="form-control form-control-md bg-white px-4 common-select-deep-blue rounded-pill"
                  id="exampleFormControlSelect1"
                >
                  <option> Select </option>{" "}
                  {userslist && userslist.length > 0
                    ? userslist.map((value, index) => {
                        return (
                          <option value={value.value}> {value.label} </option>
                        );
                      })
                    : null}{" "}
                </select>{" "}
              </div>{" "}
            </div>{" "}
            <div className="row pt-4">
              <div className="col-auto">
                <button
                  onClick={() => history.goBack()}
                  type="button"
                  className="btn btn-outline-deep-blue my-4"
                >
                  Back{" "}
                </button>{" "}
              </div>{" "}
              <div className="col-auto">
                <button
                  onClick={() => {
                    dispatch(assigntofieldagent(warehouseId, id));
                  }}
                  type="submit"
                  className="btn btn-deep-blue my-4"
                >
                  Submit{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </form>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default AssignFormToFieldAgent;
