import React from "react";
import { useSelector } from "react-redux";

const ClickButton = ({ onClick, title }) => {
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  return (
    <button
      onClick={onClick}
      type="button"
      className={`btn btn-deep-blue my-4 py-2 ${!read ? "" : "d-none"}`}
    >
      {" "}
      {title}{" "}
    </button>
  );
};

export default ClickButton;
