import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  updateWarehouseContact,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import { Formik, Form, Field, FieldArray } from "formik";
import FormSuccess from "./../../../components/common/FormSuccess";
import ShowMap from "../ShowMap";

const ContactPendingForm = ({ warehouseId, viewMood }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  console.log("rohanhu", data);
  const onSubmit = (fields) => {
    const payload = {
      data: {
        warehouseContactDetailRemark: {
          ...fields,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateWarehouseContact(payload));
  };

  const validationSchema = function (values) {
    return Yup.object().shape({
      contactInfo: Yup.array().of(
        Yup.object().shape({
          name: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
          phone: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
          email: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      address: Yup.object().shape({
        line1: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        addressLine2: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        city: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        state: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        district: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        country: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        pinCode: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        latitude: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        longnitude: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        // etc
      }),
    });
  };
  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const [remarkForm, setRemarkForm] = useState({
    contactInfo: [
      {
        name: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[0].name
              : ""
            : "",
          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        phone: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[0].phone
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        email: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[0].email
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        contactType: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[0].contactType
              : ""
            : "",

          whsstatus: "contact",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        name: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[2].name
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        phone: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[2].phone
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        email: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[2].email
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        contactType: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[3].contactType
              : ""
            : "",

          whsstatus: "alternateContact",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    address: {
      line1: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.line1
            : "",
        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      addressLine2: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.addressLine2
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      city: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.city
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      state: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.state
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      district: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.district
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      country: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.country
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      pinCode: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.pinCode
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      landmark: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.landmark
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      latitude: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.latitude
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      longnitude: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.longnitude
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
    },
    companyName: {
      value: "",
      whsstatus: true,
      whsremark: "compnayname",
      fastatus: "null",
      faremark: "",
    },
    id: warehouseId,
  });
  const [formField, setFormField] = useState(null);

  useEffect(() => {
    if (
      data.singleFormData &&
      data.singleFormData !== null &&
      data.singleFormData.address !== null &&
      data.type === "warehouseContactDetails"
    ) {
      setFormField(data.singleFormData.warehouseContactDetailInfo);

      if (data.singleFormData.warehouseContactDetailRemark) {
        setRemarkForm({
          contactInfo: [
            {
              name: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].name
                    : ""
                  : "",
                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].name.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].name.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].name.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].name.faremark
                    : ""
                  : "",
              },
              phone: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].phone
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].phone.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].phone.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].phone.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].phone.faremark
                    : ""
                  : "",
              },
              email: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].email
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].email.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].email.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].email.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].email.faremark
                    : ""
                  : "",
              },
              contactType: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].contactType
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].contactType.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].contactType.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].contactType.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].contactType.faremark
                    : ""
                  : "",
              },
            },
            {
              name: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].name
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].name.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].name.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].name.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].name.faremark
                    : ""
                  : "",
              },
              phone: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].phone
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].phone.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].phone.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].phone.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].phone.faremark
                    : ""
                  : "",
              },
              email: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].email
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].email.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].email.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].email.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].email.faremark
                    : ""
                  : "",
              },
              contactType: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[3].contactType
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].contactType.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].contactType.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].contactType.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].contactType.faremark
                    : ""
                  : "",
              },
            },
          ],
          address: {
            line1: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.line1
                  : "",
              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .line1.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .line1.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .line1.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .line1.faremark
                  : "",
            },
            addressLine2: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.addressLine2
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .addressLine2.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .addressLine2.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .addressLine2.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .addressLine2.faremark
                  : "",
            },
            city: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.city
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .city.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .city.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .city.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .city.faremark
                  : "",
            },
            state: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.state
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .state.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .state.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .state.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .state.faremark
                  : "",
            },
            district: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.district
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .district.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .district.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .district.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .district.faremark
                  : "",
            },
            country: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.country
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .country.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .country.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .country.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .country.faremark
                  : "",
            },
            pinCode: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.pinCode
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .pinCode.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .pinCode.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .pinCode.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .pinCode.faremark
                  : "",
            },
            landmark: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.landmark
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .landmark.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .landmark.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .landmark.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .landmark.faremark
                  : "",
            },
            latitude: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.latitude
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .latitude.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .latitude.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .latitude.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .latitude.faremark
                  : "",
            },
            longnitude: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.longnitude
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .longnitude.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .longnitude.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .longnitude.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .longnitude.faremark
                  : "",
            },
          },
          companyName: {
            value: "",
            whsstatus: true,
            whsremark: "compnayname",
            fastatus: "null",
            faremark: "",
          },
        });
      } else {
        setRemarkForm({
          contactInfo: [
            {
              name: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].name
                    : ""
                  : "",
                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              phone: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].phone
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              email: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].email
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              contactType: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].contactType
                    : ""
                  : "",

                whsstatus: "contact",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              name: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].name
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              phone: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].phone
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              email: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].email
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              contactType: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[3].contactType
                    : ""
                  : "",

                whsstatus: "alternateContact",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
          ],
          address: {
            line1: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.line1
                  : "",
              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            addressLine2: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.addressLine2
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            city: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.city
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            state: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.state
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            district: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.district
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            country: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.country
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            pinCode: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.pinCode
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            landmark: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.landmark
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            latitude: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.latitude
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            longnitude: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.longnitude
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
          },
          companyName: {
            value: "",
            whsstatus: true,
            whsremark: "compnayname",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}{" "}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => {
          console.log("hiran");
          onSubmit(fields);
        }}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setValues,
          setFieldValue,
        }) => {
          return (
            <Form className="w-100">
              <div className="col-xl-12 rounded-lg">
                <div className="">
                  <FieldArray
                    name="contactInfo"
                    render={(arrayHelpers) => (
                      <>
                        {" "}
                        {values.contactInfo.map((value, index) => (
                          <>
                            <div className="col-xl-10">
                              <table class="table table-borderless mb-0 d-non">
                                {" "}
                                {index === 0 ? (
                                  <thead>
                                    <tr>
                                      <th scope="col" class="col-6 py-0">
                                        {" "}
                                      </th>{" "}
                                      <th scope="col" className="py-0">
                                        Okay{" "}
                                      </th>{" "}
                                      <th
                                        scope="col"
                                        className="py-0"
                                        style={{
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        Not Okay{" "}
                                      </th>{" "}
                                      <th
                                        scope="col"
                                        className="text-center py-0"
                                      >
                                        Remarks{" "}
                                      </th>{" "}
                                      <th
                                        scope="col"
                                        className="text-center pb-0"
                                      >
                                        Field Agent Remarks{" "}
                                      </th>{" "}
                                    </tr>{" "}
                                  </thead>
                                ) : (
                                  <thead>
                                    <tr>
                                      <th scope="col" class="col-6 py-0">
                                        {" "}
                                      </th>{" "}
                                      <th scope="col" className="py-0">
                                        {" "}
                                      </th>{" "}
                                      <th scope="col" className="py-0">
                                        {" "}
                                      </th>{" "}
                                      <th
                                        scope="col"
                                        className="text-center py-0"
                                      ></th>{" "}
                                      <th
                                        scope="col"
                                        className="text-center py-0"
                                      ></th>{" "}
                                    </tr>{" "}
                                  </thead>
                                )}{" "}
                                <tbody>
                                  <tr>
                                    <td className="py-0">
                                      <table class="table table-borderless form-group col-12 pt-xl-1">
                                        <tr>
                                          <td className="py-0">
                                            <label
                                              htmlFor="staticEmail"
                                              className="mb-2 px-2"
                                            >
                                              {index === 0
                                                ? "Warehouse Name:"
                                                : "Company Name:"}{" "}
                                            </label>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                        <tr>
                                          <td className="py-0">
                                            <input
                                              className="form-control px-4"
                                              value={
                                                formField
                                                  ? formField.contactInfo &&
                                                    formField.contactInfo
                                                      .length > 0
                                                    ? formField.contactInfo[
                                                        index
                                                      ].name
                                                    : ""
                                                  : ""
                                              }
                                              disabled
                                            />
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td verticalAlign="middle" className="py-0">
                                      <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                        <tr>
                                          <td
                                            className="py-0 form-check mt-4 common-radio-deep-blue"
                                            verticalAlign="middle"
                                          >
                                            <Field
                                              name={`contactInfo[${index}].name.whsstatus`}
                                              className="common-radio-deep-blue-input"
                                              id={"okay" + index}
                                              type="radio"
                                              value="okay"
                                              hidden="true"
                                              required
                                              checked={
                                                values.contactInfo
                                                  ? values.contactInfo[index]
                                                      .name.whsstatus === true
                                                  : ""
                                              }
                                              disabled={viewMood}
                                              onChange={() => {
                                                let a = `contactInfo[${index}].name.whsstatus`;
                                                setFieldValue(a, true);
                                              }}
                                            />{" "}
                                            <label
                                              className="common-radio-deep-blue-label pl-sm-4"
                                              htmlFor={"okay" + index}
                                            ></label>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="py-0">
                                      <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                        <tr>
                                          <td
                                            className="py-0 form-check mt-4 common-radio-deep-blue"
                                            verticalAlign="middle"
                                          >
                                            <Field
                                              name={`contactInfo[${index}].name.whsstatus`}
                                              className="common-radio-deep-blue-input"
                                              id={"not-okay" + index}
                                              type="radio"
                                              value="notOkay"
                                              hidden="true"
                                              required
                                              checked={
                                                values.contactInfo
                                                  ? values.contactInfo[index]
                                                      .name.whsstatus === false
                                                  : ""
                                              }
                                              disabled={viewMood}
                                              onChange={() => {
                                                let a = `contactInfo[${index}].name.whsstatus`;
                                                setFieldValue(a, false);
                                              }}
                                            />{" "}
                                            <label
                                              className="common-radio-deep-blue-label pl-sm-4"
                                              htmlFor={"not-okay" + index}
                                            ></label>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="pb-0">
                                      <table class="table table-borderless form-group col-12 pt-xl-1">
                                        <tr>
                                          <td className="pb-0 ">
                                            <Field
                                              type="text"
                                              disabled={viewMood}
                                              name={`contactInfo[${index}].name.whsremark`}
                                              className="form-control bg-white px-2 ml-xl-0 ml-2 mb-2"
                                              placeholder="Remarks"
                                            />
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="pb-0 d-flex pt-4">
                                      <p className="py-2 text-nowrap pr-4">
                                        {" "}
                                        {formField &&
                                        formField.warehouseContactDetailRemark &&
                                        formField.warehouseContactDetailRemark
                                          .contactInfo.length > 0 &&
                                        formField.warehouseContactDetailRemark
                                          .contactInfo[index].name.fastatus ===
                                          true
                                          ? "okay"
                                          : "not okay"}{" "}
                                      </p>{" "}
                                      <input
                                        value={
                                          formField &&
                                          formField.warehouseContactDetailRemark &&
                                          formField.warehouseContactDetailRemark
                                            .contactInfo.length > 0 &&
                                          formField.warehouseContactDetailRemark
                                            .contactInfo[index].name.faremark
                                        }
                                        type="text"
                                        className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                        placeholder="Remarks"
                                        disabled
                                      />
                                    </td>{" "}
                                  </tr>{" "}
                                </tbody>{" "}
                              </table>{" "}
                            </div>{" "}
                          </>
                        ))}{" "}
                      </>
                    )}
                  />{" "}
                </div>
                <div className="">
                  {" "}
                  {values.contactInfo.map((value, index) => (
                    <>
                      <div className="col-xl-10">
                        <table class="table table-borderless mb-0 d-non">
                          <thead>
                            <tr>
                              <th scope="col" class="col-6 py-0">
                                {" "}
                              </th>{" "}
                              <th scope="col" className="py-0">
                                {" "}
                              </th>{" "}
                              <th scope="col" className="py-0">
                                {" "}
                              </th>{" "}
                              <th scope="col" className="text-center py-0">
                                {" "}
                              </th>{" "}
                              <th scope="col" className="text-center py-0">
                                {" "}
                              </th>{" "}
                            </tr>{" "}
                          </thead>{" "}
                          <tbody>
                            <tr>
                              <td className="py-0">
                                <table class="table table-borderless form-group col-12 pt-xl-1">
                                  <tr>
                                    <td className="py-0">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        {index === 0
                                          ? "Mobile:"
                                          : "Alternate Mobile:"}{" "}
                                      </label>{" "}
                                    </td>{" "}
                                  </tr>{" "}
                                  <tr>
                                    <td className="py-0">
                                      <input
                                        className="form-control px-4"
                                        value={
                                          formField
                                            ? formField.contactInfo &&
                                              formField.contactInfo.length > 0
                                              ? formField.contactInfo[index]
                                                  .phone
                                              : ""
                                            : ""
                                        }
                                        disabled
                                      />
                                    </td>{" "}
                                  </tr>{" "}
                                </table>{" "}
                              </td>{" "}
                              <td verticalAlign="middle" className="py-0">
                                <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                  <tr>
                                    <td
                                      className="py-0 form-check mt-4 common-radio-deep-blue"
                                      verticalAlign="middle"
                                    >
                                      <Field
                                        name={`contactInfo[${index}].phone.whsstatus`}
                                        className="common-radio-deep-blue-input"
                                        id={"okay1" + index}
                                        type="radio"
                                        hidden="true"
                                        value="okay"
                                        required
                                        disabled={viewMood}
                                        checked={
                                          values.contactInfo
                                            ? values.contactInfo[index].phone
                                                .whsstatus === true
                                            : ""
                                        }
                                        onChange={() => {
                                          let a = `contactInfo[${index}].phone.whsstatus`;
                                          setFieldValue(a, true);
                                        }}
                                      />{" "}
                                      <label
                                        className="common-radio-deep-blue-label pl-sm-4"
                                        htmlFor={"okay1" + index}
                                      ></label>{" "}
                                    </td>{" "}
                                  </tr>{" "}
                                </table>{" "}
                              </td>{" "}
                              <td className="py-0">
                                <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                  <tr>
                                    <td
                                      className="py-0 form-check mt-4 common-radio-deep-blue"
                                      verticalAlign="middle"
                                    >
                                      <Field
                                        name={`contactInfo[${index}].phone.whsstatus`}
                                        className="common-radio-deep-blue-input"
                                        id={"not-okay1" + index}
                                        type="radio"
                                        value="notOkay"
                                        hidden="true"
                                        disabled={viewMood}
                                        required
                                        checked={
                                          values.contactInfo
                                            ? values.contactInfo[index].phone
                                                .whsstatus === false
                                            : ""
                                        }
                                        onChange={() => {
                                          let a = `contactInfo[${index}].phone.whsstatus`;
                                          setFieldValue(a, false);
                                        }}
                                      />{" "}
                                      <label
                                        className="common-radio-deep-blue-label pl-sm-4"
                                        htmlFor={"not-okay1" + index}
                                      ></label>{" "}
                                    </td>{" "}
                                  </tr>{" "}
                                </table>{" "}
                              </td>{" "}
                              <td className="pb-0">
                                <table class="table table-borderless form-group col-12 pt-xl-1">
                                  <tr>
                                    <td className="pb-0 ">
                                      <Field
                                        type="text"
                                        disabled={viewMood}
                                        name={`contactInfo[${index}].phone.whsremark`}
                                        className="form-control bg-white px-2 ml-xl-0 ml-2 mb-2"
                                        placeholder="Remarks"
                                      />
                                    </td>{" "}
                                  </tr>{" "}
                                </table>{" "}
                              </td>{" "}
                              <td className="pb-0 d-flex pt-4">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.warehouseContactDetailRemark &&
                                  formField.warehouseContactDetailRemark
                                    .contactInfo.length > 0 &&
                                  formField.warehouseContactDetailRemark
                                    .contactInfo[index].phone.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.warehouseContactDetailRemark &&
                                    formField.warehouseContactDetailRemark
                                      .contactInfo.length > 0 &&
                                    formField.warehouseContactDetailRemark
                                      .contactInfo[index].phone.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </tr>{" "}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </>
                  ))}{" "}
                </div>
                <div className="">
                  {" "}
                  {values.contactInfo.map((value, index) => (
                    <>
                      <div className="col-xl-10">
                        <table class="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th scope="col" class="col-6 py-0">
                                {" "}
                              </th>{" "}
                              <th scope="col" className="py-0">
                                {" "}
                              </th>{" "}
                              <th scope="col" className="py-0">
                                {" "}
                              </th>{" "}
                              <th scope="col" className="text-center py-0">
                                {" "}
                              </th>{" "}
                              <th scope="col" className="text-center py-0">
                                {" "}
                              </th>{" "}
                            </tr>{" "}
                          </thead>{" "}
                          <tbody>
                            <tr>
                              <td className="py-0">
                                <table class="table table-borderless form-group col-12 pt-xl-1">
                                  <tr>
                                    <td className="py-0">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        {index === 0
                                          ? "Email:"
                                          : "Alternate Email:"}{" "}
                                      </label>{" "}
                                    </td>{" "}
                                  </tr>{" "}
                                  <tr>
                                    <td className="py-0">
                                      <input
                                        className="form-control px-4"
                                        value={
                                          formField
                                            ? formField.contactInfo &&
                                              formField.contactInfo.length > 0
                                              ? formField.contactInfo[index]
                                                  .email
                                              : ""
                                            : ""
                                        }
                                        disabled
                                      />
                                    </td>{" "}
                                  </tr>{" "}
                                </table>{" "}
                              </td>{" "}
                              <td verticalAlign="middle" className="py-0">
                                <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                  <tr>
                                    <td
                                      className="py-0 form-check mt-4 common-radio-deep-blue"
                                      verticalAlign="middle"
                                    >
                                      <Field
                                        name={`contactInfo[${index}].phone.whsstatus`}
                                        className="common-radio-deep-blue-input"
                                        id={"okay1" + index}
                                        type="radio"
                                        hidden="true"
                                        value="okay"
                                        required
                                        disabled={viewMood}
                                        checked={
                                          values.contactInfo
                                            ? values.contactInfo[index].phone
                                                .whsstatus === true
                                            : ""
                                        }
                                        onChange={() => {
                                          let a = `contactInfo[${index}].phone.whsstatus`;
                                          setFieldValue(a, true);
                                        }}
                                      />{" "}
                                      <label
                                        className="common-radio-deep-blue-label pl-sm-4"
                                        htmlFor={"okay1" + index}
                                      ></label>{" "}
                                    </td>{" "}
                                  </tr>{" "}
                                </table>{" "}
                              </td>{" "}
                              <td className="py-0">
                                <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                  <tr>
                                    <td
                                      className="py-0 form-check mt-4 common-radio-deep-blue"
                                      verticalAlign="middle"
                                    >
                                      <Field
                                        name={`contactInfo[${index}].email.whsstatus`}
                                        className="common-radio-deep-blue-input"
                                        id={"not-okay11" + index}
                                        type="radio"
                                        value="notOkay"
                                        required
                                        hidden="true"
                                        disabled={viewMood}
                                        checked={
                                          values.contactInfo
                                            ? values.contactInfo[index].email
                                                .whsstatus === false
                                            : ""
                                        }
                                        onChange={() => {
                                          let a = `contactInfo[${index}].email.whsstatus`;
                                          setFieldValue(a, false);
                                        }}
                                      />{" "}
                                      <label
                                        className="common-radio-deep-blue-label pl-sm-4"
                                        htmlFor={"not-okay11" + index}
                                      ></label>{" "}
                                    </td>{" "}
                                  </tr>{" "}
                                </table>{" "}
                              </td>{" "}
                              <td className="pb-0">
                                <table class="table table-borderless form-group col-12 pt-xl-1">
                                  <tr>
                                    <td className="pb-0 ">
                                      <Field
                                        type="text"
                                        disabled={viewMood}
                                        name={`contactInfo[${index}].phone.whsremark`}
                                        className="form-control bg-white px-2 ml-xl-0 ml-2 mb-2"
                                        placeholder="Remarks"
                                      />
                                    </td>{" "}
                                  </tr>{" "}
                                </table>{" "}
                              </td>{" "}
                              <td className="pb-0 d-flex pt-4">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.warehouseContactDetailRemark &&
                                  formField.warehouseContactDetailRemark
                                    .contactInfo.length > 0 &&
                                  formField.warehouseContactDetailRemark
                                    .contactInfo[index].email.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.warehouseContactDetailRemark &&
                                    formField.warehouseContactDetailRemark
                                      .contactInfo.length > 0 &&
                                    formField.warehouseContactDetailRemark
                                      .contactInfo[index].email.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </tr>{" "}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </>
                  ))}{" "}
                </div>
                <div className="">
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    Address 1:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.line1
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.line1.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="categorysOkay"
                                    type="radio"
                                    value="okay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.line1
                                        ? values.address.line1.whsstatus ===
                                          true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.line1.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="categorysOkay"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.line1.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="categorysNotOkay"
                                    type="radio"
                                    value="notOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.line1
                                        ? values.address.line1.whsstatus ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.line1.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="categorysNotOkay"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.line1.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .line1.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .addressLine2.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    Address 2:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.line2
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.addressLine2.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="categoryNotOkays"
                                    type="radio"
                                    value="okay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.addressLine2
                                        ? values.address.addressLine2
                                            .whsstatus === true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.addressLine2.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="categoryNotOkays"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.addressLine2.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="categoryNotOkayss"
                                    type="radio"
                                    value="NotOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.addressLine2
                                        ? values.address.addressLine2
                                            .whsstatus === false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.addressLine2.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="categoryNotOkayss"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.addressLine2.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .addressLine2.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .addressLine2.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    State:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.state
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.state.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="dsssssssssff"
                                    type="radio"
                                    value="okay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.state
                                        ? values.address.state.whsstatus ===
                                          true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.state.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="dsssssssssff"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.state.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="dsssssssssnn"
                                    type="radio"
                                    value="notOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.state
                                        ? values.address.state.whsstatus ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.state.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="dsssssssssnn"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.addressLine2.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .state.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .state.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    District:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.district
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.district.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="uudsssssssssff"
                                    type="radio"
                                    hidden="true"
                                    value="okay"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.district
                                        ? values.address.district.whsstatus ===
                                          true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.district.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="uudsssssssssff"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.district.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="dsssssssssnnuu"
                                    type="radio"
                                    value="notOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.district
                                        ? values.address.district.whsstatus ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.district.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="dsssssssssnnuu"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.district.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .district.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .district.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    Country:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.country
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.country.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="dsssssssss"
                                    type="radio"
                                    value="okay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.country
                                        ? values.address.country.whsstatus ===
                                          true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.country.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="dsssssssss"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.country.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="dsssssssssxd"
                                    type="radio"
                                    value="notOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.country
                                        ? values.address.country.whsstatus ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.country.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="dsssssssssxd"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.country.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .country.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .country.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>{" "}
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    City:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.city
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.city.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="ccdsssssssssff"
                                    type="radio"
                                    value="okay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.city
                                        ? values.address.city.whsstatus === true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.city.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="ccdsssssssssff"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.country.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="dsssssssssxd"
                                    type="radio"
                                    value="notOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.country
                                        ? values.address.country.whsstatus ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.country.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="dsssssssssxd"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.country.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .country.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .country.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    Landmark:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.landmark
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.landmark.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="dsssssssssll"
                                    type="radio"
                                    value="okay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.landmark
                                        ? values.address.landmark.whsstatus ===
                                          true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.landmark.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="dsssssssssll"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.landmark.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="dsssssssssllsl"
                                    type="radio"
                                    value="okay"
                                    required
                                    disabled={viewMood}
                                    hidden="true"
                                    checked={
                                      values.address.landmark
                                        ? values.address.landmark.whsstatus ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.landmark.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="dsssssssssllsl"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    name={`address.landmark.whsremark`}
                                    type="text"
                                    disabled={viewMood}
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .landmark.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .landmark.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    Pincode:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.pinCode
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.pinCode.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="pdsssssssssllsl"
                                    type="radio"
                                    value="okay"
                                    hideen="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.pinCode
                                        ? values.address.pinCode.whsstatus ===
                                          true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.pinCode.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="pdsssssssssllsl"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.pinCode.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="ppdsssssssssllsl"
                                    type="radio"
                                    value="notOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.pinCode
                                        ? values.address.pinCode.whsstatus ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.pinCode.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="ppdsssssssssllsl"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.pinCode.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .pinCode.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .pinCode.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    Warehouse GPS Location-latitude:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.latitude
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.latitude.status"
                                    className="common-radio-deep-blue-input"
                                    id="ltdsssssssssllsl"
                                    type="radio"
                                    hidden="true"
                                    value="okay"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.latitude
                                        ? values.address.latitude.status ===
                                          true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.latitude.status",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="ltdsssssssssllsl"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.latitude.status"
                                    className="common-radio-deep-blue-input"
                                    id="xxdsssssssssllsl"
                                    type="radio"
                                    value="notOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.latitude
                                        ? values.address.latitude.status ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.latitude.status",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="xxdsssssssssllsl"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.pinCode.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .latitude.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .latitude.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>
                  <div className="col-xl-10">
                    <table class="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="col-6 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            {" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="py-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    Warehouse GPS Location-longitude:
                                  </label>{" "}
                                </td>{" "}
                              </tr>{" "}
                              <tr>
                                <td className="py-0">
                                  <input
                                    className="form-control px-4"
                                    value={
                                      formField && formField.address
                                        ? formField.address.longnitude
                                        : ""
                                    }
                                    disabled
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.longnitude.status"
                                    className="common-radio-deep-blue-input"
                                    id="yydsssssssssllsl"
                                    type="radio"
                                    value="okay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.longnitude
                                        ? values.address.longnitude.status ===
                                          true
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.longnitude.status",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="yydsssssssssllsl"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0">
                            <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                              <tr>
                                <td
                                  className="py-0 form-check mt-4 common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="address.longnitude.status"
                                    className="common-radio-deep-blue-input"
                                    id="yyydsssssssssllsl"
                                    type="radio"
                                    value="notOkay"
                                    hidden="true"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.address.longnitude
                                        ? values.address.longnitude.status ===
                                          false
                                        : ""
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "address.longnitude.status",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="yyydsssssssssllsl"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 ">
                                  <Field
                                    disabled={viewMood}
                                    name={`address.longnitude.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-3 ml-xl-0 ml-2 mb-2"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0 d-flex pt-4">
                            <p className="py-2 text-nowrap pr-4">
                              {" "}
                              {formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .longnitude.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                            <input
                              value={
                                formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .address &&
                                formField.warehouseContactDetailRemark.address
                                  .longnitude.faremark
                              }
                              type="text"
                              className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                              placeholder="Remarks"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>{" "}
                </div>
                <div className="col-12">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "200px",
                      }}
                    >
                      {" "}
                      {formField &&
                      formField.address &&
                      formField.address.latitude ? (
                        <ShowMap
                          lat={parseFloat(formField.address.latitude)}
                          lng={parseFloat(formField.address.longnitude)}
                          latName="gpsLatitude"
                          lngName="gpsLongitude"
                        />
                      ) : null}{" "}
                      {/* <iframe title="GPS" width="100%" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=escale%20solution+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className={`col-12 mt-4`}>
                  {" "}
                  {/* <p>{errors?errors['warehouseName.status']:""}</p> */}
                  <div className="row justify-content-end"> </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default ContactPendingForm;
