import {
    BOOKING,
    BOOKING_BY_ID,
    BOOKING_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

import {
    GET_BOOKING_URL,
    BOOKING_BY_ID_URL,
    UPDATE_BOOKING_URL
} from '../../services/urls';



import axiosauth from '../../services/axios-auth';


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}


export function bookingList(data) {
    return {
        type: BOOKING,
        payload: data
    }
}

export function booking_By_Id(data) {
    return {
        type: BOOKING_BY_ID,
        payload: data
    }
}


export function responseBooking(data) {
    return {
        type: BOOKING_RESPONSE,
        payload: data
    }
}


// ###########  Fecth All bookingList ########

export const bookingByPage = (page, data, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        let limit = 10;
        try {
            axiosauth.post(GET_BOOKING_URL + `?page=${page}&limit=${limit}&search=${search}`, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(bookingList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("BOOKING Fail")
                }
            }).catch((error) => {
                dispatch(isLoading(false))
                dispatch(isError(error.message))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  Fecth  category Detail By Id ########

export const bookingById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(BOOKING_BY_ID_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(booking_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("booking_By_Id Fail")
                }
            }).catch((error) => {
                dispatch(isLoading(false))
                dispatch(isError(error.message))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}

// ###########  Fecth  Booking Detail withoutloading By Id ########

export const bookingByIdWithoutLoading = (id) => {
    return async (dispatch) => {
        try {
            axiosauth.get(BOOKING_BY_ID_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(booking_By_Id(res.data))
                } else {
                    console.log("booking_By_Id Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
            }).then(() => {
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}



// ###########  UPDATE UPDATE_BOOKING_URL  ############

export const updateBooking = (id, data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.put(UPDATE_BOOKING_URL + `/${id}`, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(responseBooking(res))
                    dispatch(bookingByIdWithoutLoading(id))
                    dispatch(isPending(false))
                } else {
                    dispatch(isPending(false))
                    console.log("responseBooking Details Fail")
                }
            }).catch((error) => {
                dispatch(isPending(false))
                dispatch(isError(error.message))
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}