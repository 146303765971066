import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import FormSuccess from '../../../components/common/FormSuccess';
import {
  changeWarehouseStatus1,
  updateWarehousependingDetails,
} from './../../../store/action/warehouseAction';
import CustomLoader from '../../../CustomLoader';
import Spinner from 'react-bootstrap/Spinner';
const SafetyPendingForm = ({ warehouseId, read, slug }) => {
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const onSubmit = (fields) => {
    const payload = {
      data: {
        fields: fields,
      },
      warehouseId: warehouseId,
      slug: slug,
    };
    dispatch(updateWarehousependingDetails(payload));
  };
  const [formField, setFormField] = useState(null);
  const [remarkForm, setRemarkForm] = useState({
    fireExtinguishers: [
      {
        type: '',
        quantity: '',
        fireExtinguisher: {
          validFrom: '',
          validTill: '',
        },
        whsremark: '',
      },
      {
        type: '',
        quantity: '',
        fireExtinguisher: {
          validFrom: '',
          validTill: '',
        },
        whsremark: '',
      },
      {
        type: '',
        quantity: '',
        fireExtinguisher: {
          validFrom: '',
          validTill: '',
        },
        whsremark: '',
      },
    ],
    fireSprinklers: {
      value: '',
      whsremark: '',
    },
    fireHydrant: {
      value: '',
      whsremark: '',
    },
    cctv: {
      value: '',
      whsremark: '',
    },
    waterTank: {
      value: '',
      whsremark: '',
    },
    smokeDetectorsAndFireAlarm: {
      value: '',
      whsremark: '',
    },
    securityGuard: {
      value: '',
      whsremark: '',
    },
    metalDetector: {
      value: '',
      whsremark: '',
    },
    pestControl: {
      value: '',
      whsremark: '',
    },
    safetySecurityOther: {
      value: '',
      whsremark: '',
    },
    fireABCQty: '',
    fireCO2Qty: '',
    fireOtherQty: '',
    waterQty: '',
    others: '',
  });

  useEffect(() => {
    console.log(data?.singleFormData, '**warehouseRes**');
    // if (
    //   data?.singleFormData?.warehouseRes?.warehouseSecurities !== null &&
    //   data?.singleFormData?.warehouseRes?.warehouseSecurities !== undefined
    // ) {
    //   setRemarkForm({
    //     fireExtinguishers: [
    //       {
    //         type: 'code',
    //         quantity:
    //           data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[0]
    //             ?.quantity,
    //         fireExtinguisher: {
    //           validFrom:
    //             data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[0]
    //               ?.fireExtinguisher.validTill,
    //           validTill:
    //             data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[0]
    //               ?.fireExtinguisher.validTill,
    //           whsremark: '',
    //         },
    //       },
    //       {
    //         type: 'co2',
    //         quantity:
    //           data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[1]
    //             .quantity,
    //         fireExtinguisher: {
    //           validFrom:
    //             data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[1]
    //               .fireExtinguisher.validTill,
    //           validTill:
    //             data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[1]
    //               .fireExtinguisher.validTill,
    //         },
    //       },
    //       {
    //         type: 'h20',
    //         quantity:
    //           data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[2]
    //             .quantity,
    //         fireExtinguisher: {
    //           validFrom:
    //             data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[2]
    //               .fireExtinguisher.validFrom,
    //           validTill:
    //             data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[2]
    //               .fireExtinguisher.validTill,
    //         },
    //       },
    //     ],
    //     fireSprinklers: {
    //       value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].fireSprinklers,
    //       whsremark:
    //         data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.fireSprinklers?.whsremark,
    //     },
    //     fireHydrant: {
    //       value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].fireHydrant,
    //       whsremark:
    //         data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.fireHydrant?.whsremark,
    //     },
    //     cctv: {
    //       value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].cctv,
    //       whsremark: data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.cctv?.whsremark,
    //     },
    //     waterTank: {
    //       value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].waterTank.quantity,
    //       whsremark:
    //         data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.waterTank?.whsremark,
    //     },
    //     smokeDetectorsAndFireAlarm: {
    //       value:
    //         data?.singleFormData?.warehouseRes?.warehouseSecurities[0].smokeDetectorsAndFireAlarm,
    //       whsremark:
    //         data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.smokeDetectorsAndFireAlarm
    //           ?.whsremark,
    //     },
    //     securityGuard: {
    //       value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].securityGuard,
    //       whsremark:
    //         data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.securityGuard?.whsremark,
    //     },
    //     metalDetector: {
    //       value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].metalDetector,
    //       whsremark:
    //         data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.metalDetector?.whsremark,
    //     },
    //     pestControl: {
    //       value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].pestControl,
    //       whsremark:
    //         data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.pestControl?.whsremark,
    //     },
    //     safetySecurityOther: {
    //       value: '',
    //       whsremark:
    //         data?.singleFormData?.remarks?.warehouseSecuritiesRemarks?.safetySecurityOther
    //           ?.whsremark,
    //     },
    //     fireABCQty:
    //       data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[0]
    //         ?.quantity,
    //     fireCO2Qty:
    //       data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[1].quantity,
    //     fireOtherQty:
    //       data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[2].quantity,
    //     waterQty: data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.waterTank?.quantity,
    //     others: data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.others,
    //   });
    // }
    if (
      data?.singleFormData &&
      data?.singleFormData !== null &&
      data?.singleFormData?.remarks !== undefined &&
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks.warehouseSecuritiesRemarks !== undefined &&
      Object.keys(data?.singleFormData?.remarks.warehouseSecuritiesRemarks).length > 0
    ) {
      console.log(
        data?.singleFormData?.remarks?.warehouseSecuritiesRemarks,
        '||warehouseSecurities||',
      );
      setRemarkForm(data?.singleFormData?.remarks?.warehouseSecuritiesRemarks);
    } else {
      if (
        data?.singleFormData &&
        data?.singleFormData !== null &&
        data?.singleFormData?.warehouseRes !== undefined &&
        data?.singleFormData?.warehouseRes !== null &&
        data?.singleFormData?.warehouseRes?.warehouseSecurities !== undefined &&
        data?.singleFormData?.warehouseRes?.warehouseSecurities !== null
      ) {
        if (Object.keys(data?.singleFormData?.warehouseRes?.warehouseSecurities)) {
          console.log(
            data?.singleFormData?.warehouseRes?.warehouseSecurities,
            '||warehouseSecurities||',
          );
          setRemarkForm({
            fireExtinguishers: [
              {
                type: 'code',
                quantity:
                  data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[0]
                    ?.quantity,
                fireExtinguisher: {
                  validFrom:
                    data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[0]
                      ?.fireExtinguisher.validTill,
                  validTill:
                    data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[0]
                      ?.fireExtinguisher.validTill,
                },
              },
              {
                type: 'co2',
                quantity:
                  data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[1]
                    .quantity,
                fireExtinguisher: {
                  validFrom:
                    data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[1]
                      .fireExtinguisher.validTill,
                  validTill:
                    data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[1]
                      .fireExtinguisher.validTill,
                },
              },
              {
                type: 'h20',
                quantity:
                  data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[2]
                    .quantity,
                fireExtinguisher: {
                  validFrom:
                    data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[2]
                      .fireExtinguisher.validFrom,
                  validTill:
                    data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[2]
                      .fireExtinguisher.validTill,
                },
              },
            ],

            fireSprinklers: {
              value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].fireSprinklers,
              whsremark: '',
            },
            fireHydrant: {
              value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].fireHydrant,
              whsremark: '',
            },
            cctv: {
              value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].cctv,
              whsremark: '',
            },
            waterTank: {
              value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].waterTank.quantity,
              whsremark: '',
            },
            smokeDetectorsAndFireAlarm: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseSecurities[0]
                  .smokeDetectorsAndFireAlarm,
              whsremark: '',
            },
            securityGuard: {
              value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].securityGuard,
              whsremark: '',
            },
            metalDetector: {
              value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].metalDetector,
              whsremark: '',
            },
            pestControl: {
              value: data?.singleFormData?.warehouseRes?.warehouseSecurities[0].pestControl,
              whsremark: '',
            },
            safetySecurityOther: {
              value: '',
              whsremark: '',
            },
            fireABCQty:
              data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[0]
                ?.quantity,
            fireCO2Qty:
              data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[1]
                .quantity,
            fireOtherQty:
              data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.fireExtinguishers[2]
                .quantity,
            waterQty:
              data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.waterTank?.quantity,
            others: data?.singleFormData?.warehouseRes?.warehouseSecurities[0]?.others,
          });
        }
      }
    }
  }, [data.singleFormData, data.type]);

  const handleNextPage = () => {
    if (data?.addNewResponse && data?.addNewResponse?.statusCode === 200) {
      history.push(`/pending-details/${warehouseId}/permits`);
      dispatch(changeWarehouseStatus1());
    }
  };

  return (
    <>
      {/* {data?.addNewResponse && data?.addNewResponse?.statusCode === 200 ? (
        <FormSuccess onClick={handleNextPage} message={data.addNewResponse.message} />
      ) : null}{' '} */}
      {data.isLoading === true ? (
        <CustomLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={remarkForm}
          // validate={validate(validationSchema)}
          onSubmit={(fields) => onSubmit(fields)}
          render={({ errors, status, onChange, touched, values, setFieldValue }) => {
            return (
              <Form className='w-100'>
                <div className='col-12 rounded-lg px-md-4'>
                  <div className='row'>
                    <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col' className='text-center'>
                                Remark{' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table className='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Fire Extinguisher(ABC):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p>
                                        <td className='pb-4'>
                                          <Field
                                            disabled={false}
                                            name={`fireABCQty`}
                                            type='text'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='quantity'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid from{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`fireExtinguishers[0].fireExtinguisher.validFrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='validFrom'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid till{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`fireExtinguishers[0].fireExtinguisher.validTill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`fireExtinguishers[0].fireExtinguisher.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                      <div className='col-xl-12'>
                        <table className='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table className='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Fire Extinguisher(CO2):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p>
                                        <td className='pb-4'>
                                          <Field
                                            disabled={false}
                                            name={`fireCO2Qty`}
                                            type='text'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='quantity'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid from{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`fireExtinguishers[1].fireExtinguisher.validFrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='validFrom'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid till{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`fireExtinguishers[1].fireExtinguisher.validTill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='validTill'
                                          />
                                        </td>{' '}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`fireExtinguishers[1].fireExtinguisher.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table className='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Fire Extinguisher(Others):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p>
                                        <td className='pb-4'>
                                          <Field
                                            disabled={false}
                                            name={`fireOtherQty`}
                                            type='text'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='quantity'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid from{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`fireExtinguishers[2].fireExtinguisher.validFrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='validFrom'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid till{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`fireExtinguishers[2].fireExtinguisher.validTill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='validTill'
                                          />
                                        </td>{' '}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`fireExtinguishers[2].fireExtinguisher.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Fire Sprinklers:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray px-2 mb-1'>
                                        {' '}
                                        {formField ? formField.fireSprinklers : ''}{' '}
                                      </p>{' '}
                                      <p className='px-2 mb-1'> Qty: </p>{' '}
                                      <Field
                                        disabled={false}
                                        name={`fireSprinklers.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`fireSprinklers.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Fire Hydrant:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray px-2 mb-1'>
                                        {' '}
                                        {formField ? formField.fireHydrant : ''}{' '}
                                      </p>{' '}
                                      <p className='px-2 mb-1'> Qty: </p>{' '}
                                      <Field
                                        disabled={false}
                                        name={`fireHydrant.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`fireHydrant.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        CCTV:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray px-2 mb-1'>
                                        {' '}
                                        {formField ? formField.cctv : ''}{' '}
                                      </p>{' '}
                                      <Field
                                        disabled={false}
                                        name={`cctv.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`cctv.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Water Tank:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <Field
                                        disabled={read}
                                        name={`waterQty`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`waterTank.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Smoke Detectors / Fire Alarm:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <Field
                                        disabled={false}
                                        name={`smokeDetectorsAndFireAlarm.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`smokeDetectorsAndFireAlarm.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Security Guard:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      {/* <p className="px-2 mb-1">Qty:</p> */}{' '}
                                      <Field
                                        disabled={false}
                                        name={`securityGuard.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`securityGuard.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Metal Detector:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <Field
                                        disabled={false}
                                        name={`metalDetector.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`metalDetector.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Pest Control:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray px-2 mb-1'>
                                        {' '}
                                        {formField
                                          ? formField.pestControl
                                            ? 'Yes'
                                            : 'No'
                                          : ''}{' '}
                                      </p>{' '}
                                      <td className='pb-4 w-100'>
                                        <label
                                          htmlFor=''
                                          className='mb-0 px-4 small text-uppercase'
                                        >
                                          Select Last Date of Pest Control{' '}
                                        </label>{' '}
                                        <Field
                                          disabled={false}
                                          name={`pestControl.value`}
                                          type='date'
                                          className='form-control bg-white px-4 mb-2 mr-4'
                                          placeholder='value'
                                        />
                                      </td>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`pestControl.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Others:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <td className='pb-4 w-100'>
                                        <Field
                                          disabled={false}
                                          name={`others`}
                                          type='text'
                                          className='form-control bg-white px-4 mb-2 mr-4'
                                          placeholder='value'
                                        />{' '}
                                      </td>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`safetySecurityOther.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>{' '}
                <div className={`row justify-content-end ${!read ? '' : 'd-none'}`}>
                  <div className='col-auto '>
                    {Object.keys(touched).length > 0 ? (
                      <button
                        type='submit'
                        className='btn btn-deep-blue add-class remove-class '
                        disabled={data.isPending}
                      >
                        Save {data.isPending ? <Spinner animation='border' /> : null}{' '}
                      </button>
                    ) : (
                      ''
                    )}{' '}
                  </div>{' '}
                </div>{' '}
              </Form>
            );
          }}
        />
      )}
    </>
  );
};

export default SafetyPendingForm;
