import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { serviceRecieveById } from "../../store/action/serviceAction";
import axios from "axios";

const ServiceReceiveDetail = () => {
  const history = useHistory();
  const { serviceReceiveId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.SERVICE_INFO);
  console.log("view service==>", data.ServiceReceiveDetail);

  useEffect(() => {
    dispatch(serviceRecieveById(serviceReceiveId));
  }, [dispatch, serviceReceiveId]);

  // Download excel with service and warehouse id
  const downloadAs = (url, name) => {
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <LayoutOne>
      <div className="row align-items-center">
        <div className="col-12 py-3">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            >
              {" "}
            </i>
            Service Request Details
          </h5>
        </div>{" "}
      </div>{" "}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12">
          <div className="row mx-0">
            <div className="col-12 bg-white rounded">
              {data.serviceDetail ? (
                <div className="card card-body border-0">
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Rfq ID </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceRecieveDetail?.id}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Service ID </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceRecieveDetail?.service?.id}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Service Name </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceRecieveDetail?.service?.name}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Customer Name </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceRecieveDetail?.user?.firstName +
                        " " +
                        data.serviceRecieveDetail?.user?.lastName}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Warehouse Name </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceRecieveDetail?.warehouse?.warehouseName}{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Warehouse Id </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceRecieveDetail?.warehouse?.id}{" "}
                    </div>{" "}
                  </div>
                  <div className="d-flex">
                    <div className="mw-200px px-3 py-2"> Status </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {data.serviceRecieveDetail?.status}{" "}
                    </div>{" "}
                  </div>
                  <div className="row">
                    <div className="col-12 my-5">
                      <button
                        onClick={() =>
                          downloadAs(
                            data.serviceRecieveDetail.file,
                            `service_${data.serviceRecieveDetail?.service?.id}warehouse_${data.serviceRecieveDetail?.warehouse?.id}`
                          )
                        }
                        className="btn btn-outline-deep-blue"
                      >
                        {" "}
                        Download Rfq Excel{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>
                </div>
              ) : null}{" "}
            </div>{" "}
          </div>{" "}
          <div className="row">
            <div className="col-12 my-5">
              <button
                onClick={() => history.goBack()}
                className="btn btn-outline-deep-blue"
              >
                {" "}
                Back{" "}
              </button>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default ServiceReceiveDetail;
