import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateStorageSpace,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";

const SpacePendingForm = ({ warehouseId, viewMood }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const validationSchema = function (values) {
    return Yup.object().shape({
      storageType: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      totalArea: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      palletsOnFloor: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      racks: Yup.array().of(
        Yup.object().shape({
          rack: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      availableSpace: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      loadingAndUnloadingBays: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      dockSize: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      parkingArea: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        storageSpaceRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateStorageSpace(payload));
  };
  const [formField, setFormField] = useState(null);
  const [remarkForm, setRemarkForm] = useState({
    storageType: {
      value: "",
      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    totalArea: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    noOfShift: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    palletsOnFloor: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    racks: [
      {
        rackName: "bins-up",
        rack: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        rackName: "shelveup",
        rack: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    availableSpace: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    loadingAndUnloadingBays: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    dockSize: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    parkingArea: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });
  const [ground, setground] = useState("");
  const [basement, setbasement] = useState("");
  const [first, setfirst] = useState("");
  const [second, setsecond] = useState("");

  const [ground1, setground1] = useState("");
  const [basement1, setbasement1] = useState("");
  const [first1, setfirst1] = useState("");
  const [second1, setsecond1] = useState("");
  useEffect(() => {
    if (data.singleFormData && data.type === "storageSpace") {
      setFormField(data.singleFormData.storageSpaceInfo);
      let spaceInfo = data.singleFormData.storageSpaceInfo;
      let gFloor = spaceInfo.floors.find((o) => o.floorType === "groundFloor");
      let bFloor = spaceInfo.floors.find((o) => o.floorType === "basement");
      let fFloor = spaceInfo.floors.find((o) => o.floorType === "firstFloor");
      let sFloor = spaceInfo.floors.find((o) => o.floorType === "secondFloor");
      setground(gFloor);
      setbasement(bFloor);
      setfirst(fFloor);
      setsecond(sFloor);
      //availablespace
      let gFloor1 = spaceInfo.availableSpaces.find(
        (o) => o.availableSpaceType === "groundFloor"
      );
      let bFloor1 = spaceInfo.availableSpaces.find(
        (o) => o.availableSpaceType === "basement"
      );
      let fFloor1 = spaceInfo.availableSpaces.find(
        (o) => o.availableSpaceType === "firstFloor"
      );
      let sFloor1 = spaceInfo.availableSpaces.find(
        (o) => o.availableSpaceType === "secondFloor"
      );

      setground1(gFloor1);
      setbasement1(bFloor1);
      setfirst1(fFloor1);
      setsecond1(sFloor1);
      if (data.singleFormData.storageSpaceRemark) {
        setRemarkForm({
          storageType: {
            value: "",
            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.faremark
              : "",
          },
          noOfShift: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.noOfShift.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.noOfShift.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.noOfShift.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.noOfShift.faremark
              : "",
          },
          totalArea: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.faremark
              : "",
          },
          palletsOnFloor: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.faremark
              : "",
          },
          racks: [
            {
              rackName: "bins-up",
              rack: {
                value: "",

                whsstatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .faremark
                    : ""
                  : "",
              },
            },
            {
              rackName: "shelveup",
              rack: {
                value: "",

                whsstatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .faremark
                    : ""
                  : "",
              },
            },
          ],
          availableSpace: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.faremark
              : "",
          },
          loadingAndUnloadingBays: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .faremark
              : "",
          },
          dockSize: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.faremark
              : "",
          },
          parkingArea: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.faremark
              : "",
          },
        });
      } else {
        setRemarkForm({
          storageType: {
            value: "",
            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.faremark
              : "",
          },
          totalArea: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.faremark
              : "",
          },
          palletsOnFloor: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.faremark
              : "",
          },
          racks: [
            {
              rackName: "bins-up",
              rack: {
                value: "",

                whsstatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .faremark
                    : ""
                  : "",
              },
            },
            {
              rackName: "shelveup",
              rack: {
                value: "",

                whsstatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .faremark
                    : ""
                  : "",
              },
            },
          ],
          availableSpace: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.faremark
              : "",
          },
          loadingAndUnloadingBays: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .faremark
              : "",
          },
          dockSize: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.faremark
              : "",
          },
          parkingArea: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.faremark
              : "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-12  rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="w-100px">
                            <label htmlFor="staticEmail" className="mb-2 px-0">
                              Storage Type:
                            </label>{" "}
                          </td>{" "}
                          <td className="mw-150px form-inline form-group py-3 mb-0 pr-0">
                            <input
                              className="form-control px-1"
                              value={formField ? formField.storageType : ""}
                              disabled
                            />
                          </td>{" "}
                          <td className="col-auto" colSpan="1">
                            {" "}
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="storageType.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.storageType.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("storageType.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="storageType.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.storageType.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("storageType.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`storageType.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.storageType
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.storageType
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-160px">
                            <p className="mb-3">
                              {" "}
                              Floor: <sup className="text-danger"> * </sup>
                            </p>
                          </td>{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {/* <td className="col-auto"></td> */}
                          {basement && basement.floorDimension.length !== "" ? (
                            <td className="w-10px">
                              {" "}
                              <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                <label
                                  className=" pl-4 mr-3"
                                  htmlFor="defaultCheck1"
                                >
                                  {" "}
                                  Basement{" "}
                                </label>{" "}
                              </div>{" "}
                            </td>
                          ) : null}
                          {ground && ground.floorDimension.length !== "" ? (
                            <td className="w-10px">
                              {" "}
                              <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                <label
                                  className=" pl-4 mr-3"
                                  htmlFor="defaultCheck1"
                                >
                                  {" "}
                                  Ground Floor{" "}
                                </label>{" "}
                              </div>{" "}
                            </td>
                          ) : null}
                          {first && first.floorDimension.length !== "" ? (
                            <td className="w-10px">
                              <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                <label
                                  className=" pl-4 mr-3"
                                  htmlFor="defaultCheck1"
                                >
                                  {" "}
                                  1 st Floor{" "}
                                </label>{" "}
                              </div>{" "}
                            </td>
                          ) : null}{" "}
                          {second && second.floorDimension.length !== "" ? (
                            <td className="w-10px">
                              <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                <label
                                  className=" pl-4 mr-3"
                                  htmlFor="defaultCheck1"
                                >
                                  {" "}
                                  2 nd Floor{" "}
                                </label>{" "}
                              </div>{" "}
                            </td>
                          ) : null}{" "}
                          {/* <td colSpan="2"></td> */}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-10px"></td>{" "}
                          {/* <td className="col-auto">
                                                                                  
                                                                                </td> */}{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {basement && basement.floorDimension.length !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Length{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  basement && basement.floorDimension.length
                                }
                                placeholder="Length"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {ground && ground.floorDimension.length !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Length{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={ground && ground.floorDimension.length}
                                placeholder="Length"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {first && first.floorDimension.length !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Length{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={first && first.floorDimension.length}
                                placeholder="Length"
                                disabled
                              />
                            </td>
                          ) : null}
                          {second && second.floorDimension.length !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Length{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={second && second.floorDimension.length}
                                placeholder="Length"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-10px"></td>{" "}
                          {/* <td className="col-auto"> 
                                                                                            </td> */}{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {basement && basement.floorDimension.breath !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Breadth{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  basement && basement.floorDimension.breath
                                }
                                placeholder="Breadth"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {ground && ground.floorDimension.breath !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Breadth{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={ground && ground.floorDimension.breath}
                                placeholder="Breadth"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {first && first.floorDimension.breath !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Breadth{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={first && first.floorDimension.breath}
                                placeholder="Breadth"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {second && second.floorDimension.breath !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Breadth{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={second && second.floorDimension.breath}
                                placeholder="Breadth"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-10px"></td>{" "}
                          {/* <td className="col-auto"> 
                                                                                            </td> */}{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {basement && basement.floorDimension.height !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Height{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  basement && basement.floorDimension.height
                                }
                                placeholder="Height"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {ground && ground.floorDimension.height !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Height{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  ground && ground.floorDimension.height !== ""
                                }
                                placeholder="Height"
                                disabled
                              />
                            </td>
                          ) : null}
                          {first && first.floorDimension.height !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Height{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={first && first.floorDimension.height}
                                placeholder="Height"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {second && second.floorDimension.height !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Height{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={second && second.floorDimension.height}
                                placeholder="Height"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                        </tr>{" "}
                        {formField !== null ? (
                          <p className="mb-0 mr-3">
                            {" "}
                            Total Area(
                            {formField &&
                            formField.floors &&
                            formField.floors.length > 0
                              ? formField.floors[0].unit === "FEET"
                                ? "sqft"
                                : ""
                              : ""}{" "}
                            {formField.floors
                              ? formField.floors[0].unit === "INCH"
                                ? "sqin"
                                : ""
                              : ""}{" "}
                            {formField.floors
                              ? formField.floors[0].unit === "M"
                                ? "sqm"
                                : ""
                              : ""}
                            ):{" "}
                          </p>
                        ) : null}{" "}
                        <tr>
                          {" "}
                          {/* <td className="col-auto"> */}{" "}
                          <td className="col-auto">
                            <input
                              type="text"
                              className="form-control px-4"
                              value={
                                formField &&
                                formField.floors &&
                                formField.floors.length > 0
                                  ? formField.floors[0].floorDimension.length *
                                      formField.floors[0].floorDimension
                                        .breath +
                                    formField.floors[1].floorDimension.length *
                                      formField.floors[1].floorDimension
                                        .breath +
                                    (formField.floors[2].floorDimension.length *
                                      formField.floors[2].floorDimension
                                        .breath +
                                      formField.floors[3].floorDimension
                                        .length *
                                        formField.floors[3].floorDimension
                                          .breath)
                                  : ""
                              }
                              disabled
                            />
                          </td>
                          {/* <td className="col-auto">
                                                                                                                      <Field name="totalArea" className="form-control bg-white px-4" value={(values.floors[0].floorDimension.length)*(values.floors[0].floorDimension.breath)
                                                                                                                        + (values.floors[1].floorDimension.length)*(values.floors[1].floorDimension.breath)
                                                                                                                        + (values.floors[2].floorDimension.length)*(values.floors[2].floorDimension.breath)
                                                                                                                        + (values.floors[3].floorDimension.length)*(values.floors[3].floorDimension.breath)} disabled/> 
                                                                                                                    </td>   */}{" "}
                          {/* </td> */} {/* <td className="col-auto"></td> */}
                          {/* <td className="col-auto"></td> */}{" "}
                          {formField &&
                          formField.floors &&
                          formField.floors.length > 0 &&
                          formField.floors[0].floorDimension.length ? (
                            <td className="w-150px">
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  formField &&
                                  formField.floors &&
                                  formField.floors.length > 0
                                    ? formField.floors[0].floorDimension
                                        .length *
                                      formField.floors[0].floorDimension.breath
                                    : 0
                                }
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {formField &&
                          formField.floors &&
                          formField.floors.length > 0 &&
                          formField.floors[1].floorDimension.length ? (
                            <td className="w-150px">
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  formField &&
                                  formField.floors &&
                                  formField.floors.length > 0
                                    ? formField.floors[1].floorDimension
                                        .length *
                                      formField.floors[1].floorDimension.breath
                                    : 0
                                }
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {/* {formField && formField.floors && formField.floors.length>0 && formField.floors[0].floorDimension.length?(

                                                                                                        <td className="w-150px">
                                                                                                            <input type="text" className="form-control px-4" value={formField && formField.floors && formField.floors.length>0 ? (formField.floors[0].floorDimension.length)*(formField.floors[0].floorDimension.breath) : 0 } disabled />
                                                                                                        </td> 
                                                                                                                            ):null} */}{" "}
                          {formField &&
                          formField.floors &&
                          formField.floors.length > 0 &&
                          formField.floors[2].floorDimension.length ? (
                            <td className="w-150px">
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  formField &&
                                  formField.floors &&
                                  formField.floors.length > 0
                                    ? formField.floors[2].floorDimension
                                        .length *
                                      formField.floors[2].floorDimension.breath
                                    : 0
                                }
                                disabled
                              />
                            </td>
                          ) : null}
                          {formField &&
                          formField.floors &&
                          formField.floors.length > 0 &&
                          formField.floors[3].floorDimension.length ? (
                            <td className="w-150px">
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  formField &&
                                  formField.floors &&
                                  formField.floors.length > 0
                                    ? formField.floors[3].floorDimension
                                        .length *
                                      formField.floors[3].floorDimension.breath
                                    : 0
                                }
                                disabled
                              />
                            </td>
                          ) : null}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="totalArea.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="xxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.totalArea.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("totalArea.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="xxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="totalArea.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="yynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.totalArea.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("totalArea.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="yynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`totalArea.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.totalArea
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.totalArea
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-100px">
                            <p className="mb-0"> Pallets on Floor: </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <input
                              value={formField ? formField.palletsOnFloor : ""}
                              className="form-control px-4"
                              disabled
                            />
                          </td>{" "}
                          <td className="col-auto"> </td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="palletsOnFloor.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="jjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.palletsOnFloor.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "palletsOnFloor.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="jjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="palletsOnFloor.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="jjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.palletsOnFloor.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "palletsOnFloor.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="jjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`palletsOnFloor.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.palletsOnFloor
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.palletsOnFloor
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-100px">
                            <p className="mb-0">
                              {" "}
                              Racks (Shelve  Racks) - No  of  Shelve:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-3 mb-0">
                            <input
                              className="form-control px-1"
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? "Yes"
                                  : "No"
                              }
                              disabled
                            />
                          </td>{" "}
                          <td className="col-auto"> </td>{" "}
                          <td className="w-50px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Quantity{" "}
                            </label>{" "}
                            <input
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? formField.racks[0].rackInfo.quantity
                                  : ""
                              }
                              className="form-control px-4"
                              disabled
                            />
                          </td>{" "}
                          <td className="w-50px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Length{" "}
                            </label>{" "}
                            <input
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? formField.racks[0].rackInfo.length
                                  : ""
                              }
                              className="form-control px-4"
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Breadth{" "}
                            </label>{" "}
                            <input
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? formField.racks[0].rackInfo.breath
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="B"
                              disabled
                            />
                          </td>{" "}
                          <td className="w-50px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Height{" "}
                            </label>{" "}
                            <input
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? formField.racks[0].rackInfo.height
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="H"
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="racks[0].rack.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="mmjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.racks[0]
                                    ? values.racks[0].rack.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue("racks[0].rack.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="mmjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="racks[0].rack.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="wwjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.racks[0]
                                    ? values.racks[0].rack.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "racks[0].rack.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="wwjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`racks[0].rack.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.racks &&
                                  formField.storageSpaceRemark.racks.length >
                                    0 &&
                                  formField.storageSpaceRemark.racks[0].rack
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.racks &&
                                    formField.storageSpaceRemark.racks.length >
                                      0 &&
                                    formField.storageSpaceRemark.racks[0].rack
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-100px">
                            <p className="mb-0">
                              {" "}
                              Racks (Slotted  Angle) - No  of  Bins:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-3 mb-0">
                            <input
                              className="form-control px-1"
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? "Yes"
                                  : "No"
                              }
                              disabled
                            />
                          </td>{" "}
                          <td className="col-auto"> </td>{" "}
                          <td className="w-150px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Quantity{" "}
                            </label>{" "}
                            <input
                              type="text"
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? formField.racks[1].rackInfo.quantity
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="w-150px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Length{" "}
                            </label>{" "}
                            <input
                              type="text"
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? formField.racks[1].rackInfo.length
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="L"
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td className="w-150px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Breadth{" "}
                            </label>{" "}
                            <input
                              type="text"
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? formField.racks[1].rackInfo.breath
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="B"
                              disabled
                            />
                          </td>{" "}
                          <td className="w-150px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Height{" "}
                            </label>{" "}
                            <input
                              type="text"
                              value={
                                formField &&
                                formField.racks &&
                                formField.racks.length > 0
                                  ? formField.racks[1].rackInfo.height
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="H"
                              disabled
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="racks[1].rack.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="ccmmjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.racks[1]
                                    ? values.racks[1].rack.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue("racks[1].rack.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ccmmjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="racks[1].rack.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="jjwwjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.racks[1]
                                    ? values.racks[1].rack.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "racks[1].rack.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="jjwwjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`racks[1].rack.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.racks &&
                                  formField.storageSpaceRemark.racks.length >
                                    0 &&
                                  formField.storageSpaceRemark.racks[1].rack
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.racks &&
                                    formField.storageSpaceRemark.racks.length >
                                      0 &&
                                    formField.storageSpaceRemark.racks[1].rack
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-100px">
                            <p className="mb-0"> No Of Shifts </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <input
                              value={formField ? formField.noOfShift : ""}
                              className="form-control px-4"
                              disabled
                            />
                          </td>
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="noOfShift.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="bbjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.noOfShift.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("noOfShift.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="noOfShift.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="bbjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.noOfShift.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("noOfShift.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`noOfShift.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.noOfShift
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.noOfShift
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-100px">
                            <p className="mb-0">
                              {" "}
                              No.of Loading / unloading Bays:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <input
                              type="text"
                              value={
                                formField
                                  ? formField.loadingAndUnloadingBays
                                  : ""
                              }
                              className="form-control px-4"
                              disabled
                            />
                          </td>{" "}
                          <td className="col-auto"> </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="loadingAndUnloadingBays.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="xxrxbbjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.loadingAndUnloadingBays.whsstatus ===
                                  true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "loadingAndUnloadingBays.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="xxrxbbjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="loadingAndUnloadingBays.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="yymxbbjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.loadingAndUnloadingBays.whsstatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "loadingAndUnloadingBays.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="yymxbbjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`loadingAndUnloadingBays.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark
                                    .loadingAndUnloadingBays.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark
                                      .loadingAndUnloadingBays.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-100px">
                            <p className="mb-0"> Dock Size: </p>{" "}
                          </td>{" "}
                          <td className="col-auto">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Height From Ground{" "}
                            </label>{" "}
                            <input
                              type="text"
                              value={
                                formField && formField.dockSize
                                  ? formField.dockSize.heightFromGound
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="Height From Ground"
                              disabled
                            />
                          </td>{" "}
                          <td className="w-20px"></td>{" "}
                          <td className="w-15px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Height{" "}
                            </label>{" "}
                            <input
                              type="text"
                              value={
                                formField && formField.dockSize
                                  ? formField.dockSize.height
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="Enter Height"
                              disabled
                            />
                          </td>{" "}
                          <td className="w-15px">
                            <label
                              className=" pl-4 mr-3"
                              htmlFor="defaultCheck1"
                            >
                              {" "}
                              Width{" "}
                            </label>{" "}
                            <input
                              type="text"
                              value={
                                formField && formField.dockSize
                                  ? formField.dockSize.width
                                  : ""
                              }
                              className="form-control px-4"
                              placeholder="Enter Width"
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>{" "}
                          <td className="w-50px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="dockSize.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="rxbbjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.dockSize.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("dockSize.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="rxbbjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="dockSize.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="mxbbjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.dockSize.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("dockSize.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="mxbbjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`loadingAndUnloadingBays.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.dockSize
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.dockSize
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-100px">
                            {" "}
                            {formField !== null ? (
                              <p className="mb-0">
                                {" "}
                                Parking Area(
                                {formField.floors
                                  ? formField.floors[0].unit === "FEET"
                                    ? "sqft"
                                    : ""
                                  : ""}{" "}
                                {formField.floors
                                  ? formField.floors[0].unit === "INCH"
                                    ? "sqin"
                                    : ""
                                  : ""}{" "}
                                {formField.floors
                                  ? formField.floors[0].unit === "M"
                                    ? "sqm"
                                    : ""
                                  : ""}
                                ):{" "}
                              </p>
                            ) : null}{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group py-3 mt-1 mb-0">
                            <input
                              className="form-control px-1"
                              value={formField ? "Yes" : "No"}
                              disabled
                            />
                          </td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-50px" colSpan="1">
                            <input
                              type="text"
                              value={formField ? formField.parkingArea : ""}
                              className="form-control px-4"
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"> </td>{" "}
                          <td className="w-25px"> </td>
                          <td className="w-25px"> </td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="parkingArea.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="zzrxbbjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.parkingArea.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("parkingArea.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="zzrxbbjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="parkingArea.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nnmxbbjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.parkingArea.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("parkingArea.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nnmxbbjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`parkingArea.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.parkingArea
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.parkingArea
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>
                        <tr>
                          <td className="w-160px">
                            <p className="mb-3">
                              {" "}
                              Available Space:{" "}
                              <sup className="text-danger"> * </sup>
                            </p>
                          </td>{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {/* <td className="col-auto"></td> */}
                          {basement1 &&
                          basement1.availableSpaceDimension.length !== "" ? (
                            <td className="w-10px">
                              {" "}
                              <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                <label
                                  className=" pl-4 mr-3"
                                  htmlFor="defaultCheck1"
                                >
                                  {" "}
                                  Basement{" "}
                                </label>{" "}
                              </div>{" "}
                            </td>
                          ) : null}
                          {ground1 &&
                          ground1.availableSpaceDimension.length !== "" ? (
                            <td className="w-10px">
                              {" "}
                              <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                <label
                                  className=" pl-4 mr-3"
                                  htmlFor="defaultCheck1"
                                >
                                  {" "}
                                  Ground Floor{" "}
                                </label>{" "}
                              </div>{" "}
                            </td>
                          ) : null}
                          {first1 &&
                          first1.availableSpaceDimension.length !== "" ? (
                            <td className="w-10px">
                              <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                <label
                                  className=" pl-4 mr-3"
                                  htmlFor="defaultCheck1"
                                >
                                  {" "}
                                  1 st Floor{" "}
                                </label>{" "}
                              </div>{" "}
                            </td>
                          ) : null}{" "}
                          {second1 &&
                          second1.availableSpaceDimension.length !== "" ? (
                            <td className="w-10px">
                              <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                <label
                                  className=" pl-4 mr-3"
                                  htmlFor="defaultCheck1"
                                >
                                  {" "}
                                  2 nd Floor{" "}
                                </label>{" "}
                              </div>{" "}
                            </td>
                          ) : null}{" "}
                          {/* <td colSpan="2"></td> */}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-10px"></td>{" "}
                          {/* <td className="col-auto">
                                                                                                                                      
                                                                                                                                    </td> */}{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {basement1 &&
                          basement1.availableSpaceDimension.length !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Length{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  basement1 &&
                                  basement1.availableSpaceDimension.length
                                }
                                placeholder="Length"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {ground1 &&
                          ground1.availableSpaceDimension.length !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Length{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  ground1 &&
                                  ground1.availableSpaceDimension.length
                                }
                                placeholder="Length"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {first1 &&
                          first1.availableSpaceDimension.length !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Length{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  first1 &&
                                  first1.availableSpaceDimension.length
                                }
                                placeholder="Length"
                                disabled
                              />
                            </td>
                          ) : null}
                          {second1 &&
                          second1.availableSpaceDimension.length !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Length{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  second1 &&
                                  second1.availableSpaceDimension.length
                                }
                                placeholder="Length"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-10px"></td>{" "}
                          {/* <td className="col-auto"> 
                                                                                                                                            </td> */}{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {basement1 &&
                          basement1.availableSpaceDimension.breath !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Breadth{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  basement1 &&
                                  basement1.availableSpaceDimension.breath
                                }
                                placeholder="Breadth"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {ground1 &&
                          ground1.availableSpaceDimension.breath !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Breadth{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  ground1 &&
                                  ground1.availableSpaceDimension.breath
                                }
                                placeholder="Breadth"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {first1 &&
                          first1.availableSpaceDimension.breath !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Breadth{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  first1 &&
                                  first1.availableSpaceDimension.breath
                                }
                                placeholder="Breadth"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {second1 &&
                          second1.availableSpaceDimension.breath !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Breadth{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  second1 &&
                                  second1.availableSpaceDimension.breath
                                }
                                placeholder="Breadth"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-10px"></td>{" "}
                          {/* <td className="col-auto"> 
                                                                                                                                            </td> */}{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {basement1 &&
                          basement1.availableSpaceDimension.height !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Height{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  basement1 &&
                                  basement1.availableSpaceDimension.height
                                }
                                placeholder="Height"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {ground1 &&
                          ground1.availableSpaceDimension.height !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Height{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  ground1 &&
                                  ground1.availableSpaceDimension.height !== ""
                                }
                                placeholder="Height"
                                disabled
                              />
                            </td>
                          ) : null}
                          {first1 &&
                          first1.availableSpaceDimension.height !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Height{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  first1 &&
                                  first1.availableSpaceDimension.height
                                }
                                placeholder="Height"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {second1 &&
                          second1.availableSpaceDimension.height !== "" ? (
                            <td className="w-10px">
                              <label
                                className=" pl-4 mr-3"
                                htmlFor="defaultCheck1"
                              >
                                {" "}
                                Height{" "}
                              </label>{" "}
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  second1 &&
                                  second1.availableSpaceDimension.height
                                }
                                placeholder="Height"
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                        </tr>{" "}
                        {formField !== null ? (
                          <p className="mb-0">
                            {" "}
                            Total Available Space(
                            {formField &&
                            formField.floors &&
                            formField.floors.length > 0
                              ? formField.floors[0].unit === "FEET"
                                ? "sqft"
                                : ""
                              : ""}{" "}
                            {formField.floors
                              ? formField.floors[0].unit === "INCH"
                                ? "sqin"
                                : ""
                              : ""}{" "}
                            {formField.floors
                              ? formField.floors[0].unit === "M"
                                ? "sqm"
                                : ""
                              : ""}
                            ):{" "}
                          </p>
                        ) : null}{" "}
                        <tr>
                          <td className="col-auto">
                            <input
                              type="text"
                              className="form-control px-4"
                              value={
                                formField &&
                                formField.availableSpaces &&
                                formField.availableSpaces.length > 0
                                  ? formField.availableSpaces[0]
                                      .availableSpaceDimension.length *
                                      formField.availableSpaces[0]
                                        .availableSpaceDimension.breath +
                                    formField.availableSpaces[1]
                                      .availableSpaceDimension.length *
                                      formField.availableSpaces[1]
                                        .availableSpaceDimension.breath +
                                    (formField.availableSpaces[2]
                                      .availableSpaceDimension.length *
                                      formField.availableSpaces[2]
                                        .availableSpaceDimension.breath +
                                      formField.availableSpaces[3]
                                        .availableSpaceDimension.length *
                                        formField.availableSpaces[3]
                                          .availableSpaceDimension.breath)
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          {/* <td className="col-auto"></td> */}{" "}
                          {formField &&
                          formField.availableSpaces &&
                          formField.availableSpaces.length > 0 &&
                          formField.availableSpaces[0].availableSpaceDimension
                            .length ? (
                            <td className="w-150px">
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  formField &&
                                  formField.availableSpaces &&
                                  formField.availableSpaces[0]
                                    .availableSpaceDimension.length > 0
                                    ? formField.availableSpaces[0]
                                        .availableSpaceDimension.length *
                                      formField.availableSpaces[0]
                                        .availableSpaceDimension.breath
                                    : 0
                                }
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {formField &&
                          formField.availableSpaces &&
                          formField.availableSpaces.length > 0 &&
                          formField.availableSpaces[1].availableSpaceDimension
                            .length ? (
                            <td className="w-150px">
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  formField &&
                                  formField.availableSpaces &&
                                  formField.availableSpaces[1]
                                    .availableSpaceDimension.length > 0
                                    ? formField.availableSpaces[1]
                                        .availableSpaceDimension.length *
                                      formField.availableSpaces[1]
                                        .availableSpaceDimension.breath
                                    : 0
                                }
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {/* {formField && formField.availableSpace && formField.availableSpace.length>0 && formField.availableSpace[0].availableSpaceDimension.length?(

                                                                                                                                                    <td className="w-150px">
                                                                                                                                                        <input type="text" className="form-control px-4" value={formField && formField.availableSpace && formField.availableSpace.length>0 ? (formField.availableSpace[0].availableSpaceDimension.length)*(formField.availableSpace[0].availableSpaceDimension.breath) : 0 } disabled />
                                                                                                                                                    </td> 
                                                                                                                                                                        ):null} */}{" "}
                          {formField &&
                          formField.availableSpaces &&
                          formField.availableSpaces[2].availableSpaceDimension
                            .length ? (
                            <td className="w-150px">
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  formField &&
                                  formField.availableSpaces &&
                                  formField.availableSpaces.length > 0
                                    ? formField.availableSpaces[2]
                                        .availableSpaceDimension.length *
                                      formField.availableSpaces[2]
                                        .availableSpaceDimension.breath
                                    : 0
                                }
                                disabled
                              />
                            </td>
                          ) : null}{" "}
                          {formField &&
                          formField.availableSpaces &&
                          formField.availableSpaces[3].availableSpaceDimension
                            .length ? (
                            <td className="w-150px">
                              <input
                                type="text"
                                className="form-control px-4"
                                value={
                                  formField &&
                                  formField.availableSpaces &&
                                  formField.availableSpaces.length > 0
                                    ? formField.availableSpaces[3]
                                        .availableSpaceDimension.length *
                                      formField.availableSpaces[3]
                                        .availableSpaceDimension.breath
                                    : 0
                                }
                                disabled
                              />
                            </td>
                          ) : null}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="availableSpace.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="xxsnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                checked={
                                  values.availableSpace.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "availableSpace.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="xxsnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="availableSpace.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="yysnbcategoryNotOkay"
                                value="notOkay"
                                required
                                checked={
                                  values.availableSpace.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "availableSpace.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="yysnbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              name={`availableSpace.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-150px"></td>{" "}
                          <td className="mw-150px"></td>{" "}
                          <td className="text-left px-4" colSpan="2">
                            {" "}
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}{" "}
                            <div className="row ">
                              <div className="col-auto">
                                <p className="px-3 py-2">
                                  {" "}
                                  Field Agent Remarks{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="col-auto form-inline form-group form-inline">
                                <p className="py-2">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.availableSpace
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                              </div>{" "}
                              <div className="mw-75px">
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.availableSpace
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </div>{" "}
                            </div>{" "}
                          </td>{" "}
                        </tr>{" "}
                      </tbody>
                    </table>{" "}
                  </div>{" "}
                </div>
                <div className={`col-12 mt-4`}>
                  <div className="row justify-content-end">
                    <div className="col-auto ">
                      <button
                        type="submit"
                        className="btn btn-deep-blue add-class remove-class "
                        disabled={data.isPending}
                      >
                        Save{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default SpacePendingForm;
