import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import SearchBox from "../../components/common/SearchBox";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchWarehouseByPageFieldAgent } from "../../store/action/warehouseAction";
import { useHistory } from "react-router-dom";
import FieldAgentPendingList from "../../wrapper/fieldAgentManagement/FieldAgentPendingList";

const FieldAgentPending = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    // dispatch(fetchWarehouse())
    dispatch(fetchWarehouseByPageFieldAgent(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(fetchWarehouseByPageFieldAgent(pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(fetchWarehouseByPageFieldAgent(pageCount, searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Field Agent Pending </title>{" "}
      </Helmet>
      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0 d-flex justify-content-between">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>
            Assigned for Field Verification{" "}
          </h5>
        </div>{" "}
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
          />{" "}
        </div>
        <div className="col-12 px-0">
          <div className="row align-items-center px-3 py-3">
            <div className="col-12 px-0 table-responsive table-gray-admin bg-white p-3">
              <table className="table table-dark-custom">
                <thead>
                  <tr>
                    <th className="w-100px pl-2"> S.No. </th>{" "}
                    <th className="text-nowrap"> Listing Date </th>{" "}
                    <th className="text-nowrap"> WH ID </th>{" "}
                    <th className="text-nowrap"> SP Company name </th>{" "}
                    <th className="text-nowrap"> WH Category </th>{" "}
                    <th> Status </th> <th className="text-center"> </th>{" "}
                  </tr>{" "}
                </thead>{" "}
                <tbody>
                  {data.listOfWarehouse.data &&
                    data.listOfWarehouse.data.length > 0 &&
                    data.listOfWarehouse.data.map((item, index) => {
                      return (
                        <FieldAgentPendingList
                          key={index}
                          item={item}
                          index={(pageCount - 1) * 10 + (index + 1)}
                        />
                      );
                    })}
                </tbody>{" "}
              </table>{" "}
            </div>{" "}
          </div>{" "}
          {/* ============ Pagination ============ */}{" "}
          {data.listOfWarehouse && (
            <div className="pagination-custom">
              <Pagination
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={data.listOfWarehouse.totalCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                prevPageText={<i className="fas fa-chevron-left" />}
                nextPageText={<i className="fas fa-chevron-right" />}
                hideFirstLastPages={true}
              />{" "}
            </div>
          )}
          {/* ===================================== */}{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default FieldAgentPending;
