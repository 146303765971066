import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  bookingById,
  booking_By_Id,
  updateBooking,
  responseBooking,
} from "../../store/action/bookingAction";
import { readableDate } from "../../components/helper/reuse";
import FormSuccess from "../../components/common/FormSuccess";
import { CardLoader } from "../../components/common/CustomLoader";
import Spinner from "react-bootstrap/Spinner";

const BookingDetails = () => {
  const history = useHistory();
  const { bookingId } = useParams();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.BOOKING_INFO);
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const status = new URLSearchParams(window.location.search).get("status");

  useEffect(() => {
    dispatch(bookingById(bookingId));
    return () => {
      dispatch(booking_By_Id([]));
    };
  }, [dispatch, bookingId]);

  const changeStatus = (adminStatus) => {
    let d = {
      adminStatus: adminStatus,
    };
    dispatch(updateBooking(bookingId, d));
  };

  const redirect = () => {
    dispatch(responseBooking(null));

    if (status === "CONFIRMED") {
      history.replace(`/booking-approve?page=1`);
    }
    if (status === "CANCELLED") {
      history.replace(`/booking-cancel?page=1`);
    }
    if (status === "PENDING") {
      history.replace(`/booking-pending?page=1`);
    }

    if (status === "ALL") {
      history.replace(`/booking?page=1`);
    }
  };

  return (
    <LayoutOne>
      {data.bookingResponse && data.bookingResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={redirect}
          message={data.bookingResponse.message}
        />
      ) : null}

      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div class="row align-items-center pb-3 px-3 mx-0">
          <div class="col-12 py-2">
            <h5 class="text-dark">
              {" "}
              <i
                onClick={() => history.goBack()}
                class="fas fa-chevron-left mr-1 cursorPointer"
              >
                {" "}
              </i>{" "}
              View Booking Details
            </h5>
          </div>
          <div class="col-12 bg-white p-3">
            <div class="row">
              <div class="col-md-6 d-flex py-1">
                <div class="card py-3 px-4 mb-0 border">
                  <p class="mb-2"> Booking ID </p>{" "}
                  <p class="text-gray mb-0"> {data.bookingDetail?.id} </p>{" "}
                </div>{" "}
              </div>{" "}
              <div class="col-md-6 d-flex py-1">
                <div class="card py-3 px-4 mb-0 border">
                  <p class="mb-2"> Booking Date </p>{" "}
                  <p class="text-gray mb-0">
                    {" "}
                    {readableDate(data.bookingDetail?.created_at)}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div class="col-md-6 d-flex py-1">
                <div class="card py-3 px-4 mb-0 border">
                  <p class="mb-2"> Customer Name </p>{" "}
                  <p class="text-gray mb-0">
                    {" "}
                    {data.bookingDetail?.customer?.firstName +
                      " " +
                      data.bookingDetail?.customer?.lastName}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div class="col-md-6 d-flex py-1">
                <div class="card py-3 px-4 mb-0 border">
                  <p class="mb-2"> Company Name </p>{" "}
                  <p class="text-gray mb-0">
                    {" "}
                    {data.bookingDetail?.customer?.companyName}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div class="col-md-6 d-flex py-1">
                <div class="card py-3 px-4 mb-0 border">
                  <p class="mb-2"> Customer Email ID </p>{" "}
                  <p class="text-gray mb-0">
                    {" "}
                    {data.bookingDetail?.customer?.email}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div class="col-md-6 d-flex py-1">
                <div class="card py-3 px-4 mb-0 border">
                  <p class="mb-2"> Warehouse ID </p>{" "}
                  <p class="text-gray mb-0">
                    {" "}
                    {data.bookingDetail?.warehouse?.warehouseId}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div class="col-md-6 d-flex py-1">
                <div class="card py-3 px-4 mb-0 border">
                  <p class="mb-2"> Contact No </p>{" "}
                  <p class="text-gray mb-0">
                    {" "}
                    {data.bookingDetail?.customer?.phone}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div class="col-md-6 d-flex py-1">
                <div class="card py-3 px-4 mb-0 border">
                  <p class="mb-2"> Status </p>{" "}
                  <p class="text-gray mb-0">
                    {" "}
                    {data.bookingDetail?.adminStatus}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div class="row mt-1">
              <div class="col-12 px-0 text-sm-right">
                <button
                  onClick={() => history.goBack()}
                  class="btn py-2 btn-outline-deep-blue m-3"
                >
                  {" "}
                  Back{" "}
                </button>{" "}
                <button
                  onClick={() => changeStatus("CANCELLED")}
                  type="button"
                  class={`btn btn-outline-deep-blue m-3 py-2 ${
                    data.bookingDetail?.adminStatus === "CANCELLED"
                      ? "d-none"
                      : ""
                  } ${!read ? "" : "d-none"}`}
                  disabled={data.isPending}
                >
                  Cancel{" "}
                  {data.isPending ? <Spinner animation="border" /> : null}{" "}
                </button>{" "}
                <button
                  onClick={() => changeStatus("CONFIRMED")}
                  type="button"
                  disabled={data.isPending}
                  class={`btn btn-deep-blue m-3 py-2 ${
                    data.bookingDetail?.adminStatus === "CONFIRMED"
                      ? "d-none"
                      : ""
                  } ${!read ? "" : "d-none"}`}
                >
                  Confirm{" "}
                  {data.isPending ? <Spinner animation="border" /> : null}{" "}
                </button>
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}
    </LayoutOne>
  );
};

export default BookingDetails;
