import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import ServiceList from "../../wrapper/serviceManagement/ServiceList";
import { useDispatch, useSelector } from "react-redux";
import {
  serviceByPage,
  responseService,
} from "../../store/action/serviceAction";
import FormSuccess from "../../components/common/FormSuccess";
import { CardLoader } from "../../components/helper/CustomLoader";
import { Helmet } from "react-helmet";

const ManageService = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.SERVICE_INFO);

  useEffect(() => {
    dispatch(serviceByPage());
  }, [dispatch]);

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service`);
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Manage Service </title>{" "}
      </Helmet>{" "}
      {data.serviceResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Category Status Updated`} />
      ) : null}
      <div className="row align-items-center mx-0">
        <div className="col-12 pt-3">
          <h5 className="text-dark font-weight-bold">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            ></i>{" "}
            Manage Services{" "}
          </h5>{" "}
        </div>{" "}
      </div>{" "}
      {/* <div className="row justify-content-lg-end align-items-center pt-3 px-3 bg-lighter-blue">  
                        <div className="col-auto d-flex h-100 px-2 mb-2"> 
                          <Link to="service-add" className={`btn btn-deep-blue ${!read ?"":"d-none"}`}>
                            Add New
                          </Link> 
                        </div>  
                      </div> */}
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row justify-content-end align-items-center py-3 px-3 mx-0">
          <div className="col-12">
            <div className="row">
              <div className="col-12 px-0 table-responsive table-gray-admin bg-white p-3">
                <table className="table text-center table-dark-custom">
                  <thead>
                    <tr>
                      <th className="w-100px pl-2"> S.No. </th>{" "}
                      <th className="text-nowrap"> Date Of Services </th>{" "}
                      <th> Category </th>{" "}
                      <th className="text-nowrap"> Sub-Category </th>{" "}
                      <th className="text-nowrap"> Service Name </th>{" "}
                      {/* <th className="mw-150px">Description</th>  */}{" "}
                      <th> Status </th>{" "}
                      <th className="text-center"> Action </th>{" "}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {data.serviceList && data.serviceList.length > 0
                      ? data.serviceList.map((item, index) => (
                          <ServiceList
                            item={item}
                            index={index + 1}
                            key={index}
                            read={read}
                          />
                        ))
                      : "Data Not Found"}{" "}
                  </tbody>{" "}
                </table>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default ManageService;
