import React from "react";
import { readableDate } from "../../components/helper/reuse";

const NotificationList = ({ item, index }) => {
  return (
    <>
      <tr>
        <td className="text-center"> {index} </td>{" "}
        <td className="text-nowrap p-1 pl-3">
          {" "}
          <span className="text-capitalize">
            {" "}
            {item?.user?.firstName + " " + item?.user?.lastName}{" "}
          </span>
        </td>
        <td className="text-nowrap"> {item?.title} </td>{" "}
        <td className="text-nowrap"> {item?.content?.slice(0, 70)}... </td>{" "}
        <td> {readableDate(item?.created_at)} </td>{" "}
      </tr>{" "}
    </>
  );
};

export default NotificationList;
