import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, FieldArray } from "formik";
import {
  updatePhoto,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";

const PhotoPendingForm = ({ warehouseId, viewMood }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const [coverView, setCoverView] = useState(null);
  const [inDoorView, setInDoorView] = useState([]);

  const [outDoorView, setOutDoorView] = useState([]);
  const validationSchema = function (values) {
    return Yup.object().shape({
      whsCoverImage: Yup.object().shape({
        coverImage: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        // etc
      }),

      whsIndoors: Yup.array().of(
        Yup.object().shape({
          indoor: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      whsOutdoors: Yup.array().of(
        Yup.object().shape({
          outdoor: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
    });
  };
  const [remarkForm, setRemarkForm] = useState({
    whsCoverImage: {
      type: "",
      coverImg: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
    },
    whsIndoors: [
      {
        type: "indoor",
        indoor: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "indoor2",
        indoor: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    whsOutdoors: [
      {
        type: "out1",
        outdoor: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "out2",
        outdoor: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
  });
  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        warehouseImageRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updatePhoto(payload));
  };
  useEffect(() => {
    if (
      data.singleFormData !== null &&
      data.singleFormData.warehouseImagesInfo !== undefined &&
      data.type === "warehouseImages"
    ) {
      if (data.singleFormData.warehouseImagesInfo !== null) {
        setCoverView(data.singleFormData.warehouseImagesInfo.coverImage.url);
      }

      if (
        data.singleFormData.warehouseImagesInfo &&
        data.singleFormData.warehouseImagesInfo.indoorImages.length > 0
      ) {
        setInDoorView(data.singleFormData.warehouseImagesInfo.indoorImages);
      }

      if (
        data.singleFormData.warehouseImagesInfo &&
        data.singleFormData.warehouseImagesInfo.outdoorImages.length > 0
      ) {
        setOutDoorView(data.singleFormData.warehouseImagesInfo.outdoorImages);
      }

      if (
        data.singleFormData.warehouseImageRemark &&
        data.type === "warehouseImages"
      ) {
        let whsIndoors = [];
        let whsOutdoors = [];
        if (
          data.singleFormData.warehouseImageRemark &&
          data.singleFormData.warehouseImageRemark.whsIndoors.length > 0
        ) {
          for (
            let i = 0;
            i < data.singleFormData.warehouseImageRemark.whsIndoors.length;
            i++
          ) {
            whsIndoors.push({
              type: "indoor" + i,
              indoor: {
                value: "",

                whsstatus:
                  data.singleFormData.warehouseImageRemark.whsIndoors[i].indoor
                    .whsstatus,
                whsremark:
                  data.singleFormData.warehouseImageRemark.whsIndoors[i].indoor
                    .whsremark,
                fastatus:
                  data.singleFormData.warehouseImageRemark.whsIndoors[i].indoor
                    .fastatus,
                faremark:
                  data.singleFormData.warehouseImageRemark.whsIndoors[i].indoor
                    .faremark,
              },
            });
          }
        }
        if (
          data.singleFormData.warehouseImageRemark &&
          data.singleFormData.warehouseImageRemark.whsOutdoors.length > 0
        ) {
          for (
            let i = 0;
            i < data.singleFormData.warehouseImageRemark.whsOutdoors.length;
            i++
          ) {
            whsOutdoors.push({
              type: "out" + i,
              outdoor: {
                value: "",

                whsstatus:
                  data.singleFormData.warehouseImageRemark.whsOutdoors[i]
                    .outdoor.whsstatus,
                whsremark:
                  data.singleFormData.warehouseImageRemark.whsOutdoors[i]
                    .outdoor.whsremark,
                fastatus:
                  data.singleFormData.warehouseImageRemark.whsOutdoors[i]
                    .outdoor.fastatus,
                faremark:
                  data.singleFormData.warehouseImageRemark.whsOutdoors[i]
                    .outdoor.faremark,
              },
            });
          }
        }
        setRemarkForm({
          whsCoverImage: {
            type: "",
            coverImg: {
              value: "",

              whsstatus: data.singleFormData.warehouseImageRemark
                ? data.singleFormData.warehouseImageRemark.whsCoverImage
                    .coverImg.whsstatus
                : "",
              whsremark: data.singleFormData.warehouseImageRemark
                ? data.singleFormData.warehouseImageRemark.whsCoverImage
                    .coverImg.whsremark
                : "",
              fastatus: data.singleFormData.warehouseImageRemark
                ? data.singleFormData.warehouseImageRemark.whsCoverImage
                    .coverImg.fastatus
                : "",
              faremark: data.singleFormData.warehouseImageRemark
                ? data.singleFormData.warehouseImageRemark.whsCoverImage
                    .coverImg.faremark
                : "",
            },
          },
          whsIndoors: whsIndoors,
          whsOutdoors: whsOutdoors,
        });
      } else {
        let whsIndoors = [];
        let whsOutdoors = [];
        if (
          data.singleFormData.warehouseImagesInfo &&
          data.singleFormData.warehouseImagesInfo.indoorImages.length > 0
        ) {
          for (
            let i = 0;
            i < data.singleFormData.warehouseImagesInfo.indoorImages.length;
            i++
          ) {
            whsIndoors.push({
              type: "indoor" + i,
              indoor: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            });
          }
        }
        if (
          data.singleFormData.warehouseImagesInfo &&
          data.singleFormData.warehouseImagesInfo.outdoorImages.length > 0
        ) {
          for (
            let i = 0;
            i < data.singleFormData.warehouseImagesInfo.outdoorImages.length;
            i++
          ) {
            whsOutdoors.push({
              type: "out" + i,
              outdoor: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            });
          }
        }
        setRemarkForm({
          whsCoverImage: {
            type: "",
            coverImg: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
          },
          whsIndoors: whsIndoors,
          whsOutdoors: whsOutdoors,
        });
      }
    }
  }, [data.singleFormData, data.type]);
  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form className="w-100">
              <div className="col-12 rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12">
                    <h6 className="py-3 mb-3 border-bottom">
                      Warehouse Cover Photo{" "}
                    </h6>{" "}
                  </div>{" "}
                  <div className="col-xl-8">
                    <table class="table table-borderless mb-0 ">
                      <thead>
                        <tr>
                          <th scope="col" class="col-8 py-0">
                            {" "}
                          </th>{" "}
                          <th scope="col" className="py-0">
                            Okay{" "}
                          </th>{" "}
                          <th
                            scope="col"
                            className="py-0"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            Not Okay{" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            Remarks{" "}
                          </th>{" "}
                          <th scope="col" className="text-center py-0">
                            Field Agent Remarks{" "}
                          </th>{" "}
                        </tr>{" "}
                      </thead>
                      <tbody>
                        <tr>
                          <td className="py-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className=" pb-0 d-flex align-items-center">
                                  <table className="col-12">
                                    <tr className="card card-overlay upload-image-preview position-relative">
                                      <td className="img-holder size-200px">
                                        <img
                                          className="w-100 h-100 img-fluid rounded overflow-hideen"
                                          id="imageResult"
                                          src={coverView}
                                          alt=""
                                        />
                                      </td>{" "}
                                    </tr>{" "}
                                  </table>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td verticalAlign="middle" className="py-0 pt-4">
                            <table class="table table-borderless  mx-0 pl-2 mt-2">
                              <tr>
                                <td
                                  className="py-0 form-check common-radio-deep-blue"
                                  verticalAlign="middle"
                                >
                                  <Field
                                    name="whsCoverImage.coverImg.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    id="aaassss"
                                    type="radio"
                                    hidden="true"
                                    value="okay"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.whsCoverImage.coverImg
                                        .whsstatus === true
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "whsCoverImage.coverImg.whsstatus",
                                        true
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="aaassss"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0 pt-4">
                            <table class="table table-borderless mx-0 pl-2 mt-2">
                              <tr>
                                <td className="py-0 form-check common-radio-deep-blue ">
                                  <Field
                                    name="whsCoverImage.coverImg.whsstatus"
                                    className="common-radio-deep-blue-input"
                                    type="radio"
                                    id="naaassss"
                                    hidden="true"
                                    value="notOkay"
                                    required
                                    disabled={viewMood}
                                    checked={
                                      values.whsCoverImage.coverImg
                                        .whsstatus === false
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        "whsCoverImage.coverImg.whsstatus",
                                        false
                                      )
                                    }
                                  />{" "}
                                  <label
                                    className="common-radio-deep-blue-label pl-sm-4"
                                    htmlFor="naaassss"
                                  ></label>{" "}
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="pb-0">
                            <table class="table table-borderless form-group col-12 pt-xl-1">
                              <tr>
                                <td className="pb-0 pt-3">
                                  <Field
                                    disabled={viewMood}
                                    name={`whsCoverImage.coverImg.whsremark`}
                                    type="text"
                                    className="form-control bg-white px-4 mb-2 mr-4"
                                    placeholder="Remarks"
                                  />
                                </td>{" "}
                              </tr>{" "}
                            </table>{" "}
                          </td>{" "}
                          <td className="py-0 align-items-center pt-2">
                            <td className="d-flex px-0 align-items-baseline pt-4">
                              <p className="text-nowrap pr-4">
                                {" "}
                                {data.singleFormData &&
                                data.singleFormData.warehouseImageRemark &&
                                data.singleFormData.warehouseImageRemark
                                  .whsCoverImage &&
                                data.singleFormData.warehouseImageRemark
                                  .whsCoverImage.coverImg.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  data.singleFormData &&
                                  data.singleFormData.warehouseImageRemark &&
                                  data.singleFormData.warehouseImageRemark
                                    .whsCoverImage &&
                                  data.singleFormData.warehouseImageRemark
                                    .whsCoverImage.coverImg.faremark
                                }
                                type="text"
                                className="form-control bg-white px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </td>{" "}
                        </tr>{" "}
                      </tbody>{" "}
                    </table>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="row">
                  <div className="col-12">
                    <h6 className="py-3 mb-3 border-bottom">
                      Warehouse Indoor Photos{" "}
                    </h6>{" "}
                  </div>{" "}
                  <FieldArray
                    name="whsIndoors"
                    render={(arrayHelpers) => (
                      <>
                        {" "}
                        {values.whsIndoors.map((value, index) => (
                          <>
                            <div className="col-xl-8">
                              <table class="table table-borderless mb-0 ">
                                <thead>
                                  <tr>
                                    <th scope="col" class="col-8 py-0">
                                      {" "}
                                    </th>{" "}
                                    <th scope="col" className="py-0">
                                      {" "}
                                    </th>{" "}
                                    <th
                                      scope="col"
                                      className="py-0"
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    ></th>{" "}
                                    <th
                                      scope="col"
                                      className="text-center py-0"
                                    ></th>{" "}
                                    <th
                                      scope="col"
                                      className="text-center py-0"
                                    ></th>{" "}
                                  </tr>{" "}
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="py-0">
                                      <table class="table table-borderless form-group col-12 pt-xl-1">
                                        <tr>
                                          <td className=" pb-0 d-flex align-items-center">
                                            <table className="col-12">
                                              <tr className="card card-overlay upload-image-preview position-relative">
                                                <td className="img-holder size-200px">
                                                  <img
                                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                                    id="imageResult"
                                                    src={
                                                      inDoorView[index]
                                                        ? inDoorView[index].url
                                                        : ""
                                                    }
                                                    alt=""
                                                  />
                                                </td>{" "}
                                              </tr>{" "}
                                            </table>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td
                                      verticalAlign="middle"
                                      className="py-0 pt-4"
                                    >
                                      <table class="table table-borderless  mx-0 pl-2 mt-2">
                                        <tr>
                                          <td
                                            className="py-0 form-check common-radio-deep-blue"
                                            verticalAlign="middle"
                                          >
                                            <Field
                                              disabled={viewMood}
                                              name={`whsIndoors[${index}].indoor.whsstatus`}
                                              className="common-radio-deep-blue-input"
                                              id={"okaiy" + index}
                                              type="radio"
                                              hidden="true"
                                              value="okay"
                                              required
                                              checked={
                                                values.whsIndoors
                                                  ? values.whsIndoors[index]
                                                      .indoor.whsstatus === true
                                                  : ""
                                              }
                                              onChange={() => {
                                                let a = `whsIndoors[${index}].indoor.whsstatus`;
                                                setFieldValue(a, true);
                                              }}
                                            />{" "}
                                            <label
                                              className="common-radio-deep-blue-label pl-sm-4"
                                              htmlFor={"okaiy" + index}
                                            ></label>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="py-0 pt-4">
                                      <table class="table table-borderless mx-0 pl-2 mt-2">
                                        <tr>
                                          <td className="py-0 form-check common-radio-deep-blue ">
                                            <Field
                                              disabled={viewMood}
                                              name={`whsIndoors[${index}].indoor.whsstatus`}
                                              className="common-radio-deep-blue-input"
                                              type="radio"
                                              id={"not-okaiy" + index}
                                              hidden="true"
                                              required
                                              checked={
                                                values.whsIndoors
                                                  ? values.whsIndoors[index]
                                                      .indoor.whsstatus ===
                                                    false
                                                  : ""
                                              }
                                              onChange={() => {
                                                let a = `whsIndoors[${index}].indoor.whsstatus`;
                                                setFieldValue(a, false);
                                              }}
                                            />{" "}
                                            <label
                                              className="common-radio-deep-blue-label pl-sm-4"
                                              htmlFor={"not-okaiy" + index}
                                            ></label>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="pb-0">
                                      <table class="table table-borderless form-group col-12 pt-xl-1">
                                        <tr>
                                          <td className="pb-0 pt-3">
                                            <Field
                                              disabled={viewMood}
                                              name={`whsIndoors[${index}].indoor.whsremark`}
                                              type="text"
                                              className="form-control bg-white px-4 mb-2 mr-4"
                                              placeholder="Remarks"
                                            />
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="py-0 align-items-center pt-2">
                                      <td className="d-flex px-0 align-items-baseline pt-4">
                                        <p className="text-nowrap pr-4">
                                          {" "}
                                          {data.singleFormData &&
                                          data.singleFormData
                                            .warehouseImageRemark &&
                                          data.singleFormData
                                            .warehouseImageRemark
                                            .whsCoverImage &&
                                          data.singleFormData
                                            .warehouseImageRemark.whsIndoors[
                                            index
                                          ] &&
                                          data.singleFormData
                                            .warehouseImageRemark.whsIndoors[
                                            index
                                          ].indoor &&
                                          data.singleFormData
                                            .warehouseImageRemark.whsIndoors[
                                            index
                                          ].indoor.fastatus === true
                                            ? "okay"
                                            : "not okay"}{" "}
                                        </p>{" "}
                                        <input
                                          disabled
                                          value={
                                            data.singleFormData &&
                                            data.singleFormData
                                              .warehouseImageRemark &&
                                            data.singleFormData
                                              .warehouseImageRemark
                                              .whsCoverImage &&
                                            data.singleFormData
                                              .warehouseImageRemark.whsIndoors[
                                              index
                                            ] &&
                                            data.singleFormData
                                              .warehouseImageRemark.whsIndoors[
                                              index
                                            ].indoor &&
                                            data.singleFormData
                                              .warehouseImageRemark.whsIndoors[
                                              index
                                            ].indoor.faremark
                                          }
                                          type="text"
                                          className="form-control bg-white px-4 mb-2 mr-4"
                                          placeholder="Remarks"
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </tr>{" "}
                                </tbody>{" "}
                              </table>{" "}
                            </div>{" "}
                          </>
                        ))}{" "}
                      </>
                    )}
                  />{" "}
                </div>{" "}
                <div className="row">
                  <div className="col-12">
                    <h6 className="py-3 mb-3 border-bottom">
                      Warehouse Outdoor Photos{" "}
                    </h6>{" "}
                  </div>
                  <FieldArray
                    name="whsOutdoors"
                    render={(arrayHelpers) => (
                      <>
                        {" "}
                        {values.whsOutdoors.map((value, index) => (
                          <>
                            <div className="col-xl-8">
                              <table class="table table-borderless mb-0 ">
                                <thead>
                                  <tr>
                                    <th scope="col" class="col-8 py-0">
                                      {" "}
                                    </th>{" "}
                                    <th scope="col" className="py-0">
                                      {" "}
                                    </th>{" "}
                                    <th
                                      scope="col"
                                      className="py-0"
                                      style={{
                                        whiteSpace: "nowrap",
                                      }}
                                    ></th>{" "}
                                    <th
                                      scope="col"
                                      className="text-center py-0"
                                    ></th>{" "}
                                    <th
                                      scope="col"
                                      className="text-center py-0"
                                    ></th>{" "}
                                  </tr>{" "}
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="py-0">
                                      <table class="table table-borderless form-group col-12 pt-xl-1">
                                        <tr>
                                          <td className=" pb-0 d-flex align-items-center">
                                            <table className="col-12">
                                              <tr className="card card-overlay upload-image-preview position-relative">
                                                <td className="img-holder size-200px">
                                                  <img
                                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                                    id="imageResult"
                                                    src={
                                                      outDoorView[index]
                                                        ? outDoorView[index].url
                                                        : ""
                                                    }
                                                    alt=""
                                                  />
                                                </td>{" "}
                                              </tr>{" "}
                                            </table>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td
                                      verticalAlign="middle"
                                      className="py-0 pt-4"
                                    >
                                      <table class="table table-borderless  mx-0 pl-2 mt-2">
                                        <tr>
                                          <td
                                            className="py-0 form-check common-radio-deep-blue"
                                            verticalAlign="middle"
                                          >
                                            <Field
                                              disabled={viewMood}
                                              name={`whsOutdoors[${index}].outdoor.whsstatus`}
                                              className="common-radio-deep-blue-input"
                                              id={"aokay" + index}
                                              type="radio"
                                              value="okay"
                                              hidden="true"
                                              required
                                              checked={
                                                values.whsOutdoors
                                                  ? values.whsOutdoors[index]
                                                      .outdoor.whsstatus ===
                                                    true
                                                  : ""
                                              }
                                              onChange={() => {
                                                let a = `whsOutdoors[${index}].outdoor.whsstatus`;
                                                setFieldValue(a, true);
                                              }}
                                            />{" "}
                                            <label
                                              className="common-radio-deep-blue-label pl-sm-4"
                                              htmlFor={"aokay" + index}
                                            ></label>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="py-0 pt-4">
                                      <table class="table table-borderless mx-0 pl-2 mt-2">
                                        <tr>
                                          <td className="py-0 form-check common-radio-deep-blue ">
                                            <Field
                                              disabled={viewMood}
                                              name={`whsOutdoors[${index}].outdoor.whsstatus`}
                                              className="common-radio-deep-blue-input"
                                              type="radio"
                                              id={"anot-okay" + index}
                                              hidden="true"
                                              required
                                              checked={
                                                values.whsOutdoors
                                                  ? values.whsOutdoors[index]
                                                      .outdoor.whsstatus ===
                                                    false
                                                  : ""
                                              }
                                              onChange={() => {
                                                let a = `whsOutdoors[${index}].outdoor.whsstatus`;
                                                setFieldValue(a, false);
                                              }}
                                            />{" "}
                                            <label
                                              className="common-radio-deep-blue-label pl-sm-4"
                                              htmlFor={"anot-okay" + index}
                                            ></label>{" "}
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="pb-0">
                                      <table class="table table-borderless form-group col-12 pt-xl-1">
                                        <tr>
                                          <td className="pb-0 pt-3">
                                            <Field
                                              disabled={viewMood}
                                              name={`whsOutdoors[${index}].outdoor.whsremark`}
                                              type="text"
                                              className="form-control bg-white px-4 mb-2 mr-4"
                                              placeholder="Remarks"
                                            />
                                          </td>{" "}
                                        </tr>{" "}
                                      </table>{" "}
                                    </td>{" "}
                                    <td className="py-0 align-items-center pt-2">
                                      <td className="d-flex px-0 align-items-baseline pt-4">
                                        <p className="text-nowrap pr-4">
                                          {" "}
                                          {data.singleFormData &&
                                          data.singleFormData
                                            .warehouseImageRemark &&
                                          data.singleFormData
                                            .warehouseImageRemark
                                            .whsCoverImage &&
                                          data.singleFormData
                                            .warehouseImageRemark.whsOutdoors[
                                            index
                                          ] &&
                                          data.singleFormData
                                            .warehouseImageRemark.whsOutdoors[
                                            index
                                          ].outdoor &&
                                          data.singleFormData
                                            .warehouseImageRemark.whsOutdoors[
                                            index
                                          ].outdoor.fastatus === true
                                            ? "okay"
                                            : "not okay"}{" "}
                                        </p>{" "}
                                        <input
                                          disabled
                                          value={
                                            data.singleFormData &&
                                            data.singleFormData
                                              .warehouseImageRemark &&
                                            data.singleFormData
                                              .warehouseImageRemark
                                              .whsCoverImage &&
                                            data.singleFormData
                                              .warehouseImageRemark.whsOutdoors[
                                              index
                                            ] &&
                                            data.singleFormData
                                              .warehouseImageRemark.whsOutdoors[
                                              index
                                            ].outdoor &&
                                            data.singleFormData
                                              .warehouseImageRemark.whsOutdoors[
                                              index
                                            ].outdoor.faremark
                                          }
                                          type="text"
                                          className="form-control bg-white px-4 mb-2 mr-4"
                                          placeholder="Remarks"
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </tr>{" "}
                                </tbody>{" "}
                              </table>{" "}
                            </div>{" "}
                          </>
                        ))}{" "}
                      </>
                    )}
                  />{" "}
                </div>{" "}
              </div>
              <div className={`col-12 mt-4`}>
                <div className="row justify-content-end mb-4"> </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default PhotoPendingForm;
