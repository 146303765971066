import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  roleByPage,
  whsById,
  departmentByPage,
  responseWhs,
  updateWhsUser,
} from "../../store/action/whsUserAction";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";

const EditWHSUser = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { whsId } = useParams();
  const data = useSelector((state) => state.WHS_USER_INFO);

  const [initValue, setInitValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    city: "",
    area: "",
    roleId: "",
    departmentId: "",
    isDepartmentHead: false,
  });

  const whsSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phone: Yup.string().max(10, "Must be 10 digits").required("Required"),
    roleId: Yup.string().required("Required"),
    departmentId: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (data.whsUserDetail) {
      setInitValue({
        ...data.whsUserDetail,
        roleId: data.whsUserDetail?.accountRole?.id,
        departmentId: data.whsUserDetail?.department?.id,
      });
    }
  }, [data.whsUserDetail]);

  useEffect(() => {
    dispatch(roleByPage("true"));
    dispatch(departmentByPage("true"));
  }, [dispatch]);

  const redirect = () => {
    dispatch(responseWhs(null));
    setModalShow(false);
    history.replace(`/wsh-user`);
  };

  useEffect(() => {
    dispatch(whsById(whsId));
  }, [dispatch, whsId]);

  return (
    <LayoutOne>
      {data.whsResponse !== null ? (
        <FormSuccess onClick={redirect} message={`User Updated`} />
      ) : null}

      <Formik
        enableReinitialize={true}
        validationSchema={whsSchema}
        initialValues={initValue}
        onSubmit={(fields) => {
          // delete fields['confirmPassword'];
          console.log("Fields==>", fields);

          dispatch(updateWhsUser(whsId, fields));
        }}
        render={({
          values,
          setFieldValue,
          errors,
          status,
          onChange,
          touched,
        }) => {
          return (
            <Form>
              <div className="row align-items-center px-3 mx-0">
                <div className="col-12 py-3">
                  <h5 className="text-dark">
                    {" "}
                    <i
                      onClick={() => history.goBack()}
                      className="fas fa-chevron-left cursorPointer pl-0"
                    >
                      {" "}
                    </i>{" "}
                    Edit User
                  </h5>
                </div>{" "}
                <div className="col-12 bg-white p-3">
                  <div className="row">
                    <div class="form-group col-lg-4 col-md-6 mb-4">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        First Name{" "}
                      </label>{" "}
                      <Field
                        name="firstName"
                        type="text"
                        class={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.firstName && touched.firstName
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`firstName`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div class="form-group col-lg-4 col-md-6 mb-4">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Last Name{" "}
                      </label>{" "}
                      <Field
                        name="lastName"
                        type="text"
                        class={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.lastName && touched.lastName
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`lastName`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div class="form-group col-lg-4 col-md-6 mb-4">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Email Id{" "}
                      </label>{" "}
                      <Field
                        name="email"
                        type="text"
                        class={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`email`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div class="form-group col-lg-4 col-md-6 mb-4">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Mobile No.{" "}
                      </label>{" "}
                      <Field
                        name="phone"
                        type="number"
                        class={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.phone && touched.phone ? " is-invalid" : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`phone`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    {/* <div class="form-group col-lg-4 col-md-6 mb-4">
                                                    <label for="staticEmail" class="mb-2 pl-3">City</label>
                                                    <Field name="city" type="text" class={`form-control form-control-md px-4 rounded-pill`+ (errors.city && touched.city ? ' is-invalid' : '')} id="staticEmail"  />
                                                    <ErrorMessage name={`city`} component="div" className="invalid-feedback" />
                                                  </div>
                                                  <div class="form-group col-lg-4 col-md-6 mb-4">
                                                    <label for="staticEmail" class="mb-2 pl-3">Area</label>
                                                    <Field name="area" type="text" class={`form-control form-control-md px-4 rounded-pill`+ (errors.area && touched.area ? ' is-invalid' : '')} id="staticEmail" />
                                                    <ErrorMessage name={`area`} component="div" className="invalid-feedback" />
                                                  </div>  */}{" "}
                    <div class="form-group col-lg-4 col-md-6 mb-4">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Role{" "}
                      </label>{" "}
                      <Field
                        name="roleId"
                        as="select"
                        class={
                          `form-control form-control-md bg-white px-4 common-select-deep-blue rounded-pill` +
                          (errors.roleId && touched.roleId ? " is-invalid" : "")
                        }
                        id="exampleFormControlSelect1"
                        onChange={(e) => {
                          setFieldValue("roleId", parseInt(e.target.value));
                        }}
                      >
                        <option> Select </option>{" "}
                        {data.roleList && data.roleList.length > 0
                          ? data.roleList.map((item, index) => (
                              <option value={item.id} key={index}>
                                {" "}
                                {item.name}{" "}
                              </option>
                            ))
                          : null}{" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`roleId`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div class="form-group col-lg-4 col-md-6 mb-4">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Department{" "}
                      </label>{" "}
                      <Field
                        name="departmentId"
                        as="select"
                        class={
                          `form-control form-control-md bg-white common-select-deep-blue rounded-pill` +
                          (errors.departmentId && touched.departmentId
                            ? " is-invalid"
                            : "")
                        }
                        id="exampleFormControlSelect1"
                        onChange={(e) => {
                          setFieldValue(
                            "departmentId",
                            parseInt(e.target.value)
                          );
                        }}
                      >
                        <option> Select </option>{" "}
                        {data.departmentList && data.departmentList.length > 0
                          ? data.departmentList.map((item, index) => (
                              <option value={item.id} key={index}>
                                {" "}
                                {item.name}{" "}
                              </option>
                            ))
                          : null}{" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`departmentId`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div className="row align-items-center py-3"> 
                                                            <div className="col-12 py-3">
                                                              <h5 className="text-dark"><i className="fas px-2 mr-3"></i> Update Password</h5>
                                                            </div>
                                                          </div> */}{" "}
              <div className="row align-items-center pb-3 px-3 mx-0">
                <div className="col-12 bg-white p-3">
                  <div className="row">
                    <div class="form-group col-lg-6 pl-4">
                      <div class="common-checkbox common-checkbox-dark position-relative mx-auto d-inline-block">
                        <Field
                          name="isDepartmentHead"
                          class="common-checkbox-input common-checkbox-dark-input"
                          type="checkbox"
                          onChange={(e) => {
                            setFieldValue(
                              "isDepartmentHead",
                              !values.isDepartmentHead
                            );
                          }}
                          checked={values.isDepartmentHead}
                          id="departmentalHead"
                        />
                        <label
                          class="common-checkbox-label common-checkbox-dark-label mr-3 pl-4"
                          for="departmentalHead"
                        >
                          {" "}
                          Departmental head{" "}
                        </label>
                      </div>{" "}
                    </div>

                    <div class="form-group col-lg-6 pl-4">
                      <div class="common-checkbox common-checkbox-dark position-relative mx-auto d-inline-block">
                        <Field
                          name="keyContactDetail"
                          class="common-checkbox-input common-checkbox-dark-input"
                          type="checkbox"
                          onChange={(e) => {
                            setFieldValue(
                              "keyContactDetail",
                              !values.keyContactDetail
                            );
                          }}
                          checked={values.keyContactDetail}
                          id="keyContactDetail"
                        />
                        <label
                          class="common-checkbox-label common-checkbox-dark-label mr-3 pl-4"
                          for="keyContactDetail"
                        >
                          {" "}
                          Assign Customer Support{" "}
                        </label>
                      </div>{" "}
                    </div>
                  </div>{" "}
                  <div className="row">
                    <div className="col-auto">
                      <button
                        onClick={() => history.goBack()}
                        className="btn btn-outline-deep-blue py-2"
                      >
                        {" "}
                        Back{" "}
                      </button>{" "}
                    </div>{" "}
                    <div className="col-auto">
                      <button
                        type="button"
                        onClick={() => setModalShow(true)}
                        className="btn btn-outline-deep-blue py-2"
                      >
                        {" "}
                        Change Password{" "}
                      </button>{" "}
                    </div>{" "}
                    <div className="col-auto">
                      <button
                        type="submit"
                        disabled={data.isPending}
                        className="btn btn-deep-blue py-2"
                      >
                        Update{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            </Form>
          );
        }}
      />

      {/* ============================================== */}

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create New Password{" "}
          </Modal.Title>{" "}
        </Modal.Header>{" "}
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              newPassword: Yup.string().required("Required"),
              confirmPassword: Yup.string()
                .required("Required")
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
            })}
            initialValues={{
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={(fields) => {
              // delete fields['confirmPassword'];
              console.log("Fields==>", fields);
              dispatch(updateWhsUser(whsId, fields));
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="form-group col-lg-6 col-md-6 mb-4">
                      <label for="staticEmail" className="mb-2 pl-3">
                        {" "}
                        New Password{" "}
                      </label>{" "}
                      <div className={`input-group append rounded-pill mb-1`}>
                        <Field
                          name="newPassword"
                          type="password"
                          className={`form-control form-control-md px-4`}
                          id="staticEmail"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text bg-white">
                            {" "}
                            <i className="fas fa-eye"> </i>
                          </span>
                        </div>{" "}
                      </div>{" "}
                      <span className="formikError">
                        {" "}
                        {errors.newPassword}{" "}
                      </span>
                    </div>{" "}
                    <div className="form-group col-lg-6 col-md-6 mb-4">
                      <label for="staticEmail" className="mb-2 pl-3">
                        {" "}
                        Confirm Password{" "}
                      </label>{" "}
                      <div className={`input-group append rounded-pill mb-1`}>
                        <Field
                          name="confirmPassword"
                          type="password"
                          className={`form-control form-control-md px-4`}
                          id="staticEmail"
                        />
                        <div className="input-group-append">
                          <span className="input-group-text bg-white">
                            {" "}
                            <i className="fas fa-eye"> </i>
                          </span>
                        </div>{" "}
                      </div>{" "}
                      <span className="formikError">
                        {" "}
                        {errors.confirmPassword}{" "}
                      </span>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row">
                    <div className="col mx-auto">
                      <button
                        type="submit"
                        className="btn btn-outline-deep-blue"
                      >
                        {" "}
                        Save{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>
                </Form>
              );
            }}
          />
        </Modal.Body>
      </Modal>

      {/* ================================================= */}
    </LayoutOne>
  );
};

export default EditWHSUser;
