import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import {
  customerUserById,
  vendorUserById,
} from "../../store/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { CardLoader } from "../../components/helper/CustomLoader";

const UserDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams();
  const user = useSelector((state) => state.USER_INFO);

  const isCustomer = new URLSearchParams(window.location.search).get(
    "customer"
  );

  useEffect(() => {
    if (isCustomer === "true") {
      dispatch(customerUserById(userId));
    } else {
      dispatch(vendorUserById(userId));
    }
  }, [dispatch, userId, isCustomer]);

  return (
    <LayoutOne>
      <div class="row align-items-center pb-3 mx-0">
        <div class="col-12 py-2">
          <h5
            onClick={() => history.goBack()}
            class="text-dark backButton font-weight-bold"
          >
            {" "}
            <i class="fas fa-chevron-left cursorPointer"> </i> View Vendor
            Details
          </h5>
        </div>{" "}
        <div class="col-12">
          <div class="row mx-0">
            {user.isLoading ? (
              <CardLoader />
            ) : (
              <div class="col-12 bg-white rounded">
                {" "}
                {user.userDetail ? (
                  <div class="card card-body border-0">
                    <div class="d-flex">
                      <div class="mw-200px px-3 py-2"> Contact person </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {user.userDetail?.contactPerson}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 py-2"> Company name </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {user.userDetail?.companyName}{" "}
                      </div>{" "}
                    </div>{" "}
                    {/* <div class="d-flex">
                                              <div class="mw-200px px-3 py-2">City</div>
                                              <div class="mw-300px px-3 py-2 text-gray">{"user.city"}</div>
                                            </div>
                                            <div class="d-flex">
                                              <div class="mw-200px px-3 py-2">Area</div>
                                              <div class="mw-300px px-3 py-2 text-gray">{"user.area"}</div>
                                            </div> */}{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 py-2">
                        {" "}
                        {isCustomer === "true"
                          ? "Customer ID"
                          : "Vendor ID"}{" "}
                      </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {isCustomer === "true"
                          ? user.userDetail?.customerId
                          : user.userDetail?.vendorId}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 py-2"> Contact No. </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {user.userDetail?.contactNumber}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 py-2"> Email ID </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {user.userDetail?.email}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 py-2"> Type </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray text-capitalize">
                        {" "}
                        {user.userDetail?.type}{" "}
                      </div>{" "}
                    </div>{" "}
                    {/* <div class="d-flex">
                                              <div class="mw-200px px-3 py-2">Reference By</div>
                                              <div class="mw-300px px-3 py-2 text-gray">ravish@gmail.com</div>
                                            </div> */}{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 py-2"> Status </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {user.userDetail?.status ? "Active" : "In Active"}{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                ) : null}
              </div>
            )}{" "}
          </div>{" "}
          <div class="row">
            <div class="col-12 my-2">
              <span
                onClick={() => history.goBack()}
                class="btn py-2 btn-outline-deep-blue"
              >
                {" "}
                Back{" "}
              </span>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default UserDetails;
