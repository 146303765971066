import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  updateWarehouseContact,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import { Formik, Form, Field, FieldArray } from "formik";
import FormSuccess from "./../../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";

const ContactPendingForm = ({ warehouseId, viewMood }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  console.log("rohanhu", data);
  const onSubmit = (fields) => {
    const payload = {
      data: {
        warehouseContactDetailRemark: {
          ...fields,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateWarehouseContact(payload));
  };

  const validationSchema = function (values) {
    return Yup.object().shape({
      contactInfo: Yup.array().of(
        Yup.object().shape({
          name: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
          phone: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
          email: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      address: Yup.object().shape({
        line1: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        addressLine2: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        city: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        state: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        district: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        country: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        pinCode: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        latitude: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        longnitude: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        // etc
      }),
    });
  };
  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const [remarkForm, setRemarkForm] = useState({
    contactInfo: [
      {
        name: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[0].name
              : ""
            : "",
          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        phone: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[0].phone
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        email: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[0].email
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        contactType: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[0].contactType
              : ""
            : "",

          whsstatus: "contact",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        name: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[2].name
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        phone: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[2].phone
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        email: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[2].email
              : ""
            : "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
        contactType: {
          value: data.singleFormData
            ? data.singleFormData.contactInfo &&
              data.singleFormData.contactInfo.length > 0
              ? data.singleFormData.contactInfo[3].contactType
              : ""
            : "",

          whsstatus: "alternateContact",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    address: {
      line1: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.line1
            : "",
        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      addressLine2: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.addressLine2
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      city: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.city
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      state: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.state
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      district: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.district
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      country: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.country
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      pinCode: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.pinCode
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      landmark: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.landmark
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      latitude: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.latitude
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      longnitude: {
        value:
          data.singleFormData && data.singleFormData.address
            ? data.singleFormData.address.longnitude
            : "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
    },
    companyName: {
      value: "",
      whsstatus: true,
      whsremark: "compnayname",
      fastatus: "null",
      faremark: "",
    },
    id: warehouseId,
  });
  const [formField, setFormField] = useState(null);

  useEffect(() => {
    if (
      data.singleFormData &&
      data.singleFormData !== null &&
      data.singleFormData.address !== null &&
      data.type === "warehouseContactDetails"
    ) {
      setFormField(data.singleFormData.warehouseContactDetailInfo);

      if (data.singleFormData.warehouseContactDetailRemark) {
        setRemarkForm({
          contactInfo: [
            {
              name: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].name
                    : ""
                  : "",
                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].name.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].name.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].name.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].name.faremark
                    : ""
                  : "",
              },
              phone: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].phone
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].phone.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].phone.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].phone.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].phone.faremark
                    : ""
                  : "",
              },
              email: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].email
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].email.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].email.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].email.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].email.faremark
                    : ""
                  : "",
              },
              contactType: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].contactType
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].contactType.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].contactType.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].contactType.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[0].contactType.faremark
                    : ""
                  : "",
              },
            },
            {
              name: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].name
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].name.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].name.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].name.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].name.faremark
                    : ""
                  : "",
              },
              phone: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].phone
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].phone.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].phone.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].phone.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].phone.faremark
                    : ""
                  : "",
              },
              email: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].email
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].email.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].email.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].email.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].email.faremark
                    : ""
                  : "",
              },
              contactType: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[3].contactType
                    : ""
                  : "",

                whsstatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].contactType.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 0
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].contactType.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].contactType.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.warehouseContactDetailRemark
                  ? data.singleFormData.warehouseContactDetailRemark
                      .contactInfo &&
                    data.singleFormData.warehouseContactDetailRemark.contactInfo
                      .length > 1
                    ? data.singleFormData.warehouseContactDetailRemark
                        .contactInfo[1].contactType.faremark
                    : ""
                  : "",
              },
            },
          ],
          address: {
            line1: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.line1
                  : "",
              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .line1.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .line1.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .line1.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .line1.faremark
                  : "",
            },
            addressLine2: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.addressLine2
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .addressLine2.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .addressLine2.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .addressLine2.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .addressLine2.faremark
                  : "",
            },
            city: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.city
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .city.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .city.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .city.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .city.faremark
                  : "",
            },
            state: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.state
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .state.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .state.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .state.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .state.faremark
                  : "",
            },
            district: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.district
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .district.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .district.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .district.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .district.faremark
                  : "",
            },
            country: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.country
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .country.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .country.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .country.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .country.faremark
                  : "",
            },
            pinCode: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.pinCode
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .pinCode.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .pinCode.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .pinCode.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .pinCode.faremark
                  : "",
            },
            landmark: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.landmark
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .landmark.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .landmark.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .landmark.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .landmark.faremark
                  : "",
            },
            latitude: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.latitude
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .latitude.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .latitude.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .latitude.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .latitude.faremark
                  : "",
            },
            longnitude: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.longnitude
                  : "",

              whsstatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .longnitude.whsstatus
                  : "",
              whsremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .longnitude.whsremark
                  : "",
              fastatus:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .longnitude.fastatus
                  : "",
              faremark:
                data.singleFormData.warehouseContactDetailRemark &&
                data.singleFormData.warehouseContactDetailRemark.address
                  ? data.singleFormData.warehouseContactDetailRemark.address
                      .longnitude.faremark
                  : "",
            },
          },
          companyName: {
            value: "",
            whsstatus: true,
            whsremark: "compnayname",
            fastatus: "null",
            faremark: "",
          },
        });
      } else {
        setRemarkForm({
          contactInfo: [
            {
              name: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].name
                    : ""
                  : "",
                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              phone: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].phone
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              email: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].email
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              contactType: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[0].contactType
                    : ""
                  : "",

                whsstatus: "contact",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              name: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].name
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              phone: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].phone
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              email: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[2].email
                    : ""
                  : "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
              contactType: {
                value: data.singleFormData
                  ? data.singleFormData.contactInfo &&
                    data.singleFormData.contactInfo.length > 0
                    ? data.singleFormData.contactInfo[3].contactType
                    : ""
                  : "",

                whsstatus: "alternateContact",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
          ],
          address: {
            line1: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.line1
                  : "",
              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            addressLine2: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.addressLine2
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            city: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.city
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            state: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.state
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            district: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.district
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            country: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.country
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            pinCode: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.pinCode
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            landmark: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.landmark
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            latitude: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.latitude
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            longnitude: {
              value:
                data.singleFormData && data.singleFormData.address
                  ? data.singleFormData.address.longnitude
                  : "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
          },
          companyName: {
            value: "",
            whsstatus: true,
            whsremark: "compnayname",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}{" "}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => {
          console.log("hiran");
          onSubmit(fields);
        }}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setValues,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-xl-12 rounded-lg">
                <div className="row">
                  <FieldArray
                    name="contactInfo"
                    render={(arrayHelpers) => (
                      <>
                        {" "}
                        {values.contactInfo.map((value, index) => (
                          <>
                            <div className="col-xl-6">
                              <div className="col-12">
                                <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 px-2"
                                  >
                                    {" "}
                                    {index === 0
                                      ? "Warehouse Name:"
                                      : "Company Name:"}{" "}
                                  </label>{" "}
                                  <input
                                    value={
                                      formField
                                        ? formField.contactInfo &&
                                          formField.contactInfo.length > 0
                                          ? formField.contactInfo[index].name
                                          : ""
                                        : ""
                                    }
                                    className="form-control px-4"
                                    disabled
                                  />
                                </div>{" "}
                              </div>{" "}
                              <div className="col-12">
                                <div className="row align-items-center my-3">
                                  <div className="col-auto form-inline form-group form-inline">
                                    <div className="form-check common-radio-deep-blue mx-3">
                                      <Field
                                        name={`contactInfo[${index}].name.whsstatus`}
                                        className="common-radio-deep-blue-input"
                                        id={"okay" + index}
                                        type="radio"
                                        value="okay"
                                        required
                                        checked={
                                          values.contactInfo
                                            ? values.contactInfo[index].name
                                                .whsstatus === true
                                            : ""
                                        }
                                        disabled={viewMood}
                                        onChange={() => {
                                          let a = `contactInfo[${index}].name.whsstatus`;
                                          setFieldValue(a, true);
                                        }}
                                      />{" "}
                                      <label
                                        className="common-radio-deep-blue-label pl-sm-4"
                                        htmlFor={"okay" + index}
                                      >
                                        Okay{" "}
                                      </label>
                                    </div>{" "}
                                    <div className="form-check common-radio-deep-blue mx-3">
                                      <Field
                                        name={`contactInfo[${index}].name.whsstatus`}
                                        className="common-radio-deep-blue-input"
                                        id={"not-okay" + index}
                                        type="radio"
                                        value="notOkay"
                                        required
                                        checked={
                                          values.contactInfo
                                            ? values.contactInfo[index].name
                                                .whsstatus === false
                                            : ""
                                        }
                                        disabled={viewMood}
                                        onChange={() => {
                                          let a = `contactInfo[${index}].name.whsstatus`;
                                          setFieldValue(a, false);
                                        }}
                                      />{" "}
                                      <label
                                        className="common-radio-deep-blue-label pl-sm-4"
                                        htmlFor={"not-okay" + index}
                                      >
                                        Not Okay{" "}
                                      </label>
                                    </div>{" "}
                                  </div>{" "}
                                  <div className="col-auto ml-3">
                                    <Field
                                      type="text"
                                      disabled={viewMood}
                                      name={`contactInfo[${index}].name.whsremark`}
                                      className="form-control bg-white px-2 ml-xl-0 ml-2 mb-2"
                                      placeholder="Remarks"
                                    />
                                  </div>{" "}
                                </div>{" "}
                                <div className="row justify-content-xl-end my-3">
                                  <div className="col-auto">
                                    <p className="px-3 py-2">
                                      {" "}
                                      Field Agent Remarks{" "}
                                    </p>{" "}
                                  </div>
                                  <div className="col-auto form-inline form-group form-inline">
                                    <p className="py-2">
                                      {" "}
                                      {formField &&
                                      formField.warehouseContactDetailRemark &&
                                      formField.warehouseContactDetailRemark
                                        .contactInfo.length > 0 &&
                                      formField.warehouseContactDetailRemark
                                        .contactInfo[index].name.fastatus ===
                                        true
                                        ? "okay"
                                        : "not okay"}{" "}
                                    </p>{" "}
                                  </div>{" "}
                                  <div className="col-auto">
                                    <input
                                      value={
                                        formField &&
                                        formField.warehouseContactDetailRemark &&
                                        formField.warehouseContactDetailRemark
                                          .contactInfo.length > 0 &&
                                        formField.warehouseContactDetailRemark
                                          .contactInfo[index].name.faremark
                                      }
                                      type="text"
                                      className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                      placeholder="Remarks"
                                      disabled
                                    />
                                  </div>{" "}
                                </div>{" "}
                              </div>{" "}
                            </div>
                          </>
                        ))}{" "}
                      </>
                    )}
                  />{" "}
                </div>
                <div className="row">
                  {values.contactInfo.map((value, index) => (
                    <>
                      <div className="col-xl-6">
                        <div className="col-12">
                          <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                            <label htmlFor="staticEmail" className="mb-2 px-2">
                              {" "}
                              {index === 0
                                ? "Mobile:"
                                : "Alternate Mobile:"}{" "}
                            </label>{" "}
                            <input
                              value={
                                formField
                                  ? formField.contactInfo &&
                                    formField.contactInfo.length > 0
                                    ? formField.contactInfo[index].phone
                                    : ""
                                  : ""
                              }
                              className="form-control px-4"
                              disabled
                            />
                          </div>{" "}
                        </div>{" "}
                        <div className="col-12">
                          <div className="row align-items-center my-3">
                            <div className="col-auto form-inline form-group form-inline">
                              <div className="form-check common-radio-deep-blue mx-3">
                                <Field
                                  name={`contactInfo[${index}].phone.whsstatus`}
                                  className="common-radio-deep-blue-input"
                                  id={"okay1" + index}
                                  type="radio"
                                  value="okay"
                                  required
                                  disabled={viewMood}
                                  checked={
                                    values.contactInfo
                                      ? values.contactInfo[index].phone
                                          .whsstatus === true
                                      : ""
                                  }
                                  onChange={() => {
                                    let a = `contactInfo[${index}].phone.whsstatus`;
                                    setFieldValue(a, true);
                                  }}
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor={"okay1" + index}
                                >
                                  Okay{" "}
                                </label>
                              </div>{" "}
                              <div className="form-check common-radio-deep-blue mx-3">
                                <Field
                                  name={`contactInfo[${index}].phone.whsstatus`}
                                  className="common-radio-deep-blue-input"
                                  id={"not-okay1" + index}
                                  type="radio"
                                  value="notOkay"
                                  disabled={viewMood}
                                  required
                                  checked={
                                    values.contactInfo
                                      ? values.contactInfo[index].phone
                                          .whsstatus === false
                                      : ""
                                  }
                                  onChange={() => {
                                    let a = `contactInfo[${index}].phone.whsstatus`;
                                    setFieldValue(a, false);
                                  }}
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor={"not-okay1" + index}
                                >
                                  Not Okay{" "}
                                </label>
                              </div>{" "}
                            </div>{" "}
                            <div className="col-auto ml-3">
                              <Field
                                type="text"
                                disabled={viewMood}
                                name={`contactInfo[${index}].phone.whsremark`}
                                className="form-control bg-white px-2 ml-xl-0 ml-2 mb-2"
                                placeholder="Remarks"
                              />
                            </div>{" "}
                          </div>
                          <div className="row justify-content-xl-end my-3">
                            <div className="col-auto">
                              <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                            </div>
                            <div className="col-auto form-inline form-group form-inline">
                              <p className="py-2">
                                {" "}
                                {formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .contactInfo.length > 0 &&
                                formField.warehouseContactDetailRemark
                                  .contactInfo[index].phone.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                            </div>{" "}
                            <div className="col-auto">
                              <input
                                value={
                                  formField &&
                                  formField.warehouseContactDetailRemark &&
                                  formField.warehouseContactDetailRemark
                                    .contactInfo.length > 0 &&
                                  formField.warehouseContactDetailRemark
                                    .contactInfo[index].phone.faremark
                                }
                                type="text"
                                className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                placeholder="Remarks"
                                disabled
                              />
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </>
                  ))}{" "}
                </div>
                <div className="row">
                  {values.contactInfo.map((value, index) => (
                    <>
                      <div className="col-xl-6">
                        <div className="col-12">
                          <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                            <label htmlFor="staticEmail" className="mb-2 px-2">
                              {" "}
                              {index === 0 ? "Email:" : "Alternate Email:"}{" "}
                            </label>{" "}
                            <input
                              value={
                                formField
                                  ? formField.contactInfo &&
                                    formField.contactInfo.length > 0
                                    ? formField.contactInfo[index].email
                                    : ""
                                  : ""
                              }
                              className="form-control px-4"
                              disabled
                            />
                          </div>{" "}
                        </div>{" "}
                        <div className="col-12">
                          <div className="row align-items-center my-3">
                            <div className="col-auto form-inline form-group form-inline">
                              <div className="form-check common-radio-deep-blue mx-3">
                                <Field
                                  name={`contactInfo[${index}].email.whsstatus`}
                                  className="common-radio-deep-blue-input"
                                  id={"okay11" + index}
                                  type="radio"
                                  value="okay"
                                  required
                                  disabled={viewMood}
                                  checked={
                                    values.contactInfo
                                      ? values.contactInfo[index].email
                                          .whsstatus === true
                                      : ""
                                  }
                                  onChange={() => {
                                    let a = `contactInfo[${index}].email.whsstatus`;
                                    setFieldValue(a, true);
                                  }}
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor={"okay11" + index}
                                >
                                  Okay{" "}
                                </label>
                              </div>{" "}
                              <div className="form-check common-radio-deep-blue mx-3">
                                <Field
                                  name={`contactInfo[${index}].email.whsstatus`}
                                  className="common-radio-deep-blue-input"
                                  id={"not-okay11" + index}
                                  type="radio"
                                  value="notOkay"
                                  required
                                  disabled={viewMood}
                                  checked={
                                    values.contactInfo
                                      ? values.contactInfo[index].email
                                          .whsstatus === false
                                      : ""
                                  }
                                  onChange={() => {
                                    let a = `contactInfo[${index}].email.whsstatus`;
                                    setFieldValue(a, false);
                                  }}
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor={"not-okay11" + index}
                                >
                                  Not Okay{" "}
                                </label>
                              </div>{" "}
                            </div>{" "}
                            <div className="col-auto ml-3">
                              <Field
                                type="text"
                                disabled={viewMood}
                                name={`contactInfo[${index}].email.whsremark`}
                                className="form-control bg-white px-2 ml-xl-0 ml-2 mb-2"
                                placeholder="Remarks"
                              />
                            </div>{" "}
                          </div>{" "}
                          <div className="row justify-content-xl-end my-3">
                            <div className="col-auto">
                              <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                            </div>
                            <div className="col-auto form-inline form-group form-inline">
                              <p className="py-2">
                                {" "}
                                {formField &&
                                formField.warehouseContactDetailRemark &&
                                formField.warehouseContactDetailRemark
                                  .contactInfo.length > 0 &&
                                formField.warehouseContactDetailRemark
                                  .contactInfo[index].email.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                            </div>{" "}
                            <div className="col-auto">
                              <input
                                value={
                                  formField &&
                                  formField.warehouseContactDetailRemark &&
                                  formField.warehouseContactDetailRemark
                                    .contactInfo.length > 0 &&
                                  formField.warehouseContactDetailRemark
                                    .contactInfo[index].email.faremark
                                }
                                type="text"
                                className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                placeholder="Remarks"
                                disabled
                              />
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    </>
                  ))}{" "}
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          Address 1:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.line1
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.line1.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="categorysOkay"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.line1
                                  ? values.address.line1.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue("address.line1.whsstatus", true)
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="categorysOkay"
                            >
                              Okay{" "}
                            </label>{" "}
                            {/* <input className="common-radio-deep-blue-input" type="radio" name="spaceprovidertypeOptions" id="okay1" value="option2" hidden="" />
                                              <label className="common-radio-deep-blue-label pl-sm-4" htmlFor="okay1">Okay</label> */}{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.line1.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="categorysNotOkay"
                              type="radio"
                              value="notOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.line1
                                  ? values.address.line1.whsstatus === false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue("address.line1.whsstatus", false)
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="categorysNotOkay"
                            >
                              Not Okay{" "}
                            </label>{" "}
                            {/* <input className="common-radio-deep-blue-input" type="radio" name="spaceprovidertypeOptions" id="not-okay1" value="option2" hidden="" />
                                              <label className="common-radio-deep-blue-label pl-sm-4" htmlFor="not-okay1">Not Okay</label> */}{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            disabled={viewMood}
                            name={`address.line1.whsremark`}
                            type="text"
                            className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>
                      <div className="row justify-content-xl-end my-3">
                        <div className="col-auto">
                          <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                        </div>
                        <div className="col-auto form-inline form-group form-inline">
                          <p className="py-2">
                            {" "}
                            {formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address.line1
                              .fastatus === true
                              ? "okay"
                              : "not okay"}{" "}
                          </p>{" "}
                        </div>{" "}
                        <div className="col-auto">
                          <input
                            value={
                              formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .line1.faremark
                            }
                            type="text"
                            className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          Address 2:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.line2
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.addressLine2.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="categoryNotOkays"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.addressLine2
                                  ? values.address.addressLine2.whsstatus ===
                                    true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.addressLine2.whsstatus",
                                  true
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="categoryNotOkays"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.addressLine2.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="categoryNotOkayss"
                              type="radio"
                              value="NotOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.addressLine2
                                  ? values.address.addressLine2.whsstatus ===
                                    false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.addressLine2.whsstatus",
                                  false
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="categoryNotOkayss"
                            >
                              Not Okay{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            disabled={viewMood}
                            name={`address.addressLine2.whsremark`}
                            type="text"
                            className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row justify-content-xl-end my-3">
                      <div className="col-auto">
                        <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                      </div>
                      <div className="col-auto form-inline form-group form-inline">
                        <p className="py-2">
                          {" "}
                          {formField &&
                          formField.warehouseContactDetailRemark &&
                          formField.warehouseContactDetailRemark.address &&
                          formField.warehouseContactDetailRemark.address
                            .addressLine2.fastatus === true
                            ? "okay"
                            : "not okay"}{" "}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <input
                          value={
                            formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address
                              .addressLine2.faremark
                          }
                          type="text"
                          className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          State:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.state
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.state.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="dsssssssssff"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.state
                                  ? values.address.state.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue("address.state.whsstatus", true)
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="dsssssssssff"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.state.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="dsssssssssnn"
                              type="radio"
                              value="notOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.state
                                  ? values.address.state.whsstatus === false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue("address.state.whsstatus", false)
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="dsssssssssnn"
                            >
                              Not Okay{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            name={`address.state.whsremark`}
                            disabled={viewMood}
                            type="text"
                            className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="row justify-content-xl-end my-3">
                        <div className="col-auto">
                          <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                        </div>
                        <div className="col-auto form-inline form-group form-inline">
                          <p className="py-2">
                            {" "}
                            {formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address.state
                              .fastatus === true
                              ? "okay"
                              : "not okay"}{" "}
                          </p>{" "}
                        </div>{" "}
                        <div className="col-auto">
                          <input
                            value={
                              formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .state.faremark
                            }
                            type="text"
                            className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          District:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.district
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.district.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="uudsssssssssff"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.district
                                  ? values.address.district.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.district.whsstatus",
                                  true
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="uudsssssssssff"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.district.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="dsssssssssnnuu"
                              type="radio"
                              value="notOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.district
                                  ? values.address.district.whsstatus === false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.district.whsstatus",
                                  false
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="dsssssssssnnuu"
                            >
                              Not Okay{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            disabled={viewMood}
                            name={`address.district.whsremark`}
                            type="text"
                            className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="row justify-content-xl-end my-3">
                        <div className="col-auto">
                          <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                        </div>
                        <div className="col-auto form-inline form-group form-inline">
                          <p className="py-2">
                            {" "}
                            {formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address
                              .district.fastatus === true
                              ? "okay"
                              : "not okay"}{" "}
                          </p>{" "}
                        </div>{" "}
                        <div className="col-auto">
                          <input
                            value={
                              formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .district.faremark
                            }
                            type="text"
                            className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                </div>{" "}
                <div className="row">
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          Country:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.country
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.country.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="dsssssssss"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.country
                                  ? values.address.country.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue("address.country.whsstatus", true)
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="dsssssssss"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.country.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="dsssssssssxd"
                              type="radio"
                              value="notOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.country
                                  ? values.address.country.whsstatus === false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.country.whsstatus",
                                  false
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="dsssssssssxd"
                            >
                              Not Okay{" "}
                            </label>{" "}
                            {/* <input className="common-radio-deep-blue-input" type="radio" name="spaceprovidertypeOptions" id="not-okay1" value="option2" hidden="" />
                                              <label className="common-radio-deep-blue-label pl-sm-4" htmlFor="not-okay1">Not Okay</label> */}{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            disabled={viewMood}
                            name={`address.country.whsremark`}
                            type="text"
                            className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="row justify-content-xl-end my-3">
                        <div className="col-auto">
                          <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                        </div>
                        <div className="col-auto form-inline form-group form-inline">
                          <p className="py-2">
                            {" "}
                            {formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address
                              .country.fastatus === true
                              ? "okay"
                              : "not okay"}{" "}
                          </p>{" "}
                        </div>{" "}
                        <div className="col-auto">
                          <input
                            value={
                              formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .country.faremark
                            }
                            type="text"
                            className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          City:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.city
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.city.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="ccdsssssssssff"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.city
                                  ? values.address.city.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue("address.city.whsstatus", true)
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="ccdsssssssssff"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.city.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="nndsssssssssnn"
                              type="radio"
                              value="notOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.city
                                  ? values.address.city.whsstatus === false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue("address.city.whsstatus", false)
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="nndsssssssssnn"
                            >
                              Not Okay{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            name={`address.city.whsremark`}
                            disabled={viewMood}
                            type="text"
                            className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row justify-content-xl-end my-3">
                      <div className="col-auto">
                        <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                      </div>
                      <div className="col-auto form-inline form-group form-inline">
                        <p className="py-2">
                          {" "}
                          {formField &&
                          formField.warehouseContactDetailRemark &&
                          formField.warehouseContactDetailRemark.address &&
                          formField.warehouseContactDetailRemark.address.city
                            .fastatus === true
                            ? "okay"
                            : "not okay"}{" "}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <input
                          value={
                            formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address.city
                              .faremark
                          }
                          type="text"
                          className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="row">
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          Landmark:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.landmark
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.landmark.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="dsssssssssll"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.landmark
                                  ? values.address.landmark.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.landmark.whsstatus",
                                  true
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="dsssssssssll"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.landmark.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="dsssssssssllsl"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.landmark
                                  ? values.address.landmark.whsstatus === false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.landmark.whsstatus",
                                  false
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="dsssssssssllsl"
                            >
                              Not Okay{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            name={`address.landmark.whsremark`}
                            type="text"
                            disabled={viewMood}
                            className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>
                      <div className="row justify-content-xl-end my-3">
                        <div className="col-auto">
                          <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                        </div>
                        <div className="col-auto form-inline form-group form-inline">
                          <p className="py-2">
                            {" "}
                            {formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address
                              .landmark.fastatus === true
                              ? "okay"
                              : "not okay"}{" "}
                          </p>{" "}
                        </div>{" "}
                        <div className="col-auto">
                          <input
                            value={
                              formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .landmark.faremark
                            }
                            type="text"
                            className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          Pincode:{" "}
                        </label>{" "}
                        <input
                          className="form-control px-4"
                          value={
                            formField && formField.address
                              ? formField.address.pinCode
                              : ""
                          }
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.pinCode.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="pdsssssssssllsl"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.pinCode
                                  ? values.address.pinCode.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue("address.pinCode.whsstatus", true)
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="pdsssssssssllsl"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.pinCode.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="ppdsssssssssllsl"
                              type="radio"
                              value="notOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.pinCode
                                  ? values.address.pinCode.whsstatus === false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.pinCode.whsstatus",
                                  false
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="ppdsssssssssllsl"
                            >
                              Not Okay{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            disabled={viewMood}
                            name={`address.pinCode.whsremark`}
                            type="text"
                            className="form-control bg-white px-2 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row justify-content-xl-end my-3">
                      <div className="col-auto">
                        <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                      </div>
                      <div className="col-auto form-inline form-group form-inline">
                        <p className="py-2">
                          {" "}
                          {formField &&
                          formField.warehouseContactDetailRemark &&
                          formField.warehouseContactDetailRemark.address &&
                          formField.warehouseContactDetailRemark.address.pinCode
                            .fastatus === true
                            ? "okay"
                            : "not okay"}{" "}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <input
                          value={
                            formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address
                              .pinCode.faremark
                          }
                          type="text"
                          className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          Warehouse GPS Location-latitude:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.latitude
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="px-3">
                        {" "}
                        <iframe
                          width="100%"
                          height="200"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                          src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=escale%20solution+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                          title="lat"
                        >
                          {" "}
                        </iframe>
                        <Link to="https:/ / www.maps.ie / route-planner.htm "></Link>
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.latitude.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="ltdsssssssssllsl"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.latitude
                                  ? values.address.latitude.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.latitude.whsstatus",
                                  true
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="ltdsssssssssllsl"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.latitude.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="xxdsssssssssllsl"
                              type="radio"
                              value="notOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.latitude
                                  ? values.address.latitude.whsstatus === false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.latitude.whsstatus",
                                  false
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="xxdsssssssssllsl"
                            >
                              Not Okay{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            disabled={viewMood}
                            name={`address.latitude.whsremark`}
                            type="text"
                            className="form-control bg-white px-3 ml-xl-0 ml-0 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="row justify-content-xl-end my-3">
                        <div className="col-auto">
                          <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                        </div>
                        <div className="col-auto form-inline form-group form-inline">
                          <p className="py-2">
                            {" "}
                            {formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address
                              .latitude.fastatus === true
                              ? "okay"
                              : "not okay"}{" "}
                          </p>{" "}
                        </div>{" "}
                        <div className="col-auto">
                          <input
                            value={
                              formField &&
                              formField.warehouseContactDetailRemark &&
                              formField.warehouseContactDetailRemark.address &&
                              formField.warehouseContactDetailRemark.address
                                .latitude.faremark
                            }
                            type="text"
                            className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-6">
                    <div className="col-12">
                      <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                        <label htmlFor="staticEmail" className="mb-2 px-2">
                          {" "}
                          Warehouse GPS Location-longitude:{" "}
                        </label>{" "}
                        <input
                          value={
                            formField && formField.address
                              ? formField.address.longnitude
                              : ""
                          }
                          className="form-control px-4"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="px-3">
                        {" "}
                        <iframe
                          width="100%"
                          height="200"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                          src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=escale%20solution+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                          title="log"
                        >
                          {" "}
                        </iframe>
                        <Link to="https:/ / www.maps.ie / route-planner.htm "></Link>
                      </div>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row align-items-center my-3">
                        <div className="col-auto form-inline form-group form-inline">
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.longnitude.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="yydsssssssssllsl"
                              type="radio"
                              value="okay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.longnitude
                                  ? values.address.longnitude.whsstatus === true
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.longnitude.whsstatus",
                                  true
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="yydsssssssssllsl"
                            >
                              Okay{" "}
                            </label>{" "}
                          </div>{" "}
                          <div className="form-check common-radio-deep-blue mx-3">
                            <Field
                              name="address.longnitude.whsstatus"
                              className="common-radio-deep-blue-input"
                              id="yyydsssssssssllsl"
                              type="radio"
                              value="notOkay"
                              required
                              disabled={viewMood}
                              checked={
                                values.address.longnitude
                                  ? values.address.longnitude.whsstatus ===
                                    false
                                  : ""
                              }
                              onChange={() =>
                                setFieldValue(
                                  "address.longnitude.whsstatus",
                                  false
                                )
                              }
                            />{" "}
                            <label
                              className="common-radio-deep-blue-label pl-sm-4"
                              htmlFor="yyydsssssssssllsl"
                            >
                              Not Okay{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-auto ml-3">
                          <Field
                            disabled={viewMood}
                            name={`address.longnitude.whsremark`}
                            type="text"
                            className="form-control bg-white px-3 ml-xl-0 ml-2 mb-2"
                            placeholder="Remarks"
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row justify-content-xl-end my-3">
                      <div className="col-auto">
                        <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                      </div>
                      <div className="col-auto form-inline form-group form-inline">
                        <p className="py-2">
                          {" "}
                          {formField &&
                          formField.warehouseContactDetailRemark &&
                          formField.warehouseContactDetailRemark.address &&
                          formField.warehouseContactDetailRemark.address
                            .longnitude.fastatus === true
                            ? "okay"
                            : "not okay"}{" "}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <input
                          value={
                            formField &&
                            formField.warehouseContactDetailRemark &&
                            formField.warehouseContactDetailRemark.address &&
                            formField.warehouseContactDetailRemark.address
                              .longnitude.faremark
                          }
                          type="text"
                          className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className={`col-12 mt-4`}>
                  {" "}
                  {/* <p>{errors?errors['warehouseName.whsstatus']:""}</p> */}
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <button
                        type="submit"
                        className="btn btn-deep-blue add-class remove-class"
                        disabled={data.isPending}
                      >
                        Save{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default ContactPendingForm;
