import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import SearchBox from "../../components/common/SearchBox";
import BrouserTitle from "../../components/common/BrouserTitle";
import Pagination from "react-js-pagination";
import RFQList from "../../wrapper/rfq/RFQList";
import { rfqByPage } from "../../store/action/rfqAction";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import CustomLoader from '../../components/common/CustomLoader';
import { CardLoader } from "../../components/common/CustomLoader";

const ManageRFQ = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.RFQ_INFO);

  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(rfqByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(rfqByPage(pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(rfqByPage(parseInt(pageCount), searchHandler));
  };

  return (
    <LayoutOne>
      <BrouserTitle title="RFQ Management" />
      <SearchBox callSearch={callSearch} setSearchHandler={setSearchHandler} />
      <div className="row align-items-center px-3 mx-0">
        <div className="col-12 px-0">
          <h5 className="text-dark font-weight-bold"> Manage RFQ development </h5>{" "}
        </div>{" "}
        {!data.isLoading ? (
          <div className="col-12 px-0">
            <div className="row align-items-center py-3 px-3 mx-0">
              <div className="col-12 px-0">
                <div className="row">
                  <div className="col-12 text-nowrap table-responsive table-gray-admin bg-white p-3">
                    <table className="table table-dark-custom">
                      <thead>
                        <tr>
                          <th className="text-center"> S.NO </th>{" "}
                          <th className="text-nowrap"> RFQ ID </th>{" "}
                          <th> City </th> <th> Area </th>{" "}
                          <th> Customer Name </th> {/* <th>Type</th> */}{" "}
                          <th> Date </th> <th> Status </th>{" "}
                          {/* <th className="text-nowrap">Open RFQ's</th> */}{" "}
                          <th> Manage </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        {data.rfqList.data && data.rfqList.data.length > 0
                          ? data.rfqList.data.map((data, index) => (
                              <RFQList
                                data={data}
                                index={index + 1}
                                key={index}
                              />
                            ))
                          : null}
                      </tbody>{" "}
                    </table>{" "}
                  </div>{" "}
                </div>

                <div className="pagination-custom">
                  {" "}
                  {data.rfqList.data && data.rfqList.data.length > 0 ? (
                    <Pagination
                      activePage={parseInt(pageCount)}
                      itemsCountPerPage={10}
                      totalItemsCount={data.rfqList.totalCount}
                      pageRangeDisplayed={1}
                      onChange={handlePageChange}
                      prevPageText={<i className="fas fa-chevron-left" />}
                      nextPageText={<i className="fas fa-chevron-right" />}
                      hideFirstLastPages={true}
                    />
                  ) : null}{" "}
                </div>
              </div>{" "}
            </div>
          </div>
        ) : (
          <CardLoader />
        )}
      </div>{" "}
    </LayoutOne>
  );
};

export default ManageRFQ;
