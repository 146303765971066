import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import FormSuccess from '../../../components/common/FormSuccess';
import {
  changeWarehouseStatus1,
  updateWarehousependingDetails,
} from './../../../store/action/warehouseAction';
import CustomLoader from '../../../CustomLoader';
import Spinner from 'react-bootstrap/Spinner';

const MhPendingForm = ({ warehouseId, read, slug }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const [formField, setFormField] = useState(null);

  const onSubmit = (fields) => {
    const payload = {
      data: {
        fields: fields,
      },

      warehouseId: warehouseId,
      slug: slug,
    };
    dispatch(updateWarehousependingDetails(payload));
  };
  const [remarkForm, setRemarkForm] = useState({
    pickingTrolley: {
      value: '',
      whsremark: '',
    },
    hydraulicDockLevler: {
      value: '',
      whsremark: '',
    },
    batteryOperatedPalletTruck: {
      value: '',
      whsremark: '',
    },
    handPalletTruck: {
      value: '',
      whsremark: '',
    },
    electricStacker: {
      value: '',
      whsremark: '',
    },
    shrinkAndStretchWrapMachine: {
      value: '',
      whsremark: '',
    },
    pallets: {
      value: '',
      whsremark: '',
    },
    weighingMachine: {
      value: '',
      whsremark: '',
    },
    forkLifts: [
      {
        type: 'batteryoperatedup',
        forkLift: {
          value: '',
          whsremark: '',
        },
      },
      {
        type: 'dieseloperated',
        forkLift: {
          value: '',
          whsremark: '',
        },
      },
    ],
    hydraCrane: {
      value: '',
      whsremark: '',
    },
    others: {
      value: '',
      whsremark: '',
    },
  });
  useEffect(() => {
    if (
      data?.singleFormData &&
      data?.singleFormData !== null &&
      data?.singleFormData?.remarks !== undefined &&
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks.warehouseMHInfrasRemarks !== undefined &&
      Object.keys(data?.singleFormData?.remarks.warehouseMHInfrasRemarks).length > 0
    ) {
      setRemarkForm(data?.singleFormData?.remarks?.warehouseMHInfrasRemarks);
    } else {
      console.log(data?.singleFormData, '|| MH INFRA||');
      if (
        data?.singleFormData &&
        data?.singleFormData !== null &&
        data?.singleFormData?.warehouseRes !== undefined &&
        data?.singleFormData?.warehouseRes?.warehouseMHInfras !== undefined &&
        data?.singleFormData?.warehouseRes?.warehouseMHInfras !== null
      ) {
        setRemarkForm({
          pickingTrolley: {
            value: data?.singleFormData?.warehouseRes?.warehouseMHInfras[0]?.pickingTrolley,
            whsremark: '',
          },
          hydraulicDockLevler: {
            value: data?.singleFormData?.warehouseRes?.warehouseMHInfras[0].hydraulicDockLevler,
            whsremark: '',
          },
          batteryOperatedPalletTruck: {
            value:
              data?.singleFormData?.warehouseRes?.warehouseMHInfras[0].batteryOperatedPalletTruck,
            whsremark: '',
          },
          handPalletTruck: {
            value: data?.singleFormData?.warehouseRes?.warehouseMHInfras[0].handPalletTruck,
            whsremark: '',
          },
          electricStacker: {
            value: data?.singleFormData?.warehouseRes?.warehouseMHInfras[0].electricStacker,
            whsremark: '',
          },
          shrinkAndStretchWrapMachine: {
            value:
              data?.singleFormData?.warehouseRes?.warehouseMHInfras[0].shrinkAndStretchWrapMachine,
            whsremark: '',
          },
          pallets: {
            value: data?.singleFormData?.warehouseRes?.warehouseMHInfras[0]?.pallets?.quantity,
            whsremark: '',
          },
          weighingMachine: {
            value:
              data?.singleFormData?.warehouseRes?.warehouseMHInfras[0]?.weighingMachine?.validTill,
            whsremark: '',
          },
          forkLifts: [
            {
              type: 'batteryoperatedup',
              forkLift: {
                value:
                  data?.singleFormData?.warehouseRes?.warehouseMHInfras[0]?.forkLiftsYes
                    ?.capacityTon,
                whsremark: '',
              },
            },
            {
              type: 'dieseloperated',
              forkLift: {
                value:
                  data?.singleFormData?.warehouseRes?.warehouseMHInfras[0]?.forkLiftsDieselYes
                    ?.capacityTon,
                whsremark: '',
              },
            },
          ],
          hydraCrane: {
            value:
              data?.singleFormData?.warehouseRes?.warehouseMHInfras[0]?.hydraCrane?.loadCapacity,
            whsremark: '',
          },
          others: {
            value: data?.singleFormData?.warehouseRes?.warehouseMHInfras[0]?.others,
            whsremark: '',
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  // const handleNextPage = () => {
  //   if (data?.addNewResponse && data?.addNewResponse?.statusCode === 200) {
  //     history.push(`/pending-details/${warehouseId}/safety-info`);
  //     dispatch(changeWarehouseStatus1());
  //   }
  // };
  return (
    <>
      {/* {data?.addNewResponse && data?.addNewResponse?.statusCode === 200 ? (
        <FormSuccess onClick={handleNextPage} message={data.addNewResponse.message} />
      ) : null}{' '} */}
      {data?.isLoading === true ? (
        <CustomLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={remarkForm}
          // validate={validate(validationSchema)}
          onSubmit={(fields) => onSubmit(fields)}
          render={({ errors, status, onChange, touched, values, setFieldValue }) => {
            return (
              <Form className='w-100'>
                <div className='col-12 rounded-lg'>
                  <div className='row'>
                    <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col' className='text-center'>
                                Remarks{' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Pallets:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <span className='px-1'>
                                        <Field
                                          disabled={false}
                                          name={`pallets.value`}
                                          type='text'
                                          className='form-control bg-white px-4 mb-2 mr-4'
                                          placeholder='Value'
                                        />
                                      </span>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`pallets.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Hand Pallet Truck(Hydraulic):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {formField && formField.handPalletTruck !== ''
                                          ? 'Yes'
                                          : 'No'}{' '}
                                      </p>{' '}
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={false}
                                        name={`handPalletTruck.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`handPalletTruck.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Weighing Machine:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2 text-nowrap'>Calliberated on:</p>{' '}
                                      <td className='pb-4'>
                                        <label
                                          htmlFor=''
                                          className='mb-0 px-4 small text-uppercase'
                                        >
                                          Valid till{' '}
                                        </label>{' '}
                                        <Field
                                          disabled={false}
                                          name={`weighingMachine.value`}
                                          type='text'
                                          className='form-control bg-white px-4 mb-2 mr-4'
                                          placeholder='value'
                                        />
                                      </td>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`weighingMachine.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Picking Trolley:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={false}
                                        name={`pickingTrolley.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`pickingTrolley.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Hydraulic Dock Levler:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={false}
                                        name={`hydraulicDockLevler.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`hydraulicDockLevler.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        {' '}
                                        Battery Operated Pallet Truck(BOPT){' '}
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={false}
                                        name={`batteryOperatedPalletTruck.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`batteryOperatedPalletTruck.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        {' '}
                                        Forklift(Battery Operated):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2 text-nowrap'> Capacity in ton:</p>{' '}
                                      <Field
                                        disabled={false}
                                        name={`forkLifts[0].forkLift.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`forkLifts[0].forkLift.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        {' '}
                                        Forklift(Diesel Operated):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2 text-nowrap'> Capacity in ton:</p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`forkLifts[1].forkLift.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`forkLifts[1].forkLift.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        {' '}
                                        Electric Stacker:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`electricStacker.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`electricStacker.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      {/* <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        {' '}
                                        Hydra Crane:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>Yes </p>{' '}
                                      <p className='mb-0 px-2 text-nowrap'>Load Capacity:</p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`hydraCrane.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`hydraCrane.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div> */}
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Shrink / Stretch Wrap Machine:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2 text-nowrap'>Load Capacity:</p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`shrinkAndStretchWrapMachine.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`shrinkAndStretchWrapMachine.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Others:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <Field
                                        disabled={false}
                                        name={`others.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`others.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                    </div>{' '}
                  </div>{' '}
                  <div className={`col-12 mt-4 ${!read ? '' : 'd-none'}`}>
                    <div className='row justify-content-end'>
                      <div className='col-auto '>
                        {Object.keys(touched).length > 0 ? (
                          <button
                            type='submit'
                            className='btn btn-deep-blue add-class remove-class '
                            disabled={data.isPending}
                          >
                            Save {data.isPending ? <Spinner animation='border' /> : null}{' '}
                          </button>
                        ) : (
                          ''
                        )}{' '}
                      </div>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>{' '}
              </Form>
            );
          }}
        />
      )}
    </>
  );
};

export default MhPendingForm;
