// import {
//     FEEDBACK,
//     FEEDBACK_BY_ID,
//     FEEDBACK_RESPONSE,
//     IS_ERROR,
//     IS_LOADING,
//     IS_PENDING,
//   } from "./../types";

import {
    FEEDBACK_URL,
    // FEEDBOOK_BY_ID_URL,
    // FEEDBOOK_DELETE_BY_ID,
} from "../../services/urls";

import axiosauth from "../../services/axios-auth";

//   export function isError(error) {
//     return {
//       type: IS_ERROR,
//       isError: error,
//     };
//   }

//   export function isLoading(bool) {
//     return {
//       type: IS_LOADING,
//       isLoading: bool,
//     };
//   }

//   export function isPending(bool) {
//     return {
//       type: IS_PENDING,
//       isLoading: bool,
//     };
//   }

//   export function reviewList(data) {
//     return {
//       type: REVIEW,
//       payload: data,
//     };
//   }

//   export function review_By_Id(data) {
//     return {
//       type: REVIEW_BY_ID,
//       payload: data,
//     };
//   }

//   export function responseReview(data) {
//     return {
//       type: REVIEW_RESPONSE,
//       payload: data,
//     };
//   }

// ###########  Fecth All Feedback List ########

export const fetchFeedback = (setFeedback) => {
    return async (dispatch) => {
        //   dispatch(isLoading(true));
        try {
            axiosauth
                .get(FEEDBACK_URL)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        setFeedback(res)
                    }
                })
                .catch((error) => {
                    // dispatch(isError(error.message));
                    // dispatch(isLoading(false));
                })
                .then(() => {
                    // dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

export const fetchFeedbackById = (feedbackId, setFeedback) => {
    return async (dispatch) => {
        //   dispatch(isLoading(true));
        try {
            axiosauth
                .get(FEEDBACK_URL + `/${feedbackId}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        setFeedback(res.data)
                    }
                })
                .catch((error) => {
                    // dispatch(isError(error.message));
                    // dispatch(isLoading(false));
                })
                .then(() => {
                    // dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};