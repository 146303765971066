import React, { useEffect, useState } from "react";
import "./commonMenu.scss";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/action/account/login";
import { onlyRead } from "../../store/action/SidemenuAction";

const CommonSideMenu = () => {
  const dispatch = useDispatch();
  const [profile, setUserProfile] = useState(null);

  useEffect(() => {
    dispatch(onlyRead(false));
    let user = window.localStorage.getItem("userData");
    setUserProfile(JSON.parse(user));
  }, [dispatch]);

  // Dashboard
  const dashboardActive =
    window.location.pathname === "/dashboard" ? "active" : "";
  //
  const rateActive =
    window.location.pathname === "/rate-matrix" ? "active" : "";

  // User Management

  const vendor = window.location.pathname === "/vendor" ? "active" : "";
  const customer = window.location.pathname === "/customer" ? "active" : "";
  const serviceUser =
    window.location.pathname === "/service-user" ? "active" : "";
  // const service = window.location.pathname === "/vendor/service" ? "active" : "";

  // WSH User Management
  const whsUser = window.location.pathname === "/wsh-user" ? "active" : "";
  const wshUserRole =
    window.location.pathname === "/wsh-user-role" ? "active" : "";
  const addWSHUser =
    window.location.pathname === "/add-whs-user" ? "active" : "";
  const wshDepart =
    window.location.pathname === "/wsh-department" ? "active" : "";

  // warehouse Management
  const pendingWH =
    window.location.pathname === "/pending-warehouse" ? "active" : "";
  const approvedWH =
    window.location.pathname === "/approved-warehouse" ? "active" : "";
  const rejectedWH =
    window.location.pathname === "/rejected-warehouse" ? "active" : "";
  const asignFA =
    window.location.pathname === "/assign-to-field-agent" ? "active" : "";
  const asignFA1 =
    window.location.pathname === "/assign-to-field-agent-approved"
      ? "active"
      : "";
  const asignFA2 =
    window.location.pathname === "/assign-to-field-agent-rejected"
      ? "active"
      : "";

  // Field Agent Management
  const pendingFA =
    window.location.pathname === "/field-agent-pending" ? "active" : "";
  const approvedFA =
    window.location.pathname === "/field-agent-approve" ? "active" : "";
  const rejectedFA =
    window.location.pathname === "/field-agent-reject" ? "active" : "";

  // Documents management
  const document = window.location.pathname === "/documents" ? "active" : "";

  // Booking management
  const booking = window.location.pathname === "/booking" ? "active" : "";
  const bookingApproved =
    window.location.pathname === "/booking-approve" ? "active" : "";
  const bookingPending =
    window.location.pathname === "/booking-pending" ? "active" : "";
  const bookingCancelled =
    window.location.pathname === "/booking-cancel" ? "active" : "";

  // Customer OnBoarding
  const customerManage =
    window.location.pathname === "/customer-manage" ? "active" : "";
  const customerContactDetails =
    window.location.pathname === "/onboard-customer-contact" ? "active" : "";
  const customerCostSheet =
    window.location.pathname === "/onboard-cost-sheet" ? "active" : "";
  const customerBillingSupport =
    window.location.pathname === "/onboard-billing-support" ? "active" : "";
  const customerTransitionOperation =
    window.location.pathname === "/onboard-transition-operation"
      ? "active"
      : "";
  const customerTransitionCS =
    window.location.pathname === "/onboard-transition" ? "active" : "";
  const customerSpecialInstruction =
    window.location.pathname === "/onboard-special-instruction" ? "active" : "";

  // Service Management
  const service = window.location.pathname === "/service" ? "active" : "";
  const serviceAdd =
    window.location.pathname === "/service-add" ? "active" : "";
  const serviceCategory =
    window.location.pathname === "/service-category" ? "active" : "";
  const serviceCategoryAdd =
    window.location.pathname === "/service-category-add" ? "active" : "";
  const serviceSubCategory =
    window.location.pathname === "/service-subcategory" ? "active" : "";
  const serviceAddSubCategory =
    window.location.pathname === "/service-subcategory-add" ? "active" : "";
  const serviceRecieve =
    window.location.pathname === "/service-recieve" ? "active" : "";

  // RFQ Management
  const createRfq = window.location.pathname === "/create-rfq" ? "active" : "";
  const manageRfq = window.location.pathname === "/manage-rfq" ? "active" : "";

  // Invoice Management
  const userInvoice =
    window.location.pathname === "/user-invoice" ? "active" : "";
  const vendorInvoice =
    window.location.pathname === "/vendor-invoice" ? "active" : "";

  // Category Management
  const category = window.location.pathname === "/category" ? "active" : "";

  const zone = window.location.pathname === "/zone" ? "active" : "";

  // filter Management
  const filter = window.location.pathname === "/filter" ? "active" : "";

  // Review Management
  const review = window.location.pathname === "/review" ? "active" : "";

  // Feedback Management
  const feedback = window.location.pathname === "/feedback" ? "active" : "";

  // CMS Management
  const aboutUs = window.location.pathname === "/about-us" ? "active" : "";
  const faq = window.location.pathname === "/faq" ? "active" : "";
  const tAndC =
    window.location.pathname === "/term-and-condition" ? "active" : "";
  const returnPolicy =
    window.location.pathname === "/return-policy" ? "active" : "";
  const privacy = window.location.pathname === "/privacy" ? "active" : "";
  //why-warehouse
  const whyWh = window.location.pathname === "/why-warehouse" ? "active" : "";
  const typeWh = window.location.pathname === "/type-warehouse" ? "active" : "";

  // Enquiry Management
  const customerEnquiryPending =
    window.location.pathname === "/customer-enquiry-pending?page=1"
      ? "active"
      : "";
  const customerEnquiryCancelled =
    window.location.pathname === "/customer-enquiry-cancelled?page=1"
      ? "active"
      : "";
  const vendorEnquiryPending =
    window.location.pathname === "/vendor-enquiry-pending?page=1"
      ? "active"
      : "";
  const customerEnquiryApprove =
    window.location.pathname === "/customer-enquiry-approve?page=1"
      ? "active"
      : "";
  const vendorEnquiryApprove =
    window.location.pathname === "/vendor-enquiry-approve" ? "active" : "";
  const userContact =
    window.location.pathname === "/user-contact" ? "active" : "";

  // notification Management
  const notification =
    window.location.pathname === "/notification" ? "active" : "";

  // report Management
  const reportList =
    window.location.pathname === "/warehouse-report-list" ? "active" : "";
  const dailyReport =
    window.location.pathname === "/daily-progress-report" ? "active" : "";
  const noReport =
    window.location.pathname === "/no-search-report" ? "active" : "";

  // setting Management
  const setting = window.location.pathname === "/setting" ? "active" : "";

  // space Management
  const space = window.location.pathname === "/manage-space" ? "active" : "";

  // Feture Management
  const feture =
    window.location.pathname === "/feture-warehouse" ? "active" : "";

  useEffect(() => {
    $(".sidebar-dropdown > a").click(function () {
      $(".sidebar-submenu").slideUp(200);
      if ($(this).parent().hasClass("active")) {
        $(".sidebar-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this).next(".sidebar-submenu").slideDown(200);
        $(this).parent().addClass("active");
      }
    });

    $("#close-sidebar").click(function () {
      $(".sidemenu-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function () {
      $(".sidemenu-wrapper").addClass("toggled");
    });
  }, []);
  return (
    <div className="sidemenu-wrapper sidebar-theme toggled">
      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content">
          <div className="sidebar-header">
            <div className="user-pic">
              <img
                className="img-responsive img-rounded"
                src="https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_1280.png"
                alt="vendor user"
              />
            </div>{" "}
            <div className="user-info">
              <span
                className="user-name mt-1 text-capitalize text-white"
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>
                  {" "}
                  {profile?.firstName + " " + profile?.lastName}{" "}
                </strong>{" "}
              </span>{" "}
              <span
                className="user-role"
                style={{
                  fontSize: "13px",
                }}
              >
                Warehouse Admin{" "}
              </span>{" "}
            </div>{" "}
          </div>
          <div className="sidebar-menu">
            <ul>
              {" "}
              {/* Dashboard Menu */}
              <li>
                <Link to="/dashboard" className="dropmenu">
                  <i
                    className={
                      dashboardActive === "active"
                        ? "fas fa-desktop iIcon select-iIcon"
                        : "fas fa-desktop iIcon"
                    }
                  ></i>{" "}
                  <span> Dashboard </span>{" "}
                </Link>{" "}
              </li>{" "}
              <li>
                <Link to="/rate-matrix" className="dropmenu">
                  <i
                    className={
                      rateActive === "active"
                        ? "fas fa-desktop iIcon select-iIcon"
                        : "fas fa-desktop iIcon"
                    }
                  ></i>{" "}
                  <span> Rate Matrix </span>{" "}
                </Link>{" "}
              </li>
              {/* User Management*/}{" "}
              <li>
                <Link to="/vendor?page=1" className="dropmenu">
                  <i
                    className={
                      (vendor || customer || serviceUser) === "active"
                        ? "fas fa-users iIcon select-iIcon"
                        : "fas fa-users iIcon"
                    }
                  ></i>{" "}
                  <span> User Management </span>{" "}
                </Link>{" "}
              </li>
              {/* WSH User Management*/}{" "}
              {/*<li
                className={`sidebar-dropdown ${
                  (whsUser || wshUserRole || addWSHUser || wshDepart) ===
                  "active"
                    ? "active"
                    : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-users-cog iIcon"> </i>{" "}
                  <span> WHS User Management </span>{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    (whsUser || wshUserRole || addWSHUser || wshDepart) ===
                    "active"
                      ? "d-block"
                      : ""
                  }`}
                  >
                  <ul>
                    <li>
                      <Link to="/wsh-user?page=1">
                        <i
                          className={`fa fa-circle mr-1 ${
                            whsUser === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage User{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/wsh-user-role?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            wshUserRole === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage Role{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/wsh-department?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            wshDepart === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage Department{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/add-whs-user">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            addWSHUser === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Add WHS User{" "}
                      </Link>{" "}
                    </li>{" "}
                  </ul>
                </div>
              </li>*/}
              {/* Warehouse Management*/}{" "}
              <li
                className={`sidebar-dropdown ${
                  (pendingWH ||
                    approvedWH ||
                    rejectedWH ||
                    asignFA ||
                    asignFA1 ||
                    asignFA2) === "active"
                    ? "active"
                    : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-users-cog iIcon"> </i>{" "}
                  <span> Warehouse Management </span>{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    (pendingWH ||
                      approvedWH ||
                      rejectedWH ||
                      asignFA ||
                      asignFA1 ||
                      asignFA2) === "active"
                      ? "d-block"
                      : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to="/pending-warehouse?page=1">
                        <i
                          className={`fa fa-circle mr-1 ${
                            pendingWH === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Pending{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/approved-warehouse?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            approvedWH === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Approved{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/rejected-warehouse?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            rejectedWH === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Rejected{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/assign-to-field-agent?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            asignFA === "active" ||
                            asignFA1 === "active" ||
                            asignFA2 === "active"
                              ? "select-iIcon"
                              : ""
                          }`}
                        ></i>{" "}
                        Assigned for field agent Verification{" "}
                      </Link>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </li>
              {/* Space Management*/}{" "}
              <li>
                <Link to="/manage-space" className="dropmenu">
                  <i
                    className={
                      space === "active"
                        ? "fas fa-users iIcon select-iIcon"
                        : "fas fa-users iIcon"
                    }
                  ></i>{" "}
                  <span> Space Management </span>{" "}
                </Link>{" "}
              </li>
              {/* Warehouse Field Agent Management*/}{" "}
              <li
                className={`sidebar-dropdown ${
                  (pendingFA || approvedFA || rejectedFA) === "active"
                    ? "active"
                    : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-users-cog iIcon"> </i>{" "}
                  <span> Field Agent Management </span>{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    (pendingFA || approvedFA || rejectedFA) === "active"
                      ? "d-block"
                      : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to="/field-agent-pending?page=1">
                        <i
                          className={`fa fa-circle mr-1 ${
                            pendingFA === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Pending{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/field-agent-approve?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            approvedFA === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Approved{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/field-agent-reject?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            rejectedFA === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Rejected{" "}
                      </Link>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </li>
              {/* Documents Management*/}
              <li>
                <Link to="/documents?page=1" className="dropmenu">
                  <i
                    className={
                      document === "active"
                        ? "fas fa-calendar-alt iIcon select-iIcon"
                        : "fas fa-calendar-alt iIcon"
                    }
                  ></i>{" "}
                  <span> Documents Management </span>{" "}
                </Link>{" "}
              </li>
              {/* Booking Management*/}
              <li>
                <Link to="/booking?page=1" className="dropmenu">
                  <i
                    className={
                      (booking ||
                        bookingApproved ||
                        bookingPending ||
                        bookingCancelled) === "active"
                        ? "fas fa-calendar-alt iIcon select-iIcon"
                        : "fas fa-calendar-alt iIcon"
                    }
                  ></i>{" "}
                  <span> Booking Management </span>{" "}
                </Link>{" "}
              </li>
              {/* Customer Onboarding Management*/}{" "}
              <li
                className={`sidebar-dropdown ${
                  (customerSpecialInstruction ||
                    customerTransitionCS ||
                    customerTransitionOperation ||
                    customerBillingSupport ||
                    customerCostSheet ||
                    customerManage ||
                    customerContactDetails) === "active"
                    ? "active"
                    : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-user-tie iIcon"> </i>{" "}
                  <span> Customer Onboarding </span>{" "}
                  {/* <span className="badge badge-pill badge-warning">New</span> */}{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    (customerSpecialInstruction ||
                      customerTransitionCS ||
                      customerTransitionOperation ||
                      customerBillingSupport ||
                      customerCostSheet ||
                      customerManage ||
                      customerContactDetails) === "active"
                      ? "d-block"
                      : ""
                  }`}
                >
                  <ul>
                    {" "}
                    {/* {profile?.isMainUser ? */}{" "}
                    <li>
                      <Link to="/customer-manage?page=1">
                        <i
                          className={`fa fa-circle mr-1 ${
                            customerManage === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage{" "}
                      </Link>{" "}
                    </li>{" "}
                    {/* : */}{" "}
                    <>
                      {" "}
                      {/* <li>
                                  <Link to="/onboard-customer-contact"> <i className={`fa fa-circle mr-1 ${customerContactDetails === "active" ? "select-iIcon":""}`}></i> Contact Details</Link>
                                </li>

                                <li>
                                  <Link to="/onboard-cost-sheet"> <i className={`fa fa-circle mr-1 ${customerCostSheet === "active" ? "select-iIcon":""}`}></i> Cost Sheet</Link>
                                </li>

                                <li>
                                  <Link to="/onboard-billing-support"> <i className={`fa fa-circle mr-1 ${customerBillingSupport === "active" ? "select-iIcon":""}`}></i> Billing Support</Link>
                                </li>

                                <li>
                                  <Link to="/onboard-transition-operation"> <i className={`fa fa-circle mr-1 ${customerTransitionOperation === "active" ? "select-iIcon":""}`}></i> Transition Operations</Link>
                                </li>

                                <li>
                                  <Link to="/onboard-transition"> <i className={`fa fa-circle mr-1 ${customerTransitionCS === "active" ? "select-iIcon":""}`}></i> Transition CS</Link>
                                </li>

                                <li>
                                  <Link to="/onboard-special-instruction"> <i className={`fa fa-circle mr-1 ${customerSpecialInstruction === "active" ? "select-iIcon":""}`}></i> Special Instructions</Link>
                                </li> */}{" "}
                    </>{" "}
                    {/* } */}{" "}
                  </ul>{" "}
                </div>{" "}
              </li>
              {/* Service Management*/}{" "}
              <li
                className={`sidebar-dropdown ${
                  (customerSpecialInstruction ||
                    customerTransitionCS ||
                    customerTransitionOperation ||
                    customerBillingSupport ||
                    customerCostSheet ||
                    service ||
                    customerContactDetails ||
                    serviceRecieve) === "active"
                    ? "active"
                    : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-suitcase iIcon"> </i>{" "}
                  <span> Service Management </span>{" "}
                  {/* <span className="badge badge-pill badge-warning">New</span> */}{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    (serviceAddSubCategory ||
                      serviceSubCategory ||
                      serviceCategoryAdd ||
                      serviceCategory ||
                      service ||
                      serviceAdd ||
                      serviceRecieve) === "active"
                      ? "d-block"
                      : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to="/service">
                        <i
                          className={`fa fa-circle mr-1 ${
                            service === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage Service{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/service-add">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            serviceAdd === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Add Service{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/service-category">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            serviceCategory === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage Category{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/service-category-add">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            serviceCategoryAdd === "active"
                              ? "select-iIcon"
                              : ""
                          }`}
                        ></i>{" "}
                        Add New Category{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/service-subcategory">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            serviceSubCategory === "active"
                              ? "select-iIcon"
                              : ""
                          }`}
                        ></i>{" "}
                        Manage Sub Category{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/service-subcategory-add">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            serviceAddSubCategory === "active"
                              ? "select-iIcon"
                              : ""
                          }`}
                        ></i>{" "}
                        Add New Sub Category{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/service-receive?page=1">
                        <i
                          className={`fa fa-circle mr-1 ${
                            serviceRecieve === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Receive Service{" "}
                      </Link>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </li>
              {/* RFQ Management*/}{" "}
              <li
                className={`sidebar-dropdown ${
                  (createRfq || manageRfq) === "active" ? "active" : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-suitcase iIcon"> </i>{" "}
                  <span> RFQ Management </span>{" "}
                  {/* <span className="badge badge-pill badge-warning">New</span> */}{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    createRfq === "active" ? "d-block" : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to="/manage-rfq?page=1">
                        <i
                          className={`fa fa-circle mr-1 ${
                            manageRfq === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage RFQ{" "}
                      </Link>{" "}
                    </li>{" "}
                    {/* <li>
                                <Link to="/create-rfq"><i className={`fa fa-circle mr-1 ${createRfq=== "active" ? "select-iIcon":""}`}></i> Create RFQ</Link>
                                </li>                    */}{" "}
                  </ul>{" "}
                </div>{" "}
              </li>
              {/* Invoice Management*/}{" "}
              <li
                className={`sidebar-dropdown ${
                  (userInvoice || vendorInvoice) === "active" ? "active" : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-suitcase iIcon"> </i>{" "}
                  <span> Invoice Management </span>{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    (userInvoice || vendorInvoice) === "active" ? "d-block" : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to="/user-invoice?page=1">
                        <i
                          className={`fa fa-circle mr-1 ${
                            userInvoice === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage User Invoice{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/vendor-invoice?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            vendorInvoice === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Manage Vendor Invoice{" "}
                      </Link>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </li>
              {/* Category Management */}{" "}
              <li>
                <Link to="/category?page=1" className="dropmenu">
                  <i
                    className={
                      category === "active"
                        ? "fas fa-th iIcon select-iIcon"
                        : "fas fa-th iIcon"
                    }
                  ></i>{" "}
                  <span> Category Management </span>{" "}
                </Link>{" "}
              </li>
              {/* Zone Management */}{" "}
              <li>
                <Link to="/zone" className="dropmenu">
                  <i
                    className={
                      zone === "active"
                        ? "fas fa-th iIcon select-iIcon"
                        : "fas fa-th iIcon"
                    }
                  ></i>{" "}
                  <span> Zone Management </span>{" "}
                </Link>{" "}
              </li>
              {/* Filter Management */}
              <li
                className={`sidebar-dropdown ${
                  filter === "active" ? "active" : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-suitcase iIcon"> </i>{" "}
                  <span> Filter Management </span>{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    filter === "active" ? "d-block" : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to="/filter?page=1" className="dropmenu">
                        <i
                          className={
                            filter === "active"
                              ? "fas fa-filter iIcon select-iIcon"
                              : "fas fa-filter iIcon"
                          }
                        ></i>{" "}
                        <span> Filter </span>{" "}
                      </Link>{" "}
                    </li>
                    {/* <li>
                          <Link to="/filter-option" className="dropmenu">
                              <i className={filterOption === "active" ? "fas fa-filter iIcon select-iIcon" :"fas fa-filter iIcon"}></i>
                              <span>Filter Option</span>
                            </Link>
                          </li> */}{" "}
                  </ul>{" "}
                </div>{" "}
              </li>
              {/* Review Management */}{" "}
              <li>
                <Link to="/review?page=1" className="dropmenu">
                  <i
                    className={
                      review === "active"
                        ? "fas fa-comments iIcon select-iIcon"
                        : "fas fa-comments iIcon"
                    }
                  ></i>{" "}
                  <span> Review Management </span>{" "}
                </Link>{" "}
              </li>
              {/* Feedback Management */}{" "}
              <li>
                <Link to="/feedback?page=1" className="dropmenu">
                  <i
                    className={
                      feedback === "active"
                        ? "fas fa-comments iIcon select-iIcon"
                        : "fas fa-comments iIcon"
                    }
                  ></i>{" "}
                  <span> Feedback Management </span>{" "}
                </Link>{" "}
              </li>
              {/* Notification Management */}{" "}
              <li>
                <Link to="/notification?page=1" className="dropmenu">
                  <i
                    className={
                      notification === "active"
                        ? "fas fa-bell iIcon select-iIcon"
                        : "fas fa-bell iIcon"
                    }
                  ></i>{" "}
                  <span> Notification Management </span>{" "}
                </Link>{" "}
              </li>
              {/* CMS Management */}
              <li>
                <Link to="/about-us" className="dropmenu">
                  <i
                    className={
                      (aboutUs || faq || tAndC || returnPolicy || privacy) ===
                      "active"
                        ? "fas fa-database iIcon select-iIcon"
                        : "fas fa-database iIcon"
                    }
                  ></i>{" "}
                  <span> CMS </span>{" "}
                </Link>{" "}
              </li>
              {/* Why Warehouse Management */}
              <li>
                <Link to="/why-warehouse?page=1" className="dropmenu">
                  <i
                    className={
                      whyWh === "active"
                        ? "fas fa-database iIcon select-iIcon"
                        : "fas fa-database iIcon"
                    }
                  ></i>{" "}
                  <span> Why Warehouse </span>{" "}
                </Link>{" "}
              </li>
              <li>
                <Link to="/type-warehouse?page=1" className="dropmenu">
                  <i
                    className={
                      typeWh === "active"
                        ? "fas fa-database iIcon select-iIcon"
                        : "fas fa-database iIcon"
                    }
                  ></i>{" "}
                  <span> Warehouse Type </span>{" "}
                </Link>{" "}
              </li>
              {/* Enquiry Management*/}{" "}
              <li
                className={`sidebar-dropdown ${
                  (vendorEnquiryApprove ||
                    customerEnquiryApprove ||
                    vendorEnquiryPending ||
                    customerEnquiryPending) === "active"
                    ? "active"
                    : ""
                }`}
              >
                <a href="#drop" className="dropmenu">
                  <i className="fas fa-suitcase iIcon"> </i>{" "}
                  <span> Enquiry Management </span>{" "}
                </a>{" "}
                <div
                  className={`sidebar-submenu ${
                    (customerEnquiryPending ||
                      customerEnquiryApprove ||
                      vendorEnquiryPending ||
                      vendorEnquiryApprove) === "active"
                      ? "d-block"
                      : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to="/customer-enquiry-pending?page=1">
                        <i
                          className={`fa fa-circle mr-1 ${
                            customerEnquiryPending === "active"
                              ? "select-iIcon"
                              : ""
                          }`}
                        ></i>{" "}
                        Pending{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/customer-enquiry-approve?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            customerEnquiryApprove === "active"
                              ? "select-iIcon"
                              : ""
                          }`}
                        ></i>{" "}
                        Approved{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/customer-enquiry-cancelled?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            customerEnquiryCancelled === "active"
                              ? "select-iIcon"
                              : ""
                          }`}
                        ></i>{" "}
                        Cancelled{" "}
                      </Link>{" "}
                    </li>
                    <li>
                      <Link to="/user-contact?page=1">
                        {" "}
                        <i
                          className={`fa fa-circle mr-1 ${
                            userContact === "active" ? "select-iIcon" : ""
                          }`}
                        ></i>{" "}
                        Contact Us{" "}
                      </Link>{" "}
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </li>
              {/* Feture Management*/}{" "}
              <li>
                <Link to="/feature-warehouse?page=1" className="dropmenu">
                  <i
                    className={
                      feture === "active"
                        ? "fas fa-file-word iIcon select-iIcon"
                        : "fas fa-file-word iIcon"
                    }
                  ></i>{" "}
                  <span> Feature Warehouse </span>{" "}
                </Link>{" "}
              </li>
              {/* Reports Management*/}{" "}
              <li>
                <Link to="/warehouse-report-list" className="dropmenu">
                  <i
                    className={
                      (reportList || dailyReport || noReport) === "active"
                        ? "fas fa-file-word iIcon select-iIcon"
                        : "fas fa-file-word iIcon"
                    }
                  ></i>{" "}
                  <span> Reports </span>{" "}
                </Link>{" "}
              </li>
              {/* Setting Management*/}{" "}
              <li>
                <Link to="/setting" className="dropmenu">
                  <i
                    className={
                      setting === "active"
                        ? "fas fa-cog iIcon select-iIcon"
                        : "fas fa-cog iIcon"
                    }
                  ></i>{" "}
                  <span> Settings </span>{" "}
                </Link>{" "}
              </li>
              {/* User logout */}{" "}
              <li onClick={() => dispatch(logoutUser())}>
                <Link to="/" className="dropmenu">
                  <span>
                    {" "}
                    <i className="fas fa-sign-out-alt iIcon"> </i>{" "}
                    <span> Logout </span>{" "}
                  </span>{" "}
                </Link>{" "}
              </li>{" "}
            </ul>{" "}
          </div>{" "}
          {/* sidebar-menu   */}{" "}
        </div>{" "}
        {/* sidebar-content   */}{" "}
      </nav>{" "}
    </div>
  );
};

export default CommonSideMenu;
