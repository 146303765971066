import React from "react";
import { readableDate } from "../../components/helper/reuse";

const DailyReportList = ({ item, index }) => {
  return (
    <tr>
      <td>
        {" "}
        <div className="cell"> {index} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {readableDate(item?.created_at)} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.newRequestForWhsListing} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.noOfApprovedWarehouse} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.whPendingForApproval} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.totalApprovedWarehouse} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.remark} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.newVendorRegistration} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.vendorCount} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.newCustomerRegistration} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {item?.customerCount} </div>
      </td>
    </tr>
  );
};

export default DailyReportList;
