import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import WarehousePendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/WarehousePendingForm";
import ContactPendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/ContactPendingForm";
import SpacePendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/SpacePendingForm";
import OfficePendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/OfficePendingForm";
import MhPendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/MhPendingForm";
import SafetyPendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/SafetyPendingForm";
import PermitPendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/PermitPendingForm";
import MaterialPendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/MaterialPendingForm";
import PhotoPendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/PhotoPendingForm";
import EightyPendingForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/EightyPendingForm";
import {
  fetchFieldAgentByIdAndType,
  WarehouseByIdType,
  updateWarehouseStatusFieldAgent,
  changeWarehouseStatus,
  updateWarehouseStatusFieldAgentApprove,
} from "../../store/action/warehouseAction";
import Spinner from "react-bootstrap/Spinner";
import FormSuccess from "../../components/common/FormSuccess";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: "5px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    padding: "15px",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const AssignedFieldAgentDetails = () => {
  const { warehouseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const data = useSelector((state) => state.WAREHOUSEINFO);

  // const data=useSelector((state)=>state.WAREHOUSELIST);
  // console.log("Show--->", data.isError)

  const handleChange = (panel, type) => (event, isExpanded) => {
    if (type === "warehouse") {
      if (isExpanded) {
        dispatch(fetchFieldAgentByIdAndType(warehouseId, type));
      }

      setExpanded(isExpanded ? panel : false);
    }
    if (data.singleFormData && data.singleFormData !== null) {
      if (isExpanded) {
        dispatch(fetchFieldAgentByIdAndType(warehouseId, type));
      }

      setExpanded(isExpanded ? panel : false);
    }
  };

  useEffect(() => {
    dispatch(fetchFieldAgentByIdAndType(warehouseId, "warehouse"));
    return () => {
      dispatch(WarehouseByIdType(null));
    };
  }, [dispatch, warehouseId]);

  return (
    <LayoutOne>
      {" "}
      {data.finalSubmit ? (
        <FormSuccess
          onClick={() => {
            dispatch(changeWarehouseStatus());
            history.replace(`/field-agent-approve?page=1`);
          }}
          message="your submission has been send for approval. We will respond to you very soon"
        />
      ) : null}{" "}
      <Helmet>
        <meta charSet="utf-8" />
        <title> Approved Field Agent </title>{" "}
      </Helmet>
      <div className="row justify-content-end align-items-center py-3 px-3 mx-0">
        <div className="col-12 px-0">
          <div className="row">
            <div className="col-12">
              <div className={classes.root}>
                <h5 className="backButton my-4">
                  {" "}
                  <i
                    onClick={() => history.goBack()}
                    className="fas fa-chevron-left mr-3 cursorPointer"
                  >
                    {" "}
                  </i>
                  Field Agent Verification Checklist
                </h5>

                {/* Create New Warehouse */}

                <Accordion
                  expanded={expanded === "panel"}
                  onChange={handleChange("panel", "warehouse")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        1. Warehouse Details{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <WarehousePendingForm
                      warehouseId={warehouseId}
                      viewMood={true}
                    />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Contact Detail Form */}

                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1", "warehouseContactDetails")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        2. Warehouse Contact Details{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <ContactPendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Another Form */}

                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2", "storageSpace")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 3. Storage Space </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <SpacePendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* IT INFRA Form */}

                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3", "itInfrAndOfficeSpace")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        4. IT & Office Infra{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <OfficePendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* MH Infra */}

                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4", "mhInfra")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 5. MH Infra </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <MhPendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Safety Security Form */}

                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5", "safetyAndSecurity")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        6. Safety Security Form{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <SafetyPendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Permit Form */}

                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange(
                    "panel6",
                    "buildingTradeRelatedPermit"
                  )}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 7. Permits </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails className="m-0 p-0">
                    <PermitPendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Material Type Form */}

                <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7", "materialType")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 8. Material Type </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails className="m-0 p-0">
                    <MaterialPendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Warehouse Photo Form */}

                <Accordion
                  expanded={expanded === "panel8"}
                  onChange={handleChange("panel8", "warehouseImages")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        9. Warehouse Photo{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails className="m-0 p-0">
                    <PhotoPendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/*  Form Eighty*/}

                <Accordion
                  expanded={expanded === "panel10"}
                  onChange={handleChange("panel10", "formEighty")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 10. Form 80 </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails className="m-0 p-0">
                    <EightyPendingForm warehouseId={warehouseId} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* </>)
                          }  */}
              </div>

              <div className="col-12 mt-4">
                <div
                  style={{
                    display: window.location.href.includes(
                      "field-agent-approve"
                    )
                      ? "none"
                      : "",
                  }}
                  className="row justify-content-end"
                >
                  <div className="col-auto my-2">
                    <button
                      onClick={() =>
                        dispatch(updateWarehouseStatusFieldAgent(warehouseId))
                      }
                      disabled={
                        data.isPending ||
                        data.warehouseDetail.whsFieldAgaintFormEighty === false
                      }
                      type="button"
                      className="btn btn-deep-blue"
                    >
                      {" "}
                      Submit to WHS{" "}
                      {data.warehouseDetail.formEighty === true ? (
                        data.isPending && data.finalSubmit ? (
                          <Spinner animation="border" />
                        ) : null
                      ) : null}{" "}
                    </button>{" "}
                  </div>{" "}
                  <div className="col-auto my-2">
                    <button
                      onClick={() =>
                        dispatch(
                          updateWarehouseStatusFieldAgentApprove(warehouseId)
                        )
                      }
                      disabled={
                        data.isPending ||
                        data.warehouseDetail.whsFieldAgaintFormEighty === false
                      }
                      type="button"
                      className="btn btn-deep-blue"
                    >
                      {" "}
                      Approve For Listing{" "}
                      {data.warehouseDetail.formEighty === true ? (
                        data.isPending && data.finalSubmit ? (
                          <Spinner animation="border" />
                        ) : null
                      ) : null}{" "}
                    </button>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default AssignedFieldAgentDetails;
