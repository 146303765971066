import axiosauth from '../services/axios-auth';

import {
    DOCUMET_UPLOAD_URL,
    UPLOAD_CERTIFICATE_URL
} from '../services/urls';

export const uploadDocumentCertificate = async (id, userDocType, docType, data) => {
    try {
        const response = await axiosauth.put(`/api/v1/bookingdocument/${userDocType}/${id}/type/${docType}`, data)
        let res = JSON.parse(response.data)
        if (res.statusCode === 201 || res.statusCode === 200) {
            return res
        } else {
            return res
        }

    } catch (e) {
        return e
    }
}


export const uploadWarehouseCertificate = async (data) => {
    try {
        const response = await axiosauth.post(UPLOAD_CERTIFICATE_URL, data)
        let res = JSON.parse(response.data)
        if (res.statusCode === 201 || res.statusCode === 200) {
            return {
                url: res.data.url,
                success: true
            }
        } else {
            return {
                url: "",
                success: false
            }
        }

    } catch (e) {
        return {
            url: "",
            success: false
        }
    }
}
export const documentUploadV2 = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosauth.post(DOCUMET_UPLOAD_URL, data)
            let res = JSON.parse(response.data)
            if (res.statusCode === 201 || res.statusCode === 200) {
                resolve({
                    url: res.data,
                    success: true
                })
            } else {
                reject({
                    url: res.data,
                    success: true
                })
            }
        } catch (e) {
            reject(e.message)
        }
    })
}

export const documentUpload = async (data) => {
    try {
        const response = await axiosauth.post(DOCUMET_UPLOAD_URL, data)
        let res = JSON.parse(response.data)
        if (res.statusCode === 201 || res.statusCode === 200) {
            return {
                url: res.data,
                success: true
            }
        } else {
            return {
                url: "",
                success: false
            }
        }

    } catch (e) {
        return {
            url: "",
            success: false
        }
    }
}