import React, { useEffect, useState } from "react";
import "./commonMenu.scss";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/action/account/login";
import { sidemenuList } from "../../store/action/SidemenuAction";
// import axiosauth from '../../services/axios-auth';
import SidebarNew from "./SidebarNew";
import DynamicMenu from "./DynamicMenu";
import { Link } from "react-router-dom";

const CommonSideMenu = () => {
  const dispatch = useDispatch();
  const [profile, setUserProfile] = useState(null);
  const sidemenuData = useSelector((state) => state.SIDEMENU_INFO);

  useEffect(() => {
    let user = window.localStorage.getItem("userData");
    setUserProfile(JSON.parse(user));
  }, []);

  useEffect(() => {
    dispatch(sidemenuList());
  }, [dispatch]);

  return (
    <div className="sidemenu-wrapper sidebar-theme toggled">
      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content">
          <div className="sidebar-header">
            <div className="user-pic">
              <img
                className="img-responsive img-rounded"
                src="https://raw.githubusercontent.com/azouaoui-med/pro-sidebar-template/gh-pages/src/img/user.jpg"
                alt="vendor user"
              />
            </div>{" "}
            <div className="user-info">
              <span
                className="user-name mt-1 text-capitalize"
                style={{
                  fontSize: "16px",
                }}
              >
                <strong>
                  {" "}
                  {profile?.firstName + " " + profile?.lastName}{" "}
                </strong>{" "}
              </span>{" "}
              <span
                className="user-role"
                style={{
                  fontSize: "13px",
                }}
              >
                {" "}
                Warehouse Admin{" "}
              </span>{" "}
            </div>{" "}
          </div>
          {/* <div className="sidebar-menu"> */}
          <div>
            <ul>
              {sidemenuData.sidemenu && sidemenuData.sidemenu.length > 0 ? (
                <>
                  <SidebarNew items={sidemenuData.sidemenu} />
                  <li
                    className="text-white"
                    onClick={() => dispatch(logoutUser())}
                  >
                    <Link to="/" className="dropmenu text-white">
                      <span>
                        {" "}
                        <i className="fas fa-sign-out-alt iIcon ml-3"> </i>{" "}
                        <span> Logout </span>
                      </span>
                    </Link>{" "}
                  </li>{" "}
                </>
              ) : (
                <DynamicMenu />
              )}{" "}
            </ul>
          </div>{" "}
        </div>
      </nav>{" "}
    </div>
  );
};

export default CommonSideMenu;
