import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateItInfra,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import * as Yup from "yup";

const OfficePendingForm = ({ warehouseId, viewMood, read }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const validationSchema = function (values) {
    return Yup.object().shape({
      itInfra: Yup.object().shape({
        desktop: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        printer: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        printerMultifunction: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        upsAndInverter: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        broadBand: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        scanner: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        telephone: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        wms: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        itinfra_other: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
      }),
      officeSpace: Yup.object().shape({
        area: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        airConditioner: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        workStations: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        tables: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        cabins: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        meetingRooms: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        recordRooms: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        strongRooms: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        securityGuardOfficeTable: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        electricLoad: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        officespace_other: Yup.object().shape({
          fastatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
      }),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        finalSubmitWhs: data.singleFormData.finalSubmitWhs,
        finalSubmitFieldAgaint: data.singleFormData.finalSubmitFieldAgaint,
        itAndOfficeInfraRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateItInfra(payload));
  };
  const [formField, setFormField] = useState(null);
  // eslint-disable-next-line
  const [remarkForm, setRemarkForm] = useState({
    itInfra: {
      desktop: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      printer: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      printerMultifunction: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      upsAndInverter: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      broadBand: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      scanner: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      telephone: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      wms: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      itinfra_other: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
    },
    officeSpace: {
      area: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      airConditioner: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      workStations: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },

      chairs: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      tables: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      cabins: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      meetingRooms: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      recordRooms: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      strongRooms: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      securityGuardOfficeTable: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      electricLoad: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      officespace_other: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
    },
  });

  useEffect(() => {
    if (
      data.singleFormData &&
      data.singleFormData !== null &&
      data.singleFormData.itInfraAndOfficeSpaceInfo !== null &&
      data.type === "itInfrAndOfficeSpace"
    ) {
      setFormField(data.singleFormData.itInfraAndOfficeSpaceInfo);
      if (data.singleFormData.itAndOfficeInfraRemark) {
        setRemarkForm({
          itInfra: {
            desktop: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.desktop
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.desktop
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.desktop
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.desktop
                    .faremark
                : "",
            },
            printer: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.printer
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.printer
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.printer
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.printer
                    .faremark
                : "",
            },
            printerMultifunction: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .printerMultifunction.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .printerMultifunction.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .printerMultifunction.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .printerMultifunction.faremark
                : "",
            },
            upsAndInverter: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .upsAndInverter.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .upsAndInverter.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .upsAndInverter.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .upsAndInverter.faremark
                : "",
            },
            broadBand: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.broadBand
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.broadBand
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.broadBand
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.broadBand
                    .faremark
                : "",
            },
            scanner: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.scanner
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.scanner
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.scanner
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.scanner
                    .faremark
                : "",
            },
            telephone: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.telephone
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.telephone
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.telephone
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.telephone
                    .faremark
                : "",
            },
            wms: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.wms
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.wms
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.wms
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.wms
                    .faremark
                : "",
            },
            itinfra_other: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .itinfra_other.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .itinfra_other.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .itinfra_other.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .itinfra_other.faremark
                : "",
            },
          },
          officeSpace: {
            area: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.area
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.area
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.area
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.area
                    .faremark
                : "",
            },
            airConditioner: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .airConditioner.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .airConditioner.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .airConditioner.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .airConditioner.faremark
                : "",
            },
            workStations: {
              value: "",
              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .workStations.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .workStations.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .workStations.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .workStations.faremark
                : "",
            },
            chairs: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.chairs
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.chairs
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.chairs
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.chairs
                    .faremark
                : "",
            },
            tables: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.tables
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.tables
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.tables
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.tables
                    .faremark
                : "",
            },
            cabins: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.cabins
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.cabins
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.cabins
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.cabins
                    .faremark
                : "",
            },
            meetingRooms: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .meetingRooms.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .meetingRooms.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .meetingRooms.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .meetingRooms.faremark
                : "",
            },
            recordRooms: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .recordRooms.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .recordRooms.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .recordRooms.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .recordRooms.faremark
                : "",
            },
            strongRooms: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .strongRooms.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .strongRooms.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .strongRooms.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .strongRooms.faremark
                : "",
            },
            securityGuardOfficeTable: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .securityGuardOfficeTable.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .securityGuardOfficeTable.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .securityGuardOfficeTable.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .securityGuardOfficeTable.faremark
                : "",
            },
            electricLoad: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .electricLoad.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .electricLoad.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .electricLoad.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .electricLoad.faremark
                : "",
            },
            officespace_other: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .officespace_other.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .officespace_other.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .officespace_other.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .officespace_other.faremark
                : "",
            },
          },
        });
      } else {
        setRemarkForm({
          itInfra: {
            desktop: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            printer: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            printerMultifunction: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            upsAndInverter: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            broadBand: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            scanner: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            telephone: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            wms: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            itinfra_other: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
          },
          officeSpace: {
            area: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            airConditioner: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            workStations: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },

            chairs: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            tables: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            cabins: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            meetingRooms: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            recordRooms: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            strongRooms: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            securityGuardOfficeTable: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            electricLoad: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            officespace_other: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  // console.log("office formField==>", formField)

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-12 rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12">
                    <h6 className="py-3 mb-3 border-bottom"> IT Infra </h6>{" "}
                  </div>{" "}
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="col-auto">
                            <p className="mb-0 pb-2"> Desktop: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.itInfra &&
                              formField.itInfra.desktop !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.desktop
                                  : ""
                              }
                              className="form-control px-4 mb-2"
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.desktop.fastatus"
                                className="common-radio-deep-blue-input"
                                id="xdxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.desktop.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.desktop.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="xdxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.desktop.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="yycnbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.desktop.fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.desktop.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="yycnbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.desktop.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Printer: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.itInfra &&
                              formField.itInfra.printer !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.printer
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.printer.fastatus"
                                className="common-radio-deep-blue-input"
                                id="bbnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.printer.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.printer.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.printer.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="bbbnbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.printer.fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.printer.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbbnbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.printer.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1">
                              {" "}
                              Printer(Multi Function):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.itInfra &&
                              formField.itInfra.printerMultifunction !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.printerMultifunction
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.printerMultifunction.fastatus"
                                className="common-radio-deep-blue-input"
                                id="qqnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.printerMultifunction
                                    .fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.printerMultifunction.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="qqnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.printerMultifunction.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="wwnbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.printerMultifunction
                                    .fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.printerMultifunction.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="wwnbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.printerMultifunction.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> UPS / Inverter: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.itInfra &&
                              formField.itInfra.upsAndInverter !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty(KVA): </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.upsAndInverter
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.upsAndInverter.fastatus"
                                className="common-radio-deep-blue-input"
                                id="aanbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.upsAndInverter.fastatus ===
                                  true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.upsAndInverter.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="aanbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.upsAndInverter.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="vvnbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.upsAndInverter.fastatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.upsAndInverter.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="vvnbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.upsAndInverter.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Broadband: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.itInfra &&
                              formField.itInfra.broadBand !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> MBPS: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.broadBand
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.broadBand.fastatus"
                                className="common-radio-deep-blue-input"
                                id="llnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.broadBand.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.broadBand.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="llnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.broadBand.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="qqnbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.broadBand.fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.broadBand.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="qqnbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.broadBand.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Scanner: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.itInfra &&
                              formField.itInfra.scanner !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.scanner
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.scanner.fastatus"
                                className="common-radio-deep-blue-input"
                                id="ssnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.scanner.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.scanner.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="ssnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.scanner.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="mmnbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.scanner.fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.scanner.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="mmnbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.scanner.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Telephone(Landline): </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.itInfra &&
                              formField.itInfra.telephone !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.telephone
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.telephone.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryOkayaa"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.telephone.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.telephone.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryOkayaa"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.telephone.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryNotOkaybb"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.telephone.fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.telephone.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryNotOkaybb"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.telephone.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> WMS: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.itInfra &&
                              formField.itInfra.wms !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="" colSpan="2">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.wms
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.wms.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryOkayzz"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={values.itInfra.wms.fastatus === true}
                                onChange={() =>
                                  setFieldValue("itInfra.wms.fastatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryOkayzz"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.wms.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryNotOkayss"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={values.itInfra.wms.fastatus === false}
                                onChange={() =>
                                  setFieldValue("itInfra.wms.fastatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryNotOkayss"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.wms.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-5 pb-1"> Others: </p>{" "}
                          </td>{" "}
                          <td className="" colSpan="3">
                            <textarea
                              type="text"
                              className="form-control px-4 rounded-md"
                              value={
                                formField && formField.itInfra
                                  ? formField.itInfra.others
                                  : ""
                              }
                              rows="4"
                              disabled
                            >
                              {" "}
                            </textarea>{" "}
                          </td>{" "}
                          <td
                            className="mw-250px form-inline mt-4 form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.itinfra_other.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcaaategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.itinfra_other.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.itinfra_other.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcaaategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="itInfra.itinfra_other.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcssategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.itInfra.itinfra_other.fastatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "itInfra.itinfra_other.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcssategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`itInfra.itinfra_other.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>
                    </table>{" "}
                  </div>{" "}
                </div>
                <div className="row">
                  <div className="col-12">
                    {" "}
                    <h6 className="py-3 mb-3 border-bottom"> Office Space </h6>
                  </div>
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Area(sqft): </p>{" "}
                          </td>{" "}
                          <td className="" colSpan="3">
                            <input
                              type="text"
                              className="form-control px-4mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.area
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.area.fastatus"
                                className="common-radio-deep-blue-input"
                                id="xxxnbcategoryaaOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.area.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.area.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="xxxnbcategoryaaOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.area.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="xxxnbcategoryaaNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.area.fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.area.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="xxxnbcategoryaaNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.area.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Air Conditioner: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.officeSpace &&
                              formField.officeSpace.airConditioner !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.airConditioner
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.airConditioner.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategorybbOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.airConditioner.fastatus ===
                                  true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.airConditioner.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategorybbOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.airConditioner.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategorybbNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.airConditioner.fastatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.airConditioner.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategorybbNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.airConditioner.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Work Stations: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.officeSpace &&
                              formField.officeSpace.workStations !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.workStations
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.workStations.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryccOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.workStations.fastatus ===
                                  true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.workStations.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryccOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.workStations.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryccNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.workStations.fastatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.workStations.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryccNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.workStations.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Tables: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.officeSpace &&
                              formField.officeSpace.tables !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.tables
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.tables.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryffOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.tables.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.tables.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryffOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.tables.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryffNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.tables.fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.tables.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryffNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.tables.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Cabins: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.officeSpace &&
                              formField.officeSpace.cabins !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.cabins
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.cabins.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryggOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.cabins.fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.cabins.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryggOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.cabins.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryggNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.cabins.fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.cabins.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryggNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.cabins.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Meeting Rooms: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.officeSpace &&
                              formField.officeSpace.meetingRooms !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.meetingRooms
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.meetingRooms.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryhhOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.meetingRooms.fastatus ===
                                  true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.meetingRooms.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryhhOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.meetingRooms.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryhhNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.meetingRooms.fastatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.meetingRooms.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryhhNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.meetingRooms.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Record Rooms: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.officeSpace &&
                              formField.officeSpace.recordRooms !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.recordRooms
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.recordRooms.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryiiOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.recordRooms.fastatus ===
                                  true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.recordRooms.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryiiOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.recordRooms.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryiiNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.recordRooms.fastatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.recordRooms.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryiiNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.recordRooms.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Strong Rooms: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.officeSpace &&
                              formField.officeSpace.strongRooms !== ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.strongRooms
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.strongRooms.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryjjOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.strongRooms.fastatus ===
                                  true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.strongRooms.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryjjOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.strongRooms.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryjjNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.strongRooms.fastatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.strongRooms.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryjjNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.strongRooms.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1">
                              {" "}
                              Security Guard Office / Table:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-3 mt-1">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField &&
                              formField.officeSpace &&
                              formField.officeSpace.securityGuardOfficeTable !==
                                ""
                                ? "Yes"
                                : "No"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto mt-1">
                            <p className=""> Qty: </p>{" "}
                          </td>{" "}
                          <td className="">
                            <input
                              type="text"
                              className="form-control px-4 mb-2"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace
                                      .securityGuardOfficeTable
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.securityGuardOfficeTable.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryssOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.securityGuardOfficeTable
                                    .fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.securityGuardOfficeTable.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryssOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.securityGuardOfficeTable.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryssNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.securityGuardOfficeTable
                                    .fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.securityGuardOfficeTable.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryssNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.securityGuardOfficeTable.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-0 pb-1"> Electric Load: </p>{" "}
                          </td>{" "}
                          <td className="" colSpan="2">
                            <input
                              type="text"
                              className="form-control px-4"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.electricLoad
                                  : ""
                              }
                              disabled
                            />
                          </td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.electricLoad.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategoryvvOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.electricLoad.fastatus ===
                                  true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.electricLoad.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryvvOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.electricLoad.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategoryvvNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.electricLoad.fastatus ===
                                  false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.electricLoad.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategoryvvNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.electricLoad.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-5 pb-1"> Others: </p>{" "}
                          </td>{" "}
                          <td className="" colSpan="3">
                            <textarea
                              type="text"
                              className="form-control px-4 rounded-md"
                              value={
                                formField && formField.officeSpace
                                  ? formField.officeSpace.others
                                  : ""
                              }
                              rows="4"
                              disabled
                            >
                              {" "}
                            </textarea>{" "}
                          </td>{" "}
                          <td
                            className="mw-250px form-inline mt-4 form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.officespace_other.fastatus"
                                className="common-radio-deep-blue-input"
                                id="nbcategorymmOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.officespace_other
                                    .fastatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.officespace_other.fastatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategorymmOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="officeSpace.officespace_other.fastatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="nbcategorymmNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood || read}
                                checked={
                                  values.officeSpace.officespace_other
                                    .fastatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "officeSpace.officespace_other.fastatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="nbcategorymmNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood || read}
                              name={`officeSpace.officespace_other.faremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>
                    </table>{" "}
                  </div>{" "}
                </div>{" "}
                <div className={`col-12 mt-4`}>
                  <div className={`row justify-content-end`}>
                    <div className="col-auto ">
                      <button
                        type="submit"
                        className="btn btn-deep-blue add-class remove-class "
                        disabled={data.isPending}
                      >
                        Save{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default OfficePendingForm;
