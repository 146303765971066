import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import CustomerManageList from "../../wrapper/customerOnboarding/CustomerManageList";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { onBoardByPage } from "../../store/action/onBoardAction";
import SearchBox from "../../components/common/SearchBox";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";

const CustomerManage = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.ON_BOARD_INFO);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(onBoardByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(onBoardByPage(pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(onBoardByPage(parseInt(pageCount), searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Customer Onboarding </title>{" "}
      </Helmet>

      <SearchBox callSearch={callSearch} setSearchHandler={setSearchHandler} />

      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 px-0">
          <h5 className="text-dark">
            {" "}
            Customer onboarding-Total Count: {data.onBoardList?.totalCount}{" "}
          </h5>{" "}
        </div>{" "}
        <div className="col-12 px-0">
          <div className="row align-items-center py-3 px-3 mx-0">
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-12 px-0 table-responsive table-gray-admin bg-white p-3">
                  <table className="table table-dark-custom">
                    <thead>
                      <tr>
                        <th className="text-center"> Onboard ID </th>{" "}
                        <th> Warehouse ID </th> <th> Company Name </th>{" "}
                        <th> On Board Date </th> <th> Email ID </th>{" "}
                        <th> District </th> <th> City </th> <th> Action </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {data.onBoardList?.data &&
                      data.onBoardList?.data.length > 0
                        ? data.onBoardList?.data.map((item, index) => (
                            <CustomerManageList
                              item={item}
                              key={index}
                              read={read}
                            />
                          ))
                        : null}
                    </tbody>{" "}
                  </table>{" "}
                </div>{" "}
              </div>{" "}
              <div className="row">
                <div className="col-12">
                  {" "}
                  {data.onBoardListt?.totalCount > 10 && (
                    <div className="pagination-custom">
                      <Pagination
                        activePage={parseInt(pageCount)}
                        itemsCountPerPage={10}
                        totalItemsCount={data.onBoardList?.totalCount}
                        pageRangeDisplayed={1}
                        onChange={handlePageChange}
                        prevPageText={<i className="fas fa-chevron-left" />}
                        nextPageText={<i className="fas fa-chevron-right" />}
                        hideFirstLastPages={true}
                      />{" "}
                    </div>
                  )}{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default CustomerManage;
