import {
    REVIEW,
    REVIEW_BY_ID,
    REVIEW_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    reviewList: [],
    reviewDetail: {},
    reviewResponse: null
}

export function REVIEW_INFO(state = initialState, action) {

    switch (action.type) {

        case REVIEW:
            return {
                ...state,
                reviewList: action.payload,
            };

        case REVIEW_BY_ID:
            return {
                ...state,
                reviewDetail: action.payload,
            };

        case REVIEW_RESPONSE:
            return {
                ...state,
                reviewResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        default:
            return state;
    }
}