import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateMhInfra,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";

const MhPendingForm = ({ warehouseId, viewMood }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);

  const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return datestring;
  };
  const [formField, setFormField] = useState(null);
  const validationSchema = function (values) {
    return Yup.object().shape({
      pickingTrolley: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      hydraulicDockLevler: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      batteryOperatedPalletTruck: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      handPalletTruck: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      electricStacker: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      shrinkAndStretchWrapMachine: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      mhinfra_others: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      pallets: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      weighingMachine: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      forkLifts: Yup.array().of(
        Yup.object().shape({
          forkLift: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      hydraCrane: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        mhInfraRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateMhInfra(payload));
  };
  const [remarkForm, setRemarkForm] = useState({
    pickingTrolley: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    hydraulicDockLevler: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    batteryOperatedPalletTruck: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    handPalletTruck: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    electricStacker: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    shrinkAndStretchWrapMachine: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    mhinfra_others: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    pallets: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    weighingMachine: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    forkLifts: [
      {
        type: "batteryoperatedup",
        forkLift: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "dieseloperated",
        forkLift: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    hydraCrane: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });
  useEffect(() => {
    if (
      data.singleFormData &&
      data.singleFormData !== null &&
      data.type === "mhInfra"
    ) {
      setFormField(data.singleFormData.mhInfraInfo);
      if (data.singleFormData.mhInfraRemark) {
        setRemarkForm({
          pickingTrolley: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pickingTrolley.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pickingTrolley.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pickingTrolley.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pickingTrolley.faremark
              : "",
          },
          hydraulicDockLevler: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraulicDockLevler.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraulicDockLevler.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraulicDockLevler.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraulicDockLevler.faremark
              : "",
          },
          batteryOperatedPalletTruck: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.batteryOperatedPalletTruck
                  .whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.batteryOperatedPalletTruck
                  .whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.batteryOperatedPalletTruck
                  .fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.batteryOperatedPalletTruck
                  .faremark
              : "",
          },
          handPalletTruck: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.handPalletTruck.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.handPalletTruck.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.handPalletTruck.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.handPalletTruck.faremark
              : "",
          },
          electricStacker: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.electricStacker.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.electricStacker.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.electricStacker.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.electricStacker.faremark
              : "",
          },
          shrinkAndStretchWrapMachine: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.shrinkAndStretchWrapMachine
                  .whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.shrinkAndStretchWrapMachine
                  .whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.shrinkAndStretchWrapMachine
                  .fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.shrinkAndStretchWrapMachine
                  .faremark
              : "",
          },
          mhinfra_others: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.mhinfra_others.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.mhinfra_others.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.mhinfra_others.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.mhinfra_others.faremark
              : "",
          },
          pallets: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pallets.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pallets.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pallets.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.pallets.faremark
              : "",
          },
          weighingMachine: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.weighingMachine.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.weighingMachine.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.weighingMachine.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.weighingMachine.faremark
              : "",
          },
          forkLifts: [
            {
              type: "batteryoperatedup",
              forkLift: {
                value: "",

                whsstatus: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[0]
                    ? data.singleFormData.mhInfraRemark.forkLifts[0].forkLift
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[0]
                    ? data.singleFormData.mhInfraRemark.forkLifts[0].forkLift
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[0]
                    ? data.singleFormData.mhInfraRemark.forkLifts[0].forkLift
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[0]
                    ? data.singleFormData.mhInfraRemark.forkLifts[0].forkLift
                        .faremark
                    : ""
                  : "",
              },
            },
            {
              type: "dieseloperated",
              forkLift: {
                value: "",

                whsstatus: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[1]
                    ? data.singleFormData.mhInfraRemark.forkLifts[1].forkLift
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[1]
                    ? data.singleFormData.mhInfraRemark.forkLifts[1].forkLift
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[1]
                    ? data.singleFormData.mhInfraRemark.forkLifts[1].forkLift
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.mhInfraRemark
                  ? data.singleFormData.mhInfraRemark.forkLifts[1]
                    ? data.singleFormData.mhInfraRemark.forkLifts[1].forkLift
                        .faremark
                    : ""
                  : "",
              },
            },
          ],
          hydraCrane: {
            value: "",

            whsstatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraCrane.whsstatus
              : "",
            whsremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraCrane.whsremark
              : "",
            fastatus: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraCrane.fastatus
              : "",
            faremark: data.singleFormData.mhInfraRemark
              ? data.singleFormData.mhInfraRemark.hydraCrane.faremark
              : "",
          },
        });
      } else {
        setRemarkForm({
          pickingTrolley: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          hydraulicDockLevler: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          batteryOperatedPalletTruck: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          handPalletTruck: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          electricStacker: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          shrinkAndStretchWrapMachine: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          mhinfra_others: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          pallets: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          weighingMachine: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          forkLifts: [
            {
              type: "batteryoperatedup",
              forkLift: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              type: "dieseloperated",
              forkLift: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
          ],
          hydraCrane: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  // console.log("MH formField==>", formField)

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.whsstatusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-12 rounded-lg">
                <div className="row">
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead class="pb-2">
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="">
                              Okay{" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              Not Okay{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Remarks{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Field Agent Remarks{" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0 w-100">
                              <table class="table table-borderless form-group col-12 pt-xl-1 w-100">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="d-flex px-0">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Pallets:
                                      </label>{" "}
                                      <p className="text-gray mb-0">
                                        {" "}
                                        {formField && formField.pallets
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                      <p className="mb-0 px-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex px-0">
                                      <td className="py-0">
                                        <input
                                          type="text"
                                          className="form-control px-4 mb-2"
                                          value={
                                            formField && formField.pallets
                                              ? formField.pallets.quantity
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                      <td className="py-0">
                                        <input
                                          type="text"
                                          className="form-control px-4 mb-2"
                                          value={
                                            formField && formField.pallets
                                              ? formField.pallets.whsremark
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check pb-3 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="pallets.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nabcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.pallets.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue("pallets.whsstatus", true)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nabcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check pb-3 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="pallets.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nabcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.pallets.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "pallets.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nabcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-2">
                                    <Field
                                      disabled={viewMood}
                                      name={`pallets.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex ">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.pallets.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.pallets.faremark}
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="py-0 px-0 d-flex pt-2">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Hand Pallet Truck(Hydraulic):
                                      </label>{" "}
                                      <p className="text-gray mb-0">
                                        {" "}
                                        {formField &&
                                        formField.handPalletTruck !== ""
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                      <p className="mb-0 px-2"> Qty: </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex px-0">
                                      <td className="py-0">
                                        <input
                                          type="text"
                                          className="form-control px-4 mb-2"
                                          value={
                                            formField
                                              ? formField.handPalletTruck
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                      <td className="py-0">
                                        <input
                                          type="text"
                                          className="form-control px-4 mb-2"
                                          value={
                                            formField && formField.pallets
                                              ? formField.pallets.whsremark
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="handPalletTruck.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.handPalletTruck.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "handPalletTruck.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="handPalletTruck.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbbcategoryNotOkay"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.handPalletTruck.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "handPalletTruck.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`handPalletTruck.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.handPalletTruck.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.handPalletTruck.faremark}
                                type="text"
                                className="form-control  px-4 mb-3 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0  w-100">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className=" d-flex px-0 pt-4">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Weighing Machine:
                                      </label>{" "}
                                      <p className="text-gray mb-0">
                                        {" "}
                                        {formField &&
                                        formField.weighingMachine &&
                                        formField.weighingMachine.validFrom !==
                                          null
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                      <p className="mb-0 px-2">
                                        Weighing Machine:
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex px-2">
                                      <td className="py-0 px-0">
                                        <label
                                          htmlFor=""
                                          className="mb-0 px-4 small text-uppercase"
                                        >
                                          Valid from{" "}
                                        </label>{" "}
                                        <input
                                          type="date"
                                          className="form-control  mb-2"
                                          value={
                                            formField &&
                                            formField.weighingMachine
                                              ? readableDate(
                                                  formField.weighingMachine
                                                    .validFrom
                                                )
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                      <td className="py-0">
                                        <label
                                          htmlFor=""
                                          className="mb-0 px-4 small text-uppercase"
                                        >
                                          Valid till{" "}
                                        </label>{" "}
                                        <input
                                          type="date"
                                          className="form-control   mb-2"
                                          value={
                                            formField &&
                                            formField.weighingMachine
                                              ? readableDate(
                                                  formField.weighingMachine
                                                    .validTill
                                                )
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="weighingMachine.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbxzxcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.weighingMachine.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "weighingMachine.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbxzxcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="weighingMachine.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcatedgoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.weighingMachine.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "weighingMachine.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatedgoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`weighingMachine.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.weighingMachine.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.weighingMachine.faremark}
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0  w-100">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className=" d-flex px-0 pt-2">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Picking Trolley:
                                      </label>{" "}
                                      <p className="text-gray mb-0">
                                        {" "}
                                        {formField &&
                                        formField.pickingTrolley !== ""
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                      <p className="mb-0 px-2">
                                        Weighing Machine:
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex px-2">
                                      <td className="py-0 px-0">
                                        <input
                                          type="text"
                                          className="form-control px-4 mb-2"
                                          value={
                                            formField
                                              ? formField.pickingTrolley
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="pickingTrolley.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryOkayb"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.pickingTrolley.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "pickingTrolley.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryOkayb"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="pickingTrolley.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryNotOkayb"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.pickingTrolley.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "pickingTrolley.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryNotOkayb"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`pickingTrolley.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.pickingTrolley.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.pickingTrolley.faremark}
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-2 d-flex pt-2">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Hydraulic Dock Levler:
                                      </label>{" "}
                                      <p className="text-gray mb-0">
                                        {" "}
                                        {formField &&
                                        formField.hydraulicDockLevler !== ""
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                      <p className="mb-0 px-2"> Qty: </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex ">
                                      <td className="d-flex py-0">
                                        <td className="py-0">
                                          <input
                                            type="text"
                                            className="form-control px-4 mb-2"
                                            value={
                                              formField
                                                ? formField.hydraulicDockLevler
                                                : ""
                                            }
                                            disabled
                                          />
                                        </td>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="hydraulicDockLevler.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcatgoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.hydraulicDockLevler.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "hydraulicDockLevler.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatgoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="hydraulicDockLevler.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcatgoryNotOkay"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.hydraulicDockLevler.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "hydraulicDockLevler.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatgoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`hydraulicDockLevler.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.hydraulicDockLevler.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.hydraulicDockLevler.faremark}
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex ">
                                    <td className="py-0 px-0 d-flex pt-2">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Battery Operated Pallet Truck(BOPT){" "}
                                      </label>{" "}
                                      <p className="text-gray mb-0">
                                        {" "}
                                        {formField &&
                                        formField.batteryOperatedPalletTruck !==
                                          ""
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                      <p className="mb-0 px-2 "> Qty: </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex ">
                                      <td className="d-flex py-0">
                                        <input
                                          type="text"
                                          className="form-control px-4 mb-2"
                                          value={
                                            formField
                                              ? formField.batteryOperatedPalletTruck
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="batteryOperatedPalletTruck.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcatnegoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.batteryOperatedPalletTruck
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "batteryOperatedPalletTruck.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatnegoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="batteryOperatedPalletTruck.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcatnegoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.batteryOperatedPalletTruck
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "batteryOperatedPalletTruck.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatnegoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`batteryOperatedPalletTruck.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.batteryOperatedPalletTruck.fastatus ===
                                true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  values.batteryOperatedPalletTruck.faremark
                                }
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex">
                                    <td className="pt-2 d-flex px-0">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Forklift(Battery Operated):
                                      </label>{" "}
                                      <p className="text-gray mb-0"> Qty: </p>{" "}
                                      <p className="mb-0 px-2">
                                        {" "}
                                        Capacity in ton:
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex ">
                                      <td className="d-flex py-0">
                                        <input
                                          type="text"
                                          value={
                                            formField
                                              ? formField.forkLifts[0]
                                                ? formField.forkLifts[0]
                                                    .capacityTon
                                                : ""
                                              : ""
                                          }
                                          className="form-control px-4 mb-2"
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="forkLifts[0].forkLift.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcxxategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.forkLifts[0]
                                          ? values.forkLifts[0].forkLift
                                              .whsstatus === true
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "forkLifts[0].forkLift.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcxxategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="forkLifts[0].forkLift.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcxxategoryNotOkay"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.forkLifts[0]
                                          ? values.forkLifts[0].forkLift
                                              .whsstatus === false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "forkLifts[0].forkLift.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcxxategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`forkLifts[0].forkLift.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.forkLifts[0] &&
                                values.forkLifts[0].forkLift.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  values.forkLifts[0] &&
                                  values.forkLifts[0].forkLift.faremark
                                }
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex">
                                    <td className="px-0 pt-2 d-flex">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Electric Stacker:
                                      </label>{" "}
                                      <p className="text-gray mb-0">
                                        {" "}
                                        {formField &&
                                        formField.electricStacker !== ""
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                      <p className="mb-0"> Qty: </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex ">
                                      <td className="d-flex py-0">
                                        <input
                                          type="text"
                                          value={
                                            formField
                                              ? formField.forkLifts[0]
                                                ? formField.forkLifts[0]
                                                    .capacityTon
                                                : ""
                                              : ""
                                          }
                                          className="form-control px-4 mb-2"
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="forkLifts[0].forkLift.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcxxategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.forkLifts[0]
                                          ? values.forkLifts[0].forkLift
                                              .whsstatus === true
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "forkLifts[0].forkLift.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcxxategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="forkLifts[0].forkLift.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcxxategoryNotOkay"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.forkLifts[0]
                                          ? values.forkLifts[0].forkLift
                                              .whsstatus === false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "forkLifts[0].forkLift.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcxxategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`forkLifts[0].forkLift.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-3">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.forkLifts[0] &&
                                values.forkLifts[0].forkLift.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  values.forkLifts[0] &&
                                  values.forkLifts[0].forkLift.faremark
                                }
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex">
                                    <td className="px-0 pt-2 d-flex">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Hydra Crane:
                                      </label>{" "}
                                      <p className="text-gray mb-0"> Yes </p>{" "}
                                      <p className="mb-0 px-2">
                                        Load Capacity:
                                      </p>{" "}
                                      <p className="mb-0 px-2">
                                        {" "}
                                        {formField && formField.hydraCrane
                                          ? formField.hydraCrane.loadCapacity
                                          : ""}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex ">
                                      <td className="d-flex py-0">
                                        <select
                                          style={{
                                            display: "none",
                                          }}
                                          className="form-control custom-select px-4 common-select-deep-blue"
                                          id="exampleFormControlSelect1"
                                          disabled
                                        >
                                          <option> Select </option>{" "}
                                          <option> Delhi </option>{" "}
                                          <option> Mumbai </option>{" "}
                                          <option> Kolkata </option>{" "}
                                        </select>{" "}
                                        <input
                                          value={
                                            formField && formField.hydraCrane
                                              ? formField.hydraCrane.whsremark
                                              : ""
                                          }
                                          type="text"
                                          className="form-control px-4 mb-2"
                                          placeholder="Remarks"
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="hydraCrane.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcatnegorycppOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.hydraCrane.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "hydraCrane.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatnegorycppOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="hydraCrane.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcatnegorycppNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.hydraCrane.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "hydraCrane.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatnegorycppNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`hydraCrane.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-3">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.hydraCrane.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.hydraCrane.faremark}
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex">
                                    <td className="px-0 pt-2 d-flex">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Shrink / Stretch Wrap Machine:
                                      </label>{" "}
                                      <p className="text-gray mb-0"> Yes </p>{" "}
                                      <p className="mb-0 px-2">
                                        {" "}
                                        {formField &&
                                        formField.shrinkAndStretchWrapMachine !==
                                          ""
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                      <p className="mb-0 px-2"> Qty: </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex ">
                                      <td className="d-flex py-0">
                                        <input
                                          type="text"
                                          className="form-control px-4 mb-2"
                                          value={
                                            formField
                                              ? formField.shrinkAndStretchWrapMachine
                                              : ""
                                          }
                                          disabled
                                        />
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="shrinkAndStretchWrapMachine.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcatnegorycOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.shrinkAndStretchWrapMachine
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "shrinkAndStretchWrapMachine.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatnegorycOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="shrinkAndStretchWrapMachine.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcatnegorycNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.shrinkAndStretchWrapMachine
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "shrinkAndStretchWrapMachine.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatnegorycNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`shrinkAndStretchWrapMachine.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-3">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.shrinkAndStretchWrapMachine.fastatus ===
                                true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  values.shrinkAndStretchWrapMachine.faremark
                                }
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex">
                                    <td className="px-0 pt-2 d-flex">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2"
                                      >
                                        Others:
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100">
                                        <textarea
                                          type="text"
                                          className="form-control px-4 rounded-md w-100"
                                          value={
                                            formField ? formField.others : ""
                                          }
                                          rows="3"
                                          disabled
                                        ></textarea>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="mhinfra_others.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcatnegorycOkaddy"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.mhinfra_others.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "mhinfra_others.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatnegorycOkaddy"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="mhinfra_others.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcatnegorycNotOkaddy"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.mhinfra_others.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "mhinfra_others.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcatnegorycNotOkaddy"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`mhinfra_others.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-3">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.mhinfra_others.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.mhinfra_others.faremark}
                                type="text"
                                className="form-control  px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className={`col-12 mt-4`}>
                  <div className="row justify-content-end"> </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default MhPendingForm;
