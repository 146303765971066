import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { typeById } from "../../store/action/whyAction";
import { useSelector, useDispatch } from "react-redux";
import { CardLoader } from "../../components/helper/CustomLoader";

const TypeDetail = () => {
  const history = useHistory();
  const { typeId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WHY_INFO);

  useEffect(() => {
    dispatch(typeById(parseInt(typeId)));
  }, [dispatch, typeId]);

  return (
    <LayoutOne>
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row align-items-center px-3 mx-0">
          <div className="col-12 my-2">
            <h5 className="text-dark-blue font-weight-bold">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-3 cursorPointer"
              >
                {" "}
              </i>{" "}
              Type Details
            </h5>
          </div>
          <div className="col-12">
            {" "}
            {data.typeDetail ? (
              <div className="row mx-0">
                <div className="col-12 bg-white rounded">
                  <div className="card card-body border-0">
                    <div className="d-flex">
                      <div className="mw-200px px-3 font-weight-bold py-2">
                        {" "}
                        Type{" "}
                      </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.typeDetail?.type}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="d-flex">
                      <div className="mw-200px px-3 font-weight-bold py-2">
                        {" "}
                        Image{" "}
                      </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        <img
                          src={data.typeDetail?.image}
                          style={{
                            width: 300,
                            height: 200,
                          }}
                          alt="type"
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
            ) : null}
            <div className="row">
              <div className="col-12 my-2">
                <button
                  onClick={() => history.goBack()}
                  className="btn btn-outline-deep-blue py-2"
                >
                  {" "}
                  Back{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default TypeDetail;
