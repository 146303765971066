import React from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";

const EnquiryApproveDetails = () => {
  const history = useHistory();
  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Enquiry </title>{" "}
      </Helmet>
      <div className="row align-items-center py-3">
        <div className="col-12 py-3 mt-4">
          <h5 className="text-dark">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            ></i>
            View Enquiry
          </h5>
        </div>
      </div>
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12">
          <div className="row">
            <div className="col-12 bg-light-gray pt-5 px-5">
              <div className="row mb-3">
                <div className="w-150px col-sm-auto">
                  <p className="font-weight-bold"> Reason / Title: </p>
                </div>{" "}
                <div className="col-sm">
                  <p> Looking for Warehouse Space </p>
                </div>{" "}
              </div>{" "}
              <div className="row mb-3">
                <div className="w-150px col-sm-auto">
                  <p className="font-weight-bold"> Name: </p>
                </div>{" "}
                <div className="col-sm">
                  <p> Ravish Pandey </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="row mb-3">
                <div className="w-150px col-sm-auto">
                  <p className="font-weight-bold"> Email: </p>{" "}
                </div>{" "}
                <div className="col-sm">
                  <p> ravish.kumar @escalesolutions.com </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="row mb-3">
                <div className="w-150px col-sm-auto">
                  <p className="font-weight-bold"> Company: </p>{" "}
                </div>{" "}
                <div className="col-sm">
                  <p> ABC Corporation </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="row mb-3">
                <div className="w-150px col-sm-auto">
                  <p className="font-weight-bold"> Phone: </p>{" "}
                </div>{" "}
                <div className="col-sm">
                  <p> +91 9891441737 </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="row mb-3">
                <div className="w-150px col-sm-auto">
                  <p className="font-weight-bold"> Message: </p>{" "}
                </div>{" "}
                <div className="col-sm">
                  <p>
                    {" "}
                    Looking for Warehouse Space in New Delhi.3000 Sq / feet{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <hr />
              <form className="pb-4">
                <h6 className="mb-4"> Warehousity Response </h6>{" "}
                <div className="form-group">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="Enter Message"
                    disabled
                  >
                    {" "}
                    Thank You for choosing WHS for our website and we will reply
                    you withing 4 hours{" "}
                  </textarea>{" "}
                </div>{" "}
                <div className="my-4">
                  <button
                    onClick={() => history.goBack()}
                    className="btn btn-deep-blue toggle-className"
                    type="button"
                  >
                    {" "}
                    Back{" "}
                  </button>{" "}
                </div>{" "}
              </form>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default EnquiryApproveDetails;
