import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { invoiceById } from "../../store/action/invoiceAction";
import { useSelector, useDispatch } from "react-redux";
import { CardLoader } from "../../components/helper/CustomLoader";
// import {readableDate} from '../../components/helper/reuse';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import InvoiceCertificate from "./InvoiceCertificate";

const UserInvoiceDetails = () => {
  const history = useHistory();
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.INVOICE_INFO);

  useEffect(() => {
    dispatch(invoiceById(parseInt(invoiceId)));
  }, [dispatch, invoiceId]);

  const convertPdf = () => {
    html2canvas(document.getElementById("pdf-element")).then(function (canvas) {
      var wid;
      var hgt;
      var img = canvas.toDataURL(
        "image/png",
        (wid = canvas.width),
        (hgt = canvas.height)
      );
      var hratio = hgt / wid;
      var doc = new jsPDF("p", "pt", "a4");
      var width = doc.internal.pageSize.width;
      var height = width * hratio;
      doc.addImage(img, "JPEG", 20, 20, width, height);
      doc.save("Customer Invoice.pdf");
    });
  };

  return (
    <LayoutOne>
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row align-items-center pb-3 px-3 mx-0">
          <div className="col-12 py-3">
            <h5 className="text-dark-blue font-weight-bold">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-3 cursorPointer"
              >
                {" "}
              </i>{" "}
              View User Invoice
            </h5>
          </div>{" "}
          <div className="col-12">
            {data.invoiceDetail ? (
              <>
                <InvoiceCertificate
                  userType="Customer"
                  data={data.invoiceDetail}
                />
                {/* <div className="row mx-0" id="pdf-element">
                                <div className="col-12 bg-white rounded">
                                    <div className="card card-body border-0">
                                   
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Address</div>
                                        <div className="mw-300px px-3 py-2 text-gray">{data.invoiceDetail?.area}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Warehouse ID</div>
                                        <div className="mw-300px px-3 py-2 text-gray">WI{data.invoiceDetail?.warehouse?.id}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Email ID</div>
                                        <div className="mw-300px px-3 py-2 text-gray">{data.invoiceDetail?.email}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Company Name</div>
                                        <div className="mw-300px px-3 py-2 text-gray">{data.invoiceDetail?.name}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Invoice Date </div>
                                        <div className="mw-300px px-3 py-2 text-gray">{readableDate(data.invoiceDetail?.invoiceDate)}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Due Date </div>
                                        <div className="mw-300px px-3 py-2 text-gray">{readableDate(data.invoiceDetail?.dueDate)}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Contact No.</div>
                                        <div className="mw-300px px-3 py-2 text-gray">{data.invoiceDetail?.contactNumber}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Price</div>
                                        <div className="mw-300px px-3 py-2 text-gray">{data.invoiceDetail?.price} rupees</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Gst</div>
                                        <div className="mw-300px px-3 py-2 text-gray">{data.invoiceDetail?.gst} rupees</div>
                                    </div> 
                                    <div className="d-flex">
                                        <div className="mw-200px px-3 font-weight-bold py-2">Total Price</div>
                                        <div className="mw-300px px-3 py-2 text-gray">{data.invoiceDetail?.price + data.invoiceDetail?.gst} rupees</div>
                                    </div>
                                    </div>
                                </div>
                                </div> */}{" "}
              </>
            ) : null}
            <div className="row">
              <div className="col-auto">
                <button
                  onClick={() => history.goBack()}
                  className="btn py-2 btn-outline-deep-blue"
                >
                  {" "}
                  Back{" "}
                </button>{" "}
              </div>{" "}
              <div className="col-auto">
                <button
                  onClick={convertPdf}
                  className="btn py-2 btn-outline-deep-blue"
                >
                  {" "}
                  Download Invoice{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default UserInvoiceDetails;
