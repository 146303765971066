import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Link } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import WHSReportList from "../../wrapper/report/WHSReportList";
import { Helmet } from "react-helmet";
import { reportWhsByPage, reportList } from "../../store/action/reportAction";
import { useSelector, useDispatch } from "react-redux";
import { CardLoader } from "../../components/helper/CustomLoader";

const WHSListingReport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.REPORT_INFO);

  useEffect(() => {
    dispatch(reportWhsByPage());
    return () => {
      dispatch(reportList([]));
    };
  }, [dispatch]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Report </title>{" "}
      </Helmet>

      <SearchBox />

      <div className="row align-items-center px-3">
        <div className="col-12 bg-white mb-4">
          <div className="row justify-content-between">
            <div className="col-md-auto px-4 order-md-2">
              <div className="row">
                <div className="col-auto d-flex h-100 px-2 my-2">
                  <div className="dropdown btn-export">
                    <button
                      className="btn btn-deep-blue py-2 dropdown-toggle"
                      type="button"
                      id="dropdownMenu5"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Export{" "}
                    </button>{" "}
                    <div className="dropdown-menu">
                      <a className="dropdown-item text-center" href="#!">
                        {" "}
                        Excel{" "}
                      </a>{" "}
                      <div className="dropdown-divider"> </div>{" "}
                      <a className="dropdown-item text-center" href="#!">
                        {" "}
                        PDF{" "}
                      </a>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul className="nav nav-pills admin-tabs-blue">
                <li className="nav-item">
                  <Link
                    to="/warehouse-report-list"
                    className="nav-link active_link text-uppercase"
                  >
                    {" "}
                    WHS Listing Report{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/daily-progress-report"
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    Daily Progress Report{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/no-search-report"
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    No Search Result Report{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="col-12">
          <div className="row align-items-center">
            <div className="col-12 table-responsive table-gray-admin bg-white p-3">
              {" "}
              {data.isLoading ? (
                <CardLoader />
              ) : (
                <table className="table table-dark-custom">
                  <thead>
                    <tr>
                      <th className="w-100px px-2"> S.No. </th>{" "}
                      <th className="text-nowrap px-3"> Warehouse ID </th>{" "}
                      <th className="text-nowrap px-3">
                        {" "}
                        Warehouse Display Name{" "}
                      </th>{" "}
                      {/* <th className="text-nowrap px-3">Warehouse Category</th>  */}{" "}
                      {/* <th className="text-nowrap px-3">Warehouse Type</th>  */}{" "}
                      {/* <th className="text-nowrap px-3">State</th>  */}{" "}
                      {/* <th className="text-nowrap px-3">City/Town</th>  */}{" "}
                      <th className="text-nowrap px-3"> Pincode </th>{" "}
                      <th className="text-nowrap px-3"> Total Space(sqft) </th>{" "}
                      <th className="text-nowrap px-3">
                        {" "}
                        Available Space(sqft){" "}
                      </th>{" "}
                      <th className="text-nowrap px-3">
                        {" "}
                        Listing Request Date{" "}
                      </th>{" "}
                      <th className="text-nowrap px-3"> WH Listed By </th>{" "}
                      <th className="text-nowrap px-3"> Vendor Email ID </th>{" "}
                      <th className="text-nowrap px-3"> Vendor Contact No. </th>{" "}
                      <th className="text-nowrap px-3">
                        {" "}
                        Listing Approval Date{" "}
                      </th>{" "}
                      <th className="text-nowrap px-3">
                        {" "}
                        WH Approved By{" "}
                      </th>{" "}
                      <th className="text-nowrap px-3">
                        {" "}
                        Approver Email ID{" "}
                      </th>{" "}
                      <th className="text-nowrap px-3">
                        {" "}
                        Approver Contact No.{" "}
                      </th>{" "}
                      <th className="text-nowrap px-3">
                        {" "}
                        Space Provider Company Name{" "}
                      </th>{" "}
                      <th className="text-nowrap px-3"> Status </th>{" "}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {console.log(
                      data.reportList.data,
                      "cheking data >>>>>>>>>>>>>>>>>"
                    )}{" "}
                    {data.reportList.data && data.reportList.data.length > 0
                      ? data.reportList.data.map((item, index) => {
                          return (
                            <WHSReportList
                              item={item}
                              index={index}
                              key={index}
                            />
                          );
                        })
                      : "Data Not Found"}{" "}
                  </tbody>{" "}
                </table>
              )}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default WHSListingReport;
