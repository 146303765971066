import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import FormSuccess from '../../../components/common/FormSuccess';
import {
  changeWarehouseStatus1,
  updateWarehousependingDetails,
} from './../../../store/action/warehouseAction';
import CustomLoader from '../../../CustomLoader';
import Spinner from 'react-bootstrap/Spinner';
import { object } from 'yup';

const OfficePendingForm = ({ warehouseId, read, slug }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);

  const onSubmit = (fields) => {
    const payload = {
      data: {
        fields: fields,
      },
      warehouseId: warehouseId,
      slug: slug,
    };
    dispatch(updateWarehousependingDetails(payload));
  };
  const [buttonDisabled, setbuttonDisabled] = useState(0);
  const [remarkForm, setRemarkForm] = useState({
    itInfra: {
      desktop: {
        value: '',
        whsremark: '',
      },
      printer: {
        value: '',
        whsremark: '',
      },
      printerMultifunction: {
        value: '',
        whsremark: '',
      },
      upsAndInverter: {
        value: '',
        whsremark: '',
      },
      broadBand: {
        value: '',
        whsremark: '',
      },
      scanner: {
        value: '',
        whsremark: '',
      },
      telephone: {
        value: '',
        whsremark: '',
      },
      wms: {
        value: '',
        whsremark: '',
      },
      itinfra_other: {
        value: '',
        whsremark: '',
      },
    },
    officeSpace: {
      area: {
        value: '',
        whsremark: '',
      },
      airConditioner: {
        value: '',
        whsremark: '',
      },
      workStations: {
        value: '',
        whsremark: '',
      },

      chairs: {
        value: '',
        whsremark: '',
      },
      tables: {
        value: '',
        whsremark: '',
      },
      cabins: {
        value: '',
        whsremark: '',
      },
      meetingRooms: {
        value: '',
        whsremark: '',
      },
      recordRooms: {
        value: '',
        whsremark: '',
      },
      strongRooms: {
        value: '',
        whsremark: '',
      },
      securityGuardOfficeTable: {
        value: '',
        whsremark: '',
      },
      electricLoad: {
        value: '',
        whsremark: '',
      },
      officespace_other: {
        value: '',
        whsremark: '',
      },
    },
  });

  // const buttontouch = () => {
  //   setbuttonDisabled(1)
  // }

  useEffect(() => {
    // console.log(data?.singleFormData?.remarks?.warehouseItInfrasRemarks, "warehouse target", data?.singleFormData?.warehouseRes?.warehouseItInfras)
    // if (
    //   data?.singleFormData?.remarks?.warehouseItInfrasRemarks !== null &&
    //   data?.singleFormData?.remarks?.warehouseItInfrasRemarks !== undefined &&
    //   data?.singleFormData?.warehouseRes?.warehouseItInfras !== null &&
    //   data?.singleFormData?.warehouseRes?.warehouseItInfras !== undefined
    // ) {
    //   console.log("Warehousity action trigger")
    //   setRemarkForm({
    //     itInfra: {
    //       desktop: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0]?.itInfra?.desktop,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.desktop?.whsremark,
    //       },
    //       printer: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.printer,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.printer?.whsremark,
    //       },
    //       printerMultifunction: {
    //         value:
    //           data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.printerMultifunction,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.printerMultifunction
    //             ?.whsremark,
    //       },
    //       upsAndInverter: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.upsAndInverter,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.upsAndInverter
    //             ?.whsremark,
    //       },
    //       broadBand: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.broadBand,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.broadBand
    //             ?.whsremark,
    //       },
    //       scanner: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.scanner,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.scanner?.whsremark,
    //       },
    //       telephone: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.telephone,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.telephone
    //             ?.whsremark,
    //       },
    //       wms: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.wms,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.wms?.whsremark,
    //       },
    //       itinfra_other: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.others,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.itinfra_other
    //             ?.whsremark,
    //       },
    //     },
    //     officeSpace: {
    //       area: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.area,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.area?.whsremark,
    //       },
    //       airConditioner: {
    //         value:
    //           data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.airConditioner,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.airConditioner
    //             ?.whsremark,
    //       },
    //       workStations: {
    //         value:
    //           data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.workStations,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.workStations
    //             ?.whsremark,
    //       },

    //       chairs: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.chairs,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.chairs
    //             ?.whsremark,
    //       },
    //       tables: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.tables,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.tables
    //             ?.whsremark,
    //       },
    //       cabins: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.cabins,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.cabins
    //             ?.whsremark,
    //       },
    //       meetingRooms: {
    //         value:
    //           data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.meetingRooms,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.meetingRooms
    //             ?.whsremark,
    //       },
    //       recordRooms: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.recordRooms,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.recordRooms
    //             ?.whsremark,
    //       },
    //       strongRooms: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.strongRooms,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.strongRooms
    //             ?.whsremark,
    //       },
    //       securityGuardOfficeTable: {
    //         value:
    //           data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace
    //             .securityGuardOfficeTable,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace
    //             ?.securityGuardOfficeTable?.whsremark,
    //       },
    //       electricLoad: {
    //         value:
    //           data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.electricLoad,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.electricLoad
    //             ?.whsremark,
    //       },
    //       officespace_other: {
    //         value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.others,
    //         whsremark:
    //           data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace
    //             ?.officespace_other?.whsremark,
    //       },
    //     },
    //   });
    // }
    // console.log(data?.singleFormData?.remarks?.warehouseItInfrasRemarks, "IT Infra Section")
    if (
      data?.singleFormData &&
      data?.singleFormData !== null &&
      data?.singleFormData?.remarks !== undefined &&
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks.warehouseItInfrasRemarks &&
      Object.keys(data?.singleFormData?.remarks.warehouseItInfrasRemarks).length > 0
    ) {
      setRemarkForm(data?.singleFormData?.remarks?.warehouseItInfrasRemarks);
    } else {
      // console.log(data?.singleFormData?.warehouseRes, "first enterence")
      if (
        data?.singleFormData &&
        data?.singleFormData !== null &&
        data?.singleFormData?.warehouseRes !== undefined &&
        data?.singleFormData?.warehouseRes !== null &&
        data?.singleFormData?.warehouseRes?.warehouseItInfras !== undefined &&
        data?.singleFormData?.warehouseRes?.warehouseItInfras !== null
      ) {
        // console.log(data?.singleFormData?.warehouseRes, "||I am else part section||")
        setRemarkForm({
          itInfra: {
            desktop: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0]?.itInfra?.desktop,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.desktop
                  ?.whsremark,
            },
            printer: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.printer,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.printer
                  ?.whsremark,
            },
            printerMultifunction: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra
                  .printerMultifunction,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra
                  ?.printerMultifunction?.whsremark,
            },
            upsAndInverter: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.upsAndInverter,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.upsAndInverter
                  ?.whsremark,
            },
            broadBand: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.broadBand,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.broadBand
                  ?.whsremark,
            },
            scanner: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.scanner,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.scanner
                  ?.whsremark,
            },
            telephone: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.telephone,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.telephone
                  ?.whsremark,
            },
            wms: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.wms,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.wms?.whsremark,
            },
            itinfra_other: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].itInfra.others,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.itInfra?.itinfra_other
                  ?.whsremark,
            },
          },
          officeSpace: {
            area: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.area,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.area
                  ?.whsremark,
            },
            airConditioner: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.airConditioner,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.airConditioner
                  ?.whsremark,
            },
            workStations: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.workStations,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.workStations
                  ?.whsremark,
            },

            chairs: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.chairs,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.chairs
                  ?.whsremark,
            },
            tables: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.tables,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.tables
                  ?.whsremark,
            },
            cabins: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.cabins,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.cabins
                  ?.whsremark,
            },
            meetingRooms: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.meetingRooms,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.meetingRooms
                  ?.whsremark,
            },
            recordRooms: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.recordRooms,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.recordRooms
                  ?.whsremark,
            },
            strongRooms: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.strongRooms,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.strongRooms
                  ?.whsremark,
            },
            securityGuardOfficeTable: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace
                  .securityGuardOfficeTable,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace
                  ?.securityGuardOfficeTable?.whsremark,
            },
            electricLoad: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.electricLoad,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace?.electricLoad
                  ?.whsremark,
            },
            officespace_other: {
              value: data?.singleFormData?.warehouseRes?.warehouseItInfras[0].officeSpace.others,
              whsremark:
                data?.singleFormData?.remarks?.warehouseItInfrasRemarks?.officeSpace
                  ?.officespace_other?.whsremark,
            },
          },
        });
      }
    }
  }, [data?.singleFormData]);

  const handleNextPage = () => {
    if (data?.addNewResponse && data?.addNewResponse?.statusCode === 200) {
      history.push(`/pending-details/${warehouseId}/mh-infra`);
      dispatch(changeWarehouseStatus1());
    }
  };

  return (
    <>
      {/* {data?.addNewResponse && data?.addNewResponse?.statusCode === 200 ? (
        <FormSuccess onClick={handleNextPage} message={data.addNewResponse.message} />
      ) : null}{' '} */}
      {data?.isLoading === true ? (
        <CustomLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={remarkForm}
          // validate={validate(validationSchema)}
          onSubmit={(fields) => onSubmit(fields)}
          render={({ errors, status, onChange, touched, values, setFieldValue }) => {
            return (
              <Form className='w-100'>
                <div className='col-12 rounded-lg px-md-4'>
                  <div className='row'>
                    <div className='col-12'>
                      <h6 className='py-3 mb-3 border-bottom'> IT Infra </h6>{' '}
                    </div>{' '}
                    <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col' className='text-center'>
                                Remarks{' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        {' '}
                                        Desktop:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='px-2 mb-1'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`itInfra.desktop.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                        // onChange={() => {
                                        //   setbuttonDisabled(1)
                                        //   return false
                                        // }}
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.desktop.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Printer:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`itInfra.printer.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.printer.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Printer(Multi Function):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`itInfra.printerMultifunction.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.printerMultifunction.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        UPS / Inverter:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty(KVA): </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`itInfra.upsAndInverter.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.upsAndInverter.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Broadband:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> MBPS: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`itInfra.broadBand.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.broadBand.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Scanner:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`itInfra.scanner.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.scanner.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Telephone(Landline):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`itInfra.telephone.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.telephone.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr></tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        WMS:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`itInfra.wms.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.wms.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Others:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.itinfra_other.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`itInfra.itinfra_other.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                    </div>{' '}
                  </div>{' '}
                  <div className='row'>
                    <div className='col-12'>
                      <h6 className='py-3 mb-3 border-bottom'> Office Space </h6>{' '}
                    </div>{' '}
                    <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Area(sqft):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.area.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.area.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Air Conditioner:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.airConditioner.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.airConditioner.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Work Stations:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.workStations.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.workStations.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Tables:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.tables.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.tables.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Cabins{' '}
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.cabins.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.cabins.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Meeting Rooms:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.meetingRooms.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.meetingRooms.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Record Rooms:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.recordRooms.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.recordRooms.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Strong Rooms:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.strongRooms.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.strongRooms.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Security Guard Office / Table:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='mb-0 px-2'> Qty: </p>{' '}
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.securityGuardOfficeTable.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.securityGuardOfficeTable.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Electric Load:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.electricLoad.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.electricLoad.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 d-non'>
                          <thead>
                            <tr>
                              <th scope='col' class='col-6'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Others:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.officespace_other.value`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='value'
                                      />{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`officeSpace.officespace_other.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                    </div>{' '}
                  </div>{' '}
                  <div className={`col-12 mt-4 ${!read ? '' : 'd-none'}`}>
                    <div className='row justify-content-end'>
                      {Object.keys(touched).length > 0 ? (
                        <div className='col-auto '>
                          <button
                            type='submit'
                            className='btn btn-deep-blue add-class remove-class '
                            disabled={data.isPending}
                          >
                            Save {data.isPending ? <Spinner animation='border' /> : null}{' '}
                          </button>{' '}
                        </div>
                      ) : (
                        ''
                      )}{' '}
                    </div>{' '}
                  </div>{' '}
                </div>{' '}
              </Form>
            );
          }}
        />
      )}
    </>
  );
};

export default OfficePendingForm;
