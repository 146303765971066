import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { onlyAlphaNumericSpaceAllow } from "../../components/helper/reuse";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerOnboardForm,
  responseOnboard,
  getSubUserAssignOnboardDetailById,
  updateUserForm,
  userOnboardDetail,
  formDetails,
} from "../../store/action/onBoardAction";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import CustomerCommonForm from "../../wrapper/customerOnboarding/CustomerCommonForm";

const CustomerTransitionCS = () => {
  const { onBoardId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const formType = new URLSearchParams(window.location.search).get("formType");
  const assignUserId = new URLSearchParams(window.location.search).get(
    "assignUserId"
  );
  const onData = useSelector((state) => state.ON_BOARD_INFO);

  const [costJson, setCostJson] = useState({
    transitionCSs: {
      transition: [
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
      ],
      preparedBy: "",
      preparedByDate: "",
      checkedBy: "",
      checkedByDate: "",
      approvedBy: "",
      approveByDate: "",
      ReceivedBy: "",
      ReceivedByDate: "",
    },
  });

  const schema = Yup.object().shape({
    transitionCSs: Yup.object().shape({
      preparedBy: Yup.string().required("Required"),
      preparedByDate: Yup.string().required("Required"),
      checkedBy: Yup.string().required("Required"),
      checkedByDate: Yup.string().required("Required"),
      approvedBy: Yup.string().required("Required"),
      approveByDate: Yup.string().required("Required"),
      ReceivedBy: Yup.string().required("Required"),
      ReceivedByDate: Yup.string().required("Required"),
    }),
  });

  useEffect(() => {
    console.log("ccc->", onData.formDetail?.transitionCS);

    if (onData.formDetail?.transitionCS?.transitionCSs) {
      setCostJson({
        transitionCSs: onData.formDetail?.transitionCS?.transitionCSs,
      });
    }

    // if (formType === "transitionCS" && onData.userOnboardDetail?.transitionCS && onData.userOnboardDetail?.transitionCS?.transitionCSs) {
    //   setCostJson({
    //     transitionCSs: onData.userOnboardDetail.transitionCS?.transitionCSs
    //   })
    // }
  }, [onData.formDetail?.transitionCS, onData.userOnboardDetail, formType]);

  useEffect(() => {
    if (formType === "transitionCS") {
      dispatch(getSubUserAssignOnboardDetailById("transitionCS", onBoardId));
    } else {
      dispatch(getCustomerOnboardForm(assignUserId, "transitionCS", onBoardId));
    }

    return () => {
      dispatch(userOnboardDetail(null));
      dispatch(formDetails(null));
    };
  }, [dispatch, onBoardId, formType, assignUserId]);

  const redirect = () => {
    dispatch(responseOnboard(null));
    history.goBack();
  };

  return (
    <LayoutOne>
      {" "}
      {onData.onResponse && onData.onResponse.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={onData.onResponse.message} />
      ) : null}
      <div className="row align-items-center py-2 mx-0 form-control-height">
        <div className="col-12 py-2">
          <span onClick={() => history.goBack()}>
            {" "}
            <h5 className="text-dark-blue cursorPointer">
              {" "}
              <i className="fas fa-chevron-left mr-3"> </i> Transition CS
            </h5>{" "}
          </span>{" "}
        </div>{" "}
        <CustomerCommonForm buttonHide={true} />{" "}
        <div className="col-12 mt-2 ">
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={costJson}
            onSubmit={(fields) => {
              if (onBoardId) {
                dispatch(updateUserForm(onBoardId, "transitionCS", fields));
              }
              console.log("Fields==>", fields);
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <>
                    <div className="row mx-0">
                      <div className="col-12 px-0 table-responsive table-gray-admin">
                        <table className="table text-left">
                          <tbody>
                            <tr>
                              <td className="font-weight-bold py-2">
                                {" "}
                                Activities{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Frequency{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Source of Data{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Supporting Document{" "}
                              </td>{" "}
                            </tr>{" "}
                            {values.transitionCSs?.transition &&
                              values.transitionCSs?.transition?.length > 0 &&
                              values.transitionCSs?.transition.map(
                                (ins, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        <Field
                                          onKeyPress={(e) =>
                                            onlyAlphaNumericSpaceAllow(e)
                                          }
                                          name={`transitionCSs.transition.${index}.activities`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`transition.${index}.activities`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`transitionCSs.transition.${index}.frequency`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`transition.${index}.frequency`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`transitionCSs.transition.${index}.sourceofData`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`transition.${index}.sourceofData`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`transitionCSs.transition.${index}.supportingDocument`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`transition.${index}.supportingDocument`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                    </tr>
                                  );
                                }
                              )}{" "}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </div>

                    <div className="row pt-2 mx-1 mt-1 bg-white">
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Prepared by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="transitionCSs.preparedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.transitionCSs?.preparedBy &&
                              touched.transitionCSs?.preparedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="transitionCSs.preparedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="transitionCSs.preparedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.transitionCSs?.preparedByDate &&
                              touched.transitionCSs?.preparedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="transitionCSs.preparedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Checked by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="transitionCSs.checkedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.transitionCSs?.checkedBy &&
                              touched.transitionCSs?.checkedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="transitionCSs.checkedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="transitionCSs.checkedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.transitionCSs?.checkedByDate &&
                              touched.transitionCSs?.checkedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="transitionCSs.checkedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Approved by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="transitionCSs.approvedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.transitionCSs?.approvedBy &&
                              touched.transitionCSs?.approvedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="transitionCSs.approvedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="transitionCSs.approveByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.transitionCSs?.approveByDate &&
                              touched.transitionCSs?.approveByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="transitionCSs.approveByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Received by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="transitionCSs.ReceivedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.transitionCSs?.ReceivedBy &&
                              touched.transitionCSs?.ReceivedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="transitionCSs.ReceivedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="transitionCSs.ReceivedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.transitionCSs?.ReceivedByDate &&
                              touched.transitionCSs?.ReceivedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="transitionCSs.ReceivedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-12 px-5">
                        <button
                          type="submit"
                          className="btn btn-deep-blue py-2"
                          disabled={onData.isPending}
                        >
                          Save{" "}
                          {onData.isPending ? (
                            <Spinner animation="border" />
                          ) : null}{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>
                  </>{" "}
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default CustomerTransitionCS;
