import React from "react";
import LayoutOne from "../../layout/LayoutOne";

const Setting = () => {
  return (
    <LayoutOne>
      <div className="row align-items-center py-3 px-3 ">
        <div className="col-12 pb-3 px-0">
          <h5 className="text-dark"> Settings </h5>{" "}
        </div>{" "}
        <div className="col-12 bg-white p-4">
          <form action="" className="col-md-10 mx-auto">
            <div className="row">
              <h6 className="mb-4 font-weight-bold"> Change Password </h6>{" "}
              <div className="form-group col-12 mb-4 px-0">
                <label htmlFor="staticEmail" className="mb-2 px-3">
                  {" "}
                  Old Password{" "}
                </label>{" "}
                <input
                  type="text"
                  className="form-control form-control-md rounded-pill"
                  id="staticEmail"
                  placeholder="Enter Old Password"
                />
              </div>{" "}
              <div className="form-group col-12 mb-4 px-0">
                <label htmlFor="staticEmail" className="mb-2 px-3">
                  {" "}
                  New Password{" "}
                </label>{" "}
                <input
                  type="text"
                  className="form-control form-control-md rounded-pill"
                  id="staticEmail"
                  placeholder="Enter New Password"
                />
              </div>{" "}
              <div className="form-group col-12 mb-4 px-0">
                <label htmlFor="staticEmail" className="mb-2 px-3">
                  {" "}
                  Confirm Password{" "}
                </label>{" "}
                <input
                  type="text"
                  className="form-control form-control-md rounded-pill"
                  id="staticEmail"
                  placeholder="Enter Confirm Password"
                />
              </div>{" "}
              <div className="text-right col-12 px-0">
                {" "}
                <button className="btn btn-deep-blue py-2"> Submit </button>
              </div>
            </div>{" "}
          </form>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default Setting;
