import React from "react";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { updateRecieveService } from "../../store/action/serviceAction";

const ServiceReceiveList = ({ item, index, read }) => {
  console.log("lauda", item);
  const dispatch = useDispatch();

  // Status Change confirmation
  const statusChange = (isActive) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to change status.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              updateRecieveService(item.id, {
                status: isActive,
              })
            ),
        },
        {
          label: "No",
          onClick: () => console.log("sdds"),
        },
      ],
    });
  };

  // useEffect(() => {
  //     setIsActive(item?.active)
  // }, [item]);

  const handleChange = (e) => {
    // setIsActive(e.target.value === "true")
    statusChange(e.target.value);
  };
  return (
    <tr>
      <td> {index} </td> <td> {item?.id} </td>{" "}
      <td className="text-nowrap"> {item?.service?.id} </td>{" "}
      <td className="text-nowrap"> {item?.service?.name} </td>{" "}
      <td className="text-nowrap">
        {" "}
        {item?.user?.firstName + " " + item?.user?.lastName}{" "}
      </td>
      {/* <td className="text-nowrap">{item?.warehouse?.warehouseName}</td>
                        <td className="text-nowrap">{item?.warehouse?.id}</td> */}
      {/* <td>{item?.description.substring(0, 25)}...</td> */}{" "}
      <td>
        <select
          disabled={read}
          name="status"
          onChange={handleChange}
          value={item?.status}
          className="form-control form-control-md custom-select bg-white common-select-deep-blue border-0 w-150px"
        >
          <option value={"PENDING"}> Pending </option>
          <option value={"CANCELLED"}> Cancelled </option>{" "}
          <option value={"CONFIRMED"}> Confirmed </option>
        </select>{" "}
      </td>{" "}
      <td className="text-center text-nowrape d-flex">
        <Link to={`/service-receive/${item.id}`} className="btn px-2">
          {" "}
          <i className="fas fa-eye"> </i>
        </Link>
      </td>{" "}
    </tr>
  );
};

export default ServiceReceiveList;
