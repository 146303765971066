import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_PENDING,
    USER_INFO,
    SESSIONEXPIRED,
    AUTHENTICATED,
    LOGIN_ERROR_MSG
} from '../../types';

// initial State
const initialState = {
    isPending: false,
    isError: null,
    isSuccess: null,
    errorMessage: null,
    userDetails: [],
}

export function USERACCOUNT(state = initialState, action) {

    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isSuccess: action.payload
            }

        case LOGIN_PENDING:
            return {
                ...state,
                isPending: action.payload
            }

        case LOGIN_ERROR:
            return {
                ...state,
                isError: action.payload
            }

        case LOGIN_ERROR_MSG:
            return {
                ...state,
                errorMessage: action.payload
            }

        case USER_INFO:
            return {
                ...state,
                userDetails: action.payload
            }
        default:
            return state;

    }

}


export function sessionExpiry(state = false, action) {
    switch (action.type) {
        case SESSIONEXPIRED:
            return action.session;
        default:
            return state;
    }
}


export function authenticated(state = false, action) {
    switch (action.type) {
        case AUTHENTICATED:
            return action.authenticated;
        default:
            return state;
    }
}