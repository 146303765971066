import React, { useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import { documentUpload } from "../../components/utils";
import { responseWhy, createWhy } from "../../store/action/whyAction";
import {
  onlyAlphaNumericSpaceAllow,
  maxLengthCheck,
} from "../../components/helper/reuse";

const CreateWhy = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WHY_INFO);

  const roleSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  // File Upload
  const [res, setRes] = useState([]);
  const [fileError, setFileError] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const uploadDocs = async (e) => {
    setImageLoading(true);
    setFileError(null);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let urlData = await documentUpload(formData);
    //  console.log(urlData)

    if (urlData && urlData.url) {
      setImageLoading(true);
      setRes([...res, urlData.url]);
    }
  };

  const redirect = () => {
    dispatch(responseWhy(null));
    history.replace(`/why-warehouse?page=1`);
  };

  return (
    <LayoutOne>
      {" "}
      {data.whyResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Why Warehouse Created`} />
      ) : null}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark font-weight-bold">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>{" "}
            Add Why Warehouse
          </h5>
        </div>{" "}
        <div className="col-12 bg-white p-3">
          <Formik
            enableReinitialize={true}
            validationSchema={roleSchema}
            initialValues={{
              title: "",
              description: "",
            }}
            onSubmit={(fields) => {
              if (res && res.length > 0) {
                fields["image"] = res[0];
                dispatch(createWhy(fields));
              } else {
                setFileError("Please Choose image");
              }

              console.log("Fields==>", fields);
              // dispatch(createRole(fields))
            }}
            render={({ values, errors, status, onChange, touched }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="form-group col-12 mb-2">
                      <label htmlFor="staticEmail" className="mb-1 px-3">
                        {" "}
                        Title{" "}
                      </label>{" "}
                      <Field
                        name="title"
                        type="text"
                        className={
                          `form-control form-control-sm rounded-pill` +
                          (errors.title && touched.title ? " is-invalid" : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`title`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group col-12 mb-2">
                      <label htmlFor="staticEmail" className="mb-1 px-3">
                        {" "}
                        Description{" "}
                      </label>{" "}
                      <Field
                        name="description"
                        type="text"
                        className={
                          `form-control form-control-sm rounded-pill` +
                          (errors.description && touched.description
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        onKeyPress={(e) => onlyAlphaNumericSpaceAllow(e)}
                        maxLength={200}
                        onInput={maxLengthCheck}
                      />{" "}
                      <ErrorMessage
                        name={`description`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12 mt-1">
                      <div className="row">
                        <div className="col-auto">
                          <div className="card card-overlay upload-image-preview position-relative border">
                            {res && res.length > 0 ? (
                              <div className="img-holder size-200px">
                                <img
                                  src={res[0]}
                                  className="w-100 h-100 img-fluid rounded overflow-hideen"
                                  id="imageResult"
                                  alt=""
                                />
                              </div>
                            ) : (
                              <>
                                <div className="img-holder size-200px">
                                  <img
                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                    id="imageResult"
                                    src="/assets/images/upload-bg-white.png"
                                    alt=""
                                  />
                                </div>{" "}
                                <div className="card-img-overlay size-200px d-flex justify-content-center align-items-center">
                                  <div className="card-text">
                                    <input
                                      onChange={uploadDocs}
                                      id="upload-image-preview1"
                                      type="file"
                                      className="form-control border-0"
                                      hidden
                                    />
                                    <label
                                      id="upload-label"
                                      htmlFor="upload-image-preview1"
                                      className="font-weight-light text-muted"
                                    >
                                      {" "}
                                    </label>{" "}
                                    <div className="input-group-append">
                                      <label
                                        htmlFor="upload-image-preview1"
                                        className="btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4"
                                      >
                                        <div>
                                          <img
                                            src="/assets/images/icons/upload-icon-deep-blue.png"
                                            alt=""
                                          />
                                        </div>{" "}
                                        {!imageLoading
                                          ? "Upload image"
                                          : "Uploading...."}{" "}
                                      </label>{" "}
                                    </div>{" "}
                                  </div>{" "}
                                </div>{" "}
                              </>
                            )}
                          </div>{" "}
                          <span className="errorMsg"> {fileError} </span>{" "}
                        </div>
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row pt-1">
                    <div className="col-auto">
                      <button
                        type="submit"
                        disabled={data.isPending}
                        className="btn btn-deep-blue py-2 my-4"
                      >
                        Add{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default CreateWhy;
