import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import PropTypes from 'prop-types';

const requiredAuth = (ComposedComponent) => {
    class Authentication extends Component {
        componentWillMount() {
            if (!this.props.authenticated) {
                this.props.history.push('/');
            }

        }

        componentWillUpdate(nextProps) {

            if (!nextProps.authenticated) {
                this.props.history.push('/');
            }

        }

        PropTypes = {
            router: PropTypes.object,
        }

        render() {
            return <ComposedComponent { ...this.props
            }
            />;
        }
    }

    function mapStateToProps(state) {
        return {
            authenticated: state.authenticated
        };
    }
    return connect(mapStateToProps)(Authentication);
}

export default requiredAuth;