import {
    CMS,
    CMS_BY_ID,
    CMS_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    cmsList: [],
    cmsDetail: {},
    cmsResponse: null
}

export function CMS_INFO(state = initialState, action) {

    switch (action.type) {

        case CMS:
            return {
                ...state,
                cmsList: action.payload,
            };

        case CMS_BY_ID:
            return {
                ...state,
                cmsDetail: action.payload,
            };

        case CMS_RESPONSE:
            return {
                ...state,
                cmsResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        default:
            return state;
    }
}