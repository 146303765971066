import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../store/action/account/login";
import Spinner from "react-bootstrap/Spinner";

const Login = () => {
  const [show, setShow] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const data = useSelector((state) => state.USERACCOUNT);

  const dispatch = useDispatch();

  const loginSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const forgotSchema = Yup.object().shape({
    username: Yup.string().email("Invalid email").required("Required"),
  });

  const confirmPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  return (
    <>
      <main className="page-wrapper">
        <section className="signup modal-page">
          <div className="container-fluid px-0 h-full">
            <div className="row h-full">
              <div className="col-md-6 d-md-block d-none card-image">
                <div className="card h-full bg-none">
                  <div className="card-img-overlay d-flex align-items-end justify-content-center">
                    <div className="card-text text-center pt-md-5 pt-4">
                      {" "}
                      {/* <h1 className="text-white h3">Login</h1> */}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-6 col-12">
                <div className="form-box row h-full align-items-center p-md-5 p-4">
                  <Formik
                    //   enableReinitialize={true}
                    validationSchema={loginSchema}
                    initialValues={{
                      username: "",
                      password: "",
                    }}
                    onSubmit={(fields) => {
                      dispatch(userLogin(fields));
                    }}
                    render={({ values, errors, status, onChange, touched }) => {
                      return (
                        <Form action="" className="w-100">
                          <div className="row">
                            <div className="col-12 ">
                              <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                                <div className="icon w-250px mx-auto mb-xl-4 mb-3 text-center">
                                  <img
                                    src="../assets/images/logo.png"
                                    className="img-fluid w-100"
                                    alt="Warehousity Logo"
                                  />
                                  <h5 className="font-weight-light text-dark mb-0">
                                    {" "}
                                    Login Admin!
                                  </h5>{" "}
                                </div>{" "}
                              </div>{" "}
                              <div className="px-lg-5 px-4">
                                <div className="form-group w-100">
                                  <Field
                                    name="username"
                                    className={
                                      "form-control h-50px rounded-sm bg-light" +
                                      (errors.username && touched.username
                                        ? " is-invalid"
                                        : "")
                                    }
                                    type="text"
                                    placeholder="Email"
                                  />
                                  <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="invalid-feedback text-left"
                                  />
                                </div>{" "}
                                <div className="form-group w-100">
                                  <Field
                                    name="password"
                                    className={
                                      "form-control h-50px rounded-sm bg-light" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                    type="password"
                                    placeholder="Password"
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback text-left"
                                  />
                                </div>{" "}
                                <span className="errorMsg ml-1 text-left">
                                  {" "}
                                  {data.isError}{" "}
                                </span>{" "}
                                <div className="form-group w-100 mt-1">
                                  <p
                                    className="text-right cursorPointer"
                                    onClick={() => setShow(true)}
                                  >
                                    Forgot Password ?
                                  </p>{" "}
                                  <button
                                    type="submit"
                                    disabled={data.isPending}
                                    className="btn btn-deep-blue btn-block h-50px"
                                  >
                                    {" "}
                                    Login{" "}
                                    {data.isPending ? (
                                      <Spinner animation="border" />
                                    ) : null}{" "}
                                  </button>{" "}
                                </div>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </Form>
                      );
                    }}
                  />{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
      </main>{" "}
      {/* FORGOT PASSWORD React Modal  */}{" "}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-30w"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="row">
            <div className="col-12 p-3">
              <div className="row">
                <div className="col-12">
                  <button type="button" className="close">
                    <span onClick={() => setShow(false)}> & times; </span>{" "}
                  </button>{" "}
                  <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                    <div className="icon w-130px mx-auto mb-xl-4 mb-3">
                      <img
                        src="../assets/images/logo.png"
                        className="img-fluid w-100"
                        alt="Warehousity Logo"
                      />
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>
              <Formik
                validationSchema={forgotSchema}
                initialValues={{
                  username: "",
                }}
                onSubmit={(fields) => {
                  console.log("fields=====>", fields);
                }}
                render={({ values, errors, status, onChange, touched }) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col-sm-12 form-group form-group-lg mb-3">
                          <label
                            for="spaceprovideremailid"
                            className="h5 text-center"
                          >
                            {" "}
                            Enter Registered Email ID{" "}
                            <sup className="text-danger"> * </sup>
                          </label>
                          <Field
                            name="username"
                            className={
                              "form-control h-50px rounded-sm bg-light" +
                              (errors.username && touched.username
                                ? " is-invalid"
                                : "")
                            }
                            type="text"
                            placeholder="Enter register email ID"
                          />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="invalid-feedback text-left"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn btn-deep-blue btn-block h-50px my-3 mx-auto"
                        >
                          {" "}
                          Continue{" "}
                        </button>{" "}
                      </div>{" "}
                    </Form>
                  );
                }}
              />{" "}
            </div>{" "}
          </div>{" "}
        </Modal.Body>{" "}
      </Modal>
      {/* SEND MAIL Success Modal  */}{" "}
      <Modal
        show={sendMail}
        onHide={() => setSendMail(false)}
        dialogClassName="modal-30w"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="col-12 p-3">
            <div className="row">
              <div className="col-12">
                <button type="button" className="close">
                  <span onClick={() => setSendMail(false)}> & times; </span>{" "}
                </button>{" "}
                <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                  <div className="icon w-130px mx-auto mb-xl-4 mb-3">
                    <img
                      src="../assets/images/logo.png"
                      className="img-fluid w-100"
                      alt="Warehousity Logo"
                    />
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="row">
              <div className="col-sm-12 form-group form-group-lg mb-4">
                <h4 className="text-gray font-weight-light text-center">
                  {" "}
                  A reset link has been sent to{" "}
                </h4>{" "}
                <h3 className="text-center"> shahsankmishra @abc.com </h3>{" "}
              </div>{" "}
            </div>{" "}
            <div className="text-center">
              <button
                type="button"
                className="btn btn-deep-blue h-50px btn-block my-3 mx-auto cursorPointer"
              >
                {" "}
                Ok{" "}
              </button>{" "}
            </div>{" "}
          </div>
        </Modal.Body>{" "}
      </Modal>
      {/* NEW PASSWORD MODAL  */}{" "}
      <Modal
        show={newPassword}
        onHide={() => setNewPassword(false)}
        dialogClassName="modal-30w"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"> & times; </span>{" "}
                </button>{" "}
                <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                  <div className="icon w-130px mx-auto mb-xl-4 mb-3">
                    <img
                      src="../assets/images/logo.png"
                      className="img-fluid w-100"
                      alt="Warehousity Logo"
                    />
                  </div>{" "}
                  <h4 className="text-dark"> Create New Password </h4>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <Formik
              validationSchema={confirmPasswordSchema}
              initialValues={{
                password: "",
                passwordConfirmation: "",
              }}
              onSubmit={(fields) => {
                console.log("fields=====>", fields);
              }}
              render={({ values, errors, status, onChange, touched }) => {
                return (
                  <Form>
                    <div className="row">
                      <div className="col-sm-12 form-group form-group-lg mb-3">
                        <label for="spaceprovideremailid" className="h5">
                          {" "}
                          New Password <sup className="text-danger"> * </sup>
                        </label>
                        <Field
                          name="password"
                          type="password"
                          id="spaceprovideremailid"
                          className={
                            "form-control h-50px bg-light rounded-sm" +
                            (errors.passwordConfirmation &&
                            touched.passwordConfirmation
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Type here password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback text-left"
                        />
                      </div>{" "}
                      <div className="col-sm-12 form-group form-group-lg mb-3">
                        <label for="spaceprovideremailid" className="h5">
                          {" "}
                          Confirm Password{" "}
                          <sup className="text-danger"> * </sup>
                        </label>
                        <Field
                          name="passwordConfirmation"
                          type="password"
                          id="spaceprovideremailid"
                          className={
                            "form-control h-50px bg-light rounded-sm" +
                            (errors.passwordConfirmation &&
                            touched.passwordConfirmation
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Type here confirm password"
                        />
                        <ErrorMessage
                          name="passwordConfirmation"
                          component="div"
                          className="invalid-feedback text-left"
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-deep-blue btn-block h-50px my-3 mx-auto"
                      >
                        {" "}
                        Reset Password{" "}
                      </button>{" "}
                    </div>{" "}
                  </Form>
                );
              }}
            />{" "}
          </div>
        </Modal.Body>{" "}
      </Modal>
      {/* SUCCESS MODAL  */}{" "}
      <Modal
        show={passwordSuccess}
        onHide={() => setPasswordSuccess(false)}
        dialogClassName="modal-30w"
        centered
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="col-12 py-lg-4 my-1 p-sm-4 p-3">
            <div className="row">
              <div className="col-12">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true"> & times; </span>{" "}
                </button>{" "}
                <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                  <div className="icon w-130px mx-auto mb-xl-4 mb-3">
                    <img
                      src="../assets/images/logo.png"
                      className="img-fluid w-100"
                      alt="Warehousity Logo"
                    />
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="row">
              <div className="col-sm-12 form-group form-group-lg mb-4">
                <h5 className="text-success text-center">
                  {" "}
                  Your Password successfully has changed{" "}
                </h5>{" "}
              </div>{" "}
            </div>{" "}
            <div className="text-center">
              <button
                onClick={() => {
                  setPasswordSuccess(false);
                }}
                type="button"
                className="btn btn-deep-blue h-50px btn-block my-3 mx-auto"
              >
                {" "}
                Thank you{" "}
              </button>{" "}
            </div>{" "}
          </div>
        </Modal.Body>{" "}
      </Modal>
    </>
  );
};

export default Login;
