import React from "react";
import { Link } from "react-router-dom";

const FeedbackList = ({ item, index }) => {
  return (
    <tr>
      <td className="text-center py-2"> {index} </td>{" "}
      <td className="text-nowrap">
        <span className="text-capitalize">
          {" "}
          {item?.user?.firstName + " " + item?.user?.lastName}{" "}
        </span>{" "}
      </td>{" "}
      <td className="text-nowrap"> {item.warehouse?.warehouseId} </td>{" "}
      <td className="text-nowrap"> {item.warehouse?.warehouseName} </td>{" "}
      <td className="text-nowrap"> {item.feedbackText?.slice(0, 15)} </td>{" "}
      <td className="text-nowrap">
        <Link to={`/feedback/${item.id}`}>
          <i className="fa fa-eye"> </i>{" "}
        </Link>{" "}
      </td>
    </tr>
  );
};

export default FeedbackList;
