// Base URL
require('dotenv').config();
// export const BASE_URL = "http://139.59.13.212:8080";
// export const BASE_URL = 'https://backlive.warehousity.com';

export const BASE_URL = process.env.REACT_APP_DEVELOPMENT_URL;
// export const BASE_URL = "https://backlive.warehousity.com";

// console.log("dsjhsdj",BASE_URL)

// User Account
export const LOGIN_USER = '/api/v1/auth/login';

// GET_USER_URL
export const GET_USER_URL = '';
export const CREATE_NEW_USER_URL = '/api/v1/auth/signup';
export const GET_VENDOR_USER_URL = '/api/v1/accounts/vendors';
export const GET_CUSTOMER_USER_URL = '/api/v1/accounts/customers';
export const DELETE_USER_URL = '';
export const UPDATE_USER_URL = '';

// dashboard

export const DASHBOARD_URL = '/api/v1/dashboard/admin';
export const DASHBOARD_OPERATOR_TYPE_URL = '/api/v1/dashboard/admin/admindashboardtypeoperators';
export const DASHBOARD_LOCATION_WAREHOUSE_URL =
  '/api/v1/dashboard/admin/spaceLocationwarehousewise';
export const DASHBOARD_WAREHOUSE_REQUEST_RECEIVE_URL =
  '/api/v1/dashboard/admin/typeofwarehouserequestreceived';
export const DASHBOARD_LOCATION_FILTER_URL = '/api/v1/dashboard/admin/locationfilter';
export const DASHBOARD_WAREHOUSE_CATEGORY_URL = '/api/v1/dashboard/admin/warehousecategory';
export const DEMOGRAPHY_URL = '/api/v1/warehouses/admin/dashboard/demographicsummary';

// WAREHOUSE FORMS

export const ADD_WAREHOUSE = '/api/v1/warehouses';
export const UPDATE_WAREHOUSE_URL = '/api/v1/warehouses/warehousesadminreject'; // SEND ID WITH URL PUT REQUEST
export const UPDATE_CONTACT_URL = '/api/v1/whswarehousecontactdetail';
export const UPDATE_MHINFRA_URL = '/api/v1/whsmhinfra';
export const UPDATE_OFFICE_SPACE_URL = '/api/v1/whsstoragespace';
export const UPDATE_IT_INFRA_URL = '/api/v1/itinfra';
export const UPDATE_IT_INFRA_SPACE_URL = '/api/v1/whsitinfraofficespace';
export const UPDATE_MATERIAL_TYPE_URL = '/api/v1/whsmaterialtypes';
export const UPDATE_SAFETY_SECURITY = '/api/v1/whssafetyandsecurity';
export const UPDATE_PERMIT_URL = '/api/v1/traderelatedpermit';
export const FORM_EIGHTY_URL = '/api/v1/whsformeighty';
export const WAREHOUSE_IMAGES = '/api/v1/whswarehouseimage';
// export const GET_BY_ID_TYPE_URL = '/api/v1/warehouses/1/type/tradeRelatedPermit';

// WAREHOUSE LISTING
export const WAREHOUSE_LIST_URL = '/api/v1/warehouses/fetchallwarehouses';

export const WAREHOUSE_LIST_URL_NEW = '/api/v1/warehouses/fetchallwarehousesNew';
export const WAREHOUSE_VENDOR_LIST_URL_NEW = '/api/v1/warehouses/vendorfetchallwarehousesnew';

export const WAREHOUSE_BY_ID_URL = '/api/v1/warehouses'; // SEND ID WITH URL

// WAREHOUSE PENDING APPROVAL
export const UPDATE_WAREHOUSE_PENDING_APPROVAL_FORM = '/api/v1/whswarehouses';

// RFQ
// export const CREATE_RFQ_URL =
export const GET_RFQ_URL = '/api/v1/customerrfq/whsadmin';
export const GET_CUSTOMER_RFQ_URL = '/api/v1/customerrfq';

export const VENDOR_RESPONSE_RFQ_URL = '/api/v1/vendorresponserfq';
export const WHS_TO_CUSTOMER_RFQ = '/api/v1/customerresponserfq';

export const RFQ_STATUS_URL = '/api/v1/customerrfq/admin/getcutomervendorresponse/customerRfq'; ///api/v1/customerrfq/admin/getcutomervendorresponse/customerRfq/9

// Category Management
export const GET_CATEGORY_URL = '/api/v1/category';
export const CREATE_CATEGORY_URL = '/api/v1/category';
export const UPDATE_CATEGORY = '/api/v1/category';

// DOCUMENTS

export const DOCUMET_UPLOAD_URL = '/api/v1/buildingtraderelated/fileupload';
export const UPLOAD_CERTIFICATE_URL = '/api/v1/bookingdocument';

export const GET_DOCUMENT_URL = '/api/v1/bookingdocument'; ///api/v1/bookingdocument?page=1&limit=10

// Booking

export const GET_BOOKING_URL = '/api/v1/booking/fetchallbooking'; ///api/v1/booking/fetchallbooking?page=1&limit=10
export const BOOKING_BY_ID_URL = '/api/v1/booking'; ///api/v1/booking/8
export const UPDATE_BOOKING_URL = '/api/v1/booking/admin'; ///api/v1/booking/admin/1

// Invoice

export const GET_INVOICE_URL = '/api/v1/invoicemanagement';
export const INVOICE_BY_ID_URL = '/api/v1/invoicemanagement';
export const UPDATE_INVOICE_URL = '/api/v1/invoicemanagement';
export const CREATE_INVOICE_URL = '/api/v1/invoicemanagement';

// review management

export const GET_REVIEW_URL = '/api/v1/review';
export const REVIEW_BY_ID_URL = '/api/v1/review';
export const REVIEW_DELETE_BY_ID = '/api/v1/review';

// Feedback management
export const FEEDBACK_URL = '/api/v1/feedback';

// CMS mANAGEMENT
export const GET_CMS_URL = '/api/v1/cms/cmstype'; ///api/v1/cms/cmstype/about
export const CMS_BY_ID_URL = '';
export const CMS_UPDATE_URL = '';
export const CMS_BY_TYPE_URL = '/api/v1/cms/cmstype'; ///api/v1/cms/cmstype/about/1

// GET_REPORT_URL
export const GET_REPORT_URL =
  '/api/v1/enquiry/admin/enquiries/usertype/customer/status/pending?page=1&limit=10';
export const GET_DAILY_REPORT_URL = '/api/v1/dailyprogressreport';

// WHS User mangemet

export const GET_WHS_URL = '';
export const GET_WHS_BY_ID_URL = '';
export const CREATE_WHS_URL = '';
export const UPDATE_WHS_URL = '';

export const GET_ROLE_URL = '';
export const GET_ROLE_BY_ID_URL = '';
export const CREATE_ROLE_URL = '';
export const UPDATE_ROLE_URL = '';

export const GET_DEPARTMENT_URL = '';
export const GET_DEPARTMENT_BY_ID_URL = '';
export const CREATE_DEPARTMENT_URL = '';
export const UPDATE_DEPARTMENT_URL = '';

// Service Management

export const SERVICE_URL = '/api/v1/services';
export const SERVICE_BY_ID_URL = '/api/v1/services';
export const CREATE_SERVICE_URL = '/api/v1/services';
export const UPDATE_SERVICE_URL = '/api/v1/services';
export const SERVICE_CATEGORY_URL = '/api/v1/services/categories';
export const SERVICE_CATEGORY_BY_ID_URL = '/api/v1/services/categories';
export const CREATE_SERVICE_CATEGORY_URL = '/api/v1/services/categories';
export const UPDATE_SERVICE_CATEGORY_URL = '/api/v1/services/categories';
export const SERVICE_SUBCATEGORY_URL = '/api/v1/services/subcategories';
export const SERVICE_SUBCATEGORY_BY_ID_URL = '/api/v1/services/subcategories';
export const CREATE_SERVICE_SUBCATEGORY_URL = '/api/v1/services/subcategories';
export const UPDATE_SERVICE_SUBCATEGORY_URL = '/api/v1/services/subcategories';

// WHY WHS

export const WHYWHS_URL = '/api/v1/whywarehousity';
export const WHSTYPE_URL = '/api/v1/warehousetype';

// FILTER_URL
export const ADMIN_FILTER_URL = '/api/v1/filter/admin/filter';
export const FILTER_URL = '/api/v1/filter';
export const FILTER_OPTION_URL = '/api/v1/filter/filteroption';
export const UPDATE_FILTER_URL = '/api/v1/filter';

// OnBoarding
export const CONFIRM_DOCUMENT_URL = '/api/v1/customeronboarding/createcustomeronboarding';
export const GET_ONBOARD_URL = '/api/v1/customeronboarding/admin';
export const ONBOARD_BY_ID_URL = '/api/v1/customeronboarding/admin/customeronboarding';
export const ASSIGN_ONBOARD_URL = '/api/v1/customeronboarding';

// Notification

export const GET_NOTIFICATION_URL = '/api/v1/notification/admin';
export const NOTIFICATION_BY_ID_URL = '/api/v1/notification';
export const UPDATE_NOTIFICATION_URL = '/api/v1/notification';
export const CREATE_NOTIFICATION_URL = '/api/v1/notification';
