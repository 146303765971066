import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
// import * as Yup from 'yup';
// import {updateOutboundRFQ} from '../../../store/actions/customer/rfqAction'
import { useSelector } from "react-redux";
import { CardLoader } from "../../../components/common/CustomLoader";

const OutBoundForm = (isView) => {
  const data = useSelector((state) => state.RFQ_INFO);

  const [jsonData, setJsonData] = useState({
    outbounds: [
      {
        processType: "Vehicles Outbound",
        uom: "",
        weightPerUom: "",
        volume: "DAILY",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Invoice per Vehicle",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Cartons/ Boxes /Pallets per vehicle",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Quality Check Outbound",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Quality Check Outbound",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Packing / Packaging",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Cartonisation Packing",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Cartons / Pallet Weight Check",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Cartons / Pallet Weight Check",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Shipping Labels Outbound",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Goods Issue/Invoice/STN Generation",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
      {
        processType: "Cartons / Pallet Loading",
        uom: "",
        weightPerUom: "",
        volume: "",
        quantity: "",
        vehicleType: "",
        remark: "",
      },
    ],
    transferOrders: [
      {
        processType: "B2B Orders",
        uom: "",
        weightPerUom: "",
        volume: "DAILY",
        quantity: "",
        vehicleType: "",
        remark: "",
        salesOrdersType: [
          {
            transferOrderType: "B2B Orders",
            uom: "",
            weightPerUom: "",
            volume: "DAILY",
            quantity: "",
            vehicleType: "",
            remark: "",
          },
          {
            transferOrderType: "B2C Orders",
            uom: "",
            weightPerUom: "",
            volume: "DAILY",
            quantity: "",
            vehicleType: "",
            remark: "",
          },
        ],
      },
    ],
  });

  useEffect(() => {
    if (
      data.rfqDetail.data?.outbounds &&
      data.rfqDetail.data?.outbounds.length !== 0
    ) {
      console.log(data.rfqDetail.data,"||*****|*&&*|*****||")
      setJsonData({
        outbounds: data.rfqDetail.data?.outbounds,
        transferOrders: [
          {
            ...data.rfqDetail.data?.transferOrder[0],
            salesOrdersType:
              data.rfqDetail.data?.transferOrder[0].saleOrderType,
          },
        ],
      });
    }
  }, [data?.rfqDetail?.data]);

  return (
    <>
      {" "}
      {data.isLoading ? (
        <CardLoader loaderCard="loaderCard" />
      ) : (
        <Formik
          enableReinitialize={true}
          // validationSchema={manSchema}
          initialValues={jsonData}
          onSubmit={(fields) => {
            console.log("---->", fields);
          }}
          render={({ values, errors, status, onChange, touched }) => {
            return (
              <div className="w-100 d-block">
                <Form>
                  <div className="row">
                    <div className="col-12 ml-0">
                      <div className="table-responsive border bg-deep-gray rounded-md table-cell">
                        <table className="table text-center form-control-height">
                          <tbody>
                            <tr>
                              <td className="border-top-0 font-weight-bold py-2">
                                {" "}
                                S.no{" "}
                              </td>{" "}
                              <td className="text-left border-top-0 font-weight-bold text-nowrap py-2">
                                {" "}
                               Order Handling Unit{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold py-2 mw-30px">
                                {" "}
                                Monthly Qty{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold text-nowrap py-2">
                                {" "}
                                Order UOM{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold py-2 w-100px text-nowrap">
                                {" "}
                                Qty/Order{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold py-2 mw-30px">
                                {" "}
                                Picking Unit{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold text-nowrap py-2">
                                {" "}
                                VAS{" "}
                              </td>{" "}
                            </tr>

                            {values.outbounds &&
                              values.outbounds.length > 0 &&
                              values.outbounds.map((man, index) => {
                                return (
                                  <tr>
                                    <td> {index + 1} </td>{" "}
                                    <td className="text-left">
                                      {" "}
                                      {man.processType}{" "}
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`outbounds.${index}.monthlyQty`}
                                        type="text"
                                        className="form-control text-center bg-white border-0 my-1"
                                        placeholder=""
                                        readOnly={isView}
                                      />
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`outbounds.${index}.orderUOM`}
                                        type="text"
                                        className="form-control text-center bg-white border-0 my-1"
                                        readOnly={isView}
                                      />
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`outbounds.${index}.orderQty`}
                                        type="text"
                                        className="form-control text-center bg-white border-0 my-1"
                                        readOnly={isView}
                                      />
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`outbounds.${index}.packingunit`}
                                        type="number"
                                        className="form-control text-center bg-white border-0 my-1"
                                        readOnly={isView}
                                      />
                                    </td>{" "}
                                    <td>
                                      <Field
                                        name={`outbounds.${index}.vas`}
                                        type="text"
                                        className="form-control text-center bg-white border-0 my-1"
                                        readOnly={isView}
                                      />
                                    </td>{" "}
                                  </tr>
                                );
                              })}

                            {values.transferOrders &&
                              values.transferOrders.length > 0 &&
                              values.transferOrders.map((man, index) => {
                                return (
                                  <>
                                    <>
                                      <tr key={index}>
                                        <td>2 </td>{" "}
                                        <td className="text-left">
                                          {" "}
                                          {man.processType}{" "}
                                        </td>{" "}
                                        <td>
                                          <Field
                                            name={`transferOrders.${index}.monthlyQty`}
                                            type="text"
                                            className="form-control text-center bg-white border-0 my-1"
                                            placeholder=""
                                            readOnly={isView}
                                          />
                                        </td>{" "}
                                        <td>
                                          <Field
                                            name={`transferOrders.${index}.orderUOM`}
                                            type="text"
                                            className="form-control text-center bg-white border-0 my-1"
                                            readOnly={isView}
                                          />
                                        </td>{" "}
                                        <td>
                                          <Field
                                            name={`transferOrders.${index}.orderQty`}
                                            type="text"
                                            className="form-control text-center bg-white border-0 my-1"
                                            readOnly={isView}
                                          />
                                        </td>{" "}
                                        <td>
                                          <Field
                                            name={`transferOrders.${index}.packingunit`}
                                            type="number"
                                            className="form-control text-center bg-white border-0 my-1"
                                            readOnly={isView}
                                          />
                                        </td>{" "}
                                        <td>
                                          <Field
                                            name={`transferOrders.${index}.vas`}
                                            type="text"
                                            className="form-control text-center bg-white border-0 my-1"
                                            readOnly={isView}
                                          />
                                        </td>{" "}                                        
                                      </tr>{" "}
                                    </>{" "}
                                    <tr>
                                      <td></td>
                                      <td className="text-left">
                                       3. Sales Orders{" "}
                                      </td>{" "}
                                    </tr>{" "}
                                    <>
                                      {" "}
                                    {values.transferOrders[0].salesOrdersType.map((salse, i) => {
                                      console.log(salse,"ddddddd")
                                        return (
                                          <tr key={i}>
                                            <td></td>{" "}
                                            <td className="text-left">
                                              {" "}
                                              {man.processType}{" "}
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`transferOrders[0].salesOrdersType.${i}.monthlyQty`}
                                                type="text"
                                                className="form-control text-center bg-white border-0 my-1"
                                                placeholder=""
                                                readOnly={isView}
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`transferOrders[0].salesOrdersType.${i}.orderUOM`}
                                                type="text"
                                                className="form-control text-center bg-white border-0 my-1"
                                                readOnly={isView}
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`transferOrders[0].salesOrdersType.${i}.orderQty`}
                                                type="text"
                                                className="form-control text-center bg-white border-0 my-1"
                                                readOnly={isView}
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`transferOrders[0].salesOrdersType.${i}.packingunit`}
                                                type="number"
                                                className="form-control text-center bg-white border-0 my-1"
                                                readOnly={isView}
                                              />
                                            </td>{" "}
                                            <td>
                                              <Field
                                                name={`transferOrders[0].salesOrdersType.${i}.vas`}
                                                type="text"
                                                className="form-control text-center bg-white border-0 my-1"
                                                readOnly={isView}
                                              />
                                            </td>{" "}
                                          </tr>
                                        );
                                      })}
                                    </>{" "}
                                  </>
                                );
                              })}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>{" "}
              </div>
            );
          }}
        />
      )}{" "}
    </>
  );
};

export default OutBoundForm;
