import {
    SERVICE,
    SERVICE_BY_ID,
    SERVICE_CATEGORY,
    SERVICE_CATEGORY_BY_ID,
    SERVICE_SUBCATEGORY,
    SERVICE_SUBCATEGORY_BY_ID,
    SERVICE_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
    SERVICE_RECIEVE,
    SERVICE_RECIEVE_BY_ID,
} from "./../types";

import {
    SERVICE_URL,
    SERVICE_BY_ID_URL,
    CREATE_SERVICE_URL,
    UPDATE_SERVICE_URL,
    SERVICE_CATEGORY_URL,
    SERVICE_CATEGORY_BY_ID_URL,
    SERVICE_SUBCATEGORY_URL,
    CREATE_SERVICE_CATEGORY_URL,
    UPDATE_SERVICE_CATEGORY_URL,
    SERVICE_SUBCATEGORY_BY_ID_URL,
    CREATE_SERVICE_SUBCATEGORY_URL,
    UPDATE_SERVICE_SUBCATEGORY_URL,
} from "../../services/urls";

import axiosauth from "../../services/axios-auth";

export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}

export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}

export function serviceList(data) {
    return {
        type: SERVICE,
        payload: data,
    };
}
export function serviceRecieve(data) {
    return {
        type: SERVICE_RECIEVE,
        payload: data,
    };
}
export function service_By_Id(data) {
    return {
        type: SERVICE_BY_ID,
        payload: data,
    };
}

export function service_Recieve_By_Id(data) {
    return {
        type: SERVICE_RECIEVE_BY_ID,
        payload: data,
    };
}
export function serviceCategoryList(data) {
    return {
        type: SERVICE_CATEGORY,
        payload: data,
    };
}

export function serviceCategory_By_Id(data) {
    return {
        type: SERVICE_CATEGORY_BY_ID,
        payload: data,
    };
}

export function serviceSubCategoryList(data) {
    return {
        type: SERVICE_SUBCATEGORY,
        payload: data,
    };
}

export function serviceSubCategory_By_Id(data) {
    return {
        type: SERVICE_SUBCATEGORY_BY_ID,
        payload: data,
    };
}

export function responseService(data) {
    return {
        type: SERVICE_RESPONSE,
        payload: data,
    };
}

// ###########  Fecth All ServiceList ########

export const serviceByPage = () => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        // dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(SERVICE_URL)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    console.log("service list==>", res);
                    // if (res.statusCode === 200) {
                    dispatch(isLoading(false));
                    dispatch(serviceList(res));
                    dispatch(isLoading(false));
                    // dispatch(isPending(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

export const serviceRecieveByPage = (page) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        // dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get("/api/v1/servicerfq" + `?page=${page}&limit=10&type=admin`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    console.log("service list==>", res);
                    // if (res.statusCode === 200) {
                    dispatch(isLoading(false));
                    dispatch(serviceRecieve(res.data));
                    dispatch(serviceList(res));
                    dispatch(isLoading(false));
                    // dispatch(isPending(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

// ###########  Fecth Service Details by id ########

export const serviceById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(SERVICE_BY_ID_URL + `/${id}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(service_By_Id(res));
                    dispatch(isLoading(false));
                    dispatch(isPending(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                });
        } catch (e) {}
    };
};

export const serviceRecieveById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(`/api/v1/servicerfq/${id}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(service_Recieve_By_Id(res.data));
                    dispatch(serviceRecieveByPage());
                    dispatch(isLoading(false));
                    dispatch(isPending(false));

                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

// ###########  Add new Services ##########

export const createService = (data) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .post(CREATE_SERVICE_URL, data)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(responseService(res));
                    dispatch(isLoading(false));
                    dispatch(isPending(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                });
        } catch (e) {}
    };
};

// ###########  Update new Services ##########

export const updateService = (id, data) => {
    return async (dispatch) => {
        dispatch(isPending(true));

        dispatch(isError(""));
        try {
            axiosauth
                .patch(UPDATE_SERVICE_URL + `/${id}`, data)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(responseService(res));
                    dispatch(isPending(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isPending(false));
                })
                .then(() => {
                    dispatch(isPending(false));
                });
        } catch (e) {}
    };
};

export const updateRecieveService = (id, data) => {
    return async (dispatch) => {
        dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .put(`/api/v1/servicerfq/${id}`, data)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {


                    dispatch(responseService(res));
                    dispatch(serviceRecieveByPage());

                    dispatch(isPending(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isPending(false));
                })
                .then(() => {
                    dispatch(isPending(false));
                });
        } catch (e) {}
    };
};

// ###########  Fecth Category by page ########

export const serviceCategoryByPage = () => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(SERVICE_CATEGORY_URL)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(serviceCategoryList(res));
                    dispatch(isLoading(false));
                    dispatch(isPending(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                });
        } catch (e) {}
    };
};

// ###########  Fecth Category by ID ########

export const serviceCategoryById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(SERVICE_CATEGORY_BY_ID_URL + `/${id}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(isLoading(false));
                    dispatch(serviceCategory_By_Id(res));
                    dispatch(isLoading(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                });
        } catch (e) {}
    };
};

// ###########  Craete Category ########

export const createServiceCategory = (data) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .post(CREATE_SERVICE_CATEGORY_URL, data)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(responseService(res));
                    dispatch(isLoading(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                });
        } catch (e) {}
    };
};

// ###########  update Category ########

export const updateServiceCategory = (id, data) => {
    console.log("ffff", data)
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .patch(UPDATE_SERVICE_CATEGORY_URL + `/${id}`, data)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {

                    dispatch(serviceCategoryByPage());
                    dispatch(responseService(res));



                    dispatch(isLoading(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                });
        } catch (e) {}
    };
};

// ###########  Fecth Sub Category by page ########

export const serviceSubCategoryByPage = () => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(SERVICE_SUBCATEGORY_URL)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(serviceSubCategoryList(res));
                    dispatch(isLoading(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                });
        } catch (e) {}
    };
};

// ###########  Fecth Sub Category by Id ########

export const serviceSubCategoryById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(SERVICE_SUBCATEGORY_BY_ID_URL + `/${id}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    // if (res.statusCode === 200) {
                    dispatch(serviceSubCategory_By_Id(res));
                    dispatch(isLoading(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

// ###########  Create Sub Category ########

export const createServiceSubCategory = (data) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isPending(true));

        dispatch(isError(""));
        try {
            axiosauth
                .post(CREATE_SERVICE_SUBCATEGORY_URL, data)
                .then((response) => {
                    let res = JSON.parse(response.data);

                    // if (res.statusCode === 200) {
                    dispatch(responseService(res));
                    dispatch(isLoading(false));
                    dispatch(isPending(false));
                    // }
                    // else {
                    // console.log("service Fail")
                    // }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                });
        } catch (e) {}
    };
};

// ###########  Create Sub Category ########

export const updateServiceSubCategory = (id, data) => {
    return async (dispatch) => {
        dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .patch(UPDATE_SERVICE_SUBCATEGORY_URL + `/${id}`, data)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    dispatch(responseService(res));
                    dispatch(isPending(false));
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isPending(false));
                })
                .then(() => {
                    dispatch(isPending(false));
                });
        } catch (e) {}
    };
};

// ###########  Create Sub Category ########

export const fetchSubCatByCatId = (id) => {
    return async (dispatch) => {
        dispatch(isPending(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(`/api/v1/services/categories/${id}/subcategories/homepage`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    dispatch(serviceSubCategoryList(res));
                    dispatch(isPending(false));
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isPending(false));
                })
                .then(() => {
                    dispatch(isPending(false));
                });
        } catch (e) {}
    };
};