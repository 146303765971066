import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import WHSDepartmentList from "../../wrapper/whsUserManagement/WHSDepartmentList";
import { Helmet } from "react-helmet";
import { departmentByPage } from "../../store/action/whsUserAction";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
// import { ItemNotFound } from '../../components/helper/CustomLoader';
import SearchBox from "../../components/common/SearchBox";
import LinkButton from "../../components/common/LinkButton";

const WhsDepartment = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WHS_USER_INFO);
  const pageCount = new URLSearchParams(window.location.search).get("page");
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);

  useEffect(() => {
    dispatch(departmentByPage("all", parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(departmentByPage("all", pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(departmentByPage("all", pageCount, searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> WHS Department </title>{" "}
      </Helmet>
      <div className="row justify-content-end align-items-center pt-3 pb-2 px-3 mx-0">
        <div className="bg-white mx-0 row w-100 align-items-center">
          <div className="col-12 col-sm-12 col-md-12 col-xl">
            <div className="row ">
              <div className="col-auto d-flex h-100 px-2 ">
                <div className="dropdown btn-export">
                  <button
                    className="custom-btn btn-deep-blue dropdown-toggle"
                    type="button"
                    id="dropdownMenu5"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Export{" "}
                  </button>{" "}
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#!">
                      {" "}
                      Excel{" "}
                    </a>{" "}
                    <div className="dropdown-divider"> </div>{" "}
                    <a className="dropdown-item" href="#!">
                      {" "}
                      PDF{" "}
                    </a>{" "}
                  </div>{" "}
                </div>{" "}
              </div>

              <div className="col-auto d-flex h-100 px-2">
                <LinkButton
                  link={`/create-wsh-department`}
                  label=" Create Department"
                />
              </div>
            </div>{" "}
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-xl-auto ">
            <SearchBox
              callSearch={callSearch}
              setSearchHandler={setSearchHandler}
              border={true}
            />{" "}
          </div>{" "}
        </div>{" "}
        <div className="col-12">
          <div className="row">
            <div className="col-12 px-0">
              <h5 className="text-dark pt-3"> Manage Department </h5>{" "}
            </div>{" "}
            <div className="col-12 text-nowrap table-responsive table-gray-admin bg-white p-3">
              <table className="table table-dark-custom">
                <thead>
                  <tr>
                    <th className="text-center"> S.No. </th>{" "}
                    <th> Created On </th> <th> Department </th>
                    <th> Active / In Active </th>{" "}
                    <th className="text-center"> View Details </th>{" "}
                  </tr>{" "}
                </thead>{" "}
                <tbody>
                  {" "}
                  {data.departmentList && data.departmentList.length > 0
                    ? data.departmentList.map((item, index) => (
                        <WHSDepartmentList
                          item={item}
                          index={
                            ((pageCount ? pageCount : 1) - 1) * 10 + (index + 1)
                          }
                          key={index}
                          read={read}
                        />
                      ))
                    : null}{" "}
                </tbody>{" "}
              </table>
              {data.departmentList?.totalCount > 10 && (
                <div className="pagination-custom">
                  <Pagination
                    activePage={parseInt(pageCount)}
                    itemsCountPerPage={10}
                    totalItemsCount={data.departmentList?.totalCount}
                    pageRangeDisplayed={1}
                    onChange={handlePageChange}
                    prevPageText={<i className="fas fa-chevron-left" />}
                    nextPageText={<i className="fas fa-chevron-right" />}
                    hideFirstLastPages={true}
                  />{" "}
                </div>
              )}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default WhsDepartment;
