import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { onlyAlphaNumericSpaceAllow } from "../../components/helper/reuse";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerOnboardForm,
  updateUserForm,
  responseOnboard,
  getSubUserAssignOnboardDetailById,
} from "../../store/action/onBoardAction";
import CustomerCommonForm from "../../wrapper/customerOnboarding/CustomerCommonForm";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";

const CustomerBillingSupport = () => {
  const history = useHistory();
  const { onBoardId } = useParams();
  const dispatch = useDispatch();
  const formType = new URLSearchParams(window.location.search).get("formType");
  const assignUserId = new URLSearchParams(window.location.search).get(
    "assignUserId"
  );
  const onData = useSelector((state) => state.ON_BOARD_INFO);
  const contactDetailOnboardId = new URLSearchParams(
    window.location.search
  ).get("contactDetailOnboardId");

  const [costJson, setJsonData] = useState({
    billingSupports: {
      transition: [
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
      ],
      preparedBy: "",
      preparedByDate: "",
      checkedBy: "",
      checkedByDate: "",
      approvedBy: "",
      approveByDate: "",
      ReceivedBy: "",
      ReceivedByDate: "",
    },
  });

  useEffect(() => {
    console.log("bill->", onData.formDetail?.billingSupport);
    if (onData.formDetail?.billingSupport) {
      setJsonData({
        billingSupports: onData.formDetail?.billingSupport?.billingSupports,
      });
    }

    // if (formType === "billingSupport" && onData.userOnboardDetail?.billingSupport && onData.userOnboardDetail?.billingSupport?.billingSupports) {
    //   setJsonData({
    //     billingSupports: onData.userOnboardDetail.billingSupport?.billingSupports
    //   })
    // }
  }, [onData.formDetail?.billingSupport, onData.userOnboardDetail, formType]);

  useEffect(() => {
    if (formType === "billingSupport") {
      dispatch(getSubUserAssignOnboardDetailById("billingSupport", onBoardId));
    } else {
      dispatch(
        getCustomerOnboardForm(assignUserId, "billingSupport", onBoardId)
      );
    }
  }, [dispatch, onBoardId, formType, assignUserId]);

  const schema = Yup.object().shape({
    billingSupports: Yup.object().shape({
      preparedBy: Yup.string().required("Required"),
      preparedByDate: Yup.string().required("Required"),
      checkedBy: Yup.string().required("Required"),
      checkedByDate: Yup.string().required("Required"),
      approvedBy: Yup.string().required("Required"),
      approveByDate: Yup.string().required("Required"),
      ReceivedBy: Yup.string().required("Required"),
      ReceivedByDate: Yup.string().required("Required"),
    }),
  });

  const redirect = () => {
    dispatch(responseOnboard(null));
    history.goBack();
  };

  return (
    <LayoutOne>
      {" "}
      {onData.onResponse && onData.onResponse?.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={onData.onResponse?.message} />
      ) : null}
      <div className="row align-items-center py-2 mx-0 form-control-height">
        <div className="col-12 py-2 mt-2">
          <span onClick={() => history.goBack()}>
            {" "}
            <h5 className="text-dark-blue cursorPointer">
              {" "}
              <i className="fas fa-chevron-left mr-3"> </i> Billing Support
            </h5>{" "}
          </span>{" "}
        </div>{" "}
        <CustomerCommonForm buttonHide={true} />{" "}
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={costJson}
            onSubmit={(fields) => {
              if (contactDetailOnboardId) {
                dispatch(
                  updateUserForm(
                    contactDetailOnboardId,
                    "billingSupport",
                    fields
                  )
                );
              }
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <>
                    <div className="row mx-0">
                      <div className="col-12 px-0 table-responsive table-gray-admin">
                        <table className="table text-left">
                          <tbody>
                            {" "}
                            {/* <tr>
                                                  <td className="text-deep-blue bg-white border-left-0 border-right-0 font-weight-bold py-2">{`SELECT SOLUTION TYPE ====>`}</td>  
                                                  <td className="text-deep-blue bg-white border-left-0 border-right-0 font-weight-bold py-2">PACKAGE</td>  
                                                  <td className="text-deep-blue bg-white border-left-0 border-right-0 font-weight-bold py-2">Add-Ons</td>  
                                                  <td className="text-deep-blue bg-white border-left-0 border-right-0 font-weight-bold py-2">CUSTOMIZED</td>  
                                                </tr> */}{" "}
                            <tr>
                              <td className="font-weight-bold py-2">
                                {" "}
                                Activities{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Frequency{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Source of Data{" "}
                              </td>{" "}
                              <td className="font-weight-bold py-2">
                                {" "}
                                Supporting Document{" "}
                              </td>{" "}
                            </tr>
                            {values.billingSupports?.transition &&
                              values.billingSupports.transition?.length > 0 &&
                              values.billingSupports.transition.map(
                                (ins, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        <Field
                                          onKeyPress={(e) =>
                                            onlyAlphaNumericSpaceAllow(e)
                                          }
                                          name={`billingSupports.transition.${index}.activities`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`billingSupports.transition.${index}.activities`} component="div" className="invalid-feedback" /> */}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`billingSupports.transition.${index}.frequency`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`billingSupports.transition.${index}.frequency`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`billingSupports.transition.${index}.sourceofData`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`billingSupports.transition.${index}.sourceofData`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`billingSupports.transition.${index}.supportingDocument`}
                                          type="text"
                                          className={`form-control bg-white border-0 my-1`}
                                        />{" "}
                                        {/* <ErrorMessage name={`billingSupports.transition.${index}.supportingDocument`} component="div" className="invalid-feedback" /> */}{" "}
                                      </td>{" "}
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </div>
                    <div className="row py-4 mt-1">
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Prepared by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="billingSupports.preparedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.billingSupports?.preparedBy &&
                              touched.billingSupports?.preparedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="billingSupports.preparedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="billingSupports.preparedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.billingSupports?.preparedByDate &&
                              touched.billingSupports?.preparedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="billingSupports.preparedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Checked by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="billingSupports.checkedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.billingSupports?.checkedBy &&
                              touched.billingSupports?.checkedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="billingSupports.checkedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="billingSupports.checkedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.billingSupports?.checkedByDate &&
                              touched.billingSupports?.checkedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="billingSupports.checkedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Approved by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="billingSupports.approvedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.billingSupports?.approvedBy &&
                              touched.billingSupports?.approvedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="billingSupports.approvedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="billingSupports.approveByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.billingSupports?.approveByDate &&
                              touched.billingSupports?.approveByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="billingSupports.approveByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>{" "}
                      <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                        <label htmlFor="" className="px-2">
                          {" "}
                          Received by{" "}
                        </label>{" "}
                        <div className="input-group">
                          <Field
                            name="billingSupports.ReceivedBy"
                            type="text"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.billingSupports?.ReceivedBy &&
                              touched.billingSupports?.ReceivedBy
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="billingSupports.ReceivedBy"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                        <label htmlFor="" className="px-2 mt-2">
                          {" "}
                          Date{" "}
                        </label>{" "}
                        <div className="">
                          <Field
                            name="billingSupports.ReceivedByDate"
                            type="date"
                            id="spaceproviderfirstname"
                            className={
                              `form-control px-3 rounded-pill` +
                              (errors.billingSupports?.ReceivedByDate &&
                              touched.billingSupports?.ReceivedByDate
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Type here"
                          />
                          <ErrorMessage
                            name="billingSupports.ReceivedByDate"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 py-5 px-5">
                        <button
                          type="submit"
                          disabled={onData.isPending}
                          className="btn btn-deep-blue py-2"
                        >
                          Save{" "}
                          {onData.isPending ? (
                            <Spinner animation="border" />
                          ) : null}{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>{" "}
                  </>{" "}
                </Form>
              );
            }}
          />
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default CustomerBillingSupport;
