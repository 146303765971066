import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateWarehousependingDetails,
  addNewWarehouse,
  changeWarehouseStatus1,
} from './../../../store/action/warehouseAction';
import { Formik, Form, Field, FieldArray } from 'formik';
import FormSuccess from './../../../components/common/FormSuccess';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';
import CustomLoader from '../../../CustomLoader';

const ContactPendingForm = ({ warehouseId, read, slug }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const onSubmit = (fields) => {
    const payload = {
      data: {
        fields: fields,
      },
      warehouseId: warehouseId,
      slug: slug,
    };
    dispatch(updateWarehousependingDetails(payload));
  };

  const [remarkForm, setRemarkForm] = useState({
    contactInfo: {
      name: {
        value: '',
        whsremark: '',
      },
      phone: {
        value: '',
        whsremark: '',
      },
      altMobileNumber: {
        value: '',
        whsremark: '',
      },
      email: {
        value: '',
        whsremark: '',
      },
      altEmail: {
        value: '',
        whsremark: '',
      },
    },
    address: {
      address1: {
        value: '',
        whsremark: '',
      },
      address2: {
        value: '',
        whsremark: '',
      },
      country: {
        value: 'India',
        whsremark: '',
      },
      state: {
        value: '',
        whsremark: '',
      },
      city: {
        value: '',
        whsremark: '',
      },

      district: {
        value: '',
        whsremark: '',
      },
      landmark: {
        value: '',
        whsremark: '',
      },

      pinCode: {
        value: '',
        whsremark: '',
      },

      latitude: {
        value: '',
        whsremark: '',
      },
      longnitude: {
        value: '',
        whsremark: '',
      },
    },
    companyName: {
      value: '',
      whsremark: '',
    },
  });
  useEffect(() => {
    if (data?.singleFormData !== undefined && data?.singleFormData !== null) {
      if (
        data?.singleFormData?.remarks !== null &&
        data?.singleFormData?.remarks !== undefined &&
        data?.singleFormData?.remarks?.warehouseContactsRemarks !== undefined &&
        Object.keys(data?.singleFormData?.remarks?.warehouseContactsRemarks).length > 0
      ) {
        setRemarkForm(data?.singleFormData?.remarks?.warehouseContactsRemarks);
      } else {
        if (
          data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.warehouseContacts !== undefined
        ) {
          setRemarkForm({
            contactInfo: {
              name: {
                value: data?.singleFormData?.warehouseRes?.warehouseContacts[0]?.warehouseName,
                whsremark: '',
              },
              phone: {
                value: '',
                whsremark: '',
              },
              altMobileNumber: {
                value: '',
                whsremark: '',
              },
              email: {
                value: '',
                whsremark: '',
              },
              altEmail: {
                value: '',
                whsremark: '',
              },
            },
            address: {
              address1: {
                value: data?.singleFormData?.warehouseRes?.warehouseContacts[0]?.address1,
                whsremark: '',
              },
              address2: {
                value: data?.singleFormData?.warehouseRes?.warehouseContacts[0]?.address2,
                whsremark: '',
              },
              country: {
                value: 'India',
                whsremark: '',
              },
              state: {
                value: data?.singleFormData?.warehouseRes?.warehouseContacts[0]?.state,
                whsremark: '',
              },
              city: {
                value: '',
                whsremark: '',
              },

              district: {
                value: data?.singleFormData?.warehouseRes?.warehouseContacts[0]?.district,
                whsremark: '',
              },
              landmark: {
                value: '',
                whsremark: '',
              },

              pinCode: {
                value: data?.singleFormData?.warehouseRes?.warehouseContacts[0]?.pincode,
                whsremark: '',
              },

              latitude: {
                value: data?.singleFormData?.warehouseRes?.warehouseContacts[0]?.gpsLatitude,
                whsremark: '',
              },
              longnitude: {
                value: data?.singleFormData?.warehouseRes?.warehouseContacts[0]?.gpsLongitude,
                whsremark: '',
              },
            },
            companyName: {
              value: '',
              whsremark: '',
            },
          });
        }
      }
    }
  }, [data?.singleFormData]);
  const history = useHistory();

  const handleNextPage = () => {
    if (data?.addNewResponse && data?.addNewResponse?.statusCode === 200) {
      history.push(`/pending-details/${warehouseId}/storage-space`);
      dispatch(changeWarehouseStatus1());
    }
  };

  return (
    <>
      {/* Loader */}

      {data?.isLoading ? (
        <CustomLoader />
      ) : (
        <>
          {/* {data?.addNewResponse && data?.addNewResponse?.statusCode === 200 ? (
            // <FormSuccess onClick={handleNextPage} message={data.addNewResponse.message} />
            <FormSuccess onClick={handleNextPage} message={data.addNewResponse.message} />
          ) : null}{' '} */}
          <div className='row align-items-center mx-0'>
            <div className='col-12'>
              <Formik
                enableReinitialize={true}
                initialValues={remarkForm}
                // validationSchema={validationSchema}
                onSubmit={(fields) => {
                  onSubmit(fields);
                }}
                render={({ errors, status, onChange, setFieldValue, touched, values }) => (
                  <Form>
                    <div className='row bg-white rounded mx-0 col-xxxl-11'>
                      <div className='w-30 px-0 ml-5 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Warehouse Name:
                          </label>
                          <Field
                            name='contactInfo.name.value'
                            className={
                              'form-control bg-white px-4' +
                              (errors?.contactInfo?.name?.value && touched?.contactInfo?.name?.value
                                ? ' is-invalid'
                                : '')
                            }
                            placeholder='Enter Warehouse Name'
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='contactInfo.name.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Company Name
                          </label>
                          <Field
                            name='companyName.value'
                            className={
                              'form-control bg-white px-4' +
                              (errors?.companyName?.value && touched?.companyName?.value
                                ? ' is-invalid'
                                : '')
                            }
                            placeholder='Enter Company Name'
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='companyName.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 border mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Mobile No.:
                          </label>
                          <Field
                            name='contactInfo.phone.value'
                            type='number'
                            className={
                              'form-control bg-white px-4' +
                              (errors?.contactInfo?.phone && touched?.contactInfo?.phone
                                ? ' is-invalid'
                                : '')
                            }
                            placeholder='Enter Mobile No.'
                            maxLength='10'
                            onKeyDown={(e) => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='contactInfo.phone.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 ml-5 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Alternate Mobile:
                          </label>
                          <Field
                            name='contactInfo.altMobileNumber.value'
                            type='number'
                            className={
                              'form-control bg-white px-4' +
                              (errors?.contactInfo?.altMobileNumber?.value &&
                              touched?.contactInfo?.altMobileNumber?.value
                                ? ' is-invalid'
                                : '')
                            }
                            placeholder='Enter Alternate Mobile'
                            maxLength='10'
                            onKeyDown={(e) => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='contactInfo.altMobileNumber.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Email:
                          </label>
                          <Field
                            name='contactInfo.email.value'
                            type='text'
                            className={
                              'form-control bg-white px-4' +
                              (errors?.contactInfo?.email && touched?.contactInfo?.email
                                ? ' is-invalid'
                                : '')
                            }
                            placeholder='Enter Email'
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='contactInfo.email.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 border mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Alternate Email:
                          </label>
                          <Field
                            name='contactInfo.altEmail.value'
                            type='email'
                            className={
                              'form-control bg-white px-4' +
                              (errors.altEmail && touched.altEmail ? ' is-invalid' : '')
                            }
                            placeholder='Enter Alternate Email'
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='contactInfo.altEmail.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 ml-5 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Address 1:
                          </label>
                          <Field
                            name='address.address1.value'
                            type='text'
                            className={
                              'form-control bg-white px-4' +
                              (errors?.address1 && touched?.address1 ? ' is-invalid' : '')
                            }
                            placeholder='Enter Address'
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='address.address1.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Address 2:
                          </label>
                          <Field
                            name='address.address2.value'
                            type='text'
                            className={
                              'form-control bg-white px-4' +
                              (errors?.address2 && touched?.address2 ? ' is-invalid' : '')
                            }
                            placeholder='Enter Address'
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='address.address2.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 border mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='country' className='mb-2 px-2'>
                            Country:
                          </label>
                          <Field
                            name='address.country.value'
                            type='text'
                            className={'form-control bg-white px-4'}
                            placeholder='Enter country'
                            // readOnly={viewMood}
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='address.country.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 ml-5 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='state' className='mb-2 px-2'>
                            State:
                          </label>

                          <Field
                            name='address.state.value'
                            type='text'
                            className={'form-control bg-white px-4'}
                            placeholder='Enter state'
                            // readOnly={viewMood}
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='address.state.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='city' className='mb-2 px-2'>
                            {' '}
                            City:
                          </label>
                          <Field
                            name='address.city.value'
                            type='text'
                            className={'form-control bg-white px-4'}
                            placeholder='Enter city'
                            // readOnly={viewMood}
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='address.city.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 border mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            District:
                          </label>
                          <Field
                            name='address.district.value'
                            type='text'
                            className={
                              'form-control bg-white px-4' +
                              (errors.district && touched.district ? ' is-invalid' : '')
                            }
                            placeholder='Enter District'
                            // readOnly={viewMood}
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='address.district.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className='w-30 px-0 ml-5 border mr-3 mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Landmark:
                          </label>
                          <Field
                            name='address.landmark.value'
                            type='text'
                            className={
                              'form-control bg-white px-4' +
                              (errors.landmark && touched.landmark ? ' is-invalid' : '')
                            }
                            placeholder='Enter Landmark'
                            // readOnly={viewMood}
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='address.landmark.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>
                      <div className='w-30 px-0 border mb-3'>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Pincode:
                          </label>
                          <Field
                            name='address.pinCode.value'
                            type='number'
                            className={
                              'form-control bg-white px-4' +
                              (errors.pincode && touched.pincode ? ' is-invalid' : '')
                            }
                            placeholder='Enter Pincode'
                            maxLength='6'
                            onKeyDown={(e) => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                            // readOnly={viewMood}
                          />
                        </div>
                        <div className='form-group col-12 mb-2 px-sm-3 mt-2'>
                          <label htmlFor='staticEmail' className='mb-2 px-2'>
                            Whs Remarks{' '}
                          </label>
                          <Field
                            name='address.pinCode.whsremark'
                            type='text'
                            className='form-control  px-4 ml-xl-0 ml-3 mb-2'
                            placeholder='Remarks'
                          />
                        </div>
                      </div>

                      <div className={`col-12 mt-2`}>
                        <div className='row justify-content-end'>
                          <div className='col-auto'>
                            {Object.keys(touched).length > 0 ? (
                              <button
                                type='submit'
                                disabled={data.isPending}
                                className='btn btn-deep-blue add-class remove-class'
                              >
                                Save
                                {data?.isPending ? <Spinner animation='border' /> : null}
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContactPendingForm;
