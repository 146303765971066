import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import CustomerCommonForm from "../../wrapper/customerOnboarding/CustomerCommonForm";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerOnboardForm,
  getSubUserAssignOnboardDetailById,
  updateUserForm,
  formDetails,
  userOnboardDetail,
  responseOnboard,
} from "../../store/action/onBoardAction";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";

const CustomerContactDetails = () => {
  const { onBoardId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const formType = new URLSearchParams(window.location.search).get("formType");
  const assignUserId = new URLSearchParams(window.location.search).get(
    "assignUserId"
  );
  const isAdmin = new URLSearchParams(window.location.search).get("admin");
  const contactDetailOnboardId = new URLSearchParams(
    window.location.search
  ).get("contactDetailOnboardId");

  const onData = useSelector((state) => state.ON_BOARD_INFO);

  const [contactJson, setContactJson] = useState({
    contactAndEscaltions: {
      operationalContact: [
        {
          type: "Name",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Designation",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Email",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Phone",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
      ],

      escalationOne: [
        {
          type: "Name",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Designation",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Email",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Phone",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
      ],

      escalationTwo: [
        {
          type: "Name",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Designation",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Email",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Phone",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
      ],
      escalationThree: [
        {
          type: "Name",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Designation",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Email",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
        {
          type: "Phone",
          WHSOperationsResponsible: "",
          WHSCustomerRelations: "",
          WHSSalesResponsible: "",
          locationOperator: "",
          WHSCustomer: "",
        },
      ],
    },
  });

  useEffect(() => {
    if (onData.formDetail?.contactDetail?.contactAndEscalations) {
      setContactJson({
        contactAndEscaltions:
          onData.formDetail?.contactDetail?.contactAndEscalations,
      });
    }

    // if (formType === "contactDetail" && onData.userOnboardDetail?.contactDetail && onData.userOnboardDetail?.contactDetail?.contactAndEscalations) {
    //   setContactJson({
    //     contactAndEscaltions: onData.userOnboardDetail?.contactDetail?.contactAndEscalations
    //   })
    // }

    // if (onData.userOnboardDetail?.contactDetail && onData.userOnboardDetail?.contactDetail?.contactAndEscalations) {
    //   setContactJson({
    //     contactAndEscaltions: onData.userOnboardDetail?.contactDetail?.contactAndEscalations
    //   })
    // }

    // return (() => {
    //   dispatch(formDetails(null))
    // })
  }, [
    dispatch,
    onData.formDetail?.contactDetail,
    onData.userOnboardDetail,
    formType,
  ]);

  useEffect(() => {
    if (formType === "contactDetail") {
      dispatch(getSubUserAssignOnboardDetailById("contactDetail", onBoardId));
    } else {
      dispatch(
        getCustomerOnboardForm(assignUserId, "contactDetail", onBoardId)
      );
    }

    return () => {
      dispatch(userOnboardDetail(null));
      dispatch(formDetails(null));
    };
  }, [dispatch, onBoardId, formType, assignUserId]);

  const redirect = () => {
    dispatch(responseOnboard(null));
    history.goBack();
  };

  return (
    <LayoutOne>
      {" "}
      {onData.onResponse && onData.onResponse.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={onData.onResponse.message} />
      ) : null}
      <div className="">
        <div className="row align-items-center pb-3 my-3 mx-0">
          <div className="col-12">
            <span onClick={() => history.goBack()}>
              {" "}
              <h5 className="text-dark-blue cursorPointer">
                {" "}
                <i className="fas fa-chevron-left mr-3"> </i> Contact Details
              </h5>{" "}
            </span>{" "}
          </div>{" "}
          <CustomerCommonForm buttonHide={false} isAdmin={isAdmin} />
          {/* <div className="col-12 px-0">*/}{" "}
          <div className="row align-items-center px-3 mx-0 form-control-height">
            <div className="col-12 bg-white">
              <h5 className="text-dark"> Contact & Escalation </h5>{" "}
            </div>
            <div className="col-12">
              <Formik
                enableReinitialize={true}
                // validationSchema={schema}
                initialValues={contactJson}
                onSubmit={(fields) => {
                  if (contactDetailOnboardId) {
                    dispatch(
                      updateUserForm(
                        contactDetailOnboardId,
                        "contactDetail",
                        fields
                      )
                    );
                  }
                }}
                render={({
                  values,
                  setFieldValue,
                  errors,
                  status,
                  onChange,
                  touched,
                }) => {
                  return (
                    <Form>
                      <>
                        <div className="row">
                          <div className="col-12 px-0 table-responsive table-gray-admin">
                            <table className="table text-left">
                              <tbody>
                                <tr>
                                  <td className="text-deep-blue small py-2 w-200px">
                                    {" "}
                                    Level{" "}
                                  </td>{" "}
                                  <td className="text-deep-blue small py-2">
                                    {" "}
                                    WHS Operations Responsible{" "}
                                  </td>{" "}
                                  <td className="text-deep-blue small py-2">
                                    {" "}
                                    WHS Customer Relations{" "}
                                  </td>{" "}
                                  <td className="text-deep-blue small py-2">
                                    {" "}
                                    WHSSalesResponsible{" "}
                                  </td>{" "}
                                  <td className="text-deep-blue small py-2">
                                    {" "}
                                    locationOperator{" "}
                                  </td>{" "}
                                  <td className="text-deep-blue small py-2">
                                    {" "}
                                    WHS Customer{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    To be filled in by{" "}
                                  </td>{" "}
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Sales Support{" "}
                                  </td>{" "}
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Sales Support{" "}
                                  </td>{" "}
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Sales{" "}
                                  </td>{" "}
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Sales Support{" "}
                                  </td>{" "}
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Sales / Sales Support{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td
                                    className="font-weight-bold py-2"
                                    colSpan="6"
                                  >
                                    <button className="btn btn-block bg-dark-green text-deep-blue rounded-0 text-white text-left px-3 py-2">
                                      {" "}
                                      Operational Contact(Daily){" "}
                                    </button>{" "}
                                  </td>{" "}
                                </tr>
                                {values.contactAndEscaltions
                                  ?.operationalContact &&
                                  values.contactAndEscaltions
                                    ?.operationalContact?.length > 0 &&
                                  values.contactAndEscaltions?.operationalContact.map(
                                    (ins, index) => {
                                      // const insErrors = (errors.transition?.length && errors.transition[index]) || {};
                                      // const insTouched = (touched.transition?.length && touched.transition[index]) || {};

                                      return (
                                        <tr>
                                          <td className="font-weight-bold px-5">
                                            {" "}
                                            {ins.type}{" "}
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.operationalContact.${index}.WHSOperationsResponsible`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.operationalContact.${index}.WHSCustomerRelations`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.operationalContact.${index}.WHSSalesResponsible`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.operationalContact.${index}.Location Operato`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.operationalContact.${index}.WHSCustomer`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                        </tr>
                                      );
                                    }
                                  )}
                                <tr>
                                  <td
                                    className="font-weight-bold py-2"
                                    colSpan="6"
                                  >
                                    <button className="btn btn-block bg-dark-green text-deep-blue rounded-0 text-white text-left px-3 py-2">
                                      {" "}
                                      Escalation-1{" "}
                                    </button>{" "}
                                  </td>{" "}
                                </tr>
                                {values.contactAndEscaltions?.escalationOne &&
                                  values.contactAndEscaltions?.escalationOne
                                    ?.length > 0 &&
                                  values.contactAndEscaltions?.escalationOne.map(
                                    (ins, index) => {
                                      // const insErrors = (errors.transition?.length && errors.transition[index]) || {};
                                      // const insTouched = (touched.transition?.length && touched.transition[index]) || {};

                                      return (
                                        <tr>
                                          <td className="font-weight-bold px-5">
                                            {" "}
                                            {ins.type}{" "}
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationOne.${index}.WHSOperationsResponsible`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationOne.${index}.WHSCustomerRelations`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationOne.${index}.WHSSalesResponsible`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationOne.${index}.Location Operato`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationOne.${index}.WHSCustomer`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                        </tr>
                                      );
                                    }
                                  )}
                                {/* <tr> 
                                                      <td className="font-weight-bold px-5">Name</td>
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td> 
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td>
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td>
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td>
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td>
                                                    </tr>  */}{" "}
                                {/* <tr> 
                                                      <td className="font-weight-bold px-5">Designation</td>
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td> 
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td>
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td>
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td>
                                                      <td>
                                                        <input type="text" className="form-control bg-white border-0" name=""/>
                                                      </td>
                                                    </tr>  */}
                                <tr>
                                  <td
                                    className="font-weight-bold py-2"
                                    colSpan="6"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-block bg-dark-green text-deep-blue rounded-0 text-white text-left px-3 py-2"
                                    >
                                      {" "}
                                      Escalation-2{" "}
                                    </button>{" "}
                                  </td>{" "}
                                </tr>
                                {values.contactAndEscaltions?.escalationTwo &&
                                  values.contactAndEscaltions?.escalationTwo
                                    ?.length > 0 &&
                                  values.contactAndEscaltions?.escalationTwo.map(
                                    (ins, index) => {
                                      // const insErrors = (errors.transition?.length && errors.transition[index]) || {};
                                      // const insTouched = (touched.transition?.length && touched.transition[index]) || {};

                                      return (
                                        <tr>
                                          <td className="font-weight-bold px-5">
                                            {" "}
                                            {ins.type}{" "}
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationTwo.${index}.WHSOperationsResponsible`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationTwo.${index}.WHSCustomerRelations`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationTwo.${index}.WHSSalesResponsible`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationTwo.${index}.Location Operato`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationTwo.${index}.WHSCustomer`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                        </tr>
                                      );
                                    }
                                  )}
                                <tr>
                                  <td
                                    className="font-weight-bold py-2"
                                    colSpan="6"
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-block bg-dark-green text-deep-blue rounded-0 text-white text-left px-3 py-2"
                                    >
                                      {" "}
                                      Escalation-3{" "}
                                    </button>{" "}
                                  </td>{" "}
                                </tr>
                                {values.contactAndEscaltions?.escalationThree &&
                                  values.contactAndEscaltions?.escalationThree
                                    ?.length > 0 &&
                                  values.contactAndEscaltions?.escalationThree.map(
                                    (ins, index) => {
                                      // const insErrors = (errors.transition?.length && errors.transition[index]) || {};
                                      // const insTouched = (touched.transition?.length && touched.transition[index]) || {};

                                      return (
                                        <tr>
                                          <td className="font-weight-bold px-5">
                                            {" "}
                                            {ins.type}{" "}
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationThree.${index}.WHSOperationsResponsible`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationThree.${index}.WHSCustomerRelations`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationThree.${index}.WHSSalesResponsible`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationThree.${index}.Location Operato`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`contactAndEscaltions.escalationThree.${index}.WHSCustomer`}
                                              type="text"
                                              className="form-control bg-white border-0"
                                            />
                                          </td>{" "}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>{" "}
                            </table>{" "}
                          </div>{" "}
                        </div>
                        <div className="row">
                          <div className="col-12 py-5 px-5">
                            <button
                              disabled={onData.isPending}
                              type="submit"
                              className="btn btn-deep-blue px-5"
                            >
                              Save{" "}
                              {onData.isPending ? (
                                <Spinner animation="border" />
                              ) : null}{" "}
                            </button>{" "}
                          </div>{" "}
                        </div>{" "}
                      </>
                    </Form>
                  );
                }}
              />
            </div>{" "}
            {/* </div>*/}{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default CustomerContactDetails;
