import React, { useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const EditFilter = () => {
  const history = useHistory();
  const [activeButton, setActiveButton] = useState(true);

  const filterSchema = Yup.object().shape({
    filterName: Yup.string().required("Required"),
    filterKey: Yup.string().required("Required"),
    filterType: Yup.string().required("Required"),
  });

  console.log("activeButton==>", activeButton);

  return (
    <LayoutOne>
      <div class="row align-items-center py-3">
        <div class="col-12 py-3 mt-4">
          <span onClick={() => history.goBack()}>
            {" "}
            <h5 class="text-dark-blue cursorPointer">
              {" "}
              <i class="fas fa-chevron-left mr-3"> </i> Edit Filter
            </h5>{" "}
          </span>{" "}
        </div>{" "}
      </div>{" "}
      <div class="row align-items-center pb-3 px-3 mx-0">
        <div class="col-12">
          <Formik
            enableReinitialize={true}
            validationSchema={filterSchema}
            initialValues={{
              filterName: "",
              filterKey: "",
              filterType: "",
              active: false,
            }}
            onSubmit={(fields) => {
              console.log("Fields==>", fields);
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <div class="row">
                    <div class="form-group mb-4  col-md-4 col-sm-6">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Filter Name{" "}
                      </label>{" "}
                      <Field
                        name="filterName"
                        type="text"
                        class={
                          `form-control form-control-lg px-4 rounded-pill` +
                          (errors.filterName && touched.filterName
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`filterName`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div class="form-group mb-4  col-md-4 col-sm-6">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Filter Key{" "}
                      </label>{" "}
                      <Field
                        name="filterKey"
                        type="text"
                        class={
                          `form-control form-control-lg px-4 rounded-pill` +
                          (errors.filterKey && touched.filterKey
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`filterKey`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div class="form-group mb-4  col-md-4 col-sm-6">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Status{" "}
                      </label>{" "}
                      <Field
                        name="filterType"
                        as="select"
                        class={
                          `form-control form-control-lg custom-select bg-white px-4 common-select-deep-blue rounded-pill` +
                          (errors.filterType && touched.filterType
                            ? " is-invalid"
                            : "")
                        }
                        id="exampleFormControlSelect1"
                      >
                        <option> Select </option>{" "}
                        <option value="Textbox"> Textbox </option>{" "}
                        <option value="Checklist"> Checklist </option>{" "}
                        <option value="yesno"> Yes / No </option>{" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`filterType`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div class="form-group col-lg-12 pl-4">
                      <div class="common-checkbox common-checkbox-dark position-relative mx-auto d-inline-block">
                        <Field
                          name="active"
                          class="common-checkbox-input common-checkbox-dark-input"
                          type="checkbox"
                          onChange={(e) => {
                            setFieldValue("active", !values.active);
                          }}
                          checked={values.isDepartmentHead}
                          id="active"
                        />
                        <label
                          class="common-checkbox-label common-checkbox-dark-label mr-3 pl-4"
                          for="active"
                        >
                          {" "}
                          Active{" "}
                        </label>{" "}
                      </div>{" "}
                    </div>
                  </div>{" "}
                  <div class="row pt-4">
                    <div class="col-auto">
                      <button
                        type="button"
                        onClick={() => setActiveButton(false)}
                        class="btn btn-deep-blue"
                      >
                        {" "}
                        Validate{" "}
                      </button>{" "}
                    </div>
                    <div class="col-auto">
                      <button
                        type="submit"
                        disabled={activeButton}
                        class="btn btn-deep-blue"
                      >
                        {" "}
                        Add Filter{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default EditFilter;
