import React from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  onlyLetterAllow,
  onlyNumberAllow,
  onlyAlphaNumericAllow,
  onlyLetterAllowSpace,
} from "../../components/helper/reuse";
import { createUser, responseUser } from "../../store/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";

const AddNewVendor = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.USER_INFO);

  const userSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phone: Yup.string()
      .required("Required")
      .max(10, "Must be 10 digits")
      .min(10, "Must be 10 digits"),
    email: Yup.string().email("Email is invalid").required("Required"),
    password: Yup.string()
      .required("Please Enter your password")
      .min(6, "Password is greater then 6 character"),
    confirmedPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    companyName: Yup.string().required("Required"),
    userType: Yup.string().required("Required"),
  });

  const redirect = () => {
    dispatch(responseUser(null));
    history.replace("/vendor?page=1");
  };

  return (
    <LayoutOne>
      {data.userResponse && data.userResponse.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={data.userResponse.message} />
      ) : null}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-3 mt-1">
          <span onClick={() => history.goBack()}>
            <h5 className="text-dark backButton">
              {" "}
              <i className="fas fa-chevron-left mr-1 cursorPointer "> </i> Add
              New Vendor
            </h5>
          </span>{" "}
        </div>
        <div className="col-12 bg-white py-3">
          <Formik
            enableReinitialize={true}
            validationSchema={userSchema}
            initialValues={{
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              password: "",
              confirmedPassword: "",
              companyName: "",
              countryCode: "+91",
              referredCode: "",
              userType: "individual",
              roleId: 3,
            }}
            onSubmit={(fields) => {
              console.log("Fields==>", fields);
              dispatch(createUser(fields));
            }}
            render={({ values, errors, status, onChange, touched }) => {
              return (
                <Form>
                  <div className="row px-4 rounded mx-0 col-md-11">
                    <div className="form-group mb-2  col-md-6 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-1 pl-3">
                        {" "}
                        First Name{" "}
                      </label>{" "}
                      <Field
                        name={`firstName`}
                        type="text"
                        className={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.firstName && touched.firstName
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                        onKeyPress={(e) => onlyLetterAllow(e)}
                      />{" "}
                      <ErrorMessage
                        name={`firstName`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-2  col-md-6 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-1 pl-3">
                        {" "}
                        Last Name{" "}
                      </label>{" "}
                      <Field
                        name={`lastName`}
                        type="text"
                        className={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.lastName && touched.lastName
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                        onKeyPress={(e) => onlyLetterAllow(e)}
                      />{" "}
                      <ErrorMessage
                        name={`lastName`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-2  col-md-6 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-1 pl-3">
                        {" "}
                        Contact Number{" "}
                      </label>{" "}
                      <Field
                        name={`phone`}
                        type="text"
                        className={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.phone && touched.phone ? " is-invalid" : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                        onKeyPress={(e) => onlyNumberAllow(e)}
                      />{" "}
                      <ErrorMessage
                        name={`phone`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-2  col-md-6 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-1 pl-3">
                        {" "}
                        Email{" "}
                      </label>{" "}
                      <Field
                        name={`email`}
                        type="text"
                        className={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`email`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-2  col-md-6 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-1 pl-3">
                        {" "}
                        Password{" "}
                      </label>{" "}
                      <Field
                        name={`password`}
                        type="text"
                        className={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`password`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-2  col-md-6 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-1 pl-3">
                        {" "}
                        Confirm Password{" "}
                      </label>{" "}
                      <Field
                        name={`confirmedPassword`}
                        type="text"
                        className={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.confirmedPassword && touched.confirmedPassword
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`confirmedPassword`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-2  col-md-6 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-1 pl-3">
                        {" "}
                        Company Name{" "}
                      </label>{" "}
                      <Field
                        name={`companyName`}
                        onKeyPress={(e) => onlyLetterAllowSpace(e)}
                        type="text"
                        className={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.companyName && touched.companyName
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`companyName`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-2  col-md-6 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-1 pl-3">
                        {" "}
                        Reference Code( if any){" "}
                      </label>{" "}
                      <Field
                        name={`referredCode`}
                        onKeyPress={(e) => onlyAlphaNumericAllow(e)}
                        type="text"
                        className={`form-control form-control-md px-4 rounded-pill`}
                        id="staticEmail"
                        placeholder="Type here"
                      />
                    </div>
                    <div className="col-12 form-group mb-3 px-2">
                      <p className="mb-1 text-gray"> Type </p>{" "}
                      <div className="row">
                        <div className="col-sm-auto col-md-4">
                          <div className="form-check common-radio-deep-blue-inline">
                            <Field
                              name="userType"
                              value="individual"
                              className={`common-radio-deep-blue-input`}
                              type="radio"
                              id="spaceprovidertype1"
                              hidden
                            />
                            <label
                              className={
                                `common-radio-deep-blue-label text-uppercase pl-2` +
                                (errors.userType && touched.userType
                                  ? " is-invalid"
                                  : "")
                              }
                              htmlFor="spaceprovidertype1"
                            >
                              {" "}
                              Individual{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-sm-auto col-md-4">
                          <div className="form-check common-radio-deep-blue-inline">
                            <Field
                              name="userType"
                              value="consultant"
                              className="common-radio-deep-blue-input"
                              type="radio"
                              id="spaceprovidertype2"
                              hidden
                            />
                            <label
                              className="common-radio-deep-blue-label text-uppercase pl-2"
                              htmlFor="spaceprovidertype2"
                            >
                              {" "}
                              consultant{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-sm-auto col-md-4">
                          <div className="form-check common-radio-deep-blue-inline">
                            <Field
                              name="userType"
                              value="organisation"
                              className="common-radio-deep-blue-input"
                              type="radio"
                              id="spaceprovidertype3"
                              hidden
                            />
                            <label
                              className="common-radio-deep-blue-label text-uppercase pl-2"
                              htmlFor="spaceprovidertype3"
                            >
                              {" "}
                              Organisation{" "}
                            </label>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                      <ErrorMessage
                        name={`userType`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="col-12 px-3">
                      <button
                        disabled={data.isPending}
                        type="submit"
                        className="btn btn-deep-blue py-2"
                      >
                        Create Account{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default AddNewVendor;
