import {
    REPORT,
    REPORT_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

import {
    GET_REPORT_URL,
    GET_DAILY_REPORT_URL
} from '../../services/urls';



import axiosauth from '../../services/axios-auth';


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}


export function reportList(data) {
    return {
        type: REPORT,
        payload: data
    }
}



export function responseReport(data) {
    return {
        type: REPORT_RESPONSE,
        payload: data
    }
}


// ###########  Fecth All reportList ########

export const reportByPage = () => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_REPORT_URL).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(reportList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("Report Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  Fecth All reportList ########

export const dailyReportByPage = () => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_DAILY_REPORT_URL).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(reportList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("Report Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}



// ###########  Fecth WHS reportList ########

export const reportWhsByPage = () => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(`/api/v1/whslistingreport?page=1&limit=10`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(reportList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("Report Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}