import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import axiosauth from "../../services/axios-auth";
import { useDispatch, useSelector } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  warehouseByIdSpace,
  WarehouseById,
  fetchWarehouseSpace,
} from "../../store/action/warehouseAction";
import FormSuccess from "../../components/common/FormSuccess";
import ClickButton from "../../components/common/ClickButton";

const ManageSpace = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.WAREHOUSEINFO);
  const [reduceArea, setReduceArea] = useState(null);
  const [updateRes, setUpdateRes] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    return () => {
      dispatch(WarehouseById([]));
    };
  }, [dispatch]);

  const [value, setValue] = useState(null);
  const [userCount, setUserCount] = useState(0);

  async function loadOptions(search, loadedOptions, { page }) {
    if (loadedOptions.length === 0 || loadedOptions.length < userCount) {
      // dispatch(getAllActiveVendor(page, search))
      let newArray = [];

      await axiosauth
        .get(
          `/api/v1/user/spacemanagement/vendor/all?page=${page}&limit=10&search=${search}`
        )
        .then((response) => {
          let res = JSON.parse(response.data);
          setUserCount(res.totalCount);
          if (res.data && res.data.length > 0) {
            for (let k = 0; k < res.data.length; k++) {
              newArray.push({
                label: res.data[k].firstName + ", " + res.data[k].lastName,
                value: res.data[k].id,
              });
            }
          }
        })
        .catch((error) => {})
        .then(() => {});

      return {
        options: newArray,
        hasMore: newArray.length > 0 ? true : false,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  const updateSpace = () => {
    if (reduceArea === null) {
      setError("New Space Required");
      return 0;
    }

    if (
      parseInt(reduceArea) >
      parseInt(items?.warehouseDetail?.storageSpaceInfo?.totalAvailableSpace)
    ) {
      setError("New Space <avalable space");
      return 0;
    }

    setError(null);

    let obj = {
      totalAvailableSpace: parseInt(reduceArea),
    };

    try {
      axiosauth
        .put(
          `/api/v1/storagespace/storagespace/${items?.warehouseDetail?.storageSpaceInfo?.id}`,
          obj
        )
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setUpdateRes(true);
            dispatch(warehouseByIdSpace(items?.warehouseDetail?.id));
          }
        })
        .catch((error) => {})
        .then(() => {});
    } catch (e) {}
  };

  // Select option with pagination for warehouse
  const [warehouseValue, setWarehouseValue] = useState(null);

  async function loadWarehouseOptions(search, loadWarehouseOptions, { page }) {
    if (
      loadWarehouseOptions.length === 0 ||
      loadWarehouseOptions.length < items.listOfWarehouse?.totalCount
    ) {
      dispatch(
        fetchWarehouseSpace(page, {
          filter: {
            user: value?.value,
            warehouseName: "",
          },
        })
      );
      let newArray = [];
      for (let i = 0; i < items.listOfWarehouse?.data?.length; i++) {
        newArray.push({
          label:
            items.listOfWarehouse?.data[i].id +
            ", " +
            items.listOfWarehouse?.data[i].warehouseName,
          value: items.listOfWarehouse?.data[i].id,
        });
      }

      return {
        options: newArray,
        hasMore: newArray.length > 0 ? true : false,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Manage Space </title>{" "}
      </Helmet>{" "}
      {updateRes ? (
        <FormSuccess
          onClick={() => setUpdateRes(false)}
          message={`Space Updated`}
        />
      ) : null}
      <div className="row align-items-center pt-2 px-3 mx-0">
        <div className="row align-items-center my-2 mx-0 w-100">
          <div className="col-12 px-0">
            <h5 className="text-dark mb-3"> Space Management </h5>{" "}
          </div>
          <div className="col-12 bg-white p-3">
            <div className="row">
              <div className="form-group col-lg-3 col-md-6">
                <label htmlFor="staticEmail" className="mb-2 pl-3">
                  {" "}
                  List Of Vendor{" "}
                </label>{" "}
                {/* <select className="form-control form-control-lg custom-select bg-white px-4 common-select-deep-blue" id="exampleFormControlSelect1">
                                  <option>Select</option>
                                  <option>Executive</option>
                                  <option>Sales Manager</option>
                                </select> */}
                <AsyncPaginate
                  value={value}
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setValue(e);
                    dispatch(
                      fetchWarehouseSpace(1, {
                        filter: {
                          user: e.value,
                          warehouseName: "",
                        },
                      })
                    );
                  }}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: state.isFocused && "1px solid #ced4da",
                    }),
                  }}
                />
              </div>
              <div className="form-group col-lg-3 col-md-6 mb-2">
                <label htmlFor="staticEmail" className="mb-2 pl-3">
                  {" "}
                  List Of Warehouse{" "}
                </label>{" "}
                {/* <select className="form-control form-control-lg custom-select bg-white px-4 common-select-deep-blue" id="exampleFormControlSelect1">
                                  <option>Select</option>
                                  <option>Executive</option>
                                  <option>Sales Manager</option>
                                </select> */}
                <AsyncPaginate
                  value={warehouseValue}
                  cacheUniqs={[value]}
                  loadOptions={loadWarehouseOptions}
                  onChange={(e) => {
                    setWarehouseValue(e);
                    dispatch(warehouseByIdSpace(e.value));
                    // setFieldValue("warehouse", parseInt(e.value))
                  }}
                  isDisabled={value === null}
                  additional={{
                    page: 1,
                  }}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      boxShadow: "none",
                      border: state.isFocused && "1px solid #ced4da",
                    }),
                  }}
                />
              </div>{" "}
              <div className="form-group col-lg-3 col-md-6 mb-2">
                <label htmlFor="staticEmail" className="mb-2 pl-3">
                  {" "}
                  Available Space{" "}
                </label>{" "}
                <input
                  value={
                    items?.warehouseDetail?.storageSpaceInfo
                      ?.totalAvailableSpace
                  }
                  type="number"
                  className="form-control form-control-md bg-white px-4 common-select-deep-blue"
                  id="exampleFormControlSelect1"
                  disabled
                />
              </div>
              <div className="form-group col-lg-3 col-md-6 mb-2">
                <label htmlFor="staticEmail" className="mb-2 pl-3">
                  {" "}
                  Allocate Space{" "}
                </label>{" "}
                <input
                  value={reduceArea}
                  onChange={(e) => setReduceArea(e.target.value)}
                  type="number"
                  className="form-control form-control-md bg-white px-4 common-select-deep-blue"
                  id="exampleFormControlSelect1"
                  disabled={warehouseValue === null}
                />{" "}
                <span className="errorMsg"> {error} </span>{" "}
              </div>{" "}
            </div>{" "}
            <div className="row">
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-outline-deep-blue toggle-class my-4 py-2"
                >
                  {" "}
                  Back{" "}
                </button>{" "}
              </div>{" "}
              <div className="col-auto">
                <ClickButton onClick={updateSpace} title={`Update`} />{" "}
                {/* <button  type="button" className="btn btn-deep-blue toggle-class my-4">Update</button> */}{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      </div>{" "}
    </LayoutOne>
  );
};

export default ManageSpace;
