import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerDetailCall,
  assignOnBoard,
  onBoardCustomer,
} from "../../store/action/onBoardAction";
import { useToasts } from "react-toast-notifications";

const CustomerCommonForm = ({ buttonHide, isAdmin }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const contactDetailOnboardId = new URLSearchParams(
    window.location.search
  ).get("contactDetailOnboardId");
  const onData = useSelector((state) => state.ON_BOARD_INFO);

  const [contactJson, setContactJson] = useState({
    customerContactDetail: {
      customerName: "",
      customerId: "",
      operatorsOrg: "",
      location: "",
      customerAgreementNo: "",
      agreementStartDate: "",
      salesPerson: "",
      salesOrg: "",
      customerServiceAgent: "",
      kam: "",
      whOperationsKpc: "",
      nextRevisionDate: "",
    },
  });

  useEffect(() => {
    if (onData.onBoardCustomer?.customerContactDetail === null) {
      if (onData.onBoardCustomer?.booking?.customer) {
        setContactJson({
          customerContactDetail: {
            customerName: onData.onBoardCustomer?.booking?.customer?.firstName,
            customerId: onData.onBoardCustomer?.booking?.customer?.id,
            operatorsOrg: "",
            location: "",
            customerAgreementNo: "",
            agreementStartDate: "",
            salesPerson: "",
            salesOrg: "",
            customerServiceAgent: "",
            kam: "",
            whOperationsKpc: "",
            nextRevisionDate: "",
          },
        });
      }
    } else {
      setContactJson({
        customerContactDetail: onData.onBoardCustomer?.customerContactDetail,
      });
    }
  }, [
    onData.onBoardCustomer?.booking?.customer,
    onData.onBoardCustomer?.customerContactDetail,
  ]);

  useEffect(() => {
    dispatch(getCustomerDetailCall(parseInt(contactDetailOnboardId)));
    return () => {
      dispatch(onBoardCustomer(null));
    };
  }, [dispatch, contactDetailOnboardId]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        // validationSchema={schema}
        initialValues={contactJson}
        onSubmit={(fields) => {
          if (contactDetailOnboardId) {
            fields["customeronboardingId"] = parseInt(contactDetailOnboardId);
            fields["formType"] = "customerContactDetail";
            dispatch(assignOnBoard(fields, addToast));
          }
        }}
        render={({
          values,
          setFieldValue,
          errors,
          status,
          onChange,
          touched,
        }) => {
          return (
            <Form>
              {" "}
              {/* <div className="row align-items-center pb-3 mx-0 form-control-height"> */}{" "}
              <div className="col-12">
                <div className="row bg-white py-3 rounded mx-0">
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Customer Name{" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.customerName"
                        type="text"
                        id="spaceproviderfirstname"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Ravish Kumar"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Customer ID{" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.customerId"
                        type="text"
                        id="spaceproviderfirstname1"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="JD-85975HG"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Operators Org(With ID){" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.operatorsOrg"
                        type="text"
                        id="spaceproviderfirstname2"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Location(With Warehouse ID){" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.location"
                        type="text"
                        id="spaceproviderfirstname3"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Customer Agreement No{" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.customerAgreementNo"
                        type="text"
                        id="spaceproviderfirstname4"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Agreement Start Date{" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.agreementStartDate"
                        type="date"
                        id="spaceproviderfirstname5"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Sales Person(Warehousity){" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.salesPerson"
                        type="text"
                        id="spaceproviderfirstname6"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Sales Org(With ID){" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.salesOrg"
                        type="text"
                        id="spaceproviderfirstname7"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Customer Service Agent{" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.customerServiceAgent"
                        type="text"
                        id="spaceproviderfirstname8"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      KAM(Warehousity){" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.kam"
                        type="text"
                        id="spaceproviderfirstname9"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      WH Operations KPC{" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.whOperationsKpc"
                        type="text"
                        id="spaceproviderfirstname10"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl-4 col-md-6 form-group mb-2">
                    <label htmlFor="" className="px-2">
                      {" "}
                      Next Revision Date{" "}
                    </label>{" "}
                    <div className="input-group">
                      <Field
                        name="customerContactDetail.nextRevisionDate"
                        type="date"
                        id="spaceproviderfirstname11"
                        className="form-control px-3 bg-none rounded-pill"
                        placeholder="Type here"
                      />
                    </div>{" "}
                  </div>{" "}
                </div>
              </div>{" "}
              {/* </div> */} {/* <div className={`row `}> */}{" "}
              <div
                className={`col-12 px-5 text-right ${
                  buttonHide || !isAdmin === "true" ? "d-none" : null
                }`}
              >
                <button type="submit" className="btn btn-deep-blue py-2">
                  {" "}
                  Save{" "}
                </button>{" "}
              </div>{" "}
              {/* </div> */}{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default CustomerCommonForm;
