import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";

const AssignedAgentList = ({ item, index }) => {
  const { id, warehouseName, category, created_at, adminStatus } = item;

  return (
    <tr>
      <td className="text-center py-2"> {index} </td>{" "}
      <td> {readableDate(created_at)} </td>{" "}
      <td> {item.warehouseId ? item.warehouseId : "-"} </td>{" "}
      <td> {warehouseName} </td> <td> {category?.categoryName} </td>{" "}
      <td> {adminStatus} </td>{" "}
      {adminStatus === "Rejected By FA" ? (
        <td className="text-center">
          <Link
            to={`/assign-to-field-agent-rejected/${id}`}
            className="btn p-1 text-nowrap font-weight-bold text-deep-blue text-uppercase"
          >
            View Details{" "}
          </Link>{" "}
        </td>
      ) : (
        <td className="text-center">
          <Link to={`/assign-to-field-agent/${id}`}>
            <i className="fa fa-eye"> </i>{" "}
          </Link>{" "}
        </td>
      )}{" "}
    </tr>
  );
};

export default AssignedAgentList;
