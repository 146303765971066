import React, { useEffect, useState } from 'react';
import LayoutOne from '../../layout/LayoutOne';
import { Helmet } from 'react-helmet';
import SearchBox from '../../components/common/SearchBox';
import WarehousePendingList from '../../wrapper/warehouseManagement/WarehousePendingList';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWarehouseByPage } from '../../store/action/warehouseAction';
import { useHistory } from 'react-router-dom';
import { ItemNotFoud } from '../../components/common/CustomLoader';

const PendingWarehouse = () => {
  const [searchHandler, setSearchHandler] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const pageCount = new URLSearchParams(window.location.search).get('page');

  useEffect(() => {
    dispatch(fetchWarehouseByPage(parseInt(pageCount), '', ['Pending_Approval', 'Listed_and_Pending_Verification']));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());
    dispatch(fetchWarehouseByPage(pageNumber, searchHandler, ['Pending_Approval', 'Listed_and_Pending_Verification']));
  };

  const callSearch = () => {
    dispatch(fetchWarehouseByPage(pageCount, searchHandler, ''));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet='utf-8' />
        <title> Pending Warehouse </title>{' '}
      </Helmet>
      <div className='row align-items-center mx-0 px-3'>
        <div className='col-12 col-sm-6 col-md-6 col-lg-6 px-0 d-flex justify-content-between'>
          <h5 className='text-dark'>
            {' '}
            <i onClick={() => history.goBack()} className='fas fa-chevron-left mr-3 cursorPointer'>
              {' '}
            </i>
            Pending Warehosue{' '}
          </h5>
        </div>{' '}
        <div className='col-12 col-sm-6 col-md-6 col-lg-6 px-0'>
          <SearchBox callSearch={callSearch} setSearchHandler={setSearchHandler} border={true} />{' '}
        </div>
        <div className='col-12 text-nowrap table-responsive table-gray-admin bg-white p-3'>
          {/* {'HELO ABCD TRIGGER 123'} */}
          {data?.listOfWarehouse?.data?.warehouses !== undefined && data?.listOfWarehouse?.data?.warehouses !== null &&
            data?.listOfWarehouse?.data?.warehouses.length > 0 ? (

            < table className='table table-dark-custom'>
              <thead>
                <tr>
                  <th className='text-center'> S.No. </th>{' '}
                  <th className='text-center'> Warehouse Name </th>{' '}
                  <th className='text-center'> Warehouse Category Name</th>{' '}
                  <th className='text-center'> Warehouse Type Name</th> <th> Status </th>{' '}
                  <th className='text-center'> </th>{' '}
                </tr>{' '}
              </thead>{' '}
              <tbody>
                {/* listOfWarehouse */}
                {/* {JSON.stringify(data?.listOfWarehouse)} */}
                {data?.listOfWarehouse?.data?.warehouses.length > 0 && data?.listOfWarehouse?.data?.warehouses.map((item, index) => {
                  return (
                    <>
                      {' '}
                      <WarehousePendingList
                        key={index}
                        item={item}
                        index={(pageCount - 1) * 10 + (index + 1)}
                      />{' '}
                    </>
                  );
                })}
              </tbody>{' '}
            </table>
          ) : (
            <ItemNotFoud message='Data Not Found' />
          )}{' '}
        </div>{' '}
      </div>
      {/* ============ Pagination ============ */} {' '}
      {
        data.listOfWarehouse?.data?.warehouses.totalCount > 10 && (
          <div className='pagination-custom'>
            <Pagination
              activePage={parseInt(pageCount)}
              itemsCountPerPage={10}
              totalItemsCount={data.listOfWarehouse.data.totalCount}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
              prevPageText={<i className='fas fa-chevron-left' />}
              nextPageText={<i className='fas fa-chevron-right' />}
              hideFirstLastPages={true}
            />{' '}
          </div>
        )
      }
    </LayoutOne >
  );
};

export default PendingWarehouse;
