import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";
import { roleById } from "../../store/action/whsUserAction";
import { useDispatch, useSelector } from "react-redux";

const WHSUserRoleDetails = () => {
  const history = useHistory();
  const { roleId } = useParams();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.WHS_USER_INFO);

  console.log("user-->", user.roleDetail);

  let userRole = {
    statusCode: 200,
    data: {
      roleName: "Manager",
      createdOn: "2021-07-03T07:14:22.484Z",
      status: "assigned",
    },
  };

  useEffect(() => {
    dispatch(roleById(roleId));
  }, [dispatch, roleId]);

  return (
    <LayoutOne>
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-2 px-0">
          <h5 className="text-dark">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left cursorPointer"
            ></i>{" "}
            View Details{" "}
          </h5>{" "}
        </div>
        <div className="col-12 bg-white py-2">
          {" "}
          {user.roleDetail ? (
            <div className="row">
              <div className="col-md-12 d-flex py-2">
                <div className="card py-2 px-4 mb-0 border">
                  <p className="mb-2"> Role Name </p>{" "}
                  <p className="text-gray mb-0"> {user.roleDetail?.name} </p>{" "}
                </div>{" "}
              </div>
              <div className="col-md-6 d-flex py-2">
                <div className="card py-2 px-4 mb-0 border">
                  <p className="mb-2"> Created On </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {readableDate(userRole?.data.createdOn)}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-6 d-flex py-2">
                <div className="card py-2 px-4 mb-0 border">
                  <p className="mb-2"> Status </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {userRole?.data.isActive ? "Active" : "in Active"}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          ) : null}{" "}
        </div>{" "}
        <div className="col-auto">
          <button
            onClick={() => history.goBack()}
            className="btn btn-outline-deep-blue my-2"
          >
            Back{" "}
          </button>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default WHSUserRoleDetails;
