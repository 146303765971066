import {
    USER,
    USER_BY_ID,
    ALL_USER_LIST,
    USER_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
    ERROR_MESSAGE
} from '../types'

const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    isErrorMsg: "",
    userList: [],
    allUserList: null,
    userDetail: null,
    userResponse: null
}

export const USER_INFO = (state = initialState, action) => {
    switch (action.type) {
        case USER:
            return {
                ...state,
                userList: action.payload
            }
        case ALL_USER_LIST:
            return {
                ...state,
                allUserList: action.payload
            }

        case USER_BY_ID:
            return {
                ...state,
                userDetail: action.payload
            }

        case USER_RESPONSE:
            return {
                ...state,
                userResponse: action.payload
            }

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        case ERROR_MESSAGE:
            return {
                ...state,
                isErrorMsg: action.payload,
            };

        default:
            return state;
    }
}