import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LayoutOne from "../../layout/LayoutOne";
import axiosauth from "../../services/axios-auth";
import { readableDate } from "../../components/helper/reuse";

const FeatureDetails = () => {
  const { featureId } = useParams();

  const history = useHistory();
  const [data, setData] = useState(null);
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState(null);

  function fetureCallApi() {
    try {
      axiosauth
        .get(`/api/v1/featuredwarehouse/${featureId}`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.data && res.data.length > 0) {
            setData(res.data[0]);
            setComment(res.data[0].commentByAdmin);
          }
        })
        .catch((error) => {
          console.log("rerr-->", error);
        })
        .then(() => {
          console.log("-----always executes");
        });
    } catch (e) {}
  }

  useEffect(() => {
    function fetureCall() {
      try {
        axiosauth
          .get(`/api/v1/featuredwarehouse/${featureId}`)
          .then((response) => {
            let res = JSON.parse(response.data);
            if (res.data && res.data.length > 0) {
              setData(res.data[0]);
              setComment(res.data[0].commentByAdmin);
            }
          })
          .catch((error) => {})
          .then(() => {});
      } catch (e) {}
    }
    fetureCall();
  }, [featureId]);

  const updateFeatured = () => {
    let data = {
      commentByAdmin: comment,
    };

    try {
      axiosauth
        .put(`/api/v1/featuredwarehouse/${featureId}`, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log("res==>", res);
          setMessage("Comment Updated");
          fetureCallApi();
          setTimeout(() => {
            setMessage(null);
          }, 3000);
        })
        .catch((error) => {})
        .then(() => {});
    } catch (e) {}
  };

  return (
    <LayoutOne>
      <div class="row align-items-center pb-3 px-3 mx-0">
        <div class="col-12 py-3">
          <h5 class="text-dark-blue">
            {" "}
            <i
              onClick={() => history.goBack()}
              class="fas fa-chevron-left mr-3 cursorPointer"
            >
              {" "}
            </i>{" "}
            View Reviews Details
          </h5>
        </div>
        <div class="col-12">
          <div class="row mx-0">
            <div class="col-12 bg-white rounded">
              <div class="card card-body border-0">
                <div class="d-flex">
                  <div class="mw-200px px-3 font-weight-bold py-2">
                    {" "}
                    Warehouse Name{" "}
                  </div>{" "}
                  <div class="mw-300px px-3 py-2 text-gray">
                    {" "}
                    {data?.warehouse?.warehouseName}{" "}
                  </div>{" "}
                </div>
                <div class="d-flex">
                  <div class="mw-200px px-3 font-weight-bold py-2">
                    {" "}
                    Start Date{" "}
                  </div>{" "}
                  <div class="mw-300px px-3 py-2 text-gray">
                    {" "}
                    {readableDate(data?.startDate)}{" "}
                  </div>{" "}
                </div>{" "}
                <div class="d-flex">
                  <div class="mw-200px px-3 font-weight-bold py-2">
                    {" "}
                    End Date{" "}
                  </div>{" "}
                  <div class="mw-300px px-3 py-2 text-gray">
                    {" "}
                    {readableDate(data?.endDate)}{" "}
                  </div>{" "}
                </div>{" "}
                <div class="d-flex">
                  <div class="mw-200px px-3 font-weight-bold py-2">
                    {" "}
                    Customer Comment{" "}
                  </div>{" "}
                  <div class="mw-300px px-3 py-2 text-gray">
                    {" "}
                    {data?.commentByCustomer}{" "}
                  </div>{" "}
                </div>{" "}
                <div class="d-flex">
                  <div class="mw-200px px-3 font-weight-bold py-2">
                    {" "}
                    Admin Comment{" "}
                  </div>{" "}
                  <div
                    class="px-3 py-2 text-gray"
                    style={{
                      width: "400px",
                    }}
                  >
                    <textarea
                      style={{
                        width: "400px",
                      }}
                      col="4"
                      onChange={(e) => setComment(e.target.value)}
                      type="text"
                      value={comment}
                    />{" "}
                    <span
                      style={{
                        color: "green",
                      }}
                    >
                      {" "}
                      {message}{" "}
                    </span>{" "}
                  </div>{" "}
                </div>
              </div>{" "}
            </div>{" "}
          </div>
          <div class="row">
            <div class="col-12 my-1">
              <span
                onClick={() => history.goBack()}
                class="btn btn-outline-deep-blue py-2"
              >
                {" "}
                Back{" "}
              </span>{" "}
              <span
                onClick={updateFeatured}
                class="btn btn-outline-deep-blue ml-2 py-2"
              >
                {" "}
                Update Comment{" "}
              </span>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default FeatureDetails;
