import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { reviewById } from "../../store/action/reviewAction";
import { CardLoader } from "../../components/helper/CustomLoader";
import { readableDate } from "../../components/helper/reuse";

const ReviewDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { reviewId } = useParams();
  const data = useSelector((state) => state.REVIEW_INFO);

  useEffect(() => {
    dispatch(reviewById(parseInt(reviewId)));
  }, [dispatch, reviewId]);

  return (
    <LayoutOne>
      <div class="row align-items-center pb-3 px-3 mx-0">
        <div class="col-12 py-2">
          <h5 class="text-dark">
            <i
              onClick={() => history.goBack()}
              class="fas fa-chevron-left mr-2 cursorPointer"
            ></i>{" "}
            View Reviews Details{" "}
          </h5>{" "}
        </div>
        <div class="col-12">
          {" "}
          {data.isLoading ? (
            <CardLoader />
          ) : (
            <div class="row mx-0">
              <div class="col-12 bg-white rounded">
                {" "}
                {data.reviewDetail ? (
                  <div class="card card-body border-0">
                    <div class="d-flex">
                      <div class="mw-200px px-3 font-weight-bold py-2">
                        Name{" "}
                      </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.reviewDetail?.user?.firstName +
                          " " +
                          data.reviewDetail?.user?.lastName}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 font-weight-bold py-2">
                        User Email ID{" "}
                      </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.reviewDetail?.user?.email}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 font-weight-bold py-2">
                        Company Name{" "}
                      </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.reviewDetail?.user?.companyName}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 font-weight-bold py-2">
                        Warehouse Name{" "}
                      </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.reviewDetail?.warehouse?.warehouseName}{" "}
                      </div>{" "}
                    </div>{" "}
                    {/* <div class="d-flex">
                                                  <div class="mw-200px px-3 font-weight-bold py-2">Subject</div>
                                                  <div class="mw-300px px-3 py-2 text-gray">{data.reviewDetail?.subject}</div>
                                                </div> */}{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 font-weight-bold py-2">
                        Review{" "}
                      </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.reviewDetail?.review}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div class="d-flex">
                      <div class="mw-200px px-3 font-weight-bold py-2">
                        Ratings{" "}
                      </div>
                      {data.reviewDetail?.rating === 1 ? (
                        <div class="mw-300px px-3 py-2 text-gray">
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                        </div>
                      ) : null}
                      {data.reviewDetail?.rating === 2 ? (
                        <div class="mw-300px px-3 py-2 text-gray">
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                        </div>
                      ) : null}
                      {data.reviewDetail?.rating === 3 ? (
                        <div class="mw-300px px-3 py-2 text-gray">
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                        </div>
                      ) : null}
                      {data.reviewDetail?.rating === 4 ? (
                        <div class="mw-300px px-3 py-2 text-gray">
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star"> </i>{" "}
                        </div>
                      ) : null}
                      {data.reviewDetail?.rating === 5 ? (
                        <div class="mw-300px px-3 py-2 text-gray">
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                          <i class="fas fa-star text-primary"> </i>{" "}
                        </div>
                      ) : null}{" "}
                    </div>
                    <div class="d-flex">
                      <div class="mw-200px px-3 font-weight-bold py-2">
                        Date{" "}
                      </div>{" "}
                      <div class="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {readableDate(data.reviewDetail?.created_at)}{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                ) : null}{" "}
              </div>{" "}
            </div>
          )}
          <div class="row">
            <div class="col-12 my-2">
              <span
                onClick={() => history.goBack()}
                class="btn py-2 btn-outline-deep-blue"
              >
                Back{" "}
              </span>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default ReviewDetails;
