import React from "react";
import { readableDate } from "../../components/helper/reuse";

const InvoiceCertificate = ({ data, userType }) => {
  return (
    <div className="row p-2" id="pdf-element">
      <div className="col-xl-10 col-lg-11 col-12">
        <div className="space-certficate-holder">
          <div className="space-certificate blue shadow">
            <div className="space-certificate-design top-left"> </div>{" "}
            <div className="space-certificate-design top-right"> </div>{" "}
            <div className="space-certificate-design bottom-right"> </div>{" "}
            <div className="space-certificate-design bottom-left"> </div>{" "}
            <div className="px-2 space-certificate-inner py-5 px-4">
              <div className="inner-border top-left"> </div>{" "}
              <div className="inner-border top-right"> </div>{" "}
              <div className="inner-border bottom-right"> </div>{" "}
              <div className="inner-border bottom-left"> </div>{" "}
              <div className="img-holder w-200px mx-auto mt-3">
                <img src="../assets/images/logo.png" alt="" className="w-100" />
              </div>{" "}
              <div className="px-sm-4">
                <h2 className="text-center mt-4 text-uppercase">
                  {" "}
                  {userType}
                  Invoice{" "}
                </h2>{" "}
                <div className="heading-design">
                  <span className="line left">
                    <span className="dots"> </span>{" "}
                    <span className="dots"> </span>{" "}
                    <span className="dots"> </span>{" "}
                    <span className="dots"> </span>{" "}
                  </span>{" "}
                  <span className="line right">
                    <span className="dots"> </span>{" "}
                    <span className="dots"> </span>{" "}
                    <span className="dots"> </span>{" "}
                    <span className="dots"> </span>{" "}
                  </span>{" "}
                </div>{" "}
                <div className="px-md-4">
                  <h5 className="text-center mt-4">
                    {" "}
                    This is system Generated invoice.{" "}
                  </h5>{" "}
                  <table className="table table-borderless mt-4">
                    <tbody>
                      <tr>
                        <td className="left"> Warehouse ID </td>{" "}
                        <td> {data?.warehouse?.id} </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td className="left"> Invoice Date </td>{" "}
                        <td> {readableDate(data?.invoiceDate)} </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td className="left"> Due Date </td>{" "}
                        <td> {readableDate(data?.dueDate)} </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td className="left"> Mobile No. </td>{" "}
                        <td> {`+91 ${data?.contactNumber}`} </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td className="left"> Email </td>{" "}
                        <td> {data?.email} </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td className="left"> Address </td>{" "}
                        <td> {data?.area} </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td className="left"> Price </td>{" "}
                        <td>
                          {" "}
                          {data?.price}
                          Rupees{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td className="left"> GST </td>{" "}
                        <td>
                          {" "}
                          {data?.gst}
                          Rupees{" "}
                        </td>{" "}
                      </tr>{" "}
                      <tr>
                        <td className="left"> Total Price </td>{" "}
                        <td>
                          {" "}
                          {data?.price + data?.gst}
                          Rupees{" "}
                        </td>{" "}
                      </tr>{" "}
                    </tbody>{" "}
                  </table>{" "}
                </div>
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default InvoiceCertificate;
