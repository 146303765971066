import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  createWarehouseApprovalForm,
  addNewWarehouse,
  // fetchWarehouseByIdAndType
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";

const WarehousePendingForm = ({ warehouseId, viewMood }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const [formField, setFormField] = useState(null);
  const validationSchema = function (values) {
    return Yup.object().shape({
      category: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      type: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      warehouseName: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };
  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        console.log("dsksdk", getErrorsFromValidationError(error));
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };
  const [remarkForm, setRemarkForm] = useState({
    category: {
      value: data.singleFormData ? data.singleFormData.category : "",
      whsstatus: null,
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    type: {
      value: data.singleFormData ? data.singleFormData.type : "",
      whsstatus: null,
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    warehouseName: {
      value: data.singleFormData ? data.singleFormData.warehouseName : "",
      whsstatus: null,
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });

  useEffect(() => {
    if (data.singleFormData != null && data.type === "warehouse") {
      setFormField(data.singleFormData);
      if (data.singleFormData.warehouseRemark) {
        setRemarkForm({
          category: {
            value: data.singleFormData.category,
            whsstatus: data.singleFormData.warehouseRemark.category.whsstatus,
            whsremark: data.singleFormData.warehouseRemark.category.whsremark,
            fastatus: data.singleFormData.warehouseRemark.category.fastatus,
            faremark: data.singleFormData.warehouseRemark.category.faremark,
          },
          type: {
            value: data.singleFormData.type,
            whsstatus: data.singleFormData.warehouseRemark.type.whsstatus,
            whsremark: data.singleFormData.warehouseRemark.type.whsremark,
            fastatus: data.singleFormData.warehouseRemark.type.fastatus,
            faremark: data.singleFormData.warehouseRemark.type.faremark,
          },
          warehouseName: {
            value: data.singleFormData.warehouseName,
            whsstatus:
              data.singleFormData.warehouseRemark.warehouseName.whsstatus,
            whsremark:
              data.singleFormData.warehouseRemark.warehouseName.whsremark,
            fastatus:
              data.singleFormData.warehouseRemark.warehouseName.fastatus,
            faremark:
              data.singleFormData.warehouseRemark.warehouseName.faremark,
          },
        });
      } else {
        setRemarkForm({
          category: {
            value: data.singleFormData.category,
            whsstatus: null,
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          type: {
            value: data.singleFormData.type,
            whsstatus: null,
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          warehouseName: {
            value: data.singleFormData.warehouseName,
            whsstatus: null,
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);
  const onSubmit = (fields) => {
    const payload = {
      data: {
        warehouseRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    console.log("Created call");
    dispatch(createWarehouseApprovalForm(payload, warehouseId));
  };
  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form className="w-100">
              <div className="col-12 rounded-lg">
                <div className="col-xl-10">
                  <table class="table table-borderless mb-0 d-non">
                    <thead>
                      <tr>
                        <th scope="col" class="col-lg-6">
                          {" "}
                        </th>{" "}
                        <th scope="col"> Okay </th>{" "}
                        <th scope="col"> Not okay </th>{" "}
                        <th scope="col" className="text-center">
                          Remark{" "}
                        </th>{" "}
                        <th scope="col" className="text-center">
                          Field Agent Remarks{" "}
                        </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      <tr>
                        <td className="py-0">
                          <table class="table table-borderless form-group col-12 pt-xl-1">
                            <tr>
                              <td className="py-0">
                                <label
                                  htmlFor="staticEmail"
                                  className="mb-2 px-2"
                                >
                                  Warehouse Category:
                                </label>{" "}
                              </td>{" "}
                            </tr>{" "}
                            <tr>
                              <td className="py-0">
                                <input
                                  className="form-control px-4"
                                  value={
                                    formField
                                      ? formField.category.categoryName
                                      : ""
                                  }
                                  disabled
                                />
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td verticalAlign="middle" className="py-0">
                          <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                            <tr>
                              <td
                                className="py-0 form-check mt-4 common-radio-deep-blue"
                                verticalAlign="middle"
                              >
                                <Field
                                  name="category.whsstatus"
                                  className="common-radio-deep-blue-input"
                                  id="categoryOkay"
                                  type="radio"
                                  value="okay"
                                  hidden="true"
                                  required
                                  disabled={viewMood}
                                  checked={values.category.whsstatus === true}
                                  onChange={() =>
                                    setFieldValue("category.whsstatus", true)
                                  }
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor="categoryOkay"
                                ></label>{" "}
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="py-0">
                          <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                            <tr>
                              <td
                                className="py-0 form-check mt-4 common-radio-deep-blue"
                                verticalAlign="middle"
                              >
                                <Field
                                  name="category.whsstatus"
                                  className="common-radio-deep-blue-input"
                                  type="radio"
                                  hidden="true"
                                  id="categoryNotOkay"
                                  value="notOkay"
                                  required
                                  disabled={viewMood}
                                  checked={values.category.whsstatus === false}
                                  onChange={() =>
                                    setFieldValue("category.whsstatus", false)
                                  }
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor="categoryNotOkay"
                                ></label>{" "}
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="pb-0">
                          <table class="table table-borderless form-group col-12 pt-xl-1">
                            <tr>
                              <td className="pb-0 ">
                                <Field
                                  name={`category.remark`}
                                  type="text"
                                  disabled={viewMood}
                                  className="form-control bg-white px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="pb-0 d-flex pt-4">
                          <p className="py-2 text-nowrap pr-4">
                            {" "}
                            {formField
                              ? formField.warehouseRemark
                                ? formField.warehouseRemark.category
                                    .fastatus === true
                                  ? "okay"
                                  : "Not okay"
                                : ""
                              : ""}{" "}
                          </p>{" "}
                          <input
                            value={
                              formField
                                ? formField.warehouseRemark
                                  ? formField.warehouseRemark.category.faremark
                                  : ""
                                : ""
                            }
                            type="text"
                            className="form-control px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </td>{" "}
                      </tr>{" "}
                    </tbody>{" "}
                  </table>{" "}
                </div>
                <div className="col-xl-10">
                  <table class="table table-borderless mb-0 d-non">
                    <thead>
                      <tr>
                        <th scope="col" class="col-lg-6 py-0">
                          {" "}
                        </th>{" "}
                        <th scope="col" className="py-0">
                          {" "}
                        </th>{" "}
                        <th scope="col" className="py-0">
                          {" "}
                        </th>{" "}
                        <th scope="col" className="py-0"></th>{" "}
                        <th scope="col" className="text-center py-0"></th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      <tr>
                        <td className="py-0">
                          <table class="table table-borderless form-group col-12 pt-xl-1">
                            <tr>
                              <td className="py-0">
                                <label
                                  htmlFor="staticEmail"
                                  className="mb-2 px-2"
                                >
                                  Warehouse Type:
                                </label>{" "}
                              </td>{" "}
                            </tr>{" "}
                            <tr>
                              <td className="py-0">
                                <input
                                  className="form-control px-4"
                                  value={formField ? formField.type.type : ""}
                                  disabled
                                />
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td verticalAlign="middle" className="py-0">
                          <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                            <tr>
                              <td
                                className="py-0 form-check mt-4 common-radio-deep-blue"
                                verticalAlign="middle"
                              >
                                <Field
                                  name="type.whsstatus"
                                  className="common-radio-deep-blue-input"
                                  type="radio"
                                  required
                                  disabled={viewMood}
                                  id="typeOkay"
                                  hidden="true"
                                  value="okay"
                                  checked={values.type.whsstatus === true}
                                  onChange={() =>
                                    setFieldValue("type.whsstatus", true)
                                  }
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor="typeOkay"
                                ></label>{" "}
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="py-0">
                          <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                            <tr>
                              <td
                                className="py-0 form-check mt-4 common-radio-deep-blue"
                                verticalAlign="middle"
                              >
                                <Field
                                  name="type.whsstatus"
                                  className="common-radio-deep-blue-input"
                                  type="radio"
                                  hidden="true"
                                  required
                                  disabled={viewMood}
                                  id="typeNotOkay"
                                  value="notOkay"
                                  checked={values.type.whsstatus === false}
                                  onChange={() =>
                                    setFieldValue("type.whsstatus", false)
                                  }
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor="typeNotOkay"
                                ></label>{" "}
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="pb-0">
                          <table class="table table-borderless form-group col-12 ">
                            <tr>
                              <td className="pb-0 ">
                                <Field
                                  name={`type.remark`}
                                  type="text"
                                  disabled={viewMood}
                                  className="form-control bg-white px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="pb-0 d-flex pt-4">
                          <p className="py-2 text-nowrap pr-4">
                            {" "}
                            {formField
                              ? formField.warehouseRemark
                                ? formField.warehouseRemark.category
                                    .fastatus === true
                                  ? "okay"
                                  : "Not okay"
                                : ""
                              : ""}{" "}
                          </p>{" "}
                          <input
                            value={
                              formField
                                ? formField.warehouseRemark
                                  ? formField.warehouseRemark.category.faremark
                                  : ""
                                : ""
                            }
                            type="text"
                            className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </td>{" "}
                      </tr>{" "}
                    </tbody>{" "}
                  </table>{" "}
                </div>
                <div className="col-xl-10">
                  <table class="table table-borderless mb-0 d-non">
                    <thead>
                      <tr>
                        <th scope="col" class="col-lg-6 py-0">
                          {" "}
                        </th>{" "}
                        <th scope="col" className="py-0">
                          {" "}
                        </th>{" "}
                        <th scope="col" className="py-0">
                          {" "}
                        </th>{" "}
                        <th scope="col" className="py-0"></th>{" "}
                        <th scope="col" className="text-center py-0"></th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      <tr>
                        <td className="py-0">
                          <table class="table table-borderless form-group col-12 pt-xl-1">
                            <tr>
                              <td className="py-0">
                                <label
                                  htmlFor="staticEmail"
                                  className="mb-2 px-2"
                                >
                                  Warehouse Display Name:
                                </label>{" "}
                              </td>{" "}
                            </tr>{" "}
                            <tr>
                              <td className="py-0">
                                <input
                                  className="form-control px-4"
                                  value={
                                    formField ? formField.warehouseName : ""
                                  }
                                  disabled
                                />
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td verticalAlign="middle" className="py-0">
                          <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                            <tr>
                              <td
                                className="py-0 form-check mt-4 common-radio-deep-blue"
                                verticalAlign="middle"
                              >
                                <Field
                                  name="warehouseName.whsstatus"
                                  className="common-radio-deep-blue-input"
                                  type="radio"
                                  id="warehouseOkay"
                                  hidden="true"
                                  value="okay"
                                  disabled={viewMood}
                                  required
                                  checked={
                                    values.warehouseName.whsstatus === true
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      "warehouseName.whsstatus",
                                      true
                                    )
                                  }
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor="warehouseOkay"
                                ></label>{" "}
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="py-0">
                          <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                            <tr>
                              <td
                                className="py-0 form-check mt-4 common-radio-deep-blue"
                                verticalAlign="middle"
                              >
                                <Field
                                  name="warehouseName.whsstatus"
                                  className="common-radio-deep-blue-input"
                                  type="radio"
                                  id="warehouseNotOkay"
                                  hidden="true"
                                  value="notOkay"
                                  required
                                  disabled={viewMood}
                                  checked={
                                    values.warehouseName.whsstatus === false
                                  }
                                  onChange={() =>
                                    setFieldValue(
                                      "warehouseName.whsstatus",
                                      false
                                    )
                                  }
                                />{" "}
                                <label
                                  className="common-radio-deep-blue-label pl-sm-4"
                                  htmlFor="warehouseNotOkay"
                                ></label>{" "}
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="pb-0">
                          <table class="table table-borderless form-group col-12 ">
                            <tr>
                              <td className="pb-0 ">
                                <Field
                                  name={`warehouseName.remark`}
                                  type="text"
                                  disabled={viewMood}
                                  className="form-control bg-white px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </tr>{" "}
                          </table>{" "}
                        </td>{" "}
                        <td className="pb-0 d-flex pt-4">
                          <p className="py-2 text-nowrap pr-4">
                            {" "}
                            {formField
                              ? formField.warehouseRemark
                                ? formField.warehouseRemark.warehouseName
                                    .fastatus === true
                                  ? "okay"
                                  : "Not okay"
                                : ""
                              : ""}{" "}
                          </p>{" "}
                          <input
                            value={
                              formField
                                ? formField.warehouseRemark
                                  ? formField.warehouseRemark.warehouseName
                                      .faremark
                                  : ""
                                : ""
                            }
                            type="text"
                            className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                            placeholder="Remarks"
                            disabled
                          />
                        </td>{" "}
                      </tr>{" "}
                    </tbody>{" "}
                  </table>{" "}
                </div>
                <div className={`col-12 mt-4`}>
                  {" "}
                  {/* <p>{errors?errors['warehouseName.whsstatus']:""}</p> */}
                  <div className="row justify-content-end"> </div>{" "}
                </div>
                {/* ======================== */}{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default WarehousePendingForm;
