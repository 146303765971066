import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import SearchBox from "../../components/common/SearchBox";
import { Link, useParams } from "react-router-dom";
// import FilterList from '../../wrapper/filterManagement/FilterList';
import { Helmet } from "react-helmet";
import { filterById } from "../../store/action/filterAction";
import { useSelector, useDispatch } from "react-redux";
import FilterOptionList from "../../wrapper/filterManagement/FilterOptionList";

const FilterOption = () => {
  const dispatch = useDispatch();
  const { filterId } = useParams();
  const data = useSelector((state) => state.FILTER_INFO);

  useEffect(() => {
    dispatch(filterById(filterId));
  }, [dispatch, filterId]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Filter </title>{" "}
      </Helmet>{" "}
      <div className="row justify-content-end align-items-center sticky-top py-3 px-3 bg-lighter-blue">
        <div className="col-auto d-lg-none">
          <button
            className="btn btn-deep-blue px-0 size-40px toggle-class btn-sidebar-admin sidebar-admin-toggle align-items-center justify-content-center"
            type="button"
            data-target=".sidebar-admin-toggle"
            data-toggle-class="open"
          >
            <span> </span>{" "}
          </button>{" "}
        </div>{" "}
        <SearchBox />
      </div>{" "}
      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 px-0">
          <h5 className="text-dark"> Filter Option Management </h5>{" "}
        </div>
        <div className="col-12 px-0">
          <div className="row align-items-center py-3 mt-4">
            <div className="col-12 table-responsive table-row-margin-bottom-admin">
              <table className="table">
                <tbody>
                  {" "}
                  {data.filterDetail?.filterOptions &&
                  data.filterDetail?.filterOptions.length > 0
                    ? data.filterDetail?.filterOptions.map((item, index) => {
                        return (
                          <FilterOptionList
                            fltId={filterId}
                            item={item}
                            filterName={data.filterDetail?.filterType}
                            type={data.filterDetail?.type}
                          />
                        );
                      })
                    : "Filter Option Not Found"}
                </tbody>{" "}
              </table>{" "}
            </div>{" "}
          </div>{" "}
          <div className="row">
            <div className="col-12 text-right">
              <Link
                to={`/add-filter-option/${filterId}`}
                className="btn btn-deep-blue"
              >
                {" "}
                Add Filter Options{" "}
              </Link>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default FilterOption;
