import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "./../../components/helper/reuse";

const OnBoardFormList = ({ formType, item }) => {
  console.log("Item-->", item);
  return (
    <tr>
      <td className="text-center"> {item?.id} </td>{" "}
      <td> {item?.booking?.warehouse?.warehouseId} </td>{" "}
      <td> {item?.booking?.customer?.companyName} </td>{" "}
      <td> {readableDate(item?.booking?.created_at)} </td>{" "}
      <td> {item?.booking?.customer?.email} </td>{" "}
      <td className="text-capitalize"> {item?.booking?.customer?.city} </td>{" "}
      <td className="text-capitalize"> {item?.booking?.customer?.area} </td>{" "}
      <td className="text-center text-nowrape d-flex justify-content-center">
        <Link
          to={
            formType === "contactForm"
              ? `/customer-contact-details/${item?.contactDetail?.id}?formType=contactDetail&contactDetailOnboardId=${item?.id}`
              : formType === "billingForm"
              ? `/customer-billing-support/${item?.id}?formType=billingSupport&contactDetailOnboardId=${item?.id}`
              : formType === "operationForm"
              ? `/customer-transition-operation/${item.transitionOperation?.id}?formType=transitionOperation&contactDetailOnboardId=${item?.id}`
              : formType === "csForm"
              ? `/customer-transition/${item.transitionCS?.id}?formType=transitionCS&contactDetailOnboardId=${item?.id}`
              : formType === "specialForm"
              ? `/customer-special-instruction/${item?.specialInstruction?.id}?formType=specialInstruction&contactDetailOnboardId=${item?.id}`
              : formType === "costForm"
              ? `/customer-cost-sheet/${item?.costSheet?.id}?formType=costSheet&contactDetailOnboardId=${item?.id}`
              : "/not-Found"
          }
          className="btn text-deep-blue font-weight-bold px-2"
        >
          {" "}
          Open Form{" "}
        </Link>{" "}
      </td>{" "}
    </tr>
  );
};

export default OnBoardFormList;
