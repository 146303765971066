import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import SearchBox from "../../components/common/SearchBox";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { typeByPage } from "../../store/action/whyAction";
import { CardLoader } from "../../components/helper/CustomLoader";
import TypeList from "../../wrapper/whyWarehouse/TypeList";
import Pagination from "react-js-pagination";
import { ItemNotFoud } from "../../components/common/CustomLoader";

const TypeWarehouse = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WHY_INFO);
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(typeByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(typeByPage(pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(typeByPage(parseInt(pageCount), searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Warehouse Type </title>{" "}
      </Helmet>
      <div className="row align-items-center px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            >
              {" "}
            </i>
            Warehouse Type
          </h5>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
          />{" "}
        </div>
        <div className="col-12 px-0">
          {data.isLoading ? (
            <CardLoader />
          ) : (
            <div className="col-12 px-0 table-responsive table-gray-admin bg-white p-3">
              {" "}
              {data.typeList &&
              data.typeList?.data &&
              data.typeList.data.length > 0 ? (
                <table className="table table-dark-custom">
                  <thead>
                    <tr>
                      <th className="text-center"> S.NO </th> <th> Type </th>{" "}
                      <th> image </th> <th className="text-center"> Action </th>{" "}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {data.typeList.data.map((item, index) => {
                      return (
                        <TypeList
                          item={item}
                          index={index + 1}
                          key={index}
                          read={read}
                        />
                      );
                    })}{" "}
                  </tbody>{" "}
                </table>
              ) : (
                <ItemNotFoud message="Data Not Found" />
              )}
            </div>
          )}
          {data.typeList?.totalCount > 10 && (
            <div className="pagination-custom">
              <Pagination
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={data.typeListt?.totalCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                prevPageText={<i className="fas fa-chevron-left" />}
                nextPageText={<i className="fas fa-chevron-right" />}
                hideFirstLastPages={true}
              />{" "}
            </div>
          )}
          <div className="row my-2">
            <div className="col-12 text-right">
              <Link
                to="/add-type"
                className={`btn py-2 btn-deep-blue ${!read ? "" : "d-none"}`}
              >
                {" "}
                Add{" "}
              </Link>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default TypeWarehouse;
