import React from "react";
import { readableDate } from "../../components/helper/reuse";

const NoReportList = ({ item }) => {
  return (
    <tr>
      <td>
        {" "}
        <div className="cell text-left py-1 fs-14px px-1"> {item?.id} </div>
      </td>
      <td>
        {" "}
        <div className="cell text-left py-1 fs-14px">
          {" "}
          {readableDate(item?.created_at)}{" "}
        </div>
      </td>
      <td>
        {" "}
        <div className="cell text-left py-1 fs-14px">
          {" "}
          {item?.customerEnquiry?.user?.companyName}{" "}
        </div>
      </td>
      <td>
        {" "}
        <div className="cell text-left py-1 fs-14px">
          {" "}
          {item?.customerEnquiry?.user?.firstName +
            " " +
            item?.customerEnquiry?.user?.lastName}{" "}
        </div>
      </td>
      <td>
        {" "}
        <div className="cell text-left py-1 fs-14px">
          {" "}
          {item?.customerEnquiry?.user?.email}{" "}
        </div>
      </td>
      <td>
        {" "}
        <div className="cell text-left py-1 fs-14px">
          {" "}
          {item?.customerEnquiry?.user?.phone}{" "}
        </div>
      </td>
      <td>
        {" "}
        <div className="cell text-left py-1 fs-14px">
          {" "}
          {item?.customerEnquiry?.serviceType}{" "}
        </div>
      </td>
    </tr>
  );
};

export default NoReportList;
