import React from "react";
import CommonSideMenu from "../components/SideMenu/CommonSideMenu";
// import DynamicMenu from '../components/SideMenu/DynamicMenu';

const LayoutOne = (props) => {
  return (
    <main className="page-wrapper">
      <div className="row">
        <div
          className="col-auto d-none d-lg-block"
          style={{
            width: "280px",
          }}
        >
          <CommonSideMenu /> {/* <DynamicMenu /> */}{" "}
        </div>

        <div className="content-admin px-4"> {props.children} </div>
      </div>{" "}
    </main>
  );
};

export default LayoutOne;
