import {
    CATEGORY,
    CATEGORY_BY_ID,
    CATEGORY_RESPONSE,
    IS_ERROR,
    IS_LOADING,
} from './../types';

import {
    GET_CATEGORY_URL,
    CREATE_CATEGORY_URL,
    UPDATE_CATEGORY
} from '../../services/urls';

import axiosauth from '../../services/axios-auth';


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}


export function categoryList(data) {
    return {
        type: CATEGORY,
        payload: data
    }
}

export function category_By_Id(data) {
    return {
        type: CATEGORY_BY_ID,
        payload: data
    }
}


export function responseCategory(data) {

    return {
        type: CATEGORY_RESPONSE,
        payload: data
    }
}


// ###########  Fecth All categoryList ########

export const categoryByPage = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        console.log(search, "search")
        console.log(page, "page")
        try {
            axiosauth.get(GET_CATEGORY_URL + `?page=${page}&limit=${50}&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(categoryList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("rfq Fail")
                }
            }).catch((error) => {
                dispatch(isLoading(false))
                dispatch(isError(error.message))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }


}


// ###########  Fecth  category Detail By Id ########

export const categoryById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_CATEGORY_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(category_By_Id(res))
                    dispatch(isLoading(false))
                } else {
                    dispatch(isLoading(false))
                    console.log("category Fail")
                }
            }).catch((error) => {
                dispatch(isLoading(false))
                dispatch(isError(error.message))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  Fecth  category Detail By Id ########

export const deleteCategory = (id) => {

    console.log("hello")

    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.delete(GET_CATEGORY_URL + `/${id}`).then(response => {
                console.log("kidhar", response);
                let res = JSON.parse(response.data)
                if (res.statusCode === 409) {
                    alert("Warehouse Category is already in use so you can't delete this category")
                }
                if (res.statusCode === 200) {


                    dispatch(categoryByPage(1, ""))
                    dispatch(isLoading(false))
                } else {
                    dispatch(isLoading(false))
                    console.log("category Fail")
                }
            }).catch((error) => {
                dispatch(isLoading(false))
                dispatch(isError(error.message))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");


            })

        } catch (e) {}
    }
}


// ###########  CREATE category  ############

export const createCategory = (data) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        console.log("form data==>", data)
        dispatch(isError(""))
        try {
            axiosauth.post(CREATE_CATEGORY_URL, data).then(response => {
                let res = JSON.parse(response.data)
                console.log("Create resss--->", res)
                if (res.statusCode === 200) {
                    dispatch(responseCategory(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("category Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  UPDATE category  ############

export const updateCategory = (data, id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.put(UPDATE_CATEGORY + `/${id}`, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    console.log("hello")
                    dispatch(responseCategory(res))
                    dispatch(categoryByPage(1, ""))
                    dispatch(isLoading(false))
                } else {
                    console.log("category Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}