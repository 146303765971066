import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LayoutOne from "../../layout/LayoutOne";
import { Modal, Button } from "react-bootstrap";

import { Helmet } from "react-helmet";
import { isPending } from "../../store/action/warehouseAction";

import { enquiryById } from "../../store/action/enquiryAction";
import { useSelector, useDispatch } from "react-redux";
import { CardLoader } from "../../components/helper/CustomLoader";
import axiosauth from "../../services/axios-auth";

function EnquirySchemaMoal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body
        style={{
          textAlign: "center",
        }}
      >
        <img
          src={"/assets/images/unnamed.jpg"}
          className="size-150px p-4 mx-auto"
          alt="success"
        />
        <h6>enquiry submitted successfully </h6>{" "}
        <Button className="my-3" onClick={props.onHide}>
          {" "}
          Close{" "}
        </Button>{" "}
      </Modal.Body>{" "}
    </Modal>
  );
}

const EnquiryDetails = () => {
  const [equiryModal, setEnquiryModal] = useState(false);
  const [enquiry, setenquiry] = useState("");
  const submit = (event) => {
    event.preventDefault();
    let id = data.enquiryDetail.vendorEnquiry
      ? data.enquiryDetail.vendorEnquiry.id
      : "";
    axiosauth
      .put("/api/v1/enquiry/admin/user/" + id, {
        adminMessage: enquiry,
        userType: "vendor",
      })
      .then((response) => {
        let res = JSON.parse(response.data);
        if (res.statusCode === 200) {
          dispatch(isPending(false));
          setEnquiryModal(true);
        } else {
          dispatch(isPending(false));

          console.log("Add Warehouse Fail");
        }
      })
      .catch((error) => {})
      .then(() => {
        console.log("-----always executes");
      });
  };
  const history = useHistory();
  const { enquiryId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ENQUIRYINFO);
  useEffect(() => {
    dispatch(enquiryById(enquiryId));
  }, [dispatch, enquiryId]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Enquiry </title>{" "}
      </Helmet>{" "}
      {equiryModal ? (
        <EnquirySchemaMoal
          show={equiryModal}
          onHide={() => {
            setEnquiryModal(false);
            dispatch(enquiryById(enquiryId));
          }}
        />
      ) : null}{" "}
      <div className="row align-items-center px-3 mx-0">
        <div className="content col-12 view-enquiry-details">
          <div className=" mb-3 pt-3">
            <span className=" name-breadcrumb px-0 text-dark mr-3 toggle-class">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left pr-2"
              >
                {" "}
              </i>{" "}
              View Response
            </span>
          </div>
          {data.isLoading ? (
            <CardLoader />
          ) : (
            <div className="row">
              <div className="col-12 bg-white p-3">
                <form className="mb-4">
                  <div className="form-group">
                    <input
                      value={
                        data.enquiryDetail.vendorEnquiry
                          ? data.enquiryDetail.vendorEnquiry.serviceType
                          : ""
                      }
                      type="text"
                      className="form-control form-control-lg"
                      id="formGroupExampleInput"
                      readOnly
                    />
                  </div>{" "}
                  <div className="form-group">
                    <input
                      value={
                        data.enquiryDetail.vendorEnquiry
                          ? data.enquiryDetail.vendorEnquiry.name
                          : ""
                      }
                      type="text"
                      className="form-control form-control-lg"
                      id="formGroupExampleInput"
                      placeholder="Ravish Kumar"
                      readOnly
                    />
                  </div>{" "}
                  <div className="form-group">
                    <input
                      value={
                        data.enquiryDetail.vendorEnquiry
                          ? data.enquiryDetail.vendorEnquiry.email
                          : ""
                      }
                      type="email"
                      className="form-control form-control-lg"
                      id="formGroupExampleInput"
                      placeholder="ravishkumar@gmail.com"
                      readOnly
                    />
                  </div>{" "}
                  <div className="form-group">
                    <input
                      value={
                        data.enquiryDetail.vendorEnquiry
                          ? data.enquiryDetail.vendorEnquiry.companyName
                          : ""
                      }
                      type="text"
                      className="form-control form-control-lg"
                      id="formGroupExampleInput"
                      placeholder="Abc Corporation"
                      readOnly
                    />
                  </div>{" "}
                  <div className="form-group">
                    <input
                      value={
                        data.enquiryDetail.vendorEnquiry
                          ? data.enquiryDetail.vendorEnquiry.phone
                          : ""
                      }
                      type="tel"
                      className="form-control form-control-lg"
                      id="formGroupExampleInput"
                      placeholder="+91 876 542 758 8"
                      readOnly
                    />
                  </div>{" "}
                  <div className="form-group">
                    <textarea
                      value={
                        data.enquiryDetail.vendorEnquiry
                          ? data.enquiryDetail.vendorEnquiry.message
                          : ""
                      }
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      readOnly
                    >
                      {" "}
                      I am looking for warehouse space in Delhi, Kindly help me
                      out.{" "}
                    </textarea>{" "}
                  </div>{" "}
                </form>{" "}
                {data.enquiryDetail.vendorEnquiry &&
                data.enquiryDetail.vendorEnquiry.adminMessage !== "" ? (
                  <form>
                    <h5 className="mb-3"> Response from warehousity. </h5>{" "}
                    <div className="form-group">
                      <textarea
                        value={
                          data.enquiryDetail.vendorEnquiry
                            ? data.enquiryDetail.vendorEnquiry.adminMessage
                            : ""
                        }
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        readOnly
                      >
                        {" "}
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs.{" "}
                      </textarea>{" "}
                    </div>{" "}
                  </form>
                ) : (
                  <form onSubmit={submit}>
                    <h5 className="mb-3"> Response from warehousity. </h5>{" "}
                    <div className="form-group">
                      <textarea
                        required={true}
                        value={enquiry}
                        onChange={(e) => setenquiry(e.target.value)}
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                      >
                        {" "}
                        Lorem ipsum, or lipsum as it is sometimes known, is
                        dummy text used in laying out print, graphic or web
                        designs.{" "}
                      </textarea>{" "}
                    </div>{" "}
                    <div className="row">
                      <div className="col-12 my-4">
                        <button className="btn btn-deep-blue toggle-class">
                          {" "}
                          Submit{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>{" "}
                  </form>
                )}
              </div>{" "}
            </div>
          )}{" "}
          <div className="row">
            <div className="col-12 my-4">
              {" "}
              {/* <button onClick={()=>history.goBack()} className="btn btn-deep-primary toggle-class">Back</button> */}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default EnquiryDetails;
