import {
    CMS,
    CMS_BY_ID,
    CMS_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

import {
    GET_CMS_URL,
    CMS_BY_ID_URL,
    CMS_BY_TYPE_URL,
} from '../../services/urls';



import axiosauth from '../../services/axios-auth';


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}


export function cmsList(data) {
    return {
        type: CMS,
        payload: data
    }
}

export function cms_By_Id(data) {
    return {
        type: CMS_BY_ID,
        payload: data
    }
}


export function responseCMS(data) {
    return {
        type: CMS_RESPONSE,
        payload: data
    }
}


// ###########  Fecth All CMS ########

export const cmsByPage = () => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.post(GET_CMS_URL).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(cmsList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("cms Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }


}


// ###########  Fecth  CMS Detail By Id ########

export const cmsById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(CMS_BY_ID_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(cms_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("cms_By_Id Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  Fecth  CMS_BY_TYPE_URL Detail By Id ########

export const cmsByType = (type) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(CMS_BY_TYPE_URL + `/${type}`).then(response => {
                let res = JSON.parse(response.data)
                // console.log("")
                if (res.statusCode === 200) {
                    dispatch(cms_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("review_By_Id Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })
        } catch (e) {}
    }
}


// ###########  Create New CMS ########

export const createCMS = (obj) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.post(`/api/v1/cms`, obj).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 201) {
                    dispatch(responseCMS(res))
                    dispatch(isLoading(false))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })
        } catch (e) {}
    }
}



// ###########  Fecth  CMS_BY_TYPE_URL Detail By Id ########

export const cmsUpdateByTypeId = (obj, type, id, specs) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.put(CMS_BY_TYPE_URL + `/${type}`, obj).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    if (type === "FAQ") {
                        // dispatch(cmsByType("test"));

                    } else {
                        dispatch(cms_By_Id(res.data))

                    }
                    dispatch(responseCMS(res))
                    dispatch(isLoading(false))
                    if (type === "FAQ1") {
                        axiosauth.put(CMS_BY_TYPE_URL + `/testcust/${7}`, {
                                "cmsType": "testcust",
                                "cmsActive": true,
                                "data": JSON.stringify(specs)
                            })
                            .then(response => {
                                let res = JSON.parse(response.data)
                                console.log("ress--->", res)
                                if (res.statusCode === 200) {
                                    dispatch(cmsByType("test"));

                                    // dispatch(cms_By_Id(res.data))
                                    // dispatch(responseCMS(res))
                                    // dispatch(isLoading(false))
                                } else {
                                    console.log("review_By_Id Fail")
                                }
                            }).catch((error) => {
                                dispatch(isError(error.message))
                                dispatch(isLoading(false))
                            }).then(() => {
                                dispatch(isLoading(false))
                                console.log("-----always executes");
                            })
                    }

                    if (type === "FAQ") {
                        axiosauth.put(CMS_BY_TYPE_URL + `/test/${6}`, {
                                "cmsType": "test",
                                "cmsActive": true,
                                "data": JSON.stringify(specs)
                            })
                            .then(response => {
                                let res = JSON.parse(response.data)
                                console.log("ress--->", res)
                                if (res.statusCode === 200) {
                                    dispatch(cmsByType("test"));

                                    // dispatch(cms_By_Id(res.data))
                                    // dispatch(responseCMS(res))
                                    // dispatch(isLoading(false))
                                } else {
                                    console.log("review_By_Id Fail")
                                }
                            }).catch((error) => {
                                dispatch(isError(error.message))
                                dispatch(isLoading(false))
                            }).then(() => {
                                dispatch(isLoading(false))
                                console.log("-----always executes");
                            })
                    }

                } else {
                    console.log("review_By_Id Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })
        } catch (e) {}
    }
}