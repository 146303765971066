import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import { documentUpload } from "../../components/utils";
import {
  responseWhy,
  typeById,
  updateType,
} from "../../store/action/whyAction";

const UpdateType = () => {
  const { typeId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [inItValue, setInItValue] = useState({
    type: "",
  });
  const data = useSelector((state) => state.WHY_INFO);

  const roleSchema = Yup.object().shape({
    type: Yup.string().required("Required"),
  });

  // File Upload
  const [res, setRes] = useState([]);
  const [fileError, setFileError] = useState(null);

  const uploadDocs = async (e) => {
    setFileError(null);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let urlData = await documentUpload(formData);
    //  console.log(urlData)

    if (urlData && urlData.url) {
      setRes([urlData.url]);
    }
  };

  useEffect(() => {
    if (data.typeDetail) {
      setRes([data.typeDetail.image]);
      setInItValue(data.typeDetail);
    }
  }, [data.typeDetail]);

  useEffect(() => {
    dispatch(typeById(parseInt(typeId)));
  }, [dispatch, typeId]);

  const redirect = () => {
    dispatch(responseWhy(null));
    history.replace(`/type-warehouse`);
  };

  return (
    <LayoutOne>
      {" "}
      {data.whyResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Type Updated`} />
      ) : null}
      <div className="row align-items-center mx-0">
        <div className="col-12 py-3 mt-1">
          <h5 className="text-dark font-weight-bold">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>{" "}
            Update Type Warehouse
          </h5>
        </div>{" "}
      </div>{" "}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 bg-white p-3">
          <Formik
            enableReinitialize={true}
            validationSchema={roleSchema}
            initialValues={inItValue}
            onSubmit={(fields) => {
              if (res && res.length > 0) {
                fields["image"] = res[0];
                dispatch(updateType(typeId, fields));
              } else {
                setFileError("Please Choose image");
              }
            }}
            render={({ values, errors, status, onChange, touched }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="form-group col-12 mb-4">
                      <label htmlFor="staticEmail" className="mb-2 px-3">
                        {" "}
                        Title{" "}
                      </label>{" "}
                      <Field
                        name="type"
                        type="text"
                        className={
                          `form-control form-control-md rounded-pill` +
                          (errors.type && touched.type ? " is-invalid" : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`type`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <div className="row">
                        <div className="col-auto">
                          <div className="card card-overlay upload-image-preview position-relative">
                            {res && res.length > 0 ? (
                              <>
                                <div className="img-holder size-200px">
                                  <img
                                    src={res[0]}
                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                    id="imageResult"
                                    alt=""
                                  />
                                </div>{" "}
                              </>
                            ) : (
                              <>
                                <div className="img-holder size-200px">
                                  <img
                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                    id="imageResult"
                                    src="/assets/images/upload-bg-white.png"
                                    alt=""
                                  />
                                </div>{" "}
                                <div className="card-img-overlay size-200px d-flex justify-content-center align-items-center">
                                  <div className="card-text">
                                    <input
                                      onChange={uploadDocs}
                                      id="upload-image-preview1"
                                      type="file"
                                      accept="image/*"
                                      className="form-control border-0"
                                      hidden
                                    />
                                    <label
                                      id="upload-label"
                                      htmlFor="upload-image-preview1"
                                      className="font-weight-light text-muted"
                                    >
                                      {" "}
                                    </label>{" "}
                                    <div className="input-group-append">
                                      <label
                                        htmlFor="upload-image-preview1"
                                        className="btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4"
                                      >
                                        <div>
                                          <img
                                            src="/assets/images/icons/upload-icon-deep-blue.png"
                                            alt=""
                                          />
                                        </div>
                                        Upload image{" "}
                                      </label>{" "}
                                    </div>{" "}
                                  </div>{" "}
                                </div>{" "}
                              </>
                            )}
                          </div>
                          <input
                            onChange={uploadDocs}
                            id="upload-image-previewChange"
                            type="file"
                            className="form-control border-0"
                            hidden
                          />{" "}
                          {/* <label id="upload-label" style={{ border: '1px solid gray', padding: 4, marginTop: 3 }} htmlFor="upload-image-previewChange" className="font-weight-light text-muted btn btn-deep-blue">
                                                    Change Image</label> */}{" "}
                          <label
                            id="upload-label"
                            style={{
                              padding: 7,
                              marginTop: 3,
                              bottom: 0,
                              right: 26,
                              position: "absolute",
                              borderRadius: "8px",
                            }}
                            htmlFor="upload-image-previewChange"
                            className="text-white border-0 bg-white"
                          >
                            {" "}
                            <i class="fas fa-edit text-whtie"> </i>
                          </label>
                          <span className="errorMsg"> {fileError} </span>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row pt-1">
                    <div className="col-auto">
                      <button
                        type="submit"
                        disabled={data.isPending}
                        className="btn btn-deep-blue toggle-class my-2 py-2"
                      >
                        Update{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default UpdateType;
