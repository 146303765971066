import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { CardLoader } from "../../../components/common/CustomLoader";

const AdditionalRequirementsForm = ({ isView }) => {
  const data = useSelector((state) => state.RFQ_INFO);
  // console.log("InBound RFQ===>", data)
  const [add, setAdd] = useState(null);

  const inboundSchema = Yup.object().shape({
    additionalRequirements: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (
      data?.rfqDetail?.data &&
      data?.rfqDetail?.data?.additionalRequirement &&
      data?.rfqDetail?.data?.additionalRequirement !== null
    ) {
      setAdd(data?.rfqDetail?.data?.additionalRequirement?.additionalRequirement);
    }
  }, [data?.rfqDetail?.data]);

  return (
    <>
      {" "}
      {data?.isLoading ? (
        <CardLoader loaderCard="loaderCard" />
      ) : (
        <Formik
          enableReinitialize={true}
          validationSchema={inboundSchema}
          initialValues={{
            additionalRequirements: add,
          }}
          onSubmit={(fields) => {
            console.log("---->", fields);
          }}
          render={({ values, errors, status, onChange, touched }) => {
            return (
              <div className="w-100 d-block">
                <Form>
                  <div className="row col-12">
                    <div className="row col-12 ml-0">
                      <div className="input-group">
                        <Field
                          type="textarea"
                          name="additionalRequirements"
                          id=""
                          className={
                            `w-100 form-control form-control-md` +
                            (errors.additionalRequirements &&
                            touched.additionalRequirements
                              ? " is-invalid"
                              : "")
                          }
                          rows="5"
                          readOnly={isView}
                        />
                          {" "}
                        <ErrorMessage
                          name="additionalRequirements"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>{" "}
              </div>
            );
          }}
        />
      )}{" "}
    </>
  );
};


export default AdditionalRequirementsForm;
