import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import SearchBox from "../../components/common/SearchBox";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchWarehouseByPage34 } from "../../store/action/warehouseAction";
import { useHistory } from "react-router-dom";
import AssignedAgentList from "../../wrapper/warehouseManagement/AssignedAgentList";
import { Link } from "react-router-dom";

const AssignedFieldAgent = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    // dispatch(fetchWarehouse())
    dispatch(fetchWarehouseByPage34(parseInt(pageCount)));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(fetchWarehouseByPage34(pageNumber));
  };

  const callSearch = () => {
    dispatch(fetchWarehouseByPage34(pageCount, searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Rejected By Field Agent </title>{" "}
      </Helmet>{" "}
      <div className="row align-items-center  mx-0 px-3 my-1">
        <div className="col-12 col-sm-12 col-md-12 col-xl bg-white mb-2">
          <div className="row justify-content-between">
            <div className="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul className="nav nav-pills admin-tabs-blue">
                <li className="nav-item">
                  <Link
                    to="/assign-to-field-agent?page=1"
                    className="nav-link  text-uppercase"
                  >
                    Submitted To FA{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/assign-to-field-agent-approved?page=1"
                    className="nav-link text-uppercase "
                  >
                    Approved By FA{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/assign-to-field-agent-rejected?page=1"
                    className="nav-link active text-uppercase "
                  >
                    Rejected By FA{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-xl-auto bg-white mb-2">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
            border={true}
          />{" "}
        </div>
        <div className="col-12 px-0 table-responsive table-gray-admin">
          {" "}
          {data.listOfWarehouse.data && data.listOfWarehouse.data.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th className="w-100px"> S.No. </th>{" "}
                  <th className="text-nowrap"> Listing Date </th>{" "}
                  <th className="text-nowrap"> WH ID </th>{" "}
                  <th className="text-nowrap"> SP Company name </th>{" "}
                  <th className="text-nowrap"> WH Category </th>{" "}
                  <th> Status </th> <th className="text-center"> </th>{" "}
                </tr>{" "}
              </thead>{" "}
              <tbody>
                {data.listOfWarehouse.data.map((item, index) => {
                  return (
                    <AssignedAgentList
                      key={index}
                      item={item}
                      index={(pageCount - 1) * 10 + (index + 1)}
                    />
                  );
                })}{" "}
              </tbody>{" "}
            </table>
          ) : null}{" "}
        </div>{" "}
      </div>{" "}
      {/* ============ Pagination ============ */}{" "}
      {data.listOfWarehouse.totalCount > 10 && (
        <div className="pagination-custom">
          <Pagination
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={data.listOfWarehouse.totalCount}
            pageRangeDisplayed={1}
            onChange={handlePageChange}
            prevPageText={<i className="fas fa-chevron-left" />}
            nextPageText={<i className="fas fa-chevron-right" />}
            hideFirstLastPages={true}
          />{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default AssignedFieldAgent;
