import React, { useEffect, useState } from 'react';
import LayoutOne from '../../layout/LayoutOne';
import { Helmet } from 'react-helmet';
import SearchBox from '../../components/common/SearchBox';
import WarehouseRejectedList from '../../wrapper/warehouseManagement/WarehouseRejectedList';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWarehouseByPage2New, fetchWarehouseByPage } from '../../store/action/warehouseAction';
import { useHistory } from 'react-router-dom';
import { ItemNotFoud } from '../../components/common/CustomLoader';

const RejectedWarehouse = () => {
  const [searchHandler, setSearchHandler] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const pageCount = new URLSearchParams(window.location.search).get('page');
  useEffect(() => {
    // dispatch(fetchWarehouse())
    dispatch(fetchWarehouseByPage(parseInt(pageCount), '', ['Rejected']));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('page', pageNumber);
    history.push(window.location.pathname + '?' + currentUrlParams.toString());
    // dispatch(fetchWarehouseByPage2(pageNumber, searchHandler));
  };

  const callSearch = () => {
    // dispatch(fetchWarehouseByPage2(pageCount, searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet='utf-8' />
        <title> Rejected Warehouse </title>{' '}
      </Helmet>
      <div className='row align-items-center  mx-0 px-3'>
        <div className='col-12 col-sm-6 col-md-6 col-lg-6 px-0 d-flex justify-content-between'>
          <h5 className='text-dark'>
            <i
              onClick={() => history.goBack()}
              className='fas fa-chevron-left mr-3 cursorPointer'
            ></i>
            Rejected Warehosue{' '}
          </h5>{' '}
        </div>{' '}
        <div className='col-12 col-sm-6 col-md-6 col-lg-6 px-0'>
          <SearchBox callSearch={callSearch} setSearchHandler={setSearchHandler} border={true} />{' '}
        </div>
        <div className='col-12 text-nowrap table-responsive table-gray-admin bg-white p-3 '>
          {' '}
          {data?.listOfWarehouse?.data?.warehouses &&
            data?.listOfWarehouse?.data?.warehouses.length > 0 ? (
            <table className='table table-dark-custom'>
              <thead>
                <tr>
                  <th className='w-100px'> S.No. </th>
                  <th className='text-nowrap'> WH ID </th>{' '}
                  <th className='text-nowrap'> Warehouse Name </th>{' '}
                  <th> Status </th>{' '}
                  <th> Action </th>{' '}
                  <th className='text-center'> </th>{' '}
                </tr>{' '}
              </thead>{' '}
              <tbody>
                {' '}
                {data.listOfWarehouse.data.warehouses.map((item, index) => {
                  return (
                    <WarehouseRejectedList
                      key={index}
                      item={item}
                      index={(pageCount - 1) * 10 + (index + 1)}
                    />
                  );
                })}{' '}
              </tbody>{' '}
            </table>
          ) : (
            <ItemNotFoud message='Data not Found' />
          )}{' '}
        </div>{' '}
      </div>
      {/* ============ Pagination ============ */}{' '}
      {data?.listOfWarehouse?.data?.totalCount > 1 && (
        <div className='pagination-custom'>
          <Pagination
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={data.listOfWarehouse.data.totalCount}
            pageRangeDisplayed={1}
            onChange={handlePageChange}
            prevPageText={<i className='fas fa-chevron-left' />}
            nextPageText={<i className='fas fa-chevron-right' />}
            hideFirstLastPages={true}
          />{' '}
        </div>
      )}{' '}
    </LayoutOne>
  );
};

export default RejectedWarehouse;
