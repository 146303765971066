import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import ReviewList from "../../wrapper/ReviewManagement/ReviewList";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { reviewByPage, responseReview } from "../../store/action/reviewAction";
import FormSuccess from "../../components/common/FormSuccess";
import { CardLoader, ItemNotFound } from "../../components/helper/CustomLoader";
import SearchBox from "../../components/common/SearchBox";
import Pagination from "react-js-pagination";
import { useHistory } from "react-router-dom";

const Review = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.REVIEW_INFO);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  console.log("reviewpage", pageCount);

  useEffect(() => {
    dispatch(reviewByPage(pageCount, ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(reviewByPage(pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(reviewByPage(pageCount, searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Review </title>{" "}
      </Helmet>
      {data && data?.reviewResponse?.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(responseReview(null))}
          message={data.reviewResponse.message}
        />
      ) : null}
      <div class="row align-items-center px-3 mx-0">
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <h5 class="text-dark mt-2">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-1 cursorPointer"
            ></i>
            Review Management{" "}
          </h5>{" "}
        </div>{" "}
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
          />{" "}
        </div>
        {data.isLoading ? (
          <CardLoader />
        ) : (
          <div class="col-12">
            <div class="row align-items-center py-3">
              <div class="col-12 table-responsive table-gray-admin bg-white p-3">
                {" "}
                {data.reviewList && data.reviewList.length > 0 ? (
                  <table className="table table-dark-custom">
                    <thead>
                      <tr>
                        <th className="text-nowrap text-center"> Sr.No. </th>{" "}
                        <th className="text-nowrap"> User </th>{" "}
                        <th className="text-nowrap"> Warehpuse Name </th>{" "}
                        <th className="text-nowrap"> View </th>{" "}
                        <th className="text-nowrap"> Delete </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      {data.reviewList.map((item, index) => {
                        return (
                          <ReviewList
                            item={item}
                            key={index}
                            index={index + 1}
                          />
                        );
                      })}{" "}
                    </tbody>{" "}
                  </table>
                ) : (
                  <ItemNotFound message="Review Not Found" />
                )}
                {/* <table class="table">
                                      <tbody>

                                        {data.reviewList && data.reviewList.length > 0 ?
                                          data.reviewList.map((item, index) => {
                                            return (
                                              <ReviewList item={item} key={index} />
                                            )
                                          })
                                          :
                                          <ItemNotFound message="Review Not Found" />
                                        }
                                      </tbody>
                                    </table> */}{" "}
              </div>{" "}
            </div>
            {data.reviewList?.length > 10 && (
              <div className="pagination-custom">
                <Pagination
                  activePage={parseInt(pageCount)}
                  itemsCountPerPage={10}
                  totalItemsCount={data.reviewList?.totalCount}
                  pageRangeDisplayed={1}
                  onChange={handlePageChange}
                  prevPageText={<i className="fas fa-chevron-left" />}
                  nextPageText={<i className="fas fa-chevron-right" />}
                  hideFirstLastPages={true}
                />{" "}
              </div>
            )}{" "}
          </div>
        )}{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default Review;
