import React, { useEffect, useState } from 'react';
import LayoutOne from '../../layout/LayoutOne';
import { Link, useHistory, useParams } from 'react-router-dom';
import { rfqStatusByRfqId } from '../../store/action/rfqAction';
import { useSelector, useDispatch } from 'react-redux';
import { readableDate } from '../../components/helper/reuse';
import { CardLoader } from '../../components/common/CustomLoader';

const ManageRFQView = () => {
  const history = useHistory();
  const { rfqId, warehouseId } = useParams();
  const dispatch = useDispatch();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.RFQ_INFO);

  // eslint-disable-next-line
  const [showFirst, setShowFirst] = useState(true);
  const [buttonShow, setButtonShow] = useState(true);
  const [sendToVendorButton, setSendToVendorButton] = useState(true);

  // eslint-disable-next-line
  const isOpen = new URLSearchParams(window.location.search).get('isOpen');
  const stateName = new URLSearchParams(window.location.search).get('stateName');

  useEffect(() => {
    dispatch(rfqStatusByRfqId(rfqId));
  }, [dispatch, rfqId]);

  useEffect(() => {
    if (
      data.rfqDetail.data &&
      data.rfqDetail.data?.customerRequestResponses &&
      data.rfqDetail.data?.customerRequestResponses.length > 1
    ) {
      for (let i = 0; i < data.rfqDetail.data.customerRequestResponses.length; i++) {
        if (
          data.rfqDetail.data.customerRequestResponses[i]['purpose'] === 'Customer to Warehouse'
        ) {
          setShowFirst(false);
        }
      }

      for (let i = 0; i < data.rfqDetail.data.customerRequestResponses.length; i++) {
        if (
          data.rfqDetail.data.customerRequestResponses[i]['purpose'] === 'warehousity to customer'
        ) {
          setButtonShow(false);
        }
      }
    }

    if (data.rfqDetail.data?.vendorRequestRfq && data.rfqDetail.data?.vendorRequestRfq.length > 0) {
      // for (let k = 0; k <data.rfqDetail.data.vendorRequestRfq.length; k++) {
      //   for (let p = 0; p <data.rfqDetail.data.vendorRequestRfq[k].vendorRequestResponse?.length; p++) {
      // if (data.rfqDetail.data.vendorRequestRfq[k].vendorRequestResponse[0].warehouseId === parseInt(warehouseId)) {
      // setSendToVendorButton(false);
      // }
      //   }
      // }
    }
  }, [data.rfqDetail.data]);

  return (
    <LayoutOne>
      <div className='row align-items-center py-3 px-3 mx-0'>
        <div className='col-12'>
          <h5 className='text-dark'>
            {' '}
            <i onClick={() => history.goBack()} className='fas fa-chevron-left mr-2 cursorPointer'>
              {' '}
            </i>
            Manage RFQ Details Development Action ( RFQ ID : {rfqId})
          </h5>
        </div>

        {data.isLoading ? (
          <CardLoader />
        ) : (
          <div className='col-12 px-0 table-responsive table-row-margin-bottom-admin bg-white p-3 border'>
            <table className='table table-dark-custom table-bordered'>
              <thead>
                <tr>
                  <th className='text-nowrap py-2'> Document Name </th>{' '}
                  <th className='text-nowrap text-capitalize py-2'> Shared By </th>{' '}
                  <th className='text-nowrap text-capitalize py-2'> Shared To </th>{' '}
                  <th className='text-nowrap text-capitalize py-2'> On Date </th>{' '}
                  <th className='text-nowrap mw-250px text-capitalize py-2'> Purpose </th>{' '}
                  <th className='text-center py-2'> </th>{' '}
                </tr>{' '}
              </thead>{' '}
              <tbody>
                {' '}
                {data.rfqDetail.data?.customerRequestResponses &&
                data.rfqDetail.data?.customerRequestResponses.length > 0
                  ? data.rfqDetail.data.customerRequestResponses.map((item, index) => {
                      return (
                        <>
                          {/* {index === 0 ? */}
                          <tr key={index}>
                            <td className='py-2'> {item.documentName} </td>{' '}
                            <td className='py-2'> {item.sharedBy} </td>{' '}
                            <td className='py-2'> {item.sharedTo} new </td>{' '}
                            <td className='text-nowrap py-2'> {readableDate(item.onDate)} </td>{' '}
                            <td className='py-2'>
                              {' '}
                              {item.purpose === 'customer to warehousity' ? (
                                <div> Request For Warehouse </div>
                              ) : null}{' '}
                              <div> {item.purpose} </div>{' '}
                            </td>{' '}
                            <td className='py-2'>
                              {' '}
                              {item.purpose === 'customer to warehousity' ? (
                                <Link
                                  to={`/customer-rfq/${rfqId}`}
                                  className={`btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1 ${
                                    !read ? '' : 'd-none'
                                  }`}
                                >
                                  View Document{' '}
                                </Link>
                              ) : (
                                <Link
                                  to={`/whs-to-customer-rfq/${item.customerResponseId}`}
                                  className={`btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1 ${
                                    !read ? '' : 'd-none'
                                  }`}
                                >
                                  View Document{' '}
                                </Link>
                              )}{' '}
                            </td>{' '}
                          </tr>{' '}
                          {/* : null} */}
                          {/*{item.warehouseId === parseInt(warehouseId) ? (
                            <tr key={index}>
                              <td> {item.documentName} </td> <td> {item.sharedBy} </td>{' '}
                              <td> {item.sharedTo} duplicate </td>{' '}
                              <td className='text-nowrap'> {readableDate(item.onDate)} </td>{' '}
                              <td>
                                {' '}
                                {item.purpose === 'customer to warehousity' ? (
                                  <div> Request For Warehouse </div>
                                ) : null}{' '}
                                <div> {item.purpose} </div>{' '}
                              </td>{' '}
                              <td>
                                {' '}
                                {item.purpose === 'customer to warehousity' ? (
                                  <Link
                                    to={`/customer-rfq/${rfqId}`}
                                    className={`btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1 ${
                                      !read ? '' : 'd-none'
                                    }`}
                                  >
                                    View Document{' '}
                                  </Link>
                                ) : (
                                  <Link
                                    to={`/whs-to-customer-rfq/${item.customerResponseId}`}
                                    className={`btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1 ${
                                      !read ? '' : 'd-none'
                                    }`}
                                  >
                                    View Document{' '}
                                  </Link>
                                )}{' '}
                              </td>{' '}
                            </tr>
                          ) : null}*/}
                          {console.log(sendToVendorButton, '&&&&&&&*sendToVendorButton*&&&&&&')}{' '}
                          {console.log(item.purpose, '|*|&&&&&&&&&&|*|', sendToVendorButton)}
                          {item.purpose == 'customer to warehousity' &&
                          sendToVendorButton === true ? (
                            <div className='row justify-content-start'>
                              <div className='col-auto'>
                                <Link
                                  to={`/create-rfq/${rfqId}/${warehouseId}?stateName=${stateName}`}
                                  className='btn btn-outline-blue mr-3 my-2 text-nowrap'
                                >
                                  {' '}
                                  Send To Vendor{' '}
                                </Link>{' '}
                              </div>{' '}
                            </div>
                          ) : (
                            'Else Section'
                          )}{' '}
                        </>
                      );
                    })
                  : null}
                {data.rfqDetail.data?.vendorRequestRfq &&
                data.rfqDetail.data?.vendorRequestRfq.length > 0
                  ? data.rfqDetail.data.vendorRequestRfq.map((item, index) => {
                      return (
                        <>
                          {' '}
                          {item.vendorRequestResponse && item.vendorRequestResponse.length > 0
                            ? item.vendorRequestResponse.map((vResponse, i) => {
                                return (
                                  <>
                                    {' '}
                                    {/* {vResponse.warehouseId === parseInt(warehouseId) ? */}{' '}
                                    <>
                                      <tr key={i}>
                                        <td> {vResponse.documentName} </td>{' '}
                                        <td> {vResponse.sharedBy} vendorRequestResponse </td>{' '}
                                        <td> {vResponse.sharedTo} </td>{' '}
                                        <td className='text-nowrap'>
                                          {' '}
                                          {readableDate(vResponse.onDate)}{' '}
                                        </td>{' '}
                                        <td>
                                          <div> {vResponse.purpose} </div>{' '}
                                        </td>{' '}
                                        <td>
                                          {vResponse.purpose === 'Warehousity to Vendor' ? (
                                            <Link
                                              to={`/whs-to-vendor-rfq/${rfqId}/${item.id}`}
                                              className={`btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1 ${
                                                !read ? '' : 'd-none'
                                              }`}
                                            >
                                              View Document{' '}
                                            </Link>
                                          ) : null}

                                          {vResponse.purpose === 'Vendor to Warehousity' ? (
                                            <Link
                                              to={`/vendor-to-whs-rfq/${vResponse.vendorResponseId}`}
                                              className={`btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1 ${
                                                !read ? '' : 'd-none'
                                              }`}
                                            >
                                              View Document{' '}
                                            </Link>
                                          ) : null}
                                        </td>{' '}
                                      </tr>
                                      {vResponse.purpose === 'Vendor to Warehousity' &&
                                      buttonShow ? (
                                        <div className='row justify-content-start'>
                                          <div className='col-auto'>
                                            <Link
                                              to={`/send-whs-to-customer-rfq/${rfqId}/${vResponse?.warehouseId}/${vResponse.vendorResponseId}`}
                                              className='btn btn-outline-secondary mr-3 my-2 toggle-class text-nowrap'
                                            >
                                              {' '}
                                              Submit To Customer{' '}
                                            </Link>{' '}
                                          </div>{' '}
                                        </div>
                                      ) : null}

                                      {/* 
                                                                                                    {vResponse.purpose === "Warehousity to Vendor" && sendToVendorButton &&

                                                                                                      <div className="row justify-content-start">
                                                                                                        <div className="col-auto">
                                                                                                          <Link to={`/create-rfq/${rfqId}/${warehouseId}`} className="btn btn-outline-secondary mr-3 my-2 toggle-class text-nowrap">Send To Vendor</Link>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                      } */}
                                    </>{' '}
                                    {/* : null} */}{' '}
                                  </>
                                );
                              })
                            : null}{' '}
                        </>
                      );
                    })
                  : null}
              </tbody>{' '}
            </table>{' '}
          </div>
        )}

        {/* <div className="col-12 mt-3">
                                                <div className="row justify-content-start">
                                                  <div className="col-auto">
                                                  <Link to={`/send-whs-to-customer-rfq/${rfqId}/${warehouseId}`} className="btn btn-outline-secondary mr-3 my-2 toggle-class">Submit To Customer</Link>
                                                  </div>
                                                </div>
                                              </div> */}
      </div>{' '}
    </LayoutOne>
  );
};

export default ManageRFQView;
