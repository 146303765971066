import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import {
  getDashboard,
  getOperatorType,
  getLocationWarehouse,
  getRequestReceive,
  getLocationFilter,
  getDashboardCategory,
} from "../../store/action/dashboardAction";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ItemNotFound } from "../../components/helper/CustomLoader";
import CardBox from "./CardBox";
import TypeWarehouse from "./TypeWarehouse";
import CategoryCard from "./CategoryCard";
import SpaceLocation from "./SpaceLocation";

const Dashboard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.DASHBOARD_INFO);
  const [dashboardChange, setDashboardChange] = useState(false);

  useEffect(() => {
    let account = JSON.parse(localStorage.getItem("userData"));
    if (account?.accountRole?.id) {
      setDashboardChange(true);
    } else {
      dispatch(getDashboard());
      dispatch(getOperatorType());
      dispatch(getLocationWarehouse());
      dispatch(getRequestReceive());
      dispatch(getLocationFilter());
      dispatch(getDashboardCategory());
    }
  }, [dispatch]);

  const dashboardIcons = [
    {
      id: 1,
      imgIcon: "/dashboard-icons/total-no-registered-space-providers.png",
    },
    {
      id: 2,
      imgIcon: "/dashboard-icons/Listed-Space-Provider.png",
    },
    {
      id: 3,
      imgIcon: "/dashboard-icons/Operative-Space-Provider.png",
    },
    {
      id: 4,
      imgIcon: "/dashboard-icons/No-of-vendors-with-no-isting.png",
    },
    {
      id: 5,
      imgIcon: "/dashboard-icons/Verified-warehouse-details.png",
    },
    {
      id: 6,
      imgIcon: "/dashboard-icons/Warehouses-listing-Request-Received.png",
    },
    {
      id: 7,
      imgIcon: "/dashboard-icons/Warehousenot-submit-for-approval.png",
    },
    {
      id: 8,
      imgIcon: "/dashboard-icons/Total-Listed-Warehouses-(WHSPortal).png",
    },
    {
      id: 9,
      imgIcon: "/dashboard-icons/Pending-for-Listing-(WHS Verification).png",
    },
    {
      id: 10,
      imgIcon: "/dashboard-icons/Operational-warehouses.png",
    },
    {
      id: 11,
      imgIcon: "/dashboard-icons/Type-of-Warehouse.png",
    },
    {
      id: 12,
      imgIcon: "/dashboard-icons/operational-databases.png",
    },
    {
      id: 13,
      imgIcon: "/dashboard-icons/Agreement-about-to-Expire-(Day Limit).png",
    },
    {
      id: 14,
      imgIcon: "/dashboard-icons/Total-Space-Registered-with-WHS-(Sqft).png",
    },
    {
      id: 15,
      imgIcon: "/dashboard-icons/Available--Space.png",
    },
    {
      id: 16,
      imgIcon: "/dashboard-icons/State-Count.png",
    },
    {
      id: 17,
      imgIcon: "/dashboard-icons/State-Count.png",
    },
    {
      id: 18,
      imgIcon: "/dashboard-icons/Cities-Count.png",
    },
    {
      id: 19,
      imgIcon: "/dashboard-icons/Pincode-Count.png",
    },
    {
      id: 20,
      imgIcon: "/dashboard-icons/City-Profile-Update-WHS-portal.png",
    },
    {
      id: 21,
      imgIcon: "/dashboard-icons/count.png",
    },
    {
      id: 22,
      imgIcon: "/dashboard-icons/Pincode-Count.png",
    },
    {
      id: 23,
      imgIcon: "/dashboard-icons/Type-of-Customers.png",
    },
    {
      id: 24,
      imgIcon: "/dashboard-icons/Pincode-Count.png",
    },
    {
      id: 25,
      imgIcon: "/dashboard-icons/Total-Request-for-WH-Booking.png",
    },
    {
      id: 26,
      imgIcon: "/dashboard-icons/Booking-Request-attended-WHS.png",
    },
    {
      id: 27,
      imgIcon: "/dashboard-icons/application-settings.png",
    },
    {
      id: 28,
      imgIcon: "/dashboard-icons/Confirmed-Bookings.png",
    },
    {
      id: 29,
      imgIcon: "/dashboard-icons/Booking-Request-Hold.png",
    },
    {
      id: 30,
      imgIcon: "/dashboard-icons/expired.png",
    },
    {
      id: 31,
      imgIcon: "/dashboard-icons/Industry-Specific-Customer.png",
    },
    {
      id: 32,
      imgIcon: "/dashboard-icons/certificate.png",
    },
    {
      id: 33,
      imgIcon: "/dashboard-icons/Active-Customer-State.png",
    },
    {
      id: 34,
      imgIcon: "/dashboard-icons/Active-Customer-Cities.png",
    },
    {
      id: 35,
      imgIcon: "/dashboard-icons/Pincode-Count.png",
    },
    {
      id: 35,
      imgIcon: "/dashboard-icons/customer.png",
    },
  ];
  const dasIcons = [
    {
      id: 1,
      imgIcon: "/dashboard-icons/warehouse2.png",
    },
    {
      id: 2,
      imgIcon: "/dashboard-icons/warehouse2.png",
    },
  ];
  const categoryIcons = [
    {
      id: 1,
      imgIcon: "/dashboard-icons/warehouse2.png",
    },
    {
      id: 2,
      imgIcon: "/dashboard-icons/warehouse2.png",
    },
    {
      id: 3,
      imgIcon: "/dashboard-icons/warehouse2.png",
    },
    {
      id: 4,
      imgIcon: "/dashboard-icons/warehouse2.png",
    },
    {
      id: 5,
      imgIcon: "/dashboard-icons/warehouse2.png",
    },
  ];
  const spaceLocation = [
    {
      id: 1,
      imgIcon: "/dashboard-icons/Active-Customer-Cities.png",
      padding: "p-3",
    },
    {
      id: 2,
      padding: "p-2",
      imgIcon: "/dashboard-icons/india.png",
    },
  ];

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Warehouse Admin </title>{" "}
      </Helmet>
      {!dashboardChange ? (
        <div className="justify-content-end align-items-center py-3">
          <div className="col-12 bg-white custom-shadow py-1 mb-4  tabs-scroll">
            <ul className="nav nav-pills pl-3" id="pills-tab" role="tablist">
              <li className="nav-item">
                <Link
                  to={`/dashboard`}
                  className="nav-link h6 active_link text-uppercase"
                >
                  Space Provider{" "}
                </Link>{" "}
              </li>
              <li className="nav-item">
                <Link
                  to={`/customer-dashboard`}
                  className="nav-link h6 text-uppercase"
                >
                  Customer Details{" "}
                </Link>{" "}
              </li>
              <li className="nav-item">
                <Link
                  to={`/dashboard-demography`}
                  className="nav-link h6 text-uppercase"
                >
                  Demography{" "}
                </Link>{" "}
              </li>{" "}
            </ul>{" "}
          </div>{" "}
          <div className="col-12 px-0 ">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-warehouse-summary"
                role="tabpanel"
                aria-labelledby="pills-warehouse-summary-tab"
              >
                <div className="dashboard-cards row">
                  <div className="col-12 mb-2 d-flex">
                    <div className="dashboard-card bg-white custom-shadow w-100 rounded">
                      <div className="card-body py-xxl-5 py-2 px-4">
                        <h6 className="mb-0">
                          Available SpaceSpace-Warehouse category{" "}
                        </h6>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                  <div className="px-4 row">
                    {" "}
                    {data?.dashboardDetail && data?.dashboardDetail.length > 0
                      ? data?.dashboardDetail.map((item, index) => {
                          return (
                            <CardBox
                              key={index}
                              data={item}
                              title={item}
                              // imgIcon={item?.imageUrl}
                              dashboardIcon={dashboardIcons[index]}
                            />
                          );
                        })
                      : null}{" "}
                  </div>{" "}
                </div>
                {data.dashboardWarehouseReceive &&
                data.dashboardWarehouseReceive.length > 0
                  ? data.dashboardWarehouseReceive.map((item, k) => {
                      return (
                        <div key={k} className="row ">
                          <div className="col-12 ">
                            <div className="dashboard-card bg-white custom-shadow w-100 rounded bg-white mb-2">
                              <div className="card-body py-xxl-5 py-2 px-4">
                                <span className=""> {item.title} </span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>
                          <div className="row w-100 pl-4">
                            {" "}
                            {item.counts.map((wh, i) => {
                              return (
                                <TypeWarehouse
                                  key={i}
                                  data={wh}
                                  // imgIcon={item?.imageUrl}
                                  dashboardIcon={dasIcons[i]}
                                />
                              );
                            })}{" "}
                          </div>{" "}
                        </div>
                      );
                    })
                  : null}
                {data.dashboardWarehouseCategory &&
                data.dashboardWarehouseCategory.length > 0
                  ? data.dashboardWarehouseCategory.map((item, k) => {
                      return (
                        <div index={k} className="row">
                          <div className="col-12">
                            <div className="dashboard-card bg-white custom-shadow w-100 rounded bg-white mb-2">
                              <div className="card-body py-xxl-5 py-2 px-4">
                                <span className="" key={k}>
                                  {" "}
                                  {item.title}{" "}
                                </span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                          <div className="row w-100 pl-4">
                            {" "}
                            {item.counts.map((wh, i) => {
                              return (
                                <CategoryCard
                                  key={i}
                                  data={wh}
                                  // imgIcon={item?.imageUrl}
                                  dashboardIcon={categoryIcons[i]}
                                />
                              );
                            })}{" "}
                          </div>{" "}
                        </div>
                      );
                    })
                  : null}
                {/* =================================================== */}
                {data.dashboardOperator && data.dashboardOperator.length > 0
                  ? data.dashboardOperator.map((item, k) => {
                      return (
                        <div key={k} className="row">
                          <div className="col-12">
                            <div className="dashboard-card bg-white custom-shadow w-100 rounded bg-white mb-2">
                              <div className="card-body py-xxl-5 py-2 px-4">
                                <span className="" key={k}>
                                  {" "}
                                  {item.title}{" "}
                                </span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                          <div className="row w-100 pl-4">
                            {" "}
                            {item.counts.map((wh, i) => {
                              return (
                                <CategoryCard
                                  key={i}
                                  data={wh}
                                  // imgIcon={item?.imageUrl}
                                  dashboardIcon={categoryIcons[i]}
                                />
                              );
                            })}{" "}
                          </div>{" "}
                        </div>
                      );
                    })
                  : null}
                {/* =================================================== */}
                {data.dashboardLocation && data.dashboardLocation.length > 0
                  ? data.dashboardLocation.map((item, k) => {
                      return (
                        <div key={k} className="row">
                          <div className="col-12">
                            <div className="dashboard-card bg-white custom-shadow w-100 rounded bg-white mb-2">
                              <div className="card-body py-xxl-5 py-2 px-4">
                                <span> {item.title} </span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                          <div className="row w-100 pl-4">
                            {" "}
                            {item.counts.map((wh, i) => {
                              return (
                                <SpaceLocation
                                  key={i}
                                  data={wh}
                                  // imgIcon={item?.imageUrl}
                                  dashboardIcon={spaceLocation[i]}
                                />
                              );
                            })}{" "}
                          </div>{" "}
                        </div>
                      );
                    })
                  : null}
                {/* =================================================== */}
                {data.dashboardLocationFilter &&
                data.dashboardLocationFilter.length > 0
                  ? data.dashboardLocationFilter.map((item, k) => {
                      return (
                        <div key={k} className="row">
                          <div className="col-12">
                            <div className="dashboard-card bg-white custom-shadow w-100 rounded bg-white mb-2">
                              <div className="card-body py-xxl-5 py-2 px-4">
                                <span> {item.title} </span>{" "}
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                          <div className="row w-100 pl-4">
                            {" "}
                            {item.counts.map((wh, i) => {
                              return (
                                <SpaceLocation
                                  key={i}
                                  data={wh}
                                  // imgIcon={item?.imageUrl}
                                  dashboardIcon={spaceLocation[i]}
                                />
                              );
                            })}{" "}
                          </div>{" "}
                        </div>
                      );
                    })
                  : null}{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      ) : (
        <ItemNotFound message="Welcome to Warehousity" />
      )}{" "}
    </LayoutOne>
  );
};

export default Dashboard;
