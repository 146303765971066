import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import {
  serviceSubCategoryById,
  updateServiceSubCategory,
  responseService,
} from "../../store/action/serviceAction";
import { CardLoader } from "../../components/helper/CustomLoader";
import { onlyAlphaNumericSpaceAllow } from "../../components/helper/reuse";

const EditServiceSubCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.SERVICE_INFO);
  const { subCategoryId } = useParams();

  const [initCategory, setInitCategory] = useState({
    name: "",
    categoryId: 1,
    features: [
      {
        name: "",
      },
    ],
  });

  const roleSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service-subcategory`);
  };

  useEffect(() => {
    if (data.subCategoryDetail) {
      setInitCategory({
        name: data.subCategoryDetail?.name,
        categoryId: data.subCategoryDetail?.category?.id,
        active: data.subCategoryDetail?.active,
        features: JSON.parse(data.subCategoryDetail?.serviceFeature),
      });
    }
  }, [dispatch, data.subCategoryDetail]);

  useEffect(() => {
    dispatch(serviceSubCategoryById(subCategoryId));
  }, [dispatch, subCategoryId]);

  return (
    <LayoutOne>
      {" "}
      {data.serviceResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Sub Category Updated`} />
      ) : null}
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row align-items-center pb-3 px-3 mx-0">
          <div className="col-12 py-3">
            <h5 className="text-dark">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-3 cursorPointer"
              ></i>{" "}
              Update Sub Category{" "}
            </h5>{" "}
          </div>{" "}
          <div className="col-12">
            <Formik
              enableReinitialize={true}
              validationSchema={roleSchema}
              initialValues={initCategory}
              onSubmit={(fields) => {
                fields.serviceFeature = JSON.stringify(fields.features);
                if (data.subCategoryDetail?.id) {
                  dispatch(
                    updateServiceSubCategory(data.subCategoryDetail?.id, fields)
                  );
                }
              }}
              render={({ values, errors, status, onChange, touched }) => {
                return (
                  <Form>
                    <div className="row">
                      <div className="form-group col-12 mb-4">
                        <label htmlFor="staticEmail" className="mb-2 px-3">
                          Select Category Name{" "}
                        </label>{" "}
                        <select
                          name="categoryId"
                          value={data.subCategoryDetail?.category?.name}
                          as="select"
                          type="text"
                          className={`form-control form-control-md common-select-deep-blue rounded-pill`}
                          disabled
                        >
                          <option> Select </option>{" "}
                          <option
                            value={data.subCategoryDetail?.category?.name}
                          >
                            {data.subCategoryDetail?.category?.name}{" "}
                          </option>{" "}
                          <option value="Finance"> Finance </option>{" "}
                        </select>{" "}
                      </div>{" "}
                      <div className="form-group col-12 mb-4">
                        <label htmlFor="staticEmail" className="mb-2 px-3">
                          Sub Category Name{" "}
                        </label>{" "}
                        <Field
                          onKeyPress={(e) => onlyAlphaNumericSpaceAllow(e)}
                          name="name"
                          type="text"
                          className={
                            `form-control form-control-md rounded-pill` +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                          id="staticEmail"
                        />
                        <ErrorMessage
                          name={`name`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="form-group col-12 mb-4">
                      <label htmlFor="features" className="mb-2 px-3">
                        Features{" "}
                      </label>{" "}
                      <FieldArray name="features">
                        {" "}
                        {(fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps;
                          const { values } = form;
                          const { features } = values;
                          return (
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <table className="table border-0">
                                <thead>
                                  <tr>
                                    <th> S.No </th> <th> </th>
                                    <th>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <label
                                          className="btn btn-remove"
                                          onClick={() => push("")}
                                        >
                                          Add More +
                                        </label>{" "}
                                      </div>{" "}
                                    </th>{" "}
                                  </tr>
                                  {features &&
                                    features.length > 0 &&
                                    features.map((priceField, index) => (
                                      <tr key={index}>
                                        <td className="mt-2"> {index + 1} </td>{" "}
                                        <td key={index} colSpan={2}>
                                          <Field
                                            name={`features.${index}.name`}
                                            className="form-control mr-4"
                                            placeholder="Name"
                                          />
                                        </td>{" "}
                                        {/* <td key={`priceindex${index}`}>
                                                                                                  <Field
                                                                                                    name={`features[${index}][value]`}
                                                                                                    className="form-control"
                                                                                                    placeholder="Value"

                                                                                                  />
                                                                                                 
                                                                                                </td> */}
                                        <td key={`deleteicon${index}`}>
                                          {" "}
                                          {/* <i className="fas fa-trash" style={{ color: "tomato", cursor: 'pointer' }} onClick={() => remove(index)}></i> */}{" "}
                                          <i
                                            className="fas fa-trash mt-2"
                                            style={{
                                              color: "tomato",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              remove(index);
                                            }}
                                          ></i>{" "}
                                        </td>{" "}
                                      </tr>
                                    ))}{" "}
                                </thead>{" "}
                              </table>{" "}
                            </div>
                          );
                        }}{" "}
                      </FieldArray>{" "}
                    </div>
                    <div className="row pt-1">
                      <div className="col-auto">
                        <button
                          type="submit"
                          disabled={data.isPending}
                          className="btn btn-deep-blue toggle-class py-2"
                        >
                          Update{" "}
                          {data.isPending ? (
                            <Spinner animation="border" />
                          ) : null}{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>{" "}
                  </Form>
                );
              }}
            />{" "}
          </div>{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default EditServiceSubCategory;
