import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  filterByPage,
  updateFilterOptionById,
  responseFilter,
} from "../../store/action/filterAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchWarehouseFilter } from "../../store/action/warehouseAction";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";
import FormSuccess from "../../components/common/FormSuccess";

const UpdateFilterOption = () => {
  const { filterId, filterOptionId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectWare, setSelectWare] = useState(null);
  const [sendWarehouse, setSendWarehouse] = useState(null);

  const warehouses = useSelector((state) => state.WAREHOUSEINFO);
  const data = useSelector((state) => state.FILTER_INFO);

  const filterSchema = Yup.object().shape({
    filterOption: Yup.string().required("Required"),
  });

  const handleChange = (selectedOption) => {
    setSendWarehouse(selectedOption);
  };

  useEffect(() => {
    dispatch(filterByPage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchWarehouseFilter(1));
  }, [dispatch]);

  useEffect(() => {
    if (
      warehouses.listOfWarehouse.data &&
      warehouses.listOfWarehouse.data !== null
    ) {
      let whList = [];
      for (let i = 0; i < warehouses.listOfWarehouse.data?.length; i++) {
        whList.push({
          value: warehouses.listOfWarehouse.data[i]["id"],
          label: warehouses.listOfWarehouse.data[i]["warehouseName"],
          id: warehouses.listOfWarehouse.data[i]["id"],
          isOpenRfq: true,
        });
      }
      setSelectWare(whList);
    }
  }, [warehouses.listOfWarehouse.data]);

  // redirect url
  const redirect = () => {
    dispatch(responseFilter([]));
    return history.push(`/filter-option/${filterId}`);
  };

  return (
    <LayoutOne>
      {" "}
      {data.filterResponse &&
      data.filterResponse.message === "Filter Options created successfully" ? (
        <FormSuccess onClick={redirect} message={data.filterResponse.message} />
      ) : null}{" "}
      <div className="row align-items-center py-3">
        <div className="col-12 py-3 mt-4">
          <span>
            {" "}
            <h5 className="text-dark-blue">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-3 cursorPointer"
              >
                {" "}
              </i>{" "}
              Update Filter Option
            </h5>{" "}
          </span>{" "}
        </div>{" "}
      </div>{" "}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            validationSchema={filterSchema}
            initialValues={{
              filterOption: "",
            }}
            onSubmit={(fields) => {
              if (sendWarehouse) {
                let whList = [];
                console.log("sendWarehouse-->", sendWarehouse);
                for (let i = 0; i < sendWarehouse.length; i++) {
                  whList.push(sendWarehouse[i].id);
                }
                console.log("whList-->", whList);
                if (whList && whList.length > 0 && filterId && filterOptionId) {
                  fields["warehouseId"] = whList;
                  console.log("Fields==>", fields);
                  dispatch(
                    updateFilterOptionById(filterId, filterOptionId, fields)
                  );
                }
              }
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label for="staticEmail" className="mb-2 pl-3">
                        {" "}
                        Filter Option Name{" "}
                      </label>{" "}
                      <Field
                        name="filterOption"
                        type="text"
                        className={
                          `form-control form-control-md px-4` +
                          (errors.filterOption && touched.filterOption
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`filterOption`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label for="staticEmail" className="mb-2 pl-3">
                        {" "}
                        Warehouse Name{" "}
                      </label>

                      <Select
                        // value={locationHouse}
                        onChange={handleChange}
                        options={selectWare}
                        isMulti
                      />
                    </div>{" "}
                  </div>
                  <div className="row pt-4">
                    <div className="col-auto">
                      <button
                        disabled={data.isPending}
                        type="submit"
                        className="btn btn-deep-blue"
                      >
                        Update Filter Option{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default UpdateFilterOption;
