import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import {
  changeWarehouseStatus1,
  updateWarehousependingDetails,
} from './../../../store/action/warehouseAction';
import FormSuccess from '../../../components/common/FormSuccess';
import CustomLoader from '../../../CustomLoader';

const PermitPendingForm = ({ warehouseId, read, slug }) => {
  const history = useHistory();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const [remarkForm, setRemarkForm] = useState({
    tradePermit: {
      gstRegistrationCertificate: {
        value: '',
        whsremark: '',
      },
      panCardOfCompany: {
        value: '',
        whsremark: '',
      },
      electricityBill: {
        value: '',
        whsremark: '',
      },
      shopAndEstablishmentCertificate: {
        value: '',
        whsremark: '',
      },
      tradeRelatedPermit: [
        {
          type: 'Panchayat / Municipal NOC',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Trade License',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Labor License',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Factory License (for VAS activities)',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'FSSAI License',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Pollution Under Control(PUC)',
          trade: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
      ],
    },
    buildingPermit: {
      ownershipDocument: {
        value: '',
        whsremark: '',
      },
      cluCommercialWarehousing: {
        value: '',
        whsremark: '',
      },
      completionCertificate: {
        value: '',
        whsremark: '',
      },
      occupancyCertificate: {
        value: '',
        whsremark: '',
      },

      approvedBuildingLayout: {
        value: '',
        whsremark: '',
      },
      warehousePhotoGallery: {
        value: '',
        whsremark: '',
      },
      warehouseProfilePic: {
        value: '',
        whsremark: '',
      },
      buildingRelatedPermit: [
        {
          type: 'Copy Of Lease Agreement With Landlord',
          building: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Building Insurance',
          building: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Building Stability Certificate',
          building: {
            value: {
              validfromfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Fire NOC',
          building: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
        {
          type: 'Floor Load Bearing Capacity Certificate',
          building: {
            value: {
              validfrom: '',
              validtill: '',
            },
            whsremark: '',
          },
        },
      ],
    },
  });

  const [yesForm, setYesForm] = useState({
    landlordYes: true,
    ownershipYes: true,
    cluYes: true,
    completionYes: true,
    occupancyYes: true,
    buildingInsuranceYes: true,
    buildingStabilityYes: true,
    FireNOCYes: true,
    floorLoadYes: true,
    approvedBuildingYes: true,
    gstRegistrationYes: true,
    panchayatNOCYes: true,
    panCardYes: true,
    electricityBillYes: true,
    shopCertificateYes: true,
    tradeLicenseYes: true,
    laborLicenseYes: true,
    factoryLicenseYes: true,
    fssaiLicenseYes: true,
    pollutionPollutionYes: true,
  });

  const {
    landlordYes,
    ownershipYes,
    cluYes,
    completionYes,
    occupancyYes,
    buildingInsuranceYes,
    buildingStabilityYes,
    FireNOCYes,
    floorLoadYes,
    approvedBuildingYes,
    gstRegistrationYes,
    panchayatNOCYes,
    panCardYes,
    electricityBillYes,
    shopCertificateYes,
    tradeLicenseYes,
    laborLicenseYes,
    factoryLicenseYes,
    fssaiLicenseYes,
    pollutionPollutionYes,
  } = yesForm;

  const [permitForm, setPermitForm] = useState({
    landlordFrom: '',
    landlordTill: '',
    landlordFile: '',
    landlordFileLink: '',
    ownershipFile: '',
    ownershipFileLink: '',
    cluFile: '',
    cluFileLink: '',
    completionFile: '',
    completionFileLink: '',
    occupancyFile: '',
    occupancyFileLink: '',

    buildingInsuranceFrom: '',
    buildingInsuranceTill: '',
    buildingInsuranceFile: '',
    buildingInsuranceFileLink: '',

    buildingStabilityFrom: '',
    buildingStabilityTill: '',
    buildingStabilityFile: '',
    buildingStabilityFileLink: '',

    FireNOCFrom: '',
    FireNOCTill: '',
    FireNOCFile: '',
    FireNOCFileLink: '',

    floorLoadFrom: '',
    floorLoadTill: '',
    floorLoadFile: '',
    floorLoadFileLink: '',

    approvedBuildingFile: '',
    approvedBuildingFileLink: '',

    photoFile: '',
    photoFileLink: '',

    profileFile: '',
    profileFileLink: '',

    gstRegistrationFile: '',
    gstRegistrationFileLink: '',

    panchayatNOCFrom: '',
    panchayatNOCTill: '',
    panchayatNOCFile: '',
    panchayatNOCFileLink: '',

    panCardFile: '',
    panCardFileLink: '',

    electricityBillFile: '',
    electricityBillFileLink: '',

    shopCertificateFile: '',
    shopCertificateFileLink: '',

    tradeLicenseFrom: '',
    tradeLicenseTill: '',
    tradeLicenseFile: '',
    tradeLicenseFileLink: '',

    laborLicenseFrom: '',
    laborLicenseTill: '',
    laborLicenseFile: '',
    laborLicenseFileLink: '',

    factoryLicenseFrom: '',
    factoryLicenseTill: '',
    factoryLicenseFile: '',
    factoryLicenseFileLink: '',

    fssaiLicenseFrom: '',
    fssaiLicenseTill: '',
    fssaiLicenseFile: '',
    fssaiLicenseFileLink: '',

    pollutionPollutionFrom: '',
    pollutionPollutionTill: '',
    pollutionPollutionFile: '',
    pollutionPollutionFileLink: '',
  });

  const {
    landlordFrom,
    landlordTill,
    landlordFileLink,
    ownershipFileLink,
    cluFileLink,
    completionFileLink,
    occupancyFileLink,

    buildingInsuranceFrom,
    buildingInsuranceTill,
    buildingInsuranceFileLink,

    buildingStabilityFrom,
    buildingStabilityTill,
    buildingStabilityFileLink,

    FireNOCFrom,
    FireNOCTill,
    FireNOCFileLink,

    floorLoadFrom,
    floorLoadTill,
    floorLoadFileLink,

    approvedBuildingFileLink,

    photoFileLink,

    profileFileLink,

    gstRegistrationFileLink,

    panchayatNOCFrom,
    panchayatNOCTill,
    panchayatNOCFileLink,

    panCardFileLink,

    electricityBillFileLink,

    shopCertificateFileLink,

    tradeLicenseFrom,
    tradeLicenseTill,
    tradeLicenseFileLink,

    laborLicenseFrom,
    laborLicenseTill,
    laborLicenseFileLink,

    factoryLicenseFrom,
    factoryLicenseTill,
    factoryLicenseFileLink,

    fssaiLicenseFrom,
    fssaiLicenseTill,
    fssaiLicenseFileLink,

    pollutionPollutionFrom,
    pollutionPollutionTill,
    pollutionPollutionFileLink,
  } = permitForm;
  // eslint-disable-next-line

  const onSubmit = (fields) => {
    fields.landlordFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.landlordFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.landlordFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[1]?.filePath
        : '';
    fields.ownershipFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit?.ownershipDocument?.value
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit?.ownershipDocument
        : '';
    fields.completionFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.completionFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.completionFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.completionCertificate
        : '';
    fields.laborLicenseFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.laborLicenseFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.laborLicenseFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[1]
            ?.filePath
        : '';

    fields.electricityBillFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.electricityBillFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.electricityBillFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.electricityBill
        : '';

    fields.panCardFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.panCardFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.panCardFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.panCardOfCompany
        : '';

    fields.cluFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.cluFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.cluFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.cluCommercialWarehousing
        : '';

    fields.occupancyFileLink =
      data?.singleFormData?.warehouseRes !== null &&
      data?.singleFormData?.warehouseRes !== undefined &&
      data?.singleFormData?.warehouseRes?.permits !== undefined &&
      data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.occupancyCertificate
        : '';

    fields.buildingInsuranceFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.buildingInsuranceFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.buildingInsuranceFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[0]?.filePath
        : '';

    fields.buildingStabilityFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.buildingStabilityFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.buildingStabilityFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[3]?.filePath
        : '';

    fields.floorLoadFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.floorLoadFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.floorLoadFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.buildingRelatedPermitInfo[5]?.filePath
        : '';

    fields.approvedBuildingFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.approvedBuildingFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.approvedBuildingFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.approvedBuildingLayout
        : '';

    fields.photoFileLink =
      data?.singleFormData?.warehouseRes !== null &&
      data?.singleFormData?.warehouseRes !== undefined &&
      data?.singleFormData?.warehouseRes?.permits !== undefined &&
      data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
            ?.warehousePhotoGallery
        : '';

    fields.profileFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.profileFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.profileFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit?.warehouseProfilePic
        : '';

    fields.gstRegistrationFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.gstRegistrationFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.gstRegistrationFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.gstRegistrationCertificate
        : '';

    fields.panchayatNOCFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.panchayatNOCFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.panchayatNOCFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[0]
            ?.filePath
        : '';

    fields.shopCertificateFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.shopCertificateFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.shopCertificateFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
            ?.shopAndEstablishmentCertificate
        : '';

    fields.tradeLicenseFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.tradeLicenseFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.tradeLicenseFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[2]
            ?.filePath
        : '';

    fields.factoryLicenseFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.factoryLicenseFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.factoryLicenseFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[3]
            ?.filePath
        : '';

    fields.fssaiLicenseFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.fssaiLicenseFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.fssaiLicenseFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[4]
            ?.filePath
        : '';

    fields.pollutionPollutionFileLink =
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      Object.keys(
        data?.singleFormData?.remarks?.permitsRemarks !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks
          : {},
      )?.length > 0
        ? data?.singleFormData?.remarks?.permitsRemarks?.pollutionPollutionFileLink !== undefined
          ? data?.singleFormData?.remarks?.permitsRemarks?.pollutionPollutionFileLink
          : ''
        : data?.singleFormData?.warehouseRes !== null &&
          data?.singleFormData?.warehouseRes !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== undefined &&
          data?.singleFormData?.warehouseRes?.permits !== null
        ? data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated?.tradeRelatedPermitInfo[5]
            ?.filePath
        : '';
    const payload = {
      data: {
        fields: fields,
      },

      warehouseId: warehouseId,
      slug: slug,
    };
    dispatch(updateWarehousependingDetails(payload));
  };

  useEffect(() => {
    if (
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      data?.singleFormData?.remarks?.permitsRemarks !== undefined &&
      Object.keys(data?.singleFormData?.remarks?.permitsRemarks).length > 0
    ) {
      setRemarkForm(data?.singleFormData?.remarks?.permitsRemarks);
    } else {
      if (
        data?.singleFormData?.warehouseRes !== null &&
        data?.singleFormData?.warehouseRes !== undefined &&
        data?.singleFormData?.warehouseRes?.permits !== undefined &&
        data?.singleFormData?.warehouseRes?.permits !== null
      ) {
        if (Object.keys(data?.singleFormData?.warehouseRes?.permits).length > 0) {
          setRemarkForm({
            tradePermit: {
              gstRegistrationCertificate: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.tradePermit
                    ?.gstRegistrationCertificate?.whsremark,
              },
              panCardOfCompany: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.tradePermit?.panCardOfCompany
                    .whsremark,
              },
              electricityBill: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.tradePermit?.electricityBill
                    .whsremark,
              },
              shopAndEstablishmentCertificate: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.tradePermit
                    ?.shopAndEstablishmentCertificate?.whsremark,
              },
              tradeRelatedPermit: [
                {
                  type: 'Panchayat / Municipal NOC',
                  trade: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[0]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[0]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.tradePermit
                        ?.tradeRelatedPermit[0]?.trade?.whsremark,
                  },
                },
                {
                  type: 'Trade License',
                  trade: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[2]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[2]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.tradePermit
                        ?.tradeRelatedPermit[1]?.trade?.whsremark,
                  },
                },
                {
                  type: 'Labor License',
                  trade: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[1]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[1]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.tradePermit
                        ?.tradeRelatedPermit[2]?.trade?.whsremark,
                  },
                },
                {
                  type: 'Factory License (for VAS activities)',
                  trade: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[3]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[3]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.tradePermit
                        ?.tradeRelatedPermit[3]?.trade?.whsremark,
                  },
                },
                {
                  type: 'FSSAI License',
                  trade: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[4]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[4]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.tradePermit
                        ?.tradeRelatedPermit[4]?.trade?.whsremark,
                  },
                },
                {
                  type: 'Pollution Under Control(PUC)',
                  trade: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[5]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.tradeRelated
                          ?.tradeRelatedPermitInfo[5]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.tradePermit
                        ?.tradeRelatedPermit[5]?.trade?.whsremark,
                  },
                },
              ],
            },
            buildingPermit: {
              ownershipDocument: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit?.ownershipDocument
                    ?.whsremark,
              },
              cluCommercialWarehousing: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                    ?.cluCommercialWarehousing?.whsremark,
              },
              completionCertificate: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                    ?.completionCertificate?.whsremark,
              },
              occupancyCertificate: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                    ?.occupancyCertificate?.whsremark,
              },

              approvedBuildingLayout: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                    ?.approvedBuildingLayout?.whsremark,
              },
              warehousePhotoGallery: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                    ?.warehousePhotoGallery?.whsremark,
              },
              warehouseProfilePic: {
                value: '',
                whsremark:
                  data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit?.warehouseProfilePic
                    ?.whsremark,
              },
              buildingRelatedPermit: [
                {
                  type: 'Copy Of Lease Agreement With Landlord',
                  building: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[1]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[1]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                        ?.buildingRelatedPermit[0]?.building?.whsremark,
                  },
                },
                {
                  type: 'Building Insurance',
                  building: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[0]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[0]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                        ?.buildingRelatedPermit[1]?.building?.whsremark,
                  },
                },
                {
                  type: 'Building Stability Certificate',
                  building: {
                    value: {
                      validfromfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[3]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[3]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                        ?.buildingRelatedPermit[2]?.building?.whsremark,
                  },
                },
                {
                  type: 'Fire NOC',
                  building: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[4]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[4]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                        ?.buildingRelatedPermit[3]?.building?.whsremark,
                  },
                },
                {
                  type: 'Floor Load Bearing Capacity Certificate',
                  building: {
                    value: {
                      validfrom:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[5]?.validForm,
                      validtill:
                        data?.singleFormData?.warehouseRes?.permits[0]?.buildingRelatedPermit
                          ?.buildingRelatedPermitInfo[5]?.validTill,
                    },
                    whsremark:
                      data?.singleFormData?.remarks?.permitsRemarks?.buildingPermit
                        ?.buildingRelatedPermit[4]?.building?.whsremark,
                  },
                },
              ],
            },
          });
        }
      }
    }
  }, [data?.singleFormData]);

  // const handleNextPage = () => {
  //   if (data?.addNewResponse && data?.addNewResponse?.statusCode === 200) {
  //     history.push(`/pending-details/${warehouseId}/material-type`);
  //     dispatch(changeWarehouseStatus1());
  //   }
  // };

  return (
    <>
      {/* {data?.addNewResponse && data?.addNewResponse?.statusCode === 200 ? (
        <FormSuccess onClick={handleNextPage} message={data.addNewResponse.message} />
      ) : null}{' '} */}
      {data.isLoading === true ? (
        <CustomLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={remarkForm}
          // validate={validate(validationSchema)}
          onSubmit={(fields) => onSubmit(fields)}
          render={({ errors, status, onChange, touched, values, setFieldValue }) => {
            return (
              <Form>
                <div className='col-12 rounded-lg px-md-4'>
                  <div className='row'>
                    <div className='col-12'>
                      <h6 className='py-3 mb-3 border-bottom'>Building Related </h6>{' '}
                    </div>{' '}
                    <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Copy Of Lease Agreement With Landlord:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {landlordYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <td className='pb-4 d-inline-flex'>
                                        <label
                                          htmlFor=''
                                          className='mb-0 px-4 small text-uppercase'
                                        >
                                          {/* Valid till{' '} */}
                                        </label>{' '}
                                        <Field
                                          disabled={read}
                                          name={`buildingPermit.buildingRelatedPermit[0].building.value.validfrom`}
                                          type='date'
                                          className='form-control bg-white px-4 mb-2 mr-4'
                                          placeholder='value'
                                        />
                                        <Field
                                          disabled={read}
                                          name={`buildingPermit.buildingRelatedPermit[0].building.value.validtill`}
                                          type='date'
                                          className='form-control bg-white px-4 mb-2 mr-4'
                                          placeholder='value'
                                        />
                                      </td>{' '}
                                      {(data?.singleFormData?.remarks !== undefined &&
                                        data?.singleFormData?.remarks !== null &&
                                        data?.singleFormData?.remarks?.permitsRemarks !==
                                          undefined &&
                                        data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                        Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                          .length > 0) ||
                                      (data?.singleFormData?.warehouseRes !== undefined &&
                                        data?.singleFormData?.warehouseRes !== null &&
                                        data?.singleFormData?.warehouseRes?.permits !== undefined &&
                                        data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                        <a
                                          href={
                                            data?.singleFormData?.remarks?.permitsRemarks
                                              ?.landlordFileLink
                                              ? data?.singleFormData?.remarks?.permitsRemarks
                                                  ?.landlordFileLink
                                              : data?.singleFormData?.warehouseRes?.permits !==
                                                  undefined &&
                                                data?.singleFormData?.warehouseRes?.permits !== null
                                              ? data?.singleFormData?.warehouseRes?.permits[0]
                                                  ?.buildingRelatedPermit
                                                  ?.buildingRelatedPermitInfo[1]?.filePath
                                              : ''
                                          }
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          className='btn px-3 py-2 btn-deep-blue'
                                          download=''
                                        >
                                          <i className='fas fa-eye'> </i>{' '}
                                        </a>
                                      ) : (
                                        ''
                                      )}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.buildingRelatedPermit[0].building.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Ownership Document Copy:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {ownershipYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.ownershipFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit?.ownershipDocument
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.ownershipDocument.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        CLU-Commercial / Warehousing:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {cluYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.cluFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit
                                                    ?.cluCommercialWarehousing
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.cluCommercialWarehousing.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Completion Certificate:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {completionYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.completionFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit?.completionCertificate
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.completionCertificate.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Building Insurance:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {buildingInsuranceYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4 d-inline-flex'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`buildingPermit.buildingRelatedPermit[1].building.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                          <Field
                                            disabled={false}
                                            name={`buildingPermit.buildingRelatedPermit[1].building.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.buildingInsuranceFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit
                                                    ?.buildingRelatedPermitInfo[0]?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.buildingRelatedPermit[1].building.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Building Stability Certificate:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {buildingStabilityYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4 d-inline-flex'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={read}
                                            name={`buildingPermit.buildingRelatedPermit[2].building.value.validfromfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                          <Field
                                            disabled={read}
                                            name={`buildingPermit.buildingRelatedPermit[2].building.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.buildingStabilityFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit
                                                    ?.buildingRelatedPermitInfo[3]?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.buildingRelatedPermit[2].building.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Fire NOC:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {FireNOCYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4 d-inline-flex'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={read}
                                            name={`buildingPermit.buildingRelatedPermit[3].building.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                          <Field
                                            disabled={read}
                                            name={`buildingPermit.buildingRelatedPermit[3].building.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.FireNOCFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit
                                                    ?.buildingRelatedPermitInfo[4]?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.buildingRelatedPermit[3].building.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Floor Load Bearing Capacity Certificate:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {floorLoadYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4 d-inline-flex'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`buildingPermit.buildingRelatedPermit[4].building.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                          <Field
                                            disabled={read}
                                            name={`buildingPermit.buildingRelatedPermit[4].building.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.floorLoadFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit
                                                    ?.buildingRelatedPermitInfo[5]?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.buildingRelatedPermit[4].building.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Approved Building Layout Copy:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {approvedBuildingYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.approvedBuildingFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit?.approvedBuildingLayout
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.approvedBuildingLayout.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      {/* <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Warehouse Photo Gallery:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p>
                                        {' '}
                                        <td className='text-center text-nowrape d-flex'>
                                          <div className='btn btn-group px-0 mt-1'>
                                            <a
                                              href={
                                                data?.singleFormData?.remarks !== null &&
                                                data?.singleFormData?.remarks !== undefined &&
                                                Object.keys(
                                                  data?.singleFormData?.remarks?.permitsRemarks !==
                                                    undefined
                                                    ? data?.singleFormData?.remarks?.permitsRemarks
                                                    : {},
                                                )?.length > 0
                                                  ? data?.singleFormData?.remarks?.permitsRemarks
                                                      ?.buildingPermit?.warehousePhotoGallery?.value
                                                  : data?.singleFormData?.warehouseRes !== null &&
                                                    data?.singleFormData?.warehouseRes !==
                                                      undefined &&
                                                    data?.singleFormData?.warehouseRes?.permits !==
                                                      undefined &&
                                                    data?.singleFormData?.warehouseRes?.permits !==
                                                      null
                                                  ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit?.warehousePhotoGallery
                                                  : ''
                                              }
                                              target='_blank'
                                              rel='noopener noreferrer'
                                              className='btn px-3 py-2 btn-deep-blue'
                                              download=''
                                            >
                                              <i className='fas fa-eye'> </i>{' '}
                                            </a>{' '}
                                          </div>{' '}
                                        </td>{' '}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.warehousePhotoGallery.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div> */}
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Warehouse Profile Pic:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.profileFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.buildingRelatedPermit?.warehouseProfilePic
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`buildingPermit.warehouseProfilePic.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                    </div>{' '}
                  </div>{' '}
                  <div className='row'>
                    <div className='col-12'>
                      <h6 className='py-3 mb-3 border-bottom'> Trade Related </h6>{' '}
                    </div>{' '}
                    <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        GST Registration Certificate:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {ownershipYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.gstRegistrationFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.gstRegistrationCertificate
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`tradePermit.gstRegistrationCertificate.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Panchayat / Municipal NOC:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {panchayatNOCYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4 d-inline-flex'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            name={`tradePermit.tradeRelatedPermit[0].trade.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                          <Field
                                            name={`tradePermit.tradeRelatedPermit[0].trade.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.panchayatNOCFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.tradeRelatedPermitInfo[0]
                                                    ?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        name={`tradePermit.tradeRelatedPermit[0].trade.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        PAN Card copy of Company:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {panCardYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.panCardFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.panCardFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.panCardOfCompany
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table className='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        name={`tradePermit.panCardOfCompany.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Electricity Bill Copy:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {electricityBillYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.electricityBillFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.electricityBillFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.electricityBill
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`tradePermit.electricityBill.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Shop & amp; Establishment Certificate:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {shopCertificateYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        {' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.shopCertificateFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.shopAndEstablishmentCertificate
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`tradePermit.shopAndEstablishmentCertificate.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Trade License:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {tradeLicenseYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4 d-inline-flex'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={read}
                                            name={`tradePermit.tradeRelatedPermit[1].trade.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                          <Field
                                            disabled={read}
                                            name={`tradePermit.tradeRelatedPermit[1].trade.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.tradeLicenseFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.tradeRelatedPermitInfo[2]
                                                    ?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`tradePermit.tradeRelatedPermit[1].trade.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Labor License:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {laborLicenseYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid from{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`tradePermit.tradeRelatedPermit[2].trade.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`tradePermit.tradeRelatedPermit[2].trade.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.laborLicenseFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.tradeRelatedPermitInfo[1]
                                                    ?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`tradePermit.tradeRelatedPermit[2].trade.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Factory License( for VAS activities):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {factoryLicenseYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid from{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`tradePermit.tradeRelatedPermit[3].trade.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={false}
                                            name={`tradePermit.tradeRelatedPermit[3].trade.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.factoryLicenseFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.tradeRelatedPermitInfo[3]
                                                    ?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`tradePermit.tradeRelatedPermit[3].trade.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        FSSAI License:
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {fssaiLicenseYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid from{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={read}
                                            name={`tradePermit.tradeRelatedPermit[4].trade.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={read}
                                            name={`tradePermit.tradeRelatedPermit[4].trade.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.fssaiLicenseFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.tradeRelatedPermitInfo[4]
                                                    ?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`tradePermit.tradeRelatedPermit[4].trade.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>
                      <div className='col-xl-12'>
                        <table class='table table-borderless mb-0 '>
                          <thead>
                            <tr>
                              <th scope='col' class='col-lg-8'>
                                {' '}
                              </th>{' '}
                              <th scope='col'> </th>{' '}
                              <th
                                scope='col'
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              ></th>{' '}
                              <th scope='col' className='text-center'>
                                {' '}
                              </th>{' '}
                            </tr>{' '}
                          </thead>
                          <tbody>
                            <tr>
                              <td className='py-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='py-0'>
                                      <label htmlFor='staticEmail' className=' px-2'>
                                        Pollution Under Control(PUC):
                                      </label>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                  <tr>
                                    <td className=' pb-0 d-flex align-items-center'>
                                      <p className='text-gray py-3 mb-0 px-2'>
                                        {' '}
                                        {pollutionPollutionYes ? 'Yes' : 'No'}{' '}
                                      </p>{' '}
                                      <p>
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            Valid from{' '}
                                          </label>{' '}
                                          <Field
                                            disabled={read}
                                            name={`tradePermit.tradeRelatedPermit[5].trade.value.validfrom`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        <td className='pb-4'>
                                          <label
                                            htmlFor=''
                                            className='mb-0 px-4 small text-uppercase'
                                          >
                                            {/* Valid till{' '} */}
                                          </label>{' '}
                                          <Field
                                            disabled={read}
                                            name={`tradePermit.tradeRelatedPermit[5].trade.value.validtill`}
                                            type='date'
                                            className='form-control bg-white px-4 mb-2 mr-4'
                                            placeholder='value'
                                          />
                                        </td>{' '}
                                        {(data?.singleFormData?.remarks !== undefined &&
                                          data?.singleFormData?.remarks !== null &&
                                          data?.singleFormData?.remarks?.permitsRemarks !==
                                            undefined &&
                                          data?.singleFormData?.remarks?.permitsRemarks !== null &&
                                          Object.keys(data?.singleFormData?.remarks?.permitsRemarks)
                                            .length > 0) ||
                                        (data?.singleFormData?.warehouseRes !== undefined &&
                                          data?.singleFormData?.warehouseRes !== null &&
                                          data?.singleFormData?.warehouseRes?.permits !==
                                            undefined &&
                                          data?.singleFormData?.warehouseRes?.permits !== null) ? (
                                          <a
                                            href={
                                              data?.singleFormData?.remarks?.permitsRemarks
                                                ?.landlordFileLink
                                                ? data?.singleFormData?.remarks?.permitsRemarks
                                                    ?.pollutionPollutionFileLink
                                                : data?.singleFormData?.warehouseRes?.permits !==
                                                    undefined &&
                                                  data?.singleFormData?.warehouseRes?.permits !==
                                                    null
                                                ? data?.singleFormData?.warehouseRes?.permits[0]
                                                    ?.tradeRelated?.tradeRelatedPermitInfo[5]
                                                    ?.filePath
                                                : ''
                                            }
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='btn px-3 py-2 btn-deep-blue'
                                            download=''
                                          >
                                            <i className='fas fa-eye'> </i>{' '}
                                          </a>
                                        ) : (
                                          ''
                                        )}
                                      </p>{' '}
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                              <td className='pb-0'>
                                <table class='table table-borderless form-group col-12 pt-xl-1'>
                                  <tr>
                                    <td className='pb-0 pt-3'>
                                      <Field
                                        disabled={read}
                                        name={`tradePermit.tradeRelatedPermit[5].trade.whsremark`}
                                        type='text'
                                        className='form-control bg-white px-4 mb-2 mr-4'
                                        placeholder='Remarks'
                                      />
                                    </td>{' '}
                                  </tr>{' '}
                                </table>{' '}
                              </td>{' '}
                            </tr>{' '}
                          </tbody>{' '}
                        </table>{' '}
                      </div>{' '}
                    </div>{' '}
                  </div>{' '}
                </div>
                <div className={`col-12 mt-2`}>
                  <div className={`row justify-content-end mb-4 ${!read ? '' : 'd-none'}`}>
                    <div className='col-auto '>
                      {Object.keys(touched).length > 0 ? (
                        <button
                          type='submit'
                          className='btn btn-deep-blue add-class remove-class '
                          disabled={data.isPending}
                        >
                          Save
                        </button>
                      ) : (
                        ''
                      )}{' '}
                    </div>{' '}
                  </div>{' '}
                </div>{' '}
              </Form>
            );
          }}
        />
      )}
    </>
  );
};

export default PermitPendingForm;
