import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Link, useHistory } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import BrouserTitle from "../../components/common/BrouserTitle";
import { useDispatch, useSelector } from "react-redux";
import { vendorUserByPage } from "../../store/action/userAction";
import { CardLoader } from "../../components/helper/CustomLoader";
import VendorUserList from "../../wrapper/userManagement/VendorUserList";
import Pagination from "react-js-pagination";
import LinkButton from "../../components/common/LinkButton";

const Vendor = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.USER_INFO);
  const pageCount = new URLSearchParams(window.location.search).get("page");
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);

  useEffect(() => {
    dispatch(vendorUserByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const callSearch = () => {
    dispatch(vendorUserByPage(pageCount, searchHandler));
  };

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(vendorUserByPage(pageNumber, searchHandler));
  };

  return (
    <LayoutOne>
      <BrouserTitle title="Vendor" />
      <div className="row align-items-center px-3 mx-0 mt-3">
        <div className="row bg-white justify-content-between w-100 mx-0">
          <div className="col-12 col-sm-12 col-md-12 col-xl">
            <div className="row align-items-center">
              <div className="col-auto px-4 order-2">
                <div className="row">
                  <div className="col-auto d-flex h-100 px-2">
                    <div className="dropdown btn-export">
                      <button
                        className="custom-btn btn-deep-blue dropdown-toggle"
                        type="button"
                        id="dropdownMenu5"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Export{" "}
                      </button>{" "}
                      <div className="dropdown-menu">
                        <a className="dropdown-item text-center" href="#!">
                          {" "}
                          Excel{" "}
                        </a>{" "}
                        <div className="dropdown-divider"> </div>{" "}
                        <a className="dropdown-item text-center" href="#!">
                          {" "}
                          PDF{" "}
                        </a>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <LinkButton link={`/add-vendor`} label="Add New Vendor" />
                </div>{" "}
              </div>{" "}
              <div className="tabs-scroll col-auto">
                <ul className="nav nav-pills admin-tabs-blue">
                  <li className="nav-item">
                    <Link
                      to="/vendor?page=1"
                      className="nav-link active_link text-uppercase"
                    >
                      {" "}
                      Vendors{" "}
                    </Link>{" "}
                  </li>{" "}
                  <li className="nav-item">
                    <Link
                      to="/customer?page=1"
                      className="nav-link text-uppercase"
                    >
                      {" "}
                      Customers{" "}
                    </Link>{" "}
                  </li>{" "}
                </ul>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
          <div className="col-12 col-sm-12 col-md-12 col-xl-auto">
            <SearchBox
              callSearch={callSearch}
              setSearchHandler={setSearchHandler}
              border={true}
            />{" "}
          </div>{" "}
        </div>
        <div className="col-12 px-0 pt-3">
          <div className="row align-items-center px-3 mx-0">
            <div className="col-12 px-0">
              {" "}
              {user.isLoading ? (
                <CardLoader />
              ) : (
                <div className="row">
                  <div className="col-12 px-0 table-responsive table-gray-admin bg-white p-3">
                    {" "}
                    {user.userList?.data && user.userList?.data?.length > 0 ? (
                      <table className="table table-dark-custom">
                        <thead>
                          <tr>
                            <th className="text-nowrap px-2"> Sr.No. </th>{" "}
                            <th className="text-nowrap"> Vendor Name </th>{" "}
                            <th className="text-nowrap"> Company Name </th>{" "}
                            <th className="text-nowrap"> Vendor ID </th>{" "}
                            <th> Email ID </th> <th> Contact No. </th>{" "}
                            <th> Status </th> <th className="text-center"> </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          {user.userList.data.map((item, index) => {
                            return (
                              <VendorUserList
                                data={item.owner}
                                index={
                                  ((pageCount ? pageCount : 1) - 1) * 10 +
                                  (index + 1)
                                }
                                key={index}
                                read={read}
                              />
                            );
                          })}
                        </tbody>{" "}
                      </table>
                    ) : null}{" "}
                  </div>{" "}
                </div>
              )}{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="pagination-custom">
          <Pagination
            activePage={parseInt(pageCount)}
            itemsCountPerPage={10}
            totalItemsCount={user.userList.totalCount}
            pageRangeDisplayed={1}
            onChange={handlePageChange}
            prevPageText={<i className="fas fa-chevron-left" />}
            nextPageText={<i className="fas fa-chevron-right" />}
            hideFirstLastPages={true}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default Vendor;
