import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AdditionalRequirementsForm from "../../wrapper/rfq/customerDetail/AdditionalRequirementsForm";
import KPIForm from "../../wrapper/rfq/customerDetail/KPIForm";
import OtherServiceRequirementForm from "../../wrapper/rfq/customerDetail/OtherServiceRequirementForm";
import ValueAddedServiceForm from "../../wrapper/rfq/customerDetail/ValueAddedServiceForm";
import StorageTypeForm from "../../wrapper/rfq/customerDetail/StorageTypeForm";
import InventoryManagementForm from "../../wrapper/rfq/customerDetail/InventoryManagementForm";
import ReturnForm from "../../wrapper/rfq/customerDetail/ReturnForm";
import ManPowerForm from "../../wrapper/rfq/customerDetail/ManPowerForm";
import OutBoundForm from "../../wrapper/rfq/customerDetail/OutBoundForm";
import InBoundForm from "../../wrapper/rfq/customerDetail/InBoundForm";
import GeneralForm from "../../wrapper/rfq/customerDetail/GeneralForm";
import RFQForm from "../../wrapper/rfq/customerDetail/RFQForm";
import ContactInformationForm from "../../wrapper/rfq/customerDetail/ContactInformationForm";
import { rfqByIdAndType } from "../../store/action/rfqAction";
import { useDispatch } from "react-redux";
//================================================

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: "5px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    padding: "15px",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const CustomerRFQDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { customerRfqId } = useParams();

  useEffect(() => {
    dispatch(rfqByIdAndType(customerRfqId, "contactInformation"));
  }, [dispatch, customerRfqId]);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel, type) => (event, isExpanded) => {
    dispatch(rfqByIdAndType(customerRfqId, type));
    setExpanded(isExpanded ? panel : false);
  };


  return (
    <LayoutOne>
      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 px-0">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>
            View Customer RFQ
          </h5>
        </div>

        <div className="content col-12 bg-white p-3">
          <div className="border-bottom"> </div>

          <div>
            {" "}
            {/* Create New Warehouse */}{" "}
            <span className="accord-heading"> Initial Information </span>{" "}
            <RFQForm isView={true} />
            {/* Contact information */}{" "}
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2", "contactInformation")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading">
                    {" "}
                    1. Contact Information{" "}
                  </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <ContactInformationForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>
            {/* Contact information */}{" "}
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3", "general")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading"> 2. General </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <GeneralForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>
            {/* Contact information */}{" "}
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4", "inbounds")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading"> 3. Inbound </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <InBoundForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>
            {/* OutBount */}{" "}
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5", "outbounds")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading"> 4. Outbound </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <OutBoundForm isView={true}/>
              </AccordionDetails>{" "}
            </Accordion>
            {/* Man Power */}{" "}
            {/*<Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6", "manPowers")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading"> 5. Man Power </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <ManPowerForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>*/}
            {/* Return */}{" "}
            {/*<Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7", "returnRfq")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading"> 6. Return </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <ReturnForm isView={true} />{" "}
              </AccordionDetails>{" "}
            </Accordion>*/}
            {/* Inventory Management */}{" "}
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8", "inventoryManagements")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading">
                    {" "}
                    5. Inventory Management{" "}
                  </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <InventoryManagementForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>
            {/* Storage Type */}{" "}
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9", "storageTypes")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading"> 6. Storage Type </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <StorageTypeForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>
            {/* Value Added Service */}{" "}
            {/*<Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10", "valueAddedServices")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading">
                    {" "}
                    9. Value Added Service{" "}
                  </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <ValueAddedServiceForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>*/}
            {/* Other Service Requirement */}{" "}
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11", "otherServiceRequirements")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading">
                    {" "}
                    7. Other Service Requirement{" "}
                  </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <OtherServiceRequirementForm isView={true} />{" "}
              </AccordionDetails>{" "}
            </Accordion>
            {/* Other Service Requirement */}{" "}
            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChange("panel12", "kpi")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading"> 8. KPI </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <KPIForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>
            {/* Additional Requirements */}{" "}
            <Accordion
              expanded={expanded === "panel13"}
              onChange={handleChange("panel13", "additionalRequirement")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>
                  {" "}
                  <span className="accord-heading">
                    {" "}
                    9. Additional Requirements{" "}
                  </span>
                </Typography>
              </AccordionSummary>{" "}
              <AccordionDetails>
                <AdditionalRequirementsForm isView={true} />
              </AccordionDetails>{" "}
            </Accordion>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row justify-content-start">
          <div className="col-auto">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="btn btn-outline-blue mr-3 mb-2 py-1"
            >
              {" "}
              Back{" "}
            </button>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default CustomerRFQDetails;
