import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import BookingList from "../../wrapper/bookingManagement/BookingList";
import { Link } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import { bookingByPage } from "../../store/action/bookingAction";
import { CardLoader, ItemNotFoud } from "../../components/common/CustomLoader";
import { useHistory } from "react-router-dom";
import Pagination from "react-js-pagination";

const BookingApproved = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.BOOKING_INFO);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(
      bookingByPage(
        parseInt(pageCount),
        {
          filter: {
            type: "admin",
            status: "CONFIRMED",
          },
        },
        ""
      )
    );
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(
      bookingByPage(pageNumber, {
        filter: {
          type: "admin",
          status: "CONFIRMED",
        },
      })
    );
  };

  const callSearch = () => {
    dispatch(
      bookingByPage(
        parseInt(pageCount),
        {
          filter: {
            type: "admin",
            status: "CONFIRMED",
          },
        },
        searchHandler
      )
    );
  };

  return (
    <LayoutOne>
      <div className="row align-items-center py-1 px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 bg-white rounded">
          <div className="row justify-content-between">
            <div className="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul className="nav nav-pills admin-tabs-blue">
                <li className="nav-item">
                  <Link
                    to="/booking?page=1"
                    className="nav-link  text-uppercase"
                  >
                    {" "}
                    All{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/booking-pending?page=1"
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    Pending{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/booking-approve?page=1"
                    className="nav-link active_link text-uppercase"
                  >
                    {" "}
                    CONFIRMED{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/booking-cancel?page=1"
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    Cancelled{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 bg-white rounded">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
            border={true}
          />{" "}
        </div>
        {data.isLoading ? (
          <CardLoader />
        ) : (
          <div className="col-12 mt-2">
            <div className="row align-items-center">
              <div className="col-12 table-responsive table-gray-admin bg-white p-3">
                {" "}
                {data?.bookingList.data &&
                data?.bookingList.data?.length > 0 ? (
                  <table className="table table-dark-custom">
                    <thead>
                      <tr>
                        <th className="text-nowrap text-center"> Sr.No. </th>{" "}
                        <th className="text-nowrap"> WH ID </th>{" "}
                        <th className="text-nowrap"> RFQ ID </th>{" "}
                        <th className="text-nowrap"> Company Name </th>{" "}
                        <th className="text-nowrap"> Booking ID </th>{" "}
                        <th className="text-nowrap"> Booking Date </th>{" "}
                        <th className="text-nowrap"> Status </th>{" "}
                        <th className="text-nowrap"> Action </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      {data?.bookingList.data?.map((item, index) => {
                        return (
                          <BookingList
                            item={item}
                            status="CONFIRMED"
                            index={index + 1}
                            key={index}
                          />
                        );
                      })}
                    </tbody>{" "}
                  </table>
                ) : (
                  <ItemNotFoud message="Data Not Found" />
                )}{" "}
              </div>{" "}
            </div>

            {data.bookingList?.totalCount > 10 && (
              <div className="pagination-custom">
                <Pagination
                  activePage={parseInt(pageCount)}
                  itemsCountPerPage={10}
                  totalItemsCount={data.bookingList.totalCount}
                  pageRangeDisplayed={1}
                  onChange={handlePageChange}
                  prevPageText={<i className="fas fa-chevron-left" />}
                  nextPageText={<i className="fas fa-chevron-right" />}
                  hideFirstLastPages={true}
                />{" "}
              </div>
            )}
          </div>
        )}{" "}
      </div>
    </LayoutOne>
  );
};

export default BookingApproved;
