import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { onlyAlphaNumericSpaceAllow } from "../../components/helper/reuse";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerOnboardForm,
  updateUserForm,
  getSubUserAssignOnboardDetailById,
  responseOnboard,
  userOnboardDetail,
  formDetails,
} from "../../store/action/onBoardAction";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import CustomerCommonForm from "../../wrapper/customerOnboarding/CustomerCommonForm";

const CustomerTransitionOperation = () => {
  const { onBoardId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const formType = new URLSearchParams(window.location.search).get("formType");
  const assignUserId = new URLSearchParams(window.location.search).get(
    "assignUserId"
  );
  const onData = useSelector((state) => state.ON_BOARD_INFO);
  const contactDetailOnboardId = new URLSearchParams(
    window.location.search
  ).get("contactDetailOnboardId");

  const [costJson, setCostJson] = useState({
    transitionOperations: {
      transition: [
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
        {
          activities: "",
          frequency: "",
          sourceofData: "",
          supportingDocument: "",
        },
      ],
      preparedBy: "",
      preparedByDate: "",
      checkedBy: "",
      checkedByDate: "",
      approvedBy: "",
      approveByDate: "",
      ReceivedBy: "",
      ReceivedByDate: "",
    },
  });

  const schema = Yup.object().shape({
    // transition: Yup.array().of(
    //     Yup.object().shape({
    //       activities: Yup.string().required('Required'),
    //       frequency: Yup.string().required('Required'),
    //       sourceofData: Yup.string().required('Required'),
    //       supportingDocument: Yup.string().required('Required')
    //       })
    //     ),

    transitionOperations: Yup.object().shape({
      preparedBy: Yup.string().required("Required"),
      preparedByDate: Yup.string().required("Required"),
      checkedBy: Yup.string().required("Required"),
      checkedByDate: Yup.string().required("Required"),
      approvedBy: Yup.string().required("Required"),
      approveByDate: Yup.string().required("Required"),
      ReceivedBy: Yup.string().required("Required"),
      ReceivedByDate: Yup.string().required("Required"),
    }),
  });

  useEffect(() => {
    if (onData.formDetail?.transitionOperation) {
      setCostJson({
        transitionOperations:
          onData.formDetail?.transitionOperation?.transitionOperations,
      });
    }

    // if (formType === "transitionOperation" && onData.userOnboardDetail?.transitionOperation && onData.userOnboardDetail?.transitionOperation?.transitionOperations) {
    //   setCostJson({
    //     transitionOperations: onData.userOnboardDetail.transitionOperation?.transitionOperations
    //   })
    // }
  }, [
    onData.formDetail?.transitionOperation,
    onData.userOnboardDetail,
    formType,
  ]);

  useEffect(() => {
    if (formType === "transitionOperation") {
      dispatch(
        getSubUserAssignOnboardDetailById("transitionOperation", onBoardId)
      );
    } else {
      dispatch(
        getCustomerOnboardForm(assignUserId, "transitionOperation", onBoardId)
      );
    }

    return () => {
      dispatch(userOnboardDetail(null));
      dispatch(formDetails(null));
    };
  }, [dispatch, onBoardId, formType, assignUserId]);

  const redirect = () => {
    dispatch(responseOnboard(null));
    history.goBack();
  };

  return (
    <LayoutOne>
      {" "}
      {onData.onResponse && onData.onResponse.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={onData.onResponse.message} />
      ) : null}
      <div className="row align-items-center pb-3 mx-0 form-control-height">
        <div className="col-12 py-2 mt-4">
          <span onClick={() => history.goBack()}>
            {" "}
            <h5 className="text-dark-blue cursorPointer">
              {" "}
              <i className="fas fa-chevron-left mr-3"> </i> Transition
              Operations
            </h5>{" "}
          </span>{" "}
        </div>{" "}
        <CustomerCommonForm buttonHide={true} />{" "}
        <div className="col-12 px-0">
          <div className="row align-items-center py-2 mx-0">
            <div className="col-12">
              <Formik
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={costJson}
                onSubmit={(fields) => {
                  // if (onBoardId && formType === "transitionOperation") {
                  if (contactDetailOnboardId) {
                    dispatch(
                      updateUserForm(
                        contactDetailOnboardId,
                        "transitionOperation",
                        fields
                      )
                    );
                  }
                }}
                render={({
                  values,
                  setFieldValue,
                  errors,
                  status,
                  onChange,
                  touched,
                }) => {
                  return (
                    <Form>
                      <>
                        <div className="row mx-0 ">
                          <div className="col-12 px-0 table-responsive table-gray-admin">
                            <table className="table text-left">
                              <tbody>
                                <tr>
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Activities{" "}
                                  </td>{" "}
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Frequency{" "}
                                  </td>{" "}
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Source of Data{" "}
                                  </td>{" "}
                                  <td className="font-weight-bold py-2">
                                    {" "}
                                    Supporting Document{" "}
                                  </td>{" "}
                                </tr>

                                {values.transitionOperations?.transition &&
                                  values.transitionOperations?.transition
                                    ?.length > 0 &&
                                  values.transitionOperations.transition.map(
                                    (ins, index) => {
                                      return (
                                        <tr>
                                          <td>
                                            <Field
                                              onKeyPress={(e) =>
                                                onlyAlphaNumericSpaceAllow(e)
                                              }
                                              name={`transitionOperations.transition.${index}.activities`}
                                              type="text"
                                              className={`form-control bg-white border-0 my-1`}
                                            />{" "}
                                            {/* <ErrorMessage name={`transition.${index}.activities`} component="div" className="invalid-feedback" /> */}{" "}
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`transitionOperations.transition.${index}.frequency`}
                                              type="text"
                                              className={`form-control bg-white border-0 my-1`}
                                            />{" "}
                                            {/* <ErrorMessage name={`transition.${index}.frequency`} component="div" className="invalid-feedback" /> */}{" "}
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`transitionOperations.transition.${index}.sourceofData`}
                                              type="text"
                                              className={`form-control bg-white border-0 my-1`}
                                            />{" "}
                                            {/* <ErrorMessage name={`transition.${index}.sourceofData`} component="div" className="invalid-feedback" /> */}{" "}
                                          </td>{" "}
                                          <td>
                                            <Field
                                              name={`transitionOperations.transition.${index}.supportingDocument`}
                                              type="text"
                                              className={`form-control bg-white border-0 my-1`}
                                            />{" "}
                                            {/* <ErrorMessage name={`transition.${index}.supportingDocument`} component="div" className="invalid-feedback" /> */}{" "}
                                          </td>{" "}
                                        </tr>
                                      );
                                    }
                                  )}
                              </tbody>{" "}
                            </table>{" "}
                          </div>{" "}
                        </div>

                        <div className="row py-2 mt-1">
                          <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                            <label htmlFor="" className="px-2">
                              {" "}
                              Prepared by{" "}
                            </label>{" "}
                            <div className="input-group">
                              <Field
                                name="transitionOperations.preparedBy"
                                type="text"
                                id="spaceproviderfirstname"
                                className={
                                  `form-control px-3 rounded-pill` +
                                  (errors.transitionOperations?.preparedBy &&
                                  touched.transitionOperations?.preparedBy
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Type here"
                              />
                              <ErrorMessage
                                name="transitionOperations.preparedBy"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <label htmlFor="" className="px-2 mt-2">
                              {" "}
                              Date{" "}
                            </label>{" "}
                            <div className="">
                              <Field
                                name="transitionOperations.preparedByDate"
                                type="date"
                                id="spaceproviderfirstname"
                                className={
                                  `form-control px-3 rounded-pill` +
                                  (errors.transitionOperations
                                    ?.preparedByDate &&
                                  touched.transitionOperations?.preparedByDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Type here"
                              />
                              <ErrorMessage
                                name="transitionOperations.preparedByDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </div>{" "}
                          <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                            <label htmlFor="" className="px-2">
                              {" "}
                              Checked by{" "}
                            </label>{" "}
                            <div className="input-group">
                              <Field
                                name="transitionOperations.checkedBy"
                                type="text"
                                id="spaceproviderfirstname"
                                className={
                                  `form-control px-3 rounded-pill` +
                                  (errors.transitionOperations?.checkedBy &&
                                  touched.transitionOperations?.checkedBy
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Type here"
                              />
                              <ErrorMessage
                                name="transitionOperations.checkedBy"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>{" "}
                            <label htmlFor="" className="px-2 mt-2">
                              {" "}
                              Date{" "}
                            </label>{" "}
                            <div className="">
                              <Field
                                name="transitionOperations.checkedByDate"
                                type="date"
                                id="spaceproviderfirstname"
                                className={
                                  `form-control px-3 rounded-pill` +
                                  (errors.transitionOperations?.checkedByDate &&
                                  touched.transitionOperations?.checkedByDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Type here"
                              />
                              <ErrorMessage
                                name="transitionOperations.checkedByDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>{" "}
                          </div>{" "}
                          <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                            <label htmlFor="" className="px-2">
                              {" "}
                              Approved by{" "}
                            </label>{" "}
                            <div className="input-group">
                              <Field
                                name="transitionOperations.approvedBy"
                                type="text"
                                id="spaceproviderfirstname"
                                className={
                                  `form-control px-3 rounded-pill` +
                                  (errors.transitionOperations?.approvedBy &&
                                  touched.transitionOperations?.approvedBy
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Type here"
                              />
                              <ErrorMessage
                                name="transitionOperations.approvedBy"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>{" "}
                            <label htmlFor="" className="px-2 mt-2">
                              {" "}
                              Date{" "}
                            </label>{" "}
                            <div className="">
                              <Field
                                name="transitionOperations.approveByDate"
                                type="date"
                                id="spaceproviderfirstname"
                                className={
                                  `form-control px-3 rounded-pill` +
                                  (errors.transitionOperations?.approveByDate &&
                                  touched.transitionOperations?.approveByDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Type here"
                              />
                              <ErrorMessage
                                name="transitionOperations.approveByDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>{" "}
                          </div>{" "}
                          <div className="col-xl-3 col-lg-4 col-md-6 form-group mb-4">
                            <label htmlFor="" className="px-2">
                              {" "}
                              Received by{" "}
                            </label>{" "}
                            <div className="input-group">
                              <Field
                                name="transitionOperations.ReceivedBy"
                                type="text"
                                id="spaceproviderfirstname"
                                className={
                                  `form-control px-3 rounded-pill` +
                                  (errors.transitionOperations?.ReceivedBy &&
                                  touched.transitionOperations?.ReceivedBy
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Type here"
                              />
                              <ErrorMessage
                                name="transitionOperations.ReceivedBy"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>{" "}
                            <label htmlFor="" className="px-2 mt-2">
                              {" "}
                              Date{" "}
                            </label>{" "}
                            <div className="">
                              <Field
                                name="transitionOperations.ReceivedByDate"
                                type="date"
                                id="spaceproviderfirstname"
                                className={
                                  `form-control px-3 rounded-pill` +
                                  (errors.transitionOperations
                                    ?.ReceivedByDate &&
                                  touched.transitionOperations?.ReceivedByDate
                                    ? " is-invalid"
                                    : "")
                                }
                                placeholder="Type here"
                              />
                              <ErrorMessage
                                name="transitionOperations.ReceivedByDate"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>{" "}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 px-5">
                            <button
                              type="submit"
                              disabled={onData.isPending}
                              className="btn btn-deep-blue py-2"
                            >
                              Save{" "}
                              {onData.isPending ? (
                                <Spinner animation="border" />
                              ) : null}{" "}
                            </button>{" "}
                          </div>{" "}
                        </div>
                      </>{" "}
                    </Form>
                  );
                }}
              />
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default CustomerTransitionOperation;
