// ############# Common Type ########
export const IS_ERROR = "IS_ERROR";
export const IS_LOADING = "IS_LOADING";
export const IS_PENDING = "IS_PENDING";
export const ERROR_MESSAGE = "ERROR_MESSAGE";

// ##########################
export const SIDEMENU = "SIDEMENU";
export const READ_ONLY = "READ_ONLY";


// ##########################################################
// #################### User Accounnt #######################
// ##########################################################
// ENQUIRY
export const ENQUIRY = "ENQUIRY";
export const ENQUIRY_BY_ID = "ENQUIRY_BY_ID"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const AUTHENTICATED = "AUTHENTICATED";
export const SESSIONEXPIRED = "SESSIONEXPIRED";
export const USER_INFO = "USER_INFO";
export const LOGIN_ERROR_MSG = "LOGIN_ERROR_MSG";

export const TYPE_TO_USE = "TYPE_TO_USE";

// Dashboard Management
export const DASHBOARD = "DASHBOARD";
export const DEMOGRAPHY = "DEMOGRAPHY";
export const USER_SUMMERY = "USER_SUMMERY";
export const USER_SPACE = "USER_SPACE";
export const DASHBOARD_RESPONSE = "DASHBOARD_RESPONSE";

export const DASHBOARD_OPERATOR_TYPE = "DASHBOARD_OPERATOR_TYPE";
export const DASHBOARD_LOCATION_WAREHOUSE = "DASHBOARD_LOCATION_WAREHOUSE";
export const DASHBOARD_WAREHOUSE_REQUEST_RECEIVE = "DASHBOARD_WAREHOUSE_REQUEST_RECEIVE";
export const DASHBOARD_LOCATION_FILTER = "DASHBOARD_LOCATION_FILTER";
export const DASHBOARD_WAREHOUSE_CATEGORY = "DASHBOARD_WAREHOUSE_CATEGORY";
export const REGISTER_USER_STATS = "REGISTER_USER_STATS";

// User Management

export const USER = "USER";
export const USER_BY_ID = "USER_BY_ID";
export const USER_RESPONSE = "USER_RESPONSE";

export const USER_LIST = "USER_LIST";
export const ALL_USER_LIST = "ALL_USER_LIST";
export const USER_DETAILS = "USER_DETAILS";
export const COUNT = "COUNT";


// ##########################################################
// ################## LIST OF WAREHOUSE #####################
// ##########################################################

export const WAREHOUSE_SUCCESS = 'WAREHOUSE_SUCCESS';
export const WAREHOUSE_PENDING = 'WAREHOUSE_PENDING';
export const WAREHOUSE_LOADING = 'WAREHOUSE_LOADING';
export const WAREHOUSE_ERROR = 'WAREHOUSE_ERROR';
export const WAREHOUSE_ERROR_MSG = 'WAREHOUSE_ERROR_MSG';
export const ADD_NEW_WAREHOUSE = "ADD_NEW_WAREHOUSE";
export const WAREHOUSE_LIST = "WAREHOUSE_LIST";
export const WAREHOUSE_BY_ID = "WAREHOUSE_BY_ID";
export const NEW_WAREHOUSE_INFO = "NEW_WAREHOUSE_INFO";
export const ACCORDION = "ACCORDION";
export const FINAL_SUBMIT = "FINAL_SUBMIT";
export const WAREHOUSE_BY_ID_AND_TYPE = "WAREHOUSE_BY_ID_AND_TYPE";
export const FINAL_SUBMIT_VENDOR = "FINAL_SUBMIT_VENDOR";

// ############  RFQ ########
export const RFQ = "RFQ";
export const RFQ_ADMIN_TO_VENDOR = "RFQ_ADMIN_TO_VENDOR";
export const RFQ_BY_ID = "RFQ_BY_ID";
export const RFQ_RESPONSE = "RFQ_RESPONSE";

// CATEGORY
export const CATEGORY = "CATEGORY";
export const CATEGORY_BY_ID = "CATEGORY_BY_ID";
export const CATEGORY_RESPONSE = "CATEGORY_RESPONSE";

// Booking 
export const BOOKING = "BOOKING";
export const BOOKING_BY_ID = "BOOKING_BY_ID";
export const BOOKING_RESPONSE = "BOOKING_RESPONSE";

// DOCUMENTS
export const DOCUMENT = "DOCUMENT";
export const DOCUMENT_BY_ID = "DOCUMENT_BY_ID";
export const DOCUMENT_RESPONSE = "DOCUMENT_RESPONSE";

// Invoice Management

export const INVOICE = "INVOICE";
export const INVOICE_BY_ID = "INVOICE_BY_ID";
export const INVOICE_RESPONSE = "INVOICE_RESPONSE";

// review Management

export const REVIEW = "REVIEW";
export const REVIEW_BY_ID = "REVIEW_BY_ID";
export const REVIEW_RESPONSE = "REVIEW_RESPONSE";

// Feedback Management
// export const  REVIEW = "REVIEW";
// export const  REVIEW_BY_ID = "REVIEW_BY_ID";
// export const  REVIEW_RESPONSE = "REVIEW_RESPONSE";
// FEEDBACK,
//     FEEDBACK_BY_ID,
//     FEEDBACK_RESPONSE,

// CMS Management

export const CMS = "CMS";
export const CMS_BY_ID = "CMS_BY_ID";
export const CMS_RESPONSE = "CMS_RESPONSE";

// Report Reponse

export const REPORT = "REPORT";
export const REPORT_RESPONSE = "REPORT_RESPONSE";


// WHS USer MAnagement

export const WHS_USER = "WHS_USER";
export const WHS_USER_BY_ID = "WHS_USER_BY_ID";
export const ROLE = "ROLE";
export const ROLE_BY_ID = "ROLE_BY_ID";
export const DEPARTMENT = "DEPARTMENT";
export const DEPARTMENT_BY_ID = "DEPARTMENT_BY_ID";
export const WHS_RESPONSE = "WHS_RESPONSE";
export const PERMISSION = "PERMISSION";
export const PERMISSION_BY_ID = "PERMISSION_BY_ID";
export const ROLE_PERMISSION = "ROLE_PERMISSION";

// Service Management
export const SERVICE = "SERVICE";
export const SERVICE_BY_ID = "SERVICE_BY_ID";
export const SERVICE_CATEGORY = "SERVICE_CATEGORY";
export const SERVICE_CATEGORY_BY_ID = "SERVICE_CATEGORY_BY_ID";
export const SERVICE_SUBCATEGORY = "SERVICE_SUBCATEGORY";
export const SERVICE_SUBCATEGORY_BY_ID = "SERVICE_SUBCATEGORY_BY_ID";
export const SERVICE_RESPONSE = "SERVICE_RESPONSE";
export const SERVICE_RECIEVE = "SERVICE_RECIEVE";
export const SERVICE_RECIEVE_BY_ID = "SERVICE_RECIEVE_BY_ID";


// WHYWHS

export const WHYWHS = "WHYWHS";
export const WHY_RESPONSE = "WHY_RESPONSE";
export const WHYWHS_BY_ID = "WHYWHS_BY_ID";

export const WHS_TYPE = "WHS_TYPE";
export const WHS_TYPE_ID = "WHS_TYPE_ID";

// Filter

export const FILTER = "FILTER";
export const FILTER_OPTION = "FILTER_OPTION";
export const FILTER_BY_ID = "FILTER_BY_ID";
export const FILTER_RESPONSE = "FILTER_RESPONSE";

// OnBoard Management

export const ON_BOARD = "ON_BOARD";
export const ON_BOARD_BY_ID = "ON_BOARD_BY_ID";
export const ON_BOARD_RESPONSE = "ON_BOARD_RESPONSE";
export const FORM_DETAIL = "FORM_DETAIL";
export const USER_ONBOARD_DETAIL = "USER_ONBOARD_DETAIL";
export const USER_ONBOARD_LIST = "USER_ONBOARD_LIST";
export const ONBOARD_CUSTOMER_DETAIL = "ONBOARD_CUSTOMER_DETAIL";

// notification
export const NOTIFICATION = "NOTIFICATION";
export const NOTIFICATION_BY_ID = "NOTIFICATION_BY_ID";
export const NOTIFICATION_RESPONSE = "NOTIFICATION_RESPONSE";