import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";

const RFQResponseList = ({ item, rfqId, index }) => {
  return (
    <tr>
      <td> {index}. </td>{" "}
      <td className="text-nowrap"> {item.customerRfq?.location?.city.name} </td>{" "}
      <td className="text-nowrap"> {item.customerRfq?.location?.area.name} </td>{" "}
      <td className="text-nowrap">
        {" "}
        {item.customerRfq?.user?.firstName +
          " " +
          item.customerRfq?.user?.lastName}{" "}
      </td>{" "}
      <td className="text-nowrap"> WHID-{item.warehouse?.id} </td>{" "}
      <td className="text-nowrap"> {readableDate(item.updated_at)} </td>{" "}
      <td className="text-nowrap"> {item.customerRfq?.user?.email} </td>{" "}
      <td>
        <Link
          to={`/manage-rfq/${rfqId}/${item.warehouse?.id}?isOpen=true`}
          className="btn p-1 btn-link btn-link-deep-blue font-weight-bold"
        >
          View{" "}
        </Link>{" "}
      </td>{" "}
    </tr>
  );
};

export default RFQResponseList;
