import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";

const DocList = ({ item, read }) => {
  return (
    <tr>
      <td className="text-center py-2"> {item.warehouse?.warehouseId} </td>{" "}
      <td className="text-nowrap"> {item.warehouse.users[0].companyName} </td>
      <td className="text-nowrap">
        {" "}
        {item.customerDocument
          ? readableDate(item.customerDocument.created_at)
          : null}{" "}
      </td>
      <td className="text-nowrap">
        {" "}
        {item.warehouse.users[0].firstName +
          " " +
          item.warehouse.users[0].lastName}{" "}
      </td>
      <td className="text-nowrap">
        {" "}
        {item.vendorDocument
          ? readableDate(item.vendorDocument.updated_at)
          : null}{" "}
      </td>
      {/* <td className="text-nowrap">{item.status}</td> */}
      <td className={`text-center text-nowrape d-flex justify-content-center `}>
        {" "}
        {!read ? (
          <Link
            to={`/customer-documents/${item?.id}/${item.warehouse.id}`}
            className={`btn text-deep-blue font-weight-bold p-1 `}
          >
            {" "}
            Manage{" "}
          </Link>
        ) : (
          <button
            type="button"
            className={`btn text-deep-blue font-weight-bold p-1 `}
          >
            {" "}
            Manage{" "}
          </button>
        )}{" "}
      </td>
    </tr>
  );
};

export default DocList;
