import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";

const FieldAgentApproveList = ({ item, index }) => {
  const { id, warehouseName, category, created_at, fieldAgaintStatus } = item;

  return (
    <tr>
      <td>
        {" "}
        <div className="cell"> {index} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {readableDate(created_at)} </div>
      </td>
      <td>
        {" "}
        <div className="cell">
          {" "}
          {item.warehouseId ? item.warehouseId : "-"}{" "}
        </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {warehouseName} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {category?.categoryName} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> {fieldAgaintStatus} </div>
      </td>
      <td class="text-center">
        {" "}
        <Link
          to={`/field-agent-approve/${id}`}
          class="btn px-3 text-nowrap font-weight-bold text-deep-blue text-uppercase"
        >
          {" "}
          View Details{" "}
        </Link>
      </td>
    </tr>
  );
};

export default FieldAgentApproveList;
