import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CardLoader } from "../../../components/common/CustomLoader";

const KPIForm = ({ isView }) => {
  const data = useSelector((state) => state.RFQ_INFO);
  const [kpiUrl, setKpiUrl] = useState(null);

  useEffect(() => {
    if (data?.rfqDetail?.data && data?.rfqDetail?.data && data?.rfqDetail?.data?.kpi) {
      // console.log(data?.rfqDetail?.data?.kpi,"|||****||**||****|||")
      setKpiUrl(data?.rfqDetail?.data?.kpi);
    }
  }, [data?.rfqDetail?.data]);

  return (
    <>
      {" "}
      {data.isLoading ? (
        <CardLoader loaderCard="loaderCard" />
      ) : (
        <div className="row">
          <div className="row col-12 ml-0">
            <div className="col-12 mb-md-4 mb-5 pt-md-1 px-0">
              <div className="custom-file form-group form-inline">
                <span
                  id="custom-file-name"
                  className="d-block custom-file-name px-0 mr-3 mb-2"
                >
                  {" "}
                  KPI requirements.{" "}
                </span>{" "}
                <div>
                  <a
                    href={kpiUrl}
                    download
                    target="_blank"
                    rel="noreferrer"
                    className="custom-file-upload-label py-2 btn-deep-primary btn text-nowrap w-250px"
                    htmlFor="custom-file-upload-input1"
                  >
                    Download{" "}
                  </a>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
          <div className={`col-12 ${isView ? "d-none" : ""}`}>
            <div className="row justify-content-end">
              {" "}
              {/* <div className="col-auto">
                            <button type="button" className="btn btn-outline-deep-primary mb-3 add-className remove-className" data-add-target=".steps10" data-add-target-className="d-none" data-remove-target=".steps9" data-remove-target-className="d-none">Back</button>
                          </div> */}{" "}
              <div className="col-auto">
                <button
                  type="button"
                  className="btn py-1 btn-deep-primary mb-3 add-class"
                >
                  {" "}
                  Submit{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}{" "}
    </>
  );
};

export default KPIForm;
