import React from "react";
import { Link } from "react-router-dom";
// import {updateUser} from '../../store/action/userAction';
// import {useDispatch} from 'react-redux';

const UserList = ({ data, index, read }) => {
  const updateUser = (status) => {
    console.log("Update", status);
    // dispatch(updateUser(customerId, {status:status}))
  };

  return (
    <tr>
      <td className="text-center"> {index} </td>{" "}
      <td className="text-nowrap">
        {" "}
        {data?.firstName + " " + data?.lastName}{" "}
      </td>{" "}
      <td className="text-nowrap"> {data?.companyName} </td>{" "}
      {/* <td className="text-nowrap">{city}</td>
                    <td className="text-nowrap">{area}</td> */}{" "}
      <td> {data?.id} </td> <td> {data?.email} </td> <td> {data?.phone} </td>{" "}
      <td>
        <select
          onChange={(e) => updateUser(e.target.value === "true")}
          value={data?.active}
          disabled={read}
          className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
        >
          <option value={true}> Active </option>{" "}
          <option value={false}> Inactive </option>{" "}
        </select>{" "}
      </td>{" "}
      <td className="text-center text-nowrape d-flex">
        <Link to={`/vendor/${data?.id}?customer=true`} className="btn p-1">
          {" "}
          <i className="fas fa-eye"> </i>
        </Link>

        {/* <button className="btn px-2 btn-link btn-link-deep-blue font-weight-bold"><i
                          className="fas fa-trash-alt"></i></button> */}
      </td>{" "}
    </tr>
  );
};

export default UserList;
