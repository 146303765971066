import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import FileSaver from "file-saver";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import { Card } from "reactstrap";

import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import { responseService } from "../../store/action/serviceAction";
import axiosauth from "../../services/axios-auth";
const Graph = () => {
  let fileObj = null;
  const [filedownload, setfiledownload] = useState(null);
  const [fileerror, setfileerror] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.SERVICE_INFO);

  const roleSchema = Yup.object().shape({
    categoryId: Yup.string().required("Required"),
    subcategoryId: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const handleDownload = (e) => {
    e.preventDefault();
    setfiledownload(file1);
    if (filedownload) {
      FileSaver.saveAs(filedownload, filedownload.name);
    }
  };
  const [file1, setfile1] = useState(null);
  // File Upload

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service`);
  };

  const [download, setdownload] = useState(null);
  useEffect(() => {
    // dispatch(serviceCategoryByPage());
    axiosauth
      .get("/api/v1/rate-matrix")
      .then((response) => {
        let res = JSON.parse(response.data);
        console.log("sdsd", res);
        // createFile(res.data.filePath)
        // fetch(res.data.filePath)
        //   .then((e) => {
        //     return e.blob();
        //   })
        //   .then((blob) => {
        //     let b = blob;
        //     b.lastModifiedDate = new Date();
        //     b.name = "test";
        //     console.log(b);
        //     // return b
        //   });
        setdownload(res.data.filePath);
      })
      .catch((error) => {})
      .then(() => {
        console.log("-----always executes");
      });
  }, [dispatch]);

  return (
    <LayoutOne>
      {" "}
      {data.serviceResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Service Added`} />
      ) : null}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark font-weight-bold"> Rate Matrix </h5>{" "}
        </div>
        <div className="col-12 bg-white p-3">
          <Formik
            enableReinitialize={true}
            validationSchema={roleSchema}
            initialValues={{
              categoryId: "",
              subcategoryId: "",
              name: "",
              description: "",
              expiryDate: "",
              isActive: false,
              featuredData: [],
              rows: "",
              cols: "",
              features: [],
            }}
            onSubmit={(fields) => {
              if (file1 === null) {
                setfileerror("Please Upload Rate Matrix Excel");
              }

              let formData = new FormData();
              formData.append("file", file1);
              // let urlData = documentUploadV2 (formData)
              // urlData.then((res)=>{
              //     fields['sampleRfq']=res.url

              //     fields.expiryDate = new Date()
              //     dispatch(createService(fields))
              // })
              console.log("sdsd", formData);

              axiosauth
                .post("/rate-matrix", formData)
                .then((response) => {
                  let res = JSON.parse(response.data);
                  console.log("sdsd", res);
                })
                .catch((error) => {})
                .then(() => {
                  console.log("-----always executes");
                });
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <div className="row ">
                    <div className="form-group col-6 mb-0">
                      <label className="mt-2"> Upload Rate Matrix xlsx </label>{" "}
                      <div class="custom-file mt-1">
                        <label class="custom-file-label" for="customFile">
                          {" "}
                          {file1 !== null ? file1.name : "Choose File"}{" "}
                        </label>{" "}
                        <Field
                          className="w-100"
                          onChange={(e) => {
                            fileObj = e.target.files[0];

                            //just pass the fileObj as parameter
                            ExcelRenderer(fileObj, (err, resp) => {
                              if (err) {
                                console.log(err);
                              } else {
                                setFieldValue("cols", resp.cols);
                                setFieldValue("rows", resp.rows);
                                //  this.setState({
                                //    cols: resp.cols,
                                //    rows: resp.rows
                                //  });
                              }
                            });
                            setfile1(e.target.files[0]);
                            let formData = new FormData();
                            formData.append("file", e.target.files[0]);
                            // let urlData = documentUploadV2 (formData)
                            // urlData.then((res)=>{
                            //     fields['sampleRfq']=res.url

                            //     fields.expiryDate = new Date()
                            //     dispatch(createService(fields))
                            // })
                            console.log("sdsd", formData);

                            axiosauth
                              .post("/api/v1/rate-matrix/", formData)
                              .then((response) => {
                                let res = JSON.parse(response.data);
                                console.log("sdsd", res);
                                alert("Rate Matrix Uploaded Successfully");
                              })
                              .catch((error) => {})
                              .then(() => {
                                console.log("-----always executes");
                              });
                          }}
                          type="file"
                          name="file"
                          accept=".xlsx, .xls, .csv"
                          class="custom-file-input"
                          id="customFile"
                        />
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {" "}
                          {fileerror}{" "}
                        </p>{" "}
                        <ErrorMessage
                          name={`file`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="col-auto align-self-end">
                      <button
                        type="submit"
                        disabled={data.isPending}
                        className={`btn btn-deep-blue toggle-class ${
                          !read ? "" : "d-none"
                        }`}
                      >
                        Update{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                      <button
                        type="button"
                        onClick={handleDownload}
                        className="btn py-2 ml-2 btn-outline-deep-blue"
                      >
                        Download Rate Matrix Excel{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      backgroundColor: "white",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    {values.rows !== "" && values.cols !== "" ? (
                      <Card
                        body
                        outline
                        color="secondary"
                        className="restrict-card"
                      >
                        <OutTable
                          data={values.rows}
                          columns={values.cols}
                          tableClassName="ExcelTable2007"
                          tableHeaderRowClass="heading"
                        />
                      </Card>
                    ) : null}{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default Graph;
