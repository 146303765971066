import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { readableDate } from "../../components/helper/reuse";
import axiosauth from "../../services/axios-auth";
import { Link } from "react-router-dom";

const FetureList = ({ item, fetureCallApi, index }) => {
  // Status Change confirmation
  const statusChange = (status) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to change status.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("isActive===>", status);
            let data = {
              adminApproved: status,
            };

            try {
              axiosauth
                .put(`/api/v1/featuredwarehouse/${item.id}`, data)
                .then((response) => {
                  let res = JSON.parse(response.data);
                  console.log("res-->", res);
                  fetureCallApi();
                })
                .catch((error) => {
                  console.log("rerr-->", error);
                })
                .then(() => {
                  console.log("-----always executes");
                });
            } catch (e) {}
          },
        },
        {
          label: "No",
          onClick: () => console.log("isActive===>", status),
        },
      ],
    });
  };

  return (
    <tr>
      <td className="text-center">{index} </td>{" "}
      <td className="">
        <div className="font-weight-bold text-capitalize text-nowrap">
          {" "}
          {item?.warehouse?.warehouseId?.slice(0, 7)}{" "}
        </div>{" "}
      </td>
      <td className="">
        <div className="font-weight-bold text-capitalize text-nowrap">
          {" "}
          {item?.warehouse?.warehouseName}{" "}
        </div>{" "}
      </td>
      <td className="">
        <div className="font-weight-bold text-capitalize text-nowrap">
          {" "}
          {readableDate(item?.startDate)}{" "}
        </div>{" "}
      </td>
      <td className="">
        <div className="font-weight-bold text-capitalize text-nowrap">
          {" "}
          {readableDate(item?.endDate)}{" "}
        </div>{" "}
      </td>
      <td>
        <select
          onChange={(e) => statusChange(e.target.value)}
          name="status"
          value={item?.adminApproved}
          className="form-control custom-select bg-white common-select-deep-blue border w-150px"
        >
          <option value={`Pending`}> New Request </option>{" "}
          <option value={`Confirmed`}> Confirmed </option>{" "}
          <option value={`Cancelled`}> Cancelled </option>{" "}
          <option value={`Pending For Live`}> Pending For Live </option>{" "}
          <option value={`Hold`}> Hold </option>{" "}
        </select>
      </td>
      <td className="">
        <Link to={`/feature-warehouse/${item.id}`} className="font-weight-bold">
          {" "}
          <i className="fas fa-eye"> </i>
        </Link>{" "}
        {/* <div className="font-weight-bold text-capitalize text-nowrap">{readableDate(item?.endDate)}</div> */}{" "}
      </td>
    </tr>
  );
};

export default FetureList;
