import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import SearchBox from "../../components/common/SearchBox";
import BrouserTitle from "../../components/common/BrouserTitle";
import { Link, useHistory } from "react-router-dom";
import { rfqByIdAndType } from "../../store/action/rfqAction";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";
import { CardLoader } from "../../components/common/CustomLoader";

const ManageRFQList = () => {
  const { rfqId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.RFQ_INFO);

  useEffect(() => {
    if (rfqId !== "") {
      dispatch(rfqByIdAndType(rfqId, "customerRfq"));
    }
  }, [dispatch, rfqId]);

  useEffect(() => {
    let arr = [];
    if (
      data.rfqDetail &&
      data.rfqDetail?.data &&
      data.rfqDetail.data?.customerRequestResponses &&
      data.rfqDetail.data?.customerRequestResponses.length > 0
    ) {
      for (
        let i = 0;
        i < data.rfqDetail.data?.customerRequestResponses.length;
        i++
      ) {
        if (
          data.rfqDetail.data?.customerRequestResponses[i].purpose ===
          "warehousity to customer"
        ) {
          arr.push(
            data.rfqDetail.data?.customerRequestResponses[i].warehouseId
          );
        }
      }
    }
  }, [data.rfqDetail]);
  

  return (
    <LayoutOne>
      <BrouserTitle title="Manage RFQ" />
      <SearchBox />
      <div className="row align-items-center pt-2 px-3 mx-0">
        <div class="col-12 px-0">
          <h5 className="text-dark font-weight-bold">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-1 cursorPointer"
            >
              {" "}
            </i>
            RFQ's Warehouse List (RFQ ID : {rfqId})
          </h5>
        </div>{" "}
        <div className="col-12 px-0">
          <div className="row align-items-center py-3 px-3 mx-0">
            {" "}
            {!data.isLoading ? (
              <div className="col-12 px-0">
                <div className="row">
                  <div className="col-12 px-0 table-responsive table-gray-admin bg-white p-3">
                    <table className="table table-dark-custom">
                      <thead>
                        <tr>
                          <th className="text-cente"> S.NO </th> <th> City </th>{" "}
                          <th> Area </th>{" "}
                          <th className="text-nowrap"> Vendor Name </th>{" "}
                          <th className="text-nowrap"> WH ID </th>{" "}
                          <th> Date </th> <th> Email </th> <th> Action </th>{" "}
                          {/* <td className="text-nowrap">Create RFQ</td> */}{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        {" "}
                        {data.rfqDetail.data &&
                        data.rfqDetail.data.warehouses &&
                        data.rfqDetail.data.warehouses.length > 0 &&
                        data.rfqDetail.data.location ? (
                          <>
                            {" "}
                            {data.rfqDetail.data.warehouses.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td> {index + 1}. </td>{" "}
                                    <td>
                                      {" "}
                                      {data.rfqDetail
                                        ? data.rfqDetail.data.location.city.name
                                        : ""}{" "}
                                    </td>{" "}
                                    <td className="text-nowrap text-capitalize">
                                      {" "}
                                      {data.rfqDetail
                                        ? data.rfqDetail.data.location.area.name
                                        : ""}{" "}
                                    </td>{" "}
                                    <td className="text-nowrap text-capitalize">
                                      {" "}
                                      {item.users && item.users.length > 0
                                        ? item.users[0].firstName +
                                          " " +
                                          item.users[0].lastName
                                        : ""}{" "}
                                    </td>{" "}
                                    <td> WH {item.id} </td>{" "}
                                    <td className="text-nowrap">
                                      {" "}
                                      {readableDate(
                                        data.rfqDetail.data.created_at
                                      )}{" "}
                                    </td>{" "}
                                    <td className="text-nowrap">
                                      {" "}
                                      {item.users && item.users.length > 0
                                        ? item.users[0].email
                                        : ""}{" "}
                                    </td>{" "}
                                    <td>
                                      <Link
                                        to={`/manage-rfq/${rfqId}/${item.id}?isOpen=false`}
                                        className="btn p-1 btn-link btn-link-deep-blue font-weight-bold"
                                      >
                                        View{" "}
                                      </Link>{" "}
                                    </td>{" "}
                                  </tr>
                                );
                              }
                            )}
                          </>
                        ) : null}
                      </tbody>{" "}
                    </table>{" "}
                  </div>{" "}
                </div>{" "}
                {/* ============ Pagination ============ */}
                {/* <div className="pagination-custom">
                                <Pagination
                                  activePage={activePage}
                                  itemsCountPerPage={10}
                                  totalItemsCount={datas.length}
                                  pageRangeDisplayed={1}
                                  onChange={handlePageChange}
                                  prevPageText = {<i className="fas fa-chevron-left"/>}
                                  nextPageText= {<i className="fas fa-chevron-right"/>}
                                  hideFirstLastPages={true}
                                />
                              </div> */}
              </div>
            ) : (
              <CardLoader />
            )}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default ManageRFQList;
