import {
    WAREHOUSE_ERROR,
    WAREHOUSE_PENDING,
    WAREHOUSE_LOADING,
    ADD_NEW_WAREHOUSE,
    WAREHOUSE_LIST,
    WAREHOUSE_BY_ID,
    NEW_WAREHOUSE_INFO,
    ACCORDION,
    FINAL_SUBMIT,
    WAREHOUSE_BY_ID_AND_TYPE,
    WAREHOUSE_ERROR_MSG,
    TYPE_TO_USE,
    FINAL_SUBMIT_VENDOR
} from '../types';


const initialState = {
    isPending: false,
    isLoading: false,
    isError: "",
    type: "",
    isErrorMsg: "",
    accordion: "",
    finalSubmit: false,
    finalSubmitVendor: false,

    addNewResponse: [],
    listOfWarehouse: [],
    warehouseDetail: [],
    newWarehouseInfo: [],
    singleFormData: []
}

export function WAREHOUSEINFO(state = initialState, action) {
    switch (action.type) {
        case ADD_NEW_WAREHOUSE:
            return {
                ...state,
                addNewResponse: action.payload,
            }
        case TYPE_TO_USE:
            return {
                ...state,
                type: action.payload,
            }
        case NEW_WAREHOUSE_INFO:
            return {
                ...state,
                newWarehouseInfo: action.payload,
            }

        case WAREHOUSE_PENDING:
            return {
                ...state,
                isPending: action.payload,
            };

        case WAREHOUSE_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };

        case WAREHOUSE_LIST:
            return {
                ...state,
                listOfWarehouse: action.payload,
            };

        case WAREHOUSE_BY_ID:
            return {
                ...state,
                warehouseDetail: action.payload,
            };

        case WAREHOUSE_BY_ID_AND_TYPE:
            return {
                ...state,
                singleFormData: action.payload,
            };

        case WAREHOUSE_ERROR:
            return {
                ...state,
                isError: action.payload,
            };

        case WAREHOUSE_ERROR_MSG:
            return {
                ...state,
                isErrorMsg: action.payload,
            };

        case ACCORDION:
            return {
                ...state,
                accordion: action.payload,
            };

        case FINAL_SUBMIT:
            return {
                ...state,
                finalSubmit: action.payload,
            };
        case FINAL_SUBMIT_VENDOR:
            return {
                ...state,
                finalSubmitVendor: action.payload,
            };

        default:
            return state;
    }
}