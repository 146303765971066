import {
    FILTER,
    FILTER_OPTION,
    FILTER_BY_ID,
    FILTER_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

import {
    FILTER_URL,
    UPDATE_FILTER_URL,
    FILTER_OPTION_URL,
    ADMIN_FILTER_URL
} from '../../services/urls';

import axiosauth from '../../services/axios-auth';

export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}


export function filterList(data) {
    return {
        type: FILTER,
        payload: data
    }
}

export function filterOptionList(data) {
    return {
        type: FILTER_OPTION,
        payload: data
    }
}

export function filter_By_Id(data) {
    return {
        type: FILTER_BY_ID,
        payload: data
    }
}


export function responseFilter(data) {
    return {
        type: FILTER_RESPONSE,
        payload: data
    }
}


// ###########  Fecth All filter ########

export const filterByPage = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(ADMIN_FILTER_URL + `?page=${page}&limit=${10}&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(filterList(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("filter Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}

    }
}



// ###########  Fecth All filter ########

export const activeFilterList = () => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        dispatch(filterList([]))
        try {
            axiosauth.get(`/api/v1/filter/admin/filter`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(filterList(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("filter Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}

    }
}




// ###########  Fecth All filter ########

export const filterByLoader = () => {
    return async (dispatch) => {
        dispatch(isError(""))
        try {
            axiosauth.get(ADMIN_FILTER_URL).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(filterList(res.data))
                } else {
                    console.log("filter Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                console.log("-----always executes");
            })

        } catch (e) {}

    }
}



// ###########  Fecth  filter Detail By Id ########

export const filterById = (id) => {
    return async (dispatch) => {

        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(FILTER_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(filter_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("filter_By_Id Fail")
                }
            }).catch((error) => {
                console.log("Error==>", error.message)
                dispatch(isError(error.message))
                dispatch(isLoading(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  Fecth  filter Detail By Id ########

export const deleteFilterById = (id) => {
    return async (dispatch) => {
        dispatch(isError(""))
        try {
            axiosauth.delete(FILTER_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(filter_By_Id(res.data))
                    dispatch(filterByLoader())
                } else {
                    console.log("filter_By_Id Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  CREATE_FILTER_URL  ############

export const createFilter = (data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.post(FILTER_URL, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(responseFilter(res))
                    dispatch(isPending(false))
                } else {
                    console.log("FIlter Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}



// ###########  UPDATE_FILTER_URL  ############

export const updateFilter = (id, fields) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.put(UPDATE_FILTER_URL + `/${id}`, fields).then(response => {
                let res = JSON.parse(response.data)
                console.log("resss-->", res)
                if (res.statusCode === 200) {
                    dispatch(responseFilter(res))
                    dispatch(isPending(false))
                } else {
                    console.log("Filter Details Fail")
                }
            }).catch((error) => {
                console.log("catch-->", error)
                dispatch(isError(error.message))
                dispatch(isPending(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}



// ###########  CREATE_FILTER_Option  ############

export const createFilterOption = (filterId, data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.post(FILTER_OPTION_URL + `/${filterId}`, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(responseFilter(res))
                    dispatch(isPending(false))
                } else {
                    console.log("FIlter Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}




// ###########  CREATE_FILTER_Option  ############

export const updateFilterOptionById = (filterId, filterOptionId, data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.post(`/api/v1/filter/filter/${filterId}/subfilter/${filterOptionId}`, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(responseFilter(res))
                    dispatch(isPending(false))
                } else {
                    console.log("FIlter Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
                setTimeout(() => {
                    dispatch(isError(""))
                }, 5000)
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}