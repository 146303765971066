import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import PropTypes from 'prop-types';

const noRequiredAuth = (ComposedComponent) => {
    class NotAuthentication extends Component {
        componentWillMount() {
            if (this.props.authenticated) {
                this.props.history.push('/dashboard');
            }
        }

        componentWillUpdate(nextProps) {
            if (nextProps.authenticated) {
                this.props.history.push('/dashboard');
            }
        }

        PropTypes = {
            router: PropTypes.object,
        }

        render() {
            return <ComposedComponent { ...this.props
            }
            />;
        }
    }

    function mapStateToProps(state) {
        return {
            authenticated: state.authenticated,
        };
    }

    return connect(mapStateToProps)(NotAuthentication);
}

export default noRequiredAuth;