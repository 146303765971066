import {
  DASHBOARD,
  DASHBOARD_RESPONSE,
  DASHBOARD_OPERATOR_TYPE,
  DASHBOARD_LOCATION_WAREHOUSE,
  DASHBOARD_WAREHOUSE_REQUEST_RECEIVE,
  DASHBOARD_LOCATION_FILTER,
  DASHBOARD_WAREHOUSE_CATEGORY,
  DEMOGRAPHY,
  USER_SUMMERY,
  REGISTER_USER_STATS,
  USER_SPACE,
  IS_ERROR,
  IS_LOADING,
  IS_PENDING,
} from "./../types";

import {
  DASHBOARD_URL,
  DEMOGRAPHY_URL,
  DASHBOARD_OPERATOR_TYPE_URL,
  DASHBOARD_LOCATION_WAREHOUSE_URL,
  DASHBOARD_WAREHOUSE_REQUEST_RECEIVE_URL,
  DASHBOARD_LOCATION_FILTER_URL,
  DASHBOARD_WAREHOUSE_CATEGORY_URL,
} from "../../services/urls";

import axiosauth from "../../services/axios-auth";

export function isError(error) {
  return {
    type: IS_ERROR,
    isError: error,
  };
}

export function isLoading(bool) {
  return {
    type: IS_LOADING,
    isLoading: bool,
  };
}

export function isPending(bool) {
  return {
    type: IS_PENDING,
    isLoading: bool,
  };
}

export function dashboardList(data) {
  return {
    type: DASHBOARD,
    payload: data,
  };
}

export function responseDashboard(data) {
  return {
    type: DASHBOARD_RESPONSE,
    payload: data,
  };
}

// ###########  Fecth All bookingList ########

export const getDashboard = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    let filter = {
      adminDashboard: [
        "totalRegisteredSpaceProviders",
        "listedSpaceProvider",
        "operativeSpaceProviders",
        "typeOfOperators",
        "noOfVendorsListing",
        "verifiedWarehouseDetails",
        "warehousesListingRequestReceived",
        "warehouseNotSubmitApproval",
        "totalListedWarehouses",
        "pendingForListing",
        "operationalWarehouses",
        "typeOfWarehouse",
        "operationalWarehouses",
        "agreementAboutExpire",
        "totalSpaceRegisteredWithWHS",
        "availableSpace",
        "warehouseCategory",
        "spaceBookedWHS",
        "locationFilter",
        "stateCount",
        "citiesCount",
        "pincodeCount",
        "cityProfileUpdateWHS",
        "vendorCount",
        "totalNoRegisteredCustomers",
        "typeOfCustomersCategory",
        "noOfInactiveCustomers",
        "totalRequestWhBooking",
        "bookingRequestAttendedByWHS",
        "bookingRequestUnattendedWHS",
        "confirmedBookings",
        "bookingRequestHoldLost",
        "typeOfWarehouseRequestReceived",
        "agreementAboutExpireDayLimit",
        "industrySpecificCustomer",
        "spaceLocationWarehouseWise",
        "spaceCertificatesIssued",
        "spaceWarehousecategory",
        "stateCountActiveCust",
        "citiesCountActiveCust",
        "pincodeCountActiveCust",
        "customerCount",
      ],
    };

    try {
      axiosauth
        .post(DASHBOARD_URL, filter)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch(dashboardList(res.data));
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

// ########### 1  Fecth All DASHBOARD_OPERATOR_TYPe ########

export const getOperatorType = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .get(DASHBOARD_OPERATOR_TYPE_URL)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch({
              type: DASHBOARD_OPERATOR_TYPE,
              payload: res.data,
            });

            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

// ########### 2  Fecth All DASHBOARD_LOCATION_WAREHOUSE ########

export const getLocationWarehouse = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .get(DASHBOARD_LOCATION_WAREHOUSE_URL)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch({
              type: DASHBOARD_LOCATION_WAREHOUSE,
              payload: res.data,
            });
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

// ########### 3  Fecth All bookingList ########

export const getRequestReceive = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .get(DASHBOARD_WAREHOUSE_REQUEST_RECEIVE_URL)
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log("ressss--->", res);
          if (res.statusCode === 200) {
            dispatch({
              type: DASHBOARD_WAREHOUSE_REQUEST_RECEIVE,
              payload: res.data,
            });
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

// ########### 4  Fecth All bookingList ########

export const getLocationFilter = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .get(DASHBOARD_LOCATION_FILTER_URL)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch({
              type: DASHBOARD_LOCATION_FILTER,
              payload: res.data,
            });
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

// ########### 5  Fecth All bookingList ########

export const getDashboardCategory = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .get(DASHBOARD_WAREHOUSE_CATEGORY_URL)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            dispatch({
              type: DASHBOARD_WAREHOUSE_CATEGORY,
              payload: res.data,
            });
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

var colorArray = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

// Demography APIS
export const getDemograpgy = (data) => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .post(DEMOGRAPHY_URL, data)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            let arr = [];
            for (let j = 0; j < res.data?.length; j++) {
              arr.push({
                title: res.data[j].key,
                value: res.data[j].count,
                color: colorArray[j],
              });
            }

            dispatch({
              type: DEMOGRAPHY,
              payload: {
                data: arr,
                totalWarehouse: res.totalWarehouse,
              },
            });
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

// Get User Summery APIS
export const getUserSummery = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .get(`/api/v1/user/spacesummary/users`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            console.log("res user summery--->", res);

            // let arr = []
            // for(let j=0; j<res.data?.length ; j++){
            //     arr.push({ title: res.data[j].key, value: res.data[j].count, color: colorArray[j] })
            // }

            dispatch({
              type: USER_SPACE,
              payload: res.data,
            });
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

// Get User Register Detail
export const getUserRegister = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .get(`/api/v1/user/dashboard/customerdetail?type=individual&year=2020`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            // let arr = []
            // for(let j=0; j<res.data?.length ; j++){
            //     arr.push({ title: res.data[j].key, value: res.data[j].count, color: colorArray[j] })
            // }

            dispatch({
              type: REGISTER_USER_STATS,
              payload: res.data,
            });
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};

// Get User Summery APIS
export const getUserSpaceSummery = () => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    dispatch(isError(""));

    try {
      axiosauth
        .get(`/api/v1/user/spacesummary`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            console.log("res user Space summery--->", res);

            // let arr = []
            // for(let j=0; j<res.data?.length ; j++){
            //     arr.push({ title: res.data[j].key, value: res.data[j].count, color: colorArray[j] })
            // }

            dispatch({
              type: USER_SUMMERY,
              payload: res.data,
            });
            dispatch(isLoading(false));
          }
        })
        .catch((error) => {
          dispatch(isLoading(false));
          dispatch(isError(error.message));
        })
        .then(() => {
          dispatch(isLoading(false));
        });
    } catch (e) {}
  };
};
