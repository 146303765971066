import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import ServiceReceiveList from "../../wrapper/serviceManagement/ServiceReceiveList";
import { useDispatch, useSelector } from "react-redux";
import {
  serviceRecieveByPage,
  responseService,
  serviceByPage,
} from "../../store/action/serviceAction";
import FormSuccess from "../../components/common/FormSuccess";
import { CardLoader } from "../../components/helper/CustomLoader";
import { Helmet } from "react-helmet";
import Pagination from "react-js-pagination";
import { useState } from "react";

const ServiceReceive = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.SERVICE_INFO);

  console.log("dattt", data);

  const [searchHandler, setSearchHandler] = useState("");
  const pageCount = new URLSearchParams(window.location.search).get("page");

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(serviceRecieveByPage(pageNumber));
  };

  useEffect(() => {
    dispatch(serviceRecieveByPage(parseInt(pageCount)));
  }, [dispatch, pageCount]);

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service-receive`);
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Receive Service </title>{" "}
      </Helmet>{" "}
      {data.serviceResponse !== null ? (
        <FormSuccess
          onClick={redirect}
          message={`Service Rfq Status Updated`}
        />
      ) : null}
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row justify-content-end align-items-center py-3 px-3 mx-0">
          <div className="col-12 px-0">
            <h5 className="text-dark font-weight-bold">
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-1 cursorPointer"
              ></i>{" "}
              Manage Receive Services Request{" "}
            </h5>{" "}
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12 px-0 table-responsive table-gray-admin bg-white p-3">
                <table className="table table-dark-custom">
                  <thead>
                    <tr>
                      <th className="w-100px pl-2"> S.No. </th>{" "}
                      <th className="text-nowrap"> Rfq Id </th>
                      <th className="text-nowrap"> Service Id </th>{" "}
                      <th> Service Name </th>{" "}
                      <th className="text-nowrap"> Customer Name </th>{" "}
                      {/* <th className="text-nowrap">Warehouse Name</th> */}{" "}
                      {/* <th className="mw-150px">Warehouse Id</th> */}{" "}
                      <th> Status </th>{" "}
                      <th className="text-center"> Action </th>{" "}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {data.serviceRecieve && data.serviceRecieve.length > 0
                      ? data.serviceRecieve.map((item, index) => (
                          <ServiceReceiveList
                            item={item}
                            index={index + 1}
                            key={index}
                            read={read}
                          />
                        ))
                      : "Data Not Found"}{" "}
                  </tbody>{" "}
                </table>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="pagination-custom">
            {" "}
            {data.serviceList && data.serviceList !== undefined && (
              <Pagination
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={data.serviceList.totalCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                prevPageText={<i className="fas fa-chevron-left" />}
                nextPageText={<i className="fas fa-chevron-right" />}
                hideFirstLastPages={true}
              />
            )}{" "}
          </div>{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default ServiceReceive;
