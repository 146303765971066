import {
    BOOKING,
    BOOKING_BY_ID,
    BOOKING_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
    ERROR_MESSAGE
} from './../types';


const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    isErrorMsg: "",
    bookingList: null,
    bookingDetail: null,
    bookingResponse: null
}

export function BOOKING_INFO(state = initialState, action) {

    switch (action.type) {

        case BOOKING:
            return {
                ...state,
                bookingList: action.payload,
            };

        case BOOKING_BY_ID:
            return {
                ...state,
                bookingDetail: action.payload,
            };

        case BOOKING_RESPONSE:
            return {
                ...state,
                bookingResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        case ERROR_MESSAGE:
            return {
                ...state,
                isErrorMsg: action.payload,
            };

        default:
            return state;
    }
}