import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
// import {readableDate} from '../../components/helper/reuse';
import { whsById } from "../../store/action/whsUserAction";
import { useDispatch, useSelector } from "react-redux";

const WHSUserDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { whsUserId } = useParams();
  const data = useSelector((state) => state.WHS_USER_INFO);
  console.log("view data==>", data.whsUserDetail);

  useEffect(() => {
    dispatch(whsById(whsUserId));
  }, [dispatch, whsUserId]);

  return (
    <LayoutOne>
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 pt-3">
          <h5 className="text-dark-blue mb-3">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>{" "}
            View Details
          </h5>
        </div>
        <div className="col-12">
          {data.whsUserDetail ? (
            <div className="row">
              <div className="col-md-6 d-flex py-1">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> First Name </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data.whsUserDetail?.firstName}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-6 d-flex py-1">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Last Name </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data.whsUserDetail?.lastName}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-6 d-flex py-1">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Email ID </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data.whsUserDetail?.email}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-6 d-flex py-1">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Mobile No. </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data.whsUserDetail?.phone}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-6 d-flex py-1">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Role </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data.whsUserDetail?.accountRole?.name}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div className="col-md-6 d-flex py-3">
                                  <div className="card py-3 px-4 mb-0">
                                    <p className="mb-2">City</p>
                                    <p className="text-gray mb-0">Delhi</p>
                                  </div>
                                </div>
                                <div className="col-md-6 d-flex py-3">
                                  <div className="card py-3 px-4 mb-0">
                                    <p className="mb-2">Area</p>
                                    <p className="text-gray mb-0">Sector 62</p>
                                  </div>
                                </div> */}{" "}
              <div className="col-md-6 d-flex py-1">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Department </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data.whsUserDetail?.department?.name}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-md-6 d-flex py-1">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Departmental Head </p>{" "}
                  <p className="text-gray mb-0">
                    {" "}
                    {data.whsUserDetail?.isDepartmentHead ? "Yes" : "No"}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div className="col-md-6 d-flex py-3">
                                  <div className="card py-3 px-4 mb-0">
                                    <p className="mb-2">Created On</p>
                                    <p className="text-gray mb-0">{readableDate(data.whsUserDetail?.createdOn)}</p>
                                  </div>
                                </div> */}{" "}
              <div className="col-md-6 d-flex py-1">
                <div className="card py-3 px-4 mb-0">
                  <p className="mb-2"> Status </p>{" "}
                  <p className="text-success mb-0">
                    {" "}
                    {data.whsUserDetail?.active ? "Active" : "In Active"}{" "}
                  </p>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          ) : null}
          <div className="row">
            <div className="col-auto">
              <button
                onClick={() => history.goBack()}
                type="button"
                className="btn btn-outline-deep-blue toggle-className my-4 py-2"
              >
                {" "}
                Back{" "}
              </button>{" "}
            </div>{" "}
            <div className="col-auto">
              <a
                href="view-whs-user-log.html"
                className="btn btn-deep-blue toggle-className my-4 px-5 py-2"
              >
                {" "}
                <i className="fas fa-file-alt mr-3"> </i> View Log
              </a>
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default WHSUserDetails;
