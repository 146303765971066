import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { CardLoader } from "../../../components/common/CustomLoader";

const OtherServiceRequirementForm = ({ isView }) => {
  const data = useSelector((state) => state.RFQ_INFO);
  // console.log("Other service RFQ===>", data)

  const [jsonData, setJsonData] = useState([
    {
      descriptionType: "IT",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "WMS",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "DMS",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "TMS",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "Material Handling Equipments -",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "Forklift",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "Hand Pallets",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "Reach Staker",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "Wooden/Plastic Pallets",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "Hand Trolleys",
      yesNo: "",
      remark: "",
    },
    {
      descriptionType: "Others (pls specify)",
      yesNo: "",
      remark: "",
    },
  ]);

  const sarSchema = Yup.object().shape({
    otherServiceRequirements: Yup.array().of(
      Yup.object().shape({
        noOfIds: Yup.string().required("Required"),
        quantity: Yup.string().required("Required"),
        remark: Yup.string().required("Required"),
      })
    ),
  });

  useEffect(() => {
    if (
      data?.rfqDetail?.data &&
      data?.rfqDetail?.data &&
      data?.rfqDetail?.data?.otherServiceRequirements &&
      data?.rfqDetail?.data?.otherServiceRequirements.length !== 0
    ) {
      console.log(data.rfqDetail.data.otherServiceRequirements,"|||i am other service sectgion|||||||")
      setJsonData(data.rfqDetail.data.otherServiceRequirements);
    }
  }, [data?.rfqDetail?.data]);

  return (
    <>
      {" "}
      {data.isLoading ? (
        <CardLoader loaderCard="loaderCard" />
      ) : (
        <Formik
          enableReinitialize={true}
          validationSchema={sarSchema}
          initialValues={{
            otherServiceRequirements: jsonData,
          }}
          onSubmit={(fields) => {
            console.log("---->", fields);
          }}
          render={({ values, errors, status, onChange, touched }) => {
            return (
              <div className="w-100 d-block">
                <Form>
                  <div className="row">
                    <div className="row col-12 ml-0">
                      <div className="col-12 px-0 table-responsive border bg-deep-gray rounded-md table-cell">
                        <table className="table text-center form-control-height">
                          <thead>
                            <tr>
                              <td className="border-top-0 font-weight-bold py-2">
                                {" "}
                                S.no{" "}
                              </td>{" "}
                              <td className="text-left border-top-0 font-weight-bold text-nowrap py-2 w-160px">
                                {" "}
                                Description{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold text-nowrap py-2 mw-150px">
                                {" "}
                                Action{" "}
                              </td>{" "}
                              <td className="border-top-0 font-weight-bold py-2 mw-200px">
                                {" "}
                                Remarks{" "}
                              </td>{" "}
                            </tr>{" "}
                          </thead>{" "}
                          <tbody>
                            {values.otherServiceRequirements.length > 0 &&
                              values.otherServiceRequirements.map(
                                (man, index) => {
                                  const boundErrors =
                                    (errors.otherServiceRequirements?.length &&
                                      errors.otherServiceRequirements[index]) ||
                                    {};
                                  const boundTouched =
                                    (touched.otherServiceRequirements?.length &&
                                      touched.otherServiceRequirements[
                                        index
                                      ]) ||
                                    {};
                                  return (
                                    <tr>
                                      <td> {index + 1} </td>{" "}
                                      <td className="text-left">
                                        {" "}
                                        {man.descriptionType}{" "}
                                      </td>{" "}                                      
                                      <td>
                                        {JSON.stringify(man.yesNo)==="true"?("Yes"):("No")}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`otherServiceRequirements.${index}.remark`}
                                          type="text"
                                          className={
                                            `form-control text-center bg-white  my-1` +
                                            (boundErrors.remark &&
                                            boundTouched.remark
                                              ? " is-invalid"
                                              : "")
                                          }
                                          readOnly={isView}
                                        />{" "}
                                        <ErrorMessage
                                          name={`otherServiceRequirements.${index}.remark`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </td>{" "}
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>{" "}
                        </table>{" "}
                      </div>{" "}
                    </div>
                  </div>{" "}
                </Form>{" "}
              </div>
            );
          }}
        />
      )}{" "}
    </>
  );
};

export default OtherServiceRequirementForm;
