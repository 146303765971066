import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import LayoutOne from '../../layout/LayoutOne';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
// import WarehousePendingForm from '../../wrapper/warehouseManagement/pendingForms/WarehousePendingForm';
// import ContactPendingForm from '../../wrapper/warehouseManagement/pendingForms/ContactPendingForm';
// import SpacePendingForm from '../../wrapper/warehouseManagement/pendingForms/SpacePendingForm';
// import OfficePendingForm from '../../wrapper/warehouseManagement/pendingForms/OfficePendingForm';
// import MhPendingForm from '../../wrapper/warehouseManagement/pendingForms/MhPendingForm';
// import SafetyPendingForm from '../../wrapper/warehouseManagement/pendingForms/SafetyPendingForm';
// import PermitPendingForm from '../../wrapper/warehouseManagement/pendingForms/PermitPendingForm';
// import MaterialPendingForm from '../../wrapper/warehouseManagement/pendingForms/MaterialPendingForm';
// import PhotoPendingForm from '../../wrapper/warehouseManagement/pendingForms/PhotoPendingForm';
// import EightyPendingForm from '../../wrapper/warehouseManagement/pendingForms/EightyPendingForm';
import {
  fetchWarehouseByIdAndType,
  WarehouseByIdType,
  updateWarehouseStatusResubmitted,
  updateWarehouseStatusAdminApproved,
  changeWarehouseStatus,
  changeWarehouseStatus1,
} from '../../store/action/warehouseAction';
import WarehouseAgentBasicForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/WarehouseAgentBasicForm';
import ContactAgentForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/ContactAgentForm';
import SpaceAgentForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/SpaceAgentForm';
import OfficeAgentForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/OfficeAgentForm';
import MhAgentForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/MhAgentForm';
import SafetyApprovedForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/SafetyAgentForm';
import PermitAgentForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/PermitAgentForm';
import MaterialAgentForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/MaterialAgentForm';
import EightyAgentForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/EightyAgentForm';
import PhotoAgentForm from '../../wrapper/warehouseManagement/assignagentrejectedforms/PhotoAgentForm';

import FormSuccess from '../../components/common/FormSuccess';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '5px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    padding: '15px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const AssignedFieldAgentDetails = () => {
  const { warehouseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const data = useSelector((state) => state.WAREHOUSEINFO);

  // const data=useSelector((state)=>state.WAREHOUSELIST);
  // console.log("Show--->", data.isError)

  const handleChange = (panel, type) => (event, isExpanded) => {
    if (isExpanded) {
      dispatch(fetchWarehouseByIdAndType(warehouseId, type));
    }

    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(fetchWarehouseByIdAndType(warehouseId, 'warehouse'));
    return () => {
      dispatch(WarehouseByIdType(null));
    };
  }, [dispatch, warehouseId]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet='utf-8' />
        <title> Assign Field Agent Warehouse </title>{' '}
      </Helmet>{' '}
      {data.finalSubmitVendor ? (
        <FormSuccess
          onClick={() => {
            dispatch(changeWarehouseStatus1());
            history.replace(`/pending-warehouse?page=1`);
          }}
          message='Resubmitted To Vendor'
        />
      ) : null}
      {data.finalSubmit ? (
        <FormSuccess
          onClick={() => {
            dispatch(changeWarehouseStatus());
            history.replace(`/assign-to-field-agent-rejected?page=1`);
          }}
          message='your submission has been send for approval. We will respond to you very soon'
        />
      ) : null}
      <div className='row justify-content-end align-items-center py-3 px-3 mx-0'>
        <div className='col-12 px-0 wh-spacing-y-0'>
          <div className='row'>
            <div className='col-12'>
              <div className={classes.root}>
                <h5 className='backButton my-4'>
                  {' '}
                  <i
                    onClick={() => history.goBack()}
                    className='fas fa-chevron-left mr-3 cursorPointer'
                  >
                    {' '}
                  </i>
                  Field Agent Verification Checklist
                </h5>

                {/* Create New Warehouse */}

                <Accordion
                  expanded={expanded === 'panel'}
                  onChange={handleChange('panel', 'warehouse')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 1. Warehouse Details </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails>
                    <WarehouseAgentBasicForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* Contact Detail Form */}

                <Accordion
                  expanded={expanded === 'panel1'}
                  onChange={handleChange('panel1', 'warehouseContactDetails')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 2. Warehouse Contact Details </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails>
                    {' '}
                    {/* <ContactPendingForm warehouseId = {warehouseId} /> */}{' '}
                    <ContactAgentForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* Another Form */}

                <Accordion
                  expanded={expanded === 'panel2'}
                  onChange={handleChange('panel2', 'storageSpace')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 3. Storage Space </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails>
                    {' '}
                    {/* <SpacePendingForm /> */} <SpaceAgentForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* IT INFRA Form */}

                <Accordion
                  expanded={expanded === 'panel3'}
                  onChange={handleChange('panel3', 'itInfrAndOfficeSpace')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 4. IT & Office Infra </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails>
                    {' '}
                    {/* <OfficePendingForm /> */} <OfficeAgentForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* MH Infra */}

                <Accordion
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4', 'mhInfra')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 5. MH Infra </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails>
                    {' '}
                    {/* <MhPendingForm /> */} <MhAgentForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* Safety Security Form */}

                <Accordion
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5', 'safetyAndSecurity')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 6. Safety Security Form </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails>
                    {' '}
                    {/* <SafetyPendingForm /> */} <SafetyApprovedForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* Permit Form */}

                <Accordion
                  expanded={expanded === 'panel6'}
                  onChange={handleChange('panel6', 'buildingTradeRelatedPermit')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 7. Permits </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails className='m-0 p-0'>
                    {' '}
                    {/* <PermitPendingForm /> */} <PermitAgentForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* Material Type Form */}

                <Accordion
                  expanded={expanded === 'panel7'}
                  onChange={handleChange('panel7', 'materialType')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 8. Material Type </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails className='m-0 p-0'>
                    {' '}
                    {/* <MaterialPendingForm /> */} <MaterialAgentForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* Warehouse Photo Form */}

                <Accordion
                  expanded={expanded === 'panel8'}
                  onChange={handleChange('panel8', 'warehouseImages')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 9. Warehouse Photo </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails className='m-0 p-0'>
                    {' '}
                    {/* <PhotoPendingForm /> */} <PhotoAgentForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/*  Form Eighty*/}

                <Accordion
                  expanded={expanded === 'panel10'}
                  onChange={handleChange('panel10', 'formEighty')}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'
                  >
                    <Typography className={classes.heading}>
                      {' '}
                      <span className='accord-heading'> 10. Form 80 </span>
                    </Typography>
                  </AccordionSummary>{' '}
                  <AccordionDetails className='m-0 p-0'>
                    {' '}
                    {/* <EightyPendingForm /> */} <EightyAgentForm warehouseId={warehouseId} />{' '}
                  </AccordionDetails>{' '}
                </Accordion>

                {/* </>)
                          }  */}
              </div>
              <div className='col-12 mt-4'>
                <div className='row justify-content-end'>
                  <div className='col-auto my-2'>
                    <button
                      onClick={() =>
                        dispatch(
                          updateWarehouseStatusResubmitted(warehouseId, {
                            status: 'PENDINGFORAPPROVAL',
                          }),
                        )
                      }
                      type='button'
                      className='btn btn-deep-blue'
                    >
                      {' '}
                      Re-Submit to SP{' '}
                    </button>{' '}
                  </div>{' '}
                  <div className='col-auto my-2'>
                    <button
                      onClick={() =>
                        dispatch(
                          updateWarehouseStatusAdminApproved(warehouseId, {
                            status: 'Listed_and_Verified',
                          }),
                        )
                      }
                      type='button'
                      className='btn btn-success'
                    >
                      {' '}
                      Approve for Listing{' '}
                    </button>{' '}
                  </div>{' '}
                </div>{' '}
              </div>{' '}
            </div>{' '}
          </div>{' '}
        </div>{' '}
      </div>
    </LayoutOne>
  );
};

export default AssignedFieldAgentDetails;
