import {
    NOTIFICATION,
    NOTIFICATION_BY_ID,
    NOTIFICATION_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    notificationList: [],
    notificationDetail: null,
    notificationResponse: null
}

export function NOTIFICATION_INFO(state = initialState, action) {

    switch (action.type) {

        case NOTIFICATION:
            return {
                ...state,
                notificationList: action.payload,
            };

        case NOTIFICATION_BY_ID:
            return {
                ...state,
                notificationDetail: action.payload,
            };

        case NOTIFICATION_RESPONSE:
            return {
                ...state,
                notificationResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        default:
            return state;
    }
}