import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateStorageSpace,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";

const SpacePendingForm = ({ warehouseId, viewMood }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const validationSchema = function (values) {
    return Yup.object().shape({
      storageType: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      totalArea: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      palletsOnFloor: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      racks: Yup.array().of(
        Yup.object().shape({
          rack: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      availableSpace: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      loadingAndUnloadingBays: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      dockSize: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      parkingArea: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        storageSpaceRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateStorageSpace(payload));
  };
  const [formField, setFormField] = useState(null);
  const [remarkForm, setRemarkForm] = useState({
    storageType: {
      value: "",
      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    totalArea: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    noOfShift: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    palletsOnFloor: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    racks: [
      {
        rackName: "bins-up",
        rack: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        rackName: "shelveup",
        rack: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    availableSpace: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    loadingAndUnloadingBays: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    dockSize: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    parkingArea: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });
  const [ground, setground] = useState("");
  const [basement, setbasement] = useState("");
  const [first, setfirst] = useState("");
  const [second, setsecond] = useState("");

  const [ground1, setground1] = useState("");
  const [basement1, setbasement1] = useState("");
  const [first1, setfirst1] = useState("");
  const [second1, setsecond1] = useState("");
  useEffect(() => {
    if (data.singleFormData && data.type === "storageSpace") {
      setFormField(data.singleFormData.storageSpaceInfo);
      let spaceInfo = data.singleFormData.storageSpaceInfo;
      let gFloor = spaceInfo.floors.find((o) => o.floorType === "groundFloor");
      let bFloor = spaceInfo.floors.find((o) => o.floorType === "basement");
      let fFloor = spaceInfo.floors.find((o) => o.floorType === "firstFloor");
      let sFloor = spaceInfo.floors.find((o) => o.floorType === "secondFloor");
      setground(gFloor);
      setbasement(bFloor);
      setfirst(fFloor);
      setsecond(sFloor);
      //availablespace
      let gFloor1 = spaceInfo.availableSpaces.find(
        (o) => o.availableSpaceType === "groundFloor"
      );
      let bFloor1 = spaceInfo.availableSpaces.find(
        (o) => o.availableSpaceType === "basement"
      );
      let fFloor1 = spaceInfo.availableSpaces.find(
        (o) => o.availableSpaceType === "firstFloor"
      );
      let sFloor1 = spaceInfo.availableSpaces.find(
        (o) => o.availableSpaceType === "secondFloor"
      );

      setground1(gFloor1);
      setbasement1(bFloor1);
      setfirst1(fFloor1);
      setsecond1(sFloor1);
      if (data.singleFormData.storageSpaceRemark) {
        setRemarkForm({
          storageType: {
            value: "",
            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.faremark
              : "",
          },
          totalArea: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.faremark
              : "",
          },
          palletsOnFloor: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.faremark
              : "",
          },
          noOfShift: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.noOfShift.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.noOfShift.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.noOfShift.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.noOfShift.faremark
              : "",
          },
          racks: [
            {
              rackName: "bins-up",
              rack: {
                value: "",

                whsstatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .faremark
                    : ""
                  : "",
              },
            },
            {
              rackName: "shelveup",
              rack: {
                value: "",

                whsstatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .faremark
                    : ""
                  : "",
              },
            },
          ],
          availableSpace: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.faremark
              : "",
          },
          loadingAndUnloadingBays: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .faremark
              : "",
          },
          dockSize: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.faremark
              : "",
          },
          parkingArea: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.faremark
              : "",
          },
        });
      } else {
        setRemarkForm({
          storageType: {
            value: "",
            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.storageType.faremark
              : "",
          },
          totalArea: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.totalArea.faremark
              : "",
          },
          palletsOnFloor: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.palletsOnFloor.faremark
              : "",
          },
          racks: [
            {
              rackName: "bins-up",
              rack: {
                value: "",

                whsstatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[0]
                    ? data.singleFormData.storageSpaceRemark.racks[0].rack
                        .faremark
                    : ""
                  : "",
              },
            },
            {
              rackName: "shelveup",
              rack: {
                value: "",

                whsstatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.storageSpaceRemark
                  ? data.singleFormData.storageSpaceRemark.racks[1]
                    ? data.singleFormData.storageSpaceRemark.racks[1].rack
                        .faremark
                    : ""
                  : "",
              },
            },
          ],
          availableSpace: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.availableSpace.faremark
              : "",
          },
          loadingAndUnloadingBays: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.loadingAndUnloadingBays
                  .faremark
              : "",
          },
          dockSize: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.dockSize.faremark
              : "",
          },
          parkingArea: {
            value: "",

            whsstatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.whsstatus
              : "",
            whsremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.whsremark
              : "",
            fastatus: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.fastatus
              : "",
            faremark: data.singleFormData.storageSpaceRemark
              ? data.singleFormData.storageSpaceRemark.parkingArea.faremark
              : "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form className="w-100">
              <div className="col-12  rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              Okay{" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              Not Okay{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Remarks{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Field Agent Remarks{" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Storage Type:
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <input
                                        className="form-control px-1"
                                        value={
                                          formField ? formField.storageType : ""
                                        }
                                        disabled
                                      />
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="storageType.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.storageType.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "storageType.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="storageType.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      hidden="true"
                                      id="nbcategoryNotOkay"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.storageType.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "storageType.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`storageType.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.storageType
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.storageType
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className=" px-2"
                                    >
                                      Floor:{" "}
                                      <sup className="text-danger"> * </sup>{" "}
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className=" pb-0 px-0">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th scope="col" class="col-auto py-0">
                                            {" "}
                                            {basement &&
                                            basement.floorDimension.length !==
                                              "" ? (
                                              <td className=" py-0">
                                                {" "}
                                                <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                                  <label
                                                    className=" pl-4 mr-3 text-nowrap"
                                                    htmlFor="defaultCheck1"
                                                  >
                                                    Basement{" "}
                                                  </label>{" "}
                                                </div>{" "}
                                              </td>
                                            ) : null}{" "}
                                          </th>{" "}
                                          <th
                                            scope="col"
                                            className="col-auto py-0"
                                          >
                                            {ground &&
                                            ground.floorDimension.length !==
                                              "" ? (
                                              <td className=" py-0">
                                                {" "}
                                                <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                                  <label
                                                    className=" pl-4 mr-3 text-nowrap"
                                                    htmlFor="defaultCheck1"
                                                  >
                                                    Ground Floor{" "}
                                                  </label>{" "}
                                                </div>{" "}
                                              </td>
                                            ) : null}{" "}
                                          </th>
                                          <th
                                            scope="col"
                                            className="text-center col-auto py-0"
                                          >
                                            {first &&
                                            first.floorDimension.length !==
                                              "" ? (
                                              <td className=" py-0">
                                                <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                                  <label
                                                    className=" pl-4 mr-3 text-nowrap"
                                                    htmlFor="defaultCheck1"
                                                  >
                                                    1 st Floor{" "}
                                                  </label>{" "}
                                                </div>{" "}
                                              </td>
                                            ) : null}{" "}
                                          </th>{" "}
                                          <th
                                            scope="col"
                                            className="text-center py-0"
                                          >
                                            {second &&
                                            second.floorDimension.length !==
                                              "" ? (
                                              <td className=" py-0">
                                                <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                                  <label
                                                    className=" pl-4 mr-3 text-nowrap"
                                                    htmlFor="defaultCheck1"
                                                  >
                                                    2 nd Floor{" "}
                                                  </label>{" "}
                                                </div>{" "}
                                              </td>
                                            ) : null}{" "}
                                          </th>{" "}
                                        </tr>{" "}
                                      </thead>
                                      <tbody>
                                        <tr>
                                          {" "}
                                          {basement &&
                                          basement.floorDimension.length !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  basement &&
                                                  basement.floorDimension.length
                                                }
                                                placeholder="Length"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {ground &&
                                          ground.floorDimension.length !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  ground &&
                                                  ground.floorDimension.length
                                                }
                                                placeholder="Length"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {first &&
                                          first.floorDimension.length !== "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  first &&
                                                  first.floorDimension.length
                                                }
                                                placeholder="Length"
                                                disabled
                                              />
                                            </td>
                                          ) : null}
                                          {second &&
                                          second.floorDimension.length !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  second &&
                                                  second.floorDimension.length
                                                }
                                                placeholder="Length"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                        </tr>{" "}
                                        <tr>
                                          {" "}
                                          {basement &&
                                          basement.floorDimension.breath !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Breadth{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  basement &&
                                                  basement.floorDimension.breath
                                                }
                                                placeholder="Breadth"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {ground &&
                                          ground.floorDimension.breath !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Breadth{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  ground &&
                                                  ground.floorDimension.breath
                                                }
                                                placeholder="Breadth"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {first &&
                                          first.floorDimension.breath !== "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Breadth{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  first &&
                                                  first.floorDimension.breath
                                                }
                                                placeholder="Breadth"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {second &&
                                          second.floorDimension.breath !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Breadth{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  second &&
                                                  second.floorDimension.breath
                                                }
                                                placeholder="Breadth"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                        </tr>{" "}
                                        <tr>
                                          {" "}
                                          {basement &&
                                          basement.floorDimension.height !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  basement &&
                                                  basement.floorDimension.height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {ground &&
                                          ground.floorDimension.height !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  ground &&
                                                  ground.floorDimension.height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}
                                          {first &&
                                          first.floorDimension.height !== "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  first &&
                                                  first.floorDimension.height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {second &&
                                          second.floorDimension.height !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  second &&
                                                  second.floorDimension.height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                        </tr>{" "}
                                      </tbody>{" "}
                                    </table>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue "
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="totalArea.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="xxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.totalArea.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "totalArea.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="xxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless  mx-0 pl-2">
                                <tr>
                                  <td className="py-0 form-check common-radio-deep-blue">
                                    <Field
                                      name="totalArea.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="yynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.totalArea.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "totalArea.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="yynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1 w-100 px-0">
                                <tr>
                                  <td className="pb-0 pt-3 px-0 w-100">
                                    <Field
                                      disabled={viewMood}
                                      name={`totalArea.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.totalArea
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.totalArea
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Pallets on Floor:
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <input
                                        value={
                                          formField
                                            ? formField.palletsOnFloor
                                            : ""
                                        }
                                        className="form-control px-4"
                                        disabled
                                      />
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="palletsOnFloor.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="jjxxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.palletsOnFloor.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "palletsOnFloor.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="jjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="palletsOnFloor.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="jjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.palletsOnFloor.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "palletsOnFloor.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="jjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`palletsOnFloor.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.palletsOnFloor
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.palletsOnFloor
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Racks (Shelve  Racks) - No  of  Shelve:
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <input
                                        value={
                                          formField
                                            ? formField.palletsOnFloor
                                            : ""
                                        }
                                        className="form-control px-4"
                                        disabled
                                      />
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="palletsOnFloor.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="jjxxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.palletsOnFloor.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "palletsOnFloor.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="jjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="palletsOnFloor.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="jjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.palletsOnFloor.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "palletsOnFloor.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="jjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`palletsOnFloor.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.palletsOnFloor
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.palletsOnFloor
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className=" px-2"
                                    >
                                      Racks (Slotted  Angle) - No  of  Bins:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className=" pb-0 px-0">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th
                                            scope="col"
                                            class="col-auto py-0 mt-3"
                                          >
                                            <input
                                              className="form-control px-1"
                                              value={
                                                formField &&
                                                formField.racks &&
                                                formField.racks.length > 0
                                                  ? "Yes"
                                                  : "No"
                                              }
                                              disabled
                                            />
                                          </th>{" "}
                                          <th
                                            scope="col"
                                            className="col-auto py-0"
                                          >
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Quantity{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                value={
                                                  formField &&
                                                  formField.racks &&
                                                  formField.racks.length > 0
                                                    ? formField.racks[1]
                                                        .rackInfo.quantity
                                                    : ""
                                                }
                                                className="form-control px-4"
                                                placeholder="Qty"
                                                disabled
                                              />
                                            </td>{" "}
                                          </th>
                                          <th
                                            scope="col"
                                            className="text-center col-auto py-0"
                                          >
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                value={
                                                  formField &&
                                                  formField.racks &&
                                                  formField.racks.length > 0
                                                    ? formField.racks[1]
                                                        .rackInfo.length
                                                    : ""
                                                }
                                                className="form-control px-4"
                                                placeholder="L"
                                                disabled
                                              />
                                            </td>{" "}
                                          </th>{" "}
                                          <th
                                            scope="col"
                                            className="text-center py-0"
                                          >
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                value={
                                                  formField &&
                                                  formField.racks &&
                                                  formField.racks.length > 0
                                                    ? formField.racks[1]
                                                        .rackInfo.height
                                                    : ""
                                                }
                                                className="form-control px-4"
                                                placeholder="H"
                                                disabled
                                              />
                                            </td>{" "}
                                          </th>{" "}
                                        </tr>{" "}
                                      </thead>{" "}
                                    </table>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue "
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="racks[1].rack.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="ccmmjjxxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.racks[1]
                                          ? values.racks[1].rack.whsstatus ===
                                            true
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "racks[1].rack.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="ccmmjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless  mx-0 pl-2">
                                <tr>
                                  <td className="py-0 form-check common-radio-deep-blue">
                                    <Field
                                      name="racks[1].rack.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="jjwwjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.racks[1]
                                          ? values.racks[1].rack.whsstatus ===
                                            false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "racks[1].rack.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="jjwwjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1 w-100 px-0">
                                <tr>
                                  <td className="pb-0 pt-3 px-0 w-100">
                                    <Field
                                      disabled={viewMood}
                                      name={`racks[1].rack.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.totalArea
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.racks &&
                                    formField.storageSpaceRemark.racks.length >
                                      0 &&
                                    formField.storageSpaceRemark.racks[1].rack
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        No Of Shifts:
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <input
                                        value={
                                          formField ? formField.noOfShift : ""
                                        }
                                        className="form-control px-4"
                                        disabled
                                      />
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="noOfShift.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="bbjjxxnbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.noOfShift.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "noOfShift.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="noOfShift.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="bbjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.noOfShift.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "noOfShift.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`noOfShift.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.noOfShift
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.noOfShift
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        No.of Loading / unloading Bays:
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <input
                                        type="text"
                                        value={
                                          formField
                                            ? formField.loadingAndUnloadingBays
                                            : ""
                                        }
                                        className="form-control px-4"
                                        disabled
                                      />
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="loadingAndUnloadingBays.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="xxrxbbjjxxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.loadingAndUnloadingBays
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "loadingAndUnloadingBays.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="xxrxbbjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="loadingAndUnloadingBays.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="yymxbbjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.loadingAndUnloadingBays
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "loadingAndUnloadingBays.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="yymxbbjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`loadingAndUnloadingBays.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark
                                    .loadingAndUnloadingBays.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark
                                      .loadingAndUnloadingBays.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Dock Size:
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <tr>
                                        <table>
                                          <td className="py-0">
                                            <label
                                              className=" pl-4 mr-3 text-nowrap"
                                              htmlFor="defaultCheck1"
                                            >
                                              Height From Ground{" "}
                                            </label>{" "}
                                            <input
                                              type="text"
                                              value={
                                                formField && formField.dockSize
                                                  ? formField.dockSize
                                                      .heightFromGound
                                                  : ""
                                              }
                                              className="form-control px-4"
                                              placeholder="Height From Ground"
                                              disabled
                                            />
                                          </td>{" "}
                                          <td className="py-0">
                                            <label
                                              className=" pl-4 mr-3"
                                              htmlFor="defaultCheck1"
                                            >
                                              Height{" "}
                                            </label>{" "}
                                            <input
                                              type="text"
                                              value={
                                                formField && formField.dockSize
                                                  ? formField.dockSize.height
                                                  : ""
                                              }
                                              className="form-control px-4"
                                              placeholder="Enter Height"
                                              disabled
                                            />
                                          </td>{" "}
                                          <td className="py-0">
                                            <label
                                              className=" pl-4 mr-3"
                                              htmlFor="defaultCheck1"
                                            >
                                              Width{" "}
                                            </label>{" "}
                                            <input
                                              type="text"
                                              value={
                                                formField && formField.dockSize
                                                  ? formField.dockSize.width
                                                  : ""
                                              }
                                              className="form-control px-4"
                                              placeholder="Enter Width"
                                              disabled
                                            />
                                          </td>{" "}
                                        </table>{" "}
                                      </tr>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="dockSize.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="rxbbjjxxnbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.dockSize.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "dockSize.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="rxbbjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="dockSize.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="mxbbjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.dockSize.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "dockSize.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="mxbbjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`loadingAndUnloadingBays.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.dockSize
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.dockSize
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        {formField !== null ? (
                                          <p className="mb-0">
                                            Parking Area(
                                            {formField.floors
                                              ? formField.floors[0].unit ===
                                                "FEET"
                                                ? "sqft"
                                                : ""
                                              : ""}{" "}
                                            {formField.floors
                                              ? formField.floors[0].unit ===
                                                "INCH"
                                                ? "sqin"
                                                : ""
                                              : ""}{" "}
                                            {formField.floors
                                              ? formField.floors[0].unit === "M"
                                                ? "sqm"
                                                : ""
                                              : ""}
                                            ):
                                          </p>
                                        ) : null}{" "}
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <tr>
                                        <table>
                                          <td className="py-0">
                                            <input
                                              className="form-control px-1"
                                              value={formField ? "Yes" : "No"}
                                              disabled
                                            />
                                          </td>{" "}
                                          <td className="py-0">
                                            <input
                                              type="text"
                                              value={
                                                formField
                                                  ? formField.parkingArea
                                                  : ""
                                              }
                                              className="form-control px-4"
                                              disabled
                                            />
                                          </td>{" "}
                                        </table>{" "}
                                      </tr>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="parkingArea.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="zzrxbbjjxxnbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.parkingArea.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "parkingArea.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="zzrxbbjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="parkingArea.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nnmxbbjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.parkingArea.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "parkingArea.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nnmxbbjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`parkingArea.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.parkingArea
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.parkingArea
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        {formField !== null ? (
                                          <p className="mb-0">
                                            Parking Area(
                                            {formField.floors
                                              ? formField.floors[0].unit ===
                                                "FEET"
                                                ? "sqft"
                                                : ""
                                              : ""}{" "}
                                            {formField.floors
                                              ? formField.floors[0].unit ===
                                                "INCH"
                                                ? "sqin"
                                                : ""
                                              : ""}{" "}
                                            {formField.floors
                                              ? formField.floors[0].unit === "M"
                                                ? "sqm"
                                                : ""
                                              : ""}
                                            ):
                                          </p>
                                        ) : null}{" "}
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <tr>
                                        <table>
                                          <td className="py-0">
                                            <input
                                              className="form-control px-1"
                                              value={formField ? "Yes" : "No"}
                                              disabled
                                            />
                                          </td>{" "}
                                          <td className="py-0">
                                            <input
                                              type="text"
                                              value={
                                                formField
                                                  ? formField.parkingArea
                                                  : ""
                                              }
                                              className="form-control px-4"
                                              disabled
                                            />
                                          </td>{" "}
                                        </table>{" "}
                                      </tr>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="parkingArea.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="zzrxbbjjxxnbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.parkingArea.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "parkingArea.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="zzrxbbjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="parkingArea.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nnmxbbjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.parkingArea.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "parkingArea.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nnmxbbjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`parkingArea.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.parkingArea
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.parkingArea
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className=" px-2"
                                    >
                                      Available Space:
                                      <sup className="text-danger">
                                        {" "}
                                        *{" "}
                                      </sup>{" "}
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className=" pb-0 px-0">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th scope="col" class="col-auto py-0">
                                            {" "}
                                            {basement1 &&
                                            basement1.availableSpaceDimension
                                              .length !== "" ? (
                                              <td className=" py-0">
                                                {" "}
                                                <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                                  <label
                                                    className=" pl-4 mr-3"
                                                    htmlFor="defaultCheck1"
                                                  >
                                                    Basement{" "}
                                                  </label>{" "}
                                                </div>{" "}
                                              </td>
                                            ) : null}{" "}
                                          </th>{" "}
                                          <th
                                            scope="col"
                                            className="col-auto py-0"
                                          >
                                            {ground1 &&
                                            ground1.availableSpaceDimension
                                              .length !== "" ? (
                                              <td className=" py-0">
                                                {" "}
                                                <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                                  <label
                                                    className=" pl-4 mr-3 text-nowrap"
                                                    htmlFor="defaultCheck1"
                                                  >
                                                    Ground Floor{" "}
                                                  </label>{" "}
                                                </div>{" "}
                                              </td>
                                            ) : null}{" "}
                                          </th>
                                          <th
                                            scope="col"
                                            className="text-center col-auto py-0"
                                          >
                                            {first1 &&
                                            first1.availableSpaceDimension
                                              .length !== "" ? (
                                              <td className=" py-0">
                                                <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                                  <label
                                                    className=" pl-4 mr-3 text-nowrap"
                                                    htmlFor="defaultCheck1"
                                                  >
                                                    1 st Floor{" "}
                                                  </label>{" "}
                                                </div>{" "}
                                              </td>
                                            ) : null}{" "}
                                          </th>{" "}
                                          <th
                                            scope="col"
                                            className="text-center py-0"
                                          >
                                            {second1 &&
                                            second1.availableSpaceDimension
                                              .length !== "" ? (
                                              <td className=" py-0">
                                                <div className="common-checkbox-deep-blue2 position-relative mb-2 mx-auto d-inline-block">
                                                  <label
                                                    className=" pl-4 mr-3 text-nowrap"
                                                    htmlFor="defaultCheck1"
                                                  >
                                                    2 nd Floor{" "}
                                                  </label>{" "}
                                                </div>{" "}
                                              </td>
                                            ) : null}{" "}
                                          </th>{" "}
                                        </tr>{" "}
                                      </thead>
                                      <tbody>
                                        <tr>
                                          {" "}
                                          {basement1 &&
                                          basement1.availableSpaceDimension
                                            .length !== "" ? (
                                            <td className="  py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  basement1 &&
                                                  basement1
                                                    .availableSpaceDimension
                                                    .length
                                                }
                                                placeholder="Length"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {ground1 &&
                                          ground1.availableSpaceDimension
                                            .length !== "" ? (
                                            <td className="  py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  ground1 &&
                                                  ground1
                                                    .availableSpaceDimension
                                                    .length
                                                }
                                                placeholder="Length"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {first1 &&
                                          first1.availableSpaceDimension
                                            .length !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  first1 &&
                                                  first1.availableSpaceDimension
                                                    .length
                                                }
                                                placeholder="Length"
                                                disabled
                                              />
                                            </td>
                                          ) : null}
                                          {second1 &&
                                          second1.availableSpaceDimension
                                            .length !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Length{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  second1 &&
                                                  second1
                                                    .availableSpaceDimension
                                                    .length
                                                }
                                                placeholder="Length"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                        </tr>{" "}
                                        <tr>
                                          {" "}
                                          {basement1 &&
                                          basement1.availableSpaceDimension
                                            .breath !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Breadth{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  basement1 &&
                                                  basement1
                                                    .availableSpaceDimension
                                                    .breath
                                                }
                                                placeholder="Breadth"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {ground1 &&
                                          ground1.availableSpaceDimension
                                            .breath !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Breadth{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  ground1 &&
                                                  ground1
                                                    .availableSpaceDimension
                                                    .breath
                                                }
                                                placeholder="Breadth"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {first1 &&
                                          first1.availableSpaceDimension
                                            .breath !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Breadth{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  first1 &&
                                                  first1.availableSpaceDimension
                                                    .breath
                                                }
                                                placeholder="Breadth"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {second1 &&
                                          second1.availableSpaceDimension
                                            .breath !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Breadth{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  second1 &&
                                                  second1
                                                    .availableSpaceDimension
                                                    .breath
                                                }
                                                placeholder="Breadth"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                        </tr>{" "}
                                        <tr>
                                          {" "}
                                          {basement &&
                                          basement.floorDimension.height !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  basement &&
                                                  basement.floorDimension.height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {ground &&
                                          ground.floorDimension.height !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  ground &&
                                                  ground.floorDimension.height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}
                                          {first &&
                                          first.floorDimension.height !== "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  first &&
                                                  first.floorDimension.height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {second &&
                                          second.floorDimension.height !==
                                            "" ? (
                                            <td className="">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  second &&
                                                  second.floorDimension.height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                        </tr>{" "}
                                        <tr>
                                          {" "}
                                          {basement1 &&
                                          basement1.availableSpaceDimension
                                            .height !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  basement1 &&
                                                  basement1
                                                    .availableSpaceDimension
                                                    .height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {ground1 &&
                                          ground1.availableSpaceDimension
                                            .height !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  ground1 &&
                                                  ground1
                                                    .availableSpaceDimension
                                                    .height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}
                                          {first1 &&
                                          first1.availableSpaceDimension
                                            .height !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  first1 &&
                                                  first1.availableSpaceDimension
                                                    .height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {second1 &&
                                          second1.availableSpaceDimension
                                            .height !== "" ? (
                                            <td className=" py-0">
                                              <label
                                                className=" pl-4 mr-3"
                                                htmlFor="defaultCheck1"
                                              >
                                                Height{" "}
                                              </label>{" "}
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  second1 &&
                                                  second1
                                                    .availableSpaceDimension
                                                    .height
                                                }
                                                placeholder="Height"
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                        </tr>
                                        {formField !== null ? (
                                          <p className="mb-0 text-nowrap pt-2">
                                            Total Available Space(
                                            {formField &&
                                            formField.floors &&
                                            formField.floors.length > 0
                                              ? formField.floors[0].unit ===
                                                "FEET"
                                                ? "sqft"
                                                : ""
                                              : ""}{" "}
                                            {formField.floors
                                              ? formField.floors[0].unit ===
                                                "INCH"
                                                ? "sqin"
                                                : ""
                                              : ""}{" "}
                                            {formField.floors
                                              ? formField.floors[0].unit === "M"
                                                ? "sqm"
                                                : ""
                                              : ""}
                                            ):
                                          </p>
                                        ) : null}{" "}
                                        <tr>
                                          <td className="col-auto">
                                            <input
                                              type="text"
                                              className="form-control px-4"
                                              value={
                                                formField &&
                                                formField.availableSpaces &&
                                                formField.availableSpaces
                                                  .length > 0
                                                  ? formField.availableSpaces[0]
                                                      .availableSpaceDimension
                                                      .length *
                                                      formField
                                                        .availableSpaces[0]
                                                        .availableSpaceDimension
                                                        .breath +
                                                    formField.availableSpaces[1]
                                                      .availableSpaceDimension
                                                      .length *
                                                      formField
                                                        .availableSpaces[1]
                                                        .availableSpaceDimension
                                                        .breath +
                                                    (formField
                                                      .availableSpaces[2]
                                                      .availableSpaceDimension
                                                      .length *
                                                      formField
                                                        .availableSpaces[2]
                                                        .availableSpaceDimension
                                                        .breath +
                                                      formField
                                                        .availableSpaces[3]
                                                        .availableSpaceDimension
                                                        .length *
                                                        formField
                                                          .availableSpaces[3]
                                                          .availableSpaceDimension
                                                          .breath)
                                                  : ""
                                              }
                                              disabled
                                            />
                                          </td>{" "}
                                          {/* <td className="col-auto"></td> */}{" "}
                                          {formField &&
                                          formField.availableSpaces &&
                                          formField.availableSpaces.length >
                                            0 &&
                                          formField.availableSpaces[0]
                                            .availableSpaceDimension.length ? (
                                            <td className="">
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  formField &&
                                                  formField.availableSpaces &&
                                                  formField.availableSpaces[0]
                                                    .availableSpaceDimension
                                                    .length > 0
                                                    ? formField
                                                        .availableSpaces[0]
                                                        .availableSpaceDimension
                                                        .length *
                                                      formField
                                                        .availableSpaces[0]
                                                        .availableSpaceDimension
                                                        .breath
                                                    : 0
                                                }
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {formField &&
                                          formField.availableSpaces &&
                                          formField.availableSpaces.length >
                                            0 &&
                                          formField.availableSpaces[1]
                                            .availableSpaceDimension.length ? (
                                            <td className="">
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  formField &&
                                                  formField.availableSpaces &&
                                                  formField.availableSpaces[1]
                                                    .availableSpaceDimension
                                                    .length > 0
                                                    ? formField
                                                        .availableSpaces[1]
                                                        .availableSpaceDimension
                                                        .length *
                                                      formField
                                                        .availableSpaces[1]
                                                        .availableSpaceDimension
                                                        .breath
                                                    : 0
                                                }
                                                disabled
                                              />
                                            </td>
                                          ) : null}
                                          {formField &&
                                          formField.availableSpaces &&
                                          formField.availableSpaces[2]
                                            .availableSpaceDimension.length ? (
                                            <td className="">
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  formField &&
                                                  formField.availableSpaces &&
                                                  formField.availableSpaces
                                                    .length > 0
                                                    ? formField
                                                        .availableSpaces[2]
                                                        .availableSpaceDimension
                                                        .length *
                                                      formField
                                                        .availableSpaces[2]
                                                        .availableSpaceDimension
                                                        .breath
                                                    : 0
                                                }
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                          {formField &&
                                          formField.availableSpaces &&
                                          formField.availableSpaces[3]
                                            .availableSpaceDimension.length ? (
                                            <td className="">
                                              <input
                                                type="text"
                                                className="form-control px-4"
                                                value={
                                                  formField &&
                                                  formField.availableSpaces &&
                                                  formField.availableSpaces
                                                    .length > 0
                                                    ? formField
                                                        .availableSpaces[3]
                                                        .availableSpaceDimension
                                                        .length *
                                                      formField
                                                        .availableSpaces[3]
                                                        .availableSpaceDimension
                                                        .breath
                                                    : 0
                                                }
                                                disabled
                                              />
                                            </td>
                                          ) : null}{" "}
                                        </tr>{" "}
                                      </tbody>{" "}
                                    </table>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue "
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="availableSpace.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="xxsnbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      checked={
                                        values.availableSpace.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "availableSpace.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="xxsnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless  mx-0 pl-2">
                                <tr>
                                  <td className="py-0 form-check common-radio-deep-blue">
                                    <Field
                                      name="availableSpace.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      hidden="true"
                                      id="yysnbcategoryNotOkay"
                                      value="notOkay"
                                      required
                                      checked={
                                        values.availableSpace.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "availableSpace.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="yysnbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1 w-100 px-0">
                                <tr>
                                  <td className="pb-0 pt-3 px-0 w-100">
                                    <Field
                                      name={`availableSpace.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {formField &&
                                  formField.storageSpaceRemark &&
                                  formField.storageSpaceRemark.availableSpace
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  value={
                                    formField &&
                                    formField.storageSpaceRemark &&
                                    formField.storageSpaceRemark.availableSpace
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                                  placeholder="Remarks"
                                  disabled
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className={`col-12 mt-4`}>
                  <div className="row justify-content-end"> </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default SpacePendingForm;
