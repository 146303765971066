import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

const CustomLoader = () => {
  return (
    <div className='text-center justify-content-center'>
      <div className='custom-loader'>
        <Spinner animation='border' />
      </div>
    </div>
  );
};

export default CustomLoader;
