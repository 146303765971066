import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "./../../components/helper/reuse";

const CustomerManageList = ({ item, read }) => {
  console.log("item-->", item);
  return (
    <tr>
      <td className="text-center"> {item?.id} </td>{" "}
      <td> {item?.booking?.warehouse?.warehouseId} </td>{" "}
      <td> {item?.booking?.customer.companyName} </td>{" "}
      <td className="text-nowrap">
        {" "}
        {readableDate(item?.booking?.created_at)}{" "}
      </td>{" "}
      <td> {item?.booking?.customer?.email} </td>{" "}
      <td className="text-capitalize"> {item?.booking?.customer.city} </td>{" "}
      <td className="text-nowrap text-capitalize">
        {" "}
        {item?.booking?.customer.area}{" "}
      </td>{" "}
      <td className="text-nowrape">
        {" "}
        {!read ? (
          <Link
            to={`/customer-manage-update/${item?.id}`}
            className={`btn text-deep-blue font-weight-bold p-1`}
          >
            {" "}
            Manage{" "}
          </Link>
        ) : (
          <span className={`btn text-deep-blue font-weight-bold p-1`}>
            {" "}
            Not Allow{" "}
          </span>
        )}{" "}
      </td>{" "}
    </tr>
  );
};

export default CustomerManageList;
