import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { readableDate } from '../../components/helper/reuse';
import {
  updateSoldOut,
  updateWarehouseStatusAdminApproved,
} from '../../store/action/warehouseAction';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

const WarehouseApprovedList = ({ item, index, page }) => {
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.CATEGORY_INFO.categoryList);
  const typeList = useSelector((state) => state.WHY_INFO.typeList);
  const { id, warehouseName, category, created_at, soldOut, adminStatus, type } = item;

  const [isSoldOut, setIsSoldOut] = useState(false);

  const updateWarehouse = (iso) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: 'Are you sure to mark sold out.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            setIsSoldOut(iso);
            dispatch(
              updateSoldOut(
                id,
                {
                  soldOut: iso,
                },
                page,
              ),
            );
            dispatch(
              updateWarehouseStatusAdminApproved(id, {
                status: 'Listed_and_Verified',
              }),
            );
          },
        },
        {
          label: 'No',
          onClick: () => console.log(''),
        },
      ],
    });
  };
  const warehouseCategory = (id) => {
    if (
      categoryList !== null &&
      categoryList !== undefined &&
      Object.keys(categoryList).length > 0
    ) {
      const categoryListAd = categoryList?.find((currentvalue, index) => currentvalue.id === id);
      return categoryListAd?.categoryName;
    }
  };
  const warehouseType = (id) => {
    if (typeList !== undefined && typeList !== null) {
      const typeListAd = typeList?.data?.find((currentvalue, index) => currentvalue.id === id);
      return typeListAd.type;
    }
  };

  // const categoryFilter = categoryList.find((currentvalue, index) => currentvalue.id ===id)
  useEffect(() => {
    setIsSoldOut(soldOut);
  }, [soldOut]);

  return (
    <tr>
      <td className='text-center py-2'> {index} </td>{' '}
      <td> {item.warehouseId ? item.warehouseId : '-'} </td> <td> {warehouseName} </td>
      <td> {warehouseCategory(item.warehouseCategory)} </td>{' '}
      <td> {warehouseType(item.warehouseType)} </td>{' '}
      <td>
        <select
          onChange={(e) => updateWarehouse(e.target.value === 'true')}
          value={isSoldOut}
          // disabled={read}
          className='form-control custom-select bg-white common-select-deep-blue border-0 w-90px'
        >
          <option value={true}> Active </option> <option value={false}> Inactive </option>{' '}
        </select>{' '}
      </td>{' '}
      <td className='text-center'>
        <Link to={`/approved-details/${id}/contact-detail`}>
          {' '}
          <i className='fa fa-eye'> </i>
        </Link>{' '}
      </td>{' '}
    </tr>
  );
};

export default WarehouseApprovedList;
