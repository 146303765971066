import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Link } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import NoReportList from "../../wrapper/report/NoReportList";
import { useSelector, useDispatch } from "react-redux";
import { reportByPage, reportList } from "../../store/action/reportAction";
import { CardLoader } from "../../components/helper/CustomLoader";

const NoSearchReport = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.REPORT_INFO);

  useEffect(() => {
    dispatch(reportByPage());
    return () => {
      dispatch(reportList([]));
    };
  }, [dispatch]);

  return (
    <LayoutOne>
      <SearchBox />
      <div className="row align-items-center px-3">
        <div className="col-12 bg-white mb-4">
          <div className="row justify-content-between">
            <div className="col-md-auto px-4 order-md-2">
              <div className="row">
                <div className="col-auto d-flex h-100 px-2 my-2">
                  <div className="dropdown btn-export">
                    <button
                      className="btn btn-deep-blue dropdown-toggle py-2"
                      type="button"
                      id="dropdownMenu5"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Export{" "}
                    </button>{" "}
                    <div className="dropdown-menu">
                      <a className="dropdown-item text-center" href="#!">
                        {" "}
                        Excel{" "}
                      </a>{" "}
                      <div className="dropdown-divider"> </div>{" "}
                      <a className="dropdown-item text-center" href="#!">
                        {" "}
                        PDF{" "}
                      </a>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul className="nav nav-pills admin-tabs-blue">
                <li className="nav-item">
                  <Link
                    to="/warehouse-report-list"
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    WHS Listing Report{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/daily-progress-report"
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    Daily Progress Report{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/no-search-report"
                    className="nav-link active_link text-uppercase"
                  >
                    {" "}
                    No Search Result Report{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="col-12">
          <div className="row align-items-center ">
            {data.isLoading ? (
              <CardLoader />
            ) : (
              <div className="col-12 table-responsive table-gray-admin bg-white p-3">
                <table className="table table-dark-custom">
                  <thead>
                    <tr>
                      <th className="px-2"> S.No. </th>{" "}
                      <th className="text-nowrap"> Query Date </th>{" "}
                      {/* <th className="text-nowrap">Warehouse Category Criteria</th>
                                            <th className="text-nowrap">Location Criteria</th> 
                                            <th className="text-nowrap">Space Criteria</th>  */}{" "}
                      <th className="text-nowrap"> Company Name </th>{" "}
                      <th className="text-nowrap"> Search ByUser </th>{" "}
                      <th className="text-nowrap"> Email </th>{" "}
                      <th className="text-nowrap"> Contact No. </th>{" "}
                      <th className="text-nowrap text-left"> Requirement </th>{" "}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {data.reportList.data && data.reportList.data.length > 0
                      ? data.reportList.data.map((item, index) => {
                          return (
                            <NoReportList
                              item={item}
                              index={index}
                              key={index}
                            />
                          );
                        })
                      : "Data Not Found"}{" "}
                  </tbody>{" "}
                </table>{" "}
              </div>
            )}{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default NoSearchReport;
