import React from "react";
import { Link } from "react-router-dom";

const EnquiryList = ({ enquiry, index }) => {
  const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return datestring;
  };
  const { id, created_at } = enquiry;
  return (
    <tr>
      <td className="text-center"> {index} </td>{" "}
      <td> {readableDate(created_at)} </td>{" "}
      <td> {enquiry.vendorEnquiry ? enquiry.vendorEnquiry.name : ""} </td>{" "}
      <td>
        {" "}
        {enquiry.vendorEnquiry ? enquiry.vendorEnquiry.serviceType : ""}{" "}
      </td>{" "}
      <td>
        <button className="alert btn-sm py-1 mb-0 alert-success">
          {" "}
          {enquiry.vendorEnquiry ? enquiry.vendorEnquiry.status : ""}{" "}
        </button>{" "}
      </td>{" "}
      <td>
        <Link to={`/enquiry-pending-detail-vendor/${id}`}>
          <button className="btn p-0 btn-line-black mb-2">
            {" "}
            <i className="fas fa-eye"> </i>
          </button>
        </Link>{" "}
      </td>{" "}
    </tr>
  );
};

export default EnquiryList;
