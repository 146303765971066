import React from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createFilter, responseFilter } from "../../store/action/filterAction";
import FormSuccess from "../../components/common/FormSuccess";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

const AddFilter = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.FILTER_INFO);

  const filterSchema = Yup.object().shape({
    filterType: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
  });

  // redirect url
  const redirect = () => {
    dispatch(responseFilter([]));
    return history.push("/filter");
  };

  return (
    <LayoutOne>
      {data.filterResponse &&
      data.filterResponse.message === "Filter created successfully" ? (
        <FormSuccess onClick={redirect} message={data.filterResponse.message} />
      ) : null}
      <div className="row align-items-center py-3">
        <div className="col-12 py-3 mt-4">
          <span>
            {" "}
            <h5 className="text-dark-blue">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-3 cursorPointer"
              >
                {" "}
              </i>{" "}
              Add Filter
            </h5>{" "}
          </span>{" "}
        </div>{" "}
      </div>{" "}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            validationSchema={filterSchema}
            initialValues={{
              filterType: "",
              active: false,
              type: "",
            }}
            onSubmit={(fields) => {
              dispatch(createFilter(fields));
              console.log("Fields==>", fields);
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label for="staticEmail" className="mb-2 pl-3">
                        {" "}
                        Filter Name{" "}
                      </label>{" "}
                      <Field
                        name="filterType"
                        type="text"
                        className={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.filterType && touched.filterType
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`filterType`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <label for="staticEmail" className="mb-2 pl-3">
                        {" "}
                        Filter Type{" "}
                      </label>{" "}
                      <Field
                        as="select"
                        name="type"
                        class={
                          `form-control bg-white common-select-deep-blue rounded-pill` +
                          (errors.type && touched.type ? " is-invalid" : "")
                        }
                      >
                        <option value=""> Select </option>{" "}
                        <option value="textbox"> Textbox </option>{" "}
                        <option value="checkbox"> Checkbox </option>{" "}
                        <option value="boolean"> Boolean </option>{" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`type`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group mb-4  col-md-4 col-sm-6">
                      <div className="mt-4 common-checkbox common-checkbox-dark position-relative mx-auto d-inline-block">
                        <Field
                          name="active"
                          className="common-checkbox-input common-checkbox-dark-input"
                          type="checkbox"
                          onChange={(e) => {
                            setFieldValue("active", !values.active);
                          }}
                          checked={values.isDepartmentHead}
                          id="active"
                        />
                        <label
                          className="common-checkbox-label common-checkbox-dark-label mr-3 pl-4"
                          for="active"
                        >
                          {" "}
                          Active{" "}
                        </label>{" "}
                      </div>{" "}
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-auto">
                      <button
                        disabled={data.isPending}
                        type="submit"
                        className="btn btn-deep-blue"
                      >
                        Add Filter{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default AddFilter;
