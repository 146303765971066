import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
// import SearchBox from '../../components/common/SearchBox';
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Pagination from "react-js-pagination";
import { ItemNotFoud } from "../../components/common/CustomLoader";
import FetureList from "../../wrapper/fetureWarehouse/FetureList";
import axiosauth from "../../services/axios-auth";

const FetureWarehouse = () => {
  const history = useHistory();
  const [data, setData] = useState(null);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  function fetureCallApi(pageNumber) {
    try {
      axiosauth
        .get(`/api/v1/featuredwarehouse?page=${pageNumber}&limit=10&type=admin`)
        .then((response) => {
          let res = JSON.parse(response.data);
          setData(res);
        })
        .catch((error) => {
          console.log("rerr-->", error);
        })
        .then(() => {
          console.log("-----always executes");
        });
    } catch (e) {}
  }

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    fetureCallApi(pageNumber);
  };

  useEffect(() => {
    function fetureCall() {
      try {
        axiosauth
          .get(
            `/api/v1/featuredwarehouse?page=${parseInt(
              pageCount
            )}&limit=10&type=admin`
          )
          .then((response) => {
            let res = JSON.parse(response.data);
            setData(res);
          })
          .catch((error) => {
            console.log("rerr-->", error);
          })
          .then(() => {
            console.log("-----always executes");
          });
      } catch (e) {}
    }
    fetureCall();
  }, [pageCount]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Featured Warehouse </title>{" "}
      </Helmet>

      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <h5 className="text-dark"> Featured Warehouse </h5>{" "}
        </div>
        {/* <div class="col-12 col-sm-6 col-md-6 col-lg-6 bg-white border-1">
                      <SearchBox callSearch={callSearch} setSearchHandler={setSearchHandler} border={true} />
                    </div> */}
        <div className="col-12 px-0">
          <div className="row align-items-center py-3">
            <div className="col-12 table-responsive table-gray-admin bg-white p-3">
              {" "}
              {data?.data && data.data.length > 0 ? (
                <table className="table table-dark-custom">
                  <thead>
                    <tr>
                      <th className="text-nowrap text-center"> Sr.No. </th>{" "}
                      <th className="text-nowrap"> WH ID </th>{" "}
                      <th className="text-nowrap"> Warehouse Name </th>{" "}
                      <th className="text-nowrap"> Start Date </th>{" "}
                      <th className="text-nowrap"> End Date </th>{" "}
                      <th className="text-nowrap"> Status </th>{" "}
                      <th className="text-nowrap"> Action </th>{" "}
                    </tr>{" "}
                  </thead>
                  <tbody>
                    {" "}
                    {data.data.map((item, index) => {
                      return (
                        <FetureList
                          key={index}
                          item={item}
                          fetureCallApi={fetureCallApi}
                          index={index + 1}
                        />
                      );
                    })}
                  </tbody>{" "}
                </table>
              ) : (
                <ItemNotFoud message="Data Nat Found" />
              )}{" "}
            </div>{" "}
          </div>

          {data?.totalCount > 10 && (
            <div className="pagination-custom">
              <Pagination
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={data?.totalCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                prevPageText={<i className="fas fa-chevron-left" />}
                nextPageText={<i className="fas fa-chevron-right" />}
                hideFirstLastPages={true}
              />{" "}
            </div>
          )}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default FetureWarehouse;
