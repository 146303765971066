import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
// import SearchBox from '../../components/common/SearchBox';
import { useHistory } from "react-router-dom";
import AutoFilterList from "../../wrapper/filterManagement/AutoFilterList";
import { Helmet } from "react-helmet";
import { activeFilterList } from "../../store/action/filterAction";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { ItemNotFoud } from "../../components/common/CustomLoader";

const AutoFilter = () => {
  // const [searchHandler, setSearchHandler] = useState("")
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.FILTER_INFO);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(activeFilterList(parseInt(pageCount)));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(activeFilterList(pageNumber));
  };

  // const callSearch = () =>{
  //   dispatch(activeFilterList(parseInt(pageCount), searchHandler))
  // }

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Filter </title>{" "}
      </Helmet>
      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 px-0">
          <h5 className="text-dark"> Filter Management </h5>{" "}
        </div>{" "}
        <div className="col-12">
          <div className="row align-items-center py-3">
            <div className="col-12 table-responsive table-row-margin-bottom-admin bg-white p-3">
              {" "}
              {data.filterList && data.filterList.length > 0 ? (
                <table className="table table-dark-custom">
                  <tbody>
                    {" "}
                    {data.filterList.map((item, index) => {
                      return <AutoFilterList key={index} item={item} />;
                    })}
                  </tbody>{" "}
                </table>
              ) : (
                <ItemNotFoud message="Data Nat Found" />
              )}{" "}
            </div>{" "}
          </div>

          {data.filterList?.totalCount > 10 && (
            <div className="pagination-custom">
              <Pagination
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={data.filterList?.totalCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                prevPageText={<i className="fas fa-chevron-left" />}
                nextPageText={<i className="fas fa-chevron-right" />}
                hideFirstLastPages={true}
              />{" "}
            </div>
          )}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default AutoFilter;
