import {
    ON_BOARD,
    ON_BOARD_BY_ID,
    ON_BOARD_RESPONSE,
    FORM_DETAIL,
    USER_ONBOARD_LIST,
    USER_ONBOARD_DETAIL,
    ONBOARD_CUSTOMER_DETAIL,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING
} from './../types';

import {
    GET_ONBOARD_URL,
    ONBOARD_BY_ID_URL,
    ASSIGN_ONBOARD_URL,
    CONFIRM_DOCUMENT_URL
} from '../../services/urls';



import axiosauth from '../../services/axios-auth';
import {
    sendNotification
} from '../action/notificationAction';
import {
    documentById
} from './documentAction';


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}


export function onBoardList(data) {
    return {
        type: ON_BOARD,
        payload: data
    }
}

export function onBoard_By_Id(data) {
    return {
        type: ON_BOARD_BY_ID,
        payload: data
    }
}

export function onBoardCustomer(data) {
    return {
        type: ONBOARD_CUSTOMER_DETAIL,
        payload: data
    }
}

export function formDetails(data) {

    return {
        type: FORM_DETAIL,
        payload: data
    }
}

export function userOnboardDetail(data) {

    return {
        type: USER_ONBOARD_DETAIL,
        payload: data
    }
}

export function userOnboardList(data) {

    return {
        type: USER_ONBOARD_LIST,
        payload: data
    }
}


export function responseOnboard(data) {
    return {
        type: ON_BOARD_RESPONSE,
        payload: data
    }
}


// ###########  Fecth All booking Onboard List ########

export const onBoardByPage = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        let limit = 10;
        try {
            axiosauth.get(GET_ONBOARD_URL + `?page=${page}&limit=${limit}&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(onBoardList(res))
                    dispatch(isLoading(false))
                } else {
                    dispatch(isLoading(false))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
            })

        } catch (e) {}
    }
}


// ###########  Fecth  Onboard Detail By Id ########

export const onBoardById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(ONBOARD_BY_ID_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(onBoard_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    dispatch(isLoading(false))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
            })

        } catch (e) {}
    }
}

// ###########  Fecth  Onboard Detail WIthout loading ########

export const onBoardByIdWithoutLoading = (id) => {
    return async (dispatch) => {
        dispatch(isError(""))
        try {
            axiosauth.get(ONBOARD_BY_ID_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(onBoard_By_Id(res.data))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
            }).then(() => {})

        } catch (e) {}
    }
}




// ###########  Assign  Onboard To user ########




export const assignOnBoardWithoutToast = (data) => {
    return async (dispatch) => {
        dispatch(isError(""))
        try {
            axiosauth.post(ASSIGN_ONBOARD_URL, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    // dispatch(onBoardByIdWithoutLoading(data.customeronboardingId))

                    // dispatch(isPending(false))
                }
            }).catch((error) => {
                // dispatch(isError(error.message))
            }).then(() => {})

        } catch (e) {}
    }
}





export const assignOnBoard = (data, addToast) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.post(ASSIGN_ONBOARD_URL, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(onBoardByIdWithoutLoading(data.customeronboardingId))
                    addToast(res.message, {
                        appearance: "success",
                        autoDismiss: true
                    });

                    dispatch(isPending(false))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
            })

        } catch (e) {}
    }
}


// ###########  Get Customer Details ########

export const getCustomerDetailCall = (onBoardId) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.get(`/api/v1/customeronboarding/customercontactdetail/${onBoardId}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(onBoardCustomer(res.data))
                    dispatch(isPending(false))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
            })

        } catch (e) {}
    }
}


// ###########  Get Data By userId and customer onboarding ########

export const getCustomerOnboardForm = (userId, formType, onBoardId) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        dispatch(formDetails(null))
        dispatch(userOnboardDetail(null))
        try {
            axiosauth.get(`/api/v1/customeronboarding/adminformdetailbyuser/user/${userId}/type/${formType}/customeronboard/${onBoardId}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    console.log("assign rs->", res)
                    dispatch(formDetails(res.data))
                    // dispatch(userOnboardDetail(res.data))// new from other
                }
            }).catch((error) => {
                dispatch(isError(error.message))
            }).then(() => {
                dispatch(isPending(false))
            })

        } catch (e) {}
    }
}

// ###########  getSubUserAssignOnboard ########

export const getSubUserAssignOnboard = (formType) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(`/api/v1/customeronboarding/sub/user/formtype/${formType}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(userOnboardList(res.data))
                    dispatch(isLoading(false))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
            })

        } catch (e) {}
    }
}

// ###########  getSubUserAssignOnboard ########

export const getSubUserAssignOnboardDetail = (formType) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        dispatch(userOnboardDetail(null))
        try {
            axiosauth.get(`/api/v1/customeronboarding/sub/user/formtype/${formType}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(userOnboardDetail(res.data))
                    dispatch(isLoading(false))
                } else {
                    dispatch(isLoading(false))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
            })

        } catch (e) {}
    }
}


// ###########  getSubUserAssignOnboard ########

export const getSubUserAssignOnboardDetailById = (formType, id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        dispatch(userOnboardDetail(null))
        dispatch(formDetails(null))
        try {
            axiosauth.get(`/api/v1/customeronboarding/sub/user/formtype/${formType}/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    // dispatch(userOnboardDetail(res.data))
                    console.log("formd rs->", res)
                    dispatch(formDetails(res.data)) // new Froms others
                }
            }).catch((error) => {
                dispatch(isError(error.message))
            }).then(() => {
                dispatch(isLoading(false))
            })

        } catch (e) {}
    }
}

// ###########  update sub user forms ########

export const updateUserForm = (onBoardId, formType, fields) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.put(`/api/v1/customeronboarding/${onBoardId}/type/${formType}`, fields).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    console.log("res--->", res)
                    dispatch(responseOnboard(res))
                    dispatch(isPending(false))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
            })

        } catch (e) {}
    }
}



// ###########  update sub user forms ########

export const confirmOboardFromDocumen = (fields, customerId, vendorId, addToast, warehouseId) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        let userData = localStorage.getItem('userData');
        let userId = JSON.parse(userData).id
        console.log(userId)

        try {
            axiosauth.post(CONFIRM_DOCUMENT_URL, fields).then(response => {
                let res = JSON.parse(response.data)

                if (res.statusCode === 500) {
                    addToast(res.message, {
                        appearance: "error",
                        autoDismiss: true
                    });
                }

                if (res.statusCode === 200) {
                    addToast(res.message, {
                        appearance: "success",
                        autoDismiss: true
                    });

                    dispatch(sendNotification({
                        "title": "Booking Confirmed",
                        "content": "Booking Confirm Success",
                        "to": "Warehousity",
                        "user": customerId
                    }))
                    dispatch(sendNotification({
                        "title": "Booking Confirmed",
                        "content": "Booking Confirm Success",
                        "to": "Warehousity",
                        "user": vendorId
                    }))
                    dispatch(responseOnboard(res.data))
                    dispatch(isPending(false))
                    dispatch(documentById(warehouseId))
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
            })

        } catch (e) {}
    }
}