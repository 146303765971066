import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import { CFormGroup, CInputCheckbox } from "@coreui/react";
import { documentUploadV2 } from "../../components/utils";

// import {documentUpload} from '../../components/utils';
import {
  updateService,
  serviceById,
  serviceCategoryByPage,
  serviceSubCategoryByPage,
  responseService,
} from "../../store/action/serviceAction";
import { CardLoader } from "../../components/helper/CustomLoader";
import {
  onlyAlphaNumericSpaceAllow,
  forDescriptionValidation,
  readableDate,
} from "../../components/helper/reuse";

const EditService = () => {
  const [specs, setspecs] = useState([]);
  const [file1, setfile1] = useState(null);
  const removespecs = (index) => {
    const temp = [...specs];

    const filter = temp.filter((item, i) => i !== index);

    setspecs(filter);
  };
  const addspecs = (val) => {
    setspecs([
      ...specs,
      {
        name: val,
      },
    ]);
  };
  const { serviceId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.SERVICE_INFO);
  const [initValue, setInitValue] = useState({
    categoryId: "",
    subcategoryId: "",
    name: "",
    description: "",
    expiryDate: "",
    isActive: false,
    features: [],
  });

  const roleSchema = Yup.object().shape({
    categoryId: Yup.string().required("Required"),
    subcategoryId: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  // File Upload
  // const [res, setRes] = useState([])
  // const [fileError, setFileError]=useState(null)

  // const uploadDocs = async (e) =>{
  //   setFileError(null)
  //     let formData = new FormData()
  //     formData.append("file", e.target.files[0])
  //     let urlData = await documentUpload(formData)
  //    //  console.log(urlData)
  //    if(urlData && urlData.url){
  //      setRes([...res, urlData.url])
  //    }
  //   }

  //   console.log("res==>", res)

  useEffect(() => {
    if (data.serviceDetail) {
      setInitValue({
        ...data.serviceDetail,
        expiryDate: readableDate(data.serviceDetail?.expiryDate),
        categoryId: data.serviceDetail?.category.id,
        subcategoryId: data.serviceDetail?.subcategory.id,
        features: JSON.parse(data.serviceDetail?.subcategory?.serviceFeature),
      });
      setspecs(data.serviceDetail?.serviceFeature);
    }
  }, [data.serviceDetail]);

  useEffect(() => {
    dispatch(serviceCategoryByPage());
    dispatch(serviceSubCategoryByPage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(serviceById(serviceId));
  }, [dispatch, serviceId]);

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service`);
  };

  return (
    <LayoutOne>
      {" "}
      {data.serviceResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Service Updated`} />
      ) : null}
      <div className="row align-items-center py-3 mx-0">
        <div className="col-12">
          <h5 className="text-dark-blue font-weight-bold">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            ></i>{" "}
            Update Services{" "}
          </h5>{" "}
        </div>{" "}
      </div>
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row align-items-center pb-3 px-3 mx-0">
          <div className="col-12">
            <Formik
              enableReinitialize={true}
              validationSchema={roleSchema}
              initialValues={initValue}
              onSubmit={(fields) => {
                if (file1 !== null) {
                  let formData = new FormData();
                  formData.append("file", file1);
                  let urlData = documentUploadV2(formData);
                  urlData.then((res) => {
                    fields["sampleRfq"] = res.url;
                    fields.serviceFeature = JSON.stringify(specs);
                    dispatch(updateService(serviceId, fields));
                  });
                } else {
                  fields.serviceFeature = JSON.stringify(specs);
                  dispatch(updateService(serviceId, fields));
                }

                console.log("Fields==>", fields);
              }}
              render={({
                values,
                setFieldValue,
                errors,
                status,
                onChange,
                touched,
              }) => {
                return (
                  <Form>
                    <div className="row">
                      <div className="form-group col-12 mb-4">
                        <label htmlFor="staticEmail" className="mb-2 px-3">
                          Select Category{" "}
                        </label>
                        <Field
                          name="categoryId"
                          as="select"
                          className={
                            `form-control form-control-md common-select-deep-blue rounded-pill` +
                            (errors.categoryId && touched.categoryId
                              ? " is-invalid"
                              : "")
                          }
                          onChange={(e) => {
                            setFieldValue(
                              "categoryId",
                              parseInt(e.target.value)
                            );
                          }}
                        >
                          <option value=""> Select </option>{" "}
                          {data.categoryList && data.categoryList.length > 0
                            ? data.categoryList.map((item, index) => (
                                <option value={item?.id}> {item?.name} </option>
                              ))
                            : null}{" "}
                        </Field>{" "}
                        <ErrorMessage
                          name={`categoryId`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                      <div className="form-group col-12 mb-4">
                        <label htmlFor="staticEmail" className="mb-2 px-3">
                          Sub Category{" "}
                        </label>{" "}
                        <Field
                          name="subcategoryId"
                          as="select"
                          className={
                            `form-control form-control-md common-select-deep-blue rounded-pill` +
                            (errors.subcategoryId && touched.subcategoryId
                              ? " is-invalid"
                              : "")
                          }
                          onChange={(e) => {
                            // console.log("sdjjd",e.target.value,data.subCategoryList)
                            var catid;
                            data.subCategoryList.forEach((value) => {
                              console.log(
                                "tests",
                                value.id,
                                e.target.value,
                                parseInt(value.id) === parseInt(e.target.value)
                              );
                              if (
                                parseInt(value.id) === parseInt(e.target.value)
                              ) {
                                console.log("test", value, e.target.value);

                                catid = value.serviceFeature;
                              }
                            });
                            console.log("dksks", catid);
                            setFieldValue("features", JSON.parse(catid));
                            setFieldValue("subcategoryId", e.target.value);
                            setspecs([]);
                          }}
                        >
                          <option value=""> Select </option>{" "}
                          {data.subCategoryList &&
                          data.subCategoryList.length > 0
                            ? data.subCategoryList.map((item, index) => (
                                <option value={item?.id}> {item?.name} </option>
                              ))
                            : null}{" "}
                        </Field>{" "}
                        <ErrorMessage
                          name={`subcategoryId`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                      <div className="form-group col-12 mb-4">
                        <label htmlFor="staticEmail" className="mb-2 px-3">
                          Services Name{" "}
                        </label>{" "}
                        <Field
                          onKeyPress={(e) => onlyAlphaNumericSpaceAllow(e)}
                          name="name"
                          type="text"
                          className={
                            `form-control form-control-md rounded-pill` +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                          id="staticEmail"
                          placeholder="Keyboard"
                        />
                        <ErrorMessage
                          name={`name`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                      <div className="form-group col-12">
                        <label htmlFor="staticEmail" className="mb-2 px-3">
                          Description{" "}
                        </label>{" "}
                        <Field
                          onKeyPress={(e) => forDescriptionValidation(e)}
                          name="description"
                          type="text"
                          className={
                            `form-control form-control-md rounded-pill` +
                            (errors.description && touched.description
                              ? " is-invalid"
                              : "")
                          }
                          id="staticEmail"
                          placeholder="Lorem ipsum dolor sit amet"
                        />
                        <ErrorMessage
                          name={`description`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                      <div className="form-group col-12 mb-4">
                        <label htmlFor="features" className="mb-2 px-3">
                          Features{" "}
                        </label>{" "}
                        <FieldArray name="features">
                          {" "}
                          {(fieldArrayProps) => {
                            const { form } = fieldArrayProps;
                            const { values } = form;
                            const { features } = values;
                            return (
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <table className="table border-0">
                                  <thead>
                                    <tr>
                                      <th> S.No </th> <th> </th>
                                      <th>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "end",
                                          }}
                                        >
                                          {/* <label
                                                                                        className="btn btn-remove"
                                                                                        onClick={() => push("")}
                                                                                      >
                                                                                        Add More +
                                                                                      </label> */}{" "}
                                        </div>{" "}
                                      </th>{" "}
                                    </tr>{" "}
                                    {features &&
                                      features.length > 0 &&
                                      features.map((priceField, index) => (
                                        <tr key={index}>
                                          <td className="mt-2">
                                            {" "}
                                            {index + 1}{" "}
                                          </td>{" "}
                                          <td className="mt-2">
                                            <CFormGroup
                                              variant="checkbox"
                                              className="checkbox"
                                            >
                                              <CInputCheckbox
                                                style={{
                                                  marginTop: "0.8em",
                                                  lineHeight: "40px",
                                                }}
                                                checked={
                                                  specs?.findIndex(
                                                    (x) =>
                                                      x.name ===
                                                      features[index].name
                                                  ) !== -1
                                                    ? true
                                                    : false
                                                }
                                                value={
                                                  specs?.findIndex(
                                                    (x) =>
                                                      x.name ===
                                                      features[index].name
                                                  ) !== -1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    specs.findIndex(
                                                      (x) =>
                                                        x.name ===
                                                        features[index].name
                                                    ) === -1
                                                  ) {
                                                    addspecs(
                                                      features[index].name
                                                    );
                                                  } else {
                                                    removespecs(
                                                      specs.findIndex(
                                                        (x) =>
                                                          x.name ===
                                                          features[index].name
                                                      )
                                                    );
                                                  }
                                                }}
                                              />{" "}
                                            </CFormGroup>{" "}
                                          </td>{" "}
                                          <td key={index}>
                                            <Field
                                              name={`features.${index}.name`}
                                              className="form-control mr-4"
                                              placeholder="Name"
                                              readOnly
                                            />
                                          </td>{" "}
                                          {/* <td key={`priceindex${index}`}>
                                                                                                  <Field
                                                                                                    name={`features[${index}][value]`}
                                                                                                    className="form-control"
                                                                                                    placeholder="Value"

                                                                                                  />
                                                                                                 
                                                                                                </td> */}
                                          {/* <td key={`deleteicon${index}`}>
                                                                                                  <i
                                                                                                    className="fas fa-trash mt-2"
                                                                                                    style={{
                                                                                                      color: "tomato",
                                                                                                      cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() =>
                                                                                                     {
                                                                                                      remove(index);

                                                                                                     }
                                                                                                    }
                                                                                                  ></i>
                                                                                                </td> */}{" "}
                                        </tr>
                                      ))}{" "}
                                  </thead>{" "}
                                </table>{" "}
                              </div>
                            );
                          }}{" "}
                        </FieldArray>{" "}
                      </div>{" "}
                      <div className="form-group col-12">
                        <label className=""> Upload RFQ xlsx </label>
                        <div className="custom-file mt-1">
                          <label
                            className="custom-file-label"
                            htmlFor="customFile"
                          >
                            {" "}
                            {file1 !== null ? file1.name : "Choose File"}{" "}
                          </label>{" "}
                          <Field
                            onChange={(e) => {
                              setfile1(e.target.files[0]);
                            }}
                            type="file"
                            name="file"
                            accept=".xlsx, .xls, .csv"
                            className="custom-file-input"
                            id="customFile"
                          />
                          <ErrorMessage
                            name={`file`}
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row">
                      <div className="col-auto">
                        <button
                          type="submit"
                          disabled={data.isPending}
                          className="btn btn-deep-blue toggle-className py-2"
                        >
                          Edit{" "}
                          {data.isPending ? (
                            <Spinner animation="border" />
                          ) : null}{" "}
                        </button>{" "}
                      </div>{" "}
                    </div>{" "}
                  </Form>
                );
              }}
            />{" "}
          </div>{" "}
        </div>
      )}{" "}
    </LayoutOne>
  );
};

export default EditService;
