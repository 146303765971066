import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import WHSUserList from "../../wrapper/whsUserManagement/WHSUserList";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { whsByPage, responseWhs } from "../../store/action/whsUserAction";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Pagination from "react-js-pagination";
import SearchBox from "../../components/common/SearchBox";
import LinkButton from "../../components/common/LinkButton";

const WHSUser = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.WHS_USER_INFO);
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(whsByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const redirect = () => {
    dispatch(responseWhs(null));
    history.replace(`/wsh-user?page=1`);
  };

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(whsByPage(pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(whsByPage(pageCount, searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> WHS User </title>{" "}
      </Helmet>
      {data.whsResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Status Updated`} />
      ) : null}
      <div className="row justify-content-end  pb-3 px-3 py-2">
        {" "}
        {/* =============================== */}{" "}
        <div className="mx-0 bg-white py-2 w-100 row align-items-center">
          <div className="col-12 col-sm-12 col-md-12 col-xl py-1">
            <div className="row align-items-center">
              <div className="col-auto d-flex h-100 px-2">
                <div className="dropdown btn-export">
                  <button
                    className="custom-btn btn-deep-blue dropdown-toggle"
                    type="button"
                    id="dropdownMenu5"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Export{" "}
                  </button>{" "}
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#!">
                      Excel{" "}
                    </a>{" "}
                    <div className="dropdown-divider"> </div>{" "}
                    <a className="dropdown-item" href="#!">
                      PDF{" "}
                    </a>{" "}
                  </div>{" "}
                </div>{" "}
              </div>

              <LinkButton link={`/wsh-bulk-upload`} label="Bulk Upload" />

              <LinkButton link={`/add-whs-user`} label="Add WHS User" />
            </div>{" "}
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-xl-auto">
            <SearchBox
              callSearch={callSearch}
              setSearchHandler={setSearchHandler}
              border={true}
            />{" "}
          </div>{" "}
        </div>
        {/* ======================= */}{" "}
        <div className="col-12">
          <div className="row">
            <div className="col-12 pt-2">
              <h5 className="text-dark font-weight-bold">Manage Sub User </h5>{" "}
            </div>{" "}
            <div className="col-12 text-nowrap table-responsive table-gray-admin bg-white p-3">
              <table className="table table-dark-custom">
                <thead>
                  <tr>
                    <th className="text-center"> S.No. </th> <th> Name </th>{" "}
                    {/* <th>Created On</th> */} <th> Role </th>{" "}
                    <th> Contact No. </th> <th> Email ID </th>{" "}
                    <th> Department </th> <th> Active / In Active </th>{" "}
                    <th className="text-center"> Details </th>{" "}
                  </tr>{" "}
                </thead>{" "}
                <tbody>
                  {" "}
                  {data.whsUserList?.data && data.whsUserList.data.length > 0
                    ? data.whsUserList.data.map((item, index) => {
                        return (
                          <WHSUserList
                            item={item}
                            index={
                              ((parseInt(pageCount) ? parseInt(pageCount) : 1) -
                                1) *
                                10 +
                              (index + 1)
                            }
                            key={index}
                            read={read}
                          />
                        );
                      })
                    : null}{" "}
                </tbody>{" "}
              </table>
              {data.whsUserList?.totalCount > 10 && (
                <div className="pagination-custom">
                  <Pagination
                    activePage={parseInt(pageCount)}
                    itemsCountPerPage={10}
                    totalItemsCount={data.whsUserList?.totalCount}
                    pageRangeDisplayed={1}
                    onChange={handlePageChange}
                    prevPageText={<i className="fas fa-chevron-left" />}
                    nextPageText={<i className="fas fa-chevron-right" />}
                    hideFirstLastPages={true}
                  />{" "}
                </div>
              )}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default WHSUser;
