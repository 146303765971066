import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateSafetySecurity,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";

const SafetyPendingForm = ({ warehouseId, viewMood }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return datestring;
  };
  const validationSchema = function (values) {
    return Yup.object().shape({
      fireExtinguishers: Yup.array().of(
        Yup.object().shape({
          fireExtinguishers: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      fireSprinklers: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      fireHydrant: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),

      cctv: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      waterTank: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      smokeDetectorsAndFireAlarm: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      securityGuard: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      metalDetector: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      pestControl: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      safetySecurityOther: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        safetyAndSecurityRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateSafetySecurity(payload));
  };
  const [formField, setFormField] = useState(null);
  const [remarkForm, setRemarkForm] = useState({
    fireExtinguishers: [
      {
        type: "abc",
        fireExtinguisher: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "co2",
        fireExtinguisher: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "h20",
        fireExtinguisher: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    fireSprinklers: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    fireHydrant: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    cctv: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    waterTank: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    smokeDetectorsAndFireAlarm: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    securityGuard: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    metalDetector: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    pestControl: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    safetySecurityOther: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });

  useEffect(() => {
    if (data.singleFormData && data.type === "safetyAndSecurity") {
      setFormField(data.singleFormData.safetyAndSecurityInfo);
      if (data.singleFormData.safetyAndSecurityRemark) {
        setRemarkForm({
          fireExtinguishers: [
            {
              type: "abc",
              fireExtinguisher: {
                value: "",

                whsstatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[0]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[0].fireExtinguisher.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[0]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[0].fireExtinguisher.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[0]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[0].fireExtinguisher.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[0]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[0].fireExtinguisher.faremark
                    : ""
                  : "",
              },
            },
            {
              type: "co2",
              fireExtinguisher: {
                value: "",

                whsstatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[1]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[1].fireExtinguisher.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[1]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[1].fireExtinguisher.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[1]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[1].fireExtinguisher.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[1]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[1].fireExtinguisher.faremark
                    : ""
                  : "",
              },
            },
            {
              type: "h20",
              fireExtinguisher: {
                value: "",

                whsstatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[2]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[2].fireExtinguisher.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[2]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[2].fireExtinguisher.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[2]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[2].fireExtinguisher.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[2]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[2].fireExtinguisher.faremark
                    : ""
                  : "",
              },
            },
          ],
          fireSprinklers: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireSprinklers
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireSprinklers
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireSprinklers
                  .fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireSprinklers
                  .faremark
              : "",
          },
          fireHydrant: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireHydrant
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireHydrant
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireHydrant.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireHydrant.faremark
              : "",
          },
          cctv: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.cctv.whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.cctv.whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.cctv.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.cctv.faremark
              : "",
          },
          waterTank: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.waterTank.whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.waterTank.whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.waterTank.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.waterTank.faremark
              : "",
          },
          smokeDetectorsAndFireAlarm: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark
                  .smokeDetectorsAndFireAlarm.whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark
                  .smokeDetectorsAndFireAlarm.whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark
                  .smokeDetectorsAndFireAlarm.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark
                  .smokeDetectorsAndFireAlarm.faremark
              : "",
          },
          securityGuard: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.securityGuard
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.securityGuard
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.securityGuard
                  .fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.securityGuard
                  .faremark
              : "",
          },
          metalDetector: {
            value: "",
            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.metalDetector
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.metalDetector
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.metalDetector
                  .fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.metalDetector
                  .faremark
              : "",
          },
          pestControl: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.pestControl
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.pestControl
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.pestControl.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.pestControl.faremark
              : "",
          },
          safetySecurityOther: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.safetySecurityOther
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.safetySecurityOther
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.safetySecurityOther
                  .fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.safetySecurityOther
                  .faremark
              : "",
          },
        });
      } else {
        setRemarkForm({
          fireExtinguishers: [
            {
              type: "abc",
              fireExtinguisher: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              type: "co2",
              fireExtinguisher: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              type: "h20",
              fireExtinguisher: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
          ],
          fireSprinklers: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          fireHydrant: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          cctv: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          waterTank: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          smokeDetectorsAndFireAlarm: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          securityGuard: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          metalDetector: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          pestControl: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          safetySecurityOther: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-12 rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Fire Extinguisher(ABC):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField
                                ? formField.fireExtinguishers
                                  ? formField.fireExtinguishers[1]
                                      .fireExtinguisherValidity.validFrom
                                    ? "Yes"
                                    : "No"
                                  : ""
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <input
                              value={
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[1].quantity
                                    : ""
                                  : ""
                              }
                              type="text"
                              className="form-control px-4 mb-1 mt-3"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[1]
                                        .fireExtinguisherValidity.validFrom
                                    : ""
                                  : ""
                              )}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              type="date"
                              value={readableDate(
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[1]
                                        .fireExtinguisherValidity.validTill
                                    : ""
                                  : ""
                              )}
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group mb-5 mt-5 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireExtinguishers[0].fireExtinguisher.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="f1ccmmjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.fireExtinguishers[0]
                                    ? values.fireExtinguishers[0]
                                        .fireExtinguisher.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "fireExtinguishers[0].fireExtinguisher.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="f1ccmmjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireExtinguishers[0].fireExtinguisher.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="dffffff"
                                type="radio"
                                value="NotOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.fireExtinguishers[0]
                                    ? values.fireExtinguishers[0]
                                        .fireExtinguisher.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "fireExtinguishers[0].fireExtinguisher.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="dffffff"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`fireExtinguishers[0].fireExtinguisher.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.fireExtinguishers[0] &&
                              values.fireExtinguishers[0].fireExtinguisher
                                .fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.fireExtinguishers[0] &&
                                values.fireExtinguishers[0].fireExtinguisher
                                  .faremark
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Fire Extinguisher(CO2):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField
                                ? formField.fireExtinguishers
                                  ? formField.fireExtinguishers[0]
                                      .fireExtinguisherValidity.validFrom
                                    ? "Yes"
                                    : "No"
                                  : ""
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <input
                              value={
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[0].quantity
                                    : ""
                                  : ""
                              }
                              type="text"
                              className="form-control px-4 mb-1 mt-3"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              value={readableDate(
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[0]
                                        .fireExtinguisherValidity.validFrom
                                    : ""
                                  : ""
                              )}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              value={readableDate(
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[0]
                                        .fireExtinguisherValidity.validTill
                                    : ""
                                  : ""
                              )}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group mt-5 mb-5 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireExtinguishers[1].fireExtinguisher.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="f11ccmmjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.fireExtinguishers[1]
                                    ? values.fireExtinguishers[1]
                                        .fireExtinguisher.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "fireExtinguishers[1].fireExtinguisher.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="f11ccmmjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireExtinguishers[1].fireExtinguisher.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="xxxx"
                                type="radio"
                                value="NotOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.fireExtinguishers[1]
                                    ? values.fireExtinguishers[1]
                                        .fireExtinguisher.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "fireExtinguishers[1].fireExtinguisher.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="xxxx"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              name={`fireExtinguishers[1].fireExtinguisher.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.fireExtinguishers[1] &&
                              values.fireExtinguishers[1].fireExtinguisher
                                .fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.fireExtinguishers[1] &&
                                values.fireExtinguishers[1].fireExtinguisher
                                  .faremark
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Fire Extinguisher(Others):{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline py-4 mt-3 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField
                                ? formField.fireExtinguishers
                                  ? formField.fireExtinguishers[2]
                                      .fireExtinguisherValidity.validFrom
                                    ? "Yes"
                                    : "No"
                                  : ""
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4">
                            <input
                              type="text"
                              value={
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[2].quantity
                                    : ""
                                  : ""
                              }
                              className="form-control px-4 mb-1 mt-3"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid from{" "}
                            </label>{" "}
                            <input
                              value={readableDate(
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[2]
                                        .fireExtinguisherValidity.validFrom
                                    : ""
                                  : ""
                              )}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <label
                              htmlFor=""
                              className="mb-0 px-4 small text-uppercase"
                            >
                              {" "}
                              Valid till{" "}
                            </label>{" "}
                            <input
                              value={readableDate(
                                formField
                                  ? formField.fireExtinguishers
                                    ? formField.fireExtinguishers[2]
                                        .fireExtinguisherValidity.validTill
                                    : ""
                                  : ""
                              )}
                              type="date"
                              className="form-control px-4 mb-2"
                              placeholder=""
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group mt-5 mb-5 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireExtinguishers[2].fireExtinguisher.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="1f11ccmmjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.fireExtinguishers[2]
                                    ? values.fireExtinguishers[2]
                                        .fireExtinguisher.whsstatus === true
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "fireExtinguishers[2].fireExtinguisher.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="1f11ccmmjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireExtinguishers[2].fireExtinguisher.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="yyyy"
                                type="radio"
                                value="NotOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.fireExtinguishers[2]
                                    ? values.fireExtinguishers[2]
                                        .fireExtinguisher.whsstatus === false
                                    : ""
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "fireExtinguishers[2].fireExtinguisher.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="yyyy"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`fireExtinguishers[2].fireExtinguisher.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.fireExtinguishers[2] &&
                              values.fireExtinguishers[2].fireExtinguisher
                                .fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={
                                values.fireExtinguishers[2] &&
                                values.fireExtinguishers[2].fireExtinguisher
                                  .faremark
                              }
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Fire Sprinklers: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline mt-2 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField ? formField.fireSprinklers : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4" colSpan="2">
                            <input
                              value={formField ? formField.fireSprinklers : ""}
                              type="text"
                              className="form-control px-4 mt-2 mb-2"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4"></td>
                        </tr>
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireSprinklers.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="vvbbjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.fireSprinklers.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "fireSprinklers.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="vvbbjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireSprinklers.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="vvbbjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.fireSprinklers.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "fireSprinklers.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="vvbbjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`fireSprinklers.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.fireSprinklers.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.fireSprinklers.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Fire Hydrant: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline mt-2 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField ? formField.fireHydrant : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4" colSpan="2">
                            <input
                              type="text"
                              value={formField ? formField.fireHydrant : ""}
                              className="form-control px-4 mt-2 mb-2"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4"></td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireHydrant.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="vcvbbjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.fireHydrant.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("fireHydrant.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="vcvbbjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="fireHydrant.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="vcvbbjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.fireHydrant.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("fireHydrant.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="vcvbbjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`fireHydrant.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.fireHydrant.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.fireHydrant.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> CCTV: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline mt-2 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField ? formField.cctv : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4" colSpan="2">
                            <input
                              value={formField ? formField.cctv : ""}
                              type="text"
                              className="form-control px-4 mt-2 mb-2"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4"></td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="cctv.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="bbmjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.cctv.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("cctv.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbmjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="cctv.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="bbmjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.cctv.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("cctv.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbmjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`cctv.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.cctv.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.cctv.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Water Tank: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline mt-2 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField
                                ? formField.waterTank
                                  ? "Yes"
                                  : "No"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4" colSpan="2">
                            <input
                              value={
                                formField ? formField.waterTank.quantity : ""
                              }
                              type="text"
                              className="form-control px-4 mt-2 mb-2"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4">
                            <input
                              value={
                                formField ? formField.waterTank.storage : ""
                              }
                              type="text"
                              className="form-control px-4 mt-2 mb-2"
                              placeholder="Total Storage in Liter"
                              disabled
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="waterTank.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="bbjjxxnbcategoryvvOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.waterTank.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("waterTank.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjxxnbcategoryvvOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="waterTank.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="bbjjyynbcategoryvvNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.waterTank.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("waterTank.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjyynbcategoryvvNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`waterTank.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.waterTank.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.waterTank.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1">
                              {" "}
                              Smoke Detectors / Fire Alarm:{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline mt-2 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField
                                ? formField.smokeDetectorsAndFireAlarm
                                  ? "Yes"
                                  : "No"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4" colSpan="2">
                            <input
                              type="text"
                              value={
                                formField
                                  ? formField.smokeDetectorsAndFireAlarm
                                  : ""
                              }
                              className="form-control px-4 mt-2 mb-2"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4"></td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="smokeDetectorsAndFireAlarm.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="llbbjjxxnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.smokeDetectorsAndFireAlarm
                                    .whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "smokeDetectorsAndFireAlarm.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="llbbjjxxnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="smokeDetectorsAndFireAlarm.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="llbbjjyynbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.smokeDetectorsAndFireAlarm
                                    .whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "smokeDetectorsAndFireAlarm.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="llbbjjyynbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`smokeDetectorsAndFireAlarm.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.smokeDetectorsAndFireAlarm.fastatus ===
                              true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.smokeDetectorsAndFireAlarm.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Security Guard: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline mt-2 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField
                                ? formField.securityGuard
                                  ? "Yes"
                                  : "No"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4" colSpan="2">
                            <input
                              value={formField ? formField.securityGuard : ""}
                              type="text"
                              className="form-control px-4 mt-2 mb-2"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4"></td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="securityGuard.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="bbjjxxbbnbcategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.securityGuard.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue("securityGuard.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjxxbbnbcategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="securityGuard.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="bbjjyynbbbcategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.securityGuard.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "securityGuard.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjyynbbbcategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`securityGuard.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.securityGuard.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.securityGuard.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Metal Detector: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline mt-2 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField
                                ? formField.metalDetector
                                  ? "Yes"
                                  : "No"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4" colSpan="2">
                            <input
                              value={formField ? formField.metalDetector : ""}
                              type="text"
                              className="form-control px-4 mt-2 mb-2"
                              placeholder="Qty"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4"></td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="metalDetector.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="bbjjxxnbcategoryOkvvay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.metalDetector.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue("metalDetector.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjxxnbcategoryOkvvay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="metalDetector.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="bbjjyynbcategoryNotOkvvay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.metalDetector.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "metalDetector.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjyynbcategoryNotOkvvay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`metalDetector.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.metalDetector.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.metalDetector.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="mw-200px">
                            <p className="mb-0 pb-1"> Pest Control: </p>{" "}
                          </td>{" "}
                          <td className="col-auto form-inline form-group form-inline mt-2 mb-0">
                            <p className="text-gray py-3 mb-0">
                              {" "}
                              {formField
                                ? formField.pestControl
                                  ? "Yes"
                                  : "No"
                                : ""}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="pb-4" colSpan="2">
                            <label
                              htmlFor=""
                              className="mb-0 text-uppercase small px-4"
                            >
                              {" "}
                              Select Last Date of Pest Control{" "}
                            </label>{" "}
                            <input
                              value={
                                formField
                                  ? readableDate(formField.pestControl)
                                  : ""
                              }
                              type="date"
                              className="form-control px-4"
                              disabled
                            />
                          </td>{" "}
                          <td className="pb-4"></td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="pestControl.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="bbjjxxnbcategoryOkaybb"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={values.pestControl.whsstatus === true}
                                onChange={() =>
                                  setFieldValue("pestControl.whsstatus", true)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjxxnbcategoryOkaybb"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="pestControl.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="bbjjyynbcategoryNotOkaybb"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={values.pestControl.whsstatus === false}
                                onChange={() =>
                                  setFieldValue("pestControl.whsstatus", false)
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjyynbcategoryNotOkaybb"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`pestControl.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.pestControl.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.pestControl.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                        <tr>
                          <td className="">
                            <p className="mb-5 pb-1"> Others: </p>{" "}
                          </td>{" "}
                          <td className="" colSpan="4">
                            <textarea
                              value={formField ? formField.others : ""}
                              type="text"
                              className="form-control px-4 rounded-md"
                              placeholder=""
                              rows="4"
                              disabled
                            >
                              {" "}
                            </textarea>{" "}
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>{" "}
                          <td className="w-25px"></td>
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="safetySecurityOther.whsstatus"
                                className="common-radio-deep-blue-input"
                                id="bbjjxxnbcnnategoryOkay"
                                type="radio"
                                value="okay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.safetySecurityOther.whsstatus === true
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "safetySecurityOther.whsstatus",
                                    true
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjxxnbcnnategoryOkay"
                              >
                                Okay{" "}
                              </label>{" "}
                            </div>{" "}
                            <div className="form-check common-radio-deep-blue mx-3">
                              <Field
                                name="safetySecurityOther.whsstatus"
                                className="common-radio-deep-blue-input"
                                type="radio"
                                id="bbjjyynbcnnategoryNotOkay"
                                value="notOkay"
                                required
                                disabled={viewMood}
                                checked={
                                  values.safetySecurityOther.whsstatus === false
                                }
                                onChange={() =>
                                  setFieldValue(
                                    "safetySecurityOther.whsstatus",
                                    false
                                  )
                                }
                              />{" "}
                              <label
                                className="common-radio-deep-blue-label pl-sm-4"
                                htmlFor="bbjjyynbcnnategoryNotOkay"
                              >
                                Not Okay{" "}
                              </label>{" "}
                            </div>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <Field
                              disabled={viewMood}
                              name={`safetySecurityOther.whsremark`}
                              type="text"
                              className="form-control bg-white px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>
                        </tr>{" "}
                        <tr>
                          <td className="w-25px"></td>
                          <td className="w-25px"></td>{" "}
                          <td
                            className="mw-250px form-inline form-group py-3 mb-0 pr-0"
                            colSpan="2"
                          >
                            <p>
                              {" "}
                              Field Agent Remarks:{" "}
                              {values.safetySecurityOther.fastatus === true
                                ? "okay"
                                : "not okay"}{" "}
                            </p>{" "}
                          </td>{" "}
                          <td className="mw-150px">
                            <input
                              disabled
                              value={values.safetySecurityOther.faremark}
                              type="text"
                              className="form-control  px-4 mb-2 mr-4"
                              placeholder="Remarks"
                            />
                          </td>{" "}
                        </tr>{" "}
                      </tbody>
                    </table>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="row justify-content-end">
                <div className="col-auto ">
                  <button
                    type="submit"
                    className="btn btn-deep-blue add-class remove-class "
                    disabled={data.isPending}
                  >
                    Save{" "}
                    {data.isPending ? <Spinner animation="border" /> : null}{" "}
                  </button>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default SafetyPendingForm;
