import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";

const BookingList = ({ item, status, index }) => {
  return (
    <tr>
      <td className="text-center py-2"> {index} </td>{" "}
      <td> {item?.warehouse?.warehouseId} </td>{" "}
      <td> {item?.customerRfq.id} </td> <td> {item?.customer?.companyName} </td>{" "}
      <td>BK {item?.id} </td> <td> {readableDate(item?.created_at)} </td>{" "}
      <td>
        <span
          className={`${
            item?.adminStatus === "CONFIRMED" ? "text-deep-blue" : ""
          } ${item?.adminStatus === "CANCELLED" ? "text-red" : ""}`}
        >
          {" "}
          {item?.adminStatus}{" "}
        </span>{" "}
      </td>{" "}
      <td>
        {" "}
        <Link to={`booking-details/${item?.id}?status=${status}`}>
          {" "}
          <i className="fas fa-eye"> </i>
        </Link>{" "}
      </td>{" "}
    </tr>
  );
};

export default BookingList;
