import React from 'react';
import { Link } from 'react-router-dom';
import { readableDate } from '../../components/helper/reuse';

const WarehouseRejectedList = ({ item, index }) => {
  const { id, warehouseName, created_at, adminStatus } = item;

  return (
    <tr>
      <td>
        {' '}
        <div className='cell'> {index} </div>
      </td>
      <td>
        {' '}
        <div className='cell'> {item.warehouseId ? item.warehouseId : '-'} </div>
      </td>
      <td>
        {' '}
        <div className='cell'> {warehouseName} </div>
      </td>
      <td>
        {' '}
        <div className='cell'> {adminStatus} </div>
      </td>
      <td className='text-center'>
        {' '}
        <Link
          to={`/rejected-details/${id}/contact-detail`}
          className='btn p-1 text-nowrap font-weight-bold text-deep-blue text-uppercase'
        >
          {' '}
          View Details{' '}
        </Link>
      </td>
    </tr>
  );
};

export default WarehouseRejectedList;
