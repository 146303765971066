import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import LayoutOne from '../../layout/LayoutOne';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import WarehouseApprovedForm from '../../wrapper/warehouseManagement/approvedForms/WarehouseApprovedForm';
import ContactApprovedForm from '../../wrapper/warehouseManagement/approvedForms/ContactApprovedForm';
import SpaceApprovedForm from '../../wrapper/warehouseManagement/approvedForms/SpaceApprovedForm';
import OfficeApprovedForm from '../../wrapper/warehouseManagement/approvedForms/OfficeApprovedForm';
import MhApprovedForm from '../../wrapper/warehouseManagement/approvedForms/MhApprovedForm';
import SafetyApprovedForm from '../../wrapper/warehouseManagement/approvedForms/SafetyApprovedForm';
import PermitApprovedForm from '../../wrapper/warehouseManagement/approvedForms/PermitApprovedForm';
import MaterialApprovedForm from '../../wrapper/warehouseManagement/approvedForms/MaterialApprovedForm';
import PhotoApproveForm from '../../wrapper/warehouseManagement/approvedForms/PhotoApproveForm';
import EightyApprovedForm from '../../wrapper/warehouseManagement/approvedForms/EightyApprovedForm';
import {
  fetchWarehouseByIdAndType,
  WarehouseByIdType,
  changeWarehouseStatus1,
  fetchWarehouseByIdAndTypePending,
  updateWarehouseStatusResubmitted,
  changeWarehouseStatus,
  updateWarehouseStatusAdminApproved,
  clearSingleDataState,
} from '../../store/action/warehouseAction';
import { useHistory, useParams } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Spinner from 'react-bootstrap/Spinner';

import { CssBaseline, Container, Paper, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
// import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { FormProvider } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import { Typography, TextField, Button } from '@material-ui/core';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const steps = [
  'Contact detail',
  'Storage space',
  'IT Infra',
  'MH Infra',
  'Safety Info',
  'Permits',
  'Material Type',
  'Warehouse Photo',
  'Form 80',
];
const RoutesStep = steps.map((elem, index) => {
  return elem.toLowerCase().replace(' ', '-');
});

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const StepperSx = {
  '& .MuiStepConnector-root': {
    left: 'calc(-50% + 40px)',
    right: 'calc(50% + 40px)',
  },
  '& .MuiStepConnector-line': {
    marginTop: '22px',
  },
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: <GroupAddIcon />,
    2: <VideoLabelIcon />,
    3: <VideoLabelIcon />,
    4: <GroupAddIcon />,
    5: <VideoLabelIcon />,
    6: <GroupAddIcon />,
    7: <VideoLabelIcon />,
    8: <GroupAddIcon />,
    9: <VideoLabelIcon />,
    // 10: <GroupAddIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getStepContent(step, warehouseId, slug, read, history) {
  switch (slug) {
    // case 'warehouse-details':
    //   return <WarehouseApprovedForm viewMood={true} />;
    case 'contact-detail':
      return <ContactApprovedForm viewMood={true} />;
    case 'storage-space':
      return <SpaceApprovedForm viewMood={true} />;
    case 'it-infra':
      return <OfficeApprovedForm viewMood={true} />;
    case 'mh-infra':
      return <MhApprovedForm viewMood={true} />;
    case 'safety-info':
      return <SafetyApprovedForm viewMood={true} />;
    case 'permits':
      return <PermitApprovedForm viewMood={true} />;
    case 'material-type':
      return <MaterialApprovedForm viewMood={true} />;
    case 'warehouse-photo':
      return <PhotoApproveForm viewMood={true} />;
    case 'form-80':
      return <EightyApprovedForm viewMood={true} />;
    default:
      history.push('/');
  }
}

const ApprovedDetails = () => {
  const { warehouseId, slug } = useParams();
  const history = useHistory();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const [assigned, setAssigned] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const navigate = useNavigate();
  //   const classes = useStyles();
  const dispatch = useDispatch();
  // const [expanded, setExpanded] = useState(false);
  // const [manuallyMsg, setManuallyMsg] = useState([]);

  //linear stepper state
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  // const [checkawait, setCheckawait] = useState(false);

  const [stepError, setStepError] = useState('error message Display');

  const isStepFailed = (step) => {
    // return step === activeStep
  };

  useEffect(() => {
    console.log('clear loader', warehouseId, slug, data);
    if (warehouseId !== undefined && slug !== undefined) {
      dispatch(fetchWarehouseByIdAndType(warehouseId, slug));
    }
  }, [slug, dispatch, warehouseId]);

  const handleNext = () => {
    dispatch(clearSingleDataState([]));
    if (activeStep < steps.length - 1) {
      console.log('active step', activeStep);
      setActiveStep(activeStep + 1);
      history.push(`/rejected-details/${warehouseId}/${RoutesStep[activeStep + 1]}`);
    }
  };

  const handleBack = () => {
    dispatch(clearSingleDataState([]));
    setActiveStep(activeStep - 1);
    history.push(`/rejected-details/${warehouseId}/${RoutesStep[activeStep - 1]}`);
  };
  useEffect(() => {
    const index = RoutesStep.findIndex((elem) => elem === slug);
    console.log('index');
    console.log(index);
    setActiveStep(index);
  }, [slug]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet='utf-8' />
        <title> Pending Warehouse </title>{' '}
      </Helmet>
      <div className=''>
        <div className='row justify-content-end align-items-center py-3 px-3 mx-0'>
          <div className='col-12 '>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <h5 className='backButton mb-4 text-dark'>
                    <i
                      onClick={() => history.goBack()}
                      className='fas fa-chevron-left mr-3 cursorPointer'
                    ></i>
                    Approved Warehouse
                  </h5>

                  <>
                    <CssBaseline />
                    <Container component={Box} p={4} style={{ padding: '0px' }}>
                      <Paper component={Box} p={3} style={{ padding: '0px' }} className='py-4'>
                        <>
                          <Stack sx={{ width: '100%', paddingBottom: '15px' }} spacing={4}>
                            <Stepper
                              alternativeLabel
                              activeStep={activeStep}
                              sx={StepperSx}
                              connector={<ColorlibConnector />}
                            >
                              {steps.map((label, index) => {
                                const labelProps = {};
                                if (isStepFailed(index)) {
                                  labelProps.optional = (
                                    <Typography variant='caption' color='error'>
                                      {stepError}
                                    </Typography>
                                  );

                                  labelProps.error = true;
                                }

                                return (
                                  <Step key={label}>
                                    <Typography align='center'>{label}</Typography>
                                    {Object.keys(labelProps).length > 0 ? (
                                      <>
                                        <StepLabel {...labelProps}></StepLabel>
                                      </>
                                    ) : (
                                      <>
                                        <StepLabel
                                          {...labelProps}
                                          StepIconComponent={ColorlibStepIcon}
                                        ></StepLabel>
                                      </>
                                    )}
                                  </Step>
                                );
                              })}
                            </Stepper>
                          </Stack>
                          {activeStep === steps.length ? (
                            <Typography variant='h3' align='center'>
                              Thank You
                            </Typography>
                          ) : (
                            <>
                              <FormProvider>
                                <form onSubmit={handleNext}>
                                  {/* {getStepContent(activeStep)}           */}
                                  {getStepContent(activeStep, warehouseId, slug, read, history)}
                                  {/* {isLoading === false && checkawait === true ? (
                                    getStepContent(activeStep, warehouseId, slug, history)
                                  ) : (
                                    <SpinnerLoader />
                                    // alert("hii there")
                                  )} */}

                                  <div className='nextPreviousform pb-0'>
                                    <span>
                                      <MobileStepper
                                        variant='dots'
                                        steps={9}
                                        position='static'
                                        activeStep={activeStep}
                                        sx={{ flexGrow: 1, width: '50%', margin: '0 auto' }}
                                        nextButton={
                                          <Button
                                            size='small'
                                            onClick={() => {
                                              setIsLoading(true);
                                              handleNext();
                                            }}
                                            disabled={activeStep === steps.length - 1}
                                          >
                                            Next
                                            {theme.direction === 'rtl' ? (
                                              <KeyboardArrowLeft />
                                            ) : (
                                              <KeyboardArrowRight />
                                            )}
                                          </Button>
                                        }
                                        backButton={
                                          <Button
                                            size='small'
                                            onClick={() => {
                                              setIsLoading(true);
                                              handleBack();
                                            }}
                                            disabled={activeStep === 0}
                                          >
                                            {theme.direction === 'rtl' ? (
                                              <KeyboardArrowRight />
                                            ) : (
                                              <KeyboardArrowLeft />
                                            )}
                                            Back
                                          </Button>
                                        }
                                      />
                                    </span>
                                  </div>
                                </form>
                              </FormProvider>
                            </>
                          )}
                        </>
                      </Paper>
                    </Container>
                    <div className='row justify-content-end'>
                      <div className='col-auto my-2 '></div> <div className='col-auto my-2 '></div>
                      <div className='col-auto my-2'>
                        {' '}
                        {/* {console.log('warehouseDetail', data.warehouseDetail.finalSubmitWhs)}{' '} */}
                        {/* {data.warehouseDetail.finalSubmitWhs === false ? (
                            <button
                              // disabled={
                              //   data.isPending ||
                              //   data.warehouseDetail.finalSubmitWhs === false
                              // }
                              type='button'
                              disabled={
                                data?.singleFormData !== undefined &&
                                data?.singleFormData?.remarks === null
                                  ? true
                                  : false
                              }
                              className={`btn btn-deep-blue ${!read ? '' : 'd-none'}`}
                              onClick={() => {
                                alert('Please fill the admin details in the form first');
                              }}
                            >
                              Assign to FA
                            </button>
                          ) : (
                            <button
                              //  to={`/assign-form-to-field-agent/${warehouseId}`}
                              onClick={() => {
                                assignToFieldAgen();
                              }}
                              type='button'
                              className={`btn btn-deep-blue ${!read ? '' : 'd-none'}`}
                              disabled={
                                data?.singleFormData !== undefined &&
                                data?.singleFormData?.remarks === null
                                  ? true
                                  : false
                              }
                            >
                              Assign to FA{' '}
                            </button>
                          )}{' '} */}
                      </div>{' '}
                    </div>{' '}
                  </>
                  {/*test*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutOne>
  );
};

export default ApprovedDetails;
