import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_ERROR_MSG,
  LOGIN_PENDING,
  SESSIONEXPIRED,
  AUTHENTICATED,
  USER_INFO,
} from "../../types";

import { LOGIN_USER } from "../../../services/urls";

import axiosauth from "../../../services/axios-auth";

export function loginSuccess(user) {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
}

export function isError(user) {
  return {
    type: LOGIN_ERROR,
    payload: user,
  };
}

export function isErrorMsg(user) {
  return {
    type: LOGIN_ERROR_MSG,
    payload: user,
  };
}

export function isPending(user) {
  return {
    type: LOGIN_PENDING,
    payload: user,
  };
}

export function userDetail(user) {
  return {
    type: USER_INFO,
    payload: user,
  };
}

export function sessionexpire(user) {
  return {
    type: SESSIONEXPIRED,
    session: user,
  };
}

export function authenticated(bool) {
  return {
    type: AUTHENTICATED,
    authenticated: bool,
  };
}

export function userLogin(data) {
  return async (dispatch) => {
    dispatch(isPending(true));
    dispatch(isError(null));
    dispatch(isErrorMsg(null));

    try {
      axiosauth
        .post(LOGIN_USER, data)
        .then((response) => {
          const res = JSON.parse(response.data);
          console.log("Res==--->", res);

          if (res.statusCode === 200) {
            dispatch(isPending(false));

            if (res.data.user.roleId === 1) {
              localStorage.setItem("userData", JSON.stringify(res.data.user));
              localStorage.setItem("accesstoken", res.data.token);
              dispatch(userDetail(res.data.user));
              dispatch(authenticated(true));
              dispatch(loginSuccess("Login Successful"));
            }
          } else {
            console.log(res.message);
            dispatch(isErrorMsg(res.message));
            dispatch(isPending(false));
          }
        })
        .catch((error) => {
          dispatch(isPending(false));
          let msg = JSON.parse(error.response?.data);
          dispatch(isError(msg.message));
          setTimeout(() => {
            dispatch(isError(null));
          }, 5000);
          dispatch(isErrorMsg(null));
        })
        .then(() => {
          dispatch(isPending(false));
          console.log("-----always executes");
        });
    } catch (e) {
      console.log("try===>", e);
    }
  };
}

export function logoutUser() {
  localStorage.clear();
  return (dispatch) => {
    dispatch(loginSuccess(null));
    dispatch(authenticated(false));
    dispatch(isError(null));
    dispatch(isPending(false));
    dispatch(isErrorMsg(null));
  };
}
