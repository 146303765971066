import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import {
  // updateMaterialType,
  // addNewWarehouse,
  updateWarehousependingDetails,
} from './../../../store/action/warehouseAction';
import CustomLoader from '../../../CustomLoader';
// import FormSuccess from './../../../components/common/FormSuccess';
import Spinner from 'react-bootstrap/Spinner';
// import * as Yup from 'yup';

const MaterialPendingForm = ({ warehouseId, read, slug }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const [materialForm, setMaterialForm] = useState({
    dryMaterial: false,
    wetMaterial: false,
    agriAndAlliedIndustries: false,
    autoMobiles: false,
    autoComponents: false,
    aviation: false,
    chemicalDry: false,
    chemicalWet: false,
    consumerDurables: false,
    ecommerce: false,
    educationRelated: false,
    engineeringGoods: false,
    fmcg: false,
    healthAndPharma: false,
    itAndItes: false,
    manufacturing: false,
    mediaAndEntertainment: false,
    oilAndGas: false,
    power: false,
    renewableEnergy: false,
    retail: false,
    scienceAndTechnology: false,
    steel: false,
    telecommunication: false,
    textTiles: false,
    tourismAndHospitality: false,
    prominentCustomersInTheWarehouse: '',
  });
  const {
    dryMaterial,
    wetMaterial,
    agriAndAlliedIndustries,
    autoMobiles,
    autoComponents,
    aviation,
    chemicalDry,
    chemicalWet,
    consumerDurables,
    ecommerce,
    educationRelated,
    engineeringGoods,
    fmcg,
    healthAndPharma,
    itAndItes,
    manufacturing,
    mediaAndEntertainment,
    oilAndGas,
    power,
    renewableEnergy,
    // retail,
    scienceAndTechnology,
    steel,
    telecommunication,
    textTiles,
    tourismAndHospitality,
    prominentCustomersInTheWarehouse,
  } = materialForm;

  // console.log(retail);
  console.log(dryMaterial);
  const [remarkForm, setRemarkForm] = useState({
    dryMaterial: {
      value: '',
      whsremark: '',
    },
    wetMaterial: {
      value: '',
      whsremark: '',
    },
    agriAndAlliedIndustries: {
      value: '',
      whsremark: '',
    },
    autoMobiles: {
      value: '',
      whsremark: '',
    },
    autoComponents: {
      value: '',
      whsremark: '',
    },
    aviation: {
      value: '',
      whsremark: '',
    },
    chemicals: [
      {
        type: 'dryup',
        chemical: {
          value: '',
          whsremark: '',
        },
      },
      {
        type: 'wetup',
        chemical: {
          value: '',
          whsremark: '',
        },
      },
    ],
    consumerDurables: {
      value: '',
      whsremark: '',
    },
    ecommerce: {
      value: '',
      whsremark: '',
    },
    educationRelated: {
      value: '',
      whsremark: '',
    },
    engineeringGoods: {
      value: '',
      whsremark: '',
    },
    fmcg: {
      value: '',
      whsremark: '',
    },
    healthAndPharma: {
      value: '',
      whsremark: '',
    },
    itAndItes: {
      value: '',
      whsremark: '',
    },
    manufacturing: {
      value: '',
      whsremark: '',
    },
    mediaAndEntertainment: {
      value: '',
      whsremark: '',
    },
    oilAndGas: {
      value: '',
      whsremark: '',
    },
    power: {
      value: '',
      whsremark: '',
    },
    renewableEnergy: {
      value: '',
      whsremark: '',
    },
    // retail: {
    //   value: '',
    //   whsremark: '',
    // },
    scienceAndTechnology: {
      value: '',
      whsremark: '',
    },
    steel: {
      value: '',
      whsremark: '',
    },
    telecommunication: {
      value: '',
      whsremark: '',
    },
    textTiles: {
      value: '',
      whsremark: '',
    },
    tourismAndHospitality: {
      value: '',
      whsremark: '',
    },
    prominentCustomersInTheWarehouse: {
      value: '',
      whsremark: '',
    },
  });

  const onSubmit = (fields) => {
    // console.log(fields, 'before gernate payload', fields);

    // console.log(fields, 'hello abcd');
    // return false;
    const payload = {
      data: {
        fields: fields,
      },
      warehouseId: warehouseId,
      slug: slug,
    };
    // console.log(payload, 'POST PAYLOAD');
    dispatch(updateWarehousependingDetails(payload));
  };

  useEffect(() => {
    // console.log(data?.singleFormData, '|*|singleFormData|* fIRST|');
    if (
      data?.singleFormData !== null &&
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks !== undefined &&
      Object.keys(data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks).length > 0
    ) {
      console.log('|**|hey i am outer if material section|**|', data?.singleFormData);
      setRemarkForm({
        dryMaterial: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.dryMaterial?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.dryMaterial?.whsremark,
        },
        wetMaterial: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.wetMaterial?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.wetMaterial?.whsremark,
        },
        agriAndAlliedIndustries: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.agriAndAlliedIndustries
              ?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.agriAndAlliedIndustries
              ?.whsremark,
        },
        autoMobiles: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.autoMobiles?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.autoMobiles?.whsremark,
        },
        autoComponents: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.autoComponents?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.autoComponents?.whsremark,
        },
        aviation: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.aviation?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.aviation?.whsremark,
        },
        chemicals: [
          {
            type: 'dryup',
            chemical: {
              value:
                data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.chemicals[0]?.chemical
                  ?.value,
              whsremark:
                data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.chemicals[0]?.chemical
                  ?.whsremark,
              // data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[0]
              //   ?.chemical?.whsremark,
            },
          },
          {
            type: 'wetup',
            chemical: {
              value:
                data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.chemicals[1]?.chemical
                  ?.value,
              whsremark:
                data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.chemicals[1]?.chemical
                  ?.whsremark,
              // data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[1]
              //   ?.chemical?.whsremark,
            },
          },
        ],
        consumerDurables: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.consumerDurables?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.consumerDurables
              ?.whsremark,
        },
        ecommerce: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.ecommerce?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.ecommerce?.whsremark,
        },
        educationRelated: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.educationRelated?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.educationRelated
              ?.whsremark,
        },
        engineeringGoods: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.engineeringGoods?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.engineeringGoods
              ?.whsremark,
        },
        fmcg: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.fmcg?.value,
          whsremark: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.fmcg?.whsremark,
        },
        healthAndPharma: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.healthAndPharma?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.healthAndPharma
              ?.whsremark,
        },
        itAndItes: {
          value: data.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.itAndItes?.value,
          whsremark:
            data.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.itAndItes?.whsremark,
        },
        manufacturing: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.manufacturing?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.manufacturing?.whsremark,
        },
        mediaAndEntertainment: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.mediaAndEntertainment
              ?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.mediaAndEntertainment
              ?.whsremark,
        },
        oilAndGas: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.oilAndGas?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.oilAndGas?.whsremark,
        },
        power: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.power?.value,
          whsremark: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.power?.whsremark,
        },
        renewableEnergy: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.renewableEnergy?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.renewableEnergy
              ?.whsremark,
        },
        // retail: {
        //   value: '',
        //   whsremark:
        //     data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.retail?.whsremark,
        // },
        scienceAndTechnology: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.scienceAndTechnology
              ?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.scienceAndTechnology
              ?.whsremark,
        },
        steel: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.steel?.value,
          whsremark: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.steel?.whsremark,
        },
        telecommunication: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.telecommunication?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.telecommunication
              ?.whsremark,
        },
        textTiles: {
          value: data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.textTiles?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.textTiles?.whsremark,
        },
        tourismAndHospitality: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.tourismAndHospitality
              ?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.tourismAndHospitality
              ?.whsremark,
        },
        prominentCustomersInTheWarehouse: {
          value:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks
              ?.prominentCustomersInTheWarehouse?.value,
          whsremark:
            data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks
              ?.prominentCustomersInTheWarehouse?.whsremark,
        },
      });
      setMaterialForm({
        dryMaterial:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.dryMaterial?.value === true
            ? true
            : false,
        wetMaterial:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.wetMaterial?.value === true
            ? true
            : false,
        agriAndAlliedIndustries:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.agriAndAlliedIndustries
            ?.value === true
            ? true
            : false,
        autoMobiles:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.autoMobiles?.value === true
            ? true
            : false,
        autoComponents:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.autoComponents?.value ===
            true
            ? true
            : false,
        aviation:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.aviation?.value === true
            ? true
            : false,
        chemicalDry:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.chemicals[0]?.chemical
            ?.value === true
            ? true
            : false,
        chemicalWet:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.chemicals[1]?.chemical
            ?.value === true
            ? true
            : false,
        consumerDurables:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.consumerDurables?.value ===
            true
            ? true
            : false,
        ecommerce:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.ecommerce?.value === true
            ? true
            : false,
        educationRelated:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.educationRelated?.value ===
            true
            ? true
            : false,
        engineeringGoods:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.engineeringGoods?.value ===
            true
            ? true
            : false,
        fmcg:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.fmcg?.value === true
            ? true
            : false,
        healthAndPharma:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.healthAndPharma?.value ===
            true
            ? true
            : false,
        itAndItes:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.itAndItes?.value === true
            ? true
            : false,
        manufacturing:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.manufacturing?.value ===
            true
            ? true
            : false,
        mediaAndEntertainment:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.mediaAndEntertainment
            ?.value === true
            ? true
            : false,
        oilAndGas:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.oilAndGas?.value === true
            ? true
            : false,
        power:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.power?.value === true
            ? true
            : false,
        renewableEnergy:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.renewableEnergy?.value ===
            true
            ? true
            : false,
        // retail: data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.dryMaterial?.value!==""?true:false,
        scienceAndTechnology:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.scienceAndTechnology
            ?.value === true
            ? true
            : false,
        steel:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.steel?.value === true
            ? true
            : false,
        telecommunication:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.telecommunication?.value ===
            true
            ? true
            : false,
        textTiles:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.textTiles?.value === true
            ? true
            : false,
        tourismAndHospitality:
          data?.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.tourismAndHospitality
            ?.value === true
            ? true
            : false,
        // prominentCustomersInTheWarehouse: '',
      });
    } else if (
      data?.singleFormData !== null &&
      data?.singleFormData !== undefined &&
      data?.singleFormData?.warehouseRes !== null &&
      data?.singleFormData?.warehouseRes !== undefined &&
      data?.singleFormData?.warehouseRes?.warehouseMaterialTypes !== undefined &&
      data?.singleFormData?.warehouseRes?.warehouseMaterialTypes !== null
    ) {
      setRemarkForm({
        dryMaterial: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.dryMaterial,
          whsremark: '',
        },
        wetMaterial: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.wetMaterial,
          whsremark: '',
        },
        agriAndAlliedIndustries: {
          value:
            data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.agriAndAlliedIndustries,
          whsremark: '',
        },
        autoMobiles: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.autoMobiles,
          whsremark: '',
        },
        autoComponents: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.autoComponents,
          whsremark: '',
        },
        aviation: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.aviation,
          whsremark: '',
        },
        chemicals: [
          {
            type: 'dryup',
            chemical: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.chemicals[0]
                  ?.chemical,
              whsremark: '',
              // data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[0]
              //   ?.chemical?.whsremark,
            },
          },
          {
            type: 'wetup',
            chemical: {
              value:
                data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.chemicals[1]
                  ?.chemical,
              whsremark: '',
              // data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.chemicals[1]
              //   ?.chemical?.whsremark,
            },
          },
        ],
        consumerDurables: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.consumerDurables,
          whsremark: '',
        },
        ecommerce: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.ecommerce,
          whsremark: '',
        },
        educationRelated: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.educationRelated,
          whsremark: '',
        },
        engineeringGoods: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.engineeringGoods,
          whsremark: '',
        },
        fmcg: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.fmcg,
          whsremark: '',
        },
        healthAndPharma: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.healthAndPharma,
          whsremark: '',
        },
        itAndItes: {
          value: data.singleFormData?.remarks?.warehouseMaterialTypesRemarks?.itAndItes,
          whsremark: '',
        },
        manufacturing: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.manufacturing,
          whsremark: '',
        },
        mediaAndEntertainment: {
          value:
            data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.mediaAndEntertainment,
          whsremark: '',
        },
        oilAndGas: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.oilAndGas,
          whsremark: '',
        },
        power: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.power,
          whsremark: '',
        },
        renewableEnergy: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.renewableEnergy,
          whsremark: '',
        },
        // retail: {
        //   value: '',
        //   whsremark:
        //     data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.retail?.whsremark,
        // },
        scienceAndTechnology: {
          value:
            data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.scienceAndTechnology,
          whsremark: '',
        },
        steel: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.steel,
          whsremark: '',
        },
        telecommunication: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.telecommunication,
          whsremark: '',
        },
        textTiles: {
          value: data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.textTiles,
          whsremark: '',
        },
        tourismAndHospitality: {
          value:
            data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.tourismAndHospitality,
          whsremark: '',
        },
        prominentCustomersInTheWarehouse: {
          value:
            data?.singleFormData?.warehouseRes?.warehouseMaterialTypes
              ?.prominentCustomersInTheWarehouse,
          whsremark: '',
        },
      });
      setMaterialForm({
        dryMaterial:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.dryMaterial === true
            ? true
            : false,
        wetMaterial:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.wetMaterial === true
            ? true
            : false,
        agriAndAlliedIndustries:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.agriAndAlliedIndustries ===
            true
            ? true
            : false,
        autoMobiles:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.autoMobiles === true
            ? true
            : false,
        autoComponents:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.autoComponents === true
            ? true
            : false,
        aviation:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.aviation === true
            ? true
            : false,
        chemicalDry:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.chemicals[0]?.active ===
            true
            ? true
            : false,
        chemicalWet:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.chemicals[1]?.active ===
            true
            ? true
            : false,
        consumerDurables:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.consumerDurables === true
            ? true
            : false,
        ecommerce:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.ecommerce === true
            ? true
            : false,
        educationRelated:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.educationRelated === true
            ? true
            : false,
        engineeringGoods:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.engineeringGoods === true
            ? true
            : false,
        fmcg:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.fmcg === true
            ? true
            : false,
        healthAndPharma:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.healthAndPharma === true
            ? true
            : false,
        itAndItes:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.itAndItes === true
            ? true
            : false,
        manufacturing:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.manufacturing === true
            ? true
            : false,
        mediaAndEntertainment:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.mediaAndEntertainment ===
            true
            ? true
            : false,
        oilAndGas:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.oilAndGas === true
            ? true
            : false,
        power:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.power === true
            ? true
            : false,
        renewableEnergy:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.renewableEnergy === true
            ? true
            : false,
        // retail: data?.singleFormData?.warehouseRej?.warehouseMaterialTypesRemarks?.dryMaterial?.value!==""?true:false,
        scienceAndTechnology:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.scienceAndTechnology ===
            true
            ? true
            : false,
        steel:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.steel === true
            ? true
            : false,
        telecommunication:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.telecommunication === true
            ? true
            : false,
        textTiles:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.textTiles === true
            ? true
            : false,
        tourismAndHospitality:
          data?.singleFormData?.warehouseRes?.warehouseMaterialTypes[0]?.tourismAndHospitality ===
            true
            ? true
            : false,
        // prominentCustomersInTheWarehouse: '',
      });
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {/* {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
         <FormSuccess
           onClick={() => dispatch(addNewWarehouse([]))}
           message={data.addNewResponse.message}
         />
       ) : null} */}
      {data.isLoading === true ? (
        <CustomLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={remarkForm}
          // validate={validate(validationSchema)}
          onSubmit={(fields) => onSubmit(fields)}
          render={({ errors, status, onChange, touched, values, setFieldValue }) => {
            return (
              <Form className='w-100'>
                <div className='row bg-whitAndIte rounded mx-0 col-xxxl-11'>
                  <div className='col-12 border-0 border-bottom-0 table-responsive table-collapse'>
                    <table className='table customTable'>
                      <tbody>
                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Dry Material:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                name='dryMaterial.value'
                                // onChange={(e) => {
                                //   handleChange('dryMaterial', e);
                                // }}
                                checked={dryMaterial}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='dryMaterial'
                                value={true}
                                disabled={true}
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='dryMaterial'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('dryMaterial', e)}
                                name='dryMaterial.value'
                                checked={!dryMaterial}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                id='dryMaterial1'
                                value={false}
                                disabled={true}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='dryMaterial1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='dryMaterial.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Wet Material:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('wetMaterial', e)}
                                checked={wetMaterial}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='wetMaterial.value'
                                id='wetMaterial'
                                value={true}
                                hidden=''
                                disabled={true}
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='wetMaterial'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('wetMaterial', e)}
                                checked={!wetMaterial}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='wetMaterial.value'
                                id='wetMaterial1'
                                value={false}
                                hidden=''
                                disabled={true}
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='wetMaterial1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='wetMaterial.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Agri & Allied Industries:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('agriAndAlliedIndustries', e)}
                                checked={agriAndAlliedIndustries}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='agriAndAlliedIndustries.value'
                                id='alliedIndustries'
                                value={true}
                                hidden=''
                                disabled={true}
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='alliedIndustries'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('agriAndAlliedIndustries', e)}
                                checked={!agriAndAlliedIndustries}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='agriAndAlliedIndustries.value'
                                id='alliedIndustries1'
                                value={false}
                                disabled={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='alliedIndustries1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='agriAndAlliedIndustries.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>AutoMobiles:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('autoMobiles', e)}
                                checked={autoMobiles}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='autoMobiles.value'
                                id='autoMobiles'
                                disabled={true}
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='autoMobiles'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('autoMobiles', e)}
                                checked={!autoMobiles}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='autoMobiles.value'
                                id='autoMobiles1'
                                disabled={true}
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='autoMobiles1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='autoMobiles.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Auto Components:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('autoComponents', e)}
                                checked={autoComponents}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='autoComponents.value'
                                id='autoComponents'
                                value={true}
                                hidden=''
                                disabled={true}
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='autoComponents'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('autoComponents', e)}
                                checked={!autoComponents}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='autoComponents.value'
                                id='autoComponents1'
                                value={false}
                                hidden=''
                                disabled={true}
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='autoComponents1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='autoComponents.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Aviation:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('aviation', e)}
                                checked={aviation}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='aviation.value'
                                id='aviation'
                                value={true}
                                hidden=''
                                disabled={true}
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='aviation'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('aviation', e)}
                                checked={!aviation}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='aviation.value'
                                id='aviation1'
                                value={false}
                                hidden=''
                                disabled={true}
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='aviation1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='aviation.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Chemicals (Dry):</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChangeAry('chemicalDry', 0, e)}
                                checked={chemicalDry}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='chemicals[0].chemical.value'
                                id='chemicalDry'
                                disabled={true}
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='chemicalDry'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChangeAry('chemicalDry', 0, e)}
                                checked={!chemicalDry}
                                className='common-radio-deep-blue-input'
                                disabled={true}
                                type='radio'
                                name='chemicals[0].chemical.value'
                                id='chemicalDry1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='chemicalDry1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              // chemicals[0].chemical.whsremark
                              name='chemicals[0].chemical.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Chemicals (Wet):</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChangeAry('chemicalWet', 1, e)}
                                checked={chemicalWet}
                                className='common-radio-deep-blue-input'
                                disabled={true}
                                type='radio'
                                name='chemicals[1].chemical.value'
                                id='chemicalWet'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='chemicalWet'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChangeAry('chemicalWet', 1, e)}
                                checked={!chemicalWet}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='chemicals[1].chemical.value'
                                id='chemicalWet1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='chemicalWet1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='chemicals[1].chemical.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Consumer Durables:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('consumerDurables', e)}
                                checked={consumerDurables}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='consumerDurables.value'
                                id='consumerDurables'
                                disabled={true}
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='consumerDurables'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('consumerDurables', e)}
                                checked={!consumerDurables}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='consumerDurables.value'
                                id='consumerDurables1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='consumerDurables1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='consumerDurables.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Ecommerce:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('ecommerce', e)}
                                checked={ecommerce}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='ecommerce.value'
                                disabled={true}
                                id='ecommerce'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='ecommerce'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('ecommerce', e)}
                                checked={!ecommerce}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='ecommerce.value'
                                disabled={true}
                                id='ecommerce1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='ecommerce1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='ecommerce.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Education Related:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('educationRelated', e)}
                                checked={educationRelated}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='educationRelated'
                                disabled={true}
                                id='educationRelated'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='educationRelated'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('educationRelated', e)}
                                checked={!educationRelated}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                disabled={true}
                                name='educationRelated.value'
                                id='educationRelated1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='educationRelated1'
                              >
                                No
                              </label>
                            </div>
                          </td>

                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='educationRelated.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Engineering Goods:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('engineeringGoods', e)}
                                checked={engineeringGoods}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='engineeringGoods.value'
                                disabled={true}
                                id='engineeringGood'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='engineeringGood'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('engineeringGoods', e)}
                                checked={!engineeringGoods}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='engineeringGoods.value'
                                id='engineeringGood1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='engineeringGood1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='engineeringGoods.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>FMCG:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('fmcg', e)}
                                checked={fmcg}
                                className='common-radio-deep-blue-input'
                                disabled={true}
                                type='radio'
                                name='fmcg.value'
                                id='fmcg'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label className='common-radio-deep-blue-label pl-4' htmlFor='fmcg'>
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('fmcg', e)}
                                checked={!fmcg}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='fmcg.value'
                                id='fmcg1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label className='common-radio-deep-blue-label pl-4' htmlFor='fmcg1'>
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='fmcg.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Health & Pharma:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('healthAndPharma', e)}
                                checked={healthAndPharma}
                                className='common-radio-deep-blue-input'
                                disabled={true}
                                type='radio'
                                name='healthAndPharma.value'
                                id='healthAndPharma'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='healthAndPharma'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('healthAndPharma', e)}
                                checked={!healthAndPharma}
                                className='common-radio-deep-blue-input'
                                disabled={true}
                                type='radio'
                                name='healthAndPharma.value'
                                id='healthAndPharma1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='healthAndPharma1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='healthAndPharma.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>IT & ITES:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('itAndItes', e)}
                                checked={itAndItes}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                disabled={true}
                                name='itAndItes.value'
                                id='itAndItes'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='itAndItes'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('itAndItes', e)}
                                checked={!itAndItes}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='itAndItes.value'
                                id='itAndItes1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='itAndItes1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='itAndItes.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>
                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Manufacturing:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('manufacturing', e)}
                                checked={manufacturing}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='manufacturing.value'
                                id='manufacturing'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='manufacturing'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('manufacturing', e)}
                                checked={!manufacturing}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='manufacturing.value'
                                id='manufacturing1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='manufacturing1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='manufacturing.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Media & Entertainment:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('mediaAndEntertainment', e)}
                                checked={mediaAndEntertainment}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='mediaAndEntertainment.value'
                                id='entertainment'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='entertainment'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('mediaAndEntertainment', e)}
                                checked={!mediaAndEntertainment}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='mediaAndEntertainment.value'
                                id='entertainment1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='entertainment1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='mediaAndEntertainment.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>
                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Oil & Gas:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('oilAndGas', e)}
                                checked={oilAndGas}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='oilAndGas.value'
                                id='oilAndGas'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='oilAndGas'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('oilAndGas', e)}
                                checked={!oilAndGas}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='oilAndGas.value'
                                id='oilAndGas1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='oilAndGas1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='oilAndGas.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Power:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('power', e)}
                                checked={power}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='power.value'
                                id='power'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label className='common-radio-deep-blue-label pl-4' htmlFor='power'>
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('power', e)}
                                checked={!power}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='power.value'
                                id='power1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label className='common-radio-deep-blue-label pl-4' htmlFor='power1'>
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='power.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>
                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Renewable Energy:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('renewableEnergy', e)}
                                checked={renewableEnergy}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                disabled={true}
                                name='renewableEnergy.value'
                                id='energy'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label className='common-radio-deep-blue-label pl-4' htmlFor='energy'>
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('renewableEnergy', e)}
                                checked={!renewableEnergy}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='renewableEnergy.value'
                                id='energy1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='energy1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='renewableEnergy.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>
                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Science & Technology:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('scienceAndTechnology', e)}
                                checked={scienceAndTechnology}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='scienceAndTechnology.value'
                                id='scienceAndTechnology'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='scienceAndTechnology'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('scienceAndTechnology', e)}
                                checked={!scienceAndTechnology}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='scienceAndTechnology.value'
                                id='scienceAndTechnology1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='scienceAndTechnology1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='scienceAndTechnology.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Steel:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('steel', e)}
                                checked={steel}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                disabled={true}
                                name='steel.value'
                                id='steel'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label className='common-radio-deep-blue-label pl-4' htmlFor='steel'>
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('steel', e)}
                                checked={!steel}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                disabled={true}
                                name='steel.value'
                                id='steel1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label className='common-radio-deep-blue-label pl-4' htmlFor='steel1'>
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='steel.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Telecommunication:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('telecommunication', e)}
                                checked={telecommunication}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                disabled={true}
                                name='telecommunication.value'
                                id='telecommunication'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='telecommunication'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('telecommunication', e)}
                                checked={!telecommunication}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                disabled={true}
                                name='telecommunication.value'
                                id='telecommunication1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='telecommunication1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='telecommunication.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>TexTiles:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('textTiles', e)}
                                checked={textTiles}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                disabled={true}
                                name='textTiles.value'
                                id='texTiles'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='texTiles'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('textTiles', e)}
                                checked={!textTiles}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='textTiles.value'
                                id='texTiles1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='texTiles1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='textTiles.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Tourism & Hospitality:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('tourismAndHospitality', e)}
                                checked={tourismAndHospitality}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='tourismAndHospitality.value'
                                id='tourism'
                                value={true}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='tourism'
                              >
                                Yes
                              </label>
                            </div>
                            <div className='form-check common-radio-deep-blue mx-3'>
                              <Field
                                // onChange={(e) => handleChange('tourismAndHospitality', e)}
                                checked={!tourismAndHospitality}
                                disabled={true}
                                className='common-radio-deep-blue-input'
                                type='radio'
                                name='tourismAndHospitality.value'
                                id='tourism1'
                                value={false}
                                hidden=''
                              // disabled={viewMood}
                              />
                              <label
                                className='common-radio-deep-blue-label pl-4'
                                htmlFor='tourism1'
                              >
                                No
                              </label>
                            </div>
                          </td>
                          <td className='text-left px-4 border' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='tourismAndHospitality.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>

                        <tr className='border'>
                          <td className='w-200px'>
                            <p className='mb-0 pb-1'>Prominent Customers in the Warehouse:</p>
                          </td>
                          <td className='col-auto form-inline py-4 mb-1 border-0 mt-4'>
                            {/* <input onChange={(e)=>handleChange(e)} checked={!prominentCustomersInTheWarehouse} className="common-radio-deep-blue-input" type="radio" name="prominentCustomersInTheWarehouse" id="prominentCustomersInTheWarehouse1" value={false} hidden="" disabled={viewMood} />
                              <label className="common-radio-deep-blue-label pl-4" htmlFor="prominentCustomersInTheWarehouse1">No</label> */}
                            <Field
                              // disabled={true}
                              // onChange={(e) => {
                              //   setFieldValue(
                              //     `prominentCustomersInTheWarehouse.value`,
                              //     e.target.value,
                              //   );
                              //   // }
                              //   handleChange1('prominentCustomersInTheWarehouse', e);
                              // }}
                              name={`prominentCustomersInTheWarehouse.value`}
                              // value={prominentCustomersInTheWarehouse.value}
                              type='text'
                              className='form-control'
                            // readOnly={viewMood}
                            />
                          </td>
                          <td className='text-left px-4 border-0' colSpan='2'>
                            {/* <p className="font-weight-bold">Field Agent Remarks</p>  */}
                            <label htmlFor='staticEmail' className='mb-2 px-2'>
                              Whs Remarks
                            </label>
                            <Field
                              name='prominentCustomersInTheWarehouse.whsremark'
                              type='text'
                              className='form-control  px-4 mb-2 mr-4'
                              placeholder='Remarks'
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={`col-12 mt-4`}>
                    <div className='row justify-content-end'>
                      <div className='col-auto'>
                        {Object.keys(touched).length > 0 ? (
                          <button
                            type='submit'
                            disabled={data?.isPending}
                            className='btn btn-deep-blue add-className remove-class'
                          >
                            Save
                            {data?.isPending ? <Spinner animation='border' /> : null}
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      )}
    </>
  );
};

export default MaterialPendingForm;
