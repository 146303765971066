import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import SearchBox from "../../components/common/SearchBox";
import EnquiryPendingList from "../../wrapper/enquiryManagement/EnquiryPendingList";
import { enguiryByPageapproved } from "../../store/action/enquiryAction";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import { CardLoader } from "../../components/helper/CustomLoader";
import { ItemNotFoud } from "../../components/common/CustomLoader";

const CustomerPending = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.ENQUIRYINFO);

  const pageCount = new URLSearchParams(window.location.search).get("page");

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(enguiryByPageapproved(pageNumber), searchHandler);
  };

  useEffect(() => {
    dispatch(enguiryByPageapproved(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const callSearch = () => {
    dispatch(enguiryByPageapproved(parseInt(pageCount), searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Enquiry </title>{" "}
      </Helmet>
      <div className="row align-items-center py-1 px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 bg-white mb-2">
          <div className="row justify-content-between">
            <div className="tabs-scroll col-md-auto mt-1 order-md-1">
              <ul className="nav nav-pills admin-tabs-blue">
                <li className="nav-item">
                  <Link
                    to="/customer-enquiry-approve?page=1"
                    className="nav-link active text-uppercase"
                  >
                    Customer{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to="/vendor-enquiry-approve?page=1"
                    className="nav-link text-uppercase "
                  >
                    Space Provider{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 bg-white mb-2">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
            border={true}
          />{" "}
        </div>
        {data.isLoading ? (
          <CardLoader />
        ) : (
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-12 text-nowrap table-responsive table-gray-admin bg-white p-3">
                {" "}
                {data.enquiryList.data && data.enquiryList.data.length > 0 ? (
                  <table className="table table-dark-custom">
                    <thead>
                      <tr>
                        <th className="text-center"> S.No. </th>{" "}
                        <th> Ticket-ID </th> <th> Ticket-Date </th>{" "}
                        <th> User Id </th> <th> User Name </th>{" "}
                        <th> Status </th> <th> Action </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      {data.enquiryList.data.map((enquiry, index) => {
                        return (
                          <EnquiryPendingList
                            key={index}
                            enquiry={enquiry}
                            index={(pageCount - 1) * 10 + (index + 1)}
                          />
                        );
                      })}{" "}
                    </tbody>{" "}
                  </table>
                ) : (
                  <ItemNotFoud message="Data Not Found" />
                )}{" "}
              </div>{" "}
            </div>
            <div className="pagination-custom">
              {" "}
              {data.enquiryList?.totalCount > 10 && (
                <Pagination
                  activePage={parseInt(pageCount)}
                  itemsCountPerPage={10}
                  totalItemsCount={data.enquiryList?.totalCount}
                  pageRangeDisplayed={1}
                  onChange={handlePageChange}
                  prevPageText={<i className="fas fa-chevron-left" />}
                  nextPageText={<i className="fas fa-chevron-right" />}
                  hideFirstLastPages={true}
                />
              )}{" "}
            </div>{" "}
          </div>
        )}{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default CustomerPending;
