import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import SearchBox from "../../components/common/SearchBox";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { whyByPage, responseWhy } from "../../store/action/whyAction";
import WhyList from "../../wrapper/whyWarehouse/WhyList";
import { CardLoader } from "../../components/helper/CustomLoader";
import Pagination from "react-js-pagination";
import FormSuccess from "../../components/common/FormSuccess";
import { ItemNotFoud } from "../../components/common/CustomLoader";

const WhyWarehouse = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.WHY_INFO);

  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(whyByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(whyByPage(pageNumber, searchHandler));
  };

  const redirect = () => {
    dispatch(responseWhy(null));
    history.replace(`/why-warehouse?page=1`);
  };

  const callSearch = () => {
    dispatch(whyByPage(parseInt(pageCount), searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> WHY Warehouse </title>{" "}
      </Helmet>
      {data.whyResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Why Warehouse Deleted`} />
      ) : null}
      <div className="row align-items-center py-1 px-3 mx-0">
        <div className="col-6 px-0">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>
            Why Warehouse{" "}
          </h5>
        </div>{" "}
        <div className="col-6 px-0">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
          />{" "}
        </div>{" "}
        <div className="col-12 px-0">
          {data.isLoading ? (
            <CardLoader />
          ) : (
            <>
              <div className="col-12 text-nowrap table-responsive table-gray-admin bg-white p-3">
                {" "}
                {data.whyList &&
                data.whyList.data &&
                data.whyList.data.length > 0 ? (
                  <table className="table table-dark-custom">
                    <thead>
                      <tr>
                        <th className="text-center"> S.NO </th> <th> Title </th>{" "}
                        <th> description </th> <th> image </th>{" "}
                        <th className="text-center"> Action </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {" "}
                      {data.whyList.data.map((item, index) => {
                        return (
                          <WhyList
                            item={item}
                            index={index + 1}
                            key={index}
                            read={read}
                          />
                        );
                      })}{" "}
                    </tbody>{" "}
                  </table>
                ) : (
                  <ItemNotFoud message="Data Not Found" />
                )}
              </div>

              {data.whyList?.totalCount > 10 && (
                <div className="pagination-custom">
                  <Pagination
                    activePage={parseInt(pageCount)}
                    itemsCountPerPage={10}
                    totalItemsCount={data.whyList?.totalCount}
                    pageRangeDisplayed={1}
                    onChange={handlePageChange}
                    prevPageText={<i className="fas fa-chevron-left" />}
                    nextPageText={<i className="fas fa-chevron-right" />}
                    hideFirstLastPages={true}
                  />{" "}
                </div>
              )}
            </>
          )}
          <div className="row my-2">
            <div className="col-12 text-right">
              <Link
                to="/add-why"
                className={`btn py-2 btn-deep-blue ${!read ? "" : "d-none"}`}
              >
                {" "}
                Add{" "}
              </Link>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default WhyWarehouse;
