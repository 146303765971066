import React from "react";

const TypeWarehouse = ({ data, title, index, key, imgIcon, dashboardIcon }) => {
  return (
    <div
      key={key}
      className="col-xl-3 col-lg-4 col-sm-6 mb-4 d-flex  new-card-box"
    >
      <div className="dashboard-card d-flex cardHover w-100 py-2 h-100">
        <div className="d-flex align-items-center justify-content-center px-3 my-auto">
          <div className="icons-box">
            <img
              src={
                dashboardIcon
                  ? dashboardIcon.imgIcon
                  : "/dashboard-icons/warehouse2.png"
              }
              alt={`dashoard${index}`}
              className="p-2"
              style={{
                maxWidth: 65,
                height: 65,
              }}
            />{" "}
          </div>{" "}
          <div className="card-body py-xxl-5 py-2 px-2">
            <h4 className="h5 mb-0"> {data.count} </h4>{" "}
            <p
              className="font-heading"
              style={{
                fontSize: "14px",
                lineHeight: "17px",
                fontWeight: "normal",
              }}
            >
              {data?.category}{" "}
            </p>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default TypeWarehouse;
