import {
    WHYWHS,
    WHY_RESPONSE,
    WHS_TYPE,
    WHYWHS_BY_ID,
    WHS_TYPE_ID,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
    ERROR_MESSAGE
} from './../types';


const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    isErrorMsg: "",
    whyList: null,
    whyDetail: null,
    typeList: null,
    typeDetail: null,
    whyResponse: null
}

export function WHY_INFO(state = initialState, action) {

    switch (action.type) {

        case WHYWHS:
            return {
                ...state,
                whyList: action.payload,
            };

        case WHS_TYPE:
            return {
                ...state,
                typeList: action.payload,
            };

        case WHYWHS_BY_ID:
            return {
                ...state,
                whyDetail: action.payload,
            };

        case WHS_TYPE_ID:
            return {
                ...state,
                typeDetail: action.payload,
            };

        case WHY_RESPONSE:
            return {
                ...state,
                whyResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:
            return {
                ...state,
                isPending: action.isPending,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        case ERROR_MESSAGE:
            return {
                ...state,
                isErrorMsg: action.payload,
            };

        default:
            return state;
    }
}