import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { serviceCategoryById } from "../../store/action/serviceAction";
import { CardLoader } from "../../components/helper/CustomLoader";

const ViewServiceCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { serviceCategoryId } = useParams();
  const data = useSelector((state) => state.SERVICE_INFO);

  useEffect(() => {
    dispatch(serviceCategoryById(parseInt(serviceCategoryId)));
  }, [dispatch, serviceCategoryId]);

  return (
    <LayoutOne>
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 my-2">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left cursorPointer"
            >
              {" "}
            </i>{" "}
            View Service Category Details
          </h5>
        </div>{" "}
        <div className="col-12">
          {data.isLoading ? (
            <CardLoader />
          ) : (
            <div className="row mx-0">
              <div className="col-12 bg-white rounded">
                {" "}
                {data.categoryDetail ? (
                  <div className="card card-body border-0">
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Category Name </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.categoryDetail?.name}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Category ID </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.categoryDetail?.id}{" "}
                      </div>{" "}
                    </div>
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Active date </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        12-01-2021{" "}
                      </div>{" "}
                    </div>
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Status </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.categoryDetail?.active
                          ? "Active"
                          : "In Active"}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Description </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.categoryDetail?.description}{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                ) : null}
              </div>{" "}
            </div>
          )}
          <div className="row">
            <div className="col-12 my-2">
              <button
                onClick={() => history.goBack()}
                className="btn py-2 btn-outline-deep-blue"
              >
                {" "}
                Back{" "}
              </button>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default ViewServiceCategory;
