import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Link, useParams, useHistory } from "react-router-dom";
import { departmentByPage } from "../../store/action/whsUserAction";
import { useDispatch, useSelector } from "react-redux";
import { subUserByDepartmentId } from "../../store/action/whsUserAction";
import {
  onBoardById,
  assignOnBoard,
  responseOnboard,
} from "../../store/action/onBoardAction";
import FormSuccess from "../../components/common/FormSuccess";
import { useToasts } from "react-toast-notifications";

const CustomerManageUpdate = () => {
  const { onBoardId } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const depData = useSelector((state) => state.WHS_USER_INFO.departmentList);
  const data = useSelector((state) => state.WHS_USER_INFO);
  const onData = useSelector((state) => state.ON_BOARD_INFO);

  useEffect(() => {
    dispatch(departmentByPage("true"));
  }, [dispatch]);

  // Fetch Onboading by Id
  useEffect(() => {
    dispatch(onBoardById(onBoardId));
  }, [dispatch, onBoardId]);

  // call User by department
  const callDepartment = (id) => {
    // dispatch(whsByPage())
    dispatch(subUserByDepartmentId(parseInt(id)));
  };

  function isPresent(present) {
    return present ? parseInt(present) : "";
  }

  function isAssign(assign) {
    return assign ? assign : true;
  }

  // ##############################################################
  // #############   Contact Forms  ###############################
  // ##############################################################

  const [contactError, setContactError] = useState(null);

  const [contactForm, setContactForm] = useState({
    city: "",
    area: "",
    department: isPresent(onData.onBoardDetail?.contactDetail?.department?.id),
    assign: isAssign(onData.onBoardDetail?.contactDetail?.assign),
    assignTo: isPresent(onData.onBoardDetail?.contactDetail?.assignTo?.id),
  });

  const contactHandle = (e) => {
    console.log("e-->", e.target.value);
    setContactError(null);
    if (e.target.name === "department") {
      callDepartment(e.target.value);
      console.log("name->", e.target.name);
      // contactForm.department = parseInt(e.target.value)
      setContactForm({ ...contactForm, [e.target.name]: e.target.value });
    } else if (e.target.name === "assignTo") {
      setContactForm({
        ...contactForm,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (e.target.name === "assign") {
      console.log("assignnnn--->", e.target.value);
      setCSForm({ ...contactForm, [e.target.name]: e.target.value === "true" });
    } else {
      setContactForm({ ...contactForm, [e.target.name]: e.target.value });
    }
  };

  const contactSubmit = () => {
    // if (contactForm.city === "") {
    //   setContactError("City Required")
    //   return 0;
    // } else if (contactForm.area === "") {
    //   setContactError("Area Required")
    //   return 0;
    // } else
    if (contactForm.department === "") {
      setContactError("Department Required");
      return 0;
    } else if (contactForm.assignTo === "") {
      setContactError("AssignTo Required");
      return 0;
    } else if (contactForm.assign === "") {
      setContactError("Assign Required");
      return 0;
    }

    let submitData = {
      customeronboardingId: parseInt(onBoardId),
      contactDetail: contactForm,
      formType: "contactDetail",
    };
    dispatch(assignOnBoard(submitData, addToast));
  };

  // ##############################################################
  // #############   Cost Sheet Forms  ###############################
  // ##############################################################

  const [costError, setCostError] = useState(null);

  const [costForm, setCostForm] = useState({
    city: "",
    area: "",
    department: isPresent(onData.onBoardDetail?.costSheet?.department?.id),
    assign: isAssign(onData.onBoardDetail?.costSheet?.assign),
    assignTo: isPresent(onData.onBoardDetail?.costSheet?.assignTo?.id),
  });

  const costHandle = (e) => {
    setCostError(null);
    if (e.target.name === "department") {
      callDepartment(parseInt(e.target.value));
      setCostForm({ ...costForm, [e.target.name]: parseInt(e.target.value) });
    } else if (e.target.name === "assignTo") {
      setCostForm({ ...costForm, [e.target.name]: parseInt(e.target.value) });
    } else if (e.target.name === "assign") {
      setCostForm({ ...costForm, [e.target.name]: e.target.value === "true" });
    } else {
      setCostForm({ ...costForm, [e.target.name]: e.target.value });
    }
  };

  const costSubmit = () => {
    // if (costForm.city === "") {
    //   setCostError("City Required")
    //   return 0;
    // } else if (costForm.area === "") {
    //   setCostError("Area Required")
    //   return 0;
    // } else
    // if (costForm.department === "" || costForm.department === null) {
    //   setCostError("Department Required")
    //   return 0;
    // } else if (costForm.assignTo === "") {
    //   setCostError("AssignTo Required")
    //   return 0;
    // } else if (costForm.assign === "") {
    //   setCostError("Assign Required")
    //   return 0;
    // }

    console.log("costForm-->", costForm);

    let submitData = {
      customeronboardingId: parseInt(onBoardId),
      costSheet: costForm,
      formType: "costSheet",
    };

    dispatch(assignOnBoard(submitData, addToast));
  };

  // ##############################################################
  // #############   Billing Support Forms  ###############################
  // ##############################################################

  const [billingError, setBillingError] = useState(null);

  const [billingForm, setbillingForm] = useState({
    city: "",
    area: "",
    department: isPresent(onData.onBoardDetail?.billingSupport?.department?.id),
    assign: isAssign(onData.onBoardDetail?.billingSupport?.assign),
    assignTo: isPresent(onData.onBoardDetail?.billingSupport?.assignTo?.id),
  });

  const billingHandle = (e) => {
    console.log("e-->", e.target.value);
    setBillingError(null);
    if (e.target.name === "department") {
      callDepartment(e.target.value);
      setbillingForm({ ...billingForm, [e.target.name]: e.target.value });
    } else if (e.target.name === "assignTo") {
      setbillingForm({
        ...billingForm,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (e.target.name === "assign") {
      setbillingForm({
        ...billingForm,
        [e.target.name]: e.target.value === "true",
      });
    } else {
      setbillingForm({ ...billingForm, [e.target.name]: e.target.value });
    }
  };

  const billingSubmit = () => {
    // if (billingForm.city === "") {
    //   setBillingError("City Required")
    //   return 0;
    // } else if (billingForm.area === "") {
    //   setBillingError("Area Required")
    //   return 0;
    // } else
    if (billingForm.department === "") {
      setBillingError("Department Required");
      return 0;
    } else if (billingForm.assignTo === "") {
      setBillingError("AssignTo Required");
      return 0;
    } else if (billingForm.assign === "") {
      setBillingError("Assign Required");
      return 0;
    }

    let submitData = {
      customeronboardingId: parseInt(onBoardId),
      billingSupport: billingForm,
      formType: "billingSupport",
    };
    dispatch(assignOnBoard(submitData, addToast));
  };

  // ##############################################################
  // #############   Transition Operation Forms  ###############################
  // ##############################################################

  const [transitionError, setTransitionError] = useState(null);

  const [transitionForm, settransitionForm] = useState({
    city: "",
    area: "",
    department: isPresent(
      onData.onBoardDetail?.transitionOperation?.department?.id
    ),
    assign: isAssign(onData.onBoardDetail?.transitionOperation?.assign),
    assignTo: isPresent(
      onData.onBoardDetail?.transitionOperation?.assignTo?.id
    ),
  });

  const transitionHandle = (e) => {
    console.log("e-->", e.target.value);
    setTransitionError(null);
    if (e.target.name === "department") {
      callDepartment(e.target.value);
      settransitionForm({ ...transitionForm, [e.target.name]: e.target.value });
    } else if (e.target.name === "assignTo") {
      settransitionForm({
        ...transitionForm,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (e.target.name === "assign") {
      settransitionForm({
        ...transitionForm,
        [e.target.name]: e.target.value === "true",
      });
    } else {
      settransitionForm({ ...transitionForm, [e.target.name]: e.target.value });
    }
  };

  const transitionSubmit = () => {
    // if (transitionForm.city === "") {
    //   setTransitionError("City Required")
    //   return 0;
    // } else if (transitionForm.area === "") {
    //   setTransitionError("Area Required")
    //   return 0;
    // } else
    if (transitionForm.department === "") {
      setTransitionError("Department Required");
      return 0;
    } else if (transitionForm.assignTo === "") {
      setTransitionError("AssignTo Required");
      return 0;
    } else if (transitionForm.assign === "") {
      setTransitionError("Assign Required");
      return 0;
    }

    let submitData = {
      customeronboardingId: parseInt(onBoardId),
      transitionOperation: transitionForm,
      formType: "transitionOperation",
    };
    dispatch(assignOnBoard(submitData, addToast));
  };

  // ##############################################################
  // #############   Transition CS Forms  ###############################
  // ##############################################################

  const [csError, setCSError] = useState(null);

  const [csForm, setCSForm] = useState({
    city: "",
    area: "",
    department: isPresent(onData.onBoardDetail?.transitionCS?.department?.id),
    assign: isAssign(onData.onBoardDetail?.transitionCS?.assign),
    assignTo: isPresent(onData.onBoardDetail?.transitionCS?.assignTo?.id),
  });

  const csHandle = (e) => {
    setCSError(null);
    if (e.target.name === "department") {
      callDepartment(e.target.value);
      setCSForm({ ...csForm, [e.target.name]: e.target.value });
    } else if (e.target.name === "assignTo") {
      setCSForm({ ...csForm, [e.target.name]: parseInt(e.target.value) });
    } else if (e.target.name === "assign") {
      setCSForm({ ...csForm, [e.target.name]: e.target.value === "true" });
    } else {
      setCSForm({ ...csForm, [e.target.name]: e.target.value });
    }
  };

  const csSubmit = () => {
    // if (csForm.city === "") {
    //   setCSError("City Required")
    //   return 0;
    // } else if (csForm.area === "") {
    //   setCSError("Area Required")
    //   return 0;
    // } else
    if (csForm.department === "") {
      setCSError("Department Required");
      return 0;
    } else if (csForm.assignTo === "") {
      setCSError("AssignTo Required");
      return 0;
    } else if (csForm.assign === "") {
      setCSError("Assign Required");
      return 0;
    }

    let submitData = {
      customeronboardingId: parseInt(onBoardId),
      transitionCS: csForm,
      formType: "transitionCS",
    };
    dispatch(assignOnBoard(submitData, addToast));
  };

  // ##############################################################
  // #############   Special Instruction Forms  ###############################
  // ##############################################################

  const [specialError, setspecialError] = useState(null);

  const [specialForm, setspecialForm] = useState({
    city: "",
    area: "",
    department: isPresent(
      onData.onBoardDetail?.specialInstruction?.department?.id
    ),
    assign: isAssign(onData.onBoardDetail?.specialInstruction?.assign),
    assignTo: isPresent(onData.onBoardDetail?.specialInstruction?.assignTo?.id),
  });

  const specialHandle = (e) => {
    console.log("e-->", e.target.value);
    setspecialError(null);
    if (e.target.name === "department") {
      callDepartment(e.target.value);
      setspecialForm({ ...specialForm, [e.target.name]: e.target.value });
    } else if (e.target.name === "assignTo") {
      setspecialForm({
        ...specialForm,
        [e.target.name]: parseInt(e.target.value),
      });
    } else if (e.target.name === "assign") {
      setspecialForm({
        ...specialForm,
        [e.target.name]: e.target.value === "true",
      });
    } else {
      setspecialForm({ ...specialForm, [e.target.name]: e.target.value });
    }
  };

  const specialSubmit = () => {
    // if (specialForm.city === "") {
    //   setspecialError("City Required")
    //   return 0;
    // } else if (specialForm.area === "") {
    //   setspecialError("Area Required")
    //   return 0;
    // } else
    if (specialForm.department === "") {
      setspecialError("Department Required");
      return 0;
    } else if (specialForm.assignTo === "") {
      setspecialError("AssignTo Required");
      return 0;
    } else if (specialForm.assign === "") {
      setspecialError("Assign Required");
      return 0;
    }

    let submitData = {
      customeronboardingId: parseInt(onBoardId),
      specialInstruction: specialForm,
      formType: "specialInstruction",
    };
    dispatch(assignOnBoard(submitData, addToast));
  };

  // Set State form

  useEffect(() => {
    // if (onData.onBoardDetail?.contactDetail) {
    //   setContactForm({
    //     "city": onData.onBoardDetail?.contactDetail?.city,
    //     "area": onData.onBoardDetail?.contactDetail?.area,
    //     "department": onData.onBoardDetail?.contactDetail?.department.id,
    //     "assign": onData.onBoardDetail?.contactDetail?.assign,
    //     "assignTo": onData.onBoardDetail?.contactDetail?.assignTo?.id,
    //   })
    // }
    // if (onData.onBoardDetail?.costSheet) {
    //   setCostForm({
    //     "city": onData.onBoardDetail?.costSheet?.city,
    //     "area": onData.onBoardDetail?.costSheet?.area,
    //     "department": onData.onBoardDetail?.costSheet?.department,
    //     "assign": onData.onBoardDetail?.costSheet?.assign,
    //     "assignTo": onData.onBoardDetail?.costSheet?.assignTo?.id,
    //   })
    // }
    // if (onData.onBoardDetail?.billingSupport) {
    //   setbillingForm({
    //     "city": onData.onBoardDetail?.billingSupport?.city,
    //     "area": onData.onBoardDetail?.billingSupport?.area,
    //     "department": onData.onBoardDetail?.billingSupport?.department,
    //     "assign": onData.onBoardDetail?.billingSupport?.assign,
    //     "assignTo": onData.onBoardDetail?.billingSupport?.assignTo?.id,
    //   })
    // }
    // if (onData.onBoardDetail?.transitionOperation) {
    //   settransitionForm({
    //     "city": onData.onBoardDetail?.transitionOperation?.city,
    //     "area": onData.onBoardDetail?.transitionOperation?.area,
    //     "department": onData.onBoardDetail?.transitionOperation?.department,
    //     "assign": onData.onBoardDetail?.transitionOperation?.assign,
    //     "assignTo": onData.onBoardDetail?.transitionOperation?.assignTo?.id,
    //   })
    // }
    // if (onData.onBoardDetail?.transitionCS) {
    //   setCSForm({
    //     "city": onData.onBoardDetail?.transitionCS?.city,
    //     "area": onData.onBoardDetail?.transitionCS?.area,
    //     "department": onData.onBoardDetail?.transitionCS?.department,
    //     "assign": onData.onBoardDetail?.transitionCS?.assign,
    //     "assignTo": onData.onBoardDetail?.transitionCS?.assignTo?.id,
    //   })
    // }
    // if (onData.onBoardDetail?.specialInstruction) {
    //   setspecialForm({
    //     "city": onData.onBoardDetail?.specialInstruction?.city,
    //     "area": onData.onBoardDetail?.specialInstruction?.area,
    //     "department": onData.onBoardDetail?.specialInstruction?.department,
    //     "assign": onData.onBoardDetail?.specialInstruction?.assign,
    //     "assignTo": onData.onBoardDetail?.specialInstruction?.assignTo?.id,
    //   })
    // }
  }, [onData]);

  const redirect = () => {
    dispatch(responseOnboard(null));
    history.replace(`/customer-manage-update/${onData?.onBoardDetail?.id}`);
  };

  return (
    <LayoutOne>
      {onData.onResponse && onData.onResponse.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={onData.onResponse.message} />
      ) : null}

      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 px-0">
          <h5 className="text-dark"> Manage Customer </h5>{" "}
        </div>{" "}
        <div className="col-12 px-0">
          <div className="row align-items-center py-3 px-3 mx-0">
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-12 px-0 table-responsive table-gray-admin">
                  <table className="table">
                    <thead>
                      <tr>
                        <th> </th>{" "}
                        {/* <th>State</th>
                                            <th>District</th> */}{" "}
                        <th> Department </th> <th> Assign to </th>{" "}
                        <th> Assign </th> <th> Action </th>{" "}
                        <th className="text-center"> View Form </th>{" "}
                      </tr>{" "}
                    </thead>{" "}
                    <tbody>
                      {/*##################### Contact Details ###################3 */}

                      <tr>
                        <td className="font-weight-bold text-left">
                          {" "}
                          Contact Details{" "}
                        </td>{" "}
                        {/* <td>
                                            <select name="city" value={contactForm.city} onChange={contactHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                              <option value="">Select City</option>
                                              {options && options.length>0?options.map((value,index)=>{
                                                return <option value={value.label} >{value.label}</option>
                                                }):null}
                                            </select>
                                            <span className="errorMsg">{contactError === "City Required" ? "Required" : null}</span>
                                          </td> 
                                          <td>
                                            <select  name="area" value={contactForm.area}  onChange={contactHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                               <option value="">Select Area</option>
                                              <option value={`Delhi`}>{allData.data?.contactDetail?.city}</option>
                                              <option value={`Noida`}>{`Noida`}</option>
                                            </select>
                                            <span className="errorMsg">{contactError === "Area Required" ? "Required" : null}</span>
                                          </td> */}{" "}
                        <td>
                          <select
                            name="department"
                            value={contactForm?.department}
                            onChange={contactHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select Department </option>{" "}
                            {depData && depData.length > 0
                              ? depData.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {" "}
                                      {value.name}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {contactError === "Department Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            value={contactForm.assignTo}
                            name="assignTo"
                            onChange={contactHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            {data.whsUserList?.data &&
                            data.whsUserList?.data.length > 0
                              ? data.whsUserList?.data.map((item, index) => {
                                  return (
                                    <option key={index} value={item?.id}>
                                      {" "}
                                      {item?.firstName}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {contactError === "AssignTo Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assign"
                            value={contactForm.assign}
                            onChange={contactHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            <option value={true}> Assign </option>{" "}
                            <option value={false}> Un-Assign </option>{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {contactError === "Assign Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <button
                            onClick={contactSubmit}
                            className="btn btn-deep-blue font-weight-bold py-1 px-3"
                          >
                            {" "}
                            Update{" "}
                          </button>{" "}
                        </td>{" "}
                        <td className="text-center text-nowrape d-flex justify-content-center">
                          {" "}
                          {/* {onData.onBoardDetail?.contactDetail ? */}{" "}
                          <Link
                            to={`/customer-contact-details/${onBoardId}?assignUserId=${onData.onBoardDetail?.contactDetail?.assignTo?.id}&admin=true&contactDetailOnboardId=${onBoardId}&adminSide=true`}
                            className="btn text-deep-blue font-weight-bold px-2"
                          >
                            {" "}
                            View Form{" "}
                          </Link>{" "}
                          {/* :
                                                         <span className="btn text-deep-blue font-weight-bold px-2">Pending</span>
                                                       } */}{" "}
                        </td>{" "}
                      </tr>

                      {/*##################### Cost Sheet ################### */}

                      <tr>
                        <td className="font-weight-bold text-left">
                          {" "}
                          Cost Sheet{" "}
                        </td>{" "}
                        {/* <td>
                                                      <select name="city" value={costForm.city} onChange={costHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                        <option value="">Select City</option>
                                                        {options && options.length > 0 ? options.map((value, index) => {
                                                          return <option value={value.label} >{value.label}</option>
                                                        }) : null}
                                                      </select>
                                                      <span className="errorMsg">{costError === "City Required" ? "Required" : null}</span>
                                                    </td>
                                                    <td>
                                                      <select name="area" value={costForm.area} onChange={costHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                        <option value="">Select Area</option>
                                                        <option value={`Delhi`}>{allData.data?.contactDetail?.city}</option>
                                                        <option value={`Noida`}>{`Noida`}</option>
                                                      </select>
                                                      <span className="errorMsg">{costError === "Area Required" ? "Required" : null}</span>
                                                    </td> */}
                        <td>
                          <select
                            name="department"
                            onChange={costHandle}
                            value={costForm.department}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select Department </option>{" "}
                            {depData && depData.length > 0
                              ? depData.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {" "}
                                      {value.name}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {costError === "Department Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assignTo"
                            value={costForm.assignTo}
                            onChange={costHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            {data.whsUserList?.data &&
                            data.whsUserList?.data.length > 0
                              ? data.whsUserList?.data.map((item, index) => {
                                  return (
                                    <option key={index} value={item?.id}>
                                      {" "}
                                      {item?.firstName}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {costError === "AssignTo Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assign"
                            value={costForm.assign}
                            onChange={costHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            <option value={true}> Assign </option>{" "}
                            <option value={false}> Un-Assign </option>{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {costError === "Assign Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <button
                            onClick={costSubmit}
                            className="btn btn-deep-blue font-weight-bold py-1 px-3"
                          >
                            {" "}
                            Update{" "}
                          </button>{" "}
                        </td>{" "}
                        <td className="text-center text-nowrape d-flex justify-content-center">
                          {/* {onData.onBoardDetail?.costSheet ? */}{" "}
                          <Link
                            to={`/customer-cost-sheet/${onBoardId}?assignUserId=${onData.onBoardDetail?.costSheet?.assignTo?.id}&contactDetailOnboardId=${onBoardId}&adminSide=true`}
                            className="btn text-deep-blue font-weight-bold px-2"
                          >
                            {" "}
                            View Form{" "}
                          </Link>{" "}
                          {/* :
                                                                <span className="btn text-deep-blue font-weight-bold px-2">Pending</span>
                                                              } */}
                        </td>{" "}
                      </tr>

                      {/*##################### Billing Support ################### */}

                      <tr>
                        <td className="font-weight-bold text-left">
                          {" "}
                          Billing Support{" "}
                        </td>
                        {/* <td>
                                                          <select name="city" value={billingForm.city} onChange={billingHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                            <option value="">Select City</option>
                                                            {options && options.length > 0 ? options.map((value, index) => {
                                                              return <option value={value.label} >{value.label}</option>
                                                            }) : null}
                                                          </select>
                                                          <span className="errorMsg">{billingError === "City Required" ? "Required" : null}</span>
                                                        </td>
                                                        <td>
                                                          <select name="area" value={billingForm.area} onChange={billingHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                            <option value="">Select Area</option>
                                                            <option value={`Delhi`}>{allData.data?.contactDetail?.city}</option>
                                                            <option value={`Noida`}>{`Noida`}</option>
                                                          </select>
                                                          <span className="errorMsg">{billingError === "Area Required" ? "Required" : null}</span>
                                                        </td> */}{" "}
                        <td>
                          <select
                            name="department"
                            value={
                              onData.onBoardDetail?.billingSupport?.department
                                ?.id
                            }
                            onChange={billingHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select Department </option>{" "}
                            {depData && depData.length > 0
                              ? depData.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {" "}
                                      {value.name}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {billingError === "Department Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assignTo"
                            value={billingForm.assignTo}
                            onChange={billingHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>

                            {data.whsUserList?.data &&
                            data.whsUserList?.data.length > 0
                              ? data.whsUserList?.data.map((item, index) => {
                                  return (
                                    <option key={index} value={item?.id}>
                                      {" "}
                                      {item?.firstName}{" "}
                                    </option>
                                  );
                                })
                              : null}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {billingError === "AssignTo Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assign"
                            value={billingForm.assign}
                            onChange={billingHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            <option value={true}> Assign </option>{" "}
                            <option value={false}> Un-Assign </option>{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {billingError === "Assign Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <button
                            onClick={billingSubmit}
                            className="btn btn-deep-blue font-weight-bold py-1 px-3"
                          >
                            {" "}
                            Update{" "}
                          </button>{" "}
                        </td>{" "}
                        <td className="text-center text-nowrape d-flex justify-content-center">
                          {" "}
                          {/* {onData.onBoardDetail?.billingSupport ? */}{" "}
                          <Link
                            to={`/customer-billing-support/${onBoardId}?assignUserId=${onData.onBoardDetail?.billingSupport?.assignTo?.id}&contactDetailOnboardId=${onBoardId}`}
                            className="btn text-deep-blue font-weight-bold px-2"
                          >
                            {" "}
                            View Form{" "}
                          </Link>{" "}
                          {/* :
                                                                    <span className="btn text-deep-blue font-weight-bold px-2">Pending</span>
                                                                 } */}
                        </td>{" "}
                      </tr>

                      {/*##################### Transition Operations ################### */}

                      <tr>
                        <td className="font-weight-bold text-left">
                          {" "}
                          Transition Operations{" "}
                        </td>
                        {/* <td>
                                                              <select name="city" value={transitionForm.city} onChange={transitionHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                                <option value="">Select City</option>
                                                                {options && options.length > 0 ? options.map((value, index) => {
                                                                  return <option value={value.label} >{value.label}</option>
                                                                }) : null}
                                                              </select>
                                                              <span className="errorMsg">{transitionError === "City Required" ? "Required" : null}</span>
                                                            </td>
                                                            <td>
                                                              <select name="area" value={transitionForm.area} onChange={transitionHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                                <option value="">Select Area</option>
                                                                <option value={`Delhi`}>{allData.data?.contactDetail?.city}</option>
                                                                <option value={`Noida`}>{`Noida`}</option>
                                                              </select>
                                                              <span className="errorMsg">{transitionError === "Area Required" ? "Required" : null}</span>
                                                            </td> */}{" "}
                        <td>
                          <select
                            name="department"
                            value={
                              onData.onBoardDetail?.transitionOperation
                                ?.department?.id
                            }
                            onChange={transitionHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select Department </option>{" "}
                            {depData && depData.length > 0
                              ? depData.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {" "}
                                      {value.name}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {transitionError === "Department Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assignTo"
                            value={transitionForm.assignTo}
                            onChange={transitionHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>

                            {data.whsUserList?.data &&
                            data.whsUserList?.data.length > 0
                              ? data.whsUserList?.data.map((item, index) => {
                                  return (
                                    <option key={index} value={item?.id}>
                                      {" "}
                                      {item?.firstName}{" "}
                                    </option>
                                  );
                                })
                              : null}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {transitionError === "AssignTo Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assign"
                            value={transitionForm.assign}
                            onChange={transitionHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            <option value={true}> Assign </option>{" "}
                            <option value={false}> Un-Assign </option>{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {transitionError === "Assign Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <button
                            onClick={transitionSubmit}
                            className="btn btn-deep-blue font-weight-bold py-1 px-3"
                          >
                            {" "}
                            Update{" "}
                          </button>{" "}
                        </td>{" "}
                        <td className="text-center text-nowrape d-flex justify-content-center">
                          {/* {onData.onBoardDetail?.transitionOperation ? */}{" "}
                          <Link
                            to={`/customer-transition-operation/${onBoardId}?assignUserId=${onData.onBoardDetail?.transitionOperation?.assignTo?.id}&contactDetailOnboardId=${onBoardId}`}
                            className="btn text-deep-blue font-weight-bold px-2"
                          >
                            {" "}
                            View Form{" "}
                          </Link>{" "}
                          {/* :
                                                                        <span className="btn text-deep-blue font-weight-bold px-2">Pending</span>
                                                                      } */}
                        </td>{" "}
                      </tr>

                      {/*##################### Transition CS ################### */}

                      <tr>
                        <td className="font-weight-bold text-left">
                          {" "}
                          Transition CS{" "}
                        </td>
                        {/* <td>
                                                                  <select name="city" value={csForm.city} onChange={csHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                                    <option value="">Select City</option>
                                                                    {options && options.length > 0 ? options.map((value, index) => {
                                                                      return <option value={value.label} >{value.label}</option>
                                                                    }) : null}
                                                                  </select>
                                                                  <span className="errorMsg">{csError === "City Required" ? "Required" : null}</span>
                                                                </td>
                                                                <td>
                                                                  <select name="area" value={csForm.area} onChange={csHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                                    <option value="">Select Area</option>
                                                                    <option value={`Delhi`}>{allData.data?.contactDetail?.city}</option>
                                                                    <option value={`Noida`}>{`Noida`}</option>
                                                                  </select>
                                                                  <span className="errorMsg">{csError === "Area Required" ? "Required" : null}</span>
                                                                </td> */}{" "}
                        <td>
                          <select
                            name="department"
                            value={
                              onData.onBoardDetail?.transitionCS?.department?.id
                            }
                            onChange={csHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select Department </option>{" "}
                            {depData && depData.length > 0
                              ? depData.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {" "}
                                      {value.name}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {csError === "Department Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assignTo"
                            value={csForm.assignTo}
                            onChange={csHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>

                            {data.whsUserList?.data &&
                            data.whsUserList?.data.length > 0
                              ? data.whsUserList?.data.map((item, index) => {
                                  return (
                                    <option key={index} value={item?.id}>
                                      {" "}
                                      {item?.firstName}{" "}
                                    </option>
                                  );
                                })
                              : null}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {csError === "AssignTo Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assign"
                            value={csForm.assign}
                            onChange={csHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            <option value={true}> Assign </option>{" "}
                            <option value={false}> Un-Assign </option>{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {csError === "Assign Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <button
                            onClick={csSubmit}
                            className="btn btn-deep-blue font-weight-bold py-1 px-3"
                          >
                            {" "}
                            Update{" "}
                          </button>{" "}
                        </td>{" "}
                        <td className="text-center text-nowrape d-flex justify-content-center">
                          {/* {onData.onBoardDetail?.transitionCS ? */}{" "}
                          <Link
                            to={`/customer-transition/${onBoardId}?assignUserId=${onData.onBoardDetail?.transitionCS?.assignTo?.id}&contactDetailOnboardId=${onBoardId}`}
                            className="btn text-deep-blue font-weight-bold px-2"
                          >
                            {" "}
                            View Form{" "}
                          </Link>{" "}
                          {/* //   :
                                                                          //   <span className="btn text-deep-blue font-weight-bold px-2">Pending</span>
                                                                          // } */}
                        </td>{" "}
                      </tr>

                      {/*##################### Special Instruction ################### */}

                      <tr>
                        <td className="font-weight-bold text-left">
                          {" "}
                          Special Instruction{" "}
                        </td>
                        {/* <td>
                                                                      <select name="city" value={specialForm.city} onChange={specialHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                                        <option value="">Select City</option>
                                                                        {options && options.length > 0 ? options.map((value, index) => {
                                                                          return <option value={value.label} >{value.label}</option>
                                                                        }) : null}
                                                                      </select>
                                                                      <span className="errorMsg">{specialError === "City Required" ? "Required" : null}</span>
                                                                    </td>
                                                                    <td>
                                                                      <select name="area" value={specialForm.area} onChange={specialHandle} className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px">
                                                                        <option value="">Select Area</option>
                                                                        <option value={`Delhi`}>{allData.data?.contactDetail?.city}</option>
                                                                        <option value={`Noida`}>{`Noida`}</option>
                                                                      </select>
                                                                      <span className="errorMsg">{specialError === "Area Required" ? "Required" : null}</span>
                                                                    </td> */}{" "}
                        <td>
                          <select
                            name="department"
                            value={
                              onData.onBoardDetail?.specialInstruction
                                ?.department?.id
                            }
                            onChange={specialHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select Department </option>{" "}
                            {depData && depData.length > 0
                              ? depData.map((value, index) => {
                                  return (
                                    <option key={index} value={value.id}>
                                      {" "}
                                      {value.name}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {specialError === "Department Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assignTo"
                            value={specialForm.assignTo}
                            onChange={specialHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            {data.whsUserList?.data &&
                            data.whsUserList?.data.length > 0
                              ? data.whsUserList?.data.map((item, index) => {
                                  return (
                                    <option key={index} value={item?.id}>
                                      {" "}
                                      {item?.firstName}{" "}
                                    </option>
                                  );
                                })
                              : null}{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {specialError === "AssignTo Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <select
                            name="assign"
                            value={specialForm.assign}
                            onChange={specialHandle}
                            className="form-control custom-select bg-white common-select-deep-blue border-0 w-100px"
                          >
                            <option value=""> Select </option>{" "}
                            <option value={true}> Assign </option>{" "}
                            <option value={false}> Un-Assign </option>{" "}
                          </select>{" "}
                          <span className="errorMsg">
                            {" "}
                            {specialError === "Assign Required"
                              ? "Required"
                              : null}{" "}
                          </span>{" "}
                        </td>{" "}
                        <td>
                          <button
                            onClick={specialSubmit}
                            className="btn btn-deep-blue font-weight-bold py-1 px-3"
                          >
                            {" "}
                            Update{" "}
                          </button>{" "}
                        </td>{" "}
                        <td className="text-center text-nowrape d-flex justify-content-center">
                          {" "}
                          {/* {onData.onBoardDetail?.specialInstruction ? */}{" "}
                          <Link
                            to={`/customer-special-instruction/${onBoardId}?assignUserId=${onData.onBoardDetail?.specialInstruction?.assignTo?.id}&contactDetailOnboardId=${onBoardId}`}
                            className="btn text-deep-blue font-weight-bold px-2"
                          >
                            {" "}
                            View Form{" "}
                          </Link>{" "}
                          {/* :
                                                                                  <button className="btn text-deep-blue font-weight-bold px-2">Pending </button>
                                                                                  } */}
                        </td>{" "}
                      </tr>
                    </tbody>{" "}
                  </table>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div className="row">
                                                                            <div className="col-12">
                                                                              <nav aria-label="...">
                                                                                <ul className="pagination justify-content-center my-5">
                                                                                  <li className="page-item mx-2">
                                                                                    <a className="page-link pagination-deep-blue rounded" href="#"><i className="fas fa-chevron-left"></i></a>
                                                                                  </li> 
                                                                                  
                                                                                  <li className="page-item mx-2">
                                                                                    <a className="page-link pagination-deep-blue rounded" href="#"><i className="fas fa-chevron-right"></i></a>
                                                                                  </li>
                                                                                </ul>
                                                                              </nav>
                                                                            </div>
                                                                          </div> */}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default CustomerManageUpdate;
