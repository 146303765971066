import {
    DOCUMENT,
    DOCUMENT_BY_ID,
    DOCUMENT_RESPONSE,
    IS_ERROR,
    IS_LOADING,
} from './../types';

import {
    GET_DOCUMENT_URL,
} from '../../services/urls';



import axiosauth from '../../services/axios-auth';


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}


export function documentList(data) {
    return {
        type: DOCUMENT,
        payload: data
    }
}

export function document_By_Id(data) {
    return {
        type: DOCUMENT_BY_ID,
        payload: data
    }
}


export function responseDocument(data) {
    return {
        type: DOCUMENT_RESPONSE,
        payload: data
    }
}

// ###########  Fecth All categoryList ########

export const documentByPage = (page, search) => {
    return async (dispatch) => {
        let limit = 10;
        // dispatch(documentList(docList))        
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_DOCUMENT_URL + `?page=${page}&limit=${limit}&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(documentList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("Document Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }

}


// ###########  Fecth  Document Detail By Id ########

export const documentById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_DOCUMENT_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)

                if (res.statusCode === 200) {

                    dispatch(document_By_Id(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("category Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  Fecth Booking ########

export const customerSpaceCertificate = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(`/api/v1/booking/spacecertificate/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(document_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("Space cetificate Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}