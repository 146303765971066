import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import {
  createServiceSubCategory,
  serviceCategoryByPage,
  responseService,
} from "../../store/action/serviceAction";
import { onlyAlphaNumericSpaceAllow } from "../../components/helper/reuse";
import { Helmet } from "react-helmet";

const AddServiceSubCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.SERVICE_INFO);

  const roleSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    categoryId: Yup.number().required("Required"),
  });

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service-subcategory`);
  };

  useEffect(() => {
    dispatch(serviceCategoryByPage());
  }, [dispatch]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Add Service SubCategory </title>{" "}
      </Helmet>{" "}
      {data.serviceResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Sub Category Created`} />
      ) : null}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            ></i>{" "}
            Add New Sub Category{" "}
          </h5>{" "}
        </div>
        <div className="col-12 bg-white p-3">
          <Formik
            enableReinitialize={true}
            validationSchema={roleSchema}
            initialValues={{
              name: "",
              categoryId: "",
              features: [
                {
                  name: "",
                },
              ],
            }}
            onSubmit={(fields) => {
              console.log("Fields==>", fields);
              fields.serviceFeature = JSON.stringify(fields.features);
              // dispatch(responseService({isPending: true}));
              dispatch(createServiceSubCategory(fields));
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="form-group col-12 mb-1">
                      <label htmlFor="staticEmail" className="mb-2 px-3">
                        Select Category Name{" "}
                      </label>{" "}
                      <Field
                        name="categoryId"
                        as="select"
                        className={
                          `form-control form-control-md common-select-deep-blue rounded-pill` +
                          (errors.categoryId && touched.categoryId
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) => {
                          setFieldValue("categoryId", parseInt(e.target.value));
                        }}
                      >
                        <option value=""> Select </option>{" "}
                        {data.categoryList && data.categoryList.length > 0
                          ? data.categoryList.map((item, index) => (
                              <option value={item?.id}> {item?.name} </option>
                            ))
                          : null}{" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`categoryId`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group col-12 mb-4">
                      <label htmlFor="staticEmail" className="mb-2 px-3">
                        Sub Category Name{" "}
                      </label>{" "}
                      <Field
                        onKeyPress={(e) => onlyAlphaNumericSpaceAllow(e)}
                        name="name"
                        type="text"
                        className={
                          `form-control form-control-md rounded-pill` +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`name`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group col-12 mb-4">
                      <label htmlFor="features" className="mb-2 px-3">
                        Features{" "}
                      </label>{" "}
                      <FieldArray name="features">
                        {" "}
                        {(fieldArrayProps) => {
                          const { push, remove, form } = fieldArrayProps;
                          const { values } = form;
                          const { features } = values;
                          return (
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <table className="table border-0">
                                <thead>
                                  <tr>
                                    <th> S.No </th> <th> </th>
                                    <th className="py-0">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <label
                                          className="btn btn-remove py-0"
                                          onClick={() => push("")}
                                        >
                                          Add More +
                                        </label>{" "}
                                      </div>{" "}
                                    </th>{" "}
                                  </tr>
                                  {features &&
                                    features.length > 0 &&
                                    features.map((priceField, index) => (
                                      <tr key={index}>
                                        <td className="mt-2"> {index + 1} </td>{" "}
                                        <td key={index} colSpan={2}>
                                          <Field
                                            name={`features.${index}.name`}
                                            className="form-control mr-4"
                                            placeholder="Name"
                                          />
                                        </td>{" "}
                                        {/* <td key={`priceindex${index}`}>
                                                                                          <Field
                                                                                            name={`features[${index}][value]`}
                                                                                            className="form-control"
                                                                                            placeholder="Value"

                                                                                          />
                                                                                         
                                                                                        </td> */}
                                        <td key={`deleteicon${index}`}>
                                          {" "}
                                          {/* <i className="fas fa-trash" style={{ color: "tomato", cursor: 'pointer' }} onClick={() => remove(index)}></i> */}{" "}
                                          <i
                                            className="fas fa-trash mt-2"
                                            style={{
                                              color: "tomato",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              remove(index);
                                            }}
                                          ></i>{" "}
                                        </td>{" "}
                                      </tr>
                                    ))}{" "}
                                </thead>{" "}
                              </table>{" "}
                            </div>
                          );
                        }}{" "}
                      </FieldArray>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row justify-content-end border-top pt-2">
                    <div className="col-auto">
                      <button
                        type="submit"
                        disabled={data.isPending}
                        className={`btn btn-deep-blue py-2 ${
                          !read ? "" : "d-none"
                        }`}
                      >
                        Add{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                        {console.log("dataispending", data.isPending)}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default AddServiceSubCategory;
