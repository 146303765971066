import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Link, useHistory, useParams } from "react-router-dom";
import { documentById } from "../../store/action/documentAction";
import {
  confirmOboardFromDocumen,
  responseOnboard,
} from "../../store/action/onBoardAction";
import { useSelector, useDispatch } from "react-redux";
import DocCertificateList from "../../wrapper/documents/DocCertificateList";
import Spinner from "react-bootstrap/Spinner";
import FormSuccess from "../../components/common/FormSuccess";
import { useToasts } from "react-toast-notifications";

const CustomerDocuments = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { warehouseId, whId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.DOCUMENT_INFO.documentDetail);
  const onBoard = useSelector((state) => state.ON_BOARD_INFO);

  useEffect(() => {
    dispatch(documentById(warehouseId));
  }, [dispatch, warehouseId]);

  const confirmDocument = () => {
    if (data?.data?.booking?.id) {
      let fields = {
        booking: parseInt(data?.data?.booking?.id),
      };
      dispatch(
        confirmOboardFromDocumen(
          fields,
          data?.data?.booking?.customer?.id,
          parseInt(whId),
          addToast,
          warehouseId
        )
      );
    }
  };

  return (
    <LayoutOne>
      {" "}
      {onBoard.onResponse && onBoard.onResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(responseOnboard(null))}
          message={onBoard.onResponse?.message}
        />
      ) : null}
      <div className="row align-items-center py-1 px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0">
          <h5 className="text-dark-blue py-2">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>{" "}
            Warehouse ID : DL-{whId}
          </h5>
        </div>
        <div className="col-12 bg-white rounded">
          <div className="row justify-content-between">
            <div className="col-md-auto px-4 order-md-2">
              {" "}
              {data?.data?.customerDocument !== null &&
                data?.data?.vendorDocument != null &&
                !data?.data?.onBoardApproved && (
                  <div className="row">
                    <div className="col-auto d-flex h-100 px-2 my-2">
                      <button
                        disabled={onBoard?.isPending}
                        onClick={confirmDocument}
                        className="custom-btn btn-deep-blue"
                      >
                        {" "}
                        {onBoard?.isPending ? (
                          <Spinner animation="border" />
                        ) : null}
                        Approved{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>
                )}
            </div>

            <div className="tabs-scroll col-md-auto order-md-1 d-flex">
              <ul className="nav nav-pills admin-tabs-blue justify-content-between">
                <li className="nav-item">
                  <Link
                    to={`/customer-documents/${warehouseId}/${whId}`}
                    className="nav-link text-uppercase active"
                  >
                    {" "}
                    customers{" "}
                  </Link>{" "}
                </li>{" "}
                <li className="nav-item">
                  <Link
                    to={`/vendor-documents/${warehouseId}/${whId}`}
                    className="nav-link text-uppercase"
                  >
                    {" "}
                    vendors{" "}
                  </Link>{" "}
                </li>{" "}
              </ul>
            </div>
          </div>{" "}
        </div>{" "}
        <div className="col-12 mt-2 text-nowrap table-responsive  table-gray-admin">
          <table className="table">
            <thead>
              <tr>
                <th> Warehouse ID </th> <th> Document Name </th>{" "}
                <th className="text-center"> View </th>{" "}
                <th className="text-center"> Upload Document </th>{" "}
              </tr>{" "}
            </thead>{" "}
            <tbody>
              <tr>
                <td> ID {whId} </td> <td> {`Space Certificate`} </td>{" "}
                <td className="text-center">
                  <Link
                    to={`/space-documents/${data.data?.id}?userType=customer`}
                    className="btn font-weight-bold px-1 py-0"
                  >
                    {" "}
                    <i className="fas fa-eye"> </i>
                  </Link>
                </td>{" "}
                <td className="text-center"></td>{" "}
              </tr>

              {data.data &&
              data.data.customerDocument &&
              data.data.customerDocument.documents &&
              data.data.customerDocument.documents.length > 0
                ? data.data.customerDocument.documents.map((item, index) => {
                    return (
                      <DocCertificateList
                        userDocType="customerdoc"
                        userType="customer"
                        item={item}
                        warehouseId={warehouseId}
                        key={index}
                        whId={whId}
                        documentId={data.data.customerDocument.id}
                        index={index}
                      />
                    );
                  })
                : null}
            </tbody>{" "}
          </table>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default React.memo(CustomerDocuments);
