import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormSuccess from '../../../components/common/FormSuccess';
import { Formik, Form, Field } from 'formik';
import {
  changeWarehouseStatus1,
  updateWarehousependingDetails,
} from './../../../store/action/warehouseAction';
import CustomLoader from '../../../CustomLoader';
import Spinner from 'react-bootstrap/Spinner';
import * as Yup from 'yup';

const PhotoPendingForm = ({ warehouseId, read, slug }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const [coverView, setCoverView] = useState(null);
  const [inDoorView, setInDoorView] = useState([]);

  const [outDoorView, setOutDoorView] = useState([]);
  const validationSchema = function (values) {
    return Yup.object().shape({
      whsCoverImage: Yup.object().shape({
        coverImage: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], 'Please Select a option'),
          // etc
        }),
        // etc
      }),
      whsIndoors: Yup.object().shape({
        indoor: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], 'Please Select a option'),
          // etc
        }),
        // etc
      }),
      whsOutdoors: Yup.object().shape({
        outdoor: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], 'Please Select a option'),
          // etc
        }),
        // etc
      }),
    });
  };
  const [remarkForm, setRemarkForm] = useState({
    whsCoverImage: {
      type: '',
      coverImg: {
        value: '',
        whsremark: '',
      },
    },
    whsIndoors: {
      type: '',
      indoor: [
        {
          value: [],
          whsremark: '',
        },
      ],
    },
    whsOutdoors: {
      type: '',
      outdoor: [
        {
          value: [],
          whsremark: '',
        },
        {
          value: '',
          whsremark: '',
        },
      ],
    },
  });
  // const validate = (getValidationSchema) => {
  //   return (values) => {
  //     const validationSchema = getValidationSchema(values);
  //     try {
  //       return {};
  //     } catch (error) {
  //       return getErrorsFromValidationError(error);
  //     }
  //   };
  // };
  // const getErrorsFromValidationError = (validationError) => {
  //   const FIRST_ERROR = 0;
  //   return validationError.inner.reduce((errors, error) => {
  //     return {
  //       ...errors,
  //       [error.path]: error.errors[FIRST_ERROR],
  //     };
  //   }, {});
  // };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        fields: fields,
      },
      warehouseId: warehouseId,
      slug: slug,
    };
    console.log('tesitng', payload);
    dispatch(updateWarehousependingDetails(payload));
  };

  useEffect(() => {
    if (
      data?.singleFormData?.remarks !== null &&
      data?.singleFormData?.remarks !== undefined &&
      data?.singleFormData?.remarks?.warehouseImagesRemarks !== undefined &&
      Object.keys(data?.singleFormData?.remarks?.warehouseImagesRemarks).length > 0
    ) {
      setRemarkForm(data?.singleFormData?.remarks?.warehouseImagesRemarks);
    } else {
      if (
        data?.singleFormData?.warehouseRes !== null &&
        data?.singleFormData?.warehouseRes !== undefined &&
        data?.singleFormData?.warehouseRes?.warehouseImages !== undefined
      ) {
        if (Object.keys(data?.singleFormData?.warehouseRes?.warehouseImages).length > 0) {
          console.log('data format');
          console.log(data?.singleFormData?.warehouseRes?.warehouseImages);
          console.log('warehouse images components');
          setRemarkForm({
            whsCoverImage: {
              type: '',
              coverImg: {
                value: data?.singleFormData?.warehouseRes?.warehouseImages[0]?.cover,
                whsremark: '',
              },
            },
            whsIndoors: {
              type: '',
              indoor: [
                {
                  value: data?.singleFormData?.warehouseRes?.warehouseImages[0]?.photo?.indoor,
                  whsremark: '',
                },
              ],
            },
            whsOutdoors: {
              type: '',
              outdoor: [
                {
                  value: data?.singleFormData?.warehouseRes?.warehouseImages[0]?.photo?.outdoor,
                  whsremark: '',
                },
              ],
            },
          });
        }
      }
    }
  }, [data?.singleFormData]);

  const handleNextPage = () => {
    if (data?.addNewResponse && data?.addNewResponse?.statusCode === 200) {
      history.push(`/pending-details/${warehouseId}/form-80`);
      dispatch(changeWarehouseStatus1());
    }
  };

  return (
    <>
      {/* {data?.addNewResponse && data?.addNewResponse?.statusCode === 200 ? (
        <FormSuccess onClick={handleNextPage} message={data.addNewResponse.message} />
      ) : null}{' '} */}
      {data.isLoading === true ? (
        <CustomLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={remarkForm}
          // validate={validate(validationSchema)}
          onSubmit={(fields) => onSubmit(fields)}
          render={({ errors, status, onChange, touched, values, setFieldValue }) => {
            return (
              <Form>
                <div className='col-12 rounded-lg px-md-4'>
                  <div className='row'>
                    <div className='col-12'>
                      <h6 className='py-3 mb-3 border-bottom'>Warehouse Cover Photo </h6>{' '}
                    </div>
                    <div className='col-xl-8'>
                      <table className='table table-borderless mb-0 '>
                        <thead>
                          <tr>
                            <th scope='col' class='col-lg-5'>
                              {' '}
                            </th>{' '}
                            <th scope='col' className='text-center'>
                              Remark{' '}
                            </th>{' '}
                          </tr>{' '}
                        </thead>
                        <tbody>
                          <tr>
                            <td className='py-0'>
                              <table className='table table-borderless form-group col-12 pt-xl-1'>
                                <tr>
                                  <td className=' pb-0 d-flex align-items-center'>
                                    <table className='col-12'>
                                      <tr className='card card-overlay upload-image-preview position-relative'>
                                        <td className='img-holder size-200px'>
                                          <img
                                            className='w-100 h-100 img-fluid rounded overflow-hideen'
                                            id='imageResult'
                                            src={
                                              remarkForm?.whsCoverImage?.coverImg?.value !== ''
                                                ? remarkForm?.whsCoverImage?.coverImg?.value
                                                : ''
                                            }
                                            alt=''
                                          />
                                        </td>{' '}
                                      </tr>{' '}
                                    </table>{' '}
                                  </td>{' '}
                                </tr>{' '}
                              </table>{' '}
                            </td>{' '}
                            <td className='pb-0'>
                              <table class='table table-borderless form-group col-12 pt-xl-1'>
                                <tr>
                                  <td className='pb-0 pt-3'>
                                    <Field
                                      disabled={read}
                                      name={`whsCoverImage.coverImg.whsremark`}
                                      type='text'
                                      className='form-control bg-white px-4 mb-2 mr-4'
                                      placeholder='Remarks'
                                    />
                                  </td>{' '}
                                </tr>{' '}
                              </table>{' '}
                            </td>{' '}
                          </tr>{' '}
                        </tbody>{' '}
                      </table>{' '}
                    </div>
                    {/* old code  */}{' '}
                  </div>{' '}
                  <div className='row'>
                    <div className='col-12'>
                      <h6 className='py-3 mb-3 border-bottom'>Indoor Cover Photo </h6>{' '}
                    </div>
                    {remarkForm?.whsIndoors?.indoor !== null &&
                      remarkForm?.whsIndoors?.indoor[0]?.value?.length > 0 &&
                      remarkForm?.whsIndoors?.indoor[0]?.value.map((elem, index) => {
                        return (
                          <div className='col-xl-8' key={index}>
                            <table class='table table-borderless mb-0 '>
                              <thead>
                                <tr>
                                  <th scope='col' class='col-lg-5'>
                                    {' '}
                                  </th>{' '}
                                  {index === 0 && (
                                    <th scope='col' className='text-center'>
                                      Remark{' '}
                                    </th>
                                  )}{' '}
                                </tr>{' '}
                              </thead>
                              <tbody>
                                <tr>
                                  <td className='py-0'>
                                    <table class='table table-borderless form-group col-12 pt-xl-1'>
                                      <tr>
                                        <td className=' pb-0 d-flex align-items-center'>
                                          <table className='col-12'>
                                            <tr className='card card-overlay upload-image-preview position-relative'>
                                              <td className='img-holder size-200px'>
                                                <img
                                                  className='w-100 h-100 img-fluid rounded overflow-hideen'
                                                  id='imageResult'
                                                  src={elem}
                                                  alt=''
                                                />
                                              </td>{' '}
                                            </tr>{' '}
                                          </table>{' '}
                                        </td>{' '}
                                      </tr>{' '}
                                    </table>{' '}
                                  </td>{' '}
                                  {index === 0 && (
                                    <td className='pb-0'>
                                      <table className='table table-borderless form-group col-12 pt-xl-1'>
                                        <tr>
                                          <td className='pb-0 pt-3'>
                                            <Field
                                              disabled={read}
                                              name={`whsIndoors.indoor[0].whsremark`}
                                              type='text'
                                              className='form-control bg-white px-4 mb-2 mr-4'
                                              placeholder='Remarks'
                                            />
                                          </td>{' '}
                                        </tr>{' '}
                                      </table>{' '}
                                    </td>
                                  )}{' '}
                                </tr>{' '}
                              </tbody>{' '}
                            </table>{' '}
                          </div>
                        );
                      })}
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <h6 className='py-3 mb-3 border-bottom'>Outdoor Cover Photo </h6>{' '}
                    </div>
                    {remarkForm?.whsOutdoors?.outdoor !== null &&
                      remarkForm?.whsOutdoors?.outdoor[0]?.value?.length > 0 &&
                      remarkForm?.whsOutdoors?.outdoor[0]?.value?.map((current, index) => {
                        return (
                          <div className='col-xl-8' key={index}>
                            <table class='table table-borderless mb-0 '>
                              <thead>
                                <tr>
                                  <th scope='col' class='col-lg-5'>
                                    {' '}
                                  </th>{' '}
                                  {index === 0 && (
                                    <th scope='col' className='text-center'>
                                      Remark{' '}
                                    </th>
                                  )}{' '}
                                </tr>{' '}
                              </thead>
                              <tbody>
                                <tr>
                                  <td className='py-0'>
                                    <table className='table table-borderless form-group col-12 pt-xl-1'>
                                      <tr>
                                        <td className=' pb-0 d-flex align-items-center'>
                                          <table className='col-12'>
                                            <tr className='card card-overlay upload-image-preview position-relative'>
                                              <td className='img-holder size-200px'>
                                                <img
                                                  className='w-100 h-100 img-fluid rounded overflow-hideen'
                                                  id='imageResult'
                                                  src={current ? current : ''}
                                                  alt=''
                                                />
                                              </td>{' '}
                                            </tr>{' '}
                                          </table>{' '}
                                        </td>{' '}
                                      </tr>{' '}
                                    </table>{' '}
                                  </td>{' '}
                                  {index === 0 && (
                                    <td className='pb-0'>
                                      <table class='table table-borderless form-group col-12 pt-xl-1'>
                                        <tr>
                                          <td className='pb-0 pt-3'>
                                            <Field
                                              disabled={read}
                                              name={`whsOutdoors.outdoor[0].whsremark`}
                                              type='text'
                                              className='form-control bg-white px-4 mb-2 mr-4'
                                              placeholder='Remarks'
                                            />
                                          </td>{' '}
                                        </tr>{' '}
                                      </table>{' '}
                                    </td>
                                  )}{' '}
                                </tr>{' '}
                              </tbody>{' '}
                            </table>{' '}
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className={`col-12 mt-4`}>
                  <div className={`row justify-content-end mb-4 ${!read ? '' : 'd-none'}`}>
                    <div className='col-auto '>
                      {Object.keys(touched).length > 0 ? (
                        <button
                          type='submit'
                          className='btn btn-deep-blue add-class remove-class '
                          disabled={data.isPending}
                        >
                          Save {data.isPending ? <Spinner animation='border' /> : null}{' '}
                        </button>
                      ) : (
                        ''
                      )}{' '}
                    </div>{' '}
                  </div>{' '}
                </div>{' '}
              </Form>
            );
          }}
        />
      )}
    </>
  );
};

export default PhotoPendingForm;
