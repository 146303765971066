import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  fetchFieldAgentByIdAndType,
  WarehouseByIdType,
} from "../../store/action/warehouseAction";

import { useDispatch, useSelector } from "react-redux";
import WarehouseRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/WarehousePendingForm";
import ContactRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/ContactPendingForm";
import SpaceRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/SpacePendingForm";
import OfficeRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/OfficePendingForm";
import MhRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/MhPendingForm";
import SafetyRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/SafetyPendingForm";
import PermitRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/PermitPendingForm";
import MaterialRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/MaterialPendingForm";
import PhotoRejectedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/PhotoPendingForm";
import EightyApprovedForm from "../../wrapper/warehouseManagement/fieldagentpendingForms/EightyPendingForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: "5px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    padding: "15px",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const FieldAgentRejectDetail = () => {
  const { warehouseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const data = useSelector((state) => state.WAREHOUSEINFO);

  // const data=useSelector((state)=>state.WAREHOUSELIST);
  // console.log("Show--->", data.isError)

  const handleChange = (panel, type) => (event, isExpanded) => {
    if (type === "warehouse") {
      if (isExpanded) {
        dispatch(fetchFieldAgentByIdAndType(warehouseId, type));
      }

      setExpanded(isExpanded ? panel : false);
    }
    if (data.singleFormData && data.singleFormData !== null) {
      if (isExpanded) {
        dispatch(fetchFieldAgentByIdAndType(warehouseId, type));
      }

      setExpanded(isExpanded ? panel : false);
    }
  };

  useEffect(() => {
    dispatch(fetchFieldAgentByIdAndType(warehouseId, "warehouse"));
    return () => {
      dispatch(WarehouseByIdType(null));
    };
  }, [dispatch, warehouseId]);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Rejected Field Agent </title>{" "}
      </Helmet>

      <div className="row justify-content-end align-items-center py-3 px-3 mx-0">
        <div className="col-12 px-0">
          <div className="row">
            <div className="col-12">
              <div className={classes.root}>
                <h5 className="backButton my-4">
                  {" "}
                  <i
                    onClick={() => history.goBack()}
                    className="fas fa-chevron-left mr-3 cursorPointer"
                  >
                    {" "}
                  </i>
                  Rejected Fields Verification Checklist
                </h5>

                {/* Loader

                          {data.isLoading ?  <CustomLoader /> : (data.isError !=="" ? 
                          <ErrorCard message={data.isError} />
                          :
                                            <> */}

                {/* Create New Warehouse */}

                <Accordion
                  expanded={expanded === "panel"}
                  onChange={handleChange("panel", "wareUpdate")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        1. Warehouse Details{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <WarehouseRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Contact Detail Form */}

                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1", "warehouseContactDetails")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        2. Warehouse Contact Details{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <ContactRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Another Form */}

                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2", "storageSpace")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 3. Storage Space </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <SpaceRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* IT INFRA Form */}

                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3", "itInfrAndOfficeSpace")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        4. IT & Office Infra{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <OfficeRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* MH Infra */}

                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4", "mhInfra")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 5. MH Infra </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <MhRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Safety Security Form */}

                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5", "safetyAndSecurity")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        6. Safety Security Form{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails>
                    <SafetyRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Permit Form */}

                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange(
                    "panel6",
                    "buildingTradeRelatedPermit"
                  )}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 7. Permits </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails className="m-0 p-0">
                    <PermitRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Material Type Form */}

                <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7", "materialType")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 8. Material Type </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails className="m-0 p-0">
                    <MaterialRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/* Warehouse Photo Form */}

                <Accordion
                  expanded={expanded === "panel8"}
                  onChange={handleChange("panel8", "warehouseImages")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading">
                        {" "}
                        9. Warehouse Photo{" "}
                      </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails className="m-0 p-0">
                    <PhotoRejectedForm viewMood={true} />{" "}
                  </AccordionDetails>{" "}
                </Accordion>

                {/*  Form Eighty*/}

                <Accordion
                  expanded={expanded === "panel10"}
                  onChange={handleChange("panel10", "formEighty")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className={classes.heading}>
                      {" "}
                      <span className="accord-heading"> 10. Form 80 </span>
                    </Typography>
                  </AccordionSummary>{" "}
                  <AccordionDetails className="m-0 p-0">
                    <EightyApprovedForm />
                  </AccordionDetails>{" "}
                </Accordion>

                <div className="col-12 mt-4">
                  <div className="row justify-content-start">
                    <div className="col-auto my-2">
                      <button
                        onClick={() => history.goBack()}
                        type="button"
                        className="btn btn-deep-blue"
                      >
                        {" "}
                        Back{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default FieldAgentRejectDetail;
