import {
    combineReducers
} from 'redux';
import {
    USER_INFO
} from './userReducer'
import {
    USERACCOUNT,
    authenticated
} from './account/login'
import {
    WAREHOUSEINFO
} from './warehouseReducer';
import {
    RFQ_INFO
} from './rfqReducer';
import {
    CATEGORY_INFO
} from './categoryReducer';
import {
    DOCUMENT_INFO
} from './documentReducer';
import {
    ENQUIRYINFO
} from './enguiryReducer';
import {
    BOOKING_INFO
} from './bookingReducer';
import {
    INVOICE_INFO
} from './invoiceReducer';
import {
    REVIEW_INFO
} from './reviewReducer';
import {
    CMS_INFO
} from './cmsReducer';
import {
    REPORT_INFO
} from './reportReducer';
import {
    WHS_USER_INFO
} from './whsUserReducer';
import {
    SERVICE_INFO
} from './serviceReducer';
import {
    WHY_INFO
} from './whyReducer';
import {
    FILTER_INFO
} from './filterReducer';
import {
    ON_BOARD_INFO
} from './onBoardReducer';
import {
    DASHBOARD_INFO
} from './dashboardReducer';
import {
    NOTIFICATION_INFO
} from './notificationReducer';
import {
    SIDEMENU_INFO
} from './SidemenuReducer';

export default combineReducers({
    USERACCOUNT,
    USER_INFO,
    authenticated,
    ENQUIRYINFO,
    WAREHOUSEINFO,
    RFQ_INFO,
    CATEGORY_INFO,
    DOCUMENT_INFO,
    BOOKING_INFO,
    INVOICE_INFO,
    REVIEW_INFO,
    CMS_INFO,
    REPORT_INFO,
    WHS_USER_INFO,
    SERVICE_INFO,
    WHY_INFO,
    FILTER_INFO,
    ON_BOARD_INFO,
    DASHBOARD_INFO,
    NOTIFICATION_INFO,
    SIDEMENU_INFO
});