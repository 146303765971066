import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { invoiceById } from "../../store/action/invoiceAction";
import { useSelector, useDispatch } from "react-redux";
import { CardLoader } from "../../components/helper/CustomLoader";
// import {readableDate} from '../../components/helper/reuse';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import InvoiceCertificate from "./InvoiceCertificate";

const VendorInvoiceDetails = () => {
  const history = useHistory();
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.INVOICE_INFO);

  useEffect(() => {
    dispatch(invoiceById(parseInt(invoiceId)));
  }, [dispatch, invoiceId]);

  const convertPdf = () => {
    html2canvas(document.getElementById("pdf-element")).then(function (canvas) {
      var wid;
      var hgt;
      var img = canvas.toDataURL(
        "image/png",
        (wid = canvas.width),
        (hgt = canvas.height)
      );
      var hratio = hgt / wid;
      var doc = new jsPDF("p", "pt", "a4");
      var width = doc.internal.pageSize.width;
      var height = width * hratio;
      doc.addImage(img, "JPEG", 20, 20, width, height);
      doc.save("Vendor Invoice.pdf");
    });
  };

  return (
    <LayoutOne>
      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row align-items-center pb-3 px-3 mx-0">
          <div className="col-12 py-3">
            <h5 className="text-dark">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left mr-3 cursorPointer"
              >
                {" "}
              </i>{" "}
              View User Invoice
            </h5>
          </div>
          <div className="col-12">
            {" "}
            {data.invoiceDetail ? (
              <InvoiceCertificate userType="Vendor" data={data.invoiceDetail} />
            ) : null}{" "}
            <div className="row">
              <div className="col-auto my-5">
                <button
                  onClick={() => history.goBack()}
                  className="btn btn-outline-deep-blue"
                >
                  {" "}
                  Back{" "}
                </button>{" "}
              </div>{" "}
              <div className="col-auto my-5">
                <button
                  onClick={convertPdf}
                  className="btn btn-outline-deep-blue py-2"
                >
                  {" "}
                  Download Invoice{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}
    </LayoutOne>
  );
};

export default VendorInvoiceDetails;
