import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";

const RFQList = ({ data, index }) => {
  // console.log("Data List RFQ==>", data)
  return (
    <tr>
      <td> {index}. </td> <td> {data.id} </td>{" "}
      <td> {data.location ? data.location.city.name : ""} </td>{" "}
      <td className="text-nowrap">
        {" "}
        {data.location ? data.location.area.name : ""}{" "}
      </td>{" "}
      <td className="text-nowrap">
        {" "}
        {data.user?.firstName + " " + data.user?.lastName}{" "}
      </td>{" "}
      {/* <td>Warehouse</td> */}{" "}
      <td className="text-nowrap"> {readableDate(data.createdAt)} </td>{" "}
      <td className="text-nowrap"> {data.status} </td>{" "}
      {/* <td>
                  <Link to={`/manage-response/${data.id}`} className="btn p-1 btn-link btn-link-deep-blue font-weight-bold">
                    View
                  </Link>
                </td> */}{" "}
      <td>
        {" "}
        {/* <Link to={`/manage-rfq/${data.id}`} className="btn p-1 btn-link btn-link-deep-blue font-weight-bold">
                    View
                  </Link> */}
        <Link
          to={`/manage-rfq/${data.id}/${data.id}?isOpen=false&stateName=${
            data.location ? data.location.city.name : ""
          }`}
          className="btn p-1 btn-link btn-link-deep-blue font-weight-bold"
        >
          View{" "}
        </Link>
      </td>{" "}
    </tr>
  );
};

export default RFQList;
