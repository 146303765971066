import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerOnboardForm,
  getSubUserAssignOnboardDetailById,
  updateUserForm,
  responseOnboard,
  userOnboardDetail,
  formDetails,
} from "../../store/action/onBoardAction";
import FormSuccess from "../../components/common/FormSuccess";
// import Spinner from 'react-bootstrap/Spinner';
import CustomerCommonForm from "../../wrapper/customerOnboarding/CustomerCommonForm";

const CustomerCostSheet = () => {
  const { onBoardId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const formType = new URLSearchParams(window.location.search).get("formType");
  const assignUserId = new URLSearchParams(window.location.search).get(
    "assignUserId"
  );
  const adminSide = new URLSearchParams(window.location.search).get(
    "adminSide"
  );
  const onData = useSelector((state) => state.ON_BOARD_INFO);
  const contactDetailOnboardId = new URLSearchParams(
    window.location.search
  ).get("contactDetailOnboardId");

  let [costJson, setCostJson] = useState({
    costSheets: {
      manpowerActive: [
        {
          key: "Uploading",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Staging",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "QC",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Scanning",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Barcode Genration",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "MRN",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Debit Note",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Brand Learning",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Batch Lebel Printing",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "MRP Lebel",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Bunding",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Bottle Cap Ch",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Seal Pasting",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Post Production",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Pump Receipt",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Material Pick Up",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Outward QC",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Outward Scanning",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Packing",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Cartonnisation",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Weight",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Shipping Lebel",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Carton Loading",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
        {
          key: "Picklist/Invoice",
          handlingType: "",
          productvity: "",
          dailyVolume: 200,
          workingHrPerDay: 8,
          manpowerNo: "",
          manpowerType: "",
          dedicatedOrShared: "DEDICATED",
          howMutchShared: "",
          cost: "",
        },
      ],
    },
  });

  useEffect(() => {
    // console.log("null-->",onData.userOnboardDetail.costSheet)

    // if (adminSide === "true" && onData.formDetail?.costSheet) {
    //   setCostJson({
    //     costSheets: onData.formDetail?.costSheet?.costSheets
    //   })
    // }

    if (onData.formDetail?.costSheet?.costSheets) {
      setCostJson({
        costSheets: onData.formDetail?.costSheet?.costSheets,
      });
    }

    // if (formType === "costSheet" && onData.userOnboardDetail?.costSheet && onData.userOnboardDetail?.costSheet?.costSheets) {
    //   setCostJson({
    //     costSheets: onData.userOnboardDetail.costSheet?.costSheets
    //   })
    // }
  }, [
    onData.formDetail?.costSheet,
    onData.userOnboardDetail,
    formType,
    adminSide,
  ]);

  useEffect(() => {
    if (formType === "costSheet") {
      dispatch(getSubUserAssignOnboardDetailById("costSheet", onBoardId));
    } else {
      dispatch(getCustomerOnboardForm(assignUserId, "costSheet", onBoardId));
    }

    return () => {
      dispatch(userOnboardDetail(null));
      dispatch(formDetails(null));
    };
  }, [dispatch, onBoardId, formType, assignUserId]);

  const redirect = () => {
    dispatch(responseOnboard(null));
    history.goBack();
  };

  const schema = Yup.object().shape({
    manpowerActive: Yup.array().of(
      Yup.object().shape({
        handlingType: Yup.string().required("Required"),
      })
    ),
  });

  return (
    <LayoutOne>
      {" "}
      {onData.onResponse && onData.onResponse?.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={onData.onResponse?.message} />
      ) : null}
      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12  form-control-height">
          <span onClick={() => history.goBack()}>
            {" "}
            <h5 className="text-dark-blue cursorPointer">
              {" "}
              <i className="fas fa-chevron-left mr-3"> </i> Cost Sheet
            </h5>{" "}
          </span>{" "}
        </div>{" "}
        <CustomerCommonForm buttonHide={true} />{" "}
        <div className="col-12 mt-2">
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={costJson}
            onSubmit={(fields) => {
              if (contactDetailOnboardId) {
                dispatch(
                  updateUserForm(contactDetailOnboardId, "costSheet", fields)
                );
              }
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              let allTotal = 0;

              return (
                <Form>
                  <div className="row">
                    <div className="col-12 px-0 table-responsive table-gray-admin">
                      {/* Cost Sheet Second File */}

                      <table className="table text-left mt-3">
                        <tbody>
                          <tr>
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Activity{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Handling Type{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Productivity / Hr / Persion{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Daily Volume{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Working Hr / Day{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Manpower No.{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Manpower Type{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Dedicated / Shared{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              How Much Shared %{" "}
                            </td>{" "}
                            <td className="font-weight-bold border-top-0 px-2 py-2">
                              {" "}
                              Cost{" "}
                            </td>{" "}
                          </tr>

                          {values.costSheets?.manpowerActive &&
                            values.costSheets.manpowerActive?.length > 0 &&
                            values.costSheets?.manpowerActive.map(
                              (mn, index) => {
                                let manpowerCost = Math.round(
                                  (mn.dailyVolume ? mn.dailyVolume : 0) /
                                    isNaN(mn.productvity)
                                    ? 1
                                    : mn.productvity / mn.workingHrPerDay
                                );

                                let totalCost = 0;
                                let finalCost = 0;

                                switch (mn.manpowerType) {
                                  case "MANAGER":
                                    totalCost = manpowerCost * 60000;
                                    break;

                                  case "ASSISTANT_MANAGER":
                                    totalCost = manpowerCost * 45000;
                                    break;

                                  case "EXECUTIVE":
                                    totalCost = manpowerCost * 28000;
                                    break;

                                  case "SUPERVISER":
                                    totalCost = manpowerCost * 28000;
                                    break;

                                  case "SKILLED_LABOUR":
                                    totalCost = manpowerCost * 24000;
                                    break;

                                  case "LABOUR":
                                    totalCost = manpowerCost * 20000;
                                    break;

                                  case "SECURITY":
                                    totalCost = manpowerCost * 20000;
                                    break;

                                  default:
                                    totalCost = manpowerCost * 0;
                                    break;
                                }
                                totalCost = isNaN(totalCost) ? 0 : totalCost;
                                if (mn.dedicatedOrShared === "DEDICATED") {
                                  finalCost = totalCost;
                                } else if (mn.dedicatedOrShared === "SHARED") {
                                  finalCost =
                                    (totalCost * mn.howMutchShared) / 100;
                                }

                                allTotal = allTotal + finalCost;

                                return (
                                  <tr key={index}>
                                    <td className="font-weight-bold px-2 border-bottom-0">
                                      {" "}
                                      {mn?.key}{" "}
                                    </td>{" "}
                                    <td className="border-bottom-0">
                                      <Field
                                        // onChange={e => setFieldValue(`costSheets.manpower.${index}.chargeable`, parseInt(e.target.value))}
                                        name={`costSheets.manpowerActive.${index}.handlingType`}
                                        type="text"
                                        className={`form-control bg-white border-1`}
                                      />
                                    </td>{" "}
                                    <td className="border-bottom-0">
                                      <Field
                                        name={`costSheets.manpowerActive.${index}.productvity`}
                                        type="number"
                                        min="0"
                                        className="form-control bg-white border-1"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `costSheets.manpowerActive.${index}.productvity`,
                                            parseInt(e.target.value)
                                          )
                                        }
                                      />{" "}
                                    </td>{" "}
                                    <td className="border-bottom-0">
                                      <Field
                                        name={`costSheets.manpowerActive.${index}.dailyVolume`}
                                        type="number"
                                        className="form-control bg-white border-1"
                                        disabled
                                      />
                                    </td>
                                    <td className="border-bottom-0">
                                      <Field
                                        name={`costSheets.manpowerActive.${index}.workingHrPerDay`}
                                        type="number"
                                        className="form-control bg-white border-1"
                                        disabled
                                      />
                                    </td>
                                    <td className="border-bottom-0">
                                      <input
                                        value={manpowerCost}
                                        // name={`costSheets.manpowerActive.${index}.manpowerNo`}
                                        type="number"
                                        className="form-control bg-white border-1"
                                        disabled
                                        // onChange={e => setFieldValue(`costSheets.manpowerActive.${index}.manpowerNo`, 566)}
                                      />
                                    </td>{" "}
                                    <td className="border-bottom-0">
                                      <Field
                                        name={`costSheets.manpowerActive.${index}.manpowerType`}
                                        as="select"
                                        className="form-control  bg-white px-4 common-select-deep-blue d-inline-block"
                                        id="manpower-type1"
                                        // onChange={(e)=>sourceCost((mn.dailyVolume ? mn.dailyVolume : 0)/mn.productvity/mn.workingHrPerDay, e.target.value)}
                                      >
                                        <option value=""> Select </option>{" "}
                                        <option value="MANAGER">
                                          {" "}
                                          Manager{" "}
                                        </option>{" "}
                                        <option value="ASSISTANT_MANAGER">
                                          {" "}
                                          Assistant Manager{" "}
                                        </option>{" "}
                                        <option value="EXECUTIVE">
                                          {" "}
                                          Executive{" "}
                                        </option>{" "}
                                        <option value="SUPERVISER">
                                          {" "}
                                          Superviser{" "}
                                        </option>{" "}
                                        <option value="SKILLED_LABOUR">
                                          {" "}
                                          Skilled Labour{" "}
                                        </option>{" "}
                                        <option value="LABOUR">
                                          {" "}
                                          Labour{" "}
                                        </option>{" "}
                                        <option value="SECURITY">
                                          {" "}
                                          Security{" "}
                                        </option>
                                      </Field>
                                    </td>{" "}
                                    <td className="border-bottom-0">
                                      {" "}
                                      {/* <Field
                                                                              name={`costSheets.manpowerActive.${index}.dedicatedOrShared`}
                                                                              type="number"
                                                                              className="form-control bg-white border-1"
                                                                            /> */}
                                      <Field
                                        name={`costSheets.manpowerActive.${index}.dedicatedOrShared`}
                                        as="select"
                                        className="form-control bg-white px-4 common-select-deep-blue d-inline-block"
                                        id="manpower-type1"
                                      >
                                        <option value="SHARED"> Shared </option>{" "}
                                        <option value="DEDICATED">
                                          {" "}
                                          Dedicated{" "}
                                        </option>{" "}
                                      </Field>
                                    </td>{" "}
                                    <td className="border-bottom-0">
                                      <Field
                                        name={`costSheets.manpowerActive.${index}.howMutchShared`}
                                        type="number"
                                        min="0"
                                        maxLength="2"
                                        // onInput={maxLengthCheckWithDecimal}
                                        className="form-control bg-white border-1"
                                      />
                                    </td>{" "}
                                    <td className="border-bottom-0">
                                      <input
                                        value={finalCost}
                                        // name={`costSheets.manpowerActive.${index}.cost`}
                                        type="number"
                                        className="form-control bg-white border-1"
                                        disabled
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}

                          <tr>
                            <td className="font-weight-bold px-2 border-bottom-0">
                              {" "}
                              Total{" "}
                            </td>{" "}
                            <td colSpan="8"> </td>{" "}
                            <td className="border-bottom-0">
                              <input
                                value={allTotal}
                                type="text"
                                className="form-control bg-gray text-white border-0"
                                disabled
                              />
                            </td>
                          </tr>
                        </tbody>{" "}
                      </table>

                      <button type="submit" className="btn btn-primary">
                        {" "}
                        Submit{" "}
                      </button>
                    </div>
                  </div>{" "}
                </Form>
              );
            }}
          />
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default CustomerCostSheet;
