import {
    INVOICE,
    INVOICE_BY_ID,
    INVOICE_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    invoiceList: [],
    invoiceDetail: {},
    invoiceResponse: null
}

export function INVOICE_INFO(state = initialState, action) {

    switch (action.type) {

        case INVOICE:
            return {
                ...state,
                invoiceList: action.payload,
            };

        case INVOICE_BY_ID:
            return {
                ...state,
                invoiceDetail: action.payload,
            };

        case INVOICE_RESPONSE:
            return {
                ...state,
                invoiceResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        default:
            return state;
    }
}