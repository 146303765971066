import React from "react";

const ReportList = ({ srn }) => {
  return (
    <tr>
      <td>
        {" "}
        <div className="cell"> {srn} </div>
      </td>
      <td>
        {" "}
        <div className="cell"> DEI-4567 </div>
      </td>
      <td>
        {" "}
        <div className="cell"> Suresh SIngh </div>
      </td>
      <td>
        {" "}
        <div className="cell"> Ware.corp </div>
      </td>
      <td>
        <select
          className="form-control h-45px custom-select bg-white common-select-deep-blue border-0 w-150px"
          name=""
          id=""
        >
          <option selected="" value="">
            {" "}
            Noida{" "}
          </option>{" "}
          <option value=""> Delhi </option>{" "}
        </select>{" "}
      </td>{" "}
      <td>
        <select
          className="form-control h-45px custom-select bg-white common-select-deep-blue border-0 w-150px"
          name=""
          id=""
        >
          <option selected="" value="">
            {" "}
            Sector 65{" "}
          </option>{" "}
          <option value=""> Sector 66 </option>{" "}
        </select>{" "}
      </td>{" "}
      <td>
        <div className="cell text-green"> Active </div>{" "}
      </td>{" "}
    </tr>
  );
};

export default ReportList;
