import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import {
  createCustomerRFQ,
  vendorToWhsRfqById,
  rfq_By_Id,
  responseRfq,
} from "../../store/action/rfqAction";
import { useSelector, useDispatch } from "react-redux";
import FormSuccess from "../../components/common/FormSuccess";
import {
  onlyNumberAllow,
  onlyAlphaNumericSpaceAllow,
} from "../../components/helper/reuse";
import { CardLoader } from "../../components/common/CustomLoader";
import Spinner from "react-bootstrap/Spinner";

const WhsToCustomerRFQ = () => {
  const { rfqId, warehouseId, vendorResponseId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [costError, setCostError] = useState(null);
  const data = useSelector((state) => state.RFQ_INFO);

  useEffect(() => {
    dispatch(vendorToWhsRfqById(vendorResponseId));

    return () => {
      dispatch(rfq_By_Id([]));
    };
  }, [dispatch, vendorResponseId]);

  const [jsonData, setJsonData] = useState({
    manPowers: [
      {
        manpowerType: "WH Manager",
        type: {
          name: "Shared",
        },
        quantity: 12,
        costUnit: 39,
        totalMonth: 10,
        remark: "man remark",
      },
      {
        manpowerType: "Asst Manager",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        manpowerType: "OB Executive+DEO",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        manpowerType: "Dispatch Incharge",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        manpowerType: "Inventory Incharge",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        manpowerType: "Inventory Supervisor",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        manpowerType: "Activity Supervisors",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        manpowerType: "Labours",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        manpowerType: "Security",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        manpowerType: "House Keeping",
        type: {
          name: "Dedicated",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
    ],
    infrastructures: [
      {
        infrastructureType: "MFD",
        type: {
          name: "Shared",
        },
        quantity: 10,
        costUnit: 10,
        totalMonth: 20,
        remark: "infra remark",
      },
      {
        infrastructureType: "Fire Extinguiser",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Office Setup",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "HPT Electric Voltas",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Hand Pallet Truck",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Picking Trolley",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Desktop Computer",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Office Printer",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Printer With Xerox, Fax and Scan facility",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Barcode printer",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "UPS",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Inverter",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Electric Stacker",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "AC",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "ForkLift Battery Operated",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Projector",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Weighting Machine",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Wooden Pallets",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Generator 20KVA",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Message Boards (Notice)",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "HD Selves For File and Record",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Lock and Key Almira",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Water Cooler",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Labels & Signage",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Office and Security Fan",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Emergency Light with Battery",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "WMS Software",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Wire Mesh Cage",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "First Aid Kit",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Security Alarm",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Dock Levler",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Shelve Racks",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "CCTV",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Internal Painting",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Security & Safety Audit",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Security & Safety Displays",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Shelved Rack",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Broadband Connection setup",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Vaccumm Cleaner",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Start Up",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
      {
        infrastructureType: "Scanners",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        totalMonth: "",
        remark: "",
      },
    ],
    runningCosts: [
      {
        runningCostType: "Rent",
        type: {
          name: "Shared",
        },
        quantity: 20,
        costUnit: 3,
        amount: 40,
        totalMonth: 20,
        remark: "run remark",
      },
      {
        runningCostType: "Infrastructure",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Manpower",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Communication",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Utilities",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Staff Welfare",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Power",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Licenses",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "House Keeping Consumables",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "WMS ID",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Packaging Consumable",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Genset",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Genset",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Consumable(printing material)",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Broadband Connection",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
      {
        runningCostType: "Pallets",
        type: {
          name: "",
        },
        quantity: "",
        costUnit: "",
        amount: "",
        totalMonth: "",
        remark: "",
      },
    ],
    adminTotalPerUnitCost: "",
    remark: "",
  });

  useEffect(() => {    if (data?.rfqDetail?.data) {
      setJsonData({
        ...data?.rfqDetail?.data,
        adminTotalPerUnitCost: "",
        remark: "",
      });
    }
  }, [data?.rfqDetail?.data]);

  const rfqSchema = Yup.object().shape({
    adminTotalPerUnitCost: Yup.string()
      .required("Required")
      .max(9, "Must be 9 digits"),
    remark: Yup.string().required("Required"),
  });

  const redirect = () => {
    dispatch(responseRfq([]));
    history.replace(`/manage-rfq/${rfqId}/${warehouseId}`);
  };

  return (
    <LayoutOne>
      {data?.rfqResponse && data?.rfqResponse?.statusCode === 200 ? (
        <FormSuccess onClick={redirect} message={data?.rfqResponse?.message} />
      ) : null}
      {data?.isLoading ? (
        <CardLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          validationSchema={rfqSchema}
          initialValues={jsonData}
          onSubmit={(fields) => {
            // alert("Hello Abcd");
            console.log(vendorResponseId,rfqId,warehouseId)
            console.log("adminTotalPerUnitCost=>",fields.adminTotalPerUnitCost,"***",data?.rfqDetail?.data?.vendorTotalPerUnitCost)
            setCostError(null);
            if (vendorResponseId && rfqId && warehouseId) {
              fields["vendorResponseRfq"] = parseInt(vendorResponseId);
              fields["customerRfq"] = parseInt(rfqId);
              fields["warehouse"] = parseInt(warehouseId);

              if (
                fields.adminTotalPerUnitCost >
                data?.rfqDetail?.data?.vendorTotalPerUnitCost
              ) {
                dispatch(createCustomerRFQ(fields));
                setCostError(null);
              } else {
                setCostError(
                  `Price is Greater then (${data.rfqDetail?.data?.vendorTotalPerUnitCost})`
                );
                return 0;
              }
            }
          }}
          render={({ values, errors, status, onChange, touched }) => {
              const adminError=errors.adminTotalPerUnitCost !=undefined
              const adminTouch=touched.adminTotalPerUnitCost ==true
              const remarkError=errors.remark !=undefined
              const remarkTouch=touched.remark ==true
            return (
              <div className="w-100 d-block" >

                <Form>                
                  <div className="row align-items-center py-3 px-3 mx-0">
                    <div className="col-12 py-3 px-0">
                      <h4 className="text-dark-blue"> RFQ Details </h4>{" "}
                    </div>
                    <div className="col-12 bg-deep-gray py-3 mb-5">
                      <div className="row pt-3">
                        <div className="col-12 px-0">
                          <div className="form-group form-inline mb-3 px-0">
                            <label
                              htmlFor="inputPassword6"
                              className="w-250px justify-content-start px-3"
                            >
                              {" "}
                              RFQ ID{" "}
                            </label>{" "}
                            <div className="row mx-md-0 mx-sm-3 mx-0">
                              <div className="col-12 px-sm-3 px-0">
                                <input
                                  value={
                                    data.rfqDetail.data
                                      ? data.rfqDetail.data.customerRfqId
                                      : ""
                                  }
                                  type="text"
                                  id="inputPassword6"
                                  className="form-control d-inline-block form-control-md w-160px mx-3"
                                  placeholder="DL-01379"
                                  readOnly
                                />
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="col-12 px-0">
                          <div className="row">
                            <div className="col-12">
                              <div className="row mx-0">
                                <div className="col-md-auto pl-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 mt-2 w-250px justify-content-start px-3"
                                  >
                                    {" "}
                                    City{" "}
                                  </label>{" "}
                                  <div className="form-group col-auto px-3 mb-3">
                                    <input
                                      value={
                                        data?.rfqDetail?.data
                                          ? data?.rfqDetail?.data?.customerRfq
                                              ?.location?.city?.name
                                          : ""
                                      }
                                      className="form-control form-control-md w-160px"
                                      disabled
                                    />
                                  </div>{" "}
                                </div>{" "}
                                <div className="col-md-auto pl-0">
                                  <label
                                    htmlFor="staticEmail"
                                    className="mb-2 mt-2 w-250px justify-content-start px-3"
                                  >
                                    {" "}
                                    Area{" "}
                                  </label>{" "}
                                  <div className="form-group col-auto px-3 mb-3">
                                    <input
                                      value={
                                        data.rfqDetail.data
                                          ? data.rfqDetail.data.customerRfq
                                              ?.location.area.name
                                          : ""
                                      }
                                      className="form-control form-control-md w-160px"
                                      disabled
                                    />
                                  </div>{" "}
                                </div>
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>
                        <div className="col-12 px-0">
                          <div className="form-group form-inline mb-4 px-0">
                            <label
                              htmlFor="inputPassword6"
                              className="w-250px justify-content-start px-3"
                            >
                              {" "}
                              WH Space Required{" "}
                            </label>{" "}
                            <div className="row mx-md-0 mx-sm-3 mx-0">
                              <div className="col-12 px-sm-3 px-0">
                                <input
                                  value={
                                    data.rfqDetail.data
                                      ? data?.rfqDetail?.data?.customerRfq
                                          ?.warehouseSpaceRequired
                                      : ""
                                  }
                                  type="text"
                                  id="inputPassword6"
                                  className="form-control d-inline-block form-control-md w-160px mx-3"
                                  placeholder="Sqft"
                                  readOnly
                                />
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>
                        <div className="col-12 px-0">
                          <div className="form-group form-inline mb-4 px-0">
                            <label
                              htmlFor="inputPassword6"
                              className="w-250px justify-content-start px-3"
                            >
                              {" "}
                              Warehouse{" "}
                            </label>{" "}
                            <div className="row mx-md-0 mx-sm-3 mx-0">
                              <div className="col-12 px-sm-3 px-0">
                                <input
                                  value={
                                    data?.rfqDetail?.data
                                      ? data?.rfqDetail?.data?.warehouse
                                          ?.warehouseName
                                      : ""
                                  }
                                  type="text"
                                  id="inputPassword6"
                                  className="form-control d-inline-block form-control-md w-160px mx-3"
                                  placeholder="Sqft"
                                  readOnly
                                />
                              </div>{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>
                      </div>{" "}
                    </div>
                    <div className="col-12 py-3 px-0">


                    <h4 className="text-dark-blue"> Contact Information dev </h4>{" "}
                    
                    </div>
                    <div className="col-12 bg-deep-gray py-3 mb-5">
                      <div className="row pt-2">
                        <div className="form-group col-sm-6 mb-4">
                          <label htmlFor="company-name" className="mb-2">
                            {" "}
                            Company Name{" "}
                          </label>{" "}
                          <input
                            value={
                              data.rfqDetail.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.companyName
                                : ""
                            }
                            type="text"
                            className="form-control form-control-md"
                            id="company-name"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label htmlFor="company-type" className="mb-2">
                            {" "}
                            Type of Company{" "}
                          </label>{" "}
                          <select
                            className="form-control form-control-md"
                            id="company-type"
                            disabled
                          >
                            <option selected="">
                              {" "}
                              {data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.companyType?.type
                                : ""}{" "}
                            </option>
                          </select>{" "}
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label htmlFor="vendor-address" className="mb-2">
                            {" "}
                            Address{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.address
                                : ""
                            }
                            type="text"
                            className="form-control form-control-"
                            id="vendor-address"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-register-address"
                            className="mb-2"
                          >
                            {" "}
                            Registered Office Address{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.registerOfficeAddress
                                : ""
                            }
                            type="text"
                            className="form-control form-control-ms"
                            id="vendor-register-address"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label htmlFor="vendor-website" className="mb-2">
                            {" "}
                            Website{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.website
                                : ""
                            }
                            type="url"
                            className="form-control form-control-md"
                            id="vendor-website"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-group-company"
                            className="mb-2"
                          >
                            {" "}
                            Group Company{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.groupCompany
                                : ""
                            }
                            type="text"
                            className="form-control form-control-md"
                            id="vendor-group-company"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-authorised-signatory"
                            className="mb-2"
                          >
                            {" "}
                            Authorised Signatory{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.authorisedSignatory
                                : ""
                            }
                            type="text"
                            className="form-control form-control-md"
                            id="vendor-authorised-signatory"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-annual-turnover"
                            className="mb-2"
                          >
                            {" "}
                            Annual Turnover( in Cr INR){" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.annualTurnover
                                : ""
                            }
                            type="text"
                            className="form-control form-control-md"
                            id="vendor-annual-turnover"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-employees-number"
                            className="mb-2"
                          >
                            {" "}
                            No of Employees{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.noOfEmployees
                                : ""
                            }
                            type="number"
                            className="form-control form-control-md"
                            id="vendor-employees-number"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-core-business"
                            className="mb-2"
                          >
                            {" "}
                            Core Business{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.coreBusiness
                                : ""
                            }
                            type="text"
                            className="form-control form-control-md"
                            id="vendor-core-business"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-certifications"
                            className="mb-2"
                          >
                            {" "}
                            Certifications -{" "}
                          </label>{" "}
                          <select
                            className="form-control form-control-md"
                            id="vendor-certifications"
                            disabled
                          >
                            <option selected="">
                              {" "}
                              {data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.certification?.name
                                : ""}{" "}
                            </option>{" "}
                          </select>{" "}
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label htmlFor="vendor-registration" className="mb-2">
                            {" "}
                            Registrations -{" "}
                          </label>{" "}
                          <select
                            className="form-control form-control-md"
                            id="vendor-registration"
                            disabled
                          >
                            <option selected="">
                              {" "}
                              {data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.registration?.name
                                : ""}{" "}
                            </option>{" "}
                          </select>{" "}
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-contact-person"
                            className="mb-2"
                          >
                            {" "}
                            Contact Person{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.coreBusiness
                                : ""
                            }
                            type="text"
                            className="form-control form-control-md"
                            id="vendor-contact-person"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label htmlFor="vendor-title" className="mb-2">
                            {" "}
                            Title{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.title
                                : ""
                            }
                            type="text"
                            className="form-control form-control-md"
                            id="vendor-title"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label
                            htmlFor="vendor-email-address"
                            className="mb-2"
                          >
                            {" "}
                            Email Address{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.email
                                : ""
                            }
                            type="email"
                            className="form-control form-control-md"
                            id="vendor-email-address"
                            readOnly
                          />
                        </div>{" "}
                        <div className="form-group col-sm-6 mb-4">
                          <label htmlFor="vendor-phone" className="mb-2">
                            {" "}
                            Phone{" "}
                          </label>{" "}
                          <input
                            value={
                              data?.rfqDetail?.data
                                ? data?.rfqDetail?.data?.customerRfq
                                    ?.contactInformation?.phone
                                : ""
                            }
                            type="tel"
                            className="form-control form-control-md"
                            id="vendor-phone"
                            readOnly
                          />
                        </div>{" "}
                      </div>{" "}
                    </div>
                    <div className="col-12 py-3 px-0">
                      <h4 className="text-dark-blue">
                        {" "}
                        RFQ Reply to Warehousity{" "}
                      </h4>{" "}
                    </div>
                    <div className="col-12 bg-deep-gray mb-5">
                      {" "}
                      {/* <form className=""> */}{" "}
                      <div className="row">
                        <div className="col-12 px-0 table-gray-admin table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <td className="col font-weight-bold">
                                  Manpower:
                                </td>{" "}
                                <td className="col font-weight-bold">Type </td>{" "}
                                <td className="w-200px mw-200px font-weight-bold">
                                  Qty{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Cost / Unit{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Total / Month{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Remarks{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Considered / Not Considered{" "}
                                </td>{" "}
                              </tr>{" "}
                            </thead>{" "}
                            <tbody>
                              {values.manPowers &&
                                values.manPowers.length > 0 &&
                                values.manPowers.map((man, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <p className="m-0 pb-1">
                                          {" "}
                                          {man.manpowerType}{" "}
                                        </p>{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          readOnly
                                          name={`manPowers.${index}.type.name`}
                                          as="select"
                                          className="form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-130px"
                                          id="manpower-type1"
                                          disabled
                                        >
                                          <option value="SHARED">
                                            {" "}
                                            Shared{" "}
                                          </option>{" "}
                                          <option value="DEDICATED">
                                            {" "}
                                            Dedicated{" "}
                                          </option>{" "}
                                        </Field>{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`manPowers.${index}.quantity`}
                                          type="number"
                                          id="manpower-qty-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`manPowers.${index}.costUnit`}
                                          type="number"
                                          id="manpower-cost-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`manPowers.${index}.totalMonth`}
                                          type="number"
                                          id="manpower-months-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`manPowers.${index}.remark`}
                                          type="text"
                                          className="form-control text-center bg-white my-1 px-4"
                                          id="manpower-remarks1"
                                          disabled
                                        />
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`manPowers.${index}.considerOrNotConsider`}
                                          as="select"
                                          className={`form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-100`}
                                          id="manpower-type1"
                                          disabled
                                        >
                                          <option value=""> Select </option>{" "}
                                          <option value="CONSIDERED">
                                            {" "}
                                            Considered{" "}
                                          </option>{" "}
                                          <option value="NOTCONSIDERED">
                                            {" "}
                                            Not Considered{" "}
                                          </option>{" "}
                                        </Field>{" "}
                                      </td>{" "}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>{" "}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="col-12 bg-deep-gray mb-5">
                      <div className="row">
                        <div className="col-12 px-0 table-gray-admin table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <td className="col mw-150px font-weight-bold">
                                  Infrastructure:
                                </td>{" "}
                                <td className="col font-weight-bold">Type </td>{" "}
                                <td className="w-200px mw-200px font-weight-bold">
                                  Qty{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Cost / Unit{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Total / Month{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Remarks{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Considered / Not Considered{" "}
                                </td>{" "}
                              </tr>{" "}
                            </thead>{" "}
                            <tbody>
                              {values.infrastructures &&
                                values.infrastructures.length > 0 &&
                                values.infrastructures.map((man, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <p className="m-0 pb-1">
                                          {" "}
                                          {man.infrastructureType}{" "}
                                        </p>{" "}
                                      </td>{" "}
                                      <td>
                                        <select
                                          name={`infrastructures.${index}.type.name`}
                                          id="infrastructure-type1"
                                          className="form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-130px"
                                          disabled
                                        >
                                          <option value="SHARED">
                                            {" "}
                                            Shared{" "}
                                          </option>{" "}
                                          <option value="DEDICATED">
                                            {" "}
                                            Dedicated{" "}
                                          </option>{" "}
                                        </select>{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`infrastructures.${index}.quantity`}
                                          type="text"
                                          id="infrastructure-qty-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`infrastructures.${index}.costUnit`}
                                          type="text"
                                          id="infrastructure-cost-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`infrastructures.${index}.totalMonth`}
                                          type="text"
                                          id="infrastructure-months-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`infrastructures.${index}.remark`}
                                          type="text"
                                          className="form-control text-center bg-white my-1 px-4"
                                          id="infrastructure-remarks1"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`infrastructures.${index}.considerOrNotConsider`}
                                          as="select"
                                          className={`form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-100`}
                                          id="manpower-type1"
                                          disabled
                                        >
                                          <option value=""> Select </option>{" "}
                                          <option value="CONSIDERED">
                                            {" "}
                                            Considered{" "}
                                          </option>{" "}
                                          <option value="NOTCONSIDERED">
                                            {" "}
                                            Not Considered{" "}
                                          </option>{" "}
                                        </Field>{" "}
                                      </td>{" "}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>{" "}
                        </div>{" "}
                      </div>{" "}
                      {/* </form> */}{" "}
                    </div>
                    <div className="col-12 bg-deep-gray mb-5">
                      {" "}
                      {/* <form className=""> */}{" "}
                      <div className="row">
                        <div className="col-12 px-0 table-gray-admin table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <td className="col mw-150px font-weight-bold">
                                  Running Cost:
                                </td>{" "}
                                <td className="col font-weight-bold">Type </td>{" "}
                                <td className="w-200px mw-200px font-weight-bold">
                                  Qty{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Cost / Unit{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Total / Month{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Remarks{" "}
                                </td>{" "}
                                <td className="mw-250px font-weight-bold">
                                  Considered / Not Considered{" "}
                                </td>{" "}
                              </tr>{" "}
                            </thead>{" "}
                            <tbody>
                              {values.runningCosts &&
                                values.runningCosts.length > 0 &&
                                values.runningCosts.map((man, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <p className="m-0 pb-1">
                                          {" "}
                                          {man.runningCostType}{" "}
                                        </p>{" "}
                                      </td>{" "}
                                      <td>
                                        <select
                                          name={`runningCosts.${index}.type.name`}
                                          id="runningcost-type1"
                                          className="form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-130px"
                                          disabled
                                        >
                                          <option value="SHARED">
                                            {" "}
                                            Shared{" "}
                                          </option>{" "}
                                          <option value="DEDICATED">
                                            {" "}
                                            Dedicated{" "}
                                          </option>{" "}
                                        </select>{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`runningCosts.${index}.quantity`}
                                          type="text"
                                          id="runningcost-qty-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`runningCosts.${index}.costUnit`}
                                          type="text"
                                          id="runningcost-cost-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`runningCosts.${index}.totalMonth`}
                                          type="text"
                                          className="form-control text-center bg-white my-1 px-4"
                                          id="runningcost-remarks1"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        <Field
                                          name={`runningCosts.${index}.remark`}
                                          type="text"
                                          id="runningcost-months-input1"
                                          className="form-control bg-white px-4"
                                          disabled
                                        />{" "}
                                      </td>{" "}
                                      <td>
                                        <Field
                                          name={`runningCosts.${index}.considerOrNotConsider`}
                                          as="select"
                                          className={`form-control custom-select bg-white px-4 common-select-deep-blue d-inline-block w-100`}
                                          id="manpower-type1"
                                          disabled
                                        >
                                          <option value=""> Select </option>{" "}
                                          <option value="CONSIDERED">
                                            {" "}
                                            Considered{" "}
                                          </option>{" "}
                                          <option value="NOTCONSIDERED">
                                            {" "}
                                            Not Considered{" "}
                                          </option>{" "}
                                        </Field>{" "}
                                      </td>{" "}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>{" "}
                        </div>{" "}
                      </div>{" "}
                      {/* </form> */}{" "}
                    </div>
                    {/*pending section*/}
                    <div className="col-12 bg-deep-gray mb-5">
                        <div className="row py-4">
                          <div className="col-sm-6 mb-2">
                            <label className="h6 py-3">Total Per unit Cost in INR</label>
                          </div>
                          <div className="col-sm-6 ml-0">
                            <div className="input-group">
                              
                              <Field name={`adminTotalPerUnitCost`} id="" type="number" className={`w-100 form-control form-control-md`+(adminError && adminTouch?(" is-invalid"):(""))} />
 
                            </div>
                          </div>
                          <div className="col-lg-12 mb-2">
                            <h6>Remarks</h6>
                          </div>
                          <div className="row col-12 ml-0">
                            <div className="input-group">
                              <Field name={`remark`} id="" className={`w-100 form-control form-control-lg` +(remarkError && remarkTouch?(" is-invalid"):(""))} rows="3"></Field>

                            </div>
                          </div>
                        </div>
                      </div>
                    <div className="col-12">
                      <div className="row justify-content-start">
                        <div className="col-auto">
                        <div style={{color:"red"}}>
                          {costError}
                        </div>
                          <button
                            type="submit"
                            disabled={data?.isPending}
                            className="btn btn-outline-secondary mr-3 my-2 toggle-class"
                          >
                            Submit{" "}
                            {data?.isPending ? (
                              <Spinner animation="border" />
                            ) : null}{" "}
                          </button>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                </Form>{" "}
              </div>
            );
          }}
        />
      )}{" "}
    </LayoutOne>
  );
};

export default WhsToCustomerRFQ;
