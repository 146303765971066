import {
    WHYWHS,
    WHYWHS_BY_ID,
    WHS_TYPE_ID,
    WHY_RESPONSE,
    WHS_TYPE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from './../types';

import {
    WHYWHS_URL,
    WHSTYPE_URL
} from '../../services/urls';


import axiosauth from '../../services/axios-auth';


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isPending: bool,
    };
}


export function whyList(data) {
    return {
        type: WHYWHS,
        payload: data
    }
}

export function typeList(data) {
    return {
        type: WHS_TYPE,
        payload: data
    }
}



export function why_By_Id(data) {
    return {
        type: WHYWHS_BY_ID,
        payload: data
    }
}

export function type_By_Id(data) {
    return {
        type: WHS_TYPE_ID,
        payload: data
    }
}

export function responseWhy(data) {
    return {
        type: WHY_RESPONSE,
        payload: data
    }
}


// ###########  Fecth All categoryList ########

export const whyByPage = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(WHYWHS_URL + `?page=${page}&limit=${10}&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(whyList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("rfq Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  Fecth  category Detail By Id ########

export const whyById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(WHYWHS_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(why_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("category Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}

// ###########  Fecth  category Detail By Id ########

export const deleteById = (id) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.delete(WHYWHS_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(whyByPage(1))
                    dispatch(responseWhy(res))
                    dispatch(isPending(false))
                } else {
                    console.log("category Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  CREATE category  ############

export const createWhy = (data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.post(WHYWHS_URL, data).then(response => {
                let res = JSON.parse(response.data)
                console.log("responseWhy resss--->", res)
                if (res.statusCode === 200) {
                    dispatch(responseWhy(res))
                    dispatch(isPending(false))
                } else {
                    console.log("category Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}

// ###########  Update WHy  ############

export const updateWhy = (id, data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.put(WHYWHS_URL + `/${id}`, data).then(response => {
                let res = JSON.parse(response.data)
                console.log("responseWhy resss--->", res)
                if (res.statusCode === 200) {
                    dispatch(responseWhy(res))
                    dispatch(isPending(false))
                } else {
                    console.log("category Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}





// Warehouse type for homepage 

export const typeByPage = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        let filter = {
            "filter": {}
        }
        try {
            axiosauth.post(`/api/v1/warehousetype/all?page=${page}&limit=${10}&search=${search}`, filter).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(typeList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("rfq Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}



// Warehouse type for homepage 

export const typeById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))

        try {
            axiosauth.get(`/api/v1/warehousetype/${id}`).then(response => {
                let res = JSON.parse(response.data)
                console.log("type lIst ressss22===>", res)
                if (res.statusCode === 200) {
                    dispatch(type_By_Id(res.data))
                    dispatch(isLoading(false))
                } else {
                    console.log("rfq Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}



// ###########  CREATE category  ############

export const createType = (data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.post(WHSTYPE_URL, data).then(response => {
                let res = JSON.parse(response.data)
                console.log("responseWhy resss--->", res)
                if (res.statusCode === 200) {
                    dispatch(responseWhy(res))
                    dispatch(isPending(false))
                } else {
                    console.log("category Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// ###########  CREATE category  ############

export const updateType = (id, data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.put(WHSTYPE_URL + `/${id}`, data).then(response => {
                let res = JSON.parse(response.data)
                console.log("responseWhy resss--->", res)
                if (res.statusCode === 200) {
                    dispatch(responseWhy(res))
                    dispatch(isPending(false))
                } else {
                    console.log("category Details Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}