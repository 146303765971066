import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { fetchFeedbackById } from "../../store/action/feedbackAction";
// import { CardLoader } from "../../components/helper/CustomLoader";
// import { readableDate } from "../../components/helper/reuse";
import { useDispatch } from "react-redux";

const FeedbackDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { feedbackId } = useParams();

  const [feedback, setFeedback] = useState(null);

  useEffect(() => {
    dispatch(fetchFeedbackById(parseInt(feedbackId), setFeedback));
  }, [dispatch, feedbackId]);

  return (
    <LayoutOne>
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            ></i>{" "}
            View Reviews Details{" "}
          </h5>{" "}
        </div>
        <div className="col-12">
          <div className="row mx-0">
            <div className="col-12 bg-white rounded">
              {" "}
              {feedback && (
                <div className="card card-body border-0">
                  <div className="d-flex">
                    <div className="mw-200px px-3 font-weight-bold py-2">
                      User Name{" "}
                    </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {feedback?.user?.firstName +
                        " " +
                        feedback?.user?.lastName}{" "}
                    </div>{" "}
                  </div>
                  <div className="d-flex">
                    <div className="mw-200px px-3 font-weight-bold py-2">
                      Warehouse ID{" "}
                    </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {feedback?.warehouse?.warehouseId}{" "}
                    </div>{" "}
                  </div>
                  <div className="d-flex">
                    <div className="mw-200px px-3 font-weight-bold py-2">
                      Warehouse Name{" "}
                    </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {feedback?.warehouse?.warehouseName}{" "}
                    </div>{" "}
                  </div>
                  <div className="d-flex">
                    <div className="mw-200px px-3 font-weight-bold py-2">
                      Feedbacks{" "}
                    </div>{" "}
                    <div className="mw-300px px-3 py-2 text-gray">
                      {" "}
                      {feedback?.feedbackText}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              )}{" "}
            </div>{" "}
          </div>
          <div className="row">
            <div className="col-12 my-2">
              <span
                onClick={() => history.goBack()}
                className="btn py-2 btn-outline-deep-blue"
              >
                Back{" "}
              </span>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default FeedbackDetails;
