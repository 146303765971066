import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {useParams} from 'react-router-dom';
import { rfqByIdAndType } from "../../../store/action/rfqAction";
import { useDispatch } from "react-redux";
const RFQForm = ({ isView }) => {
  const dispatch = useDispatch();
  const [spaceRequired, setSpaceRequired] = useState(null);
  const [wareList, setWareList] = useState(null);
  const [fqId, setFqId] = useState(null);
  const params = useParams();
  const rfqData = useSelector((state) => state.RFQ_INFO);
  
  useEffect(() => {
    dispatch(rfqByIdAndType(params.customerRfqId, 'customerRfq'));
  },[params?.customerRfqId])

  useEffect(() => {
    if (rfqData?.rfqDetail?.data && (rfqData?.rfqDetail?.data != undefined && rfqData?.rfqDetail?.data !== null)) {
        console.log("** We are pushing one by one **",rfqData?.rfqDetail?.data)
        setFqId(rfqData?.rfqDetail?.data?.id);
        setSpaceRequired(rfqData?.rfqDetail?.data?.warehouseSpaceRequired);
        let whList = [];
        for (let i = 0; i < rfqData.rfqDetail.data.warehouses?.length; i++) {
          whList.push({
            value: rfqData.rfqDetail.data.warehouses[i]["id"],
            label: rfqData.rfqDetail.data.warehouses[i]["warehouseName"],
          });
        }
        setWareList(whList);
    }
  }, [rfqData?.rfqDetail?.data]);

  const submitHandle = (e) => {
    e.preventDefault();
    let wareList = [];

    let data = {
      warehouseSpaceRequired: "",
      kpi: "kpi",
      additionalRequirements: "additional reqire",
      warehouses: wareList,
    };

    // dispatch(createRFQ(data))
    console.log("data==>", data);
  };

  return (
    <>
      <form onSubmit={submitHandle}>
        <div className="row pt-2">
          <div className="col-12 px-0">
            <div className="form-group form-inline mb-3 px-0">
              <label
                htmlFor="inputPassword6"
                className="w-250px justify-content-start px-3"
              >
                RFQ ID{" "}
              </label>{" "}
              <div className="row mx-md-0 mx-sm-3 mx-0">
                <div className="col-12 px-sm-3 px-0">
                  <input
                    value={fqId}
                    type="text"
                    id="inputPassword6"
                    className="form-control d-inline-block form-control-md w-160px mx-3"
                    readOnly
                  />
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
          <div className="col-12 px-0">
            <div className="row">
              {" "}
              {/* <div className="col-auto">
                            <label htmlFor="staticEmail" className="mb-2 mt-2 w-250px justify-content-start px-3">Location</label>
                            </div> */}{" "}
              <div className="col-xl-6 col-lg-7 px-md-0 col-md-7 col-sm-6 px-3">
                <div className="row mx-0">
                  {" "}
                  {/* <div className="form-group col-auto px-3 d-inline-block form-inline mb-3">
                                <select defaultValue={`Delhi`} className="form-control form-control-lg w-160px" readOnly={isView}>
                                    <option value="0">Delhi</option>
                                    <option value="1">Mumbai</option>
                                    <option value="2">Pune</option>
                                    <option value="3">Kolkata</option>
                                </select>
                                </div> */}{" "}
                  {/* <div className="w-250px col-auto px-3 mb-3">


                                <Select
                                    value={locationHouse}
                                    onChange={handleChange}
                                    options={warehouseList}
                                    isDisabled={isView}
                                    isMulti
                                />

                                </div> */}{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
          <div className="col-12 px-0">
            <div className="form-group form-inline mb-4 px-0">
              <label
                htmlFor="inputPassword7"
                className="w-250px justify-content-start px-3"
              >
                WH Space Required{" "}
              </label>{" "}
              <div className="row mx-md-0 mx-sm-3 mx-0">
                <div className="col-12 px-sm-3 px-0">
                  <input
                    value={spaceRequired}
                    id="inputPassword7"
                    className="form-control d-inline-block form-control-md w-160px mx-3"
                    placeholder="Sqft"
                    disabled={isView}
                  />{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div className="col-12 px-0">
            <div className="form-group form-inline mb-4 px-0">
              <label
                htmlFor="inputPassword7"
                className="w-250px justify-content-start px-3"
              >
                Warehouses{" "}
              </label>{" "}
              {wareList !== null ? (
                <div className="row mx-md-0 mx-sm-3 mx-0">
                  {" "}
                  {wareList.map((item, index) => {
                    return (
                      <div key={index} className="col-12 px-sm-3 px-0 mb-1">
                        <input
                          value={item.label}
                          id={index}
                          className="form-control d-inline-block form-control-md w-160px mx-3"
                          placeholder="Sqft"
                          disabled={isView}
                        />{" "}
                      </div>
                    );
                  })}{" "}
                </div>
              ) : null}{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </form>{" "}
    </>
  );
};

export default RFQForm;
