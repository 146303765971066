import React, { useState, useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { CFormGroup, CInputCheckbox } from "@coreui/react";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import { documentUpload, documentUploadV2 } from "../../components/utils";
import {
  createService,
  serviceCategoryByPage,
  fetchSubCatByCatId,
  responseService,
} from "../../store/action/serviceAction";
import {
  onlyAlphaNumericSpaceAllow,
  forDescriptionValidation,
} from "../../components/helper/reuse";
import { Helmet } from "react-helmet";

const AddService = () => {
  const [specs, setspecs] = useState([]);
  const [fileerror, setfileerror] = useState(null);
  const removespecs = (index) => {
    const temp = [...specs];

    const filter = temp.filter((item, i) => i !== index);

    setspecs(filter);
  };
  const addspecs = (val) => {
    setspecs([
      ...specs,
      {
        name: val,
      },
    ]);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.SERVICE_INFO);

  const roleSchema = Yup.object().shape({
    categoryId: Yup.string().required("Required"),
    subcategoryId: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });
  const [file1, setfile1] = useState(null);
  // File Upload
  const [res, setRes] = useState([]);
  const [fileError, setFileError] = useState(null);
  //  const[featuredData,setFeaturedData]=useState([])
  const uploadDocs = async (e) => {
    setFileError(null);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let urlData = await documentUpload(formData);
    //  console.log(urlData)
    if (urlData && urlData.url) {
      setRes([...res, urlData.url]);
    }
  };

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service`);
  };

  useEffect(() => {
    dispatch(serviceCategoryByPage());
  }, [dispatch]);

  const callSubCategory = (id) => {
    dispatch(fetchSubCatByCatId(id));
    console.log("call Sub Cat==>", id);
  };

  //  const  file=async(val)=>{

  //   }

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Add Service </title>{" "}
      </Helmet>{" "}
      {data.serviceResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Service Added`} />
      ) : null}
      <div className="row align-items-center pb-1 px-3 mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark font-weight-bold">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left cursorPointer"
            ></i>{" "}
            Add New Services{" "}
          </h5>{" "}
        </div>
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            validationSchema={roleSchema}
            initialValues={{
              categoryId: "",
              subcategoryId: "",
              name: "",
              description: "",
              expiryDate: "",
              isActive: false,
              featuredData: [],
              features: [],
            }}
            onSubmit={(fields) => {
              if (file1 === null) {
                setfileerror("Please Upload Rfq Excel");
              }
              let setImg = [];
              if (res && res.length > 0) {
                for (let i = 0; i < res.length; i++) {
                  setImg.push({
                    imageURL: res[i],
                  });
                }
                let formData = new FormData();
                formData.append("file", file1);
                let urlData = documentUploadV2(formData);
                urlData.then((res) => {
                  fields["sampleRfq"] = res.url;
                  fields["images"] = setImg;
                  console.log("craete services", specs);

                  let isVasCat = data.categoryList.find(
                    (cat) => cat.id === fields.categoryId
                  );
                  fields["vas"] = isVasCat.vas;
                  // return 0
                  fields.serviceFeature = JSON.stringify(specs);
                  fields.expiryDate = new Date();
                  dispatch(createService(fields));
                });
              } else {
                setFileError("Please Choose image");
              }

              console.log("Fields==>", fields);
            }}
            render={({
              values,
              setFieldValue,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form className="bg-white p-3">
                  <div className="row">
                    <div className="form-group col-12 mb-2">
                      <label htmlFor="staticEmail" className="mb-2 px-3">
                        Select Category{" "}
                      </label>{" "}
                      <Field
                        name="categoryId"
                        as="select"
                        className={
                          `form-control form-control-md common-select-deep-blue rounded-pill` +
                          (errors.categoryId && touched.categoryId
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) => {
                          setFieldValue("categoryId", parseInt(e.target.value));
                          callSubCategory(parseInt(e.target.value));
                        }}
                      >
                        <option value=""> Select </option>{" "}
                        {data.categoryList && data.categoryList.length > 0
                          ? data.categoryList.map((item, index) => (
                              <option key={index} value={item?.id}>
                                {" "}
                                {item?.name}{" "}
                              </option>
                            ))
                          : null}{" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`categoryId`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group col-12 mb-2">
                      <label htmlFor="staticEmail" className="mb-2 px-3">
                        Sub Category{" "}
                      </label>{" "}
                      <Field
                        name="subcategoryId"
                        as="select"
                        className={
                          `form-control form-control-md common-select-deep-blue rounded-pill` +
                          (errors.subcategoryId && touched.subcategoryId
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) => {
                          // console.log("sdjjd",e.target.value,data.subCategoryList)
                          var catid;
                          data.subCategoryList.forEach((value) => {
                            console.log(
                              "tests",
                              value.id,
                              e.target.value,
                              parseInt(value.id) === parseInt(e.target.value)
                            );
                            if (
                              parseInt(value.id) === parseInt(e.target.value)
                            ) {
                              catid = value.serviceFeature;
                            }
                          });
                          console.log("dksks", catid);
                          setFieldValue("features", JSON.parse(catid));
                          setFieldValue("subcategoryId", e.target.value);
                          setspecs([]);
                        }}
                      >
                        <option value=""> Select </option>{" "}
                        {data.subCategoryList && data.subCategoryList.length > 0
                          ? data.subCategoryList.map((item, index) => (
                              <option value={item?.id}> {item?.name} </option>
                            ))
                          : null}{" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`subcategoryId`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group col-12 mb-2">
                      <label htmlFor="staticEmail" className="mb-2 px-3">
                        Services Name{" "}
                      </label>{" "}
                      <Field
                        onKeyPress={(e) => onlyAlphaNumericSpaceAllow(e)}
                        name="name"
                        type="text"
                        className={
                          `form-control form-control-md rounded-pill` +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                        id="staticEmail"
                        placeholder="Keyboard"
                      />
                      <ErrorMessage
                        name={`name`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group col-12 mb-2">
                      <label htmlFor="staticEmail" className="mb-2 px-3">
                        Description{" "}
                      </label>{" "}
                      <Field
                        onKeyPress={(e) => forDescriptionValidation(e)}
                        name="description"
                        type="text"
                        className={
                          `form-control form-control-md rounded-pill` +
                          (errors.description && touched.description
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Lorem ipsum dolor sit amet"
                      />
                      <ErrorMessage
                        name={`description`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {/* ========================================== */}
                    <div className="form-group col-12">
                      <label htmlFor="features" className="mb-2 px-3">
                        Features{" "}
                      </label>{" "}
                      <FieldArray name="features">
                        {" "}
                        {(fieldArrayProps) => {
                          const { form } = fieldArrayProps;
                          const { values } = form;
                          const { features } = values;
                          return (
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <table className="table border-0 mb-0">
                                <thead>
                                  <tr>
                                    <th> S.No </th> <th> </th>
                                    <th>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        {/* <label
                                                                                className="btn btn-remove"
                                                                                onClick={() => push("")}
                                                                              >
                                                                                Add More +
                                                                              </label> */}{" "}
                                      </div>{" "}
                                    </th>{" "}
                                  </tr>
                                  {features &&
                                    features.length > 0 &&
                                    features.map((priceField, index) => (
                                      <tr key={index}>
                                        <td className="mt-2"> {index + 1} </td>{" "}
                                        <td className="mt-2">
                                          <CFormGroup
                                            variant="checkbox"
                                            className="checkbox"
                                          >
                                            <CInputCheckbox
                                              style={{
                                                marginTop: "0.8em",
                                                lineHeight: "40px",
                                              }}
                                              checked={
                                                specs.findIndex(
                                                  (x) =>
                                                    x.name ===
                                                    features[index].name
                                                ) !== -1
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                specs.findIndex(
                                                  (x) =>
                                                    x.name ===
                                                    features[index].name
                                                ) !== -1
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                console.log(
                                                  "sdsd",
                                                  specs.findIndex(
                                                    (x) =>
                                                      x.name ===
                                                      features[index].name
                                                  )
                                                );
                                                if (
                                                  specs.findIndex(
                                                    (x) =>
                                                      x.name ===
                                                      features[index].name
                                                  ) === -1
                                                ) {
                                                  addspecs(
                                                    features[index].name
                                                  );
                                                } else {
                                                  removespecs(
                                                    specs.findIndex(
                                                      (x) =>
                                                        x.name ===
                                                        features[index].name
                                                    )
                                                  );
                                                }
                                              }}
                                            />{" "}
                                          </CFormGroup>{" "}
                                        </td>{" "}
                                        <td key={index}>
                                          <Field
                                            name={`features.${index}.name`}
                                            className="form-control mr-4"
                                            placeholder="Name"
                                            readOnly
                                          />
                                        </td>{" "}
                                        {/* <td key={`priceindex${index}`}>
                                                                                          <Field
                                                                                            name={`features[${index}][value]`}
                                                                                            className="form-control"
                                                                                            placeholder="Value"

                                                                                          />
                                                                                         
                                                                                        </td> */}
                                        {/* <td key={`deleteicon${index}`}>
                                                                                          <i
                                                                                            className="fas fa-trash mt-2"
                                                                                            style={{
                                                                                              color: "tomato",
                                                                                              cursor: "pointer",
                                                                                            }}
                                                                                            onClick={() =>
                                                                                             {
                                                                                              remove(index);

                                                                                             }
                                                                                            }
                                                                                          ></i>
                                                                                        </td> */}{" "}
                                      </tr>
                                    ))}{" "}
                                </thead>{" "}
                              </table>{" "}
                            </div>
                          );
                        }}{" "}
                      </FieldArray>{" "}
                    </div>{" "}
                    <div className="col-12">
                      <div className="row">
                        <div className="col-auto">
                          <div className="card card-overlay upload-image-preview position-relative">
                            {" "}
                            {res && res.length > 0 ? (
                              <div className="img-holder size-200px  border rounded-lg">
                                <img
                                  src={res[0]}
                                  className="w-100 h-100 img-fluid rounded overflow-hideen"
                                  id="imageResult"
                                  alt=""
                                />
                              </div>
                            ) : (
                              <>
                                <div className="img-holder size-200px  border rounded-lg">
                                  <img
                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                    id="imageResult"
                                    src="/assets/images/upload-bg-white.png"
                                    alt=""
                                  />
                                </div>{" "}
                                <div className="card-img-overlay size-200px d-flex justify-content-center align-items-center">
                                  <div className="card-text">
                                    <input
                                      onChange={uploadDocs}
                                      id="upload-image-preview1"
                                      type="file"
                                      accept="image/*"
                                      className="form-control border"
                                      hidden
                                    />
                                    <label
                                      id="upload-label"
                                      htmlFor="upload-image-preview1"
                                      className="font-weight-light text-muted"
                                    ></label>{" "}
                                    <div className="input-group-append">
                                      <label
                                        htmlFor="upload-image-preview1"
                                        className="btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4"
                                      >
                                        <div>
                                          <img
                                            src="/assets/images/icons/upload-icon-deep-blue.png"
                                            alt=""
                                          />
                                        </div>
                                        Upload image{" "}
                                      </label>{" "}
                                    </div>{" "}
                                  </div>{" "}
                                </div>{" "}
                              </>
                            )}{" "}
                          </div>{" "}
                          <span className="errorMsg"> {fileError} </span>{" "}
                        </div>
                        {res && res.length >= 1 ? (
                          <div className="col-auto">
                            <div className="card card-overlay upload-image-preview position-relative">
                              {" "}
                              {res && res.length > 1 ? (
                                <div className="img-holder size-200px  border rounded-lg">
                                  <img
                                    src={res[1]}
                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                    id="imageResult"
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <>
                                  <div className="img-holder size-200px  border rounded-lg">
                                    <img
                                      className="w-100 h-100 img-fluid rounded overflow-hideen"
                                      id="imageResult"
                                      src={"/assets/images/upload-bg-white.png"}
                                      alt=""
                                    />
                                  </div>{" "}
                                  <div className="card-img-overlay size-200px d-flex justify-content-center align-items-center">
                                    <div className="card-text">
                                      <input
                                        onChange={uploadDocs}
                                        id="upload-image-preview2"
                                        type="file"
                                        accept="image/*"
                                        className="form-control border-0"
                                        hidden
                                      />
                                      <label
                                        id="upload-label"
                                        htmlFor="upload-image-preview2"
                                        className="font-weight-light text-muted"
                                      ></label>{" "}
                                      <div className="input-group-append">
                                        <label
                                          htmlFor="upload-image-preview2"
                                          className="btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4"
                                        >
                                          <div>
                                            <img
                                              src="/assets/images/icons/upload-icon-deep-blue.png"
                                              alt=""
                                            />
                                          </div>
                                          Upload image{" "}
                                        </label>{" "}
                                      </div>{" "}
                                    </div>{" "}
                                  </div>{" "}
                                </>
                              )}{" "}
                            </div>{" "}
                          </div>
                        ) : null}{" "}
                      </div>{" "}
                    </div>
                    <div className="form-group col-12">
                      <label className="mt-2"> Upload RFQ xlsx </label>
                      <div class="custom-file mt-1">
                        <label class="custom-file-label" for="customFile">
                          {" "}
                          {file1 !== null ? file1.name : "Choose File"}{" "}
                        </label>{" "}
                        <Field
                          onChange={(e) => {
                            setfile1(e.target.files[0]);
                          }}
                          type="file"
                          name="file"
                          accept=".xlsx, .xls, .csv"
                          class="custom-file-input"
                          id="customFile"
                        />
                        <p
                          style={{
                            color: "red",
                          }}
                        >
                          {" "}
                          {fileerror}{" "}
                        </p>{" "}
                        <ErrorMessage
                          name={`file`}
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <button
                        type="submit"
                        disabled={data.isPending}
                        className={`btn btn-deep-blue toggle-class ${
                          !read ? "" : "d-none"
                        }`}
                      >
                        Add{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default AddService;
