import React from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";

const FieldAgentPendingList = ({ item, index }) => {
  const { id, warehouseName, category, created_at, fieldAgaintStatus } = item;
  return (
    <tr>
      <td className="text-center"> {index} </td>{" "}
      <td> {readableDate(created_at)} </td>{" "}
      <td> {item.warehouseId ? item.warehouseId : "-"} </td>{" "}
      <td> {warehouseName} </td> <td> {category?.categoryName} </td>{" "}
      <td> {fieldAgaintStatus} </td>{" "}
      <td className="text-center">
        {" "}
        <Link
          to={`/field-agent-pending/${id}`}
          className="btn px-3 text-nowrap font-weight-bold text-deep-blue text-uppercase"
        >
          {" "}
          View Details{" "}
        </Link>
      </td>
    </tr>
  );
};

export default FieldAgentPendingList;
