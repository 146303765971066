import React, { useEffect, useState } from "react";
import { readableDate } from "../../components/helper/reuse";
import { confirmAlert } from "react-confirm-alert";
import {
  updateCategory,
  deleteCategory,
} from "../../store/action/categoryAction";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const CategoryList = ({ item, read, index }) => {
  const { id, categoryName, created_at, inactiveDate, categoryStatus, image } =
    item;

  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(null);

  useEffect(() => {
    setIsActive(categoryStatus);
  }, [categoryStatus]);

  // Status Change confirmation
  const statusChange = (isActive) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to change status.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              updateCategory(
                {
                  categoryName: categoryName,
                  categoryStatus: isActive,
                },
                id
              )
            ),
        },
        {
          label: "No",
          onClick: () => setIsActive(!isActive),
        },
      ],
    });
  };

  // Status Change confirmation
  const deleteChange = (id, categoryStatus) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to Delete Category",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            if (categoryStatus) {
              alert("Active category can not be deleted");
              return 0;
            }
            dispatch(deleteCategory(id, categoryStatus));
          },
        },
        {
          label: "No",
          onClick: () => setIsActive(!isActive),
        },
      ],
    });
  };

  const haldleChange = (e) => {
    setIsActive(e.target.value === "true");
    statusChange(e.target.value === "true");
  };

  return (
    <>
      <tr>
        <td className="text-center py-2"> {index} </td>{" "}
        <td className="text-nowrap"> {categoryName} </td>{" "}
        <td className="text-nowrap"> {readableDate(created_at)} </td>{" "}
        <td className="text-nowrap">
          <img
            src={image}
            alt="category"
            className="py-1"
            style={{
              height: "60px",
            }}
          />{" "}
        </td>{" "}
        <td className="text-nowrap">
          {" "}
          {inactiveDate ? (
            <div className="font-weight-bold">
              {" "}
              {readableDate(inactiveDate)}{" "}
            </div>
          ) : null}{" "}
        </td>{" "}
        <td className="text-nowrap">
          <div className="d-inline-block">
            <select
              name="status"
              onChange={haldleChange}
              value={isActive}
              className={`custom-select py-0 common-select-deep-blue ${
                !read ? "" : "d-none"
              }`}
            >
              <option value={true}> Active </option>{" "}
              <option value={false}> Inactive </option>{" "}
            </select>{" "}
          </div>{" "}
        </td>{" "}
        <td className="text-nowrap">
          <i
            onClick={() => deleteChange(id, categoryStatus)}
            className={`fas fa-trash-alt text-dark ${!read ? "" : "d-none"}`}
          >
            {" "}
          </i>{" "}
        </td>{" "}
        <td className="text-nowrap">
          <Link
            to={`update-category/${id}`}
            className={`${!read ? "" : "d-none"}`}
          >
            <i className="far fa-edit"> </i>{" "}
          </Link>{" "}
        </td>
      </tr>
    </>
  );
};

export default CategoryList;
