import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteById } from "../../store/action/whyAction";

const WhyList = ({ item, index, read }) => {
  const dispatch = useDispatch();

  return (
    <tr>
      <td className="text-center"> {index}. </td> <td> {item.title} </td>{" "}
      <td> {item.description.substring(0, 70)}... </td>{" "}
      <td>
        {" "}
        <img
          src={item.image}
          style={{
            width: 100,
            height: 50,
          }}
          alt="why"
        />{" "}
      </td>{" "}
      <td
        className="text-center"
        style={{
          width: "20%",
        }}
      >
        <Link
          to={`/update-why/${item.id}`}
          className={`btn font-weight-bold px-1 py-0 text-danger ${
            !read ? "" : "d-none"
          }`}
        >
          {" "}
          <i className="fas fa-edit"> </i>
        </Link>
        <button
          onClick={() => dispatch(deleteById(item.id))}
          className={`btn font-weight-bold px-1 py-0 text-danger ${
            !read ? "" : "d-none"
          }`}
        >
          {" "}
          <i className="fas fa-trash text-dark"> </i>
        </button>
        <Link
          to={`/why-detail/${item.id}`}
          className="btn font-weight-bold px-1 py-0"
        >
          {" "}
          <i className="fas fa-eye"> </i>
        </Link>
      </td>{" "}
    </tr>
  );
};

export default WhyList;
