import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { readableDate } from "../../components/helper/reuse";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { updateService } from "../../store/action/serviceAction";

const ServiceList = ({ item, index, read }) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(null);

  // Status Change confirmation
  const statusChange = (isActive) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to change status.",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              updateService(item.id, {
                active: isActive,
              })
            ),
        },
        {
          label: "No",
          onClick: () => setIsActive(!isActive),
        },
      ],
    });
  };

  useEffect(() => {
    setIsActive(item?.active);
  }, [item]);

  const handleChange = (e) => {
    setIsActive(e.target.value === "true");
    statusChange(e.target.value === "true");
  };

  return (
    <tr>
      <td> {index} </td> <td> {readableDate(item?.createdAt)} </td>{" "}
      <td className="text-nowrap"> {item?.category?.name} </td>{" "}
      <td className="text-nowrap"> {item?.subcategory?.name} </td>{" "}
      <td className="text-nowrap"> {item?.name} </td>{" "}
      {/* <td>{item?.description.substring(0, 25)}...</td> */}{" "}
      <td>
        <select
          disabled={read}
          name="status"
          onChange={handleChange}
          value={isActive}
          className="form-control form-control-md custom-select bg-white common-select-deep-blue border-0 w-90px"
        >
          <option value={true}> Active </option>{" "}
          <option value={false}> Inactive </option>{" "}
        </select>{" "}
      </td>{" "}
      <td className="text-center text-nowrape d-flex">
        <Link to={`/service/${item.id}`} className="btn p-1">
          <i className="fas fa-eye"> </i>{" "}
        </Link>{" "}
        {!read ? (
          <Link
            to={`/service-edit/${item.id}`}
            className="btn p-1 btn-link btn-link-deep-blue font-weight-bold"
          >
            Edit{" "}
          </Link>
        ) : (
          <span className="btn px-2"> Not Allow </span>
        )}{" "}
      </td>{" "}
    </tr>
  );
};

export default ServiceList;
