import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LinkButton = ({ link, label }) => {
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  return (
    <div className="col-auto d-flex h-90 px-2">
      <Link
        to={link}
        className={`custom-btn btn-deep-blue ${!read ? "" : "d-none"}`}
      >
        {" "}
        {label}{" "}
      </Link>{" "}
    </div>
  );
};

export default LinkButton;
