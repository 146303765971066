import React from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Link } from "react-router-dom";

const OpenRFQResponseView = () => {
  return (
    <LayoutOne>
      <div className="row align-items-center py-3 px-3 mx-0">
        <div className="col-12 py-3 px-0">
          <h4 className="text-dark-blue font-weight-bold">
            {" "}
            View Open RFQ 's Response
          </h4>{" "}
        </div>
        <div className="col-12 px-0 table-responsive table-row-margin-bottom-admin">
          <table className="table">
            <thead>
              <tr>
                <th className="text-nowrap"> Document Name </th>{" "}
                <th className="text-nowrap text-capitalize"> Shared By </th>{" "}
                <th className="text-nowrap text-capitalize"> Shared To </th>{" "}
                <th className="text-nowrap text-capitalize"> On Date </th>{" "}
                <th className="text-nowrap mw-250px text-capitalize">
                  {" "}
                  Purpose{" "}
                </th>{" "}
                <th className="text-center"> </th>{" "}
              </tr>{" "}
            </thead>{" "}
            <tbody>
              <tr>
                <td>Customer RFQ </td> <td> Ravish </td> <td> Sales </td>{" "}
                <td> 12.01 .2021 </td>{" "}
                <td>
                  <div> Request For Warehouse </div>{" "}
                  <div> (customer to warehousity) </div>{" "}
                </td>{" "}
                <td>
                  <Link
                    to={`/manage-rfq/${"rfdhfgdhfgdqId"}`}
                    className="btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1"
                  >
                    View Document{" "}
                  </Link>{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                <td>Base Rate matrix + Manpower Matrix Form </td>{" "}
                <td> Ravish </td> <td> Sales </td> <td> 12.01 .2021 </td>{" "}
                <td>(Warehousity to Vendor) </td>{" "}
                <td>
                  <Link
                    to={`/manage-rfq/${"rfdhfgdhfgdqId"}`}
                    className="btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1"
                  >
                    View Document{" "}
                  </Link>{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                <td>RFQ Display </td> <td> Ravish </td> <td> Sales </td>{" "}
                <td> 12.01 .2021 </td> <td>(Vendor to Warehousity) </td>{" "}
                <td>
                  <Link
                    to={`/manage-rfq/${"rfdhfgdhfgdqId"}`}
                    className="btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1"
                  >
                    View Document{" "}
                  </Link>{" "}
                </td>{" "}
              </tr>{" "}
              <tr>
                <td>RFQ Display </td> <td> Sales </td> <td> Ravish </td>{" "}
                <td> 12.01 .2021 </td> <td>(Warehousity to Customer) </td>{" "}
                <td>
                  <Link
                    to={`/manage-rfq/${"rfdhfgdhfgdqId"}`}
                    className="btn btn-block btn-line btn-line-deep-blue text-nowrap px-0 rounded-0 py-1"
                  >
                    View Document{" "}
                  </Link>{" "}
                </td>{" "}
              </tr>{" "}
            </tbody>{" "}
          </table>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default OpenRFQResponseView;
