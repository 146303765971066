import React from "react";

const WHSReportList = ({ item, index }) => {
  return (
    <tr>
      <td className="py-1">
        {" "}
        <div className="cell"> {index + 1} </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> {item?.warehouse?.id} </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> {item?.warehouse?.warehouseName} </div>
      </td>{" "}
      {/* <td className="py-1"><div className="cell">{item?.warehouse?.category}</div></td> */}{" "}
      {/* <td className="py-1"><div className="cell">{item?.warehouse?.type}</div></td> */}{" "}
      {/* <td className="py-1"><div className="cell">{item?.warehouse?.location}</div></td> */}{" "}
      {/* <td className="py-1"><div className="cell">{item?.warehouse?.location}</div></td> */}{" "}
      <td className="py-1">
        {" "}
        <div className="cell"> 781035 </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> {item?.warehouse?.totalArea} </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> 4500 </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> 2019-09-25 </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell">
          {" "}
          {item?.warehouse?.users[0].firstName +
            " " +
            item?.warehouse?.users[0].lastName}{" "}
        </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> {item?.warehouse?.users[0].email} </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> {item?.warehouse?.users[0].phone} </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> 2019-09-27 </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> Naveen Rana </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> naveen @rana.com </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> 9876543210 </div>
      </td>
      <td className="py-1">
        {" "}
        <div className="cell"> {item?.warehouse?.users[0].companyName} </div>
      </td>
      <td className="py-1">
        <div className="cell text-green"> {item?.warehouse?.adminStatus} </div>{" "}
      </td>{" "}
    </tr>
  );
};

export default WHSReportList;
