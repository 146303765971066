import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateMaterialType,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";

const MaterialPendingForm = ({ warehouseId, viewMood }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const validationSchema = function (values) {
    return Yup.object().shape({
      dryMaterial: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      wetMaterial: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      agriAndAlliedIndustries: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      chemicals: Yup.array().of(
        Yup.object().shape({
          chemical: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      autoMobiles: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      autoComponents: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      aviation: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      educationRelated: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      consumerDurables: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      ecommerce: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      engineeringGoods: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      fcmg: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      healthAndPharma: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      itAndItes: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      manufacturing: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      mediaAndEntertainment: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      oilAndGas: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      power: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      renewableEnergy: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      retail: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      scienceAndTechnology: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      steel: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };
  const [materialForm, setMaterialForm] = useState({
    dryMaterial: true,
    wetMaterial: true,
    agriAndAlliedIndustries: true,
    autoMobiles: true,
    autoComponents: true,
    aviation: true,
    chemicalDry: true,
    chemicalWet: true,
    consumerDurables: true,
    ecommerce: true,
    educationRelated: true,
    engineeringGood: true,
    fmcg: true,
    healthAndPharma: true,
    itAndItes: true,
    manufacturing: true,
    entertainment: true,
    oilAndGas: true,
    power: true,
    energy: true,
    retail: true,
    scienceAndTechnology: true,
    steel: true,
    telecommunication: true,
    texTiles: true,
    tourism: true,
    prominentCustomersInTheWarehouse: "",
  });
  const {
    dryMaterial,
    wetMaterial,
    agriAndAlliedIndustries,
    autoMobiles,
    autoComponents,
    aviation,
    chemicalDry,
    chemicalWet,
    consumerDurables,
    ecommerce,
    educationRelated,
    engineeringGood,
    fmcg,
    healthAndPharma,
    itAndItes,
    manufacturing,
    entertainment,
    oilAndGas,
    power,
    energy,
    retail,
    scienceAndTechnology,
    steel,
    telecommunication,
    texTiles,
    tourism,
    prominentCustomersInTheWarehouse,
  } = materialForm;
  const [remarkForm, setRemarkForm] = useState({
    dryMaterial: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    wetMaterial: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    agriAndAlliedIndustries: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    autoMobiles: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    autoComponents: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    aviation: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    chemicals: [
      {
        type: "dryup",
        chemical: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "wetup",
        chemical: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    consumerDurables: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    ecommerce: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    educationRelated: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    engineeringGoods: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    fmcg: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    healthAndPharma: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    itAndItes: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    manufacturing: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    mediaAndEntertainment: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    oilAndGas: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    power: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    renewableEnergy: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    retail: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    scienceAndTechnology: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    steel: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    telecommunication: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    textTiles: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    tourismAndHospitality: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    prominentCustomersInTheWarehouse: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });
  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        materialTypeRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateMaterialType(payload));
  };

  useEffect(() => {
    if (
      data.singleFormData &&
      data.singleFormData.materialTypeInfo &&
      data.type === "materialType"
    ) {
      let materialInfo = data.singleFormData.materialTypeInfo;
      setMaterialForm({
        dryMaterial: materialInfo ? materialInfo.dryMaterial : true,
        wetMaterial: materialInfo ? materialInfo.wetMaterial : true,
        agriAndAlliedIndustries: materialInfo
          ? materialInfo.agriAndAlliedIndustries
          : true,
        autoMobiles: materialInfo ? materialInfo.autoMobiles : true,
        autoComponents: materialInfo ? materialInfo.autoComponents : true,
        aviation: materialInfo ? materialInfo.aviation : true,
        chemicalDry: materialInfo
          ? materialInfo.chemicals
            ? materialInfo.chemicals[0].active
            : ""
          : "",
        chemicalWet: materialInfo
          ? materialInfo.chemicals
            ? materialInfo.chemicals[1].active
            : ""
          : "",
        consumerDurables: materialInfo ? materialInfo.consumerDurables : true,
        ecommerce: materialInfo ? materialInfo.ecommerce : true,
        educationRelated: materialInfo ? materialInfo.educationRelated : true,
        engineeringGood: materialInfo ? materialInfo.engineeringGoods : true,
        fmcg: materialInfo ? materialInfo.fmcg : true,
        healthAndPharma: materialInfo ? materialInfo.healthAndPharma : true,
        itAndItes: materialInfo ? materialInfo.itAndItes : true,
        manufacturing: materialInfo ? materialInfo.manufacturing : true,
        entertainment: materialInfo ? materialInfo.mediaAndEntertainment : true,
        oilAndGas: materialInfo ? materialInfo.oilAndGas : true,
        power: materialInfo ? materialInfo.power : true,
        energy: materialInfo ? materialInfo.renewableEnergy : true,
        retail: materialInfo ? materialInfo.retail : true,
        scienceAndTechnology: materialInfo
          ? materialInfo.scienceAndTechnology
          : true,
        steel: materialInfo ? materialInfo.steel : true,
        telecommunication: materialInfo ? materialInfo.telecommunication : true,
        texTiles: materialInfo ? materialInfo.textTiles : true,
        tourism: materialInfo ? materialInfo.tourismAndHospitality : true,
        prominentCustomersInTheWarehouse: materialInfo
          ? materialInfo.prominentCustomersInTheWarehouse
          : "",
      });

      if (data.singleFormData && data.singleFormData.materialTypeRemark) {
        setRemarkForm({
          dryMaterial: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.dryMaterial.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.dryMaterial.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.dryMaterial.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.dryMaterial.faremark
              : "",
          },
          wetMaterial: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.wetMaterial.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.wetMaterial.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.wetMaterial.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.wetMaterial.faremark
              : "",
          },
          agriAndAlliedIndustries: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.agriAndAlliedIndustries
                  .whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.agriAndAlliedIndustries
                  .whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.agriAndAlliedIndustries
                  .fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.agriAndAlliedIndustries
                  .faremark
              : "",
          },
          autoMobiles: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.autoMobiles.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.autoMobiles.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.autoMobiles.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.autoMobiles.faremark
              : "",
          },
          autoComponents: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.autoComponents.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.autoComponents.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.autoComponents.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.autoComponents.faremark
              : "",
          },
          aviation: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.aviation.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.aviation.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.aviation.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.aviation.faremark
              : "",
          },
          chemicals: [
            {
              type: "dryup",
              chemical: {
                value: "",

                whsstatus: data.singleFormData.materialTypeRemark
                  ? data.singleFormData.materialTypeRemark.chemicals[0]
                    ? data.singleFormData.materialTypeRemark.chemicals[0]
                        .chemical.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.materialTypeRemark
                  ? data.singleFormData.materialTypeRemark.chemicals[0]
                    ? data.singleFormData.materialTypeRemark.chemicals[0]
                        .chemical.whsremark
                    : ""
                  : "",

                fastatus: data.singleFormData.materialTypeRemark
                  ? data.singleFormData.materialTypeRemark.chemicals[0]
                    ? data.singleFormData.materialTypeRemark.chemicals[0]
                        .chemical.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.materialTypeRemark
                  ? data.singleFormData.materialTypeRemark.chemicals[0]
                    ? data.singleFormData.materialTypeRemark.chemicals[0]
                        .chemical.faremark
                    : ""
                  : "",
              },
            },
            {
              type: "wetup",
              chemical: {
                value: "",

                whsstatus: data.singleFormData.materialTypeRemark
                  ? data.singleFormData.materialTypeRemark.chemicals[1]
                    ? data.singleFormData.materialTypeRemark.chemicals[1]
                        .chemical.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.materialTypeRemark
                  ? data.singleFormData.materialTypeRemark.chemicals[1]
                    ? data.singleFormData.materialTypeRemark.chemicals[1]
                        .chemical.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.materialTypeRemark
                  ? data.singleFormData.materialTypeRemark.chemicals[1]
                    ? data.singleFormData.materialTypeRemark.chemicals[1]
                        .chemical.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.materialTypeRemark
                  ? data.singleFormData.materialTypeRemark.chemicals[1]
                    ? data.singleFormData.materialTypeRemark.chemicals[1]
                        .chemical.faremark
                    : ""
                  : "",
              },
            },
          ],
          consumerDurables: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.consumerDurables
                  .whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.consumerDurables
                  .whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.consumerDurables.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.consumerDurables.faremark
              : "",
          },
          ecommerce: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.ecommerce.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.ecommerce.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.ecommerce.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.ecommerce.faremark
              : "",
          },
          educationRelated: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.educationRelated
                  .whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.educationRelated
                  .whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.educationRelated.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.educationRelated.faremark
              : "",
          },
          engineeringGoods: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.engineeringGoods
                  .whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.engineeringGoods
                  .whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.engineeringGoods.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.engineeringGoods.faremark
              : "",
          },
          fmcg: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.fmcg.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.fmcg.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.fmcg.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.fmcg.faremark
              : "",
          },
          healthAndPharma: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.healthAndPharma.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.healthAndPharma.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.healthAndPharma.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.healthAndPharma.faremark
              : "",
          },
          itAndItes: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.itAndItes.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.itAndItes.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.itAndItes.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.itAndItes.faremark
              : "",
          },
          manufacturing: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.manufacturing.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.manufacturing.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.manufacturing.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.manufacturing.faremark
              : "",
          },
          mediaAndEntertainment: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.mediaAndEntertainment
                  .whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.mediaAndEntertainment
                  .whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.mediaAndEntertainment
                  .fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.mediaAndEntertainment
                  .faremark
              : "",
          },
          oilAndGas: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.oilAndGas.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.oilAndGas.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.oilAndGas.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.oilAndGas.faremark
              : "",
          },
          power: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.power.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.power.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.power.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.power.faremark
              : "",
          },
          renewableEnergy: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.renewableEnergy.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.renewableEnergy.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.renewableEnergy.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.renewableEnergy.faremark
              : "",
          },
          retail: {
            value: "",
            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.retail.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.retail.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.retail.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.retail.faremark
              : "",
          },
          scienceAndTechnology: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.scienceAndTechnology
                  .whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.scienceAndTechnology
                  .whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.scienceAndTechnology
                  .fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.scienceAndTechnology
                  .faremark
              : "",
          },
          steel: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.steel.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.steel.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.steel.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.steel.faremark
              : "",
          },
          telecommunication: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.telecommunication
                  .whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.telecommunication
                  .whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.telecommunication
                  .fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.telecommunication
                  .faremark
              : "",
          },
          textTiles: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.textTiles.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.textTiles.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.textTiles.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.textTiles.faremark
              : "",
          },
          tourismAndHospitality: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.tourismAndHospitality
                  .whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.tourismAndHospitality
                  .whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.tourismAndHospitality
                  .fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark.tourismAndHospitality
                  .faremark
              : "",
          },
          prominentCustomersInTheWarehouse: {
            value: "",

            whsstatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark
                  .prominentCustomersInTheWarehouse.whsstatus
              : "",
            whsremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark
                  .prominentCustomersInTheWarehouse.whsremark
              : "",
            fastatus: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark
                  .prominentCustomersInTheWarehouse.fastatus
              : "",
            faremark: data.singleFormData.materialTypeRemark
              ? data.singleFormData.materialTypeRemark
                  .prominentCustomersInTheWarehouse.faremark
              : "",
          },
        });
      } else {
        setRemarkForm({
          dryMaterial: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          wetMaterial: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          agriAndAlliedIndustries: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          autoMobiles: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          autoComponents: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          aviation: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          chemicals: [
            {
              type: "dryup",
              chemical: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              type: "wetup",
              chemical: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
          ],
          consumerDurables: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          ecommerce: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          educationRelated: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          engineeringGoods: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          fmcg: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          healthAndPharma: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          itAndItes: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          manufacturing: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          mediaAndEntertainment: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          oilAndGas: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          power: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          renewableEnergy: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          retail: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          scienceAndTechnology: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          steel: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          telecommunication: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          textTiles: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          tourismAndHospitality: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          prominentCustomersInTheWarehouse: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form className="w-100">
              <div className="col-12 rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <div className="col-xl-12 table-hight-custom mb-3">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              Okay{" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              Not Okay{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Remarks{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Field Agent Remarks{" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Dry Material:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {dryMaterial ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="dryMaterial.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aa"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.dryMaterial.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "dryMaterial.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aa"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="dryMaterial.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naa"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.dryMaterial.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "dryMaterial.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naa"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`dryMaterial.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.dryMaterial.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.dryMaterial.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Wet Material:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {wetMaterial ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="wetMaterial.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaa"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.wetMaterial.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "wetMaterial.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaa"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="wetMaterial.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naaaa"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.wetMaterial.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "wetMaterial.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naaaa"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`wetMaterial.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.wetMaterial.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.wetMaterial.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Agri & amp; Allied Industries:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {agriAndAlliedIndustries
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="agriAndAlliedIndustries.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabb"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.agriAndAlliedIndustries
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "agriAndAlliedIndustries.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabb"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="agriAndAlliedIndustries.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabb"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.agriAndAlliedIndustries
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "agriAndAlliedIndustries.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabb"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`agriAndAlliedIndustries.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.agriAndAlliedIndustries.fastatus ===
                                  true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.agriAndAlliedIndustries.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Automobiles:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {autoMobiles ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="autoMobiles.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbcc"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.autoMobiles.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "autoMobiles.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbcc"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="autoMobiles.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbcc"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.autoMobiles.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "autoMobiles.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbcc"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`autoMobiles.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.autoMobiles.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.autoMobiles.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Auto Components:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {autoComponents ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="autoComponents.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccdd"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.autoComponents.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "autoComponents.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccdd"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="autoComponents.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccdd"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.autoComponents.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "autoComponents.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccdd"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`autoComponents.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.autoComponents.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.autoComponents.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Aviation:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {aviation ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="aviation.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccddee"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.aviation.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "aviation.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccddee"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="aviation.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccddee"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.aviation.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "aviation.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccddee"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`aviation.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.aviation.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.aviation.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Chemicals(Dry):
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {chemicalDry ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="chemicals[0].chemical.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccddeeff"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.chemicals
                                          ? values.chemicals[0].chemical
                                              .whsstatus === true
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "chemicals[0].chemical.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccddeeff"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="chemicals[0].chemical.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccddeeff"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.chemicals
                                          ? values.chemicals[0].chemical
                                              .whsstatus === false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "chemicals[0].chemical.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccddeeff"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`chemicals[0].chemical.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.chemicals[0] &&
                                  values.chemicals[0].chemical.fastatus
                                    ? "okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.chemicals[0] &&
                                    values.chemicals[0].chemical.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Chemicals(Wet):
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {chemicalWet ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="chemicals[1].chemical.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccddeeffgg"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.chemicals
                                          ? values.chemicals[1].chemical
                                              .whsstatus === true
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "chemicals[1].chemical.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccddeeffgg"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="chemicals[1].chemical.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccddeeffgg"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.chemicals
                                          ? values.chemicals[1].chemical
                                              .whsstatus === false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "chemicals[1].chemical.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccddeeffgg"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`chemicals[1].chemical.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.chemicals[1] &&
                                  values.chemicals[1].chemical.fastatus
                                    ? "okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.chemicals[1] &&
                                    values.chemicals[1].chemical.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Consumer Durables:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {consumerDurables ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="consumerDurables.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccddaee"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.consumerDurables.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "consumerDurables.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccddaee"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="consumerDurables.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccddqee"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.consumerDurables.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "consumerDurables.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccddqee"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`consumerDurables.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.consumerDurables.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.consumerDurables.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Ecommerce:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {ecommerce ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="ecommerce.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccddaeenn"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.ecommerce.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "ecommerce.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccddaeenn"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="consumerDurables.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccddqee"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.consumerDurables.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "consumerDurables.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccddqee"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`ecommerce.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.consumerDurables.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.ecommerce.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Education Related:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {educationRelated ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="educationRelated.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccddaeenan"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.educationRelated.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "educationRelated.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccddaeenan"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="educationRelated.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccddqeenan"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.educationRelated.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "educationRelated.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccddqeenan"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`educationRelated.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.educationRelated.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.educationRelated.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Engineering Goods:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {engineeringGood ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="engineeringGoods.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccddaeenans"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.engineeringGoods.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "engineeringGoods.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccddaeenans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="engineeringGoods.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccddqeenans"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.engineeringGoods.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "engineeringGoods.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccddqeenans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`engineeringGoods.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.engineeringGoods.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.engineeringGoods.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        FMCG:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {fmcg ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fmcg.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aabbccddaeexnans"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={values.fmcg.whsstatus === true}
                                      onChange={() =>
                                        setFieldValue("fmcg.whsstatus", true)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fmcg.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="naabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={values.fmcg.whsstatus === false}
                                      onChange={() =>
                                        setFieldValue("fmcg.whsstatus", false)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="naabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`fmcg.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.fmcg.fastatus === true
                                    ? " okay"
                                    : " not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.fmcg.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Health & amp; Pharma:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {healthAndPharma ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="healthAndPharma.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.healthAndPharma.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "healthAndPharma.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="healthAndPharma.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      hidden="true"
                                      id="aanaabbccddqeexnans"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.healthAndPharma.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "healthAndPharma.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`healthAndPharma.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.healthAndPharma.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.healthAndPharma.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        IT & amp; ITES:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {itAndItes ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itAndItes.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaabbccddaeexnans"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itAndItes.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itAndItes.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itAndItes.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itAndItes.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itAndItes.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`itAndItes.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.itAndItes.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.itAndItes.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Manufacturing:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {manufacturing ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="manufacturing.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaabbccddaeexnans"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.manufacturing.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "manufacturing.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="manufacturing.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.manufacturing.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "manufacturing.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-4">
                                    <Field
                                      disabled={viewMood}
                                      name={`manufacturing.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center pt-2">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.manufacturing.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.manufacturing.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Media & amp; Entertainment:
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {entertainment ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="mediaAndEntertainment.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.mediaAndEntertainment
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "mediaAndEntertainment.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="mediaAndEntertainment.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.mediaAndEntertainment
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "mediaAndEntertainment.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`mediaAndEntertainment.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.mediaAndEntertainment.fastatus ===
                                  true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.mediaAndEntertainment.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Oil & amp; Gas:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {oilAndGas ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="oilAndGas.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.oilAndGas.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "oilAndGas.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="oilAndGas.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.oilAndGas.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "oilAndGas.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`oilAndGas.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline pt-4">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.oilAndGas.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.oilAndGas.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Power:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {power ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="power.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={values.power.whsstatus === true}
                                      onChange={() =>
                                        setFieldValue("power.whsstatus", true)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="power.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={values.power.whsstatus === false}
                                      onChange={() =>
                                        setFieldValue("power.whsstatus", false)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`power.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.power.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.power.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Renewable Energy:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {energy ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="renewableEnergy.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.renewableEnergy.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "renewableEnergy.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="renewableEnergy.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.renewableEnergy.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "renewableEnergy.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`renewableEnergy.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.renewableEnergy.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.renewableEnergy.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Renewable Energy:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {energy ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="renewableEnergy.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.renewableEnergy.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "renewableEnergy.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="renewableEnergy.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.renewableEnergy.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "renewableEnergy.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`renewableEnergy.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.renewableEnergy.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.renewableEnergy.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Retail:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {retail ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="retail.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={values.retail.whsstatus === true}
                                      onChange={() =>
                                        setFieldValue("retail.whsstatus", true)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="retail.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.retail.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue("retail.whsstatus", false)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`retail.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.retail.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.retail.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Science & amp; Technology:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {scienceAndTechnology
                                          ? "Yes"
                                          : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="scienceAndTechnology.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="asaaaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.scienceAndTechnology
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "scienceAndTechnology.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="asaaaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="scienceAndTechnology.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="asaaaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.scienceAndTechnology
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "scienceAndTechnology.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="asaaaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`scienceAndTechnology.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.scienceAndTechnology.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.scienceAndTechnology.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Steel:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {steel ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="steel.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={values.steel.whsstatus === true}
                                      onChange={() =>
                                        setFieldValue("steel.whsstatus", true)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="steel.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={values.steel.whsstatus === false}
                                      onChange={() =>
                                        setFieldValue("steel.whsstatus", false)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`steel.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.steel.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.steel.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Telecommunication:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {telecommunication ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="telecommunication.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.telecommunication.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "telecommunication.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="telecommunication.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.telecommunication.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "telecommunication.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`telecommunication.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.telecommunication.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.telecommunication.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Textiles:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {texTiles ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="textTiles.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.textTiles.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "textTiles.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="textTiles.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.textTiles.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "textTiles.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`textTiles.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.textTiles.fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.textTiles.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Tourism & amp; Hospitality:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {tourism ? "Yes" : "No"}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100"> </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="tourismAndHospitality.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.tourismAndHospitality
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "tourismAndHospitality.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="tourismAndHospitality.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaaaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.tourismAndHospitality
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "tourismAndHospitality.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`tourismAndHospitality.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.tourismAndHospitality.fastatus ===
                                  true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.tourismAndHospitality.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12 table-hight-custom">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Prominent Customers in the Warehouse:
                                      </label>{" "}
                                      <p className="text-gray  px-2 mb-2">
                                        {" "}
                                        {/* {tourism ? "Yes" : "No"} */}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <input
                                        value={prominentCustomersInTheWarehouse}
                                        className="form-control"
                                        type="text"
                                        name="spaceprovidertypeOptions"
                                        id="yes2"
                                        disabled
                                      />
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="prominentCustomersInTheWarehouse.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aaaaaaaaaaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.prominentCustomersInTheWarehouse
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "prominentCustomersInTheWarehouse.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaasaaaaaaaaaaaaaaaaabbccddaeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="prominentCustomersInTheWarehouse.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="aaaaaaaaaaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.prominentCustomersInTheWarehouse
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "prominentCustomersInTheWarehouse.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aaaaaaaaaaasaaaaaaaaaaaaaaanaabbccddqeexnans"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`prominentCustomersInTheWarehouse.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 align-items-center ">
                              <td className="d-flex px-0 align-items-baseline">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.prominentCustomersInTheWarehouse
                                    .fastatus === true
                                    ? "   okay"
                                    : "   not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.prominentCustomersInTheWarehouse
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                  </div>{" "}
                </div>
                <div className={`col-12 mt-4`}>
                  <div className="row justify-content-end mb-4"> </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default MaterialPendingForm;
