import React from "react";
import ExpandButton from "./ExpandButton";
import { useState } from "react";

const SearchBox = ({ callSearch, setSearchHandler, search, border }) => {
  // const[searchName,setSearchName] = useState("");
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("hi");
      //  console.log(searchName)
      callSearch();
    }
  };

  return (
    <div className="d-flex">
      <div className={`px-2 my-2 col bg-white ${border ? "border" : ""}`}>
        <div className="input-group prepend w-100 rounded">
          <input
            type="text"
            onChange={(e) => {
              setSearchHandler(e.target.value);
              // setSearchName(e.target.value);
            }}
            className="form-control border-0"
            placeholder={search ? search : "Search..."}
            onKeyDown={handleKeyDown}
            // style={{ height: 40 }}
          />
          <div className="input-group-prepend border-0">
            <span className="input-group-text bg-white py-0 border-0">
              <button
                onClick={callSearch}
                className="btn btn-lighter-primary p-0 size-30px border-0"
              >
                <i className="fas fa-search"> </i>{" "}
              </button>{" "}
            </span>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <ExpandButton />
    </div>
  );
};

export default SearchBox;
