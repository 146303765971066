import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import FormSuccess from '../../components/common/FormSuccess';
import { makeStyles } from '@material-ui/core/styles';
// import WarehousePendingForm from '../../wrapper/warehouseManagement/pendingForms/WarehousePendingForm';
import ContactPendingForm from '../../wrapper/warehouseManagement/pendingForms/ContactPendingForm';
import SpacePendingForm from '../../wrapper/warehouseManagement/pendingForms/SpacePendingForm';
import OfficePendingForm from '../../wrapper/warehouseManagement/pendingForms/OfficePendingForm';
import MhPendingForm from '../../wrapper/warehouseManagement/pendingForms/MhPendingForm';
import SafetyPendingForm from '../../wrapper/warehouseManagement/pendingForms/SafetyPendingForm';
import PermitPendingForm from '../../wrapper/warehouseManagement/pendingForms/PermitPendingForm';
import MaterialPendingForm from '../../wrapper/warehouseManagement/pendingForms/MaterialPendingForm';
import PhotoPendingForm from '../../wrapper/warehouseManagement/pendingForms/PhotoPendingForm';
import EightyPendingForm from '../../wrapper/warehouseManagement/pendingForms/EightyPendingForm';
import axiosauth from '../../services/axios-auth';
import {
  // fetchWarehouseByIdAndType,
  // WarehouseByIdType,
  changeWarehouseStatus1,
  fetchWarehouseByIdAndTypePending,
  updateWarehouseStatusResubmitted,
  changeWarehouseStatus,
  updateWarehouseStatusAdminApproved,
  clearSingleDataState,
} from '../../store/action/warehouseAction';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from 'react-bootstrap/Spinner';

import { CssBaseline, Container, Paper, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
// import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { FormProvider } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Typography, TextField, Button } from '@material-ui/core';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import LayoutOne from '../../layout/LayoutOne';
// import { useHistory, useParams } from 'react-router-dom';

//linear stepper outer function
const steps = [
  'Contact detail',
  'Storage space',
  'IT Infra',
  'MH Infra',
  'Safety Info',
  'Permits',
  'Material Type',
  'Warehouse Photo',
  'Form 80',
];
const RoutesStep = steps.map((elem, index) => {
  return elem.toLowerCase().replace(' ', '-');
});
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

const StepperSx = {
  '& .MuiStepConnector-root': {
    left: 'calc(-50% + 40px)',
    right: 'calc(50% + 40px)',
  },
  '& .MuiStepConnector-line': {
    marginTop: '22px',
  },
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: <GroupAddIcon />,
    2: <VideoLabelIcon />,
    3: <VideoLabelIcon />,
    4: <GroupAddIcon />,
    5: <VideoLabelIcon />,
    6: <GroupAddIcon />,
    7: <VideoLabelIcon />,
    8: <GroupAddIcon />,
    9: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

function getStepContent(step, warehouseId, slug, read, history) {
  switch (slug) {
    case 'contact-detail':
      return <ContactPendingForm warehouseId={warehouseId} read={read} slug={slug} />;
    case 'storage-space':
      return <SpacePendingForm warehouseId={warehouseId} slug={slug} read={read} />;
    case 'it-infra':
      return <OfficePendingForm warehouseId={warehouseId} slug={slug} read={read} />;
    case 'mh-infra':
      return <MhPendingForm warehouseId={warehouseId} slug={slug} read={read} />;
    case 'safety-info':
      return <SafetyPendingForm warehouseId={warehouseId} slug={slug} read={read} />;
    case 'permits':
      return <PermitPendingForm warehouseId={warehouseId} slug={slug} read={read} />;
    case 'material-type':
      return <MaterialPendingForm warehouseId={warehouseId} slug={slug} read={read} />;
    case 'warehouse-photo':
      return <PhotoPendingForm warehouseId={warehouseId} slug={slug} read={read} />;
    case 'form-80':
      return <EightyPendingForm warehouseId={warehouseId} slug={slug} read={read} />;
    default:
      history.push('/');
  }
}

const PendingDetails = ({ myFunc }) => {
  const { warehouseId, slug } = useParams();
  const history = useHistory();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const [assigned, setAssigned] = useState(null);

  // const navigate = useNavigate();
  //   const classes = useStyles();
  const dispatch = useDispatch();
  // const [expanded, setExpanded] = useState(false);
  // const [manuallyMsg, setManuallyMsg] = useState([]);

  //linear stepper state
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  // const [checkawait, setCheckawait] = useState(false);

  const [stepError, setStepError] = useState('error message Display');

  const isStepFailed = (step) => {
    // return step === activeStep
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeStep < 8) {
      if (data?.addNewResponse && data?.addNewResponse?.statusCode === 200) {
        history.push(`/pending-details/${warehouseId}/${RoutesStep[activeStep + 1]}`);
        dispatch(changeWarehouseStatus1());
      }
    }
  }, [data]);

  useEffect(() => {
    const index = RoutesStep.findIndex((elem) => elem === slug);
    // console.log('index');
    // console.log(index);
    setActiveStep(index);
  }, [slug]);

  useEffect(() => {
    console.log('clear loader', warehouseId, slug, data);
    if (warehouseId !== undefined && slug !== undefined) {
      dispatch(fetchWarehouseByIdAndTypePending(warehouseId, slug));
    }
  }, [slug, dispatch, warehouseId]);

  const handleNext = () => {
    dispatch(clearSingleDataState([]));
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
      history.push(`/pending-details/${warehouseId}/${RoutesStep[activeStep + 1]}`);
    }
  };

  const handleBack = () => {
    dispatch(clearSingleDataState([]));
    setActiveStep(activeStep - 1);
    history.push(`/pending-details/${warehouseId}/${RoutesStep[activeStep - 1]}`);
  };

  const assignToFieldAgen = () => {
    let user = JSON.parse(localStorage.getItem('userData'));
    // console.log('user=>', user?.account?.id);

    try {
      axiosauth
        .put(
          `/api/v1/warehouses/warehouse-to-fieldagent/${warehouseId}?accountId=${user?.account?.id}&fieldAgent=fieldAgent`,
        )
        .then((response) => {
          let res = JSON.parse(response.data);
          console.log('respp', res);
          if (res.statusCode === 200) {
            setAssigned(res.message);
          } else if (res.message === 'Field Agent is not Available') {
            alert('Field agent is not available');
          }
        })
        .catch((error) => {
          console.log('error=>', error);
        });
    } catch (e) { }
  };

  return (
    <LayoutOne>
      {data.finalSubmit ? (
        <FormSuccess
          onClick={() => {
            dispatch(changeWarehouseStatus());
            history.replace(`/pending-warehouse?page=1`);
          }}
          message='your submission has been send for approval. We will respond to you very soon'
        />
      ) : null}
      {data.finalSubmitVendor && (
        <FormSuccess
          onClick={() => {
            dispatch(changeWarehouseStatus1());
            history.replace(`/pending-warehouse?page=1`);
          }}
          message='Resubmitted To Vendor'
        />
      )}
      {assigned && (
        <FormSuccess
          onClick={() => {
            dispatch(changeWarehouseStatus1());
            history.replace(`/pending-warehouse?page=1`);
          }}
          message={assigned}
        />
      )}
      <Helmet>
        <meta charSet='utf-8' />
        <title> Pending Warehouse </title>{' '}
      </Helmet>
      <div className=''>
        <div className='row justify-content-end align-items-center py-3 px-3 mx-0'>
          <div className='col-12 '>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <h5 className='backButton mb-4 text-dark'>
                    <i
                      onClick={() => history.goBack()}
                      className='fas fa-chevron-left mr-3 cursorPointer'
                    ></i>
                    List Your Warehouse
                  </h5>

                  <>
                    <CssBaseline />
                    <Container component={Box} p={4} style={{ padding: '0px' }}>
                      <Paper component={Box} p={3} style={{ padding: '0px' }} className='py-4'>
                        <>
                          <Stack sx={{ width: '100%', paddingBottom: '15px' }} spacing={4}>
                            <Stepper
                              alternativeLabel
                              activeStep={activeStep}
                              sx={StepperSx}
                              connector={<ColorlibConnector />}
                            >
                              {steps.map((label, index) => {
                                const labelProps = {};
                                if (isStepFailed(index)) {
                                  labelProps.optional = (
                                    <Typography variant='caption' color='error'>
                                      {stepError}
                                    </Typography>
                                  );

                                  labelProps.error = true;
                                }

                                return (
                                  <Step key={label}>
                                    <Typography align='center'>{label}</Typography>
                                    {Object.keys(labelProps).length > 0 ? (
                                      <>
                                        <StepLabel {...labelProps}></StepLabel>
                                      </>
                                    ) : (
                                      <>
                                        <StepLabel
                                          {...labelProps}
                                          StepIconComponent={ColorlibStepIcon}
                                        ></StepLabel>
                                      </>
                                    )}
                                  </Step>
                                );
                              })}
                            </Stepper>
                          </Stack>
                          {activeStep === steps.length ? (
                            <Typography variant='h3' align='center'>
                              Thank You
                            </Typography>
                          ) : (
                            <>
                              <FormProvider>
                                <form onSubmit={handleNext}>
                                  {/* {getStepContent(activeStep)}           */}
                                  {getStepContent(activeStep, warehouseId, slug, read, history)}
                                  {/* {isLoading === false && checkawait === true ? (
                                    getStepContent(activeStep, warehouseId, slug, history)
                                  ) : (
                                    <SpinnerLoader />
                                    // alert("hii there")
                                  )} */}

                                  <div className='nextPreviousform pb-0'>
                                    <span>
                                      <MobileStepper
                                        variant='dots'
                                        steps={9}
                                        position='static'
                                        activeStep={activeStep}
                                        sx={{ flexGrow: 1, width: '50%', margin: '0 auto' }}
                                        nextButton={
                                          <Button
                                            size='small'
                                            // disabled=
                                            onClick={() => {
                                              setIsLoading(true);
                                              handleNext();
                                            }}
                                            disabled={activeStep === steps.length - 1}
                                          >
                                            Next
                                            {theme.direction === 'rtl' ? (
                                              <KeyboardArrowLeft />
                                            ) : (
                                              <KeyboardArrowRight />
                                            )}
                                          </Button>
                                        }
                                        backButton={
                                          <Button
                                            size='small'
                                            onClick={() => {
                                              setIsLoading(true);
                                              handleBack();
                                            }}
                                            disabled={activeStep === 0}
                                          >
                                            {theme.direction === 'rtl' ? (
                                              <KeyboardArrowRight />
                                            ) : (
                                              <KeyboardArrowLeft />
                                            )}
                                            Back
                                          </Button>
                                        }
                                      />
                                    </span>
                                  </div>
                                </form>
                              </FormProvider>
                            </>
                          )}
                        </>
                      </Paper>
                    </Container>
                    <div className='col-12 mt-4'>
                      <div className='row justify-content-end'>
                        <div className='col-auto my-2 '>
                          <button
                            onClick={() =>
                              dispatch(
                                updateWarehouseStatusResubmitted(warehouseId, {
                                  status: 'PENDINGFORAPPROVAL',
                                }),
                                // history.push('/pending-warehouse?page=1'),
                              )
                            }
                            // disabled=
                            type='button'
                            className={`btn btn-deep-blue ${!read ? '' : 'd-none'}`}
                          >
                            Submit to SP{' '}
                            {data.warehouseDetail.formEighty === true ? (
                              data.isPending && data.finalSubmit ? (
                                <Spinner animation='border' />
                              ) : null
                            ) : null}{' '}
                          </button>{' '}
                        </div>{' '}
                        <div className='col-auto my-2 '>
                          <button
                            onClick={() =>
                              dispatch(
                                updateWarehouseStatusAdminApproved(warehouseId, {
                                  status: 'Listed_and_Verified',
                                }),
                                // history.push('/pending-warehouse?page=1'),
                              )
                            }
                            type='button'
                            className={`btn btn-deep-blue ${!read ? '' : 'd-none'}`}
                          // disabled=
                          >
                            {' '}
                            Approve for Listing{' '}
                          </button>{' '}
                        </div>
                        <div className='col-auto my-2'>
                          {' '}
                          {/* {console.log('warehouseDetail', data.warehouseDetail.finalSubmitWhs)}{' '} */}
                          {/* {data.warehouseDetail.finalSubmitWhs === false ? (
                            <button
                              // disabled={
                              //   data.isPending ||
                              //   data.warehouseDetail.finalSubmitWhs === false
                              // }
                              type='button'
                              disabled={
                                data?.singleFormData !== undefined &&
                                data?.singleFormData?.remarks === null
                                  ? true
                                  : false
                              }
                              className={`btn btn-deep-blue ${!read ? '' : 'd-none'}`}
                              onClick={() => {
                                alert('Please fill the admin details in the form first');
                              }}
                            >
                              Assign to FA
                            </button>
                          ) : (
                            <button
                              //  to={`/assign-form-to-field-agent/${warehouseId}`}
                              onClick={() => {
                                assignToFieldAgen();
                              }}
                              type='button'
                              className={`btn btn-deep-blue ${!read ? '' : 'd-none'}`}
                              disabled={
                                data?.singleFormData !== undefined &&
                                data?.singleFormData?.remarks === null
                                  ? true
                                  : false
                              }
                            >
                              Assign to FA{' '}
                            </button>
                          )}{' '} */}
                        </div>{' '}
                      </div>{' '}
                    </div>{' '}
                  </>
                  {/*test*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutOne>
  );
};

export default PendingDetails;
