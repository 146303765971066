import {
    RFQ,
    RFQ_BY_ID,
    RFQ_ADMIN_TO_VENDOR,
    RFQ_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
    ERROR_MESSAGE
} from './../types';


const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    isErrorMsg: "",
    rfqList: [],
    rfqAdminToVendor: [],    
    rfqDetail: [],
    rfqResponse: []
}

export function RFQ_INFO(state = initialState, action) {

    switch (action.type) {

        case RFQ:
            return {
                ...state,
                rfqList: action.payload,
            };
        case RFQ_ADMIN_TO_VENDOR:
            return {
                ...state,
                rfqAdminToVendor: action.payload,
            };
        case RFQ_BY_ID:
            return {
                ...state,
                rfqDetail: action.payload,
            };

        case RFQ_RESPONSE:
            return {
                ...state,
                rfqResponse: action.payload,
            };

        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:
            return {
                ...state,
                isPending: action.isPending,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        case ERROR_MESSAGE:
            return {
                ...state,
                isErrorMsg: action.payload,
            };

        default:
            return state;
    }
}