import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCategory,
  responseCategory,
  categoryById,
} from "../../store/action/categoryAction";
import FormSuccess from "../../components/common/FormSuccess";
import { documentUpload } from "../../components/utils";

const UpdateCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const data = useSelector((state) => state.CATEGORY_INFO);

  // File Upload
  const [res, setRes] = useState([]);
  const [fileError, setFileError] = useState(null);

  const [initialValue, setInitialValue] = useState({
    categoryName: "",
    categoryStatus: true,
  });

  const uploadDocs = async (e) => {
    setFileError(null);
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    let urlData = await documentUpload(formData);
    //  console.log(urlData)

    if (urlData && urlData.url) {
      setRes([urlData.url]);
    }
  };

  // Validation Schema
  const catSchema = Yup.object().shape({
    categoryName: Yup.string().required("Required"),
    categoryStatus: Yup.string().required("Required"),
  });

  useEffect(() => {
    if (data?.categoryDetail?.data?.length > 0) {
      setRes(data?.categoryDetail?.data[0].image);
      setInitialValue({
        categoryName: data?.categoryDetail?.data[0].categoryName,
        categoryStatus: data?.categoryDetail?.data[0].categoryStatus,
      });
    }
  }, [data]);

  useEffect(() => {
    dispatch(categoryById(categoryId));
  }, [dispatch, categoryId]);

  // redirect url
  const redirect = () => {
    dispatch(responseCategory([]));
    return history.push("/category");
  };
  return (
    <LayoutOne>
      {" "}
      {data.categoryResponse &&
      data.categoryResponse.message === "Category updated successfully" ? (
        <FormSuccess
          onClick={redirect}
          message={data.categoryResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        validationSchema={catSchema}
        initialValues={initialValue}
        onSubmit={(fields) => {
          if (res) {
            fields["image"] = res;
            dispatch(updateCategory(fields, categoryId));
          } else {
            setFileError("Please Choose image");
          }
        }}
        render={({
          setFieldValue,
          values,
          errors,
          status,
          onChange,
          touched,
        }) => {
          return (
            <Form>
              <div class="row align-items-center pb-3 px-3 mx-0">
                <div class="col-12 py-3">
                  <span onClick={() => history.goBack()}>
                    {" "}
                    <h5 class="text-dark font-weight-bold">
                      {" "}
                      <i class="fas fa-chevron-left mr-2 cursorPointer">
                        {" "}
                      </i>{" "}
                      Update Category
                    </h5>{" "}
                  </span>{" "}
                </div>{" "}
                <div class="col-12 bg-white p-3">
                  <div class="row col-xl-12 col-md-12 col-sm-11">
                    <div class="form-group mb-4 w-100">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Category Name{" "}
                      </label>{" "}
                      <Field
                        name="categoryName"
                        type="text"
                        class={
                          `form-control form-control-md px-4 rounded-pill` +
                          (errors.categoryName && touched.categoryName
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`categoryName`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div class="form-group mb-4 w-100">
                      <label for="staticEmail" class="mb-2 pl-3">
                        {" "}
                        Status{" "}
                      </label>
                      <Field
                        onChange={(e) =>
                          setFieldValue(
                            `categoryStatus`,
                            e.target.value === "true"
                          )
                        }
                        name="categoryStatus"
                        as="select"
                        class={
                          `form-control form-control-md bg-white px-4 common-select-deep-blue rounded-pill` +
                          (errors.categoryStatus && touched.categoryStatus
                            ? " is-invalid"
                            : "")
                        }
                        id="exampleFormControlSelect1"
                      >
                        <option value=""> Select </option>{" "}
                        <option value={true}> Active </option>{" "}
                        <option value={false}> Inactive </option>{" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`categoryStatus`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12 mt-3">
                      <div className="row">
                        <div className="col-auto">
                          <div className="card card-overlay upload-image-preview position-relative">
                            {res ? (
                              <>
                                <div className="img-holder size-200px">
                                  <img
                                    src={res}
                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                    id="imageResult"
                                    alt=""
                                  />
                                </div>{" "}
                              </>
                            ) : (
                              <>
                                <div className="img-holder size-200px">
                                  <img
                                    className="w-100 h-100 img-fluid rounded overflow-hideen"
                                    id="imageResult"
                                    src="/assets/images/upload-bg-white.png"
                                    alt=""
                                  />
                                </div>{" "}
                                <div className="card-img-overlay size-200px d-flex justify-content-center align-items-center">
                                  <div className="card-text">
                                    <input
                                      onChange={uploadDocs}
                                      id="upload-image-preview1"
                                      type="file"
                                      accept="image/*"
                                      className="form-control border-0"
                                      hidden
                                    />
                                    <label
                                      id="upload-label"
                                      htmlFor="upload-image-preview1"
                                      className="font-weight-light text-muted"
                                    >
                                      {" "}
                                    </label>{" "}
                                    <div className="input-group-append">
                                      <label
                                        htmlFor="upload-image-preview1"
                                        className="btn px-0 text-deep-blue font-weight-bold m-0 rounded-pill px-4"
                                      >
                                        <div>
                                          <img
                                            src="/assets/images/icons/upload-icon-deep-blue.png"
                                            alt=""
                                          />
                                        </div>
                                        Upload image{" "}
                                      </label>{" "}
                                    </div>{" "}
                                  </div>{" "}
                                </div>{" "}
                              </>
                            )}
                          </div>
                          <input
                            onChange={uploadDocs}
                            id="upload-image-previewChange"
                            type="file"
                            className="form-control border-0"
                            hidden
                          />
                          <label
                            id="upload-label"
                            style={{
                              border: "1px solid gray",
                              padding: 4,
                              marginTop: 3,
                            }}
                            htmlFor="upload-image-previewChange"
                            className="font-weight-light text-muted btn btn-deep-blue"
                          >
                            {" "}
                            Change Image{" "}
                          </label>
                          <span className="errorMsg"> {fileError} </span>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>
                  </div>{" "}
                  <div class="row pt-4">
                    <div class="col-auto">
                      <button type="submit" class="btn py-2 btn-deep-blue">
                        {" "}
                        Update Category{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </LayoutOne>
  );
};

export default UpdateCategory;
