import React from "react";
import { Link } from "react-router-dom";

const EnquiryList = ({ enquiry, index }) => {
  const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return datestring;
  };
  const { id, created_at } = enquiry;

  function getStatus(status) {
    if (status === "Pending") {
      return "alert-primary";
    } else if (status === "Approved") {
      return "alert-success";
    } else {
      return "alert-danger";
    }
  }

  return (
    <tr>
      <td className=" text-center"> {index} </td>{" "}
      <td> {enquiry?.customerEnquiry?.ticketId} </td>{" "}
      <td> {readableDate(created_at)} </td>{" "}
      <td> {enquiry?.customerEnquiry?.user?.id} </td>{" "}
      <td className="text-capitalize">
        {" "}
        {enquiry?.customerEnquiry?.user?.firstName +
          " " +
          enquiry?.customerEnquiry?.user?.lastName}{" "}
      </td>{" "}
      <td>
        <button
          className={`alert btn-sm py-1 mb-0 ${getStatus(
            enquiry.customerEnquiry?.status
          )}`}
        >
          {enquiry.customerEnquiry ? enquiry.customerEnquiry?.status : ""}{" "}
        </button>{" "}
      </td>{" "}
      <td>
        <Link to={`/manageenquiry/${id}`}>
          <button className="btn p-0 btn-line-black mb-2">
            <i className="fas fa-eye"> </i>{" "}
          </button>{" "}
        </Link>{" "}
      </td>{" "}
    </tr>
  );
};

export default EnquiryList;
