import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { serviceSubCategoryById } from "../../store/action/serviceAction";
import { CardLoader } from "../../components/helper/CustomLoader";

const ViewServiceSubCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { subCategoryId } = useParams();
  const data = useSelector((state) => state.SERVICE_INFO);

  useEffect(() => {
    dispatch(serviceSubCategoryById(subCategoryId));
  }, [dispatch, subCategoryId]);

  return (
    <LayoutOne>
      <div className="row align-items-center pb-2 px-3 mx-0">
        <div className="col-12 py-3">
          <h5 className="text-dark">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left cursorPointer"
            ></i>{" "}
            View Sub Category Details{" "}
          </h5>{" "}
        </div>
        <div className="col-12">
          {" "}
          {data.isLoading ? (
            <CardLoader />
          ) : (
            <div className="row mx-0">
              <div className="col-12 bg-white rounded">
                {" "}
                {data.subCategoryDetail ? (
                  <div className="card card-body border-0">
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Category Name </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.subCategoryDetail?.category?.name}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2">
                        Sub Category Name{" "}
                      </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.subCategoryDetail?.name}{" "}
                      </div>{" "}
                    </div>
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Description </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.subCategoryDetail?.category?.description}{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Features </div>{" "}
                      <div>
                        {" "}
                        {JSON.parse(data.subCategoryDetail?.serviceFeature) &&
                        JSON.parse(data.subCategoryDetail?.serviceFeature)
                          .length > 0
                          ? JSON.parse(
                              data.subCategoryDetail?.serviceFeature
                            ).map((item, index) => {
                              return (
                                <>
                                  <div key={index} className="px-3 text-gray">
                                    {" "}
                                    {index + 1}. {item?.name}{" "}
                                  </div>{" "}
                                  <br />
                                </>
                              );
                            })
                          : null}{" "}
                      </div>{" "}
                    </div>
                    {/* <div className="d-flex">
                                                  <div className="mw-200px px-3 py-2">Active date</div>
                                                  <div className="mw-300px px-3 py-2 text-gray">{subCat.data?.createdAt}</div>
                                                </div> */}
                    <div className="d-flex">
                      <div className="mw-200px px-3 py-2"> Status </div>{" "}
                      <div className="mw-300px px-3 py-2 text-gray">
                        {" "}
                        {data.subCategoryDetail?.active
                          ? "Active"
                          : "In Active"}{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>
                ) : (
                  "Data Not FOund"
                )}{" "}
              </div>{" "}
            </div>
          )}
          <div className="row">
            <div className="col-12 my-2">
              <button
                onClick={() => history.goBack()}
                className="btn btn-outline-deep-blue py-2"
              >
                Back{" "}
              </button>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default ViewServiceSubCategory;
