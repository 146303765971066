// ########### Input Text with number typr ##############
export const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
        object.target.value = object.target.value.slice(
            0,
            object.target.maxLength
        );
    }
};

export const maxLengthCheckWithDecimal = (object) => {
    if (object.target.value.length > object.target.maxLength) {
        object.target.value = object.target.value.slice(
            0,
            object.target.maxLength
        );
    }
};



// ################  Only Alphabet Allow
export const onlyLetterAllow = (e) => {
    const re = /[a-zA-Z]+/g;
    if (!re.test(e.key)) {
        e.preventDefault();
    }
}

// ################  Only Alphabet Allow With Space
export const onlyLetterAllowSpace = (e) => {
    const re = /[a-zA-Z ]+/g;
    if (!re.test(e.key)) {
        e.preventDefault();
    }
}

// ################  Only Alphabet Allow
export const onlyNumberAllow = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
        e.preventDefault();
    }
}

// ################  Only Alphabet Allow
export const onlyAlphaNumericAllow = (e) => {
    const re = /[a-zA-Z-0-9]+/g;
    if (!re.test(e.key)) {
        e.preventDefault();
    }
}

// ################  Only AlphaNumeric Space Allow
export const onlyAlphaNumericSpaceAllow = (e) => {
    const re = /[a-zA-Z-0-9 ]+/g;
    if (!re.test(e.key)) {
        e.preventDefault();
    }
}

// ################  Only AlphaNumeric Space Allow
export const forDescriptionValidation = (e) => {
    const re = /[a-zA-Z-0-9 /@/:/-/,]+/g;
    if (!re.test(e.key)) {
        e.preventDefault();
    }
}

// ############  Check Null Value #######

export const checkNull = (checkValue) => {
    if (checkValue === null) {
        return ""
    }
    return checkValue;
}

// ############  Convert Date in readable form  #######
export const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
    return datestring;
}

// ###################33 Unique Id  ######################

export const uniqId = () => {
    let randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    return (randLetter + Date.now()).slice(0, 8);
}