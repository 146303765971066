import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  createWarehouseApprovalForm,
  addNewWarehouse,
  // fetchWarehouseByIdAndType
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";

const WarehousePendingForm = ({ warehouseId, viewMood }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const [formField, setFormField] = useState(null);
  const validationSchema = function (values) {
    return Yup.object().shape({
      category: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      type: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      warehouseName: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };
  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        console.log("dsksdk", getErrorsFromValidationError(error));
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };
  const [remarkForm, setRemarkForm] = useState({
    category: {
      value: data.singleFormData ? data.singleFormData.category : "",
      whsstatus: null,
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    type: {
      value: data.singleFormData ? data.singleFormData.type : "",
      whsstatus: null,
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    warehouseName: {
      value: data.singleFormData ? data.singleFormData.warehouseName : "",
      whsstatus: null,
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });

  useEffect(() => {
    if (data.singleFormData != null && data.type === "warehouse") {
      setFormField(data.singleFormData);
      if (data.singleFormData.warehouseRemark) {
        setRemarkForm({
          category: {
            value: data.singleFormData.category,
            whsstatus: data.singleFormData.warehouseRemark.category.whsstatus,
            whsremark: data.singleFormData.warehouseRemark.category.whsremark,
            fastatus: data.singleFormData.warehouseRemark.category.fastatus,
            faremark: data.singleFormData.warehouseRemark.category.faremark,
          },
          type: {
            value: data.singleFormData.type,
            whsstatus: data.singleFormData.warehouseRemark.type.whsstatus,
            whsremark: data.singleFormData.warehouseRemark.type.whsremark,
            fastatus: data.singleFormData.warehouseRemark.type.fastatus,
            faremark: data.singleFormData.warehouseRemark.type.faremark,
          },
          warehouseName: {
            value: data.singleFormData.warehouseName,
            whsstatus:
              data.singleFormData.warehouseRemark.warehouseName.whsstatus,
            whsremark:
              data.singleFormData.warehouseRemark.warehouseName.whsremark,
            fastatus:
              data.singleFormData.warehouseRemark.warehouseName.fastatus,
            faremark:
              data.singleFormData.warehouseRemark.warehouseName.faremark,
          },
        });
      } else {
        setRemarkForm({
          category: {
            value: data.singleFormData.category,
            whsstatus: null,
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          type: {
            value: data.singleFormData.type,
            whsstatus: null,
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          warehouseName: {
            value: data.singleFormData.warehouseName,
            whsstatus: null,
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);
  const onSubmit = (fields) => {
    const payload = {
      data: {
        warehouseRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    console.log("Created call");
    dispatch(createWarehouseApprovalForm(payload, warehouseId));
  };
  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-12 rounded-lg">
                <div className="row">
                  <div className="col-auto w-250px">
                    <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                      <label htmlFor="staticEmail" className="mb-2 px-2">
                        Warehouse Category:
                      </label>{" "}
                      <input
                        className="form-control px-4"
                        value={formField ? formField.category : ""}
                        disabled
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl pt-xl-4">
                    <div className="row align-items-center my-3">
                      <div className="col-auto form-inline form-group form-inline ml-xl-auto">
                        <div className="form-check common-radio-deep-blue mx-3">
                          {" "}
                          {console.log("ffff", values.category.whsstatus)}{" "}
                          <Field
                            name="category.whsstatus"
                            className="common-radio-deep-blue-input"
                            id="categoryOkay"
                            type="radio"
                            value="okay"
                            required
                            disabled={viewMood}
                            checked={values.category.whsstatus === true}
                            onChange={() =>
                              setFieldValue("category.whsstatus", true)
                            }
                          />{" "}
                          <label
                            className="common-radio-deep-blue-label pl-sm-4"
                            htmlFor="categoryOkay"
                          >
                            Okay{" "}
                          </label>{" "}
                        </div>{" "}
                        <div className="form-check common-radio-deep-blue mx-3">
                          <Field
                            name="category.whsstatus"
                            className="common-radio-deep-blue-input"
                            type="radio"
                            id="categoryNotOkay"
                            value="notOkay"
                            required
                            disabled={viewMood}
                            checked={values.category.whsstatus === false}
                            onChange={() =>
                              setFieldValue("category.whsstatus", false)
                            }
                          />{" "}
                          <label
                            className="common-radio-deep-blue-label pl-sm-4"
                            htmlFor="categoryNotOkay"
                          >
                            Not Okay{" "}
                          </label>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <Field
                          name={`category.remark`}
                          type="text"
                          disabled={viewMood}
                          className="form-control bg-white px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                        />
                      </div>{" "}
                    </div>{" "}
                    <div className="row justify-content-xl-end my-3">
                      <div className="col-auto">
                        <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                      </div>{" "}
                      <div className="col-auto form-inline form-group form-inline">
                        <p className="py-2">
                          {" "}
                          {formField
                            ? formField.warehouseRemark
                              ? formField.warehouseRemark.category.fastatus ===
                                true
                                ? "okay"
                                : "Not okay"
                              : ""
                            : ""}{" "}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <input
                          value={
                            formField
                              ? formField.warehouseRemark
                                ? formField.warehouseRemark.category.faremark
                                : ""
                              : ""
                          }
                          type="text"
                          className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="row">
                  <div className="col-auto w-250px">
                    <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                      <label htmlFor="staticEmail" className="mb-2 px-2">
                        Warehouse Type:
                      </label>{" "}
                      <p className="bg-white px-2 text-gray">
                        <input
                          className="form-control px-4"
                          value={formField ? formField.type : ""}
                          disabled
                        />
                      </p>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl pt-xl-2">
                    <div className="row align-items-center my-3">
                      <div className="col-auto form-inline form-group form-inline ml-xl-auto">
                        <div className="form-check common-radio-deep-blue mx-3">
                          <Field
                            name="type.whsstatus"
                            className="common-radio-deep-blue-input"
                            type="radio"
                            required
                            disabled={viewMood}
                            id="typeOkay"
                            value="okay"
                            checked={values.type.whsstatus === true}
                            onChange={() =>
                              setFieldValue("type.whsstatus", true)
                            }
                          />{" "}
                          <label
                            className="common-radio-deep-blue-label pl-sm-4"
                            htmlFor="typeOkay"
                          >
                            Okay{" "}
                          </label>{" "}
                        </div>{" "}
                        <div className="form-check common-radio-deep-blue mx-3">
                          <Field
                            name="type.whsstatus"
                            className="common-radio-deep-blue-input"
                            type="radio"
                            required
                            disabled={viewMood}
                            id="typeNotOkay"
                            value="notOkay"
                            checked={values.type.whsstatus === false}
                            onChange={() =>
                              setFieldValue("type.whsstatus", false)
                            }
                          />{" "}
                          <label
                            className="common-radio-deep-blue-label pl-sm-4"
                            htmlFor="typeNotOkay"
                          >
                            Not Okay{" "}
                          </label>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <Field
                          name={`type.remark`}
                          type="text"
                          disabled={viewMood}
                          className="form-control bg-white px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                        />
                      </div>
                    </div>{" "}
                    <div className="row justify-content-xl-end my-3">
                      <div className="col-auto">
                        <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                      </div>{" "}
                      <div className="col-auto form-inline form-group form-inline">
                        <p className="py-2">
                          {" "}
                          {formField
                            ? formField.warehouseRemark
                              ? formField.warehouseRemark.category.fastatus ===
                                true
                                ? "okay"
                                : "Not okay"
                              : ""
                            : ""}{" "}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <input
                          value={
                            formField
                              ? formField.warehouseRemark
                                ? formField.warehouseRemark.category.faremark
                                : ""
                              : ""
                          }
                          type="text"
                          className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>
                </div>{" "}
                <div className="row">
                  <div className="col-auto w-250px">
                    <div className="form-group col-12 my-xl-2 pt-xl-1 my-3">
                      <label htmlFor="staticEmail" className="mb-2 px-2">
                        Warehouse Display Name:
                      </label>{" "}
                      <input
                        className="form-control px-4"
                        value={formField ? formField.warehouseName : ""}
                        disabled
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="col-xl pt-xl-4">
                    <div className="row align-items-center my-3">
                      <div className="col-auto form-inline form-group form-inline ml-xl-auto">
                        <div className="form-check common-radio-deep-blue mx-3">
                          <Field
                            name="warehouseName.whsstatus"
                            className="common-radio-deep-blue-input"
                            type="radio"
                            id="warehouseOkay"
                            value="okay"
                            disabled={viewMood}
                            required
                            checked={values.warehouseName.whsstatus === true}
                            onChange={() =>
                              setFieldValue("warehouseName.whsstatus", true)
                            }
                          />{" "}
                          {console.log("fdjjdfj", errors)}{" "}
                          <label
                            className="common-radio-deep-blue-label pl-sm-4"
                            htmlFor="warehouseOkay"
                          >
                            Okay{" "}
                          </label>{" "}
                        </div>{" "}
                        <div className="form-check common-radio-deep-blue mx-3">
                          <Field
                            name="warehouseName.whsstatus"
                            className="common-radio-deep-blue-input"
                            type="radio"
                            id="warehouseNotOkay"
                            value="notOkay"
                            required
                            disabled={viewMood}
                            checked={values.warehouseName.whsstatus === false}
                            onChange={() =>
                              setFieldValue("warehouseName.whsstatus", false)
                            }
                          />{" "}
                          <label
                            className="common-radio-deep-blue-label pl-sm-4"
                            htmlFor="warehouseNotOkay"
                          >
                            Not Okay{" "}
                          </label>
                        </div>
                      </div>

                      <div className="col-auto">
                        <Field
                          name={`warehouseName.remark`}
                          type="text"
                          disabled={viewMood}
                          className="form-control bg-white px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-xl-end my-3">
                      <div className="col-auto">
                        <p className="px-3 py-2"> Field Agent Remarks </p>{" "}
                      </div>{" "}
                      <div className="col-auto form-inline form-group form-inline">
                        <p className="py-2">
                          {" "}
                          {formField
                            ? formField.warehouseRemark
                              ? formField.warehouseRemark.warehouseName
                                  .fastatus === true
                                ? "okay"
                                : "Not okay"
                              : ""
                            : ""}{" "}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-auto">
                        <input
                          value={
                            formField
                              ? formField.warehouseRemark
                                ? formField.warehouseRemark.warehouseName
                                    .faremark
                                : ""
                              : ""
                          }
                          type="text"
                          className="form-control  px-4 ml-xl-0 ml-3 mb-2"
                          placeholder="Remarks"
                          disabled
                        />
                      </div>{" "}
                    </div>{" "}
                  </div>
                </div>{" "}
                {/* ==================== */}
                <div className={`col-12 mt-4`}>
                  {" "}
                  {/* <p>{errors?errors['warehouseName.whsstatus']:""}</p> */}
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <button
                        type="submit"
                        className="btn btn-deep-blue add-class remove-class"
                        disabled={data.isPending}
                      >
                        Save{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
                {/* ======================== */}{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default WarehousePendingForm;
