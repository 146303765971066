import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createNotification,
  responseNotification,
} from "../../store/action/notificationAction";
import { getAllUser } from "../../store/action/userAction";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";
import { AsyncPaginate } from "react-select-async-paginate";

const CreateNotification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.NOTIFICATION_INFO);
  const userData = useSelector((state) => state.USER_INFO);
  const [value, setValue] = useState(null);

  // Validation Schema
  const notificationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    content: Yup.string().required("Required").max(200, "Maximum 200 Lettar"),
    to: Yup.string().required("Required"),
    user: Yup.string().required("Required"),
  });

  useEffect(() => {
    dispatch(getAllUser(1));
  }, [dispatch]);

  const redirect = () => {
    dispatch(responseNotification([]));
    return history.push("/notification?page=1");
  };

  // Select option with pagination
  async function loadOptions(search, loadedOptions, { page }) {
    if (
      loadedOptions.length === 0 ||
      loadedOptions.length < userData.allUserList?.totalCount
    )
      dispatch(getAllUser(page));
    let newUser = [];

    for (let i = 0; i < userData.allUserList?.data?.length; i++) {
      newUser.push({
        label:
          userData.allUserList?.data[i].firstName +
          " " +
          userData.allUserList?.data[i].lastName,
        value: userData.allUserList?.data[i].id,
      });
    }

    return {
      options: newUser,
      hasMore: newUser.length > 0 ? true : false,
      additional: {
        page: page + 1,
      },
    };
  }

  return (
    <LayoutOne>
      {data.notificationResponse &&
      data.notificationResponse?.statusCode === 200 ? (
        <FormSuccess
          onClick={redirect}
          message={data.notificationResponse?.message}
        />
      ) : null}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12 py-3 px-0">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 cursorPointer"
            >
              {" "}
            </i>{" "}
            Create Notification
          </h5>
        </div>
        <div className="col-12 bg-white p-3">
          <Formik
            enableReinitialize={true}
            validationSchema={notificationSchema}
            initialValues={{
              title: "",
              content: "",
              to: "Warehousity",
              user: "",
            }}
            onSubmit={(fields) => {
              dispatch(createNotification(fields));
            }}
            render={({
              setFieldValue,
              values,
              errors,
              status,
              onChange,
              touched,
            }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="form-group mb-2  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        {" "}
                        Notification Tittle{" "}
                      </label>{" "}
                      <Field
                        name="title"
                        type="text"
                        className={
                          `form-control form-control-md px-4` +
                          (errors.title && touched.title ? " is-invalid" : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`title`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group mb-2  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        {" "}
                        From{" "}
                      </label>{" "}
                      <Field
                        name="to"
                        type="text"
                        className={
                          `form-control form-control-md px-4` +
                          (errors.to && touched.to ? " is-invalid" : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                      />
                      <ErrorMessage
                        name={`to`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group mb-2  col-md-4 col-sm-6">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        {" "}
                        To(Customer / Space Provider){" "}
                      </label>
                      <AsyncPaginate
                        value={value}
                        loadOptions={loadOptions}
                        onChange={(e) => {
                          setValue(e);
                          setFieldValue("user", parseInt(e.value));
                        }}
                        additional={{
                          page: 1,
                        }}
                        classNamePrefix={errors.user ? `mySelect` : ""}
                      />{" "}
                      <span className="errorMsg">
                        {" "}
                        {errors.user ? `Required` : ""}{" "}
                      </span>
                    </div>{" "}
                    <div className="form-group mb-2  col-md-8 col-sm-12">
                      <label htmlFor="staticEmail" className="mb-2 pl-3">
                        {" "}
                        Description{" "}
                      </label>{" "}
                      <Field
                        name="content"
                        type="textarea"
                        className={
                          `form-control form-control-md px-4 ` +
                          (errors.content && touched.content
                            ? " is-invalid"
                            : "")
                        }
                        id="staticEmail"
                        placeholder="Type here"
                        rows="4"
                      >
                        {" "}
                      </Field>{" "}
                      <ErrorMessage
                        name={`content`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="row mt-2">
                    <div className="col-auto ml-auto">
                      <button
                        type="submit"
                        disabled={data.isPending}
                        className="btn btn-deep-blue py-2"
                      >
                        Create Notification{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>
                </Form>
              );
            }}
          />
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default CreateNotification;
