import {
    REVIEW,
    REVIEW_BY_ID,
    REVIEW_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING,
} from "./../types";

import {
    GET_REVIEW_URL,
    REVIEW_BY_ID_URL,
    REVIEW_DELETE_BY_ID,
} from "../../services/urls";

import axiosauth from "../../services/axios-auth";

export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}

export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}

export function reviewList(data) {
    return {
        type: REVIEW,
        payload: data,
    };
}

export function review_By_Id(data) {
    return {
        type: REVIEW_BY_ID,
        payload: data,
    };
}

export function responseReview(data) {
    return {
        type: REVIEW_RESPONSE,
        payload: data,
    };
}

// ###########  Fecth All bookingList ########

export const reviewByPage = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(GET_REVIEW_URL + `?page=${page}&limit=10&search=${search}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        dispatch(reviewList(res.data));
                        dispatch(isLoading(false));
                    } else {
                        console.log("Review Fail");
                    }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

// ###########  Fecth All bookingList ########

export const reviewWithoutLoader = () => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(GET_REVIEW_URL)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    console.log("res--->", res);
                    if (res.statusCode === 200) {
                        dispatch(reviewList(res.data));
                        dispatch(isLoading(false));
                    } else {
                        console.log("Review Fail");
                    }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

// ###########  Fecth  category Detail By Id ########

export const reviewById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .get(REVIEW_BY_ID_URL + `/${id}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        dispatch(review_By_Id(res.data));
                        dispatch(isLoading(false));
                    } else {
                        console.log("breview_By_Id Fail");
                    }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};

// ###########  Fecth  category Detail By Id ########

export const reviewDeleteById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true));
        dispatch(isError(""));
        try {
            axiosauth
                .delete(REVIEW_DELETE_BY_ID + `/${id}`)
                .then((response) => {
                    let res = JSON.parse(response.data);
                    if (res.statusCode === 200) {
                        dispatch(responseReview(res.data));
                        dispatch(reviewWithoutLoader());
                        dispatch(isLoading(false));
                    } else {
                        console.log("review_By_Id Fail");
                    }
                })
                .catch((error) => {
                    dispatch(isError(error.message));
                    dispatch(isLoading(false));
                })
                .then(() => {
                    dispatch(isLoading(false));
                    console.log("-----always executes");
                });
        } catch (e) {}
    };
};