import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

const FilterOptionList = ({ index, fltId, item, filterName, type }) => {
  // Status Change confirmation
  const deleteFilter = (id) => {
    confirmAlert({
      // title: 'Confirm to submit',
      message: "Are you sure to Delete Filter.",
      buttons: [
        {
          label: "Yes",
          onClick: () => console.log("Delet filter"),
        },
        {
          label: "No",
          onClick: () => console.log("filter"),
        },
      ],
    });
  };

  return (
    <tr>
      <td className="">
        <div className="text-gray text-uppercase small"> FILTER NAME </div>{" "}
        <div className="font-weight-bold text-capitalize"> {filterName} </div>{" "}
      </td>{" "}
      <td className="">
        <div className="text-gray text-uppercase small"> FILTER Type </div>{" "}
        <div className="font-weight-bold text-capitalize"> {type} </div>{" "}
      </td>{" "}
      <td className="">
        <div className="text-gray text-uppercase small">
          {" "}
          Filter Option Name{" "}
        </div>{" "}
        <div className="font-weight-bold"> {item?.filterOption} </div>{" "}
      </td>
      <td className="">
        <Link
          to={`/update-filter-option/${fltId}/${item.id}`}
          className="btn text-deep-blue font-weight-bold text-nowrap"
        >
          {" "}
          Update Filter Option{" "}
        </Link>{" "}
      </td>
      <td className="">
        <button
          onClick={() => deleteFilter(item.id)}
          className="btn text-deep-blue font-weight-bold text-nowrap"
        >
          {" "}
          Delete Filter Option{" "}
        </button>{" "}
      </td>{" "}
    </tr>
  );
};

export default FilterOptionList;
