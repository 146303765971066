import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import { getDemograpgy } from "../../store/action/dashboardAction";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { PieChart } from "react-minimal-pie-chart";

const Demography = () => {
  const [location, setLocation] = useState("Delhi");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.DASHBOARD_INFO);

  const demographyCall = (state) => {
    if (state !== "") {
      setLocation(state);
      dispatch(
        getDemograpgy({
          state: state,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      getDemograpgy({
        state: "Delhi",
      })
    );
  }, [dispatch]);

  const options = [
    // { value: '0', label: 'Select State' },
    {
      value: "1",
      label: "Andaman & Nicobar",
    },
    {
      value: "2",
      label: "Andhra Pradesh",
    },
    {
      value: "3",
      label: "Arunachal Pradesh",
    },
    {
      value: "4",
      label: "Assam",
    },
    {
      value: "5",
      label: "Bihar",
    },
    {
      value: "6",
      label: "Chandigarh",
    },
    {
      value: "7",
      label: "Chhattisgarh",
    },
    {
      value: "8",
      label: "Dadra & Nagar Haveli",
    },
    {
      value: "9",
      label: "Daman & Diu",
    },
    {
      value: "10",
      label: "Delhi",
    },
    {
      value: "11",
      label: "Goa",
    },
    {
      value: "12",
      label: "Gujarat",
    },
    {
      value: "13",
      label: "Haryana",
    },
    {
      value: "14",
      label: "Himachal Pradesh",
    },
    {
      value: "15",
      label: "Jammu & Kashmir",
    },
    {
      value: "16",
      label: "Jharkhand",
    },
    {
      value: "17",
      label: "Karnataka",
    },
    {
      value: "18",
      label: "Kerala",
    },
    {
      value: "19",
      label: "Lakshadweep",
    },
    {
      value: "20",
      label: "Madhya Pradesh",
    },
    {
      value: "21",
      label: "Maharashtra",
    },
    {
      value: "22",
      label: "Manipur",
    },
    {
      value: "23",
      label: "Meghalaya",
    },
    {
      value: "24",
      label: "Mizoram",
    },
    {
      value: "25",
      label: "Nagaland",
    },
    {
      value: "26",
      label: "Orissa",
    },
    {
      value: "27",
      label: "Pondicherry",
    },
    {
      value: "28",
      label: "Punjab",
    },
    {
      value: "29",
      label: "Rajasthan",
    },
    {
      value: "30",
      label: "Sikkim",
    },
    {
      value: "31",
      label: "Tamil Nadu",
    },
    {
      value: "32",
      label: "Tripura",
    },
    {
      value: "33",
      label: "Uttar Pradesh",
    },
    {
      value: "34",
      label: "Uttaranchal",
    },
    {
      value: "35",
      label: "West Bengal",
    },
  ];

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Warehouse Admin </title>{" "}
      </Helmet>

      <div className="row justify-content-end align-items-center py-3 px-3 mx-0">
        <div className="col-12 bg-white custom-shadow px-3 py-1 mb-2  tabs-scroll">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item">
              <Link to={`/dashboard`} className="nav-link h6 text-uppercase">
                {" "}
                Space Provider{" "}
              </Link>{" "}
            </li>
            <li className="nav-item">
              <Link
                to={`/customer-dashboard`}
                className="nav-link h6  text-uppercase"
              >
                {" "}
                Customer Details{" "}
              </Link>{" "}
            </li>{" "}
            <li className="nav-item">
              <Link
                to={`/dashboard-demography`}
                className="nav-link h6 active_link text-uppercase"
              >
                {" "}
                Demography{" "}
              </Link>{" "}
            </li>{" "}
          </ul>{" "}
        </div>
        <div className="col-12 px-0 pt-3">
          <div>
            <div className="row mx-0">
              <div className="col-12 border px-0 card">
                <div className="card-header bg-none py-2">
                  <h5 className="px-3"> State count </h5>{" "}
                </div>{" "}
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-12 mb-3">
                              <form action="">
                                <select
                                  value={location}
                                  onChange={(e) =>
                                    demographyCall(e.target.value)
                                  }
                                  className="common-select form-control"
                                >
                                  <option value=""> Select location </option>

                                  {options && options.length > 0
                                    ? options.map((item, index) => {
                                        return (
                                          <option
                                            value={item.label}
                                            key={index}
                                          >
                                            {" "}
                                            {item.label}{" "}
                                          </option>
                                        );
                                      })
                                    : null}
                                </select>{" "}
                              </form>{" "}
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 mt-2">
                              Total Warehouse:{" "}
                              {data.dashboardDemography?.totalWarehouse}{" "}
                            </div>
                          </div>{" "}
                        </div>

                        {data.dashboardDemography?.data &&
                        data.dashboardDemography.data?.length > 0
                          ? data.dashboardDemography.data.map((item, index) => {
                              return (
                                <div key={index} className="col-sm-6">
                                  <ul className="list-group">
                                    <li className="d-flex align-items-center py-2 px-2 border-0 text-gray justify-content-between">
                                      <div className="d-flex align-items-center">
                                        {" "}
                                        <div
                                          style={{
                                            color: `${item.color}`,
                                          }}
                                        >
                                          {" "}
                                          <i className="fas fa-square mr-2">
                                            {" "}
                                          </i>
                                        </div>{" "}
                                        <div> {item.title} </div>
                                      </div>
                                      <div> {item.value} </div>{" "}
                                    </li>{" "}
                                  </ul>{" "}
                                </div>
                              );
                            })
                          : null}
                      </div>{" "}
                    </div>{" "}
                    <div className="col-md-6 mx-auto p-3">
                      {" "}
                      {data.dashboardDemography?.data &&
                      data.dashboardDemography?.data?.length > 0 ? (
                        <PieChart
                          data={data.dashboardDemography?.data}
                          style={{
                            height: 250,
                          }}
                        />
                      ) : null}
                      {/* <div className="img-holder w-320px mx-auto">
                                            <img className="img-fluid w-100" src={"/assets/images/graph.png"} alt="img" />
                                          </div> */}{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default Demography;
