import {
    USER,
    USER_BY_ID,
    ALL_USER_LIST,
    USER_RESPONSE,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING
} from '../types'

import {
    GET_USER_URL,
    CREATE_NEW_USER_URL,
    GET_CUSTOMER_USER_URL,
    GET_VENDOR_USER_URL,
    UPDATE_USER_URL,
    DELETE_USER_URL,
} from '../../services/urls';

import axiosauth from '../../services/axios-auth';

export const userList = (user) => {
    return {
        type: USER,
        payload: user,
    };
}

export const userDetail = (user) => {
    return {
        type: USER_BY_ID,
        payload: user,
    };
}

export const allUserList = (user) => {
    return {
        type: ALL_USER_LIST,
        payload: user
    }
}

export function responseUser(data) {
    return {
        type: USER_RESPONSE,
        payload: data
    }
}


export function isError(error) {
    return {
        type: IS_ERROR,
        isError: error,
    };
}


export function isLoading(bool) {
    return {
        type: IS_LOADING,
        isLoading: bool,
    };
}

export function isPending(bool) {
    return {
        type: IS_PENDING,
        isLoading: bool,
    };
}


// ###########  Create New User ########

export const createUser = (fields) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.post(CREATE_NEW_USER_URL, fields).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(responseUser(res))
                    dispatch(isPending(false))
                } else {
                    console.log("uSER Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}

// Update user Status

export const updateUser = (id, data) => {
    return async (dispatch) => {
        dispatch(isPending(true))
        dispatch(isError(""))
        try {
            axiosauth.put(UPDATE_USER_URL + `/${id}`, data).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(responseUser(res))
                    dispatch(isPending(false))
                } else {
                    console.log("uSER Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isPending(false))
            }).then(() => {
                dispatch(isPending(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// Get All Active list with pgination

export const getAllUser = (page) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(`/api/v1/user/users/all?page=${page}&limit=20`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(allUserList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("uSER Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })
        } catch (e) {}
    }
}




// ###########  Fecth All Customer List ########

export const customerUserByPage = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_CUSTOMER_USER_URL + `?page=${page}&limit=${10}&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                dispatch(userList(res))
                dispatch(isLoading(false))
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })
        } catch (e) {}
    }
}


// ###########  Fecth All Customer List ########

export const vendorUserByPage = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_VENDOR_USER_URL + `?page=${page}&limit=${10}&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                dispatch(userList(res))
                dispatch(isLoading(false))
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })
        } catch (e) {}
    }
}


// ###########  Fecth All Customer by search ########

export const vendorUserSearch = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(`/api/v1/user/dashboard/searching?page=${page}&limit=10&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                dispatch(userList(res))
                dispatch(isLoading(false))
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })
        } catch (e) {}
    }
}

// ###########  Fecth All Active vendor list with searching List ########

export const getAllActiveVendor = (page, search) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(`/api/v1/user/spacemanagement/vendor/all?page=${page}&limit=10&search=${search}`).then(response => {
                let res = JSON.parse(response.data)
                // if (res.statusCode === 200) { 
                dispatch(userDetail(res))
                dispatch(isLoading(false))
                // }
                // else {
                // console.log("uSER Fail")
                // }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })
        } catch (e) {}
    }
}






// ###########  Fecth All User List ########

export const userListByPage = () => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_USER_URL).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(userList(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("uSER Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}

// GET_CUSTOMER_USER_URL
export const customerUserById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_CUSTOMER_USER_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                // if (res.statusCode === 200) { 
                dispatch(userDetail(res))
                dispatch(isLoading(false))
                // }
                // else {
                // console.log("uSER Fail")
                // }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}

export const vendorUserById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(GET_VENDOR_USER_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                // if (res.statusCode === 200) { 
                dispatch(userDetail(res))
                dispatch(isLoading(false))
                // }
                // else {
                // console.log("uSER Fail")
                // }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}


// Delete User By Id

export const deleteUserById = (id) => {
    return async (dispatch) => {
        dispatch(isLoading(true))
        dispatch(isError(""))
        try {
            axiosauth.get(DELETE_USER_URL + `/${id}`).then(response => {
                let res = JSON.parse(response.data)
                if (res.statusCode === 200) {
                    dispatch(responseUser(res))
                    dispatch(isLoading(false))
                } else {
                    console.log("uSER Fail")
                }
            }).catch((error) => {
                dispatch(isError(error.message))
                dispatch(isLoading(false))
            }).then(() => {
                dispatch(isLoading(false))
                console.log("-----always executes");
            })

        } catch (e) {}
    }
}