import React from "react";

const FormSuccess = ({ onClick, message }) => {
  return (
    <div className="form-modal">
      <div className="modal-success">
        <h5 className="text-capitalize"> {message} </h5>{" "}
        <button className="btn btn-deep-blue" onClick={onClick}>
          {" "}
          OK{" "}
        </button>{" "}
      </div>{" "}
    </div>
  );
};

export default FormSuccess;
