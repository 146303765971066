import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
// import Pagination from "react-js-pagination";
import { CardLoader } from "../../components/helper/CustomLoader";
import axiosauth from "../../services/axios-auth";

const UserContact = () => {
  const history = useHistory();
  const [contactList, setContactList] = useState([]);
  const [loader, cardLoader] = useState(true);

  const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return datestring;
  };

  useEffect(() => {
    try {
      axiosauth
        .get(`/api/v1/contactus`)
        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.statusCode === 200) {
            setContactList(res);
            cardLoader(false);
          }
        })
        .catch((error) => {})
        .then(() => {});
    } catch (e) {}
  }, []);

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Enquiry </title>{" "}
      </Helmet>

      <div className="row align-items-center px-3 py-1 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 px-0 d-flex justify-content-between">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            >
              {" "}
            </i>
            Contact Us{" "}
          </h5>
        </div>{" "}
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 bg-white mb-2">
          {" "}
          {/* <SearchBox callSearch={callSearch} setSearchHandler={setSearchHandler} border={true} /> */}{" "}
        </div>
        {loader ? (
          <CardLoader />
        ) : (
          <div className="col-12 px-0">
            <div className="row align-items-center">
              <div className="col-12 text-nowrap table-responsive table-gray-admin">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="text-center"> Name </th>{" "}
                      <th className="text-nowrap"> Contact Date </th>{" "}
                      <th className="text-nowrap"> email </th> <th> Phone </th>{" "}
                      <th> Company Name </th>{" "}
                      <th className="text-center"> Message </th>{" "}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {contactList?.data && contactList.data.length > 0
                      ? contactList.data.map((item, index) => (
                          <tr key={index}>
                            <td className=" text-center py-1"> {item.name} </td>{" "}
                            <td> {readableDate(item.created_at)} </td>{" "}
                            <td> {item.email} </td> <td> {item.phone} </td>{" "}
                            <td> {item.companyName} </td>{" "}
                            <td> {item.message} </td>{" "}
                          </tr>
                        ))
                      : "Not Foudsa"}
                    <tr>
                      <td className=" text-center"> {/* {index} */} </td>{" "}
                      <td> {/* {readableDate(created_at)} */} </td>{" "}
                      <td> "" </td> <td> "" </td> <td>"" </td>{" "}
                      <td> {/*  */} </td>{" "}
                    </tr>{" "}
                  </tbody>{" "}
                </table>{" "}
              </div>{" "}
            </div>

            {/* <div className="pagination-custom">
                                {data.enquiryList?.totalCount > 10 && (
                                  <Pagination
                                    activePage={parseInt(pageCount)}
                                    itemsCountPerPage={10}
                                    totalItemsCount={data.enquiryList.totalCount}
                                    pageRangeDisplayed={1}
                                    onChange={handlePageChange}
                                    prevPageText={<i className="fas fa-chevron-left" />}
                                    nextPageText={<i className="fas fa-chevron-right" />}
                                    hideFirstLastPages={true}
                                  />
                                )}
                              </div> */}
          </div>
        )}{" "}
      </div>
    </LayoutOne>
  );
};

export default UserContact;
