import React from 'react';
import { Link } from 'react-router-dom';
import { readableDate } from '../../components/helper/reuse';
import { useSelector } from 'react-redux';

const WarehousePendingList = ({ item, index }) => {
  const { id, warehouseName, created_at, adminStatus } = item;
  const categoryList = useSelector((state) => state.CATEGORY_INFO.categoryList);
  const typeList = useSelector((state) => state.WHY_INFO.typeList);
  const warehouseCategory = (id) => {
    if (
      categoryList &&
      categoryList !== null &&
      categoryList !== undefined &&
      Object.keys(categoryList).length > 0
    ) {
      const categoryListAd = categoryList.find((currentvalue, index) => currentvalue.id === id);
      return categoryListAd.categoryName;
    }
  };
  const warehouseType = (id) => {
    if (typeList && typeList !== null && typeList !== undefined) {
      const typeListAd = typeList?.data.find((currentvalue, index) => currentvalue.id === id);
      return typeListAd.type;
    }
  };

  // console.log(item, "||item||")
  return (
    <tr>
      <td className='text-center py-2'> {index} </td>
      <td> {item?.warehouseName} </td>
      <td> {warehouseCategory(item.warehouseCategory)} </td>{' '}
      <td> {warehouseType(item.warehouseType)} </td>{' '}
      <td className='text-center'>
        {' '}
        <Link to={`/pending-details/${id}/contact-detail`}>
          <i className='fa fa-eye'> </i>{' '}
        </Link>
      </td>
    </tr>
  );
};

export default WarehousePendingList;
