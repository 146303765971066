import React, { useEffect, useState } from "react";
import LayoutOne from "../../layout/LayoutOne";
import NotificationList from "../../wrapper/notification/NotificationList";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { notificationByPage } from "../../store/action/notificationAction";
import Pagination from "react-js-pagination";
import SearchBox from "../../components/common/SearchBox";
import { ItemNotFoud } from "../../components/common/CustomLoader";

const Notification = () => {
  const [searchHandler, setSearchHandler] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state.NOTIFICATION_INFO);
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const pageCount = new URLSearchParams(window.location.search).get("page");

  useEffect(() => {
    dispatch(notificationByPage(parseInt(pageCount), ""));
  }, [dispatch, pageCount]);

  const handlePageChange = (pageNumber) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("page", pageNumber);
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    dispatch(notificationByPage(pageNumber, searchHandler));
  };

  const callSearch = () => {
    dispatch(notificationByPage(parseInt(pageCount), searchHandler));
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Notification </title>{" "}
      </Helmet>

      <div className="row align-items-center py-2 px-3 mx-0">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 px-0 d-flex justify-content-between">
          <h5 className="text-dark d-flex justify-content-between">
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-2 pt-2 cursorPointer"
            >
              {" "}
              Notification{" "}
            </i>{" "}
          </h5>{" "}
          <Link
            to="create-notification"
            className={`btn py-2 px-md-3 px-sm-1 text-spacing btn-deep-blue ${
              !read ? "" : "d-none"
            }`}
          >
            {" "}
            Create Notification{" "}
          </Link>{" "}
        </div>{" "}
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-1 px-0">
          <SearchBox
            callSearch={callSearch}
            setSearchHandler={setSearchHandler}
          />{" "}
        </div>{" "}
        <div className="col-12 text-nowrap table-gray-admin bg-white p-3">
          {" "}
          {data.notificationList.data &&
          data.notificationList.data.length > 0 ? (
            <table className="table table-dark-custom">
              <thead>
                <tr>
                  <th className="text-nowrap pl-2"> Sr.No. </th>{" "}
                  <th className="text-nowrap pl-3"> User </th>{" "}
                  <th className="text-nowrap"> Subject </th>{" "}
                  <th className="text-nowrap"> Message </th>{" "}
                  <th className="text-nowrap"> Date </th>{" "}
                </tr>{" "}
              </thead>{" "}
              <tbody>
                {" "}
                {data.notificationList.data.map((item, index) => {
                  return (
                    <NotificationList
                      item={item}
                      key={index}
                      index={index + 1}
                    />
                  );
                })}{" "}
              </tbody>{" "}
            </table>
          ) : (
            <ItemNotFoud message="Data Not Found" />
          )}
          {data.notificationList?.totalCount > 10 && (
            <div className="pagination-custom">
              <Pagination
                activePage={parseInt(pageCount)}
                itemsCountPerPage={10}
                totalItemsCount={data.notificationList.totalCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                prevPageText={<i className="fas fa-chevron-left" />}
                nextPageText={<i className="fas fa-chevron-right" />}
                hideFirstLastPages={true}
              />{" "}
            </div>
          )}
        </div>{" "}
      </div>
    </LayoutOne>
  );
};

export default Notification;
