import React from "react";
import LayoutOne from "../../layout/LayoutOne";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createDepartment,
  responseWhs,
} from "../../store/action/whsUserAction";
import FormSuccess from "../../components/common/FormSuccess";
import Spinner from "react-bootstrap/Spinner";

const CreateWHSDepartment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WHS_USER_INFO);

  const roleSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  });

  const redirect = () => {
    dispatch(responseWhs(null));
    history.replace(`/wsh-department`);
  };

  return (
    <LayoutOne>
      {data.whsResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Department Created`} />
      ) : null}
      <div className="row align-items-center py-3">
        <div className="col-12 py-3 mt-4">
          <h5 className="text-dark">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            >
              {" "}
            </i>{" "}
            Create Department
          </h5>
        </div>{" "}
      </div>{" "}
      <div className="row align-items-center pb-3 px-3 mx-0">
        <div className="col-12">
          <Formik
            enableReinitialize={true}
            validationSchema={roleSchema}
            initialValues={{
              name: "",
              description: "",
            }}
            onSubmit={(fields) => {
              console.log("Fields==>", fields);
              dispatch(createDepartment(fields));
            }}
            render={({ values, errors, status, onChange, touched }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="form-group col-12 mb-4">
                      <label for="staticEmail" className="mb-2">
                        {" "}
                        Department Name{" "}
                      </label>{" "}
                      <Field
                        name="name"
                        type="text"
                        className={
                          `form-control form-control-lg rounded-pill` +
                          (errors.name && touched.name ? " is-invalid" : "")
                        }
                        id="staticEmail"
                      />
                      <ErrorMessage
                        name={`name`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                    <div className="form-group col-12 mb-4">
                      <label for="staticEmail" className="mb-2">
                        {" "}
                        Department Description{" "}
                      </label>{" "}
                      <Field
                        name="description"
                        className={
                          `form-control form-control form-control-lg rounded-md` +
                          (errors.description && touched.description
                            ? " is-invalid"
                            : "")
                        }
                        id="exampleFormControlTextarea1"
                        placeholder="Type Here Description"
                        rows="5"
                      >
                      </Field>{" "}
                      <ErrorMessage
                        name={`description`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>{" "}
                  </div>{" "}
                  <div className="row pt-4">
                    <div className="col-auto">
                      <button
                        onClick={() => history.goBack()}
                        type="button"
                        className="btn btn-outline-deep-blue toggle-className my-4"
                      >
                        {" "}
                        Back{" "}
                      </button>{" "}
                    </div>{" "}
                    <div className="col-auto">
                      <button
                        disabled={data.isPending}
                        type="submit"
                        className="btn btn-deep-blue toggle-className my-4"
                        data-target=".edit-users-details"
                        data-toggle-class="d-none"
                      >
                        Create Department{" "}
                        {data.isPending ? <Spinner animation="border" /> : null}{" "}
                      </button>{" "}
                    </div>{" "}
                  </div>{" "}
                </Form>
              );
            }}
          />
        </div>{" "}
      </div>{" "}
    </LayoutOne>
  );
};

export default CreateWHSDepartment;
