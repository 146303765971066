import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import SearchBox from "../../components/common/SearchBox";
import BrouserTitle from "../../components/common/BrouserTitle";
import { useHistory } from "react-router-dom";
import RFQResponseList from "../../wrapper/rfq/RFQResponseList";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { openRfqByPage } from "../../store/action/rfqAction";
import { CardLoader, ItemNotFoud } from "../../components/common/CustomLoader";

const OpenRFQResponse = () => {
  // const [activePage, setActivePage] = useState(1)
  const history = useHistory();
  const { rfqId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.RFQ_INFO);

  useEffect(() => {
    dispatch(openRfqByPage(rfqId));
  }, [dispatch, rfqId]);

  return (
    <LayoutOne>
      <BrouserTitle title="Vendor" />
      <SearchBox />
      <div className="row align-items-center py-2 px-3 mx-0">
        <div class="col-12 px-0">
          <h5 className="text-dark-blue font-weight-bold">
            {" "}
            <i
              onClick={() => history.goBack()}
              className="fas fa-chevron-left mr-3 cursorPointer"
            >
              {" "}
            </i>
            Open RFQ's Response (RFQ ID : {rfqId})
          </h5>
        </div>

        {data.isLoading ? (
          <CardLoader />
        ) : (
          <div className="col-12 px-0">
            <div className="row align-items-center py-3 px-3 mx-0">
              <div className="col-12 px-0">
                <div className="row">
                  <div className="col-12 px-0 table-responsive table-gray-admin">
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th> S.NO </th> <th> City </th> <th> Area </th>{" "}
                          <th> Vendor Name </th> <th> WH ID </th>{" "}
                          <th> Date </th> <th> Email </th> <th> Action </th>{" "}
                        </tr>{" "}
                      </thead>{" "}
                      <tbody>
                        {" "}
                        {data.rfqList.data && data.rfqList.data.length > 0
                          ? data.rfqList.data.map((item, index) => (
                              <RFQResponseList
                                item={item}
                                rfqId={rfqId}
                                index={index + 1}
                                key={index}
                              />
                            ))
                          : null}
                      </tbody>{" "}
                    </table>{" "}
                    {data.rfqList.data &&
                    data.rfqList.data.length > 0 ? null : (
                      <div className="text-center">
                        <ItemNotFoud message={`RFQ Not Found`} />{" "}
                      </div>
                    )}
                  </div>{" "}
                </div>{" "}
                {/* ============ Pagination ============ */}
                {/* <div className="pagination-custom">
                                <Pagination
                                  activePage={activePage}
                                  itemsCountPerPage={10}
                                  totalItemsCount={datas.length}
                                  pageRangeDisplayed={1}
                                  onChange={handlePageChange}
                                  prevPageText = {<i className="fas fa-chevron-left"/>}
                                  nextPageText= {<i className="fas fa-chevron-right"/>}
                                  hideFirstLastPages={true}
                                />
                              </div> */}
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </div>{" "}
    </LayoutOne>
  );
};

export default OpenRFQResponse;
