import React, { useEffect } from "react";
import LayoutOne from "../../layout/LayoutOne";
import ServiceCategoryList from "../../wrapper/serviceManagement/ServiceCategoryList";
import { useDispatch, useSelector } from "react-redux";
import {
  serviceCategoryByPage,
  responseService,
} from "../../store/action/serviceAction";
import FormSuccess from "../../components/common/FormSuccess";
import { useHistory } from "react-router-dom";
import { CardLoader, ItemNotFound } from "../../components/helper/CustomLoader";
import { Helmet } from "react-helmet";

const ManageServiceCategory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const read = useSelector((state) => state.SIDEMENU_INFO.read_only);
  const data = useSelector((state) => state.SERVICE_INFO);

  useEffect(() => {
    dispatch(serviceCategoryByPage());
  }, [dispatch]);

  const redirect = () => {
    dispatch(responseService(null));
    history.replace(`/service-category`);
  };

  return (
    <LayoutOne>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Manage Category </title>{" "}
      </Helmet>

      {data.serviceResponse !== null ? (
        <FormSuccess onClick={redirect} message={`Category Status Updated`} />
      ) : null}

      {data.isLoading ? (
        <CardLoader />
      ) : (
        <div className="row justify-content-end align-items-center py-3 px-3 mx-0">
          <div className="col-12 px-0">
            <h5 className="text-dark font-weight-bold">
              {" "}
              <i
                onClick={() => history.goBack()}
                className="fas fa-chevron-left cursorPointer"
              >
                {" "}
              </i>{" "}
              Manage Category
            </h5>
          </div>{" "}
          <div className="col-12">
            <div className="row">
              <div className="col-12 px-0 table-responsive table-gray-admin bg-white p-3">
                <table className="table text-center table-dark-custom">
                  <thead>
                    <tr>
                      <th className="w-100px pl-2"> S.No. </th>{" "}
                      <th> Category </th>{" "}
                      <th className="mw-150px"> Description </th>{" "}
                      <th> Status </th>{" "}
                      <th className="text-center"> Action </th>{" "}
                    </tr>{" "}
                  </thead>{" "}
                  <tbody>
                    {" "}
                    {data.categoryList && data.categoryList.length > 0
                      ? data.categoryList.map((item, index) => (
                          <ServiceCategoryList
                            item={item}
                            index={index + 1}
                            key={index}
                            read={read}
                          />
                        ))
                      : null}{" "}
                  </tbody>{" "}
                </table>

                {data.categoryList && data.categoryList.length > 0 ? null : (
                  <ItemNotFound message="Category Not Found" />
                )}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      )}
    </LayoutOne>
  );
};

export default ManageServiceCategory;
