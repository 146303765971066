import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateItInfra,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";

const OfficePendingForm = ({ warehouseId, viewMood }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const validationSchema = function (values) {
    return Yup.object().shape({
      itInfra: Yup.object().shape({
        desktop: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        printer: Yup.object().shape({
          whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        printerMultifunction: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        upsAndInverter: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        broadBand: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        scanner: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        telephone: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        wms: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        itinfra_other: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
      }),
      officeSpace: Yup.object().shape({
        area: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        airConditioner: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        workStations: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        tables: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        cabins: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        meetingRooms: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        recordRooms: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        strongRooms: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        securityGuardOfficeTable: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        electricLoad: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
        officespace_other: Yup.object().shape({
          whstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
          // etc
        }),
      }),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        itAndOfficeInfraRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        safetyAndSecurityRemark: {
          ...data.singleFormData.safetyAndSecurityRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateItInfra(payload));
  };
  const [formField, setFormField] = useState(null);
  // eslint-disable-next-line
  const [remarkForm, setRemarkForm] = useState({
    itInfra: {
      desktop: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      printer: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      printerMultifunction: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      upsAndInverter: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      broadBand: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      scanner: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      telephone: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      wms: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      itinfra_other: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
    },
    officeSpace: {
      area: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      airConditioner: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      workStations: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },

      chairs: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      tables: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      cabins: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      meetingRooms: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      recordRooms: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      strongRooms: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      securityGuardOfficeTable: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      electricLoad: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
      officespace_other: {
        value: "",

        whsstatus: "null",
        whsremark: "",
        fastatus: "null",
        faremark: "",
      },
    },
  });

  useEffect(() => {
    if (
      data.singleFormData &&
      data.singleFormData !== null &&
      data.singleFormData.itInfraAndOfficeSpaceInfo !== null &&
      data.type === "itInfrAndOfficeSpace"
    ) {
      setFormField(data.singleFormData.itInfraAndOfficeSpaceInfo);
      if (data.singleFormData.itAndOfficeInfraRemark) {
        setRemarkForm({
          itInfra: {
            desktop: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.desktop
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.desktop
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.desktop
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.desktop
                    .faremark
                : "",
            },
            printer: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.printer
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.printer
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.printer
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.printer
                    .faremark
                : "",
            },
            printerMultifunction: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .printerMultifunction.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .printerMultifunction.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .printerMultifunction.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .printerMultifunction.faremark
                : "",
            },
            upsAndInverter: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .upsAndInverter.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .upsAndInverter.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .upsAndInverter.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .upsAndInverter.faremark
                : "",
            },
            broadBand: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.broadBand
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.broadBand
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.broadBand
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.broadBand
                    .faremark
                : "",
            },
            scanner: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.scanner
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.scanner
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.scanner
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.scanner
                    .faremark
                : "",
            },
            telephone: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.telephone
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.telephone
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.telephone
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.telephone
                    .faremark
                : "",
            },
            wms: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.wms
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.wms
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.wms
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra.wms
                    .faremark
                : "",
            },
            itinfra_other: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .itinfra_other.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .itinfra_other.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .itinfra_other.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.itInfra
                    .itinfra_other.faremark
                : "",
            },
          },
          officeSpace: {
            area: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.area
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.area
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.area
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.area
                    .faremark
                : "",
            },
            airConditioner: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .airConditioner.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .airConditioner.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .airConditioner.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .airConditioner.faremark
                : "",
            },
            workStations: {
              value: "",
              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .workStations.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .workStations.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .workStations.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .workStations.faremark
                : "",
            },
            chairs: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.chairs
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.chairs
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.chairs
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.chairs
                    .faremark
                : "",
            },
            tables: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.tables
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.tables
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.tables
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.tables
                    .faremark
                : "",
            },
            cabins: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.cabins
                    .whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.cabins
                    .whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.cabins
                    .fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace.cabins
                    .faremark
                : "",
            },
            meetingRooms: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .meetingRooms.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .meetingRooms.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .meetingRooms.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .meetingRooms.faremark
                : "",
            },
            recordRooms: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .recordRooms.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .recordRooms.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .recordRooms.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .recordRooms.faremark
                : "",
            },
            strongRooms: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .strongRooms.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .strongRooms.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .strongRooms.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .strongRooms.faremark
                : "",
            },
            securityGuardOfficeTable: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .securityGuardOfficeTable.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .securityGuardOfficeTable.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .securityGuardOfficeTable.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .securityGuardOfficeTable.faremark
                : "",
            },
            electricLoad: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .electricLoad.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .electricLoad.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .electricLoad.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .electricLoad.faremark
                : "",
            },
            officespace_other: {
              value: "",

              whsstatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .officespace_other.whsstatus
                : "",
              whsremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .officespace_other.whsremark
                : "",
              fastatus: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .officespace_other.fastatus
                : "",
              faremark: data.singleFormData.itAndOfficeInfraRemark
                ? data.singleFormData.itAndOfficeInfraRemark.officeSpace
                    .officespace_other.faremark
                : "",
            },
          },
        });
      } else {
        setRemarkForm({
          itInfra: {
            desktop: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            printer: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            printerMultifunction: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            upsAndInverter: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            broadBand: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            scanner: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            telephone: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            wms: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            itinfra_other: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
          },
          officeSpace: {
            area: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            airConditioner: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            workStations: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },

            chairs: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            tables: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            cabins: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            meetingRooms: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            recordRooms: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            strongRooms: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            securityGuardOfficeTable: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            electricLoad: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
            officespace_other: {
              value: "",

              whsstatus: "null",
              whsremark: "",
              fastatus: "null",
              faremark: "",
            },
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form className="w-100">
              <div className="col-12 rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12">
                    <h6 className="py-3 mb-3 border-bottom"> IT Infra </h6>{" "}
                  </div>{" "}
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              Okay{" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              Not Okay{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Remarks{" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              Field Agent Remarks{" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Desktop{" "}
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.itInfra &&
                                      formField.itInfra.desktop !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      className="form-control px-4"
                                      type="text"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra.desktop
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.desktop.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="xdxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.desktop.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.desktop.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="xdxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.desktop.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="yycnbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.desktop.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.desktop.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="yycnbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      name={`itInfra.desktop.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.desktop.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.itInfra.printer.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Printer:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.itInfra &&
                                      formField.itInfra.printer !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      className="form-control px-4"
                                      type="text"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra.printer
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.desktop.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="xdxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.desktop.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.desktop.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="xdxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.desktop.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="yycnbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.desktop.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.desktop.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="yycnbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      name={`itInfra.desktop.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.desktop.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.itInfra.printer.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Printer(Multi Function):
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.itInfra &&
                                      formField.itInfra.printerMultifunction !==
                                        ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      className="form-control px-4"
                                      type="text"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra
                                              .printerMultifunction
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.printerMultifunction.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="qqnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.printerMultifunction
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.printerMultifunction.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="qqnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.printerMultifunction.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="wwnbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.printerMultifunction
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.printerMultifunction.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="wwnbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`itInfra.printerMultifunction.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.printerMultifunction
                                  .fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  values.itInfra.printerMultifunction.faremark
                                }
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      UPS / Inverter:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.itInfra &&
                                      formField.itInfra.upsAndInverter !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty(KVA): </p>{" "}
                                    </td>{" "}
                                    <input
                                      className="form-control px-4"
                                      type="text"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra
                                              .printerMultifunction
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.upsAndInverter.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="aanbcategoryOkay"
                                      hidden="true"
                                      type="radio"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.upsAndInverter
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.upsAndInverter.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="aanbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.upsAndInverter.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="vvnbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.upsAndInverter
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.upsAndInverter.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="vvnbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`itInfra.upsAndInverter.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.upsAndInverter.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.itInfra.upsAndInverter.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Broadband:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.itInfra &&
                                      formField.itInfra.upsAndInverter !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> MBPS: </p>{" "}
                                    </td>{" "}
                                    <input
                                      className="form-control px-4"
                                      type="text"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra
                                              .printerMultifunction
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.broadBand.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="llnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.broadBand.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.broadBand.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="llnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.broadBand.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="qqnbcategoryNotOkay"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.broadBand.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.broadBand.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="qqnbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`itInfra.broadBand.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.broadBand.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.itInfra.scanner.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Scanner:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.itInfra &&
                                      formField.itInfra.scanner !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      className="form-control px-4"
                                      type="text"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra.scanner
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.broadBand.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="llnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.broadBand.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.broadBand.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="llnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.scanner.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="mmnbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.scanner.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.scanner.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="mmnbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`itInfra.broadBand.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.scanner.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Telephone(Landline):
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.itInfra &&
                                      formField.itInfra.scanner !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra.telephone
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.broadBand.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="llnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.broadBand.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.broadBand.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="llnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.telephone.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryNotOkaybb"
                                      value="notOkay"
                                      hideen="true"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.telephone.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.telephone.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryNotOkaybb"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`itInfra.telephone.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.telephone.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.itInfra.telephone.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      WMS:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.itInfra &&
                                      formField.itInfra.wms !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra.wms
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.wms.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryOkayzz"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.wms.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.wms.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryOkayzz"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.wms.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryNotOkayss"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.wms.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.wms.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryNotOkayss"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`itInfra.wms.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.wms.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.itInfra.wms.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Others:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <textarea
                                      type="text"
                                      className="form-control px-4 rounded-md"
                                      value={
                                        formField && formField.itInfra
                                          ? formField.itInfra.others
                                          : ""
                                      }
                                      rows="4"
                                      disabled
                                    ></textarea>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.itinfra_other.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcaaategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.itinfra_other
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.itinfra_other.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcaaategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="itInfra.itinfra_other.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcssategoryNotOkay"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.itInfra.itinfra_other
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "itInfra.itinfra_other.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcssategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`itInfra.itinfra_other.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-5 mt-2">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.itInfra.itinfra_other.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.itInfra.itinfra_other.faremark}
                                type="text"
                                className="form-control px-4 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
                <div className="row">
                  <div className="col-12">
                    <h6 className="py-3 mb-3 border-bottom"> Office Space </h6>{" "}
                  </div>{" "}
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Area(sqft):
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0"> </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.area
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.area.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="xxxnbcategoryaaOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.area.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.area.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="xxxnbcategoryaaOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.area.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="xxxnbcategoryaaNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.area.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.area.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="xxxnbcategoryaaNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.area.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.area.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.officeSpace.area.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Air Conditioner:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.officeSpace &&
                                      formField.officeSpace.airConditioner !==
                                        ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.airConditioner
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.airConditioner.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategorybbOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.airConditioner
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.airConditioner.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategorybbOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.airConditioner.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategorybbNotOkay"
                                      value="notOkay"
                                      required
                                      hidden="true"
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.airConditioner
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.airConditioner.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategorybbNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.area.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.airConditioner.fastatus ===
                                true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  values.officeSpace.airConditioner.faremark
                                }
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Work Stations:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.officeSpace &&
                                      formField.officeSpace.workStations !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.workStations
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.workStations.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryccOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.workStations
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.workStations.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryccOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.workStations.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryccNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.workStations
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.workStations.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryccNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.area.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.workStations.fastatus ===
                                true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.officeSpace.workStations.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Tables:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.officeSpace &&
                                      formField.officeSpace.workStations !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.workStations
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.tables.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryffOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.tables.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.tables.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryffOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.tables.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryffNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.tables.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.tables.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryffNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <input
                                      disabled
                                      value={values.officeSpace.tables.faremark}
                                      type="text"
                                      className="form-control px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.tables.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.officeSpace.workStations.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Cabins:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.officeSpace &&
                                      formField.officeSpace.workStations !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.cabins
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.cabins.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryggOkay"
                                      hidden="true"
                                      type="radio"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.cabins.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.cabins.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryggOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.cabins.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryggNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.cabins.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.cabins.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryggNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.cabins.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.cabins.fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.officeSpace.cabins.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Meeting Rooms:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    {" "}
                                    {/* <p className="text-gray py-3 mb-0">
                                                              {formField &&
                                                              formField.officeSpace &&
                                                              formField.officeSpace.workStations !== ""
                                                                ? "Yes"
                                                                : "No"}
                                                            </p> */}{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.meetingRooms
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.meetingRooms.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryhhOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.meetingRooms
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.meetingRooms.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryhhOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.cabins.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryggNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.cabins.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.cabins.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryggNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.cabins.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.meetingRooms.fastatus ===
                                true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.officeSpace.meetingRooms.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Record Rooms:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.officeSpace &&
                                      formField.officeSpace.recordRooms !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.recordRooms
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.recordRooms.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryiiOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.recordRooms
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.recordRooms.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryiiOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.recordRooms.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryiiNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.recordRooms
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.recordRooms.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryiiNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.recordRooms.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.recordRooms.fastatus ===
                                true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.officeSpace.recordRooms.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Strong Rooms:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    {" "}
                                    {/* <p className="text-gray py-3 mb-0">
                                                           {formField &&
                                                      formField.officeSpace &&
                                                      formField.officeSpace.strongRooms !== ""
                                                        ? "Yes"
                                                        : "No"}
                                                            </p>  */}{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.strongRooms
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.strongRooms.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryjjOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.strongRooms
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.strongRooms.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryjjOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.strongRooms.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryjjNotOkay"
                                      hidden="true"
                                      value="notOkay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.strongRooms
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.strongRooms.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryjjNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.strongRooms.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.strongRooms.fastatus ===
                                true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.officeSpace.strongRooms.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Security Guard Office / Table:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {formField &&
                                      formField.officeSpace &&
                                      formField.officeSpace
                                        .securityGuardOfficeTable !== ""
                                        ? "Yes"
                                        : "No"}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4 mb-2"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace
                                              .securityGuardOfficeTable
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.securityGuardOfficeTable.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryssOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace
                                          .securityGuardOfficeTable
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.securityGuardOfficeTable.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryssOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.securityGuardOfficeTable.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryssNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace
                                          .securityGuardOfficeTable
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.securityGuardOfficeTable.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryssNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.securityGuardOfficeTable.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.securityGuardOfficeTable
                                  .fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  values.officeSpace.securityGuardOfficeTable
                                    .faremark
                                }
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Electric Load:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {/* {formField &&
                                                              formField.officeSpace &&
                                                              formField.officeSpace
                                                                .securityGuardOfficeTable !== ""
                                                                ? "Yes"
                                                                : "No"} */}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <input
                                      type="text"
                                      className="form-control px-4"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.electricLoad
                                          : ""
                                      }
                                      disabled
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.electricLoad.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategoryvvOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.electricLoad
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.electricLoad.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryvvOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.electricLoad.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategoryvvNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.electricLoad
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.electricLoad.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategoryvvNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.electricLoad.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.electricLoad.fastatus ===
                                true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={values.officeSpace.electricLoad.faremark}
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-10">
                      <table class="table table-borderless mb-0 d-non">
                        <thead>
                          <tr>
                            <th scope="col" class="col-6 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center pb-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <label
                                      htmlFor="staticEmail"
                                      className="mb-2 px-2"
                                    >
                                      Others:
                                    </label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                                <tr>
                                  <td className="py-0 d-flex">
                                    <p className="text-gray py-3 mb-0">
                                      {" "}
                                      {/* {formField &&
                                                              formField.officeSpace &&
                                                              formField.officeSpace
                                                                .securityGuardOfficeTable !== ""
                                                                ? "Yes"
                                                                : "No"} */}{" "}
                                    </p>{" "}
                                    <td className="py-0">
                                      <p className="mb-0 pt-3"> Qty: </p>{" "}
                                    </td>{" "}
                                    <textarea
                                      type="text"
                                      className="form-control px-4 rounded-md"
                                      value={
                                        formField && formField.officeSpace
                                          ? formField.officeSpace.others
                                          : ""
                                      }
                                      rows="4"
                                      disabled
                                    ></textarea>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.officespace_other.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="nbcategorymmOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.officespace_other
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.officespace_other.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategorymmOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check mt-4 common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="officeSpace.officespace_other.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="nbcategorymmNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.officeSpace.officespace_other
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "officeSpace.officespace_other.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="nbcategorymmNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="pb-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`officeSpace.officespace_other.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="pb-0 d-flex pt-4 mt-4">
                              <p className="py-2 text-nowrap pr-4">
                                {" "}
                                {values.officeSpace.officespace_other
                                  .fastatus === true
                                  ? "okay"
                                  : "not okay"}{" "}
                              </p>{" "}
                              <input
                                disabled
                                value={
                                  values.officeSpace.officespace_other.faremark
                                }
                                type="text"
                                className="form-control px-4 mb-2 mr-4"
                                placeholder="Remarks"
                              />
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                  </div>{" "}
                </div>{" "}
                <div className={`col-12 mt-4`}>
                  <div className="row justify-content-end"> </div>{" "}
                </div>{" "}
              </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default OfficePendingForm;
