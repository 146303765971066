import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import {
  updateSafetySecurity,
  addNewWarehouse,
} from "./../../../store/action/warehouseAction";
import FormSuccess from "./../../../components/common/FormSuccess";
import * as Yup from "yup";

const SafetyPendingForm = ({ warehouseId, viewMood }) => {
  const data = useSelector((state) => state.WAREHOUSEINFO);
  const dispatch = useDispatch();
  const readableDate = (dateReceive) => {
    const d = new Date(dateReceive);
    var datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
    return datestring;
  };
  const validationSchema = function (values) {
    return Yup.object().shape({
      fireExtinguishers: Yup.array().of(
        Yup.object().shape({
          fireExtinguishers: Yup.object().shape({
            whsstatus: Yup.bool().oneOf(
              [true, false],
              "Please Select a option"
            ),
            // etc
          }),
        })
      ),
      fireSprinklers: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      fireHydrant: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),

      cctv: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      waterTank: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      smokeDetectorsAndFireAlarm: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      securityGuard: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      metalDetector: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      pestControl: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
      safetySecurityOther: Yup.object().shape({
        whsstatus: Yup.bool().oneOf([true, false], "Please Select a option"),
        // etc
      }),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, {
          abortEarly: false,
        });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };
  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const onSubmit = (fields) => {
    const payload = {
      data: {
        safetyAndSecurityRemark: {
          ...fields,
        },
        warehouseContactDetailRemark: {
          ...data.singleFormData.warehouseContactDetailRemark,
        },
        storageSpaceRemark: {
          ...data.singleFormData.storageSpaceRemark,
        },
        itAndOfficeInfraRemark: {
          ...data.singleFormData.itAndOfficeInfraRemark,
        },
        mhInfraRemark: {
          ...data.singleFormData.mhInfraRemark,
        },
        warehouseRemark: {
          ...data.singleFormData.warehouseRemark,
        },
        permitsRemark: {
          ...data.singleFormData.permitsRemark,
        },

        materialTypeRemark: {
          ...data.singleFormData.materialTypeRemark,
        },
        warehouseImageRemark: {
          ...data.singleFormData.warehouseImageRemark,
        },
        formEightyRemark: {
          ...data.singleFormData.formEightyRemark,
        },
      },

      warehouseId: warehouseId,
    };
    dispatch(updateSafetySecurity(payload));
  };
  const [formField, setFormField] = useState(null);
  const [remarkForm, setRemarkForm] = useState({
    fireExtinguishers: [
      {
        type: "abc",
        fireExtinguisher: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "co2",
        fireExtinguisher: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
      {
        type: "h20",
        fireExtinguisher: {
          value: "",

          whsstatus: "null",
          whsremark: "",
          fastatus: "null",
          faremark: "",
        },
      },
    ],
    fireSprinklers: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    fireHydrant: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    cctv: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    waterTank: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    smokeDetectorsAndFireAlarm: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    securityGuard: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    metalDetector: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    pestControl: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
    safetySecurityOther: {
      value: "",

      whsstatus: "null",
      whsremark: "",
      fastatus: "null",
      faremark: "",
    },
  });

  useEffect(() => {
    if (data.singleFormData && data.type === "safetyAndSecurity") {
      setFormField(data.singleFormData.safetyAndSecurityInfo);
      if (data.singleFormData.safetyAndSecurityRemark) {
        setRemarkForm({
          fireExtinguishers: [
            {
              type: "abc",
              fireExtinguisher: {
                value: "",

                whsstatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[0]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[0].fireExtinguisher.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[0]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[0].fireExtinguisher.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[0]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[0].fireExtinguisher.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[0]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[0].fireExtinguisher.faremark
                    : ""
                  : "",
              },
            },
            {
              type: "co2",
              fireExtinguisher: {
                value: "",

                whsstatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[1]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[1].fireExtinguisher.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[1]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[1].fireExtinguisher.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[1]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[1].fireExtinguisher.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[1]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[1].fireExtinguisher.faremark
                    : ""
                  : "",
              },
            },
            {
              type: "h20",
              fireExtinguisher: {
                value: "",

                whsstatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[2]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[2].fireExtinguisher.whsstatus
                    : ""
                  : "",
                whsremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[2]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[2].fireExtinguisher.whsremark
                    : ""
                  : "",
                fastatus: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[2]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[2].fireExtinguisher.fastatus
                    : ""
                  : "",
                faremark: data.singleFormData.safetyAndSecurityRemark
                  ? data.singleFormData.safetyAndSecurityRemark
                      .fireExtinguishers[2]
                    ? data.singleFormData.safetyAndSecurityRemark
                        .fireExtinguishers[2].fireExtinguisher.faremark
                    : ""
                  : "",
              },
            },
          ],
          fireSprinklers: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireSprinklers
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireSprinklers
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireSprinklers
                  .fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireSprinklers
                  .faremark
              : "",
          },
          fireHydrant: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireHydrant
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireHydrant
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireHydrant.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.fireHydrant.faremark
              : "",
          },
          cctv: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.cctv.whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.cctv.whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.cctv.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.cctv.faremark
              : "",
          },
          waterTank: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.waterTank.whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.waterTank.whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.waterTank.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.waterTank.faremark
              : "",
          },
          smokeDetectorsAndFireAlarm: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark
                  .smokeDetectorsAndFireAlarm.whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark
                  .smokeDetectorsAndFireAlarm.whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark
                  .smokeDetectorsAndFireAlarm.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark
                  .smokeDetectorsAndFireAlarm.faremark
              : "",
          },
          securityGuard: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.securityGuard
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.securityGuard
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.securityGuard
                  .fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.securityGuard
                  .faremark
              : "",
          },
          metalDetector: {
            value: "",
            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.metalDetector
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.metalDetector
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.metalDetector
                  .fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.metalDetector
                  .faremark
              : "",
          },
          pestControl: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.pestControl
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.pestControl
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.pestControl.fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.pestControl.faremark
              : "",
          },
          safetySecurityOther: {
            value: "",

            whsstatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.safetySecurityOther
                  .whsstatus
              : "",
            whsremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.safetySecurityOther
                  .whsremark
              : "",
            fastatus: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.safetySecurityOther
                  .fastatus
              : "",
            faremark: data.singleFormData.safetyAndSecurityRemark
              ? data.singleFormData.safetyAndSecurityRemark.safetySecurityOther
                  .faremark
              : "",
          },
        });
      } else {
        setRemarkForm({
          fireExtinguishers: [
            {
              type: "abc",
              fireExtinguisher: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              type: "co2",
              fireExtinguisher: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
            {
              type: "h20",
              fireExtinguisher: {
                value: "",

                whsstatus: "null",
                whsremark: "",
                fastatus: "null",
                faremark: "",
              },
            },
          ],
          fireSprinklers: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          fireHydrant: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          cctv: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          waterTank: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          smokeDetectorsAndFireAlarm: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          securityGuard: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          metalDetector: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          pestControl: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
          safetySecurityOther: {
            value: "",

            whsstatus: "null",
            whsremark: "",
            fastatus: "null",
            faremark: "",
          },
        });
      }
    }
  }, [data.singleFormData, data.type]);

  return (
    <>
      {" "}
      {data.addNewResponse && data.addNewResponse.statusCode === 200 ? (
        <FormSuccess
          onClick={() => dispatch(addNewWarehouse([]))}
          message={data.addNewResponse.message}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={remarkForm}
        validate={validate(validationSchema)}
        onSubmit={(fields) => onSubmit(fields)}
        render={({
          errors,
          status,
          onChange,
          touched,
          values,
          setFieldValue,
        }) => {
          return (
            <Form>
              <div className="col-12 rounded-lg px-md-4">
                <div className="row">
                  <div className="col-12 border-0 border-bottom-0 table-responsive table-collapse">
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                              Okay{" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              Not Okay{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Remarks{" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              Field Agent Remarks{" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td class="align-items-end d-flex mb-0 pb-1 px-0 ">
                                    <td className="px-0 d-flex align-items-sm-center">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Fire Extinguisher(ABC):
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2 align-self-center">
                                        {" "}
                                        {formField
                                          ? formField.fireExtinguishers
                                            ? formField.fireExtinguishers[1]
                                                .fireExtinguisherValidity
                                                .validFrom
                                              ? "Yes"
                                              : "No"
                                            : ""
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        value={
                                          formField
                                            ? formField.fireExtinguishers
                                              ? formField.fireExtinguishers[1]
                                                  .quantity
                                              : ""
                                            : ""
                                        }
                                        type="text"
                                        className="form-control px-4 mb-1"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 pt-2">
                                        <table>
                                          <tr>
                                            <td className="py-0">
                                              <label
                                                htmlFor=""
                                                className="mb-0 px-4 small text-uppercase"
                                              >
                                                Valid from{" "}
                                              </label>{" "}
                                              <input
                                                type="date"
                                                value={readableDate(
                                                  formField
                                                    ? formField.fireExtinguishers
                                                      ? formField
                                                          .fireExtinguishers[1]
                                                          .fireExtinguisherValidity
                                                          .validFrom
                                                      : ""
                                                    : ""
                                                )}
                                                className="form-control px-4 mb-2"
                                                placeholder=""
                                                disabled
                                              />
                                            </td>{" "}
                                            <td className="py-0">
                                              <label
                                                htmlFor=""
                                                className="mb-0 px-4 small text-uppercase"
                                              >
                                                Valid till{" "}
                                              </label>{" "}
                                              <input
                                                type="date"
                                                value={readableDate(
                                                  formField
                                                    ? formField.fireExtinguishers
                                                      ? formField
                                                          .fireExtinguishers[1]
                                                          .fireExtinguisherValidity
                                                          .validTill
                                                      : ""
                                                    : ""
                                                )}
                                                className="form-control px-4 mb-2"
                                                placeholder=""
                                                disabled
                                              />
                                            </td>{" "}
                                          </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="bottom" className="py-0 pt-4">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2 py-0">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireExtinguishers[0].fireExtinguisher.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="f1ccmmjjxxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireExtinguishers[0]
                                          ? values.fireExtinguishers[0]
                                              .fireExtinguisher.whsstatus ===
                                            true
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireExtinguishers[0].fireExtinguisher.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="f1ccmmjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 pt-4" verticalAlign="bottom">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="bottom"
                                  >
                                    <Field
                                      name="fireExtinguishers[0].fireExtinguisher.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="dffffff"
                                      type="radio"
                                      value="NotOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireExtinguishers[0]
                                          ? values.fireExtinguishers[0]
                                              .fireExtinguisher.whsstatus ===
                                            false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireExtinguishers[0].fireExtinguisher.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="dffffff"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 mt-4">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 pt-3">
                                    <Field
                                      disabled={viewMood}
                                      name={`fireExtinguishers[0].fireExtinguisher.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4  mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex align-items-end pt-3">
                              <td className="d-flex px-0 align-items-lg-baseline">
                                <p className=" text-nowrap pr-4">
                                  {" "}
                                  {values.fireExtinguishers[0] &&
                                  values.fireExtinguishers[0].fireExtinguisher
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.fireExtinguishers[0] &&
                                    values.fireExtinguishers[0].fireExtinguisher
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-end py-0 pb-1">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-2 px-2 text-nowrap"
                                      >
                                        Fire Extinguisher(CO2):
                                      </label>{" "}
                                      <p className="text-gray mb-2 px-2">
                                        {" "}
                                        {formField
                                          ? formField.fireExtinguishers
                                            ? formField.fireExtinguishers[0]
                                                .fireExtinguisherValidity
                                                .validFrom
                                              ? "Yes"
                                              : "No"
                                            : ""
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        value={
                                          formField
                                            ? formField.fireExtinguishers
                                              ? formField.fireExtinguishers[0]
                                                  .quantity
                                              : ""
                                            : ""
                                        }
                                        type="text"
                                        className="form-control px-4 mb-1"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 pt-2">
                                        <table>
                                          <tr>
                                            <td className="py-0">
                                              <label
                                                htmlFor=""
                                                className="mb-0 px-4 small text-uppercase"
                                              >
                                                Valid from{" "}
                                              </label>{" "}
                                              <input
                                                value={readableDate(
                                                  formField
                                                    ? formField.fireExtinguishers
                                                      ? formField
                                                          .fireExtinguishers[0]
                                                          .fireExtinguisherValidity
                                                          .validFrom
                                                      : ""
                                                    : ""
                                                )}
                                                type="date"
                                                className="form-control px-4 mb-2"
                                                placeholder=""
                                                disabled
                                              />
                                            </td>{" "}
                                            <td className="py-0">
                                              <label
                                                htmlFor=""
                                                className="mb-0 px-4 small text-uppercase"
                                              >
                                                Valid till{" "}
                                              </label>{" "}
                                              <input
                                                value={readableDate(
                                                  formField
                                                    ? formField.fireExtinguishers
                                                      ? formField
                                                          .fireExtinguishers[0]
                                                          .fireExtinguisherValidity
                                                          .validTill
                                                      : ""
                                                    : ""
                                                )}
                                                type="date"
                                                className="form-control px-4 mb-2"
                                                placeholder=""
                                                disabled
                                              />
                                            </td>{" "}
                                          </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireExtinguishers[1].fireExtinguisher.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="f11ccmmjjxxnbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireExtinguishers[1]
                                          ? values.fireExtinguishers[1]
                                              .fireExtinguisher.whsstatus ===
                                            true
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireExtinguishers[1].fireExtinguisher.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="f11ccmmjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireExtinguishers[1].fireExtinguisher.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="xxxx"
                                      type="radio"
                                      value="NotOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireExtinguishers[1]
                                          ? values.fireExtinguishers[1]
                                              .fireExtinguisher.whsstatus ===
                                            false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireExtinguishers[1].fireExtinguisher.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="xxxx"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      name={`fireExtinguishers[1].fireExtinguisher.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex align-items-center pt-2">
                              <td className="d-flex px-0 align-item-center">
                                <p className="text-nowrap pr-4">
                                  {" "}
                                  {values.fireExtinguishers[1] &&
                                  values.fireExtinguishers[1].fireExtinguisher
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.fireExtinguishers[1] &&
                                    values.fireExtinguishers[1].fireExtinguisher
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-sm-center">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-0 px-2 text-nowrap"
                                      >
                                        Fire Extinguisher(Others):
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField
                                          ? formField.fireExtinguishers
                                            ? formField.fireExtinguishers[2]
                                                .fireExtinguisherValidity
                                                .validFrom
                                              ? "Yes"
                                              : "No"
                                            : ""
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        type="text"
                                        value={
                                          formField
                                            ? formField.fireExtinguishers
                                              ? formField.fireExtinguishers[2]
                                                  .quantity
                                              : ""
                                            : ""
                                        }
                                        className="form-control "
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table>
                                          <tr>
                                            <td className="py-0">
                                              <label
                                                htmlFor=""
                                                className="mb-0 px-4 small text-uppercase"
                                              >
                                                Valid from{" "}
                                              </label>{" "}
                                              <input
                                                value={readableDate(
                                                  formField
                                                    ? formField.fireExtinguishers
                                                      ? formField
                                                          .fireExtinguishers[2]
                                                          .fireExtinguisherValidity
                                                          .validFrom
                                                      : ""
                                                    : ""
                                                )}
                                                type="date"
                                                className="form-control px-4 mb-2"
                                                placeholder=""
                                                disabled
                                              />
                                            </td>{" "}
                                            <td className="py-0">
                                              <label
                                                htmlFor=""
                                                className="mb-0 px-4 small text-uppercase"
                                              >
                                                Valid till{" "}
                                              </label>{" "}
                                              <input
                                                value={readableDate(
                                                  formField
                                                    ? formField.fireExtinguishers
                                                      ? formField
                                                          .fireExtinguishers[2]
                                                          .fireExtinguisherValidity
                                                          .validTill
                                                      : ""
                                                    : ""
                                                )}
                                                type="date"
                                                className="form-control px-4 "
                                                placeholder=""
                                                disabled
                                              />
                                            </td>{" "}
                                          </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireExtinguishers[2].fireExtinguisher.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="1f11ccmmjjxxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireExtinguishers[2]
                                          ? values.fireExtinguishers[2]
                                              .fireExtinguisher.whsstatus ===
                                            true
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireExtinguishers[2].fireExtinguisher.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="1f11ccmmjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireExtinguishers[2].fireExtinguisher.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="yyyy"
                                      type="radio"
                                      value="NotOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireExtinguishers[2]
                                          ? values.fireExtinguishers[2]
                                              .fireExtinguisher.whsstatus ===
                                            false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireExtinguishers[2].fireExtinguisher.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="yyyy"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`fireExtinguishers[2].fireExtinguisher.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex pt-3">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.fireExtinguishers[2] &&
                                  values.fireExtinguishers[2].fireExtinguisher
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.fireExtinguishers[2] &&
                                    values.fireExtinguishers[2].fireExtinguisher
                                      .faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-sm-center py-0">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-0 px-2 text-nowrap"
                                      >
                                        {" "}
                                        Fire Sprinklers:
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField
                                          ? formField.fireSprinklers
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        value={
                                          formField
                                            ? formField.fireSprinklers
                                            : ""
                                        }
                                        type="text"
                                        className="form-control px-4 mb-2"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table>
                                          <tr> </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireSprinklers.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="vvbbjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireSprinklers.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireSprinklers.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="vvbbjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireExtinguishers[2].fireExtinguisher.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="yyyy"
                                      type="radio"
                                      value="NotOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireExtinguishers[2]
                                          ? values.fireExtinguishers[2]
                                              .fireExtinguisher.whsstatus ===
                                            false
                                          : ""
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireExtinguishers[2].fireExtinguisher.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="yyyy"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`fireSprinklers.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.fireSprinklers.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.fireSprinklers.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-sm-center py-0">
                                      <label
                                        htmlFor="staticEmail"
                                        className="mb-0 px-2 text-nowrap"
                                      >
                                        Fire Hydrant:
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField
                                          ? formField.fireHydrant
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        type="text"
                                        value={
                                          formField ? formField.fireHydrant : ""
                                        }
                                        className="form-control px-4"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table>
                                          <tr> </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireHydrant.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="vcvbbjjxxnbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireHydrant.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireHydrant.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="vcvbbjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireHydrant.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="vcvbbjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireHydrant.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireHydrant.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="vcvbbjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 ">
                                    <Field
                                      disabled={viewMood}
                                      name={`fireSprinklers.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.fireHydrant.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.fireSprinklers.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-center py-0">
                                      <label
                                        htmlFor="staticEmail"
                                        className=" px-2 text-nowrap"
                                      >
                                        CCTV:
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField ? formField.cctv : ""}{" "}
                                      </p>{" "}
                                      <input
                                        value={formField ? formField.cctv : ""}
                                        type="text"
                                        className="form-control px-4"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table>
                                          <tr> </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="fireHydrant.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="vcvbbjjxxnbcategoryOkay"
                                      type="radio"
                                      hidden="true"
                                      value="okay"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.fireHydrant.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "fireHydrant.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="vcvbbjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="cctv.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="bbmjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={values.cctv.whsstatus === false}
                                      onChange={() =>
                                        setFieldValue("cctv.whsstatus", false)
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbmjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`cctv.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.cctv.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.cctv.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-sm-center">
                                      <label
                                        htmlFor="staticEmail"
                                        className="px-2 text-nowrap"
                                      >
                                        Water Tank:
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField
                                          ? formField.waterTank
                                            ? "Yes"
                                            : "No"
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        value={
                                          formField
                                            ? formField.waterTank.quantity
                                            : ""
                                        }
                                        type="text"
                                        className="form-control px-4"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100">
                                        <table>
                                          <tr> </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="waterTank.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="bbjjxxnbcategoryvvOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.waterTank.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "waterTank.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjxxnbcategoryvvOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="waterTank.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="bbjjyynbcategoryvvNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.waterTank.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "waterTank.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjyynbcategoryvvNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`waterTank.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.waterTank.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.waterTank.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0 w-100">
                                    <td className="px-0 d-flex align-items-sm-center w-100">
                                      <label
                                        htmlFor="staticEmail"
                                        className=" px-2 mb-0 text-nowrap"
                                      >
                                        Smoke Detectors / Fire Alarm:
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField
                                          ? formField.smokeDetectorsAndFireAlarm
                                            ? "Yes"
                                            : "No"
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        type="text"
                                        value={
                                          formField
                                            ? formField.smokeDetectorsAndFireAlarm
                                            : ""
                                        }
                                        className="form-control mt-2 mb-2"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table>
                                          <tr> </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="smokeDetectorsAndFireAlarm.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="llbbjjxxnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.smokeDetectorsAndFireAlarm
                                          .whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "smokeDetectorsAndFireAlarm.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="llbbjjxxnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="smokeDetectorsAndFireAlarm.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="llbbjjyynbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.smokeDetectorsAndFireAlarm
                                          .whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "smokeDetectorsAndFireAlarm.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="llbbjjyynbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`smokeDetectorsAndFireAlarm.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.smokeDetectorsAndFireAlarm
                                    .fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.smokeDetectorsAndFireAlarm.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-sm-center">
                                      <label
                                        htmlFor="staticEmail"
                                        className=" px-2 mb-0 text-nowrap"
                                      >
                                        Security Guard:
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField
                                          ? formField.smokeDetectorsAndFireAlarm
                                            ? "Yes"
                                            : "No"
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        value={
                                          formField
                                            ? formField.securityGuard
                                            : ""
                                        }
                                        type="text"
                                        className="form-control px-4 mt-2 mb-2"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table>
                                          <tr> </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="securityGuard.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="bbjjxxbbnbcategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.securityGuard.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "securityGuard.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjxxbbnbcategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="securityGuard.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="bbjjyynbbbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.securityGuard.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "securityGuard.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjyynbbbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`securityGuard.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.securityGuard.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.smokeDetectorsAndFireAlarm.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-sm-center">
                                      <label
                                        htmlFor="staticEmail"
                                        className=" px-2 mb-0 text-nowrap"
                                      >
                                        Metal Detector:
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField
                                          ? formField.metalDetector
                                            ? "Yes"
                                            : "No"
                                          : ""}{" "}
                                      </p>{" "}
                                      <input
                                        value={
                                          formField
                                            ? formField.metalDetector
                                            : ""
                                        }
                                        type="text"
                                        className="form-control px-4 mt-2 mb-2"
                                        placeholder="Qty"
                                        disabled
                                      />
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table>
                                          <tr> </tr>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="metalDetector.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="bbjjxxnbcategoryOkvvay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.metalDetector.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "metalDetector.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjxxnbcategoryOkvvay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="securityGuard.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="bbjjyynbbbcategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.securityGuard.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "securityGuard.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjyynbbbcategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`securityGuard.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.securityGuard.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={
                                    values.smokeDetectorsAndFireAlarm.faremark
                                  }
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-sm-center">
                                      <label
                                        htmlFor="staticEmail"
                                        className=" px-2 mb-0 text-nowrap"
                                      >
                                        Pest Control:
                                      </label>{" "}
                                      <p className="text-gray mb-0 px-2">
                                        {" "}
                                        {formField
                                          ? formField.pestControl
                                            ? "Yes"
                                            : "No"
                                          : ""}{" "}
                                      </p>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table>
                                          <label
                                            htmlFor=""
                                            className="mb-0 text-uppercase small px-4"
                                          >
                                            Select Last Date of Pest Control{" "}
                                          </label>{" "}
                                          <input
                                            value={
                                              formField
                                                ? readableDate(
                                                    formField.pestControl
                                                  )
                                                : ""
                                            }
                                            type="date"
                                            className="form-control px-4"
                                            disabled
                                          />
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="pestControl.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="bbjjxxnbcategoryOkaybb"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.pestControl.whsstatus === true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "pestControl.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjxxnbcategoryOkaybb"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="pestControl.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="bbjjyynbcategoryNotOkaybb"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.pestControl.whsstatus === false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "pestControl.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjyynbcategoryNotOkaybb"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`pestControl.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.pestControl.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.pestControl.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                    <div className="col-xl-12">
                      <table class="table table-borderless mb-0 ">
                        <thead>
                          <tr>
                            <th scope="col" class="col-8 py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="py-0">
                              {" "}
                            </th>{" "}
                            <th
                              scope="col"
                              className="py-0"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            ></th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                            <th scope="col" className="text-center py-0">
                              {" "}
                            </th>{" "}
                          </tr>{" "}
                        </thead>{" "}
                        <tbody>
                          <tr>
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0 d-flex px-0">
                                    <td className="px-0 d-flex align-items-sm-center">
                                      <label
                                        htmlFor="staticEmail"
                                        className=" px-2 mb-0 text-nowrap"
                                      >
                                        Others:
                                      </label>{" "}
                                    </td>{" "}
                                    <td className="py-0 d-flex w-100">
                                      <td className="d-flex py-0 w-100 ">
                                        <table className="w-100">
                                          <textarea
                                            value={
                                              formField ? formField.others : ""
                                            }
                                            type="text"
                                            className="form-control px-4 rounded-md"
                                            placeholder=""
                                            rows="4"
                                            disabled
                                          ></textarea>{" "}
                                        </table>{" "}
                                      </td>{" "}
                                    </td>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td verticalAlign="middle" className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check  common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="safetySecurityOther.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      id="bbjjxxnbcnnategoryOkay"
                                      type="radio"
                                      value="okay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.safetySecurityOther.whsstatus ===
                                        true
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "safetySecurityOther.whsstatus",
                                          true
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjxxnbcnnategoryOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-check common-radio-deep-blue mx-0 pl-2">
                                <tr>
                                  <td
                                    className="py-0 form-check common-radio-deep-blue"
                                    verticalAlign="middle"
                                  >
                                    <Field
                                      name="safetySecurityOther.whsstatus"
                                      className="common-radio-deep-blue-input"
                                      type="radio"
                                      id="bbjjyynbcnnategoryNotOkay"
                                      value="notOkay"
                                      hidden="true"
                                      required
                                      disabled={viewMood}
                                      checked={
                                        values.safetySecurityOther.whsstatus ===
                                        false
                                      }
                                      onChange={() =>
                                        setFieldValue(
                                          "safetySecurityOther.whsstatus",
                                          false
                                        )
                                      }
                                    />{" "}
                                    <label
                                      className="common-radio-deep-blue-label pl-sm-4"
                                      htmlFor="bbjjyynbcnnategoryNotOkay"
                                    ></label>{" "}
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0">
                              <table class="table table-borderless form-group col-12 pt-xl-1">
                                <tr>
                                  <td className="py-0">
                                    <Field
                                      disabled={viewMood}
                                      name={`pestControl.whsremark`}
                                      type="text"
                                      className="form-control bg-white px-4 mb-2 mr-4"
                                      placeholder="Remarks"
                                    />
                                  </td>{" "}
                                </tr>{" "}
                              </table>{" "}
                            </td>{" "}
                            <td className="py-0 d-flex">
                              <td className="d-flex px-0 align-item-center">
                                <p className="py-2 text-nowrap pr-4">
                                  {" "}
                                  {values.safetySecurityOther.fastatus === true
                                    ? "okay"
                                    : "not okay"}{" "}
                                </p>{" "}
                                <input
                                  disabled
                                  value={values.safetySecurityOther.faremark}
                                  type="text"
                                  className="form-control  px-4 mb-2 mr-4"
                                  placeholder="Remarks"
                                />
                              </td>{" "}
                            </td>{" "}
                          </tr>{" "}
                        </tbody>{" "}
                      </table>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <div className="row justify-content-end"> </div>{" "}
            </Form>
          );
        }}
      />{" "}
    </>
  );
};

export default SafetyPendingForm;
