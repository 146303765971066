import {
    ON_BOARD,
    ON_BOARD_BY_ID,
    ON_BOARD_RESPONSE,
    FORM_DETAIL,
    USER_ONBOARD_LIST,
    USER_ONBOARD_DETAIL,
    ONBOARD_CUSTOMER_DETAIL,
    IS_ERROR,
    IS_LOADING,
    IS_PENDING
} from './../types';


const initialState = {
    isLoading: false,
    isPending: false,
    isError: "",
    isErrorMsg: "",
    onBoardList: null,
    onBoardDetail: null,
    userOnboardList: null,
    userOnboardDetail: null,
    onBoardCustomer: null,
    formDetail: null,
    onResponse: null
}

export function ON_BOARD_INFO(state = initialState, action) {

    switch (action.type) {

        case ON_BOARD:
            return {
                ...state,
                onBoardList: action.payload,
            };

        case ON_BOARD_BY_ID:
            return {
                ...state,
                onBoardDetail: action.payload,
            };
        case ONBOARD_CUSTOMER_DETAIL:
            return {
                ...state,
                onBoardCustomer: action.payload,
            };

        case FORM_DETAIL:
            return {
                ...state,
                formDetail: action.payload,
            };

        case USER_ONBOARD_DETAIL:
            return {
                ...state,
                userOnboardDetail: action.payload,
            };

        case USER_ONBOARD_LIST:
            return {
                ...state,
                userOnboardList: action.payload,
            };

        case ON_BOARD_RESPONSE:
            return {
                ...state,
                onResponse: action.payload,
            };

        case IS_LOADING:

            return {
                ...state,
                isLoading: action.isLoading,
            };

        case IS_PENDING:

            return {
                ...state,
                isPending: action.isLoading,
            };

        case IS_ERROR:
            return {
                ...state,
                isError: action.isError,
            };

        default:
            return state;
    }
}